const familyHistoryJSON = {
  section: "FR",
  topic: "Family/Social/Peer History",
  checked: "true",
  color: "secondary",
  description: "",
  body: [
    {
      id: "FH.1",
      shortname: "fr01-adol1",
      name: "Legal Guardian",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "text",
      act: "FR01-ADOL1",
      depending: "",
      exception: "",
      link: "FR01-T-ADOL1",
    },
    {
      id: "FH.2",
      extend: "true",
      shortname: "fr01-t-adol1",
      name: "",
      prevalue: "Contact Phone",
      value: "",
      disabled: "false",
      type: "phone",
      act: "FR01-T-ADOL1",
      depending: "",
      exception: "",
    },
    {
      id: "FH.3",
      shortname: "fr01-add",
      name: "Do you know the name of your biological father?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR01-ADD",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR01",
    },
    {
      id: "FH.3.1",
      shortname: "fr01",
      name: "",
      prevalue: "What is the first and last name of your biological father?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR01",
      depending: "FR01-ADD",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "FH.4",
      shortname: "fr02-add",
      name: "Do you know the name of your biological mother?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR02-ADD",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR02",
    },
    {
      id: "FH.4.1",
      shortname: "fr02",
      name: "",
      prevalue: "What is the first and last name of your biological mother?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR02",
      depending: "FR02-ADD",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "FH.5",
      shortname: "fr03",
      name: "Do they remain together as a couple?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "Not known"],
      act: "FR03",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR04",
    },
    {
      id: "FH.5.1",
      shortname: "fr04",
      name: "",
      prevalue: "If not together, please indicate?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "never married",
        "separated",
        "divorced",
        "father deceased",
        "mother deceased",
        "both deceased",
      ],
      act: "FR04",
      mandatory: "true",
      depending: "FR03",
      exception: "No",
      extend: "true",
      link: "FR05",
    },
    {
      id: "FH.5.1.1",
      shortname: "fr05",
      name: "",
      prevalue:
        "If not together, what age were you when they separated, divorced, or died? Explain.",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR05",
      depending: "FR04",
      exception: "never married",
      extend: "true",
    },
    {
      id: "FH.5.2",
      shortname: "fr03-add0",
      name: "Have you EVER had a step-father or step-mother?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR03-ADD0",
      mandatory: "true",
      depending: "FR03",
      exception: "No;Not known",
      link: "FR03-ADD2",
    },
    {
      id: "FH.5.2.1",
      shortname: "fr03-add2",
      name: "",
      prevalue: "What is the name or names of your step-father(s)?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR03-ADD2",
      depending: "FR03-ADD0",
      exception: "Yes",
      extend: "true",
      link: "FR04-ADD2",
    },
    {
      id: "FH.5.2.2",
      shortname: "fr04-add2",
      name: "",
      prevalue: "What is the name or names of your step-mother(s)?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR04-ADD2",
      depending: "FR03-ADD0",
      exception: "Yes",
      extend: "true",
      link: "FR05-ADD1",
    },
    {
      id: "FH.5.2.3",
      shortname: "fr05-add1",
      name: "",
      prevalue: "Do they remain together as a couple?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No", "Not known"],
      act: "FR05-ADD1",
      mandatory: "true",
      depending: "FR03-ADD0",
      exception: "Yes",
      extend: "true",
      link: "FR05-ADD2",
    },
    {
      id: "FH.5.2.3.1",
      shortname: "fr05-add2",
      name: "",
      prevalue: "If not together, please indicate?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "never married",
        "separated",
        "divorced",
        "father deceased",
        "mother deceased",
        "both deceased",
      ],
      act: "FR05-ADD2",
      depending: "FR05-ADD1",
      exception: "No",
      extend: "true",
    },
    {
      id: "FH.5.3",
      shortname: "fr05-new1-add",
      name: "Have you EVER been adopted?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR05-NEW1-ADD",
      mandatory: "true",
      depending: "FR03", //"FH.5"
      exception: "No;Not known",
      link: "FR05-NEW1",
    },
    {
      id: "FH.5.3.1",
      shortname: "fr05-new1",
      name: "",
      prevalue: "If adopted, father’s name?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR05-NEW1",
      depending: "FR05-NEW1-ADD",
      exception: "Yes",
      extend: "true",
      link: "FR05-NEW2",
    },
    {
      id: "FH.5.3.2",
      shortname: "fr05-new2",
      name: "",
      prevalue: "If adopted, mother’s name?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR05-NEW2",
      depending: "FR05-NEW1-ADD",
      exception: "Yes",
      extend: "true",
      link: "FR05-NEW2-COUPLE",
    },
    {
      id: "FH.5.3.3",
      shortname: "fr05-new2-couple",
      name: "",
      prevalue: "Do they remain together as a couple?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No", "Not known"],
      act: "FR05-NEW2-COUPLE",
      mandatory: "true",
      depending: "FR05-NEW1-ADD",
      exception: "Yes",
      link: "FR05-NEW2-COUPLE-ADD",
      extend: "true",
    },
    {
      id: "FH.5.3.3.1",
      shortname: "fr05-new2-couple-add",
      name: "",
      prevalue: "If not together, please indicate?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "never married",
        "separated",
        "divorced",
        "father deceased",
        "mother deceased",
        "both deceased",
      ],
      act: "FR05-NEW2-COUPLE-ADD",
      depending: "FR05-NEW2-COUPLE",
      exception: "No",
      extend: "true",
    },
    /************************************************************************* */
    {
      id: "FH.5.3.4.0",
      shortname: "fr05-table",
      name: "If you have been adopted and not continuously lived with both of your adoptive parents?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR05-TABLE",
      mandatory: "true",
      depending: "FR05-NEW1-ADD", //"FH.5.3",
      exception: "Yes",
      condition: "in",
    },
    {
      id: "FH.5.3.4",
      shortname: "fr15-adol1-subtopic",
      // name: "If you have been adopted and not continuously lived with both of your adoptive parents, please answer the next 5 questions.",
      name: "Please answer the next 5 questions.",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "subtopic",
      act: "FR15-ADOL-ACT",
      // depending: "FR05-NEW1-ADD",
      // exception: "Yes",
      // condition: "in",
      depending: "FR05-TABLE",
      exception: "Yes",
      condition: "in",

    },
    {
      id: "FH.5.3.5",
      extend: "true",
      shortname: "fr15-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR15-ADOL1",
      // depending: "FR05-NEW1-ADD",
      // exception: "Yes",
      // condition: "in",
      depending: "FR05-TABLE",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "FR15-ADOL-ACT",
      suptopic_code: "FR15-ADOL-CODE",
      subtopic_name: "Age first separated from your adoptive parents?",
    },
    {
      id: "FH.5.3.6",
      shortname: "fr16-adol1",
      name: "",
      prevalue: "",
      value: [false, false, false, false, false, false, false],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "Adoptive parents never married",
        "Adoptive parents separated",
        "Adoptive parents divorced",
        "Foster care",
        "Death",
        "Jail",
        "Other",
      ],
      act: "FR16-ADOL1",
      // depending: "FR05-NEW1-ADD",
      // exception: "Yes",
      // condition: "in",
      depending: "FR05-TABLE",
      exception: "Yes",
      condition: "in",
      link: "FR16-1-ADOL1",
      subtopic_container_act: "FR15-ADOL-ACT",
      suptopic_code: "FR16-ADOL-CODE",
      subtopic_name: "Reason separated from your adoptive parents?",
    },
    {
      id: "FH.5.3.6.1",
      extend: "true",
      shortname: "fr16-1-adol1",
      name: "",
      prevalue: "Explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR16-1-ADOL1",
      // depending: "FR16-ADOL1",
      // exception: "Other",
      // condition: "in",
      depending: "FR05-TABLE",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "FR15-ADOL-ACT",
      suptopic_code: "FR16-ADOL-CODE",
      subtopic_name: "Reason separated from your adoptive parents?",
    },
    {
      id: "FH.5.3.7",
      shortname: "fr17-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "number",
      act: "FR17-ADOL1",
      // depending: "FR05-NEW1-ADD",
      // exception: "Yes",
      // condition: "in",
      depending: "FR05-TABLE",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "FR15-ADOL-ACT",
      suptopic_code: "FR17-ADOL-CODE",
      subtopic_name:
        "Length of total separation from your adoptive parents, in months?",
    },
    {
      id: "FH.5.3.8",
      shortname: "fr18-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: [
        "No contact",
        "Indifferent contact",
        "Positive contact",
        "Negative contact",
      ],
      act: "FR18-ADOL1",
      // depending: "FR05-NEW1-ADD",
      // exception: "Yes",
      // condition: "in",
      depending: "FR05-TABLE",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "FR15-ADOL-ACT",
      suptopic_code: "FR18-ADOL-CODE",
      subtopic_name:
        "What has been your past year contact with your adoptive parents?",
    },
    {
      id: "FH.5.3.9",
      shortname: "fr19-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: [
        "No contact",
        "Indifferent contact",
        "Positive contact",
        "Negative contact",
        "Don’t know",
      ],
      act: "FR19-ADOL1",
      // depending: "FR05-NEW1-ADD",
      // exception: "Yes",
      // condition: "in",
      depending: "FR05-TABLE",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "FR15-ADOL-ACT",
      suptopic_code: "FR19-ADOL-CODE",
      subtopic_name:
        "What type of contact have your adoptive parents had in the past year?",
    },
    {
      id: "FH.6",
      shortname: "fr10-0-adol1",
      name: "Have you continuously lived with both of your biological parents?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR10-0-ADOL1",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "FH.6.1",
      shortname: "fr16-adol1-subtopic",
      name: "If you have not continuously lived with both of your biological parents, please answer the next 5 questions.",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "subtopic",
      act: "FR10-ADOL-ACT",
      depending: "FR10-0-ADOL1",
      exception: "No",
      condition: "in",
    },
    {
      id: "FH.6.2",
      extend: "true",
      shortname: "fr10-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR10-ADOL1",
      depending: "FR10-0-ADOL1",
      exception: "No",
      condition: "in",
      subtopic_container_act: "FR10-ADOL-ACT",
      suptopic_code: "FR10-ADOL-CODE",
      subtopic_name: "Age first separated from your biological parents?",
    },
    {
      id: "FH.6.3",
      shortname: "fr11-adol1",
      name: "",
      prevalue: "",
      value: [false, false, false, false, false, false, false],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "Parents never married",
        "Parents separated",
        "Parents divorced",
        "Adoption",
        "Foster care",
        "Death",
        "Jail",
        "Other",
      ],
      act: "FR11-ADOL1",
      depending: "FR10-0-ADOL1",
      exception: "No",
      condition: "in",
      link: "FR11-1-ADOL1",
      subtopic_container_act: "FR10-ADOL-ACT",
      suptopic_code: "FR11-ADOL-CODE",
      subtopic_name: "Reason separated from?",
    },
    {
      id: "FH.6.3.1",
      extend: "true",
      shortname: "fr11-1-adol1",
      name: "",
      prevalue: "Explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR11-1-ADOL1",
      depending: "FR11-ADOL1",
      exception: "Other",
      condition: "in",
      subtopic_container_act: "FR10-ADOL-ACT",
      suptopic_code: "FR11-ADOL-CODE",
      subtopic_name: "Reason separated from?",
    },
    {
      id: "FH.6.4",
      shortname: "fr12-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "number",
      act: "FR12-ADOL1",
      depending: "FR10-0-ADOL1",
      exception: "No",
      condition: "in",
      subtopic_container_act: "FR10-ADOL-ACT",
      suptopic_code: "FR12-ADOL-CODE",
      subtopic_name:
        "Length of total separation from your biological parents, in months?",
    },
    {
      id: "FH.6.5",
      shortname: "fr13-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: [
        "No contact",
        "Indifferent contact",
        "Positive contact",
        "Negative contact",
      ],
      act: "FR13-ADOL1",
      depending: "FR10-0-ADOL1",
      exception: "No",
      condition: "in",
      subtopic_container_act: "FR10-ADOL-ACT",
      suptopic_code: "FR13-ADOL-CODE",
      subtopic_name:
        "What has been your past year contact with your biological parents?",
    },
    {
      id: "FH.6.6",
      shortname: "fr14-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: [
        "No contact",
        "Indifferent contact",
        "Positive contact",
        "Negative contact",
        "Don’t know",
      ],
      act: "FR14-ADOL1",
      depending: "FR10-0-ADOL1",
      exception: "No",
      condition: "in",
      subtopic_container_act: "FR10-ADOL-ACT",
      suptopic_code: "FR14-ADOL-CODE",
      subtopic_name:
        "What type of contact have your biological parents had in the past year?",
    },
    {
      id: "FH.7",
      shortname: "fr06-table",
      name: "Do you have any siblings?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR06-TABLE",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "FH.7.1",
      shortname: "fr6-subtopic",
      name: "How many brothers and sisters do you have?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "subtopic",
      act: "FR6-SUBTOPIC",
      depending: "FR06-TABLE",
      exception: "Yes",
    },
    {
      id: "FH.7.2",
      shortname: "fr06",
      name: "",
      prevalue: "Biological Siblings?",
      value: "0",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "FR06",
      depending: "FR06-TABLE",
      exception: "Yes",
      subtopic_container_act: "FR6-SUBTOPIC",
      suptopic_code: "FR6_BROTHERS",
      subtopic_name: "Brothers",
    },
    {
      id: "FH.7.3",
      shortname: "fr06-half",
      name: "",
      prevalue: "Half Siblings?",
      value: "0",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "FR06-HALF",
      depending: "FR06-TABLE",
      exception: "Yes",
      subtopic_container_act: "FR6-SUBTOPIC",
      suptopic_code: "FR6_BROTHERS",
      subtopic_name: "Brothers",
    },
    {
      id: "FH.7.4",
      shortname: "fr06-adopted",
      name: "",
      prevalue: "Adopted Siblings?",
      value: "0",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "FR06-ADOPTED",
      depending: "FR06-TABLE",
      exception: "Yes",
      subtopic_container_act: "FR6-SUBTOPIC",
      suptopic_code: "FR6_BROTHERS",
      subtopic_name: "Brothers",
    },
    {
      id: "FH.7.5",
      shortname: "fr06-step",
      name: "0",
      prevalue: "Step Siblings?",
      value: "0",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "FR06-STEP",
      depending: "FR06-TABLE",
      exception: "Yes",
      subtopic_container_act: "FR6-SUBTOPIC",
      suptopic_code: "FR6_BROTHERS",
      subtopic_name: "Brothers",
    },
    {
      id: "FH.7.6",
      shortname: "fr07",
      name: "",
      prevalue: "Biological Siblings?",
      value: "0",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "FR07",
      depending: "FR06-TABLE",
      exception: "Yes",
      subtopic_container_act: "FR6-SUBTOPIC",
      suptopic_code: "FR6_SISTERS",
      subtopic_name: "Sisters",
    },
    {
      id: "FH.7.7",
      shortname: "fr07-half",
      name: "",
      prevalue: "Half Siblings?",
      value: "0",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "FR07-HALF",
      depending: "FR06-TABLE",
      exception: "Yes",
      subtopic_container_act: "FR6-SUBTOPIC",
      suptopic_code: "FR6_SISTERS",
      subtopic_name: "Sisters",
    },
    {
      id: "FH.7.8",
      shortname: "fr07-adopted",
      name: "",
      prevalue: "Adopted Siblings?",
      value: "0",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "FR07-ADOPTED",
      depending: "FR06-TABLE",
      exception: "Yes",
      subtopic_container_act: "FR6-SUBTOPIC",
      suptopic_code: "FR6_SISTERS",
      subtopic_name: "Sisters",
    },
    {
      id: "FH.7.9",
      shortname: "fr07-step",
      name: "",
      prevalue: "Step Siblings?",
      value: "0",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "FR07-STEP",
      depending: "FR06-TABLE",
      exception: "Yes",
      subtopic_container_act: "FR6-SUBTOPIC",
      suptopic_code: "FR6_SISTERS",
      subtopic_name: "Sisters",
    },
    {
      id: "FH.8",
      shortname: "fr06-adol1-subtopic",
      name: "Cohabitation",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "FR06-ADOL-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "FH.9",
      shortname: "fr06-adol1",
      name: "Who typically or usually lives with you?",
      prevalue: "",
      value: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      disabled: "false",
      type: "checkbox",
      radio_options: [
        "Biological father",
        "Biological mother",
        "Step- father",
        "Step- mother",
        "Father's girlfriend",
        "Mother's boyfriend",
        "Brother(s)/Sister(s)",
        "Adoptive father",
        "Adoptive mother",
        "Foster father",
        "Foster mother",
        "Grandparents",
        "Friends",
        "Sexual partner",
        "Other",
      ],
      act: "FR06-ADOL1",
      depending: "",
      exception: "",
      link: "FR06-1-ADOL1",
      subtopic_container_act: "FR06-ADOL-ACT",
      suptopic_code: "FR06-ADOL-CODE",
      subtopic_name: "Who typically or usually lives with you?",
    },
    {
      id: "FH.9.1",
      extend: "true",
      shortname: "fr06-1-adol1",
      name: "",
      prevalue: "Explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR06-1-ADOL1",
      depending: "FR06-ADOL1",
      exception: "Other",
      condition: "in",
      link: "FR06-2-ADOL1",
      subtopic_container_act: "FR06-ADOL-ACT",
      suptopic_code: "FR06-ADOL-CODE",
      subtopic_name: "Who typically or usually lives with you?",
    },
    {
      id: "FH.9.2",
      extend: "true",
      shortname: "fr06-2-adol1",
      name: "",
      prevalue: "What is the total number of people you typically live with?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "FR06-2-ADOL1",
      depending: "FR06-ADOL1",
      exception:
        "Biological father;Biological mother;Step- father;Step- mother;Father's girlfriend;Mother's boyfriend;Brother(s)/Sister(s);Adoptive father;Adoptive mother;Foster father;Foster mother;Grandparents;Friends;Sexual partner;Other",
      subtopic_container_act: "FR06-ADOL-ACT",
      suptopic_code: "FR06-ADOL-CODE",
      subtopic_name: "Who typically or usually lives with you?",
    },
    {
      id: "FH.10",
      shortname: "fr07-new-adol1",
      name: "",
      prevalue: "Has this living situation changed in the past 30 days?",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR07-NEW-ADOL1",
      depending: "",
      exception: "",
      subtopic_container_act: "FR06-ADOL-ACT",
      suptopic_code: "FR06-ADOL-CODE",
      subtopic_name: "Who typically or usually lives with you?",
    },
    {
      id: "FH.10.1",
      shortname: "fr07-adol1",
      name: "",
      prevalue: "",
      value: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "Biological father",
        "Biological mother",
        "Step- father",
        "Step- mother",
        "Father's girlfriend",
        "Mother's boyfriend",
        "Brother(s)/Sister(s)",
        "Adoptive father",
        "Adoptive mother",
        "Foster father",
        "Foster mother",
        "Grandparents",
        "Friends",
        "Sexual partner",
        "Other",
      ],
      act: "FR07-ADOL1",
      depending: "FR07-NEW-ADOL1",
      exception: "Yes",
      link: "FR07-1-ADOL1",
      subtopic_container_act: "FR06-ADOL-ACT",
      suptopic_code: "FR07-ADOL-CODE",
      subtopic_name:
        "If you have lived in any new situation for less than a month, with whom are you living with NOW?",
    },
    {
      id: "FH.10.1.1",
      extend: "true",
      shortname: "fr07-1-adol1",
      name: "",
      prevalue: "Explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR07-1-ADOL1",
      depending: "FR07-ADOL1",
      exception: "Other",
      condition: "in",
      link: "FR07-2-ADOL1",
      subtopic_container_act: "FR06-ADOL-ACT",
      suptopic_code: "FR07-ADOL-CODE",
      subtopic_name:
        "If you have lived in any new situation for less than a month, with whom are you living with NOW?",
    },
    {
      id: "FH.10.1.2",
      extend: "true",
      shortname: "fr07-2-adol1",
      name: "",
      prevalue: "What is the total number of people you live with now?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "FR07-2-ADOL1",
      depending: "FR07-ADOL1",
      exception:
        "Biological father;Biological mother;Step- father;Step- mother;Father's girlfriend;Mother's boyfriend;Brother(s)/Sister(s);Adoptive father;Adoptive mother;Foster father;Foster mother;Grandparents;Friends;Sexual partner;Other",
      subtopic_container_act: "FR06-ADOL-ACT",
      suptopic_code: "FR07-ADOL-CODE",
      subtopic_name:
        "If you have lived in any new situation for less than a month, with whom are you living with NOW?",
    },
    {
      id: "FH.11",
      shortname: "fr08-adol1-radio",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: [
        "a house",
        "an apartment",
        "a room",
        "pubic housing",
        "a shelter",
        "a group home/Halfway house",
        "a abandoned building",
        "on the street",
        "Other",
      ],
      act: "FR08-ADOL1-RADIO",
      depending: "",
      exception: "",
      link: "FR08-1-ADOL1",
      subtopic_container_act: "FR06-ADOL-ACT",
      suptopic_code: "FR08-ADOL-CODE",
      subtopic_name: "Where do you live?",
    },
    {
      id: "FH.11.1",
      extend: "true",
      shortname: "fr08-1-adol1",
      name: "",
      prevalue: "Explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR08-1-ADOL1",
      depending: "FR08-ADOL1-RADIO",
      exception: "Other",
      condition: "in",
      subtopic_container_act: "FR06-ADOL-ACT",
      suptopic_code: "FR08-ADOL-CODE",
      subtopic_name: "Where do you live?",
    },
    {
      id: "FH.12",
      shortname: "fr09-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR09-ADOL1",
      depending: "",
      exception: "",
      subtopic_container_act: "FR06-ADOL-ACT",
      suptopic_code: "FR09-ADOL-CODE",
      subtopic_name: "Do you have your own personal space?",
    },
    {
      id: "FH.13",
      shortname: "fr20-adol1",
      name: "If you have EVER been placed in a foster home, what is the total number of different foster care placements?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: ["", "0", "1", "2", "3", "4", "5", "6"],
      act: "FR20-ADOL1",
      depending: "",
      exception: "",
    },
    {
      id: "FH.14",
      shortname: "fr8-free",
      access: "private",
      name: "In your own words, please describe what it was like growing up as a child. Describe your relationship with your parental figure(s), siblings, and other significant relationships.:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "editor",
      act: "FR8-FREE",
      depending: "",
      exception: "",
      link: "FR8-ADD",
    },
    {
      id: "FH.15",
      extend: "true",
      shortname: "fr8-add",
      name: "",
      prevalue: "How would you describe your parents parenting style?",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Authoritarian",
        "Permissive",
        "Trouble-making",
        "Authoritative/democratic",
        "Unengaged/rejecting",
      ],
      act: "FR8-ADD",
      depending: "",
      exception: "",
    },
    {
      id: "FH.16",
      shortname: "fr08-00",
      name: "Marital Status:",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR08-00",
      depending: "",
      exception: "",
    },
    {
      id: "",
      shortname: "fr08-01",
      name: "Currently, are you living with a significant other?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR08-01",
      mandatory: "true",
      depending: "DEMO1",
      exception: "married",
      link: "FR08",
    },
    {
      id: "",
      shortname: "fr08",
      name: "",
      prevalue:
        "If currently married or living with a significant other, what is his/her/their name?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR08",
      depending: "FR08-01",
      exception: "Yes",
      extend: "true",
      link: "FR09",
    },
    {
      id: "",
      shortname: "fr09",
      name: "",
      prevalue: "How many years have you been in this current relationship?",
      value: "",
      disabled: "true",
      type: "number",
      act: "FR09",
      depending: "FR08-01",
      exception: "Yes",
      extend: "true",
      link: "FR10",
    },
    {
      id: "",
      shortname: "fr10",
      name: "",
      prevalue: "Are you satisfied with living arrangements?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR10",
      mandatory: "true",
      depending: "FR08-01",
      exception: "Yes",
      link: "FR10-1",
      extend: "true",
    },
    {
      id: "",
      extend: "true",
      shortname: "fr10-1",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR10-1",
      depending: "FR10",
      exception: "No",
    },
    {
      id: "FH.17",
      shortname: "fr11",
      name: "How many children do you have?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "number",
      act: "FR11",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR12",
    },
    {
      id: "FH.17.1",
      shortname: "fr12",
      name: "",
      prevalue: "What are their name(s) and current ages?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR12",
      depending: "FR11",
      exception: "0",
      extend: "true",
    },
    {
      id: "FH.18",
      shortname: "fr13",
      name: "What has been your usual living arrangement for most of the past 3 years? Explain.",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "text",
      act: "FR13",
      depending: "",
      exception: "",
    },
    {
      id: "FH.19",
      shortname: "fr14",
      name: "Do you live with someone who has an alcohol or drug use problem?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR14",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR-NEW18",
    },
    {
      id: "FH.19.1",
      shortname: "fr-new18",
      name: "",
      prevalue: "If yes, explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR-NEW18",
      depending: "FR14",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "FH.20",
      shortname: "fr15",
      name: "How many close/best friends do you have?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
      ],
      act: "FR15",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR-NEW20",
    },
    {
      id: "FH.20.1",
      shortname: "fr-new20",
      name: "",
      prevalue: "Do any of these friends have an alcohol problem or use drugs?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR-NEW20",
      depending: "FR15",
      exception: "0",
      extend: "true",
    },
    {
      id: "FH.21",
      shortname: "fr16",
      name: "With whom do you spends most of your time?",
      prevalue: "",
      value: "with ",
      disabled: "false",
      type: "text",
      act: "FR16",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "FH.22",
      shortname: "fr17a",
      name: "How many days in the past 30 days have you had serious conflicts with anyone in your family?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "FR17A",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "FH.23",
      shortname: "fr17b",
      name: "How many days in the past 30 days have you had serious conflicts with anyone other than a family member?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "FR17B",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "FH.24",
      shortname: "fh18-subtopic",
      name: "Have you had a close reciprocal relationship with the identified people in your life? All of the items below have been defaulted to YES. If your answer is NO or n/a, please change Yes to No or n/a. ",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "FH18-SUBTOPIC",
      depending: "",
      exception: "",
    },
    {
      id: "FH.25",
      shortname: "fr18a",
      name: "Past 30 day",
      prevalue: "Father",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR18A",
      depending: "",
      exception: "",
      link: "FR18B-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.25.1",
      extend: "true",
      shortname: "fr18a-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18A-NO",
      depending: "FR18A",
      exception: "No",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
      link: "FR18B",
    },
    {
      id: "FH.26",
      extend: "true",
      shortname: "fr18b",
      name: "",
      prevalue: "Mother",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR18B",
      depending: "",
      exception: "",
      link: "FR18B-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.26.1",
      extend: "true",
      shortname: "fr18b-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18B-NO",
      depending: "FR18B",
      exception: "No",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
      link: "FR18C",
    },
    {
      id: "FH.27",
      extend: "true",
      shortname: "fr18c",
      name: "",
      prevalue: "Siblings",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR18C",
      depending: "",
      exception: "",
      link: "FR18C-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.27.1",
      extend: "true",
      shortname: "fr18c-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18C-NO",
      depending: "FR18C",
      exception: "No",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
      link: "FR18D",
    },
    {
      id: "FH.28",
      extend: "true",
      shortname: "fr18d",
      name: "",
      prevalue: "Significant other",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR18D",
      depending: "",
      exception: "",
      link: "FR18D-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.28.1",
      extend: "true",
      shortname: "fr18d-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18D-NO",
      depending: "FR18D",
      exception: "No",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
      link: "FR18E",
    },
    {
      id: "FH.29",
      extend: "true",
      shortname: "fr18e",
      name: "",
      prevalue: "Child/Children",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR18E",
      depending: "",
      exception: "",
      link: "FR18E-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.29.1",
      extend: "true",
      shortname: "fr18e-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18E-NO",
      depending: "FR18E",
      exception: "No",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
      link: "FR18F",
    },
    {
      id: "FH.30",
      extend: "true",
      shortname: "fr18f",
      name: "",
      prevalue: "Friends",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR18F",
      depending: "",
      exception: "",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
      link: "FR18F-NO",
    },
    {
      id: "FH.30.1",
      extend: "true",
      shortname: "fr18f-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18F-NO",
      depending: "FR18F",
      exception: "No",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.31",
      shortname: "fr18a-1",
      name: "In lifetime",
      prevalue: "Father",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR18A-1",
      depending: "",
      exception: "",
      link: "FR18A-1-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.31.1",
      extend: "true",
      shortname: "fr18a-1-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18A-1-NO",
      depending: "FR18A-1",
      exception: "No",
      link: "FR18B-1",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.32",
      extend: "true",
      shortname: "fr18b-1",
      name: "",
      prevalue: "Mother",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR18B-1",
      depending: "",
      exception: "",
      link: "FR18B-1-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.32.1",
      extend: "true",
      shortname: "fr18b-1-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18B-1-NO",
      depending: "FR18B-1",
      exception: "No",
      link: "FR18C-1",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.33",
      extend: "true",
      shortname: "fr18c-1",
      name: "",
      prevalue: "Siblings",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR18C-1",
      depending: "",
      exception: "",
      link: "FR18C-1-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.33.1",
      extend: "true",
      shortname: "fr18c-1-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18C-1-NO",
      depending: "FR18C-1",
      exception: "No",
      link: "FR18D-1",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.34",
      extend: "true",
      shortname: "fr18d-1",
      name: "",
      prevalue: "Significant other",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR18D-1",
      depending: "",
      exception: "",
      link: "FR18D-1-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.34.1",
      extend: "true",
      shortname: "fr18d-1-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18D-1-NO",
      depending: "FR18D-1",
      exception: "No",
      link: "FR18E-1",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.35",
      extend: "true",
      shortname: "fr18e-1",
      name: "",
      prevalue: "Child/Children",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR18E-1",
      depending: "",
      exception: "",
      link: "FR18E-1-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.35.1",
      extend: "true",
      shortname: "fr18e-1-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18E-1-NO",
      depending: "FR18E-1",
      exception: "No",
      link: "FR18F-1",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.36",
      extend: "true",
      shortname: "fr18f-1",
      name: "",
      prevalue: "Friends",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR18F-1",
      depending: "",
      exception: "",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.36.1",
      extend: "true",
      shortname: "fr18f-1-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18F-1-NO",
      depending: "FR18F-1",
      exception: "No",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.37",
      shortname: "fh19-subtopic",
      name: "Have you EVER had a significant period in your life when there were serious problems getting along with the identified people?  All of the items below have been defaulted to NO. If your answer is YES or n/a, please change NO to YES or n/a.",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "FH19-SUBTOPIC",
      depending: "",
      exception: "",
    },
    {
      id: "FH.38",
      shortname: "fr19a",
      name: "Past 30 day",
      prevalue: "Father",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR19A",
      depending: "",
      exception: "",
      link: "FR19A-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.38.1",
      extend: "true",
      shortname: "fr19a-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19A-YES",
      depending: "FR19A",
      exception: "Yes",
      link: "FR19B",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.39",
      extend: "true",
      shortname: "fr19b",
      name: "",
      prevalue: "Mother",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR19B",
      depending: "",
      exception: "",
      link: "FR19B-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.39.1",
      extend: "true",
      shortname: "fr19b-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19B-YES",
      depending: "FR19B",
      exception: "Yes",
      link: "FR19C",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.40",
      extend: "true",
      shortname: "fr19c",
      name: "",
      prevalue: "Siblings",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR19C",
      depending: "",
      exception: "",
      link: "FR19C-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.40.1",
      extend: "true",
      shortname: "fr19c-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19C-YES",
      depending: "FR19C",
      exception: "Yes",
      link: "FR19D",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.41",
      extend: "true",
      shortname: "fr19d",
      name: "",
      prevalue: "Significant other",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR19D",
      depending: "",
      exception: "",
      link: "FR19D-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.41.1",
      extend: "true",
      shortname: "fr19d-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19D-YES",
      depending: "FR19D",
      exception: "Yes",
      link: "FR19E",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.42",
      shortname: "fr19e",
      name: "",
      prevalue: "Other Family",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR19E",
      depending: "",
      exception: "",
      link: "FR19E-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.42.1",
      extend: "true",
      shortname: "fr19e-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19E-YES",
      depending: "FR19E",
      exception: "Yes",
      link: "FR19F",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.43",
      extend: "true",
      shortname: "fr19f",
      name: "",
      prevalue: "Child/Children",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR19F",
      depending: "",
      exception: "",
      link: "FR19F-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.43.1",
      extend: "true",
      shortname: "fr19f-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19F-YES",
      depending: "FR19F",
      exception: "Yes",
      link: "FR19G",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.44",
      extend: "true",
      shortname: "fr19g",
      name: "",
      prevalue: "Friends",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR19G",
      depending: "",
      exception: "",
      link: "FR19G-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.44.1",
      extend: "true",
      shortname: "fr19g-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19G-YES",
      depending: "FR19G",
      exception: "Yes",
      link: "FR19H",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.45",
      extend: "true",
      shortname: "fr19h",
      name: "",
      prevalue: "Neighbors",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR19H",
      depending: "",
      exception: "",
      link: "FR19H-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.45.1",
      extend: "true",
      shortname: "fr19h-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19H-YES",
      depending: "FR19H",
      exception: "Yes",
      link: "FR19J",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.46",
      extend: "true",
      shortname: "fr19j",
      name: "",
      prevalue: "Co-Workers",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR19J",
      depending: "",
      exception: "",
      link: "FR19J-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.46.1",
      extend: "true",
      shortname: "fr19j-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19J-YES",
      depending: "FR19J",
      exception: "Yes",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.47",
      shortname: "fr19a-1",
      name: "In lifetime",
      prevalue: "Father",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR19A-1",
      depending: "",
      exception: "",
      link: "FR19A-1-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.47.1",
      extend: "true",
      shortname: "fr19a-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19A-1-YES",
      depending: "FR19A-1",
      exception: "Yes",
      link: "FR19B-1",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.48",
      extend: "true",
      shortname: "fr19b-1",
      name: "",
      prevalue: "Mother",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR19B-1",
      depending: "",
      exception: "",
      link: "FR19B-1-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.48.1",
      extend: "true",
      shortname: "fr19b-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19B-1-YES",
      depending: "FR19B-1",
      exception: "Yes",
      link: "FR19C-1",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.49",
      extend: "true",
      shortname: "fr19c-1",
      name: "",
      prevalue: "Siblings",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR19C-1",
      depending: "",
      exception: "",
      link: "FR19C-1-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.49.1",
      extend: "true",
      shortname: "fr19c-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19C-1-YES",
      depending: "FR19C-1",
      exception: "Yes",
      link: "FR19D-1",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.50",
      extend: "true",
      shortname: "fr19d-1",
      name: "",
      prevalue: "Significant other",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR19D-1",
      depending: "",
      exception: "",
      link: "FR19D-1-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.50.1",
      extend: "true",
      shortname: "fr19d-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19D-1-YES",
      depending: "FR19D-1",
      exception: "Yes",
      link: "FR19E-1",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.51",
      shortname: "fr19e-1",
      name: "",
      prevalue: "Other Family",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR19E-1",
      depending: "",
      exception: "",
      link: "FR19E-1-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.51.1",
      extend: "true",
      shortname: "fr19e-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19E-1-YES",
      depending: "FR19E-1",
      exception: "Yes",
      link: "FR19F-1",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.52",
      extend: "true",
      shortname: "fr19f-1",
      name: "",
      prevalue: "Child/Children",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR19F-1",
      depending: "",
      exception: "",
      link: "FR19F-1-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.52.1",
      extend: "true",
      shortname: "fr19f-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19F-1-YES",
      depending: "FR19F-1",
      exception: "Yes",
      link: "FR19G-1",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.53",
      extend: "true",
      shortname: "fr19g-1",
      name: "",
      prevalue: "Friends",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR19G-1",
      depending: "",
      exception: "",
      link: "FR19G-1-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.53.1",
      extend: "true",
      shortname: "fr19g-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19G-1-YES",
      depending: "FR19G-1",
      exception: "Yes",
      link: "FR19H-1",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.54",
      extend: "true",
      shortname: "fr19h-1",
      name: "",
      prevalue: "Neighbors",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR19H-1",
      depending: "",
      exception: "",
      link: "FR19H-1-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.54.1",
      extend: "true",
      shortname: "fr19h-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19H-1-YES",
      depending: "FR19H-1",
      exception: "Yes",
      link: "FR19J-1",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.55",
      extend: "true",
      shortname: "fr19j-1",
      name: "",
      prevalue: "Co-Workers",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "n/a"],
      act: "FR19J-1",
      depending: "",
      exception: "",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.55.1",
      extend: "true",
      shortname: "fr19j-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19J-1-YES",
      depending: "FR19J-1",
      exception: "Yes",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.56",
      shortname: "fr20",
      name: "Have you EVER been emotionally abused?",
      prevalue: "For the past 30 days.",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR20",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR20A",
    },
    {
      id: "FH.56.1",
      extend: "true",
      shortname: "fr20a",
      name: "",
      prevalue:
        "If yes for the past 30 days who abused you and briefly describe?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR20A",
      depending: "FR20",
      exception: "Yes",
      link: "FR20-2",
    },
    {
      id: "FH.57",
      extend: "true",
      shortname: "fr20-2",
      name: "",
      prevalue: "For the lifetime.",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR20-2",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR20B",
    },
    {
      id: "FH.57.1",
      shortname: "fr20b",
      name: "",
      prevalue: "If yes for your lifetime who abused you and briefly describe?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR20B",
      depending: "FR20-2",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "FH.58",
      shortname: "fr21",
      name: "Have you EVER been physically abused?",
      prevalue: "For the past 30 days.",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR21",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR21A",
    },
    {
      id: "FH.58.1",
      extend: "true",
      shortname: "fr21a",
      name: "",
      prevalue:
        "If yes for the past 30 days who abused you and briefly describe?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR21A",
      depending: "FR21",
      exception: "Yes",
      link: "FR21-2",
    },
    {
      id: "FH.59",
      extend: "true",
      shortname: "fr21-2",
      name: "",
      prevalue: "For the lifetime.",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR21-2",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR21B",
    },
    {
      id: "FH.59.1",
      shortname: "fr21b",
      name: "",
      prevalue: "If yes for your lifetime who abused you and briefly describe?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR21B",
      depending: "FR21-2",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "FH.60",
      shortname: "fr22",
      name: "Have you EVER been sexually abused?",
      prevalue: "For the past 30 days.",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR22",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR22A",
    },
    {
      id: "FH.60.1",
      extend: "true",
      shortname: "fr22a",
      name: "",
      prevalue:
        "If yes for the past 30 days who abused you and briefly describe?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR22A",
      depending: "FR22",
      exception: "Yes",
      link: "FR22-2",
    },
    {
      id: "FH.61",
      extend: "true",
      shortname: "fr22-2",
      name: "",
      prevalue: "For the lifetime.",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR22-2",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR22B",
    },
    {
      id: "FH.61.1",
      shortname: "fr22b",
      name: "",
      prevalue: "If yes for your lifetime who abused you and briefly describe?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR22B",
      depending: "FR22-2",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "FH.62",
      shortname: "fr22-adol1-subtopic",
      name: "Have you (or any other household member) EVER had significant periods during which you ...",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "FR22-ADOL-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "FH.63",
      shortname: "fr22-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR22-ADOL1",
      depending: "",
      exception: "",
      link: "FR22-1-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR22-ADOL-CODE",
      subtopic_name:
        "were repeatedly insulted or criticized by someone in your household (e.g., wish you were never born; you can't do anything right, i.e., emotionally abused)?",
    },
    {
      id: "FH.63.1",
      extend: "true",
      shortname: "fr22-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR22-1-ADOL1",
      condition: "in",
      depending: "FR22-ADOL1",
      exception: "Ever;Yes",
      link: "FR22-2-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR22-ADOL-CODE",
      subtopic_name:
        "were repeatedly insulted or criticized by someone in your household (e.g., wish you were never born; you can't do anything right, i.e., emotionally abused)?",
    },
    {
      id: "FH.63.2",
      extend: "true",
      shortname: "fr22-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR22-2-ADOL1",
      condition: "in",
      depending: "FR22-ADOL1",
      exception: "Ever;Yes",
      link: "FR22-3-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR22-ADOL-CODE",
      subtopic_name:
        "were repeatedly insulted or criticized by someone in your household (e.g., wish you were never born; you can't do anything right, i.e., emotionally abused)?",
    },
    {
      id: "FH.63.3",
      extend: "true",
      shortname: "fr22-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR22-3-ADOL1",
      condition: "in",
      depending: "FR22-ADOL1",
      exception: "Ever;Yes",
      link: "FR22-C-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR22-ADOL-CODE",
      subtopic_name:
        "were repeatedly insulted or criticized by someone in your household (e.g., wish you were never born; you can't do anything right, i.e., emotionally abused)?",
    },
    {
      id: "FH.63.4",
      extend: "true",
      shortname: "fr22-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR22-C-ADOL1",
      depending: "FR22-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR22-ADOL-CODE",
      subtopic_name:
        "were repeatedly insulted or criticized by someone in your household (e.g., wish you were never born; you can't do anything right, i.e., emotionally abused)?",
    },
    {
      id: "FH.64",
      shortname: "fr23-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR23-ADOL1",
      depending: "",
      exception: "",
      link: "FR23-WHO-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR23-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household been repeatedly insulted or criticized?",
    },
    {
      id: "FH.64.1",
      extend: "true",
      shortname: "fr23-who-adol1",
      name: "",
      prevalue: "If yes, who?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR23-WHO-ADOL1",
      condition: "in",
      depending: "FR23-ADOL1",
      exception: "Ever;Yes",
      link: "FR23-1-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR23-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household been repeatedly insulted or criticized?",
    },
    {
      id: "FH.64.2",
      extend: "true",
      shortname: "fr23-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR23-1-ADOL1",
      condition: "in",
      depending: "FR23-ADOL1",
      exception: "Ever;Yes",
      link: "FR23-2-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR23-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household been repeatedly insulted or criticized?",
    },
    {
      id: "FH.64.3",
      extend: "true",
      shortname: "fr23-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR23-2-ADOL1",
      condition: "in",
      depending: "FR23-ADOL1",
      exception: "Ever;Yes",
      link: "FR23-3-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR23-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household been repeatedly insulted or criticized?",
    },
    {
      id: "FH.64.4",
      extend: "true",
      shortname: "fr23-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR23-3-ADOL1",
      condition: "in",
      depending: "FR23-ADOL1",
      exception: "Ever;Yes",
      link: "FR23-C-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR23-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household been repeatedly insulted or criticized?",
    },
    {
      id: "FH.64.5",
      extend: "true",
      shortname: "fr23-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR23-C-ADOL1",
      depending: "FR23-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR23-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household been repeatedly insulted or criticized?",
    },
    {
      id: "FH.65",
      shortname: "fr24-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR24-ADOL1",
      depending: "",
      exception: "",
      link: "FR24-1-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR24-ADOL-CODE",
      subtopic_name:
        "got so angry that you threw objects, punched walls, destroyed furniture?",
    },
    {
      id: "FH.65.1",
      extend: "true",
      shortname: "fr24-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR24-1-ADOL1",
      condition: "in",
      depending: "FR24-ADOL1",
      exception: "Ever;Yes",
      link: "FR24-2-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR24-ADOL-CODE",
      subtopic_name:
        "got so angry that you threw objects, punched walls, destroyed furniture?",
    },
    {
      id: "FH.65.2",
      extend: "true",
      shortname: "fr24-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR24-2-ADOL1",
      condition: "in",
      depending: "FR24-ADOL1",
      exception: "Ever;Yes",
      link: "FR24-3-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR24-ADOL-CODE",
      subtopic_name:
        "got so angry that you threw objects, punched walls, destroyed furniture?",
    },
    {
      id: "FH.65.3",
      extend: "true",
      shortname: "fr24-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR24-3-ADOL1",
      condition: "in",
      depending: "FR24-ADOL1",
      exception: "Ever;Yes",
      link: "FR24-C-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR24-ADOL-CODE",
      subtopic_name:
        "got so angry that you threw objects, punched walls, destroyed furniture?",
    },
    {
      id: "FH.65.4",
      extend: "true",
      shortname: "fr24-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR24-C-ADOL1",
      depending: "FR24-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR24-ADOL-CODE",
      subtopic_name:
        "got so angry that you threw objects, punched walls, destroyed furniture?",
    },
    {
      id: "FH.66",
      shortname: "fr25-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR25-ADOL1",
      depending: "",
      exception: "",
      link: "FR25-WHO-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR25-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household gotten so angry that they threw objects, punched walls, destroyed furniture?",
    },
    {
      id: "FH.66.1",
      extend: "true",
      shortname: "fr25-who-adol1",
      name: "",
      prevalue: "If yes, who?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR25-WHO-ADOL1",
      condition: "in",
      depending: "FR25-ADOL1",
      exception: "Ever;Yes",
      link: "FR25-1-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR25-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household gotten so angry that they threw objects, punched walls, destroyed furniture?",
    },
    {
      id: "FH.66.2",
      extend: "true",
      shortname: "fr25-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR25-1-ADOL1",
      condition: "in",
      depending: "FR25-ADOL1",
      exception: "Ever;Yes",
      link: "FR25-2-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR25-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household gotten so angry that they threw objects, punched walls, destroyed furniture?",
    },
    {
      id: "FH.66.3",
      extend: "true",
      shortname: "fr25-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR25-2-ADOL1",
      condition: "in",
      depending: "FR25-ADOL1",
      exception: "Ever;Yes",
      link: "FR25-3-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR25-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household gotten so angry that they threw objects, punched walls, destroyed furniture?",
    },
    {
      id: "FH.66.4",
      extend: "true",
      shortname: "fr25-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR25-3-ADOL1",
      condition: "in",
      depending: "FR25-ADOL1",
      exception: "Ever;Yes",
      link: "FR25-C-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR25-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household gotten so angry that they threw objects, punched walls, destroyed furniture?",
    },
    {
      id: "FH.66.5",
      extend: "true",
      shortname: "fr25-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR25-C-ADOL1",
      depending: "FR25-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR25-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household gotten so angry that they threw objects, punched walls, destroyed furniture?",
    },
    {
      id: "FH.67",
      shortname: "fr26-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR26-ADOL1",
      depending: "",
      exception: "",
      link: "FR26-1-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR26-ADOL-CODE",
      subtopic_name:
        "were hit so hard that you had bruises, broken bones or had to be taken to the hospital, i.e., physically abused)?",
    },
    {
      id: "FH.67.1",
      extend: "true",
      shortname: "fr26-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR26-1-ADOL1",
      condition: "in",
      depending: "FR26-ADOL1",
      exception: "Ever;Yes",
      link: "FR26-2-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR26-ADOL-CODE",
      subtopic_name:
        "were hit so hard that you had bruises, broken bones or had to be taken to the hospital, i.e., physically abused)?",
    },
    {
      id: "FH.67.2",
      extend: "true",
      shortname: "fr26-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR26-2-ADOL1",
      condition: "in",
      depending: "FR26-ADOL1",
      exception: "Ever;Yes",
      link: "FR26-3-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR26-ADOL-CODE",
      subtopic_name:
        "were hit so hard that you had bruises, broken bones or had to be taken to the hospital, i.e., physically abused)?",
    },
    {
      id: "FH.67.3",
      extend: "true",
      shortname: "fr26-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR26-3-ADOL1",
      condition: "in",
      depending: "FR26-ADOL1",
      exception: "Ever;Yes",
      link: "FR26-C-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR26-ADOL-CODE",
      subtopic_name:
        "were hit so hard that you had bruises, broken bones or had to be taken to the hospital, i.e., physically abused)?",
    },
    {
      id: "FH.67.4",
      extend: "true",
      shortname: "fr26-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR26-C-ADOL1",
      depending: "FR26-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR26-ADOL-CODE",
      subtopic_name:
        "were hit so hard that you had bruises, broken bones or had to be taken to the hospital, i.e., physically abused)?",
    },
    {
      id: "FH.68",
      shortname: "fr27-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR27-ADOL1",
      depending: "",
      exception: "",
      link: "FR27-WHO-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR27-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household been hit so hard that they had bruises, broken bones or had to be taken to the hospital?",
    },
    {
      id: "FH.68.1",
      extend: "true",
      shortname: "fr27-who-adol1",
      name: "",
      prevalue: "If yes, who?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR27-WHO-ADOL1",
      condition: "in",
      depending: "FR27-ADOL1",
      exception: "Ever;Yes",
      link: "FR27-1-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR27-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household been hit so hard that they had bruises, broken bones or had to be taken to the hospital?",
    },
    {
      id: "FH.68.2",
      extend: "true",
      shortname: "fr27-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR27-1-ADOL1",
      condition: "in",
      depending: "FR27-ADOL1",
      exception: "Ever;Yes",
      link: "FR27-2-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR27-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household been hit so hard that they had bruises, broken bones or had to be taken to the hospital?",
    },
    {
      id: "FH.68.3",
      extend: "true",
      shortname: "fr27-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR27-2-ADOL1",
      condition: "in",
      depending: "FR27-ADOL1",
      exception: "Ever;Yes",
      link: "FR27-3-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR27-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household been hit so hard that they had bruises, broken bones or had to be taken to the hospital?",
    },
    {
      id: "FH.68.4",
      extend: "true",
      shortname: "fr27-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR27-3-ADOL1",
      condition: "in",
      depending: "FR27-ADOL1",
      exception: "Ever;Yes",
      link: "FR27-C-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR27-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household been hit so hard that they had bruises, broken bones or had to be taken to the hospital?",
    },
    {
      id: "FH.68.5",
      extend: "true",
      shortname: "fr27-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR27-C-ADOL1",
      depending: "FR27-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR27-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household been hit so hard that they had bruises, broken bones or had to be taken to the hospital?",
    },
    {
      id: "FH.69",
      shortname: "fr28-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR28-ADOL1",
      depending: "",
      exception: "",
      link: "FR28-1-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR28-ADOL-CODE",
      subtopic_name:
        "engaged in sexual activity against your will (i.e., sexually abused)?",
    },
    {
      id: "FH.69.1",
      extend: "true",
      shortname: "fr28-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR28-1-ADOL1",
      condition: "in",
      depending: "FR28-ADOL1",
      exception: "Ever;Yes",
      link: "FR28-2-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR28-ADOL-CODE",
      subtopic_name:
        "engaged in sexual activity against your will (i.e., sexually abused)?",
    },
    {
      id: "FH.69.2",
      extend: "true",
      shortname: "fr28-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR28-2-ADOL1",
      condition: "in",
      depending: "FR28-ADOL1",
      exception: "Ever;Yes",
      link: "FR28-3-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR28-ADOL-CODE",
      subtopic_name:
        "engaged in sexual activity against your will (i.e., sexually abused)?",
    },
    {
      id: "FH.69.3",
      extend: "true",
      shortname: "fr28-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR28-3-ADOL1",
      condition: "in",
      depending: "FR28-ADOL1",
      exception: "Ever;Yes",
      link: "FR28-C-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR28-ADOL-CODE",
      subtopic_name:
        "engaged in sexual activity against your will (i.e., sexually abused)?",
    },
    {
      id: "FH.69.4",
      extend: "true",
      shortname: "fr28-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR28-C-ADOL1",
      depending: "FR28-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR28-ADOL-CODE",
      subtopic_name:
        "engaged in sexual activity against your will (i.e., sexually abused)?",
    },
    {
      id: "FH.70",
      shortname: "fr29-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR29-ADOL1",
      depending: "",
      exception: "",
      link: "FR29-WHO-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR29-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household engaged in sexual activity against their will (i.e., sexually abused)?",
    },
    {
      id: "FH.70.1",
      extend: "true",
      shortname: "fr29-who-adol1",
      name: "",
      prevalue: "If yes, who?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR29-WHO-ADOL1",
      condition: "in",
      depending: "FR29-ADOL1",
      exception: "Ever;Yes",
      link: "FR29-1-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR29-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household engaged in sexual activity against their will (i.e., sexually abused)?",
    },
    {
      id: "FH.70.2",
      extend: "true",
      shortname: "fr29-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR29-1-ADOL1",
      condition: "in",
      depending: "FR29-ADOL1",
      exception: "Ever;Yes",
      link: "FR29-2-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR29-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household engaged in sexual activity against their will (i.e., sexually abused)?",
    },
    {
      id: "FH.70.3",
      extend: "true",
      shortname: "fr29-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR29-2-ADOL1",
      condition: "in",
      depending: "FR29-ADOL1",
      exception: "Ever;Yes",
      link: "FR29-3-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR29-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household engaged in sexual activity against their will (i.e., sexually abused)?",
    },
    {
      id: "FH.70.4",
      extend: "true",
      shortname: "fr29-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR29-3-ADOL1",
      condition: "in",
      depending: "FR29-ADOL1",
      exception: "Ever;Yes",
      link: "FR29-C-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR29-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household engaged in sexual activity against their will (i.e., sexually abused)?",
    },
    {
      id: "FH.70.5",
      extend: "true",
      shortname: "fr29-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR29-C-ADOL1",
      depending: "FR29-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR29-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household engaged in sexual activity against their will (i.e., sexually abused)?",
    },
    {
      id: "FH.71",
      shortname: "fr30-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR30-ADOL1",
      depending: "",
      exception: "",
      link: "FR30-1-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR30-ADOL-CODE",
      subtopic_name:
        "could not get along with another household member for an extended period of time, e.g., conflict, excessive verbal fighting, chaos, name calling?",
    },
    {
      id: "FH.71.1",
      extend: "true",
      shortname: "fr30-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR30-1-ADOL1",
      condition: "in",
      depending: "FR30-ADOL1",
      exception: "Ever;Yes",
      link: "FR30-2-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR30-ADOL-CODE",
      subtopic_name:
        "could not get along with another household member for an extended period of time, e.g., conflict, excessive verbal fighting, chaos, name calling?",
    },
    {
      id: "FH.71.2",
      extend: "true",
      shortname: "fr30-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR30-2-ADOL1",
      condition: "in",
      depending: "FR30-ADOL1",
      exception: "Ever;Yes",
      link: "FR30-3-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR30-ADOL-CODE",
      subtopic_name:
        "could not get along with another household member for an extended period of time, e.g., conflict, excessive verbal fighting, chaos, name calling?",
    },
    {
      id: "FH.71.3",
      extend: "true",
      shortname: "fr30-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR30-3-ADOL1",
      condition: "in",
      depending: "FR30-ADOL1",
      exception: "Ever;Yes",
      link: "FR30-C-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR30-ADOL-CODE",
      subtopic_name:
        "could not get along with another household member for an extended period of time, e.g., conflict, excessive verbal fighting, chaos, name calling?",
    },
    {
      id: "FH.71.4",
      extend: "true",
      shortname: "fr30-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR30-C-ADOL1",
      depending: "FR30-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR30-ADOL-CODE",
      subtopic_name:
        "could not get along with another household member for an extended period of time, e.g., conflict, excessive verbal fighting, chaos, name calling?",
    },
    {
      id: "FH.72",
      shortname: "fr31-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR31-ADOL1",
      depending: "",
      exception: "",
      link: "FR31-WHO-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR31-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household not gotten along with another household member for an extended period of time, e.g., conflict, excessive verbal fighting, chaos, name calling?",
    },
    {
      id: "FH.72.1",
      extend: "true",
      shortname: "fr31-who-adol1",
      name: "",
      prevalue: "If yes, who?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR31-WHO-ADOL1",
      condition: "in",
      depending: "FR31-ADOL1",
      exception: "Ever;Yes",
      link: "FR31-1-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR31-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household not gotten along with another household member for an extended period of time, e.g., conflict, excessive verbal fighting, chaos, name calling?",
    },
    {
      id: "FH.72.2",
      extend: "true",
      shortname: "fr31-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR31-1-ADOL1",
      condition: "in",
      depending: "FR31-ADOL1",
      exception: "Ever;Yes",
      link: "FR31-2-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR31-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household not gotten along with another household member for an extended period of time, e.g., conflict, excessive verbal fighting, chaos, name calling?",
    },
    {
      id: "FH.72.3",
      extend: "true",
      shortname: "fr31-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR31-2-ADOL1",
      condition: "in",
      depending: "FR31-ADOL1",
      exception: "Ever;Yes",
      link: "FR31-3-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR31-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household not gotten along with another household member for an extended period of time, e.g., conflict, excessive verbal fighting, chaos, name calling?",
    },
    {
      id: "FH.72.4",
      extend: "true",
      shortname: "fr31-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR31-3-ADOL1",
      condition: "in",
      depending: "FR31-ADOL1",
      exception: "Ever;Yes",
      link: "FR31-C-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR31-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household not gotten along with another household member for an extended period of time, e.g., conflict, excessive verbal fighting, chaos, name calling?",
    },
    {
      id: "FH.72.5",
      extend: "true",
      shortname: "fr31-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR31-C-ADOL1",
      depending: "FR31-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR31-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household not gotten along with another household member for an extended period of time, e.g., conflict, excessive verbal fighting, chaos, name calling?",
    },
    {
      id: "FH.73",
      shortname: "fr32-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR32-ADOL1",
      depending: "",
      exception: "",
      link: "FR32-1-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR32-ADOL-CODE",
      subtopic_name:
        "found it hard to (or could not) talk with your parent(s)/guardian(s) about important issues, were unable to confide in them or tell them how you really feel?",
    },
    {
      id: "FH.73.1",
      extend: "true",
      shortname: "fr32-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR32-1-ADOL1",
      condition: "in",
      depending: "FR32-ADOL1",
      exception: "Ever;Yes",
      link: "FR32-2-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR32-ADOL-CODE",
      subtopic_name:
        "found it hard to (or could not) talk with your parent(s)/guardian(s) about important issues, were unable to confide in them or tell them how you really feel?",
    },
    {
      id: "FH.73.2",
      extend: "true",
      shortname: "fr32-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR32-2-ADOL1",
      condition: "in",
      depending: "FR32-ADOL1",
      exception: "Ever;Yes",
      link: "FR32-3-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR32-ADOL-CODE",
      subtopic_name:
        "found it hard to (or could not) talk with your parent(s)/guardian(s) about important issues, were unable to confide in them or tell them how you really feel?",
    },
    {
      id: "FH.73.3",
      extend: "true",
      shortname: "fr32-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR32-3-ADOL1",
      condition: "in",
      depending: "FR32-ADOL1",
      exception: "Ever;Yes",
      link: "FR32-C-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR32-ADOL-CODE",
      subtopic_name:
        "found it hard to (or could not) talk with your parent(s)/guardian(s) about important issues, were unable to confide in them or tell them how you really feel?",
    },
    {
      id: "FH.73.4",
      extend: "true",
      shortname: "fr32-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR32-C-ADOL1",
      depending: "FR32-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR32-ADOL-CODE",
      subtopic_name:
        "found it hard to (or could not) talk with your parent(s)/guardian(s) about important issues, were unable to confide in them or tell them how you really feel?",
    },
    {
      id: "FH.74",
      shortname: "fr33-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR33-ADOL1",
      depending: "",
      exception: "",
      link: "FR33-WHO-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR33-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household had trouble talking about important things?",
    },
    {
      id: "FH.74.1",
      extend: "true",
      shortname: "fr33-who-adol1",
      name: "",
      prevalue: "If yes, who?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR33-WHO-ADOL1",
      condition: "in",
      depending: "FR33-ADOL1",
      exception: "Ever;Yes",
      link: "FR33-1-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR33-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household had trouble talking about important things?",
    },
    {
      id: "FH.74.2",
      extend: "true",
      shortname: "fr33-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR33-1-ADOL1",
      condition: "in",
      depending: "FR33-ADOL1",
      exception: "Ever;Yes",
      link: "FR33-2-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR33-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household had trouble talking about important things?",
    },
    {
      id: "FH.74.3",
      extend: "true",
      shortname: "fr33-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR33-2-ADOL1",
      condition: "in",
      depending: "FR33-ADOL1",
      exception: "Ever;Yes",
      link: "FR33-3-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR33-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household had trouble talking about important things?",
    },
    {
      id: "FH.74.4",
      extend: "true",
      shortname: "fr33-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR33-3-ADOL1",
      condition: "in",
      depending: "FR33-ADOL1",
      exception: "Ever;Yes",
      link: "FR33-C-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR33-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household had trouble talking about important things?",
    },
    {
      id: "FH.74.5",
      extend: "true",
      shortname: "fr33-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR33-C-ADOL1",
      depending: "FR33-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR33-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household had trouble talking about important things?",
    },
    {
      id: "FH.75",
      shortname: "fr34-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR34-ADOL1",
      depending: "",
      exception: "",
      link: "FR34-1-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR34-ADOL-CODE",
      subtopic_name: 'were ignored or were given the "silent treatment"? Ever',
    },
    {
      id: "FH.75.1",
      extend: "true",
      shortname: "fr34-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR34-1-ADOL1",
      condition: "in",
      depending: "FR34-ADOL1",
      exception: "Ever;Yes",
      link: "FR34-2-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR34-ADOL-CODE",
      subtopic_name: 'were ignored or were given the "silent treatment"?',
    },
    {
      id: "FH.75.2",
      extend: "true",
      shortname: "fr34-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR34-2-ADOL1",
      condition: "in",
      depending: "FR34-ADOL1",
      exception: "Ever;Yes",
      link: "FR34-3-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR34-ADOL-CODE",
      subtopic_name: 'were ignored or were given the "silent treatment"?',
    },
    {
      id: "FH.75.3",
      extend: "true",
      shortname: "fr34-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR34-3-ADOL1",
      condition: "in",
      depending: "FR34-ADOL1",
      exception: "Ever;Yes",
      link: "FR34-C-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR34-ADOL-CODE",
      subtopic_name: 'were ignored or were given the "silent treatment"?',
    },
    {
      id: "FH.75.4",
      extend: "true",
      shortname: "fr34-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR34-C-ADOL1",
      depending: "FR34-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR34-ADOL-CODE",
      subtopic_name: 'were ignored or were given the "silent treatment"?',
    },
    {
      id: "FH.76",
      shortname: "fr35-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR35-ADOL1",
      depending: "",
      exception: "",
      link: "FR35-WHO-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR35-ADOL-CODE",
      subtopic_name:
        'Has any other member of your household been ignored or were given the "silent treatment"?',
    },
    {
      id: "FH.76.1",
      extend: "true",
      shortname: "fr35-who-adol1",
      name: "",
      prevalue: "If yes, who?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR35-WHO-ADOL1",
      condition: "in",
      depending: "FR35-ADOL1",
      exception: "Ever;Yes",
      link: "FR35-1-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR35-ADOL-CODE",
      subtopic_name:
        'Has any other member of your household been ignored or were given the "silent treatment"?',
    },
    {
      id: "FH.76.2",
      extend: "true",
      shortname: "fr35-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR35-1-ADOL1",
      condition: "in",
      depending: "FR35-ADOL1",
      exception: "Ever;Yes",
      link: "FR35-2-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR35-ADOL-CODE",
      subtopic_name:
        'Has any other member of your household been ignored or were given the "silent treatment"?',
    },
    {
      id: "FH.76.3",
      extend: "true",
      shortname: "fr35-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR35-2-ADOL1",
      condition: "in",
      depending: "FR35-ADOL1",
      exception: "Ever;Yes",
      link: "FR35-3-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR35-ADOL-CODE",
      subtopic_name:
        'Has any other member of your household been ignored or were given the "silent treatment"?',
    },
    {
      id: "FH.76.4",
      extend: "true",
      shortname: "fr35-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR35-3-ADOL1",
      condition: "in",
      depending: "FR35-ADOL1",
      exception: "Ever;Yes",
      link: "FR35-C-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR35-ADOL-CODE",
      subtopic_name:
        'Has any other member of your household been ignored or were given the "silent treatment"?',
    },
    {
      id: "FH.76.5",
      extend: "true",
      shortname: "fr35-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR35-C-ADOL1",
      depending: "FR35-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR35-ADOL-CODE",
      subtopic_name:
        'Has any other member of your household been ignored or were given the "silent treatment"?',
    },
    {
      id: "FH.77",
      shortname: "fr36-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR36-ADOL1",
      depending: "",
      exception: "",
      link: "FR36-1-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR36-ADOL-CODE",
      subtopic_name:
        "ran away from home or were placed in an alternative living arrangement?",
    },
    {
      id: "FH.77.1",
      extend: "true",
      shortname: "fr36-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR36-1-ADOL1",
      condition: "in",
      depending: "FR36-ADOL1",
      exception: "Ever;Yes",
      link: "FR36-2-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR36-ADOL-CODE",
      subtopic_name:
        "ran away from home or were placed in an alternative living arrangement?",
    },
    {
      id: "FH.77.2",
      extend: "true",
      shortname: "fr36-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR36-2-ADOL1",
      condition: "in",
      depending: "FR36-ADOL1",
      exception: "Ever;Yes",
      link: "FR36-3-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR36-ADOL-CODE",
      subtopic_name:
        "ran away from home or were placed in an alternative living arrangement?",
    },
    {
      id: "FH.77.3",
      extend: "true",
      shortname: "fr36-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR36-3-ADOL1",
      condition: "in",
      depending: "FR36-ADOL1",
      exception: "Ever;Yes",
      link: "FR36-C-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR36-ADOL-CODE",
      subtopic_name:
        "ran away from home or were placed in an alternative living arrangement?",
    },
    {
      id: "FH.77.4",
      extend: "true",
      shortname: "fr36-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR36-C-ADOL1",
      depending: "FR36-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR36-ADOL-CODE",
      subtopic_name:
        "ran away from home or were placed in an alternative living arrangement?",
    },
    {
      id: "FH.78",
      shortname: "fr37-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR37-ADOL1",
      depending: "",
      exception: "",
      link: "FR37-WHO-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR37-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household run away or placed in an alternative living arrangement?",
    },
    {
      id: "FH.78.1",
      extend: "true",
      shortname: "fr37-who-adol1",
      name: "",
      prevalue: "If yes, who?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR37-WHO-ADOL1",
      condition: "in",
      depending: "FR37-ADOL1",
      exception: "Ever;Yes",
      link: "FR37-1-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR37-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household run away or placed in an alternative living arrangement?",
    },
    {
      id: "FH.78.2",
      extend: "true",
      shortname: "fr37-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR37-1-ADOL1",
      condition: "in",
      depending: "FR37-ADOL1",
      exception: "Ever;Yes",
      link: "FR37-2-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR37-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household run away or placed in an alternative living arrangement?",
    },
    {
      id: "FH.78.3",
      extend: "true",
      shortname: "fr37-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR37-2-ADOL1",
      condition: "in",
      depending: "FR37-ADOL1",
      exception: "Ever;Yes",
      link: "FR37-3-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR37-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household run away or placed in an alternative living arrangement?",
    },
    {
      id: "FH.78.4",
      extend: "true",
      shortname: "fr37-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes", "Don’t know"],
      act: "FR37-3-ADOL1",
      condition: "in",
      depending: "FR37-ADOL1",
      exception: "Ever;Yes",
      link: "FR37-C-ADOL1",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR37-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household run away or placed in an alternative living arrangement?",
    },
    {
      id: "FH.78.5",
      extend: "true",
      shortname: "fr37-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR37-C-ADOL1",
      depending: "FR37-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR22-ADOL-ACT",
      suptopic_code: "FR37-ADOL-CODE",
      subtopic_name:
        "Has any other member of your household run away or placed in an alternative living arrangement?",
    },
    {
      id: "FH.79",
      shortname: "fr38-adol1-subtopic",
      name: "Has there EVER been a significant period of time when ….",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "FR38-ADOL-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "FH.80",
      shortname: "fr38-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR38-ADOL1",
      depending: "",
      exception: "",
      link: "FR38-1-ADOL1",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR38-ADOL-CODE",
      subtopic_name:
        "you lived without heat/electricity/running water, or lived in a place infested with rodents or insects?",
    },
    {
      id: "FH.80.1",
      extend: "true",
      shortname: "fr38-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR38-1-ADOL1",
      condition: "in",
      depending: "FR38-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR38-ADOL-CODE",
      subtopic_name:
        "you lived without heat/electricity/running water, or lived in a place infested with rodents or insects?",
    },
    {
      id: "FH.80.2",
      extend: "true",
      shortname: "fr38-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR38-2-ADOL1",
      condition: "in",
      depending: "FR38-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR38-ADOL-CODE",
      subtopic_name:
        "you lived without heat/electricity/running water, or lived in a place infested with rodents or insects?",
    },
    {
      id: "FH.80.3",
      extend: "true",
      shortname: "fr38-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR38-3-ADOL1",
      condition: "in",
      depending: "FR38-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR38-ADOL-CODE",
      subtopic_name:
        "you lived without heat/electricity/running water, or lived in a place infested with rodents or insects?",
    },
    {
      id: "FH.81",
      shortname: "fr39-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR39-ADOL1",
      depending: "",
      exception: "",
      link: "FR39-1-ADOL1",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR39-ADOL-CODE",
      subtopic_name: "you went without food or were hungry?",
    },
    {
      id: "FH.81.1",
      extend: "true",
      shortname: "fr39-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR39-1-ADOL1",
      condition: "in",
      depending: "FR39-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR39-ADOL-CODE",
      subtopic_name: "you went without food or were hungry?",
    },
    {
      id: "FH.81.2",
      extend: "true",
      shortname: "fr39-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR39-2-ADOL1",
      condition: "in",
      depending: "FR39-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR39-ADOL-CODE",
      subtopic_name: "you went without food or were hungry?",
    },
    {
      id: "FH.81.3",
      extend: "true",
      shortname: "fr39-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR39-3-ADOL1",
      condition: "in",
      depending: "FR39-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR39-ADOL-CODE",
      subtopic_name: "you went without food or were hungry?",
    },
    {
      id: "FH.82",
      shortname: "fr40-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR40-ADOL1",
      depending: "",
      exception: "",
      link: "FR40-1-ADOL1",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR40-ADOL-CODE",
      subtopic_name:
        "your family/household was contacted by the police or an outside agency (e.g., child welfare/CPS) about any family/household disputes?",
    },
    {
      id: "FH.82.1",
      extend: "true",
      shortname: "fr40-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR40-1-ADOL1",
      condition: "in",
      depending: "FR40-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR40-ADOL-CODE",
      subtopic_name:
        "your family/household was contacted by the police or an outside agency (e.g., child welfare/CPS) about any family/household disputes?",
    },
    {
      id: "FH.82.2",
      extend: "true",
      shortname: "fr40-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR40-2-ADOL1",
      condition: "in",
      depending: "FR40-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR40-ADOL-CODE",
      subtopic_name:
        "your family/household was contacted by the police or an outside agency (e.g., child welfare/CPS) about any family/household disputes?",
    },
    {
      id: "FH.82.3",
      extend: "true",
      shortname: "fr40-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR40-3-ADOL1",
      condition: "in",
      depending: "FR40-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR40-ADOL-CODE",
      subtopic_name:
        "your family/household was contacted by the police or an outside agency (e.g., child welfare/CPS) about any family/household disputes?",
    },
    {
      id: "FH.83",
      shortname: "fr41-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR41-ADOL1",
      depending: "",
      exception: "",
      link: "FR41-1-ADOL1",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR41-ADOL-CODE",
      subtopic_name:
        "your parent(s)/guardian(s) did not listen to what you had to say, what you said was unimportant, your opinion did not matter or you were never taken seriously?",
    },
    {
      id: "FH.83.1",
      extend: "true",
      shortname: "fr41-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR41-1-ADOL1",
      condition: "in",
      depending: "FR41-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR41-ADOL-CODE",
      subtopic_name:
        "your parent(s)/guardian(s) did not listen to what you had to say, what you said was unimportant, your opinion did not matter or you were never taken seriously?",
    },
    {
      id: "FH.83.2",
      extend: "true",
      shortname: "fr41-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR41-2-ADOL1",
      condition: "in",
      depending: "FR41-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR41-ADOL-CODE",
      subtopic_name:
        "your parent(s)/guardian(s) did not listen to what you had to say, what you said was unimportant, your opinion did not matter or you were never taken seriously?",
    },
    {
      id: "FH.83.3",
      extend: "true",
      shortname: "fr41-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR41-3-ADOL1",
      condition: "in",
      depending: "FR41-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR41-ADOL-CODE",
      subtopic_name:
        "your parent(s)/guardian(s) did not listen to what you had to say, what you said was unimportant, your opinion did not matter or you were never taken seriously?",
    },
    {
      id: "FH.84",
      shortname: "fr42-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR42-ADOL1",
      depending: "",
      exception: "",
      link: "FR42-1-ADOL1",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR42-ADOL-CODE",
      subtopic_name:
        "you felt that your parent(s)/guardian(s) were unavailable to you, that they were not there when you needed them?",
    },
    {
      id: "FH.84.1",
      extend: "true",
      shortname: "fr42-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR42-1-ADOL1",
      condition: "in",
      depending: "FR42-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR42-ADOL-CODE",
      subtopic_name:
        "you felt that your parent(s)/guardian(s) were unavailable to you, that they were not there when you needed them?",
    },
    {
      id: "FH.84.2",
      extend: "true",
      shortname: "fr42-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR42-2-ADOL1",
      condition: "in",
      depending: "FR42-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR42-ADOL-CODE",
      subtopic_name:
        "you felt that your parent(s)/guardian(s) were unavailable to you, that they were not there when you needed them?",
    },
    {
      id: "FH.84.3",
      extend: "true",
      shortname: "fr42-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR42-3-ADOL1",
      condition: "in",
      depending: "FR42-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR42-ADOL-CODE",
      subtopic_name:
        "you felt that your parent(s)/guardian(s) were unavailable to you, that they were not there when you needed them?",
    },
    {
      id: "FH.85",
      shortname: "fr43-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR43-ADOL1",
      depending: "",
      exception: "",
      link: "FR43-1-ADOL1",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR43-ADOL-CODE",
      subtopic_name:
        "you contributed to or took responsibility for your family (e.g., provided income, consistently supervised/raised a sibling)?",
    },
    {
      id: "FH.85.1",
      extend: "true",
      shortname: "fr43-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR43-1-ADOL1",
      condition: "in",
      depending: "FR43-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR43-ADOL-CODE",
      subtopic_name:
        "you contributed to or took responsibility for your family (e.g., provided income, consistently supervised/raised a sibling)?",
    },
    {
      id: "FH.85.2",
      extend: "true",
      shortname: "fr43-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR43-2-ADOL1",
      condition: "in",
      depending: "FR43-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR43-ADOL-CODE",
      subtopic_name:
        "you contributed to or took responsibility for your family (e.g., provided income, consistently supervised/raised a sibling)?",
    },
    {
      id: "FH.85.3",
      extend: "true",
      shortname: "fr43-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR43-3-ADOL1",
      condition: "in",
      depending: "FR43-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR43-ADOL-CODE",
      subtopic_name:
        "you contributed to or took responsibility for your family (e.g., provided income, consistently supervised/raised a sibling)?",
    },
    {
      id: "FH.86",
      shortname: "fr44-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR44-ADOL1",
      depending: "",
      exception: "",
      link: "FR44-1-ADOL1",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR44-ADOL-CODE",
      subtopic_name:
        "there were no rules, structure, or supervision (e.g., you were given as much freedom as you wanted, allowed to go out as often as you wanted), your parent(s)/guardian(s) weren't home a lot and/or didn't know where you were?",
    },
    {
      id: "FH.86.1",
      extend: "true",
      shortname: "fr44-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR44-1-ADOL1",
      condition: "in",
      depending: "FR44-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR44-ADOL-CODE",
      subtopic_name:
        "there were no rules, structure, or supervision (e.g., you were given as much freedom as you wanted, allowed to go out as often as you wanted), your parent(s)/guardian(s) weren't home a lot and/or didn't know where you were?",
    },
    {
      id: "FH.86.2",
      extend: "true",
      shortname: "fr44-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR44-2-ADOL1",
      condition: "in",
      depending: "FR44-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR44-ADOL-CODE",
      subtopic_name:
        "there were no rules, structure, or supervision (e.g., you were given as much freedom as you wanted, allowed to go out as often as you wanted), your parent(s)/guardian(s) weren't home a lot and/or didn't know where you were?",
    },
    {
      id: "FH.86.3",
      extend: "true",
      shortname: "fr44-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR44-3-ADOL1",
      condition: "in",
      depending: "FR44-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR44-ADOL-CODE",
      subtopic_name:
        "there were no rules, structure, or supervision (e.g., you were given as much freedom as you wanted, allowed to go out as often as you wanted), your parent(s)/guardian(s) weren't home a lot and/or didn't know where you were?",
    },
    {
      id: "FH.87",
      shortname: "fr45-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR45-ADOL1",
      depending: "",
      exception: "",
      link: "FR45-1-ADOL1",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR45-ADOL-CODE",
      subtopic_name:
        "your parent(s)/guardian(s) covered for you (e.g., made excuses to school so that you would not get into trouble), did not tell you other parent/guardian about something that you did?",
    },
    {
      id: "FH.87.1",
      extend: "true",
      shortname: "fr45-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR45-1-ADOL1",
      condition: "in",
      depending: "FR45-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR45-ADOL-CODE",
      subtopic_name:
        "your parent(s)/guardian(s) covered for you (e.g., made excuses to school so that you would not get into trouble), did not tell you other parent/guardian about something that you did?",
    },
    {
      id: "FH.87.2",
      extend: "true",
      shortname: "fr45-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR45-2-ADOL1",
      condition: "in",
      depending: "FR45-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR45-ADOL-CODE",
      subtopic_name:
        "your parent(s)/guardian(s) covered for you (e.g., made excuses to school so that you would not get into trouble), did not tell you other parent/guardian about something that you did?",
    },
    {
      id: "FH.87.3",
      extend: "true",
      shortname: "fr45-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR45-3-ADOL1",
      condition: "in",
      depending: "FR45-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR45-ADOL-CODE",
      subtopic_name:
        "your parent(s)/guardian(s) covered for you (e.g., made excuses to school so that you would not get into trouble), did not tell you other parent/guardian about something that you did?",
    },
    {
      id: "FH.88",
      shortname: "fr46-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR46-ADOL1",
      depending: "",
      exception: "",
      link: "FR46-1-ADOL1",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR46-ADOL-CODE",
      subtopic_name:
        "rules were not consistently enforced, there was generally no follow through or consequences if you broke the rules, you received mixed messages?",
    },
    {
      id: "FH.88.1",
      extend: "true",
      shortname: "fr46-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR46-1-ADOL1",
      condition: "in",
      depending: "FR46-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR46-ADOL-CODE",
      subtopic_name:
        "rules were not consistently enforced, there was generally no follow through or consequences if you broke the rules, you received mixed messages?",
    },
    {
      id: "FH.88.2",
      extend: "true",
      shortname: "fr46-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR46-2-ADOL1",
      condition: "in",
      depending: "FR46-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR46-ADOL-CODE",
      subtopic_name:
        "rules were not consistently enforced, there was generally no follow through or consequences if you broke the rules, you received mixed messages?",
    },
    {
      id: "FH.88.3",
      extend: "true",
      shortname: "fr46-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR46-3-ADOL1",
      condition: "in",
      depending: "FR46-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR46-ADOL-CODE",
      subtopic_name:
        "rules were not consistently enforced, there was generally no follow through or consequences if you broke the rules, you received mixed messages?",
    },
    {
      id: "FH.89",
      shortname: "fr47-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR47-ADOL1",
      depending: "",
      exception: "",
      link: "FR47-1-ADOL1",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR47-ADOL-CODE",
      subtopic_name:
        "your parent(s)/guardian(s) disagreed on how to handle you, disagreed on limits, disagreed on consequences?",
    },
    {
      id: "FH.89.1",
      extend: "true",
      shortname: "fr47-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR47-1-ADOL1",
      condition: "in",
      depending: "FR47-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR47-ADOL-CODE",
      subtopic_name:
        "your parent(s)/guardian(s) disagreed on how to handle you, disagreed on limits, disagreed on consequences?",
    },
    {
      id: "FH.89.2",
      extend: "true",
      shortname: "fr47-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR47-2-ADOL1",
      condition: "in",
      depending: "FR47-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR47-ADOL-CODE",
      subtopic_name:
        "your parent(s)/guardian(s) disagreed on how to handle you, disagreed on limits, disagreed on consequences?",
    },
    {
      id: "FH.89.3",
      extend: "true",
      shortname: "fr47-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR47-3-ADOL1",
      condition: "in",
      depending: "FR47-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR47-ADOL-CODE",
      subtopic_name:
        "your parent(s)/guardian(s) disagreed on how to handle you, disagreed on limits, disagreed on consequences?",
    },
    {
      id: "FH.90",
      shortname: "fr48-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR48-ADOL1",
      depending: "",
      exception: "",
      link: "FR48-1-ADOL1",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR48-ADOL-CODE",
      subtopic_name:
        "you could not stand, really hated or loathed someone in your household?",
    },
    {
      id: "FH.90.1",
      extend: "true",
      shortname: "fr48-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR48-1-ADOL1",
      condition: "in",
      depending: "FR48-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR48-ADOL-CODE",
      subtopic_name:
        "you could not stand, really hated or loathed someone in your household?",
    },
    {
      id: "FH.90.2",
      extend: "true",
      shortname: "fr48-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR48-2-ADOL1",
      condition: "in",
      depending: "FR48-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR48-ADOL-CODE",
      subtopic_name:
        "you could not stand, really hated or loathed someone in your household?",
    },
    {
      id: "FH.90.3",
      extend: "true",
      shortname: "fr48-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR48-3-ADOL1",
      condition: "in",
      depending: "FR48-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR48-ADOL-CODE",
      subtopic_name:
        "you could not stand, really hated or loathed someone in your household?",
    },
    {
      id: "FH.91",
      shortname: "fr49-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR49-ADOL1",
      depending: "",
      exception: "",
      link: "FR49-1-ADOL1",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR49-ADOL-CODE",
      subtopic_name:
        "your family received SCOH, Wrap-Around, or other child protective services?",
    },
    {
      id: "FH.91.1",
      extend: "true",
      shortname: "fr49-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR49-1-ADOL1",
      condition: "in",
      depending: "FR49-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR49-ADOL-CODE",
      subtopic_name:
        "your family received SCOH, Wrap-Around, or other child protective services?",
    },
    {
      id: "FH.91.2",
      extend: "true",
      shortname: "fr49-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR49-2-ADOL1",
      condition: "in",
      depending: "FR49-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR49-ADOL-CODE",
      subtopic_name:
        "your family received SCOH, Wrap-Around, or other child protective services?",
    },
    {
      id: "FH.91.3",
      extend: "true",
      shortname: "fr49-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR49-3-ADOL1",
      condition: "in",
      depending: "FR49-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR49-ADOL-CODE",
      subtopic_name:
        "your family received SCOH, Wrap-Around, or other child protective services?",
    },
    {
      id: "FH.92",
      shortname: "fr50-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR50-ADOL1",
      depending: "",
      exception: "",
      link: "FR50-1-ADOL1",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR50-ADOL-CODE",
      subtopic_name:
        "your family/household members participated in counseling/family therapy?",
    },
    {
      id: "FH.92.1",
      extend: "true",
      shortname: "fr50-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR50-1-ADOL1",
      condition: "in",
      depending: "FR50-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR50-ADOL-CODE",
      subtopic_name:
        "your family/household members participated in counseling/family therapy?",
    },
    {
      id: "FH.92.2",
      extend: "true",
      shortname: "fr50-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR50-2-ADOL1",
      condition: "in",
      depending: "FR50-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR50-ADOL-CODE",
      subtopic_name:
        "your family/household members participated in counseling/family therapy?",
    },
    {
      id: "FH.92.3",
      extend: "true",
      shortname: "fr50-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR50-3-ADOL1",
      condition: "in",
      depending: "FR50-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR50-ADOL-CODE",
      subtopic_name:
        "your family/household members participated in counseling/family therapy?",
    },
    {
      id: "FH.93",
      shortname: "fr51-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR51-ADOL1",
      depending: "",
      exception: "",
      link: "FR51-2-ADOL1",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR51-ADOL-CODE",
      subtopic_name: "home felt like a safe place?",
    },
    {
      id: "FH.93.1",
      extend: "true",
      shortname: "fr51-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR51-2-ADOL1",
      condition: "in",
      depending: "FR51-ADOL1",
      exception: "Ever;No",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR51-ADOL-CODE",
      subtopic_name: "home felt like a safe place?",
    },
    {
      id: "FH.93.2",
      extend: "true",
      shortname: "fr51-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR51-3-ADOL1",
      condition: "in",
      depending: "FR51-ADOL1",
      exception: "Ever;No",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR51-ADOL-CODE",
      subtopic_name: "home felt like a safe place?",
    },
    {
      id: "FH.94",
      shortname: "fr52-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR52-ADOL1",
      depending: "",
      exception: "",
      link: "FR52-2-ADOL1",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR52-ADOL-CODE",
      subtopic_name: "you felt loved by someone in your household?",
    },
    {
      id: "FH.94.1",
      extend: "true",
      shortname: "fr52-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR52-2-ADOL1",
      condition: "in",
      depending: "FR52-ADOL1",
      exception: "Ever;No",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR52-ADOL-CODE",
      subtopic_name: "you felt loved by someone in your household? ",
    },
    {
      id: "FH.94.2",
      extend: "true",
      shortname: "fr52-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR52-3-ADOL1",
      condition: "in",
      depending: "FR52-ADOL1",
      exception: "Ever;No",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR52-ADOL-CODE",
      subtopic_name: "you felt loved by someone in your household? ",
    },
    {
      id: "FH.95",
      shortname: "fr53-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR53-ADOL1",
      depending: "",
      exception: "",
      link: "FR53-2-ADOL1",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR53-ADOL-CODE",
      subtopic_name: "you felt like someone in the household believed in you?",
    },
    {
      id: "FH.95.1",
      extend: "true",
      shortname: "fr53-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR53-2-ADOL1",
      condition: "in",
      depending: "FR53-ADOL1",
      exception: "Ever;No",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR53-ADOL-CODE",
      subtopic_name: "you felt like someone in the household believed in you?",
    },
    {
      id: "FH.95.2",
      extend: "true",
      shortname: "fr53-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR53-3-ADOL1",
      condition: "in",
      depending: "FR53-ADOL1",
      exception: "Ever;No",
      subtopic_container_act: "FR38-ADOL-ACT",
      suptopic_code: "FR53-ADOL-CODE",
      subtopic_name: "you felt like someone in the household believed in you?",
    },
    {
      id: "FH.96",
      shortname: "fr54-adol1-subtopic",
      name: "Has anyone in your household EVER...",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "FR54-ADOL-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "FH.97",
      shortname: "fr54-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological father"],
      act: "FR54-ADOL1",
      depending: "",
      exception: "",
      link: "FR54-1-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR54-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.97.1",
      extend: "true",
      shortname: "fr54-1-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR54-1-ADOL1",
      condition: "in",
      depending: "FR54-ADOL1",
      exception: "Biological father",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR54-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.98",
      shortname: "fr54-2-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological mother"],
      act: "FR54-2-ADOL1",
      depending: "",
      exception: "",
      link: "FR54-3-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR54-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.98.1",
      extend: "true",
      shortname: "fr54-3-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR54-3-ADOL1",
      condition: "in",
      depending: "FR54-2-ADOL1",
      exception: "Biological mother",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR54-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.99",
      shortname: "fr54-6-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Legal guardian"],
      act: "FR54-6-ADOL1",
      depending: "",
      exception: "",
      link: "FR54-7-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR54-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.99.1",
      extend: "true",
      shortname: "fr54-7-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR54-7-ADOL1",
      condition: "in",
      depending: "FR54-6-ADOL1",
      exception: "Legal guardian",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR54-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.100",
      shortname: "fr54-8-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Brothers/Sisters"],
      act: "FR54-8-ADOL1",
      depending: "",
      exception: "",
      link: "FR54-9-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR54-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.100.1",
      extend: "true",
      shortname: "fr54-9-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR54-9-ADOL1",
      condition: "in",
      depending: "FR54-8-ADOL1",
      exception: "Brothers/Sisters",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR54-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.101",
      shortname: "fr54-10-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other relative"],
      act: "FR54-10-ADOL1",
      depending: "",
      exception: "",
      link: "FR54-11-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR54-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.101.1",
      extend: "true",
      shortname: "fr54-11-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR54-11-ADOL1",
      condition: "in",
      depending: "FR54-10-ADOL1",
      exception: "Other relative",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR54-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.102",
      shortname: "fr54-12-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Household Member (non-relative)"],
      act: "FR54-12-ADOL1",
      depending: "",
      exception: "",
      link: "FR54-13-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR54-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.102.1",
      extend: "true",
      shortname: "fr54-13-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR54-13-ADOL1",
      condition: "in",
      depending: "FR54-12-ADOL1",
      exception: "Household Member (non-relative)",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR54-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.103",
      shortname: "fr55-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological father"],
      act: "FR55-ADOL1",
      depending: "",
      exception: "",
      link: "FR55-1-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR55-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.103.1",
      extend: "true",
      shortname: "fr55-1-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR55-1-ADOL1",
      condition: "in",
      depending: "FR55-ADOL1",
      exception: "Biological father",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR55-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.104",
      shortname: "fr55-2-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological mother"],
      act: "FR55-2-ADOL1",
      depending: "",
      exception: "",
      link: "FR55-3-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR55-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.104.1",
      extend: "true",
      shortname: "fr55-3-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR55-3-ADOL1",
      condition: "in",
      depending: "FR55-2-ADOL1",
      exception: "Biological mother",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR55-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.105",
      shortname: "fr55-6-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Legal guardian"],
      act: "FR55-6-ADOL1",
      depending: "",
      exception: "",
      link: "FR55-7-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR55-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.105.1",
      extend: "true",
      shortname: "fr55-7-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR55-7-ADOL1",
      condition: "in",
      depending: "FR55-6-ADOL1",
      exception: "Legal guardian",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR55-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.106",
      shortname: "fr55-8-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Brothers/Sisters"],
      act: "FR55-8-ADOL1",
      depending: "",
      exception: "",
      link: "FR55-9-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR55-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.106.1",
      extend: "true",
      shortname: "fr55-9-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR55-9-ADOL1",
      condition: "in",
      depending: "FR55-8-ADOL1",
      exception: "Brothers/Sisters",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR55-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.107",
      shortname: "fr55-10-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other relative"],
      act: "FR55-10-ADOL1",
      depending: "",
      exception: "",
      link: "FR55-11-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR55-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.107.1",
      extend: "true",
      shortname: "fr55-11-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR55-11-ADOL1",
      condition: "in",
      depending: "FR55-10-ADOL1",
      exception: "Other relative",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR55-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.108",
      shortname: "fr55-12-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Household Member (non-relative)"],
      act: "FR55-12-ADOL1",
      depending: "",
      exception: "",
      link: "FR55-13-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR55-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.108.1",
      extend: "true",
      shortname: "fr55-13-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR55-13-ADOL1",
      condition: "in",
      depending: "FR55-12-ADOL1",
      exception: "Household Member (non-relative)",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR55-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.109",
      shortname: "fr56-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological father"],
      act: "FR56-ADOL1",
      depending: "",
      exception: "",
      link: "FR56-1-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR56-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.109.1",
      extend: "true",
      shortname: "fr56-1-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR56-1-ADOL1",
      condition: "in",
      depending: "FR56-ADOL1",
      exception: "Biological father",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR56-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.110",
      shortname: "fr56-2-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological mother"],
      act: "FR56-2-ADOL1",
      depending: "",
      exception: "",
      link: "FR56-3-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR56-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.110.1",
      extend: "true",
      shortname: "fr56-3-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR56-3-ADOL1",
      condition: "in",
      depending: "FR56-2-ADOL1",
      exception: "Biological mother",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR56-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.111",
      shortname: "fr56-6-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Legal guardian"],
      act: "FR56-6-ADOL1",
      depending: "",
      exception: "",
      link: "FR56-7-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR56-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.111.1",
      extend: "true",
      shortname: "fr56-7-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR56-7-ADOL1",
      condition: "in",
      depending: "FR56-6-ADOL1",
      exception: "Legal guardian",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR56-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.112",
      shortname: "fr56-8-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Brothers/Sisters"],
      act: "FR56-8-ADOL1",
      depending: "",
      exception: "",
      link: "FR56-9-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR56-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.112.1",
      extend: "true",
      shortname: "fr56-9-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR56-9-ADOL1",
      condition: "in",
      depending: "FR56-8-ADOL1",
      exception: "Brothers/Sisters",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR56-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.113",
      shortname: "fr56-10-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other relative"],
      act: "FR56-10-ADOL1",
      depending: "",
      exception: "",
      link: "FR56-11-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR56-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.113.1",
      extend: "true",
      shortname: "fr56-11-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR56-11-ADOL1",
      condition: "in",
      depending: "FR56-10-ADOL1",
      exception: "Other relative",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR56-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.114",
      shortname: "fr56-12-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Household Member (non-relative)"],
      act: "FR56-12-ADOL1",
      depending: "",
      exception: "",
      link: "FR56-13-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR56-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.114.1",
      extend: "true",
      shortname: "fr56-13-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR56-13-ADOL1",
      condition: "in",
      depending: "FR56-12-ADOL1",
      exception: "Household Member (non-relative)",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR56-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.115",
      shortname: "fr57-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological father"],
      act: "FR57-ADOL1",
      depending: "",
      exception: "",
      link: "FR57-1-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR57-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.115.1",
      extend: "true",
      shortname: "fr57-1-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR57-1-ADOL1",
      condition: "in",
      depending: "FR57-ADOL1",
      exception: "Biological father",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR57-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.116",
      shortname: "fr57-2-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological mother"],
      act: "FR57-2-ADOL1",
      depending: "",
      exception: "",
      link: "FR57-3-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR57-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.116.1",
      extend: "true",
      shortname: "fr57-3-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR57-3-ADOL1",
      condition: "in",
      depending: "FR57-2-ADOL1",
      exception: "Biological mother",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR57-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.117",
      shortname: "fr57-6-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Legal guardian"],
      act: "FR57-6-ADOL1",
      depending: "",
      exception: "",
      link: "FR57-7-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR57-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.117.1",
      extend: "true",
      shortname: "fr57-7-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR57-7-ADOL1",
      condition: "in",
      depending: "FR57-6-ADOL1",
      exception: "Legal guardian",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR57-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.118",
      shortname: "fr57-8-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Brothers/Sisters"],
      act: "FR57-8-ADOL1",
      depending: "",
      exception: "",
      link: "FR57-9-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR57-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.118.1",
      extend: "true",
      shortname: "fr57-9-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR57-9-ADOL1",
      condition: "in",
      depending: "FR57-8-ADOL1",
      exception: "Brothers/Sisters",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR57-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.119",
      shortname: "fr57-10-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other relative"],
      act: "FR57-10-ADOL1",
      depending: "",
      exception: "",
      link: "FR57-11-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR57-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.119.1",
      extend: "true",
      shortname: "fr57-11-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR57-11-ADOL1",
      condition: "in",
      depending: "FR57-10-ADOL1",
      exception: "Other relative",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR57-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.120",
      shortname: "fr57-12-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Household Member (non-relative)"],
      act: "FR57-12-ADOL1",
      depending: "",
      exception: "",
      link: "FR57-13-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR57-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.120.1",
      extend: "true",
      shortname: "fr57-13-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR57-13-ADOL1",
      condition: "in",
      depending: "FR57-12-ADOL1",
      exception: "Household Member (non-relative)",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR57-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.121",
      shortname: "fr58-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological father"],
      act: "FR58-ADOL1",
      depending: "",
      exception: "",
      link: "FR58-1-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR58-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.121.1",
      extend: "true",
      shortname: "fr58-1-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR58-1-ADOL1",
      condition: "in",
      depending: "FR58-ADOL1",
      exception: "Biological father",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR58-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.122",
      shortname: "fr58-2-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological mother"],
      act: "FR58-2-ADOL1",
      depending: "",
      exception: "",
      link: "FR58-3-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR58-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.122.1",
      extend: "true",
      shortname: "fr58-3-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR58-3-ADOL1",
      condition: "in",
      depending: "FR58-2-ADOL1",
      exception: "Biological mother",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR58-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.123",
      shortname: "fr58-6-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Legal guardian"],
      act: "FR58-6-ADOL1",
      depending: "",
      exception: "",
      link: "FR58-7-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR58-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.123.1",
      extend: "true",
      shortname: "fr58-7-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR58-7-ADOL1",
      condition: "in",
      depending: "FR58-6-ADOL1",
      exception: "Legal guardian",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR58-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.124",
      shortname: "fr58-8-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Brothers/Sisters"],
      act: "FR58-8-ADOL1",
      depending: "",
      exception: "",
      link: "FR58-9-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR58-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.124.1",
      extend: "true",
      shortname: "fr58-9-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR58-9-ADOL1",
      condition: "in",
      depending: "FR58-8-ADOL1",
      exception: "Brothers/Sisters",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR58-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.125",
      shortname: "fr58-10-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other relative"],
      act: "FR58-10-ADOL1",
      depending: "",
      exception: "",
      link: "FR58-11-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR58-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.125.1",
      extend: "true",
      shortname: "fr58-11-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR58-11-ADOL1",
      condition: "in",
      depending: "FR58-10-ADOL1",
      exception: "Other relative",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR58-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.126",
      shortname: "fr58-12-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Household Member (non-relative)"],
      act: "FR58-12-ADOL1",
      depending: "",
      exception: "",
      link: "FR58-13-ADOL1",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR58-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.126.1",
      extend: "true",
      shortname: "fr58-13-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR58-13-ADOL1",
      condition: "in",
      depending: "FR58-12-ADOL1",
      exception: "Household Member (non-relative)",
      subtopic_container_act: "FR54-ADOL-ACT",
      suptopic_code: "FR58-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.127",
      shortname: "fr54-adol1-subtopic",
      name: "DURING THE PAST 30 DAYS has anyone in your household...",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "FR59-ADOL-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "FH.128",
      shortname: "fr59-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological father"],
      act: "FR59-ADOL1",
      depending: "",
      exception: "",
      link: "FR59-1-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR59-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.128.1",
      extend: "true",
      shortname: "fr59-1-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR59-1-ADOL1",
      condition: "in",
      depending: "FR59-ADOL1",
      exception: "Biological father",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR59-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.129",
      shortname: "fr59-2-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological mother"],
      act: "FR59-2-ADOL1",
      depending: "",
      exception: "",
      link: "FR59-3-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR59-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.129.1",
      extend: "true",
      shortname: "fr59-3-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR59-3-ADOL1",
      condition: "in",
      depending: "FR59-2-ADOL1",
      exception: "Biological mother",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR59-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.130",
      shortname: "fr59-6-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Legal guardian"],
      act: "FR59-6-ADOL1",
      depending: "",
      exception: "",
      link: "FR59-7-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR59-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.130.1",
      extend: "true",
      shortname: "fr59-7-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR59-7-ADOL1",
      condition: "in",
      depending: "FR59-6-ADOL1",
      exception: "Legal guardian",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR59-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.131",
      shortname: "fr59-8-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Brothers/Sisters"],
      act: "FR59-8-ADOL1",
      depending: "",
      exception: "",
      link: "FR59-9-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR59-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.131.1",
      extend: "true",
      shortname: "fr59-9-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR59-9-ADOL1",
      condition: "in",
      depending: "FR59-8-ADOL1",
      exception: "Brothers/Sisters",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR59-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.132",
      shortname: "fr59-10-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other relative"],
      act: "FR59-10-ADOL1",
      depending: "",
      exception: "",
      link: "FR59-11-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR59-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.132.1",
      extend: "true",
      shortname: "fr59-11-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR59-11-ADOL1",
      condition: "in",
      depending: "FR59-10-ADOL1",
      exception: "Other relative",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR59-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.133",
      shortname: "fr59-12-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Household Member (non-relative)"],
      act: "FR59-12-ADOL1",
      depending: "",
      exception: "",
      link: "FR59-13-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR59-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.133.1",
      extend: "true",
      shortname: "fr59-13-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR59-13-ADOL1",
      condition: "in",
      depending: "FR59-12-ADOL1",
      exception: "Household Member (non-relative)",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR59-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly?",
    },
    {
      id: "FH.134",
      shortname: "fr60-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological father"],
      act: "FR60-ADOL1",
      depending: "",
      exception: "",
      link: "FR60-1-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR60-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.134.1",
      extend: "true",
      shortname: "fr60-1-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR60-1-ADOL1",
      condition: "in",
      depending: "FR60-ADOL1",
      exception: "Biological father",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR60-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.135",
      shortname: "fr60-2-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological mother"],
      act: "FR60-2-ADOL1",
      depending: "",
      exception: "",
      link: "FR60-3-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR60-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.135.1",
      extend: "true",
      shortname: "fr60-3-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR60-3-ADOL1",
      condition: "in",
      depending: "FR60-2-ADOL1",
      exception: "Biological mother",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR60-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.136",
      shortname: "fr60-6-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Legal guardian"],
      act: "FR60-6-ADOL1",
      depending: "",
      exception: "",
      link: "FR60-7-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR60-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.136.1",
      extend: "true",
      shortname: "fr60-7-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR60-7-ADOL1",
      condition: "in",
      depending: "FR60-6-ADOL1",
      exception: "Legal guardian",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR60-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.137",
      shortname: "fr60-8-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Brothers/Sisters"],
      act: "FR60-8-ADOL1",
      depending: "",
      exception: "",
      link: "FR60-9-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR60-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.137.1",
      extend: "true",
      shortname: "fr60-9-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR60-9-ADOL1",
      condition: "in",
      depending: "FR60-8-ADOL1",
      exception: "Brothers/Sisters",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR60-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.138",
      shortname: "fr60-10-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other relative"],
      act: "FR60-10-ADOL1",
      depending: "",
      exception: "",
      link: "FR60-11-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR60-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.138.1",
      extend: "true",
      shortname: "fr60-11-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR60-11-ADOL1",
      condition: "in",
      depending: "FR60-10-ADOL1",
      exception: "Other relative",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR60-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.139",
      shortname: "fr60-12-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Household Member (non-relative)"],
      act: "FR60-12-ADOL1",
      depending: "",
      exception: "",
      link: "FR60-13-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR60-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.139.1",
      extend: "true",
      shortname: "fr60-13-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR60-13-ADOL1",
      condition: "in",
      depending: "FR60-12-ADOL1",
      exception: "Household Member (non-relative)",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR60-ADOL-CODE",
      subtopic_name: "successfully stopped using drugs, drinking alcohol?",
    },
    {
      id: "FH.140",
      shortname: "fr61-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological father"],
      act: "FR61-ADOL1",
      depending: "",
      exception: "",
      link: "FR61-1-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR61-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.140.1",
      extend: "true",
      shortname: "fr61-1-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR61-1-ADOL1",
      condition: "in",
      depending: "FR61-ADOL1",
      exception: "Biological father",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR61-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.141",
      shortname: "fr61-2-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological mother"],
      act: "FR61-2-ADOL1",
      depending: "",
      exception: "",
      link: "FR61-3-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR61-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.141.1",
      extend: "true",
      shortname: "fr61-3-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR61-3-ADOL1",
      condition: "in",
      depending: "FR61-2-ADOL1",
      exception: "Biological mother",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR61-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.142",
      shortname: "fr61-6-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Legal guardian"],
      act: "FR61-6-ADOL1",
      depending: "",
      exception: "",
      link: "FR61-7-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR61-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.142.1",
      extend: "true",
      shortname: "fr61-7-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR61-7-ADOL1",
      condition: "in",
      depending: "FR61-6-ADOL1",
      exception: "Legal guardian",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR61-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.143",
      shortname: "fr61-8-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Brothers/Sisters"],
      act: "FR61-8-ADOL1",
      depending: "",
      exception: "",
      link: "FR61-9-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR61-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.143.1",
      extend: "true",
      shortname: "fr61-9-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR61-9-ADOL1",
      condition: "in",
      depending: "FR61-8-ADOL1",
      exception: "Brothers/Sisters",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR61-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.144",
      shortname: "fr61-10-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other relative"],
      act: "FR61-10-ADOL1",
      depending: "",
      exception: "",
      link: "FR61-11-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR61-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.144.1",
      extend: "true",
      shortname: "fr61-11-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR61-11-ADOL1",
      condition: "in",
      depending: "FR61-10-ADOL1",
      exception: "Other relative",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR61-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.145",
      shortname: "fr61-12-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Household Member (non-relative)"],
      act: "FR61-12-ADOL1",
      depending: "",
      exception: "",
      link: "FR61-13-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR61-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.145.1",
      extend: "true",
      shortname: "fr61-13-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR61-13-ADOL1",
      condition: "in",
      depending: "FR61-12-ADOL1",
      exception: "Household Member (non-relative)",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR61-ADOL-CODE",
      subtopic_name: "had emotional, mental health, or psychiatric problems?",
    },
    {
      id: "FH.146",
      shortname: "fr62-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological father"],
      act: "FR62-ADOL1",
      depending: "",
      exception: "",
      link: "FR62-1-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR62-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.146.1",
      extend: "true",
      shortname: "fr62-1-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR62-1-ADOL1",
      condition: "in",
      depending: "FR62-ADOL1",
      exception: "Biological father",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR62-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.147",
      shortname: "fr62-2-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological mother"],
      act: "FR62-2-ADOL1",
      depending: "",
      exception: "",
      link: "FR62-3-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR62-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.147.1",
      extend: "true",
      shortname: "fr62-3-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR62-3-ADOL1",
      condition: "in",
      depending: "FR62-2-ADOL1",
      exception: "Biological mother",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR62-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.148",
      shortname: "fr62-6-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Legal guardian"],
      act: "FR62-6-ADOL1",
      depending: "",
      exception: "",
      link: "FR62-7-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR62-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.148.1",
      extend: "true",
      shortname: "fr62-7-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR62-7-ADOL1",
      condition: "in",
      depending: "FR62-6-ADOL1",
      exception: "Legal guardian",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR62-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.149",
      shortname: "fr62-8-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Brothers/Sisters"],
      act: "FR62-8-ADOL1",
      depending: "",
      exception: "",
      link: "FR62-9-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR62-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.149.1",
      extend: "true",
      shortname: "fr62-9-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR62-9-ADOL1",
      condition: "in",
      depending: "FR62-8-ADOL1",
      exception: "Brothers/Sisters",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR62-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.150",
      shortname: "fr62-10-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other relative"],
      act: "FR62-10-ADOL1",
      depending: "",
      exception: "",
      link: "FR62-11-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR62-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.150.1",
      extend: "true",
      shortname: "fr62-11-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR62-11-ADOL1",
      condition: "in",
      depending: "FR62-10-ADOL1",
      exception: "Other relative",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR62-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.151",
      shortname: "fr62-12-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Household Member (non-relative)"],
      act: "FR62-12-ADOL1",
      depending: "",
      exception: "",
      link: "FR62-13-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR62-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.151.1",
      extend: "true",
      shortname: "fr62-13-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR62-13-ADOL1",
      condition: "in",
      depending: "FR62-12-ADOL1",
      exception: "Household Member (non-relative)",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR62-ADOL-CODE",
      subtopic_name:
        "engaged in illegal activities, were in trouble with the law?",
    },
    {
      id: "FH.152",
      shortname: "fr63-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological father"],
      act: "FR63-ADOL1",
      depending: "",
      exception: "",
      link: "FR63-1-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR63-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.152.1",
      extend: "true",
      shortname: "fr63-1-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR63-1-ADOL1",
      condition: "in",
      depending: "FR63-ADOL1",
      exception: "Biological father",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR63-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.153",
      shortname: "fr63-2-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Biological mother"],
      act: "FR63-2-ADOL1",
      depending: "",
      exception: "",
      link: "FR63-3-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR63-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.153.1",
      extend: "true",
      shortname: "fr63-3-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR63-3-ADOL1",
      condition: "in",
      depending: "FR63-2-ADOL1",
      exception: "Biological mother",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR63-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.154",
      shortname: "fr63-6-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Legal guardian"],
      act: "FR63-6-ADOL1",
      depending: "",
      exception: "",
      link: "FR63-7-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR63-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.154.1",
      extend: "true",
      shortname: "fr63-7-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR63-7-ADOL1",
      condition: "in",
      depending: "FR63-6-ADOL1",
      exception: "Legal guardian",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR63-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.155",
      shortname: "fr63-8-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Brothers/Sisters"],
      act: "FR63-8-ADOL1",
      depending: "",
      exception: "",
      link: "FR63-9-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR63-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.155.1",
      extend: "true",
      shortname: "fr63-9-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR63-9-ADOL1",
      condition: "in",
      depending: "FR63-8-ADOL1",
      exception: "Brothers/Sisters",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR63-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.156",
      shortname: "fr63-10-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other relative"],
      act: "FR63-10-ADOL1",
      depending: "",
      exception: "",
      link: "FR63-11-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR63-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.156.1",
      extend: "true",
      shortname: "fr63-11-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR63-11-ADOL1",
      condition: "in",
      depending: "FR63-10-ADOL1",
      exception: "Other relative",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR63-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.157",
      shortname: "fr63-12-adol1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Household Member (non-relative)"],
      act: "FR63-12-ADOL1",
      depending: "",
      exception: "",
      link: "FR63-13-ADOL1",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR63-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.157.1",
      extend: "true",
      shortname: "fr63-13-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes, live-in", "Yes, non live-in"],
      act: "FR63-13-ADOL1",
      condition: "in",
      depending: "FR63-12-ADOL1",
      exception: "Household Member (non-relative)",
      subtopic_container_act: "FR59-ADOL-ACT",
      suptopic_code: "FR63-ADOL-CODE",
      subtopic_name: "kept guns, other weapons around?",
    },
    {
      id: "FH.158",
      shortname: "fr64-adol1",
      name: "Do you think you have any problems with your family?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Maybe", "Definitely"],
      act: "FR64-ADOL1",
      depending: "",
      exception: "",
    },
    {
      id: "FH.159",
      shortname: "fr65-adol1",
      name: "In the past month, how often have you experienced things about your family that bothered you?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Never", "Sometimes", "Almost always", "Always"],
      act: "FR65-ADOL1",
      depending: "",
      exception: "",
    },
    {
      id: "FH.160",
      shortname: "fr23",
      name: "Would you like to talk to a counselor about any abuse history?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR23",
      depending: "",
      exception: "",
    },
    {
      id: "FH.161",
      shortname: "fr39",
      name: "How worried or bothered have you been about family or social-related problems in the past 30 days?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: ["", "not at all", "a little bit", "a lot"],
      act: "FR39",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR39-1",
    },
    {
      id: "FH.161.1",
      shortname: "fr39-1",
      name: "",
      prevalue: "Please explain the reason you are worried or bothered?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR39-1",
      depending: "FR39",
      exception: "not at all",
      extend: "true",
    },
    {
      id: "FH.162",
      shortname: "fr40",
      name: "How important is it for you to receive counseling or treatment for family or social-related problems?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: ["", "not at all", "a little bit", "a lot"],
      act: "FR40",
      depending: "",
      exception: "",
    },
    {
      id: "",
      shortname: "fr-0-adol1",
      name: "Note: the following questions relate to you and your peer/friend relationships.",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "text",
      act: "FR-0-ADOL1",
      depending: "",
      exception: "",
    },
    {
      id: "FH.163",
      shortname: "fr66-adol1-subtopic",
      name: "Have you EVER ...",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "FR66-ADOL-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "FH.164",
      shortname: "fr66-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR66-ADOL1",
      depending: "",
      exception: "",
      link: "FR66-1-ADOL1",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR66-ADOL-CODE",
      subtopic_name: "had difficulty making or keeping friends?",
    },
    {
      id: "FH.164.1",
      extend: "true",
      shortname: "fr66-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR66-1-ADOL1",
      condition: "in",
      depending: "FR66-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR66-ADOL-CODE",
      subtopic_name: "had difficulty making or keeping friends?",
    },
    {
      id: "FH.164.2",
      extend: "true",
      shortname: "fr66-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR66-2-ADOL1",
      condition: "in",
      depending: "FR66-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR66-ADOL-CODE",
      subtopic_name: "had difficulty making or keeping friends?",
    },
    {
      id: "FH.164.3",
      extend: "true",
      shortname: "fr66-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR66-3-ADOL1",
      condition: "in",
      depending: "FR66-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR66-ADOL-CODE",
      subtopic_name: "had difficulty making or keeping friends?",
    },
    {
      id: "FH.164.4",
      extend: "true",
      shortname: "fr66-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR66-C-ADOL1",
      depending: "FR66-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR66-ADOL-CODE",
      subtopic_name: "had difficulty making or keeping friends?",
    },
    {
      id: "FH.165",
      shortname: "fr68-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR68-ADOL1",
      depending: "",
      exception: "",
      link: "FR68-1-ADOL1",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR68-ADOL-CODE",
      subtopic_name: "preferred to be alone rather than with others?",
    },
    {
      id: "FH.165.1",
      extend: "true",
      shortname: "fr68-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR68-1-ADOL1",
      condition: "in",
      depending: "FR68-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR68-ADOL-CODE",
      subtopic_name: "preferred to be alone rather than with others?",
    },
    {
      id: "FH.165.2",
      extend: "true",
      shortname: "fr68-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR68-2-ADOL1",
      condition: "in",
      depending: "FR68-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR68-ADOL-CODE",
      subtopic_name: "preferred to be alone rather than with others?",
    },
    {
      id: "FH.165.3",
      extend: "true",
      shortname: "fr68-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR68-3-ADOL1",
      condition: "in",
      depending: "FR68-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR68-ADOL-CODE",
      subtopic_name: "preferred to be alone rather than with others?",
    },
    {
      id: "FH.165.4",
      extend: "true",
      shortname: "fr68-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR68-C-ADOL1",
      depending: "FR68-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR68-ADOL-CODE",
      subtopic_name: "preferred to be alone rather than with others?",
    },
    {
      id: "FH.166",
      shortname: "fr69-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR69-ADOL1",
      depending: "",
      exception: "",
      link: "FR69-1-ADOL1",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR69-ADOL-CODE",
      subtopic_name:
        "felt that your friends were not loyal, not trustworthy, talked behind your back or were two-faced?",
    },
    {
      id: "FH.166.1",
      extend: "true",
      shortname: "fr69-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR69-1-ADOL1",
      condition: "in",
      depending: "FR69-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR69-ADOL-CODE",
      subtopic_name:
        "felt that your friends were not loyal, not trustworthy, talked behind your back or were two-faced?",
    },
    {
      id: "FH.166.2",
      extend: "true",
      shortname: "fr69-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR69-2-ADOL1",
      condition: "in",
      depending: "FR69-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR69-ADOL-CODE",
      subtopic_name:
        "felt that your friends were not loyal, not trustworthy, talked behind your back or were two-faced?",
    },
    {
      id: "FH.166.3",
      extend: "true",
      shortname: "fr69-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR69-3-ADOL1",
      condition: "in",
      depending: "FR69-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR69-ADOL-CODE",
      subtopic_name:
        "felt that your friends were not loyal, not trustworthy, talked behind your back or were two-faced?",
    },
    {
      id: "FH.166.4",
      extend: "true",
      shortname: "fr69-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR69-C-ADOL1",
      depending: "FR69-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR69-ADOL-CODE",
      subtopic_name:
        "felt that your friends were not loyal, not trustworthy, talked behind your back or were two-faced?",
    },
    {
      id: "FH.167",
      shortname: "fr70-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR70-ADOL1",
      depending: "",
      exception: "",
      link: "FR70-1-ADOL1",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR70-ADOL-CODE",
      subtopic_name:
        'found it hard to (or could not) talk about important issues, would not "open-up" to your friends, or were unable to tell them how you really feel?',
    },
    {
      id: "FH.167.1",
      extend: "true",
      shortname: "fr70-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR70-1-ADOL1",
      condition: "in",
      depending: "FR70-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR70-ADOL-CODE",
      subtopic_name:
        'found it hard to (or could not) talk about important issues, would not "open-up" to your friends, or were unable to tell them how you really feel?',
    },
    {
      id: "FH.167.2",
      extend: "true",
      shortname: "fr70-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR70-2-ADOL1",
      condition: "in",
      depending: "FR70-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR70-ADOL-CODE",
      subtopic_name:
        'found it hard to (or could not) talk about important issues, would not "open-up" to your friends, or were unable to tell them how you really feel?',
    },
    {
      id: "FH.167.3",
      extend: "true",
      shortname: "fr70-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR70-3-ADOL1",
      condition: "in",
      depending: "FR70-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR70-ADOL-CODE",
      subtopic_name:
        'found it hard to (or could not) talk about important issues, would not "open-up" to your friends, or were unable to tell them how you really feel?',
    },
    {
      id: "FH.167.4",
      extend: "true",
      shortname: "fr70-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR70-C-ADOL1",
      depending: "FR70-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR70-ADOL-CODE",
      subtopic_name:
        'found it hard to (or could not) talk about important issues, would not "open-up" to your friends, or were unable to tell them how you really feel?',
    },
    {
      id: "FH.168",
      shortname: "fr71-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR71-ADOL1",
      depending: "",
      exception: "",
      link: "FR71-1-ADOL1",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR71-ADOL-CODE",
      subtopic_name:
        "were dissatisfied with the quality of your relationships?",
    },
    {
      id: "FH.168.1",
      extend: "true",
      shortname: "fr71-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR71-1-ADOL1",
      condition: "in",
      depending: "FR71-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR71-ADOL-CODE",
      subtopic_name:
        "were dissatisfied with the quality of your relationships?",
    },
    {
      id: "FH.168.2",
      extend: "true",
      shortname: "fr71-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR71-2-ADOL1",
      condition: "in",
      depending: "FR71-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR71-ADOL-CODE",
      subtopic_name:
        "were dissatisfied with the quality of your relationships?",
    },
    {
      id: "FH.168.3",
      extend: "true",
      shortname: "fr71-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR71-3-ADOL1",
      condition: "in",
      depending: "FR71-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR71-ADOL-CODE",
      subtopic_name:
        "were dissatisfied with the quality of your relationships?",
    },
    {
      id: "FH.168.4",
      extend: "true",
      shortname: "fr71-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR71-C-ADOL1",
      depending: "FR71-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR71-ADOL-CODE",
      subtopic_name:
        "were dissatisfied with the quality of your relationships?",
    },
    {
      id: "FH.169",
      shortname: "fr72-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR72-ADOL1",
      depending: "",
      exception: "",
      link: "FR72-1-ADOL1",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR72-ADOL-CODE",
      subtopic_name:
        "were consistently teased or bullied by your peers, were humiliated?",
    },
    {
      id: "FH.169.1",
      extend: "true",
      shortname: "fr72-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR72-1-ADOL1",
      condition: "in",
      depending: "FR72-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR72-ADOL-CODE",
      subtopic_name:
        "were consistently teased or bullied by your peers, were humiliated?",
    },
    {
      id: "FH.169.2",
      extend: "true",
      shortname: "fr72-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR72-2-ADOL1",
      condition: "in",
      depending: "FR72-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR72-ADOL-CODE",
      subtopic_name:
        "were consistently teased or bullied by your peers, were humiliated?",
    },
    {
      id: "FH.169.3",
      extend: "true",
      shortname: "fr72-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR72-3-ADOL1",
      condition: "in",
      depending: "FR72-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR72-ADOL-CODE",
      subtopic_name:
        "were consistently teased or bullied by your peers, were humiliated?",
    },
    {
      id: "FH.169.4",
      extend: "true",
      shortname: "fr72-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR72-C-ADOL1",
      depending: "FR72-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR72-ADOL-CODE",
      subtopic_name:
        "were consistently teased or bullied by your peers, were humiliated?",
    },
    {
      id: "FH.170",
      shortname: "fr73-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR73-ADOL1",
      depending: "",
      exception: "",
      link: "FR73-1-ADOL1",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR73-ADOL-CODE",
      subtopic_name:
        "consistently initiated physical fights or bullied your peers?",
    },
    {
      id: "FH.170.1",
      extend: "true",
      shortname: "fr73-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR73-1-ADOL1",
      condition: "in",
      depending: "FR73-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR73-ADOL-CODE",
      subtopic_name:
        "consistently initiated physical fights or bullied your peers?",
    },
    {
      id: "FH.170.2",
      extend: "true",
      shortname: "fr73-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR73-2-ADOL1",
      condition: "in",
      depending: "FR73-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR73-ADOL-CODE",
      subtopic_name:
        "consistently initiated physical fights or bullied your peers?",
    },
    {
      id: "FH.170.3",
      extend: "true",
      shortname: "fr73-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR73-3-ADOL1",
      condition: "in",
      depending: "FR73-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR73-ADOL-CODE",
      subtopic_name:
        "consistently initiated physical fights or bullied your peers?",
    },
    {
      id: "FH.170.4",
      extend: "true",
      shortname: "fr73-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR73-C-ADOL1",
      depending: "FR73-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR73-ADOL-CODE",
      subtopic_name:
        "consistently initiated physical fights or bullied your peers?",
    },
    {
      id: "FH.171",
      shortname: "fr74-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR74-ADOL1",
      depending: "",
      exception: "",
      link: "FR74-1-ADOL1",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR74-ADOL-CODE",
      subtopic_name: "carried guns, knives or other weapons?",
    },
    {
      id: "FH.171.1",
      extend: "true",
      shortname: "fr74-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR74-1-ADOL1",
      condition: "in",
      depending: "FR74-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR74-ADOL-CODE",
      subtopic_name: "carried guns, knives or other weapons?",
    },
    {
      id: "FH.171.2",
      extend: "true",
      shortname: "fr74-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR74-2-ADOL1",
      condition: "in",
      depending: "FR74-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR74-ADOL-CODE",
      subtopic_name: "carried guns, knives or other weapons?",
    },
    {
      id: "FH.171.3",
      extend: "true",
      shortname: "fr74-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR74-3-ADOL1",
      condition: "in",
      depending: "FR74-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR74-ADOL-CODE",
      subtopic_name: "carried guns, knives or other weapons?",
    },
    {
      id: "FH.171.4",
      extend: "true",
      shortname: "fr74-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR74-C-ADOL1",
      depending: "FR74-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR74-ADOL-CODE",
      subtopic_name: "carried guns, knives or other weapons?",
    },
    {
      id: "FH.172",
      shortname: "fr75-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR75-ADOL1",
      depending: "",
      exception: "",
      link: "FR75-1-ADOL1",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR75-ADOL-CODE",
      subtopic_name:
        "felt that your friends provided you with protection or status?",
    },
    {
      id: "FH.172.1",
      extend: "true",
      shortname: "fr75-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR75-1-ADOL1",
      condition: "in",
      depending: "FR75-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR75-ADOL-CODE",
      subtopic_name:
        "felt that your friends provided you with protection or status?",
    },
    {
      id: "FH.172.2",
      extend: "true",
      shortname: "fr75-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR75-2-ADOL1",
      condition: "in",
      depending: "FR75-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR75-ADOL-CODE",
      subtopic_name:
        "felt that your friends provided you with protection or status?",
    },
    {
      id: "FH.172.3",
      extend: "true",
      shortname: "fr75-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR75-3-ADOL1",
      condition: "in",
      depending: "FR75-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR75-ADOL-CODE",
      subtopic_name:
        "felt that your friends provided you with protection or status?",
    },
    {
      id: "FH.172.4",
      extend: "true",
      shortname: "fr75-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR75-C-ADOL1",
      depending: "FR75-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR75-ADOL-CODE",
      subtopic_name:
        "felt that your friends provided you with protection or status?",
    },
    {
      id: "FH.173",
      shortname: "fr76-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR76-ADOL1",
      depending: "",
      exception: "",
      link: "FR76-1-ADOL1",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR76-ADOL-CODE",
      subtopic_name:
        'had difficulty establishing "romantic" relationships or were upset about not having that kind of relationship?',
    },
    {
      id: "FH.173.1",
      extend: "true",
      shortname: "fr76-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR76-1-ADOL1",
      condition: "in",
      depending: "FR76-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR76-ADOL-CODE",
      subtopic_name:
        'had difficulty establishing "romantic" relationships or were upset about not having that kind of relationship?',
    },
    {
      id: "FH.173.2",
      extend: "true",
      shortname: "fr76-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR76-2-ADOL1",
      condition: "in",
      depending: "FR76-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR76-ADOL-CODE",
      subtopic_name:
        'had difficulty establishing "romantic" relationships or were upset about not having that kind of relationship?',
    },
    {
      id: "FH.173.3",
      extend: "true",
      shortname: "fr76-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR76-3-ADOL1",
      condition: "in",
      depending: "FR76-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR76-ADOL-CODE",
      subtopic_name:
        'had difficulty establishing "romantic" relationships or were upset about not having that kind of relationship?',
    },
    {
      id: "FH.173.4",
      extend: "true",
      shortname: "fr76-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR76-C-ADOL1",
      depending: "FR76-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR76-ADOL-CODE",
      subtopic_name:
        'had difficulty establishing "romantic" relationships or were upset about not having that kind of relationship?',
    },
    {
      id: "FH.174",
      shortname: "fr77-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR77-ADOL1",
      depending: "",
      exception: "",
      link: "FR77-1-ADOL1",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR77-ADOL-CODE",
      subtopic_name:
        "had feelings toward a member of the same sex that you did not understand; were attracted to someone of the same sex?",
    },
    {
      id: "FH.174.1",
      extend: "true",
      shortname: "fr77-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR77-1-ADOL1",
      condition: "in",
      depending: "FR77-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR77-ADOL-CODE",
      subtopic_name:
        "had feelings toward a member of the same sex that you did not understand; were attracted to someone of the same sex?",
    },
    {
      id: "FH.174.2",
      extend: "true",
      shortname: "fr77-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR77-2-ADOL1",
      condition: "in",
      depending: "FR77-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR77-ADOL-CODE",
      subtopic_name:
        "had feelings toward a member of the same sex that you did not understand; were attracted to someone of the same sex?",
    },
    {
      id: "FH.174.3",
      extend: "true",
      shortname: "fr77-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR77-3-ADOL1",
      condition: "in",
      depending: "FR77-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR77-ADOL-CODE",
      subtopic_name:
        "had feelings toward a member of the same sex that you did not understand; were attracted to someone of the same sex?",
    },
    {
      id: "FH.174.4",
      extend: "true",
      shortname: "fr77-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "FR77-C-ADOL1",
      depending: "FR77-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR66-ADOL-ACT",
      suptopic_code: "FR77-ADOL-CODE",
      subtopic_name:
        "had feelings toward a member of the same sex that you did not understand; were attracted to someone of the same sex?",
    },
    {
      id: "FH.175",
      shortname: "fr78-adol1-subtopic",
      name: "Check all that apply",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "FR78-ADOL-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "FH.176",
      shortname: "fr78-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR78-ADOL1",
      depending: "",
      exception: "",
      link: "FR78-ADOL1-SELECT",
      subtopic_container_act: "FR78-ADOL-ACT",
      suptopic_code: "FR78-ADOL-CODE",
      subtopic_name:
        "Have you EVER known someone who you could really trust, depend on, or count on to support you?",
    },
    {
      id: "FH.176.1",
      extend: "true",
      shortname: "fr78-adol1-select",
      name: "",
      prevalue: "Whom have you trusted the most during the past year?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Drug-using peer",
        "Non-using peer",
        "Drug-using girlfriend/boyfriend",
        "Non-using girlfriend/boyfriend",
        "Drug-using family member",
        "Non-using family member",
        "Other",
      ],
      act: "FR78-ADOL1-SELECT",
      condition: "in",
      depending: "FR78-ADOL1",
      exception: "Ever;Yes",
      link: "FR78-82-ADOL1",
      subtopic_container_act: "FR78-ADOL-ACT",
      suptopic_code: "FR78-ADOL-CODE",
      subtopic_name:
        "Have you EVER known someone who you could really trust, depend on, or count on to support you?",
    },
    {
      id: "FH.176.1.1",
      extend: "true",
      shortname: "fr78-82-adol1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR78-82-ADOL1",
      condition: "in",
      depending: "FR78-ADOL1-SELECT",
      exception: "Other",
      link: "FR78-ADOL1-PAST",
      subtopic_container_act: "FR78-ADOL-ACT",
      suptopic_code: "FR78-ADOL-CODE",
      subtopic_name:
        "Have you EVER known someone who you could really trust, depend on, or count on to support you?",
    },
    {
      id: "FH.176.2",
      extend: "true",
      shortname: "fr78-adol1-past",
      name: "",
      prevalue: "Past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR78-ADOL1-PAST",
      condition: "in",
      depending: "FR78-ADOL1",
      exception: "Ever;Yes",
      link: "FR78-ADOL1-OTHER",
      subtopic_container_act: "FR78-ADOL-ACT",
      suptopic_code: "FR78-ADOL-CODE",
      subtopic_name:
        "Have you EVER known someone who you could really trust, depend on, or count on to support you?",
    },
    {
      id: "FH.176.3",
      extend: "true",
      shortname: "fr78-adol1-other",
      name: "",
      prevalue: "Other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR78-ADOL1-OTHER",
      condition: "in",
      depending: "FR78-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR78-ADOL-ACT",
      suptopic_code: "FR78-ADOL-CODE",
      subtopic_name:
        "Have you EVER known someone who you could really trust, depend on, or count on to support you?",
    },
    {
      id: "FH.177",
      shortname: "fr79-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR79-ADOL1",
      depending: "",
      exception: "",
      link: "FR79-ADOL1-PAST",
      subtopic_container_act: "FR78-ADOL-ACT",
      suptopic_code: "FR79-ADOL-CODE",
      subtopic_name: "Have you EVER felt that someone believed in you?",
    },
    {
      id: "FH.177.1",
      extend: "true",
      shortname: "fr79-adol1-select",
      name: "",
      prevalue: "Whom have you trusted the most during the past year?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Drug-using peer",
        "Non-using peer",
        "Drug-using girlfriend/boyfriend",
        "Non-using girlfriend/boyfriend",
        "Drug-using family member",
        "Non-using family member",
        "Other",
      ],
      act: "FR79-ADOL1-SELECT",
      condition: "in",
      depending: "FR79-ADOL1",
      exception: "Ever;Yes",
      link: "FR79-82-ADOL1",
      subtopic_container_act: "FR78-ADOL-ACT",
      suptopic_code: "FR79-ADOL-CODE",
      subtopic_name: "Have you EVER felt that someone believed in you?",
    },
    {
      id: "FH.177.1.1",
      extend: "true",
      shortname: "fr79-82-adol1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR79-82-ADOL1",
      condition: "in",
      depending: "FR79-ADOL1-SELECT",
      exception: "Other",
      link: "FR79-ADOL1-PAST",
      subtopic_container_act: "FR78-ADOL-ACT",
      suptopic_code: "FR79-ADOL-CODE",
      subtopic_name: "Have you EVER felt that someone believed in you?",
    },
    {
      id: "FH.177.2",
      extend: "true",
      shortname: "fr79-adol1-past",
      name: "",
      prevalue: "Past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR79-ADOL1-PAST",
      condition: "in",
      depending: "FR79-ADOL1",
      exception: "Ever;Yes",
      link: "FR79-ADOL1-OTHER",
      subtopic_container_act: "FR78-ADOL-ACT",
      suptopic_code: "FR79-ADOL-CODE",
      subtopic_name: "Have you EVER felt that someone believed in you?",
    },
    {
      id: "FH.177.3",
      extend: "true",
      shortname: "fr79-adol1-other",
      name: "",
      prevalue: "Other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR79-ADOL1-OTHER",
      condition: "in",
      depending: "FR79-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR78-ADOL-ACT",
      suptopic_code: "FR79-ADOL-CODE",
      subtopic_name: "Have you EVER felt that someone believed in you?",
    },
    {
      id: "FH.178",
      shortname: "fr66-adol1-subtopic",
      name: "Have you EVER hung around people who...",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "FR80-ADOL-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "FH.179",
      shortname: "fr80-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR80-ADOL1",
      depending: "",
      exception: "",
      link: "FR80-1-ADOL1",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR80-ADOL-CODE",
      subtopic_name: "used drugs or got drunk regularly?",
    },
    {
      id: "FH.179.1",
      extend: "true",
      shortname: "fr80-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR80-1-ADOL1",
      condition: "in",
      depending: "FR80-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR80-ADOL-CODE",
      subtopic_name: "used drugs or got drunk regularly?",
    },
    {
      id: "FH.179.2",
      extend: "true",
      shortname: "fr80-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR80-2-ADOL1",
      condition: "in",
      depending: "FR80-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR80-ADOL-CODE",
      subtopic_name: "used drugs or got drunk regularly?",
    },
    {
      id: "FH.179.3",
      extend: "true",
      shortname: "fr80-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR80-3-ADOL1",
      condition: "in",
      depending: "FR80-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR80-ADOL-CODE",
      subtopic_name: "used drugs or got drunk regularly?",
    },
    {
      id: "FH.180",
      shortname: "fr81-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR81-ADOL1",
      depending: "",
      exception: "",
      link: "FR81-1-ADOL1",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR81-ADOL-CODE",
      subtopic_name:
        "committed illegal acts or got into trouble with the police/juvenile justice system?",
    },
    {
      id: "FH.180.1",
      extend: "true",
      shortname: "fr81-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR81-1-ADOL1",
      condition: "in",
      depending: "FR81-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR81-ADOL-CODE",
      subtopic_name:
        "committed illegal acts or got into trouble with the police/juvenile justice system?",
    },
    {
      id: "FH.180.2",
      extend: "true",
      shortname: "fr81-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR81-2-ADOL1",
      condition: "in",
      depending: "FR81-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR81-ADOL-CODE",
      subtopic_name:
        "committed illegal acts or got into trouble with the police/juvenile justice system?",
    },
    {
      id: "FH.180.3",
      extend: "true",
      shortname: "fr81-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR81-3-ADOL1",
      condition: "in",
      depending: "FR81-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR81-ADOL-CODE",
      subtopic_name:
        "committed illegal acts or got into trouble with the police/juvenile justice system?",
    },
    {
      id: "FH.181",
      shortname: "fr82-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR82-ADOL1",
      depending: "",
      exception: "",
      link: "FR82-1-ADOL1",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR82-ADOL-CODE",
      subtopic_name: "were members of gangs?",
    },
    {
      id: "FH.181.1",
      extend: "true",
      shortname: "fr82-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR82-1-ADOL1",
      condition: "in",
      depending: "FR82-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR82-ADOL-CODE",
      subtopic_name: "were members of gangs?",
    },
    {
      id: "FH.181.2",
      extend: "true",
      shortname: "fr82-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR82-2-ADOL1",
      condition: "in",
      depending: "FR82-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR82-ADOL-CODE",
      subtopic_name: "were members of gangs?",
    },
    {
      id: "FH.181.3",
      extend: "true",
      shortname: "fr82-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR82-3-ADOL1",
      condition: "in",
      depending: "FR82-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR82-ADOL-CODE",
      subtopic_name: "were members of gangs?",
    },
    {
      id: "FH.182",
      shortname: "fr83-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR83-ADOL1",
      depending: "",
      exception: "",
      link: "FR83-1-ADOL1",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR83-ADOL-CODE",
      subtopic_name:
        "dropped out of school or did not attend school regularly?",
    },
    {
      id: "FH.182.1",
      extend: "true",
      shortname: "fr83-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR83-1-ADOL1",
      condition: "in",
      depending: "FR83-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR83-ADOL-CODE",
      subtopic_name:
        "dropped out of school or did not attend school regularly?",
    },
    {
      id: "FH.182.2",
      extend: "true",
      shortname: "fr83-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR83-2-ADOL1",
      condition: "in",
      depending: "FR83-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR83-ADOL-CODE",
      subtopic_name:
        "dropped out of school or did not attend school regularly?",
    },
    {
      id: "FH.182.3",
      extend: "true",
      shortname: "fr83-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR83-3-ADOL1",
      condition: "in",
      depending: "FR83-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR83-ADOL-CODE",
      subtopic_name:
        "dropped out of school or did not attend school regularly?",
    },
    {
      id: "FH.183",
      shortname: "fr84-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR84-ADOL1",
      depending: "",
      exception: "",
      link: "FR84-1-ADOL1",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR84-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly but stopped?",
    },
    {
      id: "FH.183.1",
      extend: "true",
      shortname: "fr84-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR84-1-ADOL1",
      condition: "in",
      depending: "FR84-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR84-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly but stopped?",
    },
    {
      id: "FH.183.2",
      extend: "true",
      shortname: "fr84-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR84-2-ADOL1",
      condition: "in",
      depending: "FR84-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR84-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly but stopped?",
    },
    {
      id: "FH.183.3",
      extend: "true",
      shortname: "fr84-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR84-3-ADOL1",
      condition: "in",
      depending: "FR84-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR84-ADOL-CODE",
      subtopic_name: "used drugs or drank alcohol regularly but stopped?",
    },
    {
      id: "FH.184",
      shortname: "fr85-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR85-ADOL1",
      depending: "",
      exception: "",
      link: "FR85-1-ADOL1",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR85-ADOL-CODE",
      subtopic_name: "supported not using drugs or drinking alcohol?",
    },
    {
      id: "FH.184.1",
      extend: "true",
      shortname: "fr85-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "FR85-1-ADOL1",
      condition: "in",
      depending: "FR85-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR85-ADOL-CODE",
      subtopic_name: "supported not using drugs or drinking alcohol?",
    },
    {
      id: "FH.184.2",
      extend: "true",
      shortname: "fr85-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR85-2-ADOL1",
      condition: "in",
      depending: "FR85-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR85-ADOL-CODE",
      subtopic_name: "supported not using drugs or drinking alcohol?",
    },
    {
      id: "FH.184.3",
      extend: "true",
      shortname: "fr85-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FR85-3-ADOL1",
      condition: "in",
      depending: "FR85-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "FR80-ADOL-ACT",
      suptopic_code: "FR85-ADOL-CODE",
      subtopic_name: "supported not using drugs or drinking alcohol?",
    },
    {
      id: "FH.185",
      shortname: "fr86-adol1",
      name: "Do you think you have any problems with your friends?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Maybe", "Definitely"],
      act: "FR86-ADOL1",
      depending: "",
      exception: "",
    },
    {
      id: "FH.186",
      shortname: "fr88-adol1",
      name: "How worried or bothered have you been about peer-related problems in the past 30 days?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: ["", "not at all", "a little bit", "a lot"],
      act: "FR88-ADOL1",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR88-1-ADOL1",
    },
    {
      id: "FH.186.1",
      shortname: "fr88-1-adol1",
      name: "",
      prevalue: "Please explain the reason you are worried or bothered?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR88-1-ADOL1",
      depending: "FR88-ADOL1",
      exception: "not at all",
      extend: "true",
    },
    {
      id: "FH.187",
      shortname: "fr89-adol1",
      name: "How important is it for you to receive counseling or treatment for peer-related problems?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: ["", "not at all", "a little bit", "a lot"],
      act: "FR89-ADOL1",
      depending: "",
      exception: "",
    },
    {
      id: "FH.188",
      shortname: "fr41c",
      access: "private",
      name: "Additional comments:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "editor",
      act: "FR41C",
      depending: "",
      exception: "",
    },
  ],
};

export default familyHistoryJSON;
