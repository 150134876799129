const educationHistoryJSON =   {
  // section: "EDO",
  // topic: "Education, Employment and Military History",
  // checked: "true",
  // // access: "public",
  // color: "secondary",
  section: "ES",
  topic: "EMPLOYMENT/SUPPORT STATUS",
  checked: "true",
  // access: "public",
  color: "secondary",
  description: "",
  body: [
    {
      id: "E1",
      shortname: "es1",
      name: "Education completed. GED = 12 years, note in comments. Include formal education only (YRS/MOS)",
      prevalue: 'Yrs&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbspMon',
      value: "",
      value2: "",
      disabled: "false",
      type: "year_month",
      act: "ES1",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ES1-1",
    },
    {
      id: "E1.1",
      shortname: "es1-1",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "false",
      type: "text",
      act: "ES1-1",
      // mandatory: "true",
      depending: "",
      exception: "",
      extend: "true",
    },
    {
      id: "E2",
      shortname: "es2",
      name: "Training or technical education completed",
      prevalue: "Formal, organized training only. For military training, only include training that can be used in civilian life (i.e., electronics, computers). (MOS)",
      value: "",
      disabled: "false",
      type: "number",
      act: "ES2",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ES2-1",
    },
    {
      id: "E2.1",
      shortname: "es2-1",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "false",
      type: "text",
      act: "ES2-1",
      // mandatory: "true",
      // depending: "ES2",
      // exception: "0",
      depending: "",
      exception: "",
      extend: "true",
    },
    {
      id: "E3",
      onlyasi: "",  //E3
      shortname: "es3",
      name: "Do you have a profession, trade, or skill? Employable, transferable skill acquired through training.",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ES3",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ES3-1",
    },
    {
      id: "E3.1",
      shortname: "es3-1",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "ES3-1",
      mandatory: "true",
      depending: "ES3",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "E4",
      shortname: "es4",
      name: "Do you have a valid driver’s license?",
      prevalue: "Valid license; not suspended/revoked",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ES4",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "E5",
      shortname: "es5",
      name: "Do you have an automobile available for use?",
      prevalue: "Does not require ownership, only requires availability on a regular basis",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ES5",
      mandatory: "true",
      depending: "ES4",
      exception: "No",
    },
    {
      id: "E6",
      shortname: "es6",
      name: "How long was your longest full-time job? Full-time = 35+ hours weekly; does not necessarily mean most recent job (YRS/MOS)",
      prevalue: 'Yrs&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbspMon',
      value: "",
      value2: "",
      disabled: "false",
      type: "year_month",
      act: "ES6",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    // {
    //   id: "E7",
    //   shortname: "es7",
    //   name: "Usual (or last) occupation.",
    //   prevalue: "",
    //   value: "",
    //   disabled: "false",
    //   type: "text",
    //   act: "ES7",
    //   mandatory: "true",
    //   depending: "",
    //   exception: "",
    //   link: "ES7-1",
    // },
    {
      id: "E7",
      // extend: "true",
      shortname: "es7",
      name: "Usual (or last) occupation.",
      prevalue: "Specify. Use Hollingshead Categories Reference Sheet",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      // select_options: [
      //   "",
      //   "Professional(MA, MS, ME, MD, PhD, LLD, and the like)",
      //   "Four-year college graduate (BA, BS, BM)",
      //   "One to three years college (also business school)",
      //   "High school graduate",
      //   "Ten to 11 years of school (part high school)",
      //   "Seven to nine years of school",
      //   "Less than seven years of school",
      // ],
      select_options: [
        "",
        "0 - Higher execs, major professionals, owners of large businesses.",
        "1 - Business managers if medium sized businesses, lesser professions, i.e., nurses,opticians, pharmacists, social, workers, teachers.",
        "2 - Administrative personnel, managers, minor professionals, owners/proprietors of small businesses, i.e.,bakery, car dealership, engraving business, plumbing business, florist, decoratior, actor, reporter, travel agent.",
        "3 - Clerical and sales, technicians, small businesses (bank teller, bookkeeper, clerk, draftsperson, timekeeper, srcretary)",
        "4 - Skilled manual - usually having had training (bakar, barber, brakeperson, chef, electricians,fireman, machanic, paperhanger, paiter, repairperson, tailor, welder, police, plumber).",
        "5 - Semi-skilled (hospital aide, painter, bartender, bus driver, cutter, cook, drill press, garage guard, checker, waiter, spot welder, machine operator)",
        "6 - Unskilled (attendant, janitor, construction helper,unspecified labor, porter, including unemployed).",
        "7 - Homemaker",
        "8 - Student, disabled, no occupation",
      ],
      act: "ES7",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "E8",
      // onlyasi: "",  //E8
      shortname: "es8",
      name: "Does someone contribute to your support in any way?Is patient receiving any regular support (i.e., cash, food, housing) from family/ friend. Include spouse’s contribution; exclude support by an institution ",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ES8",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ES9",
    },
    {
      id: "E9",
      // onlyasi: "",  //E9
      extend: "true",
      shortname: "es9",
      name: "",
      prevalue: "Does this support constitute the majority of your? If E8 is No, then E9 is NN.",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ES9",
      depending: "ES8",
      exception: "Yes",
    },
    {
      id: "E10",
      shortname: "es10",
      name: "Usual employment pattern, past 3 years?",
      prevalue: "Answer should represent the majority of the last 3 years, not just the most recent selection. If there are equal times for more than one category, select that which best represents the current situation.",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - Full time (35+ hours)",
        "2 - Part time (regular hours)",
        "3 - Part time (irregular hours)",
        "4 - Student",
        "5 - Military service",
        "6 - Retired/disability",
        "7 - Unemployed",
        "8 - In controlled environment",
      ],
      act: "ES10",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "E11",
      shortname: "es11",
      name: "How many days were you paid for working in the past 30 days?",
      prevalue: "Include “under-the-table” work, paid sick days and vacation.",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "ES11",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "",
      shortname: "es12-es17-subtopic",
      name: "For questions E12-E17. How much money did you receive from the following sources in the past 30 days?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "ES12-ES17-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "E12",
      shortname: "es12",
      name: "",
      prevalue: 'Net or "take home" pay, include any "under the table" money',
      value: "0",
      disabled: "false",
      type: "money",
      act: "ES12",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "ES12-ES17-ACT",
      suptopic_code: "ES12-CODE",
      subtopic_name:
        "Employment",
    },
    {
      id: "E13",
      shortname: "es13",
      name: "",
      prevalue: "",
      value: "0",
      disabled: "false",
      type: "money",
      act: "ES13",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "ES12-ES17-ACT",
      suptopic_code: "ES13-CODE",
      subtopic_name:
        "Unemployment compensation<br/><br/><br/>",
    },
    {
      id: "E14",
      shortname: "es14",
      name: "",
      prevalue: "Include food stamps, transportation money provided by an agency to go to and from treatment",
      value: "0",
      disabled: "false",
      type: "money",
      act: "ES14",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "ES12-ES17-ACT",
      suptopic_code: "ES14-CODE",
      subtopic_name:
        "Welfare",
    },
    {
      id: "E15",
      shortname: "es15",
      name: "",
      prevalue: "Include disability, pensions, retirement, veteran's benefits, SSI & workers’ compensation",
      value: "0",
      disabled: "false",
      type: "money",
      act: "ES15",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "ES12-ES17-ACT",
      suptopic_code: "ES15-CODE",
      subtopic_name:
        "Pension, benefits or social security",
    },
    {
      id: "E16",
      shortname: "es16",
      name: "",
      prevalue: "Money for personal expenses (i.e., clothing); include unreliable sources of income. Record cash payments only, include windfalls (unexpected), money from loans, legal gambling, inheritance, tax returns, etc.",
      value: "0",
      disabled: "false",
      type: "money",
      act: "ES16",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "ES12-ES17-ACT",
      suptopic_code: "ES16-CODE",
      subtopic_name:
        "Mate, family or friends",
    },
    {
      id: "E17",
      shortname: "es17",
      name: "",
      prevalue: "Cash obtained from drug dealing, stealing, fencing stolen goods, illegal gambling, prostitution, etc. Do not attempt to convert drugs exchanged to a dollar value.",
      value: "0",
      disabled: "false",
      type: "money",
      act: "ES17",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "ES12-ES17-ACT",
      suptopic_code: "ES17-CODE",
      subtopic_name:
        "Illegal",
    },
    {
      id: "E18",
      shortname: "es18",
      name: "How many people depend on you for the majority of their food, shelter, etc.?",
      prevalue: "Must be regularly depending on patient; do include alimony/child support, do not include the patient or self-supporting spouse, etc.",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "ES18",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "E19",
      shortname: "es19",
      name: "How many days have you experienced employment problems in the past 30?",
      prevalue: "Include inability to find work, if they are actively looking for work, or problems with present job in which that job is jeopardized.",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "ES19",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "",
      shortname: "es20-es21-subtopic",
      name: "For Questions E20 & E21, ask patient to use the Patient Rating Scale.",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "ES20-ES21-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "E20",
      shortname: "es20",
      name: "",
      prevalue: "If the patient has been incarcerated or detained during the past 30 days, they cannot have employment problems. In that case, code NN",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0 - not at all",
        "1 - slightly",
        "2 - moderately",
        "3 - considerably",
        "4 - extremely",
      ],
      act: "ES20",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "ES20-ES21-ACT",
      suptopic_code: "ES20-CODE",
      subtopic_name:
        "How troubled or bothered have you been by these employment problems in the past 30 days?",
    },
    {
      id: "E21",
      shortname: "es21",
      name: "",
      prevalue: "Stress help in finding or preparing a job, not giving them a job.",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0 - not at all",
        "1 - slightly",
        "2 - moderately",
        "3 - considerably",
        "4 - extremely",
      ],
      act: "ES21",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "ES20-ES21-ACT",
      suptopic_code: "ES21-CODE",
      subtopic_name:
        "How important to you now is counseling for these employment problems?",
    },
    {
      id: "E22",
      onlyasi: "",  //E22
      shortname: "es22",
      access: "private",
      name: "INTERVIEWER SEVERITY RATING",
      prevalue: "How would you rate the patient’s need for employment counseling?",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
      ],
      act: "ES22",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "",
      shortname: "es23-es24-subtopic",
      access: "private",
      name: "CONFIDENCE RATINGS. Is the above information significantly distorted by:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "ES23-ES24-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "E23",
      shortname: "es23",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ES23",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "ES23-ES24-ACT",
      suptopic_code: "ES23-CODE",
      subtopic_name:
        "Client's misrepresentation?",
    },
    {
      id: "E24",
      shortname: "es24",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ES24",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "ES23-ES24-ACT",
      suptopic_code: "ES24-CODE",
      subtopic_name:
        "Client’s inability to understand?",
    },
    {
      id: "E-COMMENT",
      shortname: "es-comment",
      access: "private",
      name: "EMPLOYMENT/SUPPORT COMMENTS:",
      prevalue: "(Include the question number with your notes)",
      value: "",
      disabled: "false",
      type: "editor",
      act: "ES-COMMENT",
      depending: "",
      exception: "",
    },
  ],
}

export default educationHistoryJSON;
