import demographicInformationJSON from "./jsonsASI/demographicInformationJSON";
import medicalHistoryJSON from "./jsonsASI/medicalHistoryJSON";
import educationHistoryJSON from "./jsonsASI/educationHistorJSON";
import alcoholHistoryJSON from "./jsonsASI/alcoholHistoryJSON";
import legalHistoryJSON from "./jsonsASI/legalHistoryJSON";
import familyHistoryJSON from "./jsonsASI/familyHistoryJSON";
import behavioralHistoryJSON from "./jsonsASI/behavioralHistoryJSON";
import summaryRecomendationsJSON from "./jsonsASI/summaryRecomendationsJSON";
import asamCriteriaJSON from "./jsonsASI/asamCriteriaJSON";
import collateralJSON from "./jsonsASI/collateralJSON";
import strengthsJSON from "./jsonsASI/strengthsJSON";

const ASIPattern = [
  //========================================================================================
  /*                                                                                      *
   *                                Demographic Information                               *
   *                                                                                      */
  //========================================================================================
  demographicInformationJSON,
  //========================================================================================
  /*                                                                                      *
   *                         Presenting Problem/Primary Complaint                         *
   *                                                                                      */
  //========================================================================================

  // presentingProblemJSON,
  //========================================================================================
  /*                                                                                      *
   *                                    Medical History                                   *
   *                                                                                      */
  //========================================================================================
  medicalHistoryJSON,
  //========================================================================================
  /*                                                                                      *
   *                      Education, Employment and Military History                      *
   *                                                                                      */
  //========================================================================================
  educationHistoryJSON,
  //========================================================================================
  /*                                                                                      *
   *                               Drug and Alcohol History                               *
   *                                                                                      */
  //========================================================================================

  alcoholHistoryJSON,
  //========================================================================================
  /*                                                                                      *
   *                                         Legal History                                       *
   *                                                                                      */
  //========================================================================================
  legalHistoryJSON,
  //========================================================================================
  /*                                                                                      *
   *                                    Family History                                    *
   *                                                                                      */
  //========================================================================================
  familyHistoryJSON,
  //========================================================================================
  /*                                                                                      *
   *                               Behavioral Health History                              *
   *                                                                                      */
  //========================================================================================
  behavioralHistoryJSON,
  //========================================================================================
  /*                                                                                      *
   *                           Diagnosis/Summary/Recommendations                          *
   *                                                                                      */
  //========================================================================================
  // summaryRecomendationsJSON,
  //========================================================================================
  /*                                                                                      *
   *                                       OPTIONAL                                       *
   *                                                                                      */
  //========================================================================================

  // {
  //   section: "OPTIONAL",
  //   topic: "OPTIONAL SECTIONS. CHECK TO INCLUDE",
  //   checked: "false",
  //   // access: "private",
  //   color: "info",
  //   body: [],
  // },
  //========================================================================================
  /*                                                                                      *
   *                                     ASAM Criteria                                    *
   *                                                                                      */
  //========================================================================================
  asamCriteriaJSON,
  //========================================================================================
  /*                                                                                      *
   *                            Mental Status Examination (MSE)                           *
   *                                                                                      */
  //========================================================================================

  // mseJSON,
  //========================================================================================
  /*                                                                                      *
   *                                Collateral Information                                *
   *                                                                                      */
  //========================================================================================

  collateralJSON,
  //========================================================================================
  /*                                                                                      *
   *                               Strengths and Challenges                                *
   *                                                                                      */
  //========================================================================================
  strengthsJSON,
  //========================================================================================
  /*                                                                                      *
   *                           Diagnosis/Summary/Recommendations                          *
   *                                                                                      */
  //========================================================================================
  summaryRecomendationsJSON,
  //========================================================================================
  /*                                                                                      *
   *                           Diagnosis/Summary/Recommendations                          *
   *                                                                                      */
  //========================================================================================
  // suggestedRecomendation
];

export default ASIPattern;
