const blockHeroin = [
  //!========================================================================================
  /*                                                                                      *
   *                                   STARTBLOCKHEROIN                                   *
   *                                                                                      */
  //!========================================================================================
  {
    id: "DA.1.1.189",
    shortname: "heroin-block-subtopic",
    name: "Direction for Heroin: Please answer the items in the grid below. Have there EVER been significant periods during which you...",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "subtopic",
    act: "HEROIN-BLOCK-SUBTOPIC",
    depending: "DA0",
    exception: "Heroin",
    condition: "in",
  },
  {
    id: "DA.1.1.190",
    shortname: "heroin-block-6",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HEROIN-BLOCK-6",
    depending: "DA0",
    exception: "Heroin",
    condition: "in",
    link: "HEROIN-BLOCK-7",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.190.1",
    extend: "true",
    shortname: "heroin-block-7",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HEROIN-BLOCK-7",
    depending: "HEROIN-BLOCK-6",
    exception: "This has happened",
    link: "HEROIN-BLOCK-8",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.190.2",
    extend: "true",
    shortname: "heroin-block-8",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-8",
    depending: "HEROIN-BLOCK-6",
    exception: "This has happened",
    link: "HEROIN-BLOCK-9",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.190.3",
    extend: "true",
    shortname: "heroin-block-9",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-9",
    depending: "HEROIN-BLOCK-6",
    exception: "This has happened",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.191",
    shortname: "heroin-block-10",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HEROIN-BLOCK-10",
    depending: "DA0",
    exception: "Heroin",
    condition: "in",
    link: "HEROIN-BLOCK-11",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.191.1",
    extend: "true",
    shortname: "heroin-block-11",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HEROIN-BLOCK-11",
    depending: "HEROIN-BLOCK-10",
    exception: "This has happened",
    link: "HEROIN-BLOCK-12",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.191.2",
    extend: "true",
    shortname: "heroin-block-12",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-12",
    depending: "HEROIN-BLOCK-10",
    exception: "This has happened",
    link: "HEROIN-BLOCK-13",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.191.3",
    extend: "true",
    shortname: "heroin-block-13",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-13",
    depending: "HEROIN-BLOCK-10",
    exception: "This has happened",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.192",
    shortname: "heroin-block-14",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HEROIN-BLOCK-14",
    depending: "DA0",
    exception: "Heroin",
    condition: "in",
    link: "HEROIN-BLOCK-15",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.192.1",
    extend: "true",
    shortname: "heroin-block-15",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HEROIN-BLOCK-15",
    depending: "HEROIN-BLOCK-14",
    exception: "This has happened",
    link: "HEROIN-BLOCK-16",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.192.2",
    extend: "true",
    shortname: "heroin-block-16",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-16",
    depending: "HEROIN-BLOCK-14",
    exception: "This has happened",
    link: "HEROIN-BLOCK-17",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.192.3",
    extend: "true",
    shortname: "heroin-block-17",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-17",
    depending: "HEROIN-BLOCK-14",
    exception: "This has happened",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.193",
    shortname: "heroin-block-18",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HEROIN-BLOCK-18",
    depending: "DA0",
    exception: "Heroin",
    condition: "in",
    link: "HEROIN-BLOCK-19",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.193.1",
    extend: "true",
    shortname: "heroin-block-19",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HEROIN-BLOCK-19",
    depending: "HEROIN-BLOCK-18",
    exception: "This has happened",
    link: "HEROIN-BLOCK-30",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.193.2",
    extend: "true",
    shortname: "heroin-block-20",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-20",
    depending: "HEROIN-BLOCK-18",
    exception: "This has happened",
    link: "HEROIN-BLOCK-21",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.193.3",
    extend: "true",
    shortname: "heroin-block-21",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-21",
    depending: "HEROIN-BLOCK-18",
    exception: "This has happened",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.194",
    shortname: "heroin-block-22",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HEROIN-BLOCK-22",
    depending: "DA0",
    exception: "Heroin",
    condition: "in",
    link: "HEROIN-BLOCK-23",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.194.1",
    extend: "true",
    shortname: "heroin-block-23",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HEROIN-BLOCK-23",
    depending: "HEROIN-BLOCK-22",
    exception: "This has happened",
    link: "HEROIN-BLOCK-24",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.194.2",
    extend: "true",
    shortname: "heroin-block-24",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-24",
    depending: "HEROIN-BLOCK-22",
    exception: "This has happened",
    link: "HEROIN-BLOCK-25",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.194.3",
    extend: "true",
    shortname: "heroin-block-25",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-25",
    depending: "HEROIN-BLOCK-22",
    exception: "This has happened",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.195",
    shortname: "heroin-block-26",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HEROIN-BLOCK-26",
    depending: "DA0",
    exception: "Heroin",
    condition: "in",
    link: "HEROIN-BLOCK-27",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.195.1",
    extend: "true",
    shortname: "heroin-block-27",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HEROIN-BLOCK-27",
    depending: "HEROIN-BLOCK-26",
    exception: "This has happened",
    link: "HEROIN-BLOCK-28",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.195.2",
    extend: "true",
    shortname: "heroin-block-28",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-28",
    depending: "HEROIN-BLOCK-26",
    exception: "This has happened",
    link: "HEROIN-BLOCK-29",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.195.3",
    extend: "true",
    shortname: "heroin-block-29",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-29",
    depending: "HEROIN-BLOCK-26",
    exception: "This has happened",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.196",
    shortname: "heroin-block-30",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HEROIN-BLOCK-30",
    depending: "DA0",
    exception: "Heroin",
    condition: "in",
    link: "HEROIN-BLOCK-31",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.196.1",
    extend: "true",
    shortname: "heroin-block-31",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HEROIN-BLOCK-31",
    depending: "HEROIN-BLOCK-30",
    exception: "This has happened",
    link: "HEROIN-BLOCK-32",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.196.2",
    extend: "true",
    shortname: "heroin-block-32",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-32",
    depending: "HEROIN-BLOCK-30",
    exception: "This has happened",
    link: "HEROIN-BLOCK-33",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.196.3",
    extend: "true",
    shortname: "heroin-block-33",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-33",
    depending: "HEROIN-BLOCK-30",
    exception: "This has happened",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.197",
    shortname: "heroin-block-34",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HEROIN-BLOCK-34",
    depending: "DA0",
    exception: "Heroin",
    condition: "in",
    link: "HEROIN-BLOCK-35",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.197.1",
    extend: "true",
    shortname: "heroin-block-35",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HEROIN-BLOCK-35",
    depending: "HEROIN-BLOCK-34",
    exception: "This has happened",
    link: "HEROIN-BLOCK-36",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.197.2",
    extend: "true",
    shortname: "heroin-block-36",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-36",
    depending: "HEROIN-BLOCK-34",
    exception: "This has happened",
    link: "HEROIN-BLOCK-37",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.197.3",
    extend: "true",
    shortname: "heroin-block-37",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-37",
    depending: "HEROIN-BLOCK-34",
    exception: "This has happened",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.198",
    shortname: "heroin-block-38",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HEROIN-BLOCK-38",
    depending: "DA0",
    exception: "Heroin",
    condition: "in",
    link: "HEROIN-BLOCK-39",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.198.1",
    extend: "true",
    shortname: "heroin-block-39",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HEROIN-BLOCK-39",
    depending: "HEROIN-BLOCK-38",
    exception: "This has happened",
    link: "HEROIN-BLOCK0",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.198.2",
    extend: "true",
    shortname: "heroin-block-40",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-40",
    depending: "HEROIN-BLOCK-38",
    exception: "This has happened",
    link: "HEROIN-BLOCK-41",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.198.3",
    extend: "true",
    shortname: "heroin-block-41",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-41",
    depending: "HEROIN-BLOCK-38",
    exception: "This has happened",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.199",
    shortname: "heroin-block-42",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HEROIN-BLOCK-42",
    depending: "DA0",
    exception: "Heroin",
    condition: "in",
    link: "HEROIN-BLOCK-43",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.199.1",
    extend: "true",
    shortname: "heroin-block-43",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HEROIN-BLOCK-43",
    depending: "HEROIN-BLOCK-42",
    exception: "This has happened",
    link: "HEROIN-BLOCK-44",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.199.2",
    extend: "true",
    shortname: "heroin-block-44",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-44",
    depending: "HEROIN-BLOCK-42",
    exception: "This has happened",
    link: "HEROIN-BLOCK-45",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.199.3",
    extend: "true",
    shortname: "heroin-block-45",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-45",
    depending: "HEROIN-BLOCK-42",
    exception: "This has happened",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.200",
    shortname: "heroin-block-46",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HEROIN-BLOCK-46",
    depending: "DA0",
    exception: "Heroin",
    condition: "in",
    link: "HEROIN-BLOCK-47",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.200.1",
    extend: "true",
    shortname: "heroin-block-47",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HEROIN-BLOCK-47",
    depending: "HEROIN-BLOCK-46",
    exception: "This has happened",
    link: "HEROIN-BLOCK-48",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.200.2",
    extend: "true",
    shortname: "heroin-block-48",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-48",
    depending: "HEROIN-BLOCK-46",
    exception: "This has happened",
    link: "HEROIN-BLOCK-49",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.200.3",
    extend: "true",
    shortname: "heroin-block-49",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HEROIN-BLOCK-49",
    depending: "HEROIN-BLOCK-46",
    exception: "This has happened",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.201",
    shortname: "heroin-block-50",
    name: "Number of criteria met for:",
    prevalue: "Number of criteria met for Heroin:",
    value: "0",
    disabled: "true",
    type: "content",
    comment: "Consider no diagnosis for Heroin",
    act: "HEROIN-BLOCK-50",
    depending: "DA0",
    exception: "Heroin",
    condition: "in",
    meaningfulcount: "This has happened",
    subtopic_container_act: "HEROIN-BLOCK-SUBTOPIC",
    suptopic_code: "HEROIN-BLOCK-12-CONTENT",
    subtopic_name: "Content Criteria",
  },
  //!========================================================================================
  /*                                                                                      *
   *                                    ENDBLOCKHEROIN                                    *
   *                                                                                      */
  //!========================================================================================
];

export default blockHeroin;
