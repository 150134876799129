const strengthsJSON = {
  section: "SAW",
  topic: "Strengths and Challenges",
  checked: "true",
  // access: "private",
  color: "secondary",
  description: "(optional)",
  body: [
    {
      id: "SAW.1",
      shortname: "saw1",
      mandatory: "true",
      name:
        "In my opinion I have the following personal strengths and positive personality characteristics:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "text",
      act: "SAW1",
      depending: "",
      exception: "",
      extend: "",
      link: "",
    },
    {
      id: "SAW.2",
      shortname: "saw2",
      mandatory: "true",
      name:
        "In my opinion I have the following personal challenges and negative personality characteristics:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "text",
      act: "SAW2",
      depending: "",
      exception: "",
      extend: "",
      link: "",
    },
    {
      id: "SAW.3",
      access: "private",
      shortname: "saw3",
      mandatory: "true",
      name:
        "In this evaluator’s opinion this patient has the following personal strengths and positive personality characteristics:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "text",
      act: "SAW3",
      depending: "",
      exception: "",
      extend: "",
      link: "",
    },
    {
      id: "SAW.4",
      access: "private",
      shortname: "saw4",
      mandatory: "true",
      name:
        "In this evaluator’s opinion this patient has the following personal challenges and negative personality characteristics:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "text",
      act: "SAW4",
      depending: "",
      exception: "",
      extend: "",
      link: "",
    },
  ],
};

export default strengthsJSON;
