import initialState from "./state";

const dahreducer = (state = initialState, action) => {
  // let act = action.type.split("_");
  // let tmp_state = {};
  switch (action.type) {
    case "DAH_TEST":
      return state;
    default:
      return state;
  }
};

export default dahreducer;
