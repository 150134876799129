import base64url from "base64url";
import moment from "moment";
import {Constants,asiCommentField,needRuleClarification,shortNameForExport} from "./constants.js";
import adultPattern from "../store/adultPattern";
import adolescentPattern from "../store/adolescentPattern";
import blockAlcohol from "../store/jsonsAdolescent/blockAlcohol"
import blockCannabis from "../store/jsonsAdolescent/blockCannabis"
import blockCocaine from "../store/jsonsAdolescent/blockCocaine"
import blockCrack from "../store/jsonsAdolescent/blockCrack"
import blockCrank from "../store/jsonsAdolescent/blockCrank"
import blockEcstasy from "../store/jsonsAdolescent/blockEcstasy"
import blockHallucinogens from "../store/jsonsAdolescent/blockHallucinogens"
import blockHeroin from "../store/jsonsAdolescent/blockHeroin"
import blockInhalants from "../store/jsonsAdolescent/blockInhalants"
import blockMeth from "../store/jsonsAdolescent/blockMeth"
import blockMethadone from "../store/jsonsAdolescent/blockMethadone"
import blockOpiates from "../store/jsonsAdolescent/blockOpiates"
import blockSedative from "../store/jsonsAdolescent/blockSedative"
import blockTobacco from "../store/jsonsAdolescent/blockTobacco"
import blockOther from "../store/jsonsAdolescent/blockOther"
import HafServices  from "./UserFunctions";
import axios from "axios";


/*=============================*/
/* CONSTANTS */
/*=============================*/
// const sectionsToIgnore = ["AC", "SUM"];
// const sectionsForCheck = ["AC", "SUM"];

/*=============================*/
/* FUNCTIONS */
/*=============================*/

const getConnection = (dispatch, authParam) => {
	dispatch({ type: "SET_AUTH", payload: "" });
};

const printKey = (copy, obj) => {
	for (var key in obj) {
		if (typeof obj[key] == "object") {
			copy = printKey(copy, obj[key]);
		} else {
			copy.push(key + ":" + obj[key]);
		}
	}
	return copy;
};

const printKey1 = (copy) => {
	return copy;
};

function decode(jwt) {
	const [headerB64, payloadB64] = jwt.split(".");
	const headerStr = base64url.decode(headerB64);
	const payloadStr = base64url.decode(payloadB64);
	return {
		header: JSON.parse(headerStr),
		payload: JSON.parse(payloadStr),
	};
}

const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",")  => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
};

const getRelatedFields = (field, allFieldsInForm) => {
	try {
		//collect all related fields (dependencies, links, subtopics, ...)
		let curItemType = (field.type || "").toUpperCase();
		let relFields = [field];
		let searchCount = 0;

		//--collect all fields within a subsection or rank
		if (curItemType === "SUBTOPIC" || curItemType === "RANK") {
			relFields = relFields.concat(allFieldsInForm.filter((el) => el.subtopic_container_act === field.act));
		}

		//--collect all fields that are linked together
		for (let i = 0; i < relFields.length; i++) {
			searchCount += 1;
			if (searchCount > 600) {
				console.log(relFields);
//				throw "ERROR - too many links";
				throw Object.assign(new Error("ERROR - too many links"));
			}

			if (relFields[i].link) {
				relFields = relFields.concat(allFieldsInForm.filter((el) => el.act === relFields[i].link));
			}
		}

		//--collect all fields that are dependent on any of the fields found so far
		relFields = Array.from(new Set(relFields));
		searchCount = 0;
		for (let i = 0; i < relFields.length; i++) {
			searchCount += 1;
			if (searchCount > 2000) {
				console.log(relFields);
				// throw "ERROR - too many dependencies";
				throw Object.assign(new Error("ERROR - too many dependencies"));
			}
			relFields = relFields.concat(allFieldsInForm.filter((el) => el.depending === relFields[i].act));
		}

		//return unique array
		return Array.from(new Set(relFields));
	} catch (err) {
		console.log(err, field);
		return [];
	}

	// return [];
};

const isValueEmpty =(valToTest) =>{
	if(
		typeof valToTest =="undefined" ||
		valToTest === null ||
		valToTest.toString().trim() === ""
	){
		return true;
	} else{
		return false;
	}
}

const isFieldValid = (field) => {
	//textareas and fields belonging to sectionsToIgnore
	if(
		field.type === "textarea" || field.type === "editor" ||
		!field.id ||
		Constants.sectionsToIgnore.includes(field.id.split(".")[0].toString().toUpperCase())
	){
		return true;
	}

	//any non-sutopic or rank field that is empty is invalid
	if(
		field.disabled !== "true" &&
		field.type !== "subtopic" &&
		field.type !== "rank" &&
		!Constants.fieldToIgnore.includes(field.act) &&
		isValueEmpty(field.value)
	){
		return false;
	}

	//all fields with data are valid
	return true;
};

const isFieldArrValid = (fieldsToCheck) => {
	fieldsToCheck = fieldsToCheck || [];
	for(var i=0; i<fieldsToCheck.length; i++){
		if(!isFieldValid(fieldsToCheck[i])){
			return false;
		}
	}
	return true;
};

const isDeepFieldValid = (field, allFieldsInForm) => {
	return isFieldArrValid(getRelatedFields(field, allFieldsInForm));
}

const checkReadyElArr = (fieldsToCheck,rankCircleStatus) => {
  // console.log("fieldsToCheck=",fieldsToCheck)
  fieldsToCheck = fieldsToCheck || [];
  let result = Constants.FIELD_DANGER;
  let resultArr = [];
  fieldsToCheck.forEach((el) => {
    if(Object.prototype.hasOwnProperty.call(el,"mandatory")){
      if(el.value!=="" || el.type==="subtopic" ||  Constants.fieldToIgnore.includes(el.act)){
        result = Constants.FIELD_SUCCESS;
        // console.log("el.id=",el.id," success")
        resultArr.push(result);
      } else {
        if(el.disabled==="true"){
          result = Constants.FIELD_SUCCESS;
          // console.log("el.id=",el.id," success")
          resultArr.push(result);
        } else {
          if(el.disabled==="false"){
            result = Constants.FIELD_DANGER;
            // console.log("el.id=",el.id," danger")
            resultArr.push(result);
          }
        }
      }
    } else {
      if(el.value!=="" || el.type==="subtopic" ||  Constants.fieldToIgnore.includes(el.act) || (el.value==="" && el.type==="rank" && rankCircleStatus) ){
        result = Constants.FIELD_SUCCESS;
        // console.log("el.id=",el.id," success")
        resultArr.push(result);
      } else {
        if(el.disabled==="true"){
          result = Constants.FIELD_SUCCESS;
          // console.log("el.id=",el.id," success")
          resultArr.push(result);
        } else {
          if(el.disabled==="false"){
            result = Constants.FIELD_WARNING;
            // console.log("el.id=",el.id," warning")
            resultArr.push(result);
          }
        }
      }
    }
  })
  // console.log(resultArr);
  // console.log(Math.min(...resultArr))
  // console.log("===============")
  return (Math.min(...resultArr))
}
// const jsonCheckTopic = (json, dangerElem) => {
// 	const arr_json = json;
// 	const dangerTopic = [];
// 	const MySet = new Set(dangerElem);
// 	for (var i = 0; i < arr_json.length; i++) {
// 		for (var j = 0; j < arr_json[i].body.length; j++) {
// 			if (MySet.has(arr_json[i].body[j].act) && arr_json[i].checked!=="false") {
// 				dangerTopic.push(arr_json[i].topic);
// 			}
// 		}
// 	}
// 	var unique = dangerTopic.filter(function (elem, index, self) {
// 		return index === self.indexOf(elem);
// 	});
//   console.log("MySet=",MySet);
//   console.log("dangerTopic=",dangerTopic);
//   console.log("unique=",unique);

// 	return unique;
// };

const jsonCheckTopic = (json, dangerElem) => {
	const arr_json = json;
	const dangerTopic = [];
	const MySet = new Set(dangerElem);
	for (var i = 0; i < arr_json.length; i++) {
		for (var j = 0; j < arr_json[i].body.length; j++) {
			if (MySet.has(arr_json[i].body[j].act) && arr_json[i].checked!=="false") {
				dangerTopic.push(arr_json[i].topic);
			}
		}
	}
  const res = dangerTopic.reduce((acc, i) => {
    // console.log("jsonCheckTopic i=",i,"MySet=",MySet);
    if (acc.hasOwnProperty(i)) {
      acc[i] += 1;
    } else {
      acc[i] = 1;
    }
    return acc;
  },{})
  // console.log("res=",res," ",Object.keys(res));

  return res;
	// var unique = dangerTopic.filter(function (elem, index, self) {
	// 	return index === self.indexOf(elem);
	// });
  // console.log("MySet=",MySet);
  // console.log("dangerTopic=",dangerTopic);
  // console.log("unique=",unique);
	// return unique;
};

const jsonCheckNormal = (json, adult_type) => {
  // console.log("adult_type=", adult_type);
  const arr_json = json;
  const arr_dep = [];
  let dangerElem = [];

  for (let i = 0; i < arr_json.length; i++) {
    for (let j = 0; j < arr_json[i].body.length; j++) {
      if (((arr_json[i].body[j].value === "" &&
          arr_json[i].body[j].id !== "" &&
          arr_json[i].body[j].type !== "rank" &&
          arr_json[i].body[j].disabled === "false" &&
          arr_json[i].body[j].type !== "subtopic" &&
          arr_json[i].body[j].type !== "editor" &&
          arr_json[i].body[j].type !== "textarea" &&
          !Constants.fieldToIgnore.includes(arr_json[i].body[j].act) && adult_type !== "ASILite" && arr_json[i].body[j].mandatory !== "true")) ||
        ((arr_json[i].body[j].value === "" &&
          arr_json[i].body[j].id !== "" &&
          arr_json[i].body[j].type !== "rank" &&
          arr_json[i].body[j].disabled === "false" &&
          arr_json[i].body[j].type !== "subtopic" &&
          arr_json[i].body[j].type !== "editor" &&
          arr_json[i].body[j].type !== "textarea" &&
          !Constants.fieldToIgnore.includes(arr_json[i].body[j].act) &&
              adult_type === "ASILite" && Object.prototype.hasOwnProperty.call(arr_json[i].body[j],"onlyasi") === false && arr_json[i].body[j].mandatory !== "true"))) {
        dangerElem.push(arr_json[i].body[j].act);
      }
    }
  }
// console.log("jsonCheckNormal dangerElem=",dangerElem);
  return dangerElem;
};


const jsonCheck = (json,adult_type) => {
	// console.log("adult_type=", adult_type);
	const arr_json = json;
	const arr_dep = [];
	let dangerElem = [];

	for (let i = 0; i < arr_json.length; i++) {
		for (let j = 0; j < arr_json[i].body.length; j++) {
			if ((arr_json[i].body[j].depending !== "" && arr_json[i].section !== "SUM" && !Constants.fieldToIgnore.includes(arr_json[i].body[j].depending)))  {
				arr_dep.push(arr_json[i].body[j].depending);
			}
			if (
				(arr_json[i].body[j].shortname === "ed-new19" && arr_json[i].body[j].depending1 !== "") ||
				(arr_json[i].body[j].shortname === "ed-new20" && arr_json[i].body[j].depending1 !== "")
			) {
				arr_dep.push(arr_json[i].body[j].depending1);
			}
			if(arr_json[i].body[j].mandatory==="true"){
				arr_dep.push(arr_json[i].body[j].act);
			}

		}
	}
// console.log("arr_dep=",arr_dep)
let QuantitySubstances=0;
// console.log(arr_json.filter((el) =>el.section === "DAH"))
if(arr_json.filter((el) =>el.section === "DAH").length)
{
	QuantitySubstances = Object.assign({},arr_json.filter((el) =>el.topic === "Drug and Alcohol History")[0].body.filter(el1=>el1.shortname === "da0")[0]).value.filter(el=>el).length;
}
	// console.log("QuantitySubstances=",QuantitySubstances);
	let sumRanks = 0;
	for (var i = 0; i < arr_json.length; i++) {
		let topic = arr_json[i];
		// console.log("topic=",topic)
		for (var j = 0; j < topic.body.length; j++) {
			let fieldObj = Object.assign({}, topic.body[j]);
			if(fieldObj.type==="rank" && fieldObj.disabled==="false"){
				sumRanks += Number(fieldObj.value)
			}
		}
	}
	// console.log("sumRanks=",sumRanks);


	const MySet = new Set(arr_dep);
  // console.log("MySet=",MySet,"arr_dep=",arr_dep);
	for (let i = 0; i < arr_json.length; i++) {
		for (let j = 0; j < arr_json[i].body.length; j++) {
      // if(MySet.has(arr_json[i].body[j].act) && arr_json[i].body[j].value === ""  && arr_json[i].body[j].disabled === "false")
      //   console.log(arr_json[i].body[j].act,"=>>>"/*,findInputControl(arr_json[i].body[j].act,arr_json[i].body[j].value)*/);
      //  if(MySet.has(arr_json[i].body[j].act)  && findInputControl(arr_json[i].body[j].act,arr_json[i].body[j].value).statusField!=="ok" && arr_json[i].body[j].disabled === "false")
      //    console.log(arr_json[i].body[j].act,"=>>>"/*,findInputControl(arr_json[i].body[j].act,arr_json[i].body[j].value)*/);
			if (
				(MySet.has(arr_json[i].body[j].act) && (arr_json[i].body[j].value === "" || findInputControl(arr_json[i].body[j].act,arr_json[i].body[j].value).statusField!=="ok") && arr_json[i].body[j].disabled === "false" && adult_type!=="ASILite")
        || (MySet.has(arr_json[i].body[j].act) && (arr_json[i].body[j].value === "" || findInputControl(arr_json[i].body[j].act,arr_json[i].body[j].value).statusField!=="ok") && arr_json[i].body[j].disabled === "false" && adult_type==="ASILite" && Object.prototype.hasOwnProperty.call(arr_json[i].body[j], "onlyasi") === false)
				|| (arr_json[i].body[j].type === "rank" && arr_json[i].body[j].value === "" && arr_json[i].body[j].disabled === "false" && QuantitySubstances >= 3 && sumRanks === 5)
				|| (arr_json[i].body[j].type === "rank" && arr_json[i].body[j].value === "" && arr_json[i].body[j].disabled === "false" && QuantitySubstances >= 3 && sumRanks === 4)
				|| (arr_json[i].body[j].type === "rank" && arr_json[i].body[j].value === "" && arr_json[i].body[j].disabled === "false" && QuantitySubstances >= 3 && sumRanks === 3)
				|| (arr_json[i].body[j].type === "rank" && arr_json[i].body[j].value === "" && arr_json[i].body[j].disabled === "false" && QuantitySubstances >= 2 && sumRanks === 1)
				|| (arr_json[i].body[j].type === "rank" && arr_json[i].body[j].value === "" && arr_json[i].body[j].disabled === "false" && QuantitySubstances >= 1 && sumRanks === 0)
			) {
        // console.log("arr_json[i].body[j].act=",arr_json[i].body[j].act)
				dangerElem.push(arr_json[i].body[j].act);
			}
		}
	}

	// console.log("dangerElem=",dangerElem);
  // console.log("jsonCheck dangerElem=",dangerElem);
	return dangerElem;
};
const calcFullYear = (dob) => {
	return moment().diff(dob, "years", false);
};

const jsonCheckboxElem = (string_json, shortname) => {
  let result = [];
  const arr_json = string_json;
  for (var i = 0; i < arr_json.length; i++) {
    for (var j = 0; j < arr_json[i].body.length; j++) {
      if (arr_json[i].body[j].shortname === shortname) {
        result = arr_json[i].body[j].radio_options.filter((el, index) => {
          if (arr_json[i].body[j].value[index]) return el;
        });
      }
      // return arr_json[i].body[j];
    }
  }
  return result;
};

const jsonSearchType = (string_json, act) => {
  let result = "";
  const arr_json = string_json;
  for (var i = 0; i < arr_json.length; i++) {
    for (var j = 0; j < arr_json[i].body.length; j++) {
      if (arr_json[i].body[j].act === act) {
        result = arr_json[i].body[j].type;
        return result;
      }
    }
  }
  return result;
};


const jsonCheckboxElemLink = (string_json, link) => {
  let result = [];
  const arr_json = string_json;
  for (var i = 0; i < arr_json.length; i++) {
    for (var j = 0; j < arr_json[i].body.length; j++) {
      if (arr_json[i].body[j].act === link && arr_json[i].body[j].type==="checkbox") {
        result = arr_json[i].body[j].radio_options.filter((el, index) => {
          if(arr_json[i].body[j].value[index]) return el;
        });
        return result;
      }
    }
  }
  return result;
};

const jsonElem = (string_json, link) => {
	const arr_json = string_json;
	for (var i = 0; i < arr_json.length; i++) {
		for (var j = 0; j < arr_json[i].body.length; j++) {
			if (arr_json[i].body[j].act === link) return arr_json[i].body[j];
		}
	}
	return null;
};
// const setJsonTopic = (arr_json, num_tab) => {
// 	for (var i = 0; i < arr_json.length; i++) {
// 		if (i === num_tab) {
// 			return arr_json[i].topic;
// 		}
// 	}
// };
const setJsonTopic = (arr_json, num_tab,scope) => {
  for (let loop = 0; loop < 2; loop++) {
	  if(loop>0)num_tab=0
    for (var i = 0; i < arr_json.length; i++) {
	  if ((i >= num_tab && arr_json[i].checked !== "false" && (Constants.CURRENT_SUPERUSER_MODE !==Constants.SUPERUSER_MODE_PROTECTED || !Constants.sectionsWhichIgnoreForProtected.includes(arr_json[i].section) && scope==="global")) ||
	   (i >= num_tab && arr_json[i].checked !== "false" &&  scope!=="global")) {
        return arr_json[i].topic;
      }
    }
  }
//   console.log("arr_json[0].topic=", arr_json[0].topic);
  return arr_json[0].topic;
};

const getJsonTopic = (arr_json, tab) => {
	for (var i = 0; i < arr_json.length; i++) {
		if (arr_json[i].topic === tab) {
			return i;
		}
	}
};
const getJsonMaxTopic = (arr_json) => {
	return arr_json.length;
};

const getJsonTopicByNum = (arr_json, num_tab) => {
	console.log("num_tab=",num_tab);
	for (var i = 0; i < arr_json.length; i++) {
		if (i >= num_tab &&( Constants.CURRENT_SUPERUSER_MODE!==Constants.SUPERUSER_MODE_PROTECTED || !Constants.sectionsWhichIgnoreForProtected.includes(arr_json[i].section))) {
			return arr_json[i].section;
		}
	}
};

const updateBaseJsonVal = (arr, payload) => {
	let tmp = arr.map((el) =>
		el.topic !== undefined
			? {
					...el,
					body: el.body.map((el1) => {
						if (el1.shortname === payload.shortname) {
							return { ...el1, value: payload.val };
						} else {
							return { ...el1 };
						}
					}),
			  }
			: el
	);
	return [...tmp];
};

const updateBaseJsonVal2 = (arr, payload) => {
	let tmp = arr.map((el) =>
		el.topic !== undefined
			? {
					...el,
					body: el.body.map((el1) => {
						if (el1.shortname === payload.shortname) {
							return { ...el1, value2: payload.val };
						} else {
							return { ...el1 };
						}
					}),
			  }
			: el
	);
	return [...tmp];
};

const updateBaseJsonDisabled = (arr, payload) => {
	let tmp = arr.map((el) =>
		el.topic !== undefined
			? {
					...el,
					body: el.body.map((el1) => {
						if (el1.shortname === payload.shortname) {
							console.log("el1.shortname=", el1.shortname, " payload.val=", payload.val);
							return { ...el1, disabled: payload.disable };
						} else {
							return { ...el1 };
						}
					}),
			  }
			: el
	);
	return [...tmp];
};

const migrationFork = (arr, payload) => {
	let tmp = arr.map((el) =>
		el.topic !== undefined
			? {
					...el,
					body: el.body.map((el1) => {
						if (el1.shortname === payload.shortname) {
							if (el1.type === "checkbox") {
								return {
									...el1,
									value: payload.value.map((el2) => el2),
								};
							} else {
								return { ...el1, value: payload.value };
							}
						} else {
							return { ...el1 };
						}
					}),
			  }
			: el
	);
	return [...tmp];
};

const getElem = (arr_json, payload) => {
	for (var i = 0; i < arr_json.length; i++) {
		for (var j = 0; j < arr_json[i].body.length; j++) {
			if (arr_json[i].body[j].shortname === payload.shortname) {
        // console.log("arr_json[i].body[j]=",arr_json[i].body[j],"payload=",payload);
				return getAction(arr_json[i].body[j], payload);
			}
		}
	}
	return { type: "NULL", payload: null };
};

const getAction = (elem, payload) => {
  // console.log("payload=",payload)
	if (elem.type === "checkbox") {
		let arr_1 = [];
		if (typeof payload.value === "object") {
			payload.value.forEach((el, index) => {
				if (el === true)
					arr_1.push({
						type: elem.act + "_CHECKBOX_INPUT_CHANGE",
						payload: index,
					});
			});
      if(arr_1.length>0)
			  return arr_1;
      else
        return { type: "NULL", payload: null };
		}
		return { type: "NULL", payload: null };
	} else if (elem.type === "radio") {
		return { type: elem.act + "_RADIO_INPUT_CHANGE", payload: payload.value===undefined?"":payload.value };
	} else if (elem.type === "select") {
		return { type: elem.act + "_FIELD_INPUT_CHANGE", payload: payload.value===undefined?"":payload.value };
	} else if (elem.type === "year") {
		return { type: elem.act + "_FIELD_INPUT_CHANGE", payload: payload.value===undefined?"":payload.value };
	} else if (elem.type === "money") {
		return { type: elem.act + "_MONEY_INPUT_CHANGE", payload: payload.value===undefined?"":payload.value };
	} else if (elem.type === "date") {
		return { type: elem.act + "_FIELD_INPUT_CHANGE", payload: payload.value===undefined?"":payload.value };
	} else if (elem.type === "number") {
		return { type: elem.act + "_FIELD_INPUT_CHANGE", payload: payload.value===undefined?"":payload.value };
	} else if (elem.type === "textarea") {
		return { type: elem.act + "_FIELD_INPUT_CHANGE", payload: payload.value===undefined?"":payload.value };
	} else if (elem.type === "month") {
		return { type: elem.act + "_FIELD_INPUT_CHANGE", payload: payload.value===undefined?"":payload.value };
	} else if (elem.type === "rank") {
		return { type: elem.act + "_FIELD_INPUT_CHANGE", payload: payload.value===undefined?"":payload.value };
	} else if (elem.type === "subtopic") {
		return { type: elem.act + "_FIELD_INPUT_CHANGE", payload: payload.value===undefined?"":payload.value };
	} else if (elem.type === "text") {
		return { type: elem.act + "_FIELD_INPUT_CHANGE", payload: payload.value===undefined?"":payload.value };
	} else if (elem.type === "withcode") {
		return { type: elem.act + "_FIELD_INPUT_CHANGE", payload: payload.value===undefined?"":payload.value };
	} else if (elem.type === "withtitle") {
		return { type: elem.act + "_FIELD_INPUT_CHANGE", payload: payload.value===undefined?"":payload.value };
	} else if (elem.type === "editor") {
		return { type: elem.act + "_FIELD_INPUT_CHANGE", payload: payload.value===undefined?"":payload.value };
	} else if (elem.type === "year_month") {
		return [{ type: elem.act + "_FIELD_INPUT_CHANGE", payload: payload.value===undefined?"":payload.value },{ type: elem.act + "_FIELD2_INPUT_CHANGE", payload: payload.value2===undefined?"":payload.value2 }];
	} else if (elem.type === "hour_minute") {
		return [{ type: elem.act + "_FIELD_INPUT_CHANGE", payload: payload.value===undefined?"":payload.value },{ type: elem.act + "_FIELD2_INPUT_CHANGE", payload: payload.value2===undefined?"":payload.value2 }];
	} else {
		return { type: elem.act + "_FIELD_INPUT_CHANGE", payload: payload.value===undefined?"":payload.value };
	}
};
/*
const updateGenderFork = (arr, payload) => {
	console.log("updateGenderFork");
	let tmp = arr.map((el) =>
		el.topic !== undefined
			? {
					...el,
					body: el.body.map((el1) => {
						if (el1.depending === payload.depending) {
							if (payload.val !== el1.exception && payload.val !== "Other") {
								return { ...el1, disabled: "true", value: "" };
							} else {
								return { ...el1, disabled: "false" };
							}
						} else {
							return { ...el1 };
						}
					}),
			  }
			: el
	);
	return [...tmp];
};

const updateSexFork = (arr, payload,title) => {
	let tmp = arr.map((el) =>
		el.topic !== undefined
			? {
					...el,
					body: el.body.map((el1) => {
						// console.log("updateSexFork=",title);
						if ((el1.act === "M-NEW15" || el1.act === "M-NEW16" || el1.act === "M14" || el1.act === "M-NEW18"))
						{
							if(payload.val === "Female")
								return { ...el1, disabled: "false" };
							else
								return { ...el1, disabled: "true", value: "" };
						} else {
							if (el1.act === "M-NEW19"){
								if(payload.val === "Male")
								return { ...el1, disabled: "false" };
							else
								return { ...el1, disabled: "true", value: "" };
							} else{
								return { ...el1 };
							}
						}
					})
			  }
			: el
	);
	return [...tmp];
};
*/
const updateGenderFork = (arr, payload) => {
	// console.log("updateGenderFork");
  // const elemGender = jsonElem(arr,"M0");
  // console.log("elemGender=",elemGender);
  // const elemSex = jsonElem(arr,"M-NEW14");
  // console.log("elemSex=",elemSex);
  const elemSection = jsonElem(arr,"M1");
  // console.log("payload=",payload);

	let tmp = arr.map((el) =>
		el.topic !== undefined
			? {
					...el,
					body: el.body.map((el1) => {
						if (el1.depending === payload.depending) {
							if (payload.val !== el1.exception && payload.val !== "Other" || elemSection.act==="M1" && elemSection.value==="Refused to answer" && el1.depending1==="M1") {
                // console.log("updateGenderFork  elemSection.act=",elemSection.act,"elemSection.value=",elemSection.value,"el1.shortname=",el1.shortname);
								return { ...el1, disabled: "true", value: "" };
							} else {
								return { ...el1, disabled: "false" };
							}
						} else {
							return { ...el1 };
						}
					}),
			  }
			: el
	);
  // console.log("------------------------------------------------")
	return [...tmp];
};

const updateSexFork = (arr, payload, title) => {
  console.log("updateSexFork");
  // const elemGender = jsonElem(arr,"M0");
  // console.log("elemGender=",elemGender);
  // const elemSex = jsonElem(arr,"M-NEW14");
  // console.log("elemSex=",elemSex);
  const elemSection = jsonElem(arr, "M1");
  // console.log("elemSection=",elemSection);

  let tmp = arr.map((el) =>
    el.topic !== undefined
      ? {
          ...el,
          body: el.body.map((el1) => {
            console.log("updateSexFork=",title);
            console.log("payload=",payload);
            if (
              el1.act === "M-NEW15" ||
              el1.act === "M-NEW16" ||
              el1.act === "M14" ||
              el1.act === "M-NEW18" ||
              (el1.act === "M12-ADOL4" && payload.adult_type === "Adolescent") ||
              (el1.act === "M-NEW15-ADOL1" && payload.adult_type === "Adolescent")
            ) {
              // console.log("updateSexFork  elemSection.act=",elemSection.act,"elemSection.value=",elemSection.value);
              if (
                payload.val === "Female" &&
                elemSection.act === "M1" &&
                elemSection.value !== "Refused to answer"
              )
                return { ...el1, disabled: "false" };
              else return { ...el1, disabled: "true", value: "" };
            } else {
              if (el1.act === "M-NEW19") {
                if (
                  payload.val === "Male" &&
                  elemSection.act === "M1" &&
                  elemSection.value !== "Refused to answer"
                )
                  return { ...el1, disabled: "false" };
                else return { ...el1, disabled: "true", value: "" };
              } else {
                return { ...el1 };
              }
            }
          }),
        }
      : el
  );
  return [...tmp];
};

const updateM1 = (arr, payload) => {
  console.log("updateM1");
  const elemSection = jsonElem(arr, "M1");
  // console.log("elemSection=",elemSection)
  let tmp = arr.map((el) =>
    el.topic !== undefined
      ? {
          ...el,
          body:
            el.section === "M"
              ? el.body.map((el1) => {
                  if (
                    el1.shortname != "m1" &&
                    el1.shortname != "mh16c" &&
                    elemSection.value === ""
                  ) {
                    // console.log("el1=",el1);
                    return { ...el1, disabled: "true" };
                    // if (payload.val !== el1.exception && payload.val !== "Other" || elemSection.act==="M1" && elemSection.value==="Refused to answer" && el1.depending1==="M1") {
                    // 	return { ...el1, disabled: "true", value: "" };
                    // } else {
                    // 	return { ...el1, disabled: "false" };
                    // }
                  } else {
                    return { ...el1 };
                  }
                })
              : el.body,
        }
      : el
  );
  // console.log("------------------------------------------------")
  return [...tmp];
};

const updateMarriageFork = (arr, payload) => {
	let tmp = arr.map((el) =>
		el.topic !== undefined
			? {
					...el,
					body: el.body.map((el1) => {
						if ((el1.act === "FR08" || el1.act === "FR09" || el1.act === "FR10") && payload.val === "married") {
							return { ...el1, disabled: "false"/*, value: "" */};
						} else {
							if (el1.act === "FR10-1" && payload.val === "married") {
								return { ...el1, disabled: "true"/*, value: "" */};
							} else {
								if (
									(el1.act === "FR08" || el1.act === "FR09" || el1.act === "FR10" || el1.act === "FR10-1") &&
									payload.val !== "married"
								) {
									return { ...el1, disabled: "true"/*, value: "" */};
								}
								if (el1.depending === payload.depending) {
                  if (payload.val === el1.exception && el1.act==="FR08-00-C" ) {
                    return { ...el1, disabled: "false", value: "" };
                  } else {
                    if(payload.val !== el1.exception && el1.act==="FR08-00-C" )
                    {
                      return { ...el1, disabled: "true", value: "" };
                    } else {
                      if (payload.val === el1.exception || payload.val === "") {
                        return { ...el1, disabled: "true", value: "" };
                      } else {
                        return { ...el1, disabled: "false", value: "" };
                      }
                    }
                  }
									// if (payload.val === el1.exception || payload.val === "") {
									// 	return { ...el1, disabled: "true"/*, value: ""*/ };
									// } else {
									// 	return { ...el1, disabled: "false"/*, value: "" */};
									// }
								} else {
									return { ...el1 };
								}
							}
						}
					}),
			  }
			: el
	);
	return [...tmp];
};

const updateED8Fork = (arr, payload) => {
	let tmp = arr.map((el) =>
	  el.topic !== undefined
		? {
			...el,
			body: el.body.map((el1) => {
			  if (
				el1.depending === "ED7" &&
				payload.val.indexOf(" employed") < 0
			  ) {
				return { ...el1, disabled: "true", value: "" };
			  } else {
				return { ...el1 };
			  }
			}),
		  }
		: el
	);
	return [...tmp];
  };

const getParent = (arr_json, elem) => {
	for (var i = 0; i < arr_json.length; i++) {
		for (var j = 0; j < arr_json[i].body.length; j++) {
			if (arr_json[i].body[j].act === elem) {
				return arr_json[i].body[j].depending;
			}
		}
	}
};

const getSeq = (arr_json, elem) => {
	let arr_path = [];
	let count = 0;
	let nextElem = elem;
	while (nextElem !== null && nextElem !== "" && nextElem !== undefined) {
		arr_path.push(nextElem);
		nextElem = getParent(arr_json, nextElem);
		count++;
		if (count > 5) break;
	}
	return arr_path;
};

const findIndex = (score, elem, level) => {
	const tmp_elem = score.find((el) => el.seq[level] === elem && el.level === level);
	return tmp_elem.curId;
};

const makeList = (json) => {
	const arr_json = json;
	const arr_dep = [];
	const arr_dep1 = [];
	const arr_dep2 = [];
	let score = [];
	let curSection = "";
	let sectionArr = [];

	for (var i = 0; i < arr_json.length; i++) {
		curSection = 1;
		sectionArr.push(arr_json[i].section);
		for (var j = 0; j < arr_json[i].body.length; j++) {
			if (
				arr_json[i].body[j].depending !== "" &&
				arr_json[i].body[j].depending !== "ED-NEW19" &&
				arr_json[i].body[j].depending !== "ED-NEW20"
			) {
				arr_dep.push(arr_json[i].body[j].act);
				score.push({
					curId: arr_json[i].section + ".",
					section: arr_json[i].section,
					act: arr_json[i].body[j].act,
					level: 1,
				});
			} else {
				score.push({
					curId: arr_json[i].section + "." + curSection.toString(),
					section: arr_json[i].section,
					act: arr_json[i].body[j].act,
					level: 0,
				});
				curSection++;
			}
		}
	}

	for (let i = 0; i < arr_json.length; i++) {
		for (let j = 0; j < arr_json[i].body.length; j++) {
			if (arr_json[i].body[j].depending !== "") {
				if (arr_dep.find((el) => el === arr_json[i].body[j].depending) !== undefined)
					arr_dep1.push(arr_json[i].body[j].act);
			}
		}
	}
	for (let i = 0; i < arr_dep1.length; i++) {
		score = score.map((el) => (el.act === arr_dep1[i] ? { ...el, level: 2 } : el));
	}

	for (let i = 0; i < arr_json.length; i++) {
		for (let j = 0; j < arr_json[i].body.length; j++) {
			if (arr_json[i].body[j].depending !== "") {
				if (arr_dep1.find((el) => el === arr_json[i].body[j].depending) !== undefined)
					arr_dep2.push(arr_json[i].body[j].act);
			}
		}
	}
	for (let i = 0; i < arr_dep2.length; i++) {
		score = score.map((el) => (el.act === arr_dep1[i] ? { ...el, level: 3 } : el));
	}
	score = score.map((el) => ({
		...el,
		seq: getSeq(arr_json, el.act).reverse(),
	}));

	for (let i = 0; i < score.length; i++) {
		if (score[i].level === 1) score[i].curId = findIndex(score, score[i].seq[0], 0) + ".";
	}

	let tmpObj = [];
	for (let section = 0; section < sectionArr.length; section++) {
		for (let i = 0; i < score.length; i++) {
			if (score[i].level === 1 && score[i].section === sectionArr[section]) {
				tmpObj.push({
					curId: score[i].curId,
					section: score[i].section,
					act: score[i].act,
				});
			}
		}
	}
};

const returnClearValue = (el) => {
	if (el.type === "checkbox") {
		return el.radio_options.map((el1) => {
			return false;
		});
	} else return "";
};

const ArrInArr = (arr1, str) => {
  // console.log("arr1=",arr1,"str=",str)
	let arr2 = str.split(";");
	let has = false;
  // console.log("arr1=",arr1,"arr2=",arr2)
	for (let i = 0; i < arr1.length; i++) {
		for (let j = 0; j < arr2.length; j++) {
      if(arr1[i]!==null && arr1[i]!==undefined ){
        if (arr1[i].indexOf(arr2[j]) >= 0) {
        // if (arr1[i] === arr2[j]) {
          has = true;
          return has;
        }
      }
		}
	}
	return has;
};

const PrependZeros = function (str, len, seperator) {
	if (typeof str === "number" || Number(str)) {
		str = str.toString();
		return len - str.length > 0 ? new Array(len + 1 - str.length).join("0") + str : str;
	} else {
		for (
			var i = 0, spl = str.split(seperator || " ");
			i < spl.length;
			spl[i] = Number(spl[i]) && spl[i].length < len ? PrependZeros(spl[i], len) : spl[i],
				str = i === spl.length - 1 ? spl.join(seperator || " ") : str,
				i++
		);
		return str;
	}
};

const generateHafAssessment = (CompanyId, UserId, id, increment) => {
	const hafCode =
		"H" + PrependZeros(CompanyId, 3, "") + PrependZeros(UserId, 4, "") + "-" + PrependZeros(id + increment, 6, "");
	return hafCode;
};

const u_atob = (ascii) => {
	return Uint8Array.from(atob(ascii), (c) => c.charCodeAt(0));
};

const u_btoa = (buffer) => {
	var binary = [];
	var bytes = new Uint8Array(buffer);
	for (var i = 0, il = bytes.byteLength; i < il; i++) {
		binary.push(String.fromCharCode(bytes[i]));
	}
	return btoa(binary.join(""));
};

const s2ab = (s) => {
	var buf = new ArrayBuffer(s.length);
	var view = new Uint8Array(buf);
	for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
	return buf;
};
const getAllUrlParams = (url) => {
	let paramName = "";
	let queryString = url ? url.split("?")[1] : window.location.search.slice(1);
	let obj = {};
	if (queryString) {
		queryString = queryString.split("#")[0];
		let arr = queryString.split("&");
		for (var i = 0; i < arr.length; i++) {
			let a = arr[i].split("=");
			let paramNum = undefined;
			paramName = a[0].replace(/\[\d*\]/, function (v) {
				paramNum = v.slice(1, -1);
				return "";
			});
			let paramValue = typeof a[1] === "undefined" ? true : a[1];
			paramName = paramName.toLowerCase();
			if (obj[paramName]) {
				if (typeof obj[paramName] === "string") {
					obj[paramName] = [obj[paramName]];
				}
				if (typeof paramNum === "undefined") {
					obj[paramName].push(paramValue);
				} else {
					obj[paramName][paramNum] = paramValue;
				}
			} else {
				obj[paramName] = paramValue;
			}
		}
	}
	return obj;
};
const jsonShortElem = (arr_json, shortname) => {
	for (let i = 0; i < arr_json.length; i++) {
		for (let j = 0; j < arr_json[i].body.length; j++) {
			if (arr_json[i].body[j].shortname === shortname) {
				if (arr_json[i].body[j].type !== "radio") {
					if (arr_json[i].body[j].value === "") return null;
					else return arr_json[i].body[j].value;
				} else {
					return arr_json[i].body[j].value;
				}
			}
		}
	}
	return null;
};

const versionCheck = (verCheck, verBase) => {
	if (verCheck === undefined || verBase === undefined) return Constants.MAJOR_DIFF_VER;
	// console.log("verCheck=",verCheck,"verBase=",verBase);
	let verCheckToArray = verCheck.split(".");
	let verBaseToArray = verBase.split(".");
	if (verCheck[0] === undefined || verCheck[1] === undefined || verBase[0] === undefined || verBase[1] === undefined)
		return Constants.MAJOR_DIFF_VER;
	if (Number(verCheckToArray[0]) > Number(verBaseToArray[0])) {
		return Constants.MAJOR_DIFF_VER;
	} else {
		if (
			Number(verCheckToArray[0]) === Number(verBaseToArray[0]) &&
			Number(verCheckToArray[1]) > Number(verBaseToArray[1])
		) {
			return Constants.MINOR_DIFF_VER;
		} else {
			return Constants.EQUAL_VER;
		}
	}
};

const readMassive = (jsonState) => {
	let massive = [];
	for (let i = 0; i < jsonState.length; i++) {
		for (let j = 0; j < jsonState[i].body.length; j++) {
			massive.push({
				shortname: jsonState[i].body[j].shortname,
				value: jsonState[i].body[j].value,
        value2: jsonState[i].body[j].value2,
				type: jsonState[i].body[j].type,
				radio_options: jsonState[i].body[j].radio_options,
			});
		}
	}
	return massive;
};

// const readMassiveForExport = (jsonState) => {
// 	let massive = [];
// 	for (let i = 0; i < jsonState.length; i++) {
// 		for (let j = 0; j < jsonState[i].body.length; j++) {
// 			massive.push({
//         name: jsonState[i].body[j].name,
//         prevalue: jsonState[i].body[j].prevalue,
// 				shortname: jsonState[i].body[j].shortname,
// 				value: jsonState[i].body[j].value,
//         value2: jsonState[i].body[j].value2,
// 				type: jsonState[i].body[j].type,
// 				radio_options: jsonState[i].body[j].radio_options,
// 			});
// 		}
// 	}
// 	return massive;
// };

const searchParent = (jsonState, el) => {
  for (let i = 0; i < jsonState.length; i++) {
    for (let j = 0; j < jsonState[i].body.length; j++) {
      if (jsonState[i].body[j].act === el.depending) {
        return jsonState[i].body[j];
      }
    }
  }
  return null;
};

const readMassiveForExportVer2 = (jsonState,assessment) => {
  let massive = [];
  for (let i = 0; i < jsonState.length; i++) {
    // console.log(jsonState[i].section)
    if (jsonState[i].section === "PL") {
      for (let j = 0; j < jsonState[i].body.length; j++) {
        // console.log(shortNameForExport)
        if (
          shortNameForExport.includes(jsonState[i].body[j].shortname)
          &&  jsonState[i].body[j].value === "Treat"
        ) {
          massive.push({...jsonState[i].body[j],...assessment});
        }
      }
    }
  }
  return massive;
};

const readMassiveForExport = (jsonState) => {
  let massive = [];
  for (let i = 0; i < jsonState.length; i++) {
    if(jsonState[i].section==="PL"){
      for (let j = 0; j < jsonState[i].body.length; j++) {
        let subResult = [];
        let parentObj = {};
        // if(jsonState[i].body[j].type==="checkbox" && jsonState[i].body[j].value.filter(el=>el===true).length>0){
        //   console.log(">",jsonState[i].body[j])
        // }
        if ((jsonState[i].body[j].type!=="checkbox" && jsonState[i].body[j].value !== "") || (jsonState[i].body[j].type==="checkbox" && jsonState[i].body[j].value.filter(el=>el===true).length>0)) {
          // if(jsonState[i].body[j].depending==="")
          if (jsonState[i].body[j].depending !== "") {
            // добавляю в subResult
            // запускаю searchParent для поиска родительского объекта
            // проверяю у родительского обънета depending !== ""
            parentObj = jsonState[i].body[j];
            subResult.push(parentObj);
            // console.log(parentObj);
            do {
              parentObj = searchParent(jsonState, parentObj);
              subResult.push(parentObj);
              // if (parentObj.id.includes("DI.9")){
              //   console.log(">>>>>>>>>>>>>>>>",parentObj);
              //   console.log(">>>>>>>>>>>>>>>>",subResult);
              // }
              if (subResult.length > 10) break;
            } while (parentObj !== null && parentObj.depending !== "");
          } else {
            subResult.push(jsonState[i].body[j]);
          }
          massive.push(subResult);
        }
      }
    }
  }
  return massive;
};

// const massive2Question = (massive) {

  // const collection = collectionFilter.map((el) => {
  //   switch (el.type) {
  //     case "select": {
  //       return {
  //         idKey: "",
  //         id: el.id,
  //         shortname: el.shortname,
  //         title: needRuleClarification.includes(el.shortname)
  //           ? el.name === ""
  //             ? `${el.id} ${el.subtopic_name}. ${el.prevalue}`
  //             : `${el.id} ${el.subtopic_name}. ${el.prevalue}`
  //           : el.name === ""
  //           ? `${el.id} ${el.prevalue}`
  //           : `${el.id} ${el.name} ${el.prevalue}`,
  //         type: el.type,
  //         scopeOperations:
  //           el.ruletype === "number"
  //             ? ruleOperations.selectOper
  //             : ruleOperations.radioOper,
  //         operation: "",
  //         scopeValues: el.select_options,
  //         value: "",
  //       };
  //     }
  //     case "checkbox":
  //     case "radio": {
  //       const scopeRadio = ["", ...el.radio_options];
  //       return {
  //         idKey: "",
  //         id: el.id,
  //         shortname: el.shortname,
  //         title: needRuleClarification.includes(el.shortname)
  //           ? el.name === ""
  //             ? `${el.id} ${el.subtopic_name}. ${el.prevalue}`
  //             : `${el.id} ${el.subtopic_name}. ${el.prevalue}`
  //           : el.name === ""
  //           ? `${el.id} ${el.prevalue}`
  //           : `${el.id} ${el.name} ${el.prevalue}`,
  //         type: el.type,
  //         scopeOperations: ruleOperations.radioOper,
  //         operation: "",
  //         scopeValues: scopeRadio,
  //         value: "",
  //       };
  //     }
  //     case "content":
  //     case "number":
  //     case "money": {
  //       return {
  //         idKey: "",
  //         id: el.id,
  //         shortname: el.shortname,
  //         title: needRuleClarification.includes(el.shortname)
  //           ? el.name === ""
  //             ? `${el.id} ${el.subtopic_name}. ${el.prevalue}`
  //             : `${el.id} ${el.subtopic_name}. ${el.prevalue}`
  //           : el.name === ""
  //           ? `${el.id} ${el.prevalue}`
  //           : `${el.id} ${el.name} ${el.prevalue}`,
  //         type: el.type,
  //         scopeOperations: ruleOperations.numberOper,
  //         operation: "",
  //         scopeValues: [],
  //         value: "",
  //       };
  //     }
  //     case "year":
  //     case "month":
  //     case "date": {
  //       return {
  //         idKey: "",
  //         id: el.id,
  //         shortname: el.shortname,
  //         title: needRuleClarification.includes(el.shortname)
  //           ? el.name === ""
  //             ? `${el.id} ${el.subtopic_name}. ${el.prevalue}`
  //             : `${el.id} ${el.subtopic_name}. ${el.prevalue}`
  //           : el.name === ""
  //           ? `${el.id} ${el.prevalue}`
  //           : `${el.id} ${el.name} ${el.prevalue}`,
  //         type: el.type,
  //         scopeOperations: ruleOperations.yearOper,
  //         operation: "",
  //         scopeValues: [],
  //         value: "",
  //       };
  //     }
  //     default:
  //       return {
  //         idKey: "",
  //         id: el.id,
  //         shortname: el.shortname,
  //         title: needRuleClarification.includes(el.shortname)
  //           ? el.name === ""
  //             ? `${el.id} ${el.subtopic_name}. ${el.prevalue}`
  //             : `${el.id} ${el.subtopic_name}. ${el.prevalue}`
  //           : el.name === ""
  //           ? `${el.id} ${el.prevalue}`
  //           : `${el.id} ${el.name} ${el.prevalue}`,
  //         type: el.type,
  //         scopeOperations: [],
  //         operation: "",
  //         scopeValues: [],
  //         value: "",
  //       };
  //   }
  // });

//   return collection;
// }

const convertReadMassiveForExport = (massive) => {
  let result = [];
  // console.log("massive=",massive)
  for (let i = 0; i < massive.length; i++) {
    let strObj = massive[i]
      // .filter((el1) => el1.type === "checkbox")
      .map((el) => {
        switch (el.type) {
          case "select": {
            return {
              id: el.id,
              shortname: el.shortname,
              title: needRuleClarification.includes(el.shortname)
                ? el.name === ""
                  ? `${el.id}: ${el.subtopic_name}. ${el.prevalue}`
                  : `${el.id}: ${el.subtopic_name}. ${el.prevalue}`
                : el.name === ""
                ? `${el.id}: ${el.prevalue}`
                : `${el.id}: ${el.name} ${el.prevalue}`,
              type: el.type,
              value: el.value,
            };
          }
          case "radio": {
            return {
              id: el.id,
              shortname: el.shortname,
              title: needRuleClarification.includes(el.shortname)
                ? el.name === ""
                  ? `${el.id}: ${el.subtopic_name}. ${el.prevalue}`
                  : `${el.id}: ${el.subtopic_name}. ${el.prevalue}`
                : el.name === ""
                ? `${el.id}: ${el.prevalue}`
                : `${el.id}: ${el.name} ${el.prevalue}`,
              type: el.type,
              value: el.value,
            };
          }
          case "checkbox": {
            let checkboxValue = "";
            for (let k = 0; k < el.value.length; k++) {
              if (el.value[k]) {
                if(checkboxValue.length>0)
                  checkboxValue = `${checkboxValue}; ${el.radio_options[k]}`;
                else
                  checkboxValue = `${el.radio_options[k]}`;
              }
            }
            return {
              id: el.id,
              shortname: el.shortname,
              title: needRuleClarification.includes(el.shortname)
                ? el.name === ""
                  ? `${el.id}: ${el.subtopic_name}. ${el.prevalue}`
                  : `${el.id}: ${el.subtopic_name}. ${el.prevalue}`
                : el.name === ""
                ? `${el.id}: ${el.prevalue}`
                : `${el.id}: ${el.name} ${el.prevalue}`,
              type: el.type,
              value: checkboxValue,
              // value: el.value,
            };
          }
          case "content":
          case "number":
          case "money": {
            return {
              id: el.id,
              shortname: el.shortname,
              title: needRuleClarification.includes(el.shortname)
                ? el.name === ""
                  ? `${el.id}: ${el.subtopic_name}. ${el.prevalue}`
                  : `${el.id}: ${el.subtopic_name}. ${el.prevalue}`
                : el.name === ""
                ? `${el.id}: ${el.prevalue}`
                : `${el.id}: ${el.name} ${el.prevalue}`,
              type: el.type,
              value: el.value,
            };
          }
          case "year":
          case "month":
          case "date": {
            return {
              id: el.id,
              shortname: el.shortname,
              title: needRuleClarification.includes(el.shortname)
                ? el.name === ""
                  ? `${el.id}: ${el.subtopic_name}. ${el.prevalue}`
                  : `${el.id}: ${el.subtopic_name}. ${el.prevalue}`
                : el.name === ""
                ? `${el.id}: ${el.prevalue}`
                : `${el.id}: ${el.name} ${el.prevalue}`,
              type: el.type,
              value: el.value,
            };
          }
          case "hour_minute": {
            return {
              id: el.id,
              shortname: el.shortname,
              title: needRuleClarification.includes(el.shortname)
                ? el.name === ""
                  ? `${el.id}: ${el.subtopic_name}. ${el.prevalue}`
                  : `${el.id}: ${el.subtopic_name}. ${el.prevalue}`
                : el.name === ""
                ? `${el.id}: ${el.prevalue}`
                : `${el.id}: ${el.name} ${el.prevalue}`,
              type: el.type,
              value: `${el.value}:${el.value2}`,
            }
          }
          case "year_month":{
            return {
              id: el.id,
              shortname: el.shortname,
              title: needRuleClarification.includes(el.shortname)
                ? el.name === ""
                  ? `${el.id}: ${el.subtopic_name}. ${el.prevalue}`
                  : `${el.id}: ${el.subtopic_name}. ${el.prevalue}`
                : el.name === ""
                ? `${el.id}: ${el.prevalue}`
                : `${el.id}: ${el.name} ${el.prevalue}`,
              type: el.type,
              value: `${el.value} ${el.value2}`,
            }
          }
          default: {
            return {
              id: el.id,
              shortname: el.shortname,
              title: needRuleClarification.includes(el.shortname)
                ? el.name === ""
                  ? `${el.id}: ${el.subtopic_name}. ${el.prevalue}`
                  : `${el.id}: ${el.subtopic_name}. ${el.prevalue}`
                : el.name === ""
                ? `${el.id}: ${el.prevalue}`
                : `${el.id}: ${el.name} ${el.prevalue}`,
              type: el.type,
              value: el.value,
            };
          }
        }
      });
      if(strObj.length>0)
        result.push(strObj);
  }

  return result;
};
// "textarea"
// 	"rank"
// 	"subtopic"
// 	"text"
// 	"withcode"
// 	"withtitle"
// 	"editor"


const concatMassive = (qaMassive) => {
  let result = [];
  for (let i = 0; i < qaMassive.length; i++) {
    let subQaMassive = qaMassive[i];
    let quest = "";
    for (let j = 0; j < subQaMassive.length; j++) {
      if(quest==="")
        quest = `Question ${subQaMassive[j].title}; ${quest}`;
      else
        quest = `Question ${subQaMassive[j].title}; Following ${quest}`;
    }
    // console.log("subQaMassive[j].title=", subQaMassive[j].title);
    result.push({
      id: subQaMassive[0].id,
      shortname: subQaMassive[0].shortname,
      title: quest,
      type: subQaMassive[0].type,
      value: subQaMassive[0].value,
    });
  }
  // result = Object.assign([], qaMassive);
  return result;
};


const readElement = (jsonState, shortname) => {
	for (let i = 0; i < jsonState.length; i++) {
		for (let j = 0; j < jsonState[i].body.length; j++) {
			if (jsonState[i].body[j].shortname === shortname) return jsonState[i].body[j];
		}
	}
	return null;
};

const makeImpressionsArray = (assessment_state, act, data) => {
	console.log(data);

	let elem = jsonElem(assessment_state, act);
	let arr = data.msg.map((el) => {
		return `${el.shortname}; ${el.checkbox_options}`;
	});
	elem.value = data.msg.map((el) => {
		return false;
	});
	elem.radio_options = arr;
};

const splitStr = (str) => {
	const arr = [];
	const pos = str.indexOf(";");
	arr[0] = str.slice(0, pos);
	arr[1] = str.slice(pos + 1);
	return arr;
};

const custGenerate = (arr) => {
	const maxEl = arr
		.map((el) => el.shortname)
		.filter((el) => el.indexOf("CUST") >= 0)
		.map((el) => 10 * Number(el[5]) + Number(el[7]))
		.reduce(function (p, v) {
			return p > v ? p : v;
		});
	const nextCust = maxEl + 1;
	const nextCustStr = PrependZeros(nextCust, 2, "0");
	if (nextCust < 100) {
		return `CUST.${nextCustStr[0]}.${nextCustStr[1]}`;
	} else {
		return `CUST.0.0`;
	}
};
function getInternetExplorerVersion()
{
  var rv = -1;
  if (navigator.appName === 'Microsoft Internet Explorer')
  {
    let ua = navigator.userAgent;
    let re = new RegExp("MSIE ([0-9]{1,}[\\.0-9]{0,})");
    if (re.exec(ua) != null)
      rv = parseFloat( RegExp.$1 );
  }
  else if (navigator.appName === 'Netscape')
  {
    let ua = navigator.userAgent;
    let re  = new RegExp("Trident/.*rv:([0-9]{1,}[\\.0-9]{0,})");
    if (re.exec(ua) != null)
      rv = parseFloat( RegExp.$1 );
  }
  return rv;
}

function contains_rank(arr, elem) {
    for (var i = 0; i < arr.length; i++) {
        if (arr[i].rank === elem) {
            return arr[i].value;
        }
    }
    return "";
}
// function showField(key,value,scope) {
//   switch (Constants.CURRENT_SUPERUSER_MODE) {
//     case Constants.SUPERUSER_MODE_NORMAL:{
// 	  return value;
// 	}
//     case Constants.SUPERUSER_MODE_PROTECTED:
//       if (Constants.PROTECTED_FIELDS.includes(key) && scope==="global") {
//         return Constants.PROTECTED_SYMBOLS;
//       }
//       return value;
//     default:
//       return Constants.PROTECTED_SYMBOLS;
//   }
// }
function getMiddlename(middlename, flag) {
  let result = middlename.trim();
  if(result.length){
    if(flag===Constants.SHORT_VIEW_MIDDLENAME){
      return `${result[0]}.`
    } else {
      if(flag===Constants.FULL_VIEW_MIDDLENAME){
        return `${result}`;
      }
    }
  }
  return result;
}

function showField(key, value, scope, allow_support/*, section*/) {
  switch (Constants.CURRENT_SUPERUSER_MODE) {
    case Constants.SUPERUSER_MODE_NORMAL: {
      if (key === "middlename") {
        // console.log("Constants.SUPERUSER_MODE_NORMAL key=",key, "value=",value,"scope=",scope )
        // if (Constants.SECTION_SHORT_MIDDLENAME.includes(section)){
          return getMiddlename(value, Constants.SHORT_VIEW_MIDDLENAME);
        // } else {
        //   return getMiddlename(value, Constants.FULL_VIEW_MIDDLENAME);
        // }
      }
      return value;
    }
    case Constants.SUPERUSER_MODE_PROTECTED: {
      if (Constants.PROTECTED_FIELDS.includes(key) && (scope === "global" || scope === "openai") && !allow_support) {
        if (key === "middlename") {
          // console.log("Constants.SUPERUSER_MODE_PROTECTED global key=",key, "value=",value,"scope=",scope )
          if(value.trim().length){
            // if (Constants.SECTION_SHORT_MIDDLENAME.includes(section)){
              return `${Constants.PROTECTED_SYMBOLS[0]}.`;
            // } else {
            //   return Constants.PROTECTED_SYMBOLS;
            // }
          }
          return ``;
        }
        return Constants.PROTECTED_SYMBOLS;
      }
      if (key === "middlename") {
        // console.log("Constants.SUPERUSER_MODE_PROTECTED key=",key, "value=",value,"scope=",scope )
        // if (Constants.SECTION_SHORT_MIDDLENAME.includes(section)){
          return getMiddlename(value, Constants.SHORT_VIEW_MIDDLENAME);
        // } else {
        //   return getMiddlename(value, Constants.FULL_VIEW_MIDDLENAME);
        // }
      }
      return value;
    }
    default:
      return Constants.PROTECTED_SYMBOLS;
  }
}




function showSearchField(radio_options, scope) {
  switch (Constants.CURRENT_SUPERUSER_MODE) {
    case Constants.SUPERUSER_MODE_NORMAL: {
      return radio_options;
    }
    case Constants.SUPERUSER_MODE_PROTECTED:
      if (scope === "global")
        return radio_options.filter((el) =>
          !Constants.PROTECTED_FIELDS.includes(el.value)
        );
      else return radio_options;
    /*
      if (Constants.PROTECTED_FIELDS.includes(key) && scope === "global") {
		return Constants.PROTECTED_SYMBOLS;
      }
	  return radio_options;
*/
    default:
      return Constants.PROTECTED_SYMBOLS;
  }
}

function changeClassNames(classes, search, oper) {
  let resultArr = "";
  if (oper !== "add" && oper !== "delete") return classes;
  let arrClasses = classes.split(" ");
  if (oper === "delete") {
    resultArr = arrClasses.filter((el) => el !== search);
    return resultArr.join(' ');
  }
  if (oper === "add") {
    resultArr = [...arrClasses, search];
    return resultArr.join(' ');
  }
}

function addClass(search){
  let resultArr = "";
  if (typeof this === "string"){
    let arrClasses = this.split(" ");
    resultArr = [...arrClasses, search];
    return resultArr.join(' ');
  }
  return this;
}
String.prototype.addClass = addClass;

function delClass(search){
  let resultArr = "";
  if (typeof this === "string"){
    let arrClasses = this.split(" ");
    resultArr = arrClasses.filter((el) => el !== search);
    return resultArr.join(' ');
  }
  return this;
}
String.prototype.delClass = delClass;

const injectGA = () => {
  if (typeof window == 'undefined') {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag(){window.dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-ELG5FPPL6M');
};

const findInputControl = (field,content) =>{

  const rule = Constants.FIELDRULES.find(el=>el.act===field) || {id:0,act:"*",maskField:""};
  if(rule.act==="*") // не анализируем поле
    return {statusField:"ok",msg:"typical field"};

  const lowerContent = content.toLowerCase();
  // console.log("lowerContent=",lowerContent)

  if(lowerContent.indexOf(rule.maskField)!==0) //with есть но оно не первое
  {
    return {statusField:"error",msg:`Incorrect data in the field. There must be the word "${rule.maskField}" at the beginning of the sentence`}
  } else {
    if(rule.maskField.length===lowerContent.length){
      return {statusField:"warning",msg:"The field is not filled"}
    } else {
      return {statusField:"ok",msg:"The data in the field is correct"}
    }
  }
}

/*
const findInputControl = (field,content) =>{
  const rule = Constants.FIELDRULES.find(el=>el.act===field) || {id:0,act:"*",maskField:""};
  if(rule.act==="*") // не анализируем поле
    return {statusField:"ok",msg:"typical field"};

  if(content.indexOf(rule.maskField)!==0) //with есть но оно не первое
  {
    return {statusField:"error",msg:`Incorrect data in the field. There must be the word "${rule.maskField}" at the beginning of the sentence`}
  } else {
    if(rule.maskField.length===content.length){
      return {statusField:"warning",msg:"The field is not filled"}
    } else {
      return {statusField:"ok",msg:"The data in the field is correct"}
    }
  }
}
*/
const findSemantic = (fieldToCheck) =>{
  const rule = Constants.FIELDRULES.find(el=>el.act===fieldToCheck.act) || {id:0,act:"*",maskField:""};
  // console.log("fieldToCheck=",fieldToCheck,"rule=",rule);
  if(rule.act==="*" || fieldToCheck.disabled==="true") // не анализируем поле
    return {statusField:"ok",msg:"typical field"};

  const lowerContent = fieldToCheck.value.toLowerCase();
  // console.log("lowerContent=",lowerContent)
  // console.log("fieldToCheck.value.indexOf(rule.maskField)=",fieldToCheck.value.indexOf(rule.maskField));
  if(lowerContent.indexOf(rule.maskField)!==0) //with есть но оно не первое
    {
      return {statusField:"error",msg:`Incorrect data in the field. There must be the word "${rule.maskField}" at the beginning of the sentence`}
    } else {
      if(rule.maskField.length===lowerContent.length){
        return {statusField:"warning",msg:"The field is not filled"}
      } else {
        return {statusField:"ok",msg:"The data in the field is correct"}
      }
    }
}

const semantickInputControl = (fieldsToCheck) =>{
  fieldsToCheck = fieldsToCheck || [];
	for(var i=0; i<fieldsToCheck.length; i++){
      // return findSemantic(fieldsToCheck[i]);
		if(findSemantic(fieldsToCheck[i]).statusField==="error"){
			return findSemantic(fieldsToCheck[i]);
		}
    if(findSemantic(fieldsToCheck[i]).statusField==="warning"){
      return findSemantic(fieldsToCheck[i]);
    }

	}
	return {statusField:"ok",msg:"The data in the field is correct"};
}

function roundPlus(x, n) {
  if (isNaN(x) || isNaN(n)) return false;
  var m = Math.pow(10, n);
  return Math.round(x * m) / m;
}

function ActNext(str) {
  let base = str.split("").slice(0, 7).join("");
  let num = Number(str.split("").slice(7).join("")) + 1;
  return base + num;
}

function ActPrev(str) {
  let base = str.split("").slice(0, 7).join("");
  let num = Number(str.split("").slice(7).join("")) - 1;
  return base + num;
}

function controlDiadTitle (act, idOfRecTitle, field ) {
  if(idOfRecTitle[act]===0 && field.trim().length)
    return false;
  else
    return true;
}
function controlDiadCode (act, idOfRecCode, field) {
  if(idOfRecCode[act]===0 && field.trim().length)
    return false;
  else
    return true;
}

function putPlaceHolder(before,after,title,index){
  let punctuationMarks = [".",",","?","!",":",";"]
  const cleanBefore = before.replace(/<\/?[^>]+(>|$)/g, "");
  const cleanAfter = after.replace(/<\/?[^>]+(>|$)/g, "");
  if(cleanBefore.trim()===""){
    if(punctuationMarks.includes(cleanAfter[0])){
      return {content :`${before.trim()}${title}${after.trim()}`, position : cleanBefore.trim().length+title.length}
    } else {
      return {content :`${before.trim()}${title} ${after.trim()}`, position : cleanBefore.trim().length+title.length+1}
    }
  } else {
    if(punctuationMarks.includes(cleanAfter[0])){
      return {content :`${before.trim()} ${title}${after.trim()}`, position : cleanBefore.trim().length+title.length+1}
    }else {
      return {content :`${before.trim()} ${title} ${after.trim()}`, position : cleanBefore.trim().length+title.length+2}
    }
  }
}

function getPromoStatus(created, expires ) {
  let status = "";
  const currentStartOfDay=  moment().endOf("day");
  const createdStartOfDay = moment(created).startOf("day");
  const expiresdStartOfDay = moment(expires).startOf("day");

  // console.log("currentStartOfDay=",currentStartOfDay)
  // console.log("createdStartOfDay=",createdStartOfDay)
  // console.log("expiresdStartOfDay=",expiresdStartOfDay)

  if(moment(currentStartOfDay).isAfter(expiresdStartOfDay, "hour") && moment(expiresdStartOfDay).format("YYYY-MM-DD") !== "1970-01-01" ){
    status = "Expired";
    return {error : true, status : status}
  } else {
    if(moment(currentStartOfDay).isBefore(createdStartOfDay, "hour")){
      status = "Pending";
      return {error : true, status : status}
    } else {
      if (moment(currentStartOfDay).isAfter(createdStartOfDay, "hour") &&  ( moment(currentStartOfDay).isBefore(expiresdStartOfDay, "hour") || moment(expiresdStartOfDay).format("YYYY-MM-DD") === "1970-01-01" )){
        status = "Active";
        return {error : false, status : status}
      } else {
        status = "Unknown";
        return {error : true, status : status}
      }
    }
  }
}

// componentDidMount() {
//   Promise.all(
//     Array.from({ length: this.state.total }, () => {
//       return new Promise(resolve => {
//         setTimeout(() => {
//           this.setState(
//             prevState => ({ done: prevState.done + 1 }),
//             () => resolve(Math.random())
//           );
//         }, Math.random() * 3000);
//       });
//     })
//   ).then(data => {
//     this.setState({ data });
//   });
// }

function getAssessmentPattern(adultType) {
  let result = [];
  if (adultType === "Adolescent") {
    // console.log("adolescentPattern=",adolescentPattern);
    const tmp = Object.assign([], {
      ...adolescentPattern.map((el) =>
        el.topic !== undefined
          ? {
              ...el,
              body: el.body
                .map((el1) => {
                  switch (el1.shortname) {
                    case "startblockalcohol":
                      return [el1].concat(blockAlcohol);
                    case "startblockcannabis":
                      return [el1].concat(blockCannabis);
                    case "startblockcocaine":
                      return [el1].concat(blockCocaine);
                    case "startblockcrack":
                      return [el1].concat(blockCrack);
                    case "startblockcrank":
                      return [el1].concat(blockCrank);
                    case "startblockecstasy":
                      return [el1].concat(blockEcstasy);
                    case "startblockhallucinogens":
                      return [el1].concat(blockHallucinogens);
                    case "startblockheroin":
                      return [el1].concat(blockHeroin);
                    case "startblockinhalants":
                      return [el1].concat(blockInhalants);
                    case "startblockmeth":
                      return [el1].concat(blockMeth);
                    case "startblockmethadone":
                      return [el1].concat(blockMethadone);
                    case "startblockopiates":
                      return [el1].concat(blockOpiates);
                    case "startblocksedative":
                      return [el1].concat(blockSedative);
                    case "startblocktobacco":
                      return [el1].concat(blockTobacco);
                    case "startblockother":
                      return [el1].concat(blockOther);
                    default:
                      return el1;
                  }
                })
                .flat(),
            }
          : el
      ),
    });
    // return Object.assign({}, result, { ruleAssessmentJson: tmp });
    return tmp;
  } else {
    if (adultType === "Adult") {
      const tmp = Object.assign([], {
        ...adultPattern.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body
                  .map((el1) => {
                    switch (el1.shortname) {
                      case "startblockalcohol":
                        return [el1].concat(blockAlcohol);
                      case "startblockcannabis":
                        return [el1].concat(blockCannabis);
                      case "startblockcocaine":
                        return [el1].concat(blockCocaine);
                      case "startblockcrack":
                        return [el1].concat(blockCrack);
                      case "startblockcrank":
                        return [el1].concat(blockCrank);
                      case "startblockecstasy":
                        return [el1].concat(blockEcstasy);
                      case "startblockhallucinogens":
                        return [el1].concat(blockHallucinogens);
                      case "startblockheroin":
                        return [el1].concat(blockHeroin);
                      case "startblockinhalants":
                        return [el1].concat(blockInhalants);
                      case "startblockmeth":
                        return [el1].concat(blockMeth);
                      case "startblockmethadone":
                        return [el1].concat(blockMethadone);
                      case "startblockopiates":
                        return [el1].concat(blockOpiates);
                      case "startblocksedative":
                        return [el1].concat(blockSedative);
                      case "startblocktobacco":
                        return [el1].concat(blockTobacco);
                      case "startblockother":
                        return [el1].concat(blockOther);
                      default:
                        return el1;
                    }
                  })
                  .flat(),
              }
            : el
        ),
      });
      // return Object.assign({}, result, { ruleAssessmentJson: tmp });
      return tmp;
      // return Object.assign({}, result, { ruleAssessmentJson: adultPattern });
      // return adultPattern;
    } else {
      const tmp = Object.assign([], {
        ...adolescentPattern.map((el) =>
          el.topic !== undefined
            ? {
                ...el,
                body: el.body
                  .map((el1) => {
                    switch (el1.shortname) {
                      case "startblockalcohol":
                        return [el1].concat(blockAlcohol);
                      case "startblockcannabis":
                        return [el1].concat(blockCannabis);
                      case "startblockcocaine":
                        return [el1].concat(blockCocaine);
                      case "startblockcrack":
                        return [el1].concat(blockCrack);
                      case "startblockcrank":
                        return [el1].concat(blockCrank);
                      case "startblockecstasy":
                        return [el1].concat(blockEcstasy);
                      case "startblockhallucinogens":
                        return [el1].concat(blockHallucinogens);
                      case "startblockheroin":
                        return [el1].concat(blockHeroin);
                      case "startblockinhalants":
                        return [el1].concat(blockInhalants);
                      case "startblockmeth":
                        return [el1].concat(blockMeth);
                      case "startblockmethadone":
                        return [el1].concat(blockMethadone);
                      case "startblockopiates":
                        return [el1].concat(blockOpiates);
                      case "startblocksedative":
                        return [el1].concat(blockSedative);
                      case "startblocktobacco":
                        return [el1].concat(blockTobacco);
                      case "startblockother":
                        return [el1].concat(blockOther);
                      default:
                        return el1;
                    }
                  })
                  .flat(),
              }
            : el
        ),
      });
      // return Object.assign({}, result, { ruleAssessmentJson: tmp });
      return tmp;
    }
  }
}
function getAllShortName(assessmentJson){
  let result=[]
  assessmentJson.forEach(el=>{
    el.body.forEach(el1 => {
      result.push(el1.shortname);
    })
  })
  return result;
}

function getAllShortNameWithStatus(assessmentJson){
  let result=[]
  assessmentJson.forEach(el=>{
    el.body.forEach(el1 => {
      result.push({shortname:el1.shortname, adolescent: el1.adolescent});
    })
  })
  return result;
}

function getInArray(shortNameMassive,ruleRecords){
  let result = [];
  ruleRecords.forEach(el=>{
    result.push(shortNameMassive.includes(el))
  })
  // console.log(result.every(el=>el===true))

  return result.every(el=>el===true);
}

function getInArrayWithAdolField(shortNameAdolMassive,ruleRecords,copyDirection){
  let result = [];
  let resultMassive = [];
  console.log("shortNameAdolMassive=",shortNameAdolMassive,"ruleRecords=",ruleRecords,"copyDirection=",copyDirection);
  ruleRecords.forEach(el=>{
    const findElemMas = shortNameAdolMassive.filter(el1=>el1.shortname===el);
    console.log("findElemMas=",findElemMas);
    if(findElemMas.length){
      resultMassive.push(findElemMas)
    } else {
      console.log("return");
      resultMassive=[]
      return;
    }
  })
  if(!resultMassive.length) return false;
  let resultMassiveFlat=resultMassive.flat();
  console.log("resultMassiveFlat=",resultMassiveFlat)
  //check adolescent
  resultMassiveFlat.forEach(el=>{
    console.log("el.adolescent=",el.adolescent,"copyDirection=",copyDirection);
    if(el.adolescent===undefined || (el.adolescent==="true" && copyDirection==="Adolescent") || (el.adolescent==="false" && copyDirection==="Adult")){
      result.push(true);
    } else {
      result.push(false);
    }
  })

  // console.log("result=",result)
  // ruleRecords.forEach(el=>{
  //   result.push(shortNameAdolMassive.includes(el))
  // })
  // console.log(result.every(el=>el===true))

  return result.every(el=>el===true);
}

function checkLengthEditor(json) {
  const arr_json = json;
  const arr_comment = [];
  let dangerElem = [];

  for (let i = 0; i < arr_json.length; i++) {
    for (let j = 0; j < arr_json[i].body.length; j++) {
      if (asiCommentField.includes(arr_json[i].body[j].shortname)) {
        arr_comment.push({
          id: arr_json[i].body[j].id,
          length: arr_json[i].body[j].value.replace(/<\/?[^>]+(>|$)/g, "")
            .length,
        });
        // console.log(arr_json[i].body[j].shortname," " ,arr_json[i].body[j].value.replace(/<\/?[^>]+(>|$)/g, "").length)
      }
    }
  }
  // console.log("arr_comment=", arr_comment);
  const arr = arr_comment.filter(
    (el) =>
      Constants.MAXSIZE_EDITOR_ASI_FORM.find((el1) => el1.id === el.id).symbols <el.length
  );

  return arr.map(el => {
    return {over:el.length-Constants.MAXSIZE_EDITOR_ASI_FORM.find((el1) => el1.id === el.id).symbols, ...el }})
  // return arr_comment.filter(
  //   (el) =>
  //     Constants.MAXSIZE_EDITOR_ASI_FORM.find((el1) => el1.id === el.id).symbols <el.length
  // );
  // return arr_comment.filter((el) => el.length > Constants.MAXSIZE_EDITOR_ASI);
}
const getDetail = (arr_json, shortname) => {
  for (var i = 0; i < arr_json.length; i++) {
    for (var j = 0; j < arr_json[i].body.length; j++) {
      if (arr_json[i].body[j].shortname === shortname) {
        return arr_json[i].body[j];
      }
    }
  }
  return { type: "NULL", payload: null };
};

//========================================================================================
/*                                                                                      *
 *                                getSubscriptionInStripe                               *
 *                                                                                      */
//========================================================================================

const getSubscriptionInStripe = async function (setupUser, emailAdminsArr) {
  let subscriptionsWithCustomersArr = [];
  // let userSubscriptionsArr = [];
  let resultSubscription = [];
  const hafServices = new HafServices();
  const subscriptionsReplay = await axios.get("/api/subscriptions", {status :'active'});
  const customersReplay = await axios.get("/api/customers", {});
  // console.log("subscriptionsReplay=", subscriptionsReplay);
  // console.log("customersReplay=", customersReplay);
  const subscriptionsTable = subscriptionsReplay.data.msg.data.map((el) => el);
  // console.log("subscriptionsTable=",subscriptionsTable)
  const customersTable = customersReplay.data.msg.data.map((el) => el);
  // console.log("subscriptionsTable=",subscriptionsTable);
  for (let i = 0; i < subscriptionsTable.length; i++) {
    for (let j = 0; j < customersTable.length; j++) {
      if (subscriptionsTable[i].customer === customersTable[j].id) {
        subscriptionsWithCustomersArr.push({
          source: "stripe",
          id: subscriptionsTable[i].id,
          customer: subscriptionsTable[i].customer,
          email: customersTable[j].email,
          created: subscriptionsTable[i].created,
          current_period_start: subscriptionsTable[i].current_period_start,
          current_period_end: subscriptionsTable[i].current_period_end,
          stripe_price_id: subscriptionsTable[i].plan.id,
          //subscription_status = 1 created subscription_status = 2 updated
          subscription_status:
            subscriptionsTable[i].created ===
            subscriptionsTable[i].current_period_start
              ? 1
              : 2,
          active_status: subscriptionsTable[i].status
        });
      }
    }
  }
  const subscriptionsWithCustomersArrLength =
    subscriptionsWithCustomersArr.filter(
      (el) =>
        // el.email === setupUser.email &&
        emailAdminsArr.includes(el.email) &&
        el.current_period_start * 1000 < moment().valueOf() &&
        el.current_period_end * 1000 > moment().valueOf()
    ).length;
  const userSubscriptions =
    subscriptionsWithCustomersArrLength > 0
      ? subscriptionsWithCustomersArr.filter(
          (el) =>
            // el.email === setupUser.email &&
            emailAdminsArr.includes(el.email) &&
            el.current_period_start * 1000 < moment().valueOf() &&
            el.current_period_end * 1000 > moment().valueOf()
        )
      : [];

  return addParamSubscription(userSubscriptions);

};

async function addParamSubscription(userSubscriptions) {
  let resultSubscription = [];
  for (const el of userSubscriptions) {
    const userObj = await axios.get(
      `/api/getuserrecordbyemail?email=${el.email}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      }
    );
    let userResult = {
      ...el,
      firstname: userObj.data.msg.rows[0].firstname,
      lastname: userObj.data.msg.rows[0].lastname,
    };
    const productObj = await axios.get(
      `/api/productbystripepriceid?&stripe_price_id=${el.stripe_price_id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
      }
    );
    let productResult = {
      ...userResult,
      product: productObj.data.msg.rows[0].product,
      cost: productObj.data.msg.rows[0].cost,
      credit: productObj.data.msg.rows[0].credit,
      type_subscription: productObj.data.msg.rows[0].type_subscription,
      type_system: productObj.data.msg.rows[0].type_system,
    };
    resultSubscription.push(productResult)
  }
  return resultSubscription;
}
//========================================================================================
/*                                                                                      *
 *                          searchSubscriptionInStripeAndMySql                          *
 *                                                                                      */
//========================================================================================

const searchSubscriptionInStripeAndMySql = function (
  setupUser,
  userSubscriptionsArr
) {
  return new Promise( async (resolve) => {
    // console.log("userSubscriptionsArr=", userSubscriptionsArr);
    let type_system = [
      { id: 1, name: "HAF" },
      { id: 2, name: "ASI" },
    ];
    let userSubscriptionsArrOut = [];
    //Circle on type_systems
    // type_system.forEach(async ts => {
    for(const ts of type_system){
      // run on type_sysyem, которые пришли со Stripe
      let subscription = userSubscriptionsArr.filter(
        (ts1) => ts1.type_system === ts.name
      );
      // console.log("subscription=", subscription);

      //  1. Проверяем есть ли в Mysql и если нет, то добавляем
      // console.log("subscription=",subscription);
      // console.log("userSubscriptionsArr=",userSubscriptionsArr)
      const hafServices = new HafServices();
      if (subscription.length > 0) {
        let usedCreditsAfterSubscription = 0;
        let data2 = await hafServices.getCountOutcoming(setupUser.CompanyId, subscription[0].current_period_start * 1000,subscription[0].current_period_end * 1000);
        if(!data2.error)
          usedCreditsAfterSubscription =parseInt(data2.msg)
        else
          usedCreditsAfterSubscription = 0

        // В Stripe есть подписка
        let data = await hafServices.getUserRecordByEmail(subscription[0].email)
        if (!data.error) {
          let newCredit = 0
          if(subscription[0].type_system==="ASI"){
            newCredit=parseInt(subscription[0].credit)
          } else {
            if(subscription[0].type_system==="HAF"){
              if(subscription[0].subscription_status===1){
                newCredit=parseInt(subscription[0].credit)
              } else {
                if(subscription[0].subscription_status===2){
                  newCredit=parseInt(subscription[0].credit)>parseInt(usedCreditsAfterSubscription)?parseInt(usedCreditsAfterSubscription):parseInt(subscription[0].credit);
                }
              }
            }
          }
          let arr = {};
          arr.CompanyId = data.msg.rows[0].CompanyId;
          arr.UserId = data.msg.rows[0].id;
          arr.subscription_start_date = subscription[0].current_period_start * 1000;
          arr.subscription_end_date = subscription[0].current_period_end * 1000;
          arr.subscription_status = subscription[0].subscription_status;  //Added subscription
          arr.type_system=ts.name;
          arr.customer = subscription[0].customer;
          arr.subscriptionId = subscription[0].id;
          arr.product=subscription[0].product;
          arr.stripe_price_id=subscription[0].stripe_price_id;
          arr.cost=subscription[0].cost;
          // arr.credit=subscription[0].credit;
          arr.credit=newCredit;
          arr.type_subscription=subscription[0].type_subscription;
          arr.type_system=subscription[0].type_system;
          arr.active_status=subscription[0].active_status;
          // console.log("arr=",arr);
          // let data1 = await hafServices.searchSubsription(arr.CompanyId, arr.UserId, arr.subscriptionId ); // для того чтобы понять это новвая аодписка или уже есть в ьазе
          let data1 = await hafServices.searchSubsription(arr.CompanyId, arr.UserId, arr.subscriptionId,arr.subscription_start_date,arr.subscription_end_date); // для того чтобы понять это новвая аодписка или уже есть в ьазе
          // console.log("data1=",data1);
          // console.log(">>>>>>>>>>arr=",arr);
          // if (!data1.error) {  // data.error===true если
            if (data1.msg.count === 0) {
              if(arr.active_status==="active"){
                let data2 = await hafServices.postSubscriptionTransaction(arr);
              }
              if(arr.active_status==="trialing"){
                arr.cost=0
                let data2 = await hafServices.postSubscriptionTransaction(arr);
              }
              // userSubscriptionsArrOut.push({error: false, msg: subscription[0], response: "added",})
              // console.log("added userSubscriptionsArrOut=", userSubscriptionsArrOut);
              userSubscriptionsArrOut.push({...subscription[0], used_credit: usedCreditsAfterSubscription, response: "added"})
            } else {
              // userSubscriptionsArrOut.push({error: false, msg: subscription[0], response: "exists",})
              // console.log("exists userSubscriptionsArrOut=", userSubscriptionsArrOut);
              userSubscriptionsArrOut.push({...subscription[0], used_credit: usedCreditsAfterSubscription, response: "exists"})
            }
          }
        // }
      } else {
        // В Stripe нет подписки
        let data1 = await hafServices.searchSubsriptionByPeriod(setupUser.CompanyId,ts.name)
        if (!data1.error) {
          if (data1.msg.count === 0) {
            userSubscriptionsArrOut.push({...subscription[0], response: "refuse",type_system: ts.name})
          } else {
            // console.log(">>>>>>>data1=",data1)
            // сбда вставить подсчет сколько
            let usedCreditsAfterSubscription = 0;
            let data2 = await hafServices.getCountOutcoming(setupUser.CompanyId, data1.msg.rows[0].subscription_start_date, data1.msg.rows[0].subscription_end_date);
            if(!data2.error)
              usedCreditsAfterSubscription =parseInt(data2.msg)
            else
              usedCreditsAfterSubscription = 0
            let arr = [];
            arr.push({
              source: "mysql",
              id: data1.msg.rows[0].status,
              customer: data1.msg.rows[0].description,
              credit: data1.msg.rows[0].incoming,
              cost: data1.msg.rows[0].total_sum,
              stripe_price_id: data1.msg.rows[0].stripe_price_id,
              used_credit: usedCreditsAfterSubscription,
              email: "",
              firstname: "",
              lastname: "",
              created: moment(data1.msg.rows[0].credit_date).valueOf() / 1000,
              current_period_start:
                moment(data1.msg.rows[0].subscription_start_date).valueOf() /
                1000,
              current_period_end:
                moment(data1.msg.rows[0].subscription_end_date).valueOf() /
                1000,
                subscription_status: data1.msg.rows[0].subscription_status,
            });
            userSubscriptionsArrOut.push({...arr[0], response: "exists", type_system: ts.name})
          }
        }
      }
    }
    // console.log("userSubscriptionsArrOut=", userSubscriptionsArrOut);
    resolve({error: false, msg: userSubscriptionsArrOut});
  });
};

//========================================================================================
/*                                                                                      *
 *                                searchLocalSubscription                               *
 *                                                                                      */
//========================================================================================

const searchLocalSubscription = function (setupUser) {
  return new Promise(async (resolve) => {
    let type_system = [
      { id: 1, name: "HAF" },
      { id: 2, name: "ASI" },
    ];
    let userSubscriptionsArrOut = [];
    const hafServices = new HafServices();
    for (const ts of type_system) {
      let data1 = await hafServices.searchSubsriptionByPeriod(setupUser.CompanyId,ts.name);
      if (!data1.error) {
        if (data1.msg.count === 0) {
          // нет подписки
          userSubscriptionsArrOut.push({/*...subscription[0],*/ response: "refuse",type_system: ts.name})
        } else {
          // console.log(">>>>>>>data1=",data1)
          // сбда вставить подсчет сколько
          let usedCreditsAfterSubscription = 0;
          let data2 = await hafServices.getCountOutcoming(setupUser.CompanyId, data1.msg.rows[0].subscription_start_date, data1.msg.rows[0].subscription_end_date);
          if(!data2.error)
            usedCreditsAfterSubscription =parseInt(data2.msg)
          else
            usedCreditsAfterSubscription = 0

          const ownerSubscription = await hafServices.getUserInfo(data1.msg.rows[0].UserId);
          const productObj = await axios.get(
            `/api/productbystripepriceid?&stripe_price_id=${data1.msg.rows[0].stripe_price_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
              },
            }
          );
          // console.log("ownerSubscription=",ownerSubscription, "data1.msg.rows[0]=",data1.msg.rows[0])
          let arr = [];
          arr.push({
            source: data1.msg.rows[0].subscription_status===0?"mysql":"stripe",
            id: data1.msg.rows[0].status,
            customer: data1.msg.rows[0].description,
            credit: data1.msg.rows[0].incoming,
            used_credit: usedCreditsAfterSubscription,
            email: ownerSubscription.msg.email,
            firstname: ownerSubscription.msg.firstname,
            lastname: ownerSubscription.msg.lastname,
            created: moment(data1.msg.rows[0].credit_date).valueOf() / 1000,
            current_period_start:
              moment(data1.msg.rows[0].subscription_start_date).valueOf() /
              1000,
            current_period_end:
              moment(data1.msg.rows[0].subscription_end_date).valueOf() /
              1000,
            subscription_status: data1.msg.rows[0].subscription_status,
            stripe_price_id: data1.msg.rows[0].stripe_price_id,
            product: productObj.data.msg.rows[0].product,
            cost: productObj.data.msg.rows[0].cost,
            type_subscription: productObj.data.msg.rows[0].type_subscription,
          });
          userSubscriptionsArrOut.push({...arr[0], response: "exists", type_system: ts.name})
        }
      }
    }
    resolve({ error: false, msg: userSubscriptionsArrOut });
  });
};

//========================================================================================
/*                                                                                      *
 *                                searchLocalContract                                   *
 *                                                                                      */
//========================================================================================

const searchLocalContract = function (setupUser) {
  // console.log("searchLocalContract=");
  return new Promise(async (resolve) => {
    let type_system = [
      { id: 1, name: "HAF" },
      { id: 2, name: "ASI" },
    ];
    let userContractsArrOut = [];
    const hafServices = new HafServices();
    for (const ts of type_system) {
      let data1 = await hafServices.searchContractByPeriod(setupUser.CompanyId,ts.name);
      // console.log(">>>>>>>data1=",data1)
      if (!data1.error) {
        if (data1.msg.count === 0) {
          // нет подписки
          userContractsArrOut.push({/*...subscription[0],*/ response: "refuse",type_system: ts.name})
        } else {
          // сбда вставить подсчет сколько
          let usedCreditsAfterSubscription = 0;
          let data2 = await hafServices.getCountOutcoming(setupUser.CompanyId, data1.msg.rows[0].subscription_start_date, data1.msg.rows[0].subscription_end_date);
          if(!data2.error)
            usedCreditsAfterSubscription =parseInt(data2.msg)
          else
            usedCreditsAfterSubscription = 0

          const ownerContract = await hafServices.getUserInfo(data1.msg.rows[0].UserId);
          // console.log("ownerContract=",ownerContract,"data1=",data1);
          const productObj = await axios.get(
            `/api/contractbycontract_id?&contract_id=${data1.msg.rows[0].status}&CompanyContractId=${data1.msg.rows[0].CompanyId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
              },
            }
          );
          // console.log("productObj=",productObj)
          let arr = [];
          arr.push({
            source: data1.msg.rows[0].subscription_status===0?"mysql":"stripe",
            id: data1.msg.rows[0].status,
            customer: data1.msg.rows[0].description,
            credit: data1.msg.rows[0].incoming,
            used_credit: usedCreditsAfterSubscription,
            email: ownerContract.msg.email,
            firstname: ownerContract.msg.firstname,
            lastname: ownerContract.msg.lastname,
            created: moment(data1.msg.rows[0].credit_date).valueOf() / 1000,
            current_period_start:
              moment(data1.msg.rows[0].subscription_start_date).valueOf() /
              1000,
            current_period_end:
              moment(data1.msg.rows[0].subscription_end_date).valueOf() /
              1000,
            subscription_status: data1.msg.rows[0].subscription_status,
            stripe_price_id: data1.msg.rows[0].stripe_price_id,
            product: /*productObj.data.msg.rows[0].product*/ "Contract", //Haf-Limited-25, ASI Unlimited
            cost: data1.msg.rows[0].total_sum,
            type_subscription: productObj.data.msg.rows[0].status_contract //"Contract", //Limited Unlimted
          });
          // console.log("arr=",arr)
          userContractsArrOut.push({...arr[0], response: "exists", type_system: ts.name})
        }
      }
    }
    resolve({ error: false, msg: userContractsArrOut });
  });
};

//========================================================================================
/*                                                                                      *
 *                                       NOT USED                                       *
 *                                                                                      */
//========================================================================================

// const searchSubscriptionInMySql = function (setupUser) {
//   return new Promise((resolve) => {
//     const hafServices = new HafServices();
//     hafServices.searchSubsriptionByPeriod(setupUser.CompanyId).then((data1) => {
//       // console.log("data1.msg=",data1);
//       if (!data1.error) {
//         // No Records
//         if (data1.msg.count === 0) {
//           resolve({
//             error: false,
//             msg: [],
//             response: "refuse",
//           });
//         } else {
//           let arr = []
//           arr.push({
//             source: "mysql",
//             id: data1.msg.rows[0].status,
//             customer: data1.msg.rows[0].description,
//             email: "",
//             firstname: "",
//             lastname: "",
//             created: moment(data1.msg.rows[0].credit_date).valueOf()/1000,
//             current_period_start: moment(data1.msg.rows[0].subscription_start_date).valueOf()/1000,
//             current_period_end: moment(data1.msg.rows[0].subscription_end_date).valueOf()/1000,
//             subscription_status: data1.msg.rows[0].subscription_status
//           });
//           resolve({
//             error: false,
//             msg: arr,
//             response: "exists",
//           });
//         }
//       }
//     });
//   })
// }

const getOwnerSubscription = function (userSubscriptionsArr) {
  if (userSubscriptionsArr.filter(el=>el.source==="stripe").length > 0) return `${userSubscriptionsArr.filter(el=>el.source==="stripe")[0].firstname} ${userSubscriptionsArr.filter(el=>el.source==="stripe")[0].lastname} ${userSubscriptionsArr.filter(el=>el.source==="stripe")[0].email}`;
  else return "";
};
const getEndPeriodSubscription = function (userSubscriptionsArr) {
    return userSubscriptionsArr.current_period_end;
};
const getStartPeriodSubscription = function (userSubscriptionsArr) {
    return userSubscriptionsArr.current_period_start;
};
const getIntervalSubscription = function (userSubscriptionsArr, type_system = "ASI") {
  // console.log("userSubscriptionsArr=",userSubscriptionsArr)
  const currentSubscription = userSubscriptionsArr.find(el=>el.type_system===type_system)
  if(currentSubscription.hasOwnProperty("current_period_start") && currentSubscription.hasOwnProperty("current_period_end")){
    return `from ${moment( getStartPeriodSubscription(currentSubscription) * 1000).format('MMMM Do YYYY, h:mm a')} to ${moment(getEndPeriodSubscription(currentSubscription) * 1000).format('MMMM Do YYYY, h:mm a')}`;
  } else {
    return ``;
  }

  // if (userSubscriptionsArr.length > 0)
  //   return `from ${moment( getStartPeriodSubscription(userSubscriptionsArr) * 1000).format('MMMM Do YYYY, h:mm a')} to ${moment(getEndPeriodSubscription(userSubscriptionsArr) * 1000).format('MMMM Do YYYY, h:mm a')}`;
  // else return ``;
};
const getExpireDateSubscription = function (userSubscriptionsArr, type_system = "ASI") {
  const currentSubscription = userSubscriptionsArr.find(el=>el.type_system===type_system) || {}
  // console.log("userSubscriptionsArr=",userSubscriptionsArr,"currentSubscription=",currentSubscription)
  if(currentSubscription.hasOwnProperty("current_period_start") && currentSubscription.hasOwnProperty("current_period_end")){
    return `It will expire on ${moment(getEndPeriodSubscription(currentSubscription) * 1000).format('MMMM Do YYYY, h:mm a')}`
  } else {
    return ``;
  }
  // if (userSubscriptionsArr.length > 0)
  //   return `It will expire on ${moment(getEndPeriodSubscription(userSubscriptionsArr) * 1000).format('MMMM Do YYYY, h:mm a')}`
  // else return ``;
};

// Object.prototype.hasOwnProperty.call(el,"mandatory")
const checkIntervalSubscription = function (userSubscriptionsArr,productListArr) {
  // console.log("productListArr=",productListArr);
  if (userSubscriptionsArr.length > 0) {
    const asiSubscription = userSubscriptionsArr.find((el) => el.type_system === "ASI");
    if(asiSubscription.type_subscription ==="Hold On" || asiSubscription.type_subscription==="Dismiss" || asiSubscription.type_subscription==="Dismiss"){
      return false;
    }
    if(productListArr.find(el=>el.stripe_price_id===asiSubscription.stripe_price_id)?.status==="inactive"){
      return false;
    }
    // if (asiSubscription.hasOwnProperty("current_period_start") && asiSubscription.hasOwnProperty("current_period_end")) {
    if (asiSubscription.hasOwnProperty("current_period_start") && asiSubscription.hasOwnProperty("current_period_end")) {
      if (
        asiSubscription.current_period_start * 1000 < moment().valueOf() &&
        asiSubscription.current_period_end * 1000 > moment().valueOf()
      ) {
        return true;
      } else {
        // console.log("1")
        return false;
      }
    } else {
      // console.log("2")
      return false;
    }
  } else return false;


  // if (userSubscriptionsArr.length > 0)
  //   if (
  //     userSubscriptionsArr[0].current_period_start * 1000 <
  //       moment().valueOf() &&
  //     userSubscriptionsArr[0].current_period_end * 1000 > moment().valueOf()
  //   )
  //     return true;
  //   else return false;
  // else return false;
};

function isNumber(val) {
  return !isNaN(val);
}

const paymentScmType = (paymentScheme, adult_type) => {
  // console.log("adult_type=",adult_type)
  switch(adult_type.toUpperCase())
  {
    case "ADULT":
      return `${paymentScheme.adultAssessment} credit`;
    case "ADOLESCENT":
      return `${paymentScheme.adolescentAssessment} credit`;
    case "ASI":
      return `${paymentScheme.asiAssessment}`;
    case "ASILITE":
        return `${paymentScheme.asiliteAssessment}`;
    default:
      return `${paymentScheme.adultAssessment} credit`;
  }
}

const paymentScmNarrative = (paymentScheme, adult_type) => {
  // console.log("adult_type=",adult_type)
  switch(adult_type.toUpperCase())
  {
    case "ADULT":
      return `${paymentScheme.adultNarrative} credit`;
    case "ADOLESCENT":
      return `${paymentScheme.adolescentNarrative} credit`;
    case "ASI":
      return `${paymentScheme.asiNarrative}`;
    case "ASILITE":
      return `${paymentScheme.asiliteNarrative}`;
    default:
      return `${paymentScheme.adultNarrative} credit`;
  }
}

const paymentScmRefund = (paymentScheme, adult_type) => {
  // console.log("adult_type=",adult_type)
  switch(adult_type.toUpperCase())
  {
    case "ADULT":
      return `${paymentScheme.adultAssessment}`;
    case "ADOLESCENT":
      return `${paymentScheme.adolescentAssessment}`;
    case "ASI":
      return `${paymentScheme.asiAssessment}`;
    case "ASILITE":
        return `${paymentScheme.asiliteAssessment}`;
    default:
      return `${paymentScheme.adultAssessment}`;
  }
}
const compareJsonSection = (json1, json2, section) => {
  // check lenngth
  // сравнить два массива shortname
  //
  // const arr_json1 = JSON.parse(string_json1);
  // const arr_json2 = JSON.parse(string_json2);
  const arr_json1 = json1;
  const arr_json2 = json2;
  // console.log("arr_json1=",arr_json1,"arr_json2=",arr_json2)
  for (let i = 0; i < arr_json1.length; i++) {
    if (arr_json1[i].section === section) {
      if(JSON.stringify(arr_json1[i].body) === JSON.stringify(arr_json2[i].body)){
        return true;
      } else {
        return false;
      }

      // for (let j = 0; j < arr_json1[i].body.length; j++) {
      //   if (arr_json[i].body[j].type === "checkbox") {

      //   } else {
      //     // simple
      //     if (arr_json1[i].body[j].shortname === arr_json2[i].body[j].shortname) {
      //       arr_json1[i].body[j].value === arr_json2[i].body[j].value

      //     }
      //   }
      // }
    }
  }
};
const generateReferral = (length) => {
  var result = "";
  var words = "123456789ABCDEFGHIJKLMNPQRSTUVWXYZ";
  var max_position = words.length - 1;
  for (let i = 0; i < length; ++i) {
    let position = Math.floor(Math.random() * max_position);
    result = result + words.substring(position, position + 1);
  }
  result = "R" + result;
  return result;
};

const getRankCircleStatus = (QuantitySubstances, RankStatus) => {
  switch (QuantitySubstances) {
    case 0:
      return true;
    case 1:
      if (RankStatus < 1) return false;
      else return true;
    case 2:
        if (RankStatus < 3) return false;
        else return true;
    case 3:
      if (RankStatus < 6) return false;
      else return true;
    default:
      if (RankStatus < 6) return false;
      else return true;
  }
};

const calcSubscription = (outcoming,creditPlan) => {
  console.log("outcoming=",Number(outcoming))
  console.log("creditPlan=",Number(creditPlan))
  let newCredit = Number(creditPlan) > Number(outcoming) ? Number(outcoming) : Number(creditPlan);
  return newCredit;
}

const removeNodeId = (node, id) => {
  if (node === null) {
    return null;
  } else {
   return node.reduce((acc, child) => {
     if(child["id"] === id) {
       const removedChild = removeNodeId(child["children"], id);
       acc = [...acc, ...removedChild];
     } else {
       child.children = removeNodeId(child["children"], id);
       acc.push(child);
     }
     return acc;
   }, []);
 }
}

function deleteItemInTree(tree,search_id) {
  // console.log(strJSON)
  let newTree =  Object.assign({},{ id: 0, children: tree });
  // let newTree2 = {}

  // console.log(">>>>>>>>>>>>>>>>newTree=",newTree);
  let idArray = [];

  let cur_children; // текущая ссылка
  let memory = [newTree]; // память (стек)
  // в начале память содержит ссылку на корень заданного дерева

  // внешний цикл, перебирающий линии заглублений
  // закончить цикл, если не получается извлечь ссылку из памяти (стека)
  while ((cur_children = memory.pop())) {
    // console.log("cur_children=",cur_children)
    // внутренний цикл обхода каждой линии заглубления дерева до листа

    while (true) {
      // ...обработка данных узла...
      // console.log(cur_children.id); // просто выводим в консоль
      idArray.push(cur_children.id);
      if (cur_children.id === search_id) {
        // cur_children.title=title
        // continue
        console.log("cur_children=",cur_children);
      }
      // если это лист, выйти из цикла
      // if (!cur_children.children) break;
      // console.log("cur_children=",cur_children)
      // console.log("cur_children.children=",cur_children.children)
      if (cur_children.children.length < 1) break;
      // помещаем ветви, ведущие налево, в память (стек)
      for (let i = 0; i < cur_children.children.length - 1; i++) {
        memory.push(cur_children.children[i]);
        console.log("cur_children.children[i]=",cur_children.children[i]);
        // newTree2.push(cur_children.children[i]);
      }
      // переходим по ветви, ведущей направо
      cur_children = cur_children.children[cur_children.children.length - 1];
    }
  }
  // console.log("newTree2=",newTree2);
  return newTree.children;;
}

function putTitleInTree(tree,search_id,title) {
  // console.log("arguments=",arguments)
  // console.log(strJSON)
  let newTree =  Object.assign({},{ id: 0, children: tree });
  // console.log(">>>>>>>>>>>>>>>>newTree=",newTree);
  let idArray = [];

  let cur_children; // текущая ссылка
  let memory = [newTree]; // память (стек)
  // в начале память содержит ссылку на корень заданного дерева

  // внешний цикл, перебирающий линии заглублений
  // закончить цикл, если не получается извлечь ссылку из памяти (стека)
  while ((cur_children = memory.pop())) {
    // console.log("cur_children=",cur_children)
    // внутренний цикл обхода каждой линии заглубления дерева до листа

    while (true) {
      // ...обработка данных узла...
      // console.log(cur_children.id); // просто выводим в консоль
      idArray.push(cur_children.id);
      if (cur_children.id === search_id) {
        cur_children.title=title
        // return newTree.children;
      }
      // если это лист, выйти из цикла
      // if (!cur_children.children) break;
      // console.log("cur_children=",cur_children)
      // console.log("cur_children.children=",cur_children.children)
      if (cur_children.children.length < 1) break;
      // помещаем ветви, ведущие налево, в память (стек)
      for (let i = 0; i < cur_children.children.length - 1; i++) {
        memory.push(cur_children.children[i]);
      }
      // переходим по ветви, ведущей направо
      cur_children = cur_children.children[cur_children.children.length - 1];
    }
  }
  // console.log(idArray)
  return newTree.children;
}
function getTitleInTree(tree,search_id) {
  let newTree =  Object.assign({},{ id: 0, children: tree });
  // console.log(">>>>>>>>>>>>>>>>newTree=",newTree);
  let idArray = [];

  let cur_children; // текущая ссылка
  let memory = [newTree]; // память (стек)
  // в начале память содержит ссылку на корень заданного дерева

  // внешний цикл, перебирающий линии заглублений
  // закончить цикл, если не получается извлечь ссылку из памяти (стека)
  while ((cur_children = memory.pop())) {
    // console.log("cur_children=",cur_children)
    // внутренний цикл обхода каждой линии заглубления дерева до листа

    while (true) {
      // ...обработка данных узла...
      // console.log(cur_children.id); // просто выводим в консоль
      idArray.push(cur_children.id);
      if (cur_children.id === search_id) {
        // cur_children.title=title
        return cur_children.title;
      }
      // если это лист, выйти из цикла
      // if (!cur_children.children) break;
      // console.log("cur_children=",cur_children)
      // console.log("cur_children.children=",cur_children.children)
      if (cur_children.children.length < 1) break;
      // помещаем ветви, ведущие налево, в память (стек)
      for (let i = 0; i < cur_children.children.length - 1; i++) {
        memory.push(cur_children.children[i]);
      }
      // переходим по ветви, ведущей направо
      cur_children = cur_children.children[cur_children.children.length - 1];
    }
  }
  // console.log(idArray)
  return "";
}

function searchMaxId1(tree,id_subtree) {
  // console.log("tree=",tree,"id_subtree=",id_subtree);
  let newTree = Object.assign({}, { id: 0, children: tree });
  // console.log(">>>>>>>>>>>>>>>>newTree=",newTree);
  let idArray = [];

  let cur_children; // текущая ссылка
  let memory = [newTree]; // память (стек)
  // в начале память содержит ссылку на корень заданного дерева

  // внешний цикл, перебирающий линии заглублений
  // закончить цикл, если не получается извлечь ссылку из памяти (стека)
  while ((cur_children = memory.pop())) {
    // console.log("cur_children=",cur_children)
    // внутренний цикл обхода каждой линии заглубления дерева до листа

    while (true) {
      // ...обработка данных узла...
      // console.log(cur_children.id); // просто выводим в консоль
      idArray.push(cur_children.id);

      if (cur_children.id === id_subtree) {
        console.log("cur_children=",cur_children,"id_subtree=",id_subtree,);
       return searchMaxId([cur_children])
      }
      // если это лист, выйти из цикла
      // if (!cur_children.children) break;
      // console.log("cur_children=",cur_children)
      // console.log("cur_children.children=",cur_children.children)
      if (cur_children.children.length < 1) break;
      // помещаем ветви, ведущие налево, в память (стек)
      for (let i = 0; i < cur_children.children.length - 1; i++) {
        memory.push(cur_children.children[i]);
      }
      // переходим по ветви, ведущей направо
      cur_children = cur_children.children[cur_children.children.length - 1];
    }
  }
  // console.log(idArray)
  // return Math.max.apply(null, idArray);
  return {localMaxId:Math.max.apply(null, idArray), arrayOfIndexes:idArray}
}




function searchMaxId(tree) {
  let newTree = Object.assign({}, { id: 0, children: tree });
  console.log(">>>>>>>>>>>>>>>>newTree=",newTree);
  let idArray = [];

  let cur_children; // текущая ссылка
  let memory = [newTree]; // память (стек)
  // в начале память содержит ссылку на корень заданного дерева

  // внешний цикл, перебирающий линии заглублений
  // закончить цикл, если не получается извлечь ссылку из памяти (стека)
  while ((cur_children = memory.pop())) {
    // console.log("cur_children=",cur_children)
    // внутренний цикл обхода каждой линии заглубления дерева до листа

    while (true) {
      // ...обработка данных узла...
      // console.log(cur_children.id); // просто выводим в консоль
      idArray.push(cur_children.id);

      // если это лист, выйти из цикла
      // if (!cur_children.children) break;
      // console.log("cur_children=",cur_children)
      // console.log("cur_children.children=",cur_children.children)
      if (cur_children.children.length < 1) break;
      // помещаем ветви, ведущие налево, в память (стек)
      for (let i = 0; i < cur_children.children.length - 1; i++) {
        memory.push(cur_children.children[i]);
      }
      // переходим по ветви, ведущей направо
      cur_children = cur_children.children[cur_children.children.length - 1];
    }
  }
  console.log(idArray)
  // return Math.max.apply(null, idArray);
  return {localMaxId:Math.max.apply(null, idArray), arrayOfIndexes:idArray}
}

function incrementTreeId(tree, afterId, increment) {
  let newTree = Object.assign({}, { id: 0, children: tree });
  // console.log(">>>>>>>>>>>>>>>>newTree=",newTree);
  let idArray = [];

  let cur_children; // текущая ссылка
  let memory = [newTree]; // память (стек)
  // в начале память содержит ссылку на корень заданного дерева

  // внешний цикл, перебирающий линии заглублений
  // закончить цикл, если не получается извлечь ссылку из памяти (стека)
  while ((cur_children = memory.pop())) {
    // console.log("cur_children=",cur_children)
    // внутренний цикл обхода каждой линии заглубления дерева до листа

    while (true) {
      // ...обработка данных узла...
      // console.log(cur_children.id); // просто выводим в консоль
      idArray.push(cur_children.id);
      if (cur_children.id > afterId) cur_children.id =cur_children.id + increment;
      // если это лист, выйти из цикла
      // if (!cur_children.children) break;
      // console.log("cur_children=",cur_children)
      // console.log("cur_children.children=",cur_children.children)
      if (cur_children.children.length < 1) break;
      // помещаем ветви, ведущие налево, в память (стек)
      for (let i = 0; i < cur_children.children.length - 1; i++) {
        memory.push(cur_children.children[i]);
      }
      // переходим по ветви, ведущей направо
      cur_children = cur_children.children[cur_children.children.length - 1];
    }
  }
  // console.log(idArray)
  return newTree.children;
}

function putLevelInTitleInTree(tree) {
  // console.log("arguments=",arguments)
  // console.log(strJSON)
  let newTree =  Object.assign({},{ id: 0, children: tree });
  // console.log(">>>>>>>>>>>>>>>>newTree=",newTree);
  let idArray = [];

  let cur_children; // текущая ссылка
  let memory = [newTree]; // память (стек)
  // в начале память содержит ссылку на корень заданного дерева

  // внешний цикл, перебирающий линии заглублений
  // закончить цикл, если не получается извлечь ссылку из памяти (стека)
  while ((cur_children = memory.pop())) {
    // console.log("cur_children=",cur_children)
    // внутренний цикл обхода каждой линии заглубления дерева до листа

    while (true) {
      // ...обработка данных узла...
      // console.log(cur_children.id); // просто выводим в консоль
      idArray.push(cur_children.id);
      // if (cur_children.id === search_id) {
        cur_children.title=cur_children.level+ ": " +cur_children.title;
        // return newTree.children;
      // }
      // если это лист, выйти из цикла
      // if (!cur_children.children) break;
      // console.log("cur_children=",cur_children)
      // console.log("cur_children.children=",cur_children.children)
      if (cur_children.children.length < 1) break;
      // помещаем ветви, ведущие налево, в память (стек)
      for (let i = 0; i < cur_children.children.length - 1; i++) {
        memory.push(cur_children.children[i]);
      }
      // переходим по ветви, ведущей направо
      cur_children = cur_children.children[cur_children.children.length - 1];
    }
  }
  // console.log(idArray)
  return newTree.children;
}

const checkJsonKeyAsam = (arr_json, shortnameAsam) => {
  // const arr_json = JSON.parse(string_json);
  let result = false;
  for (let i = 0; i < arr_json.length; i++) {
    if(arr_json[i].section === "AC") { // ASAM Criteris
      for (let j = 0; j < arr_json[i].body.length; j++) {
        // console.log(">>>=",arr_json[i].body[j].shortname)
        if (arr_json[i].body[j].shortname === shortnameAsam ) {
          result=true;
          return result;
        }
      }
    }
  }
  return result;
};

const handleCheckSubscription = async (email) => {
  try {

    const response = await axios.post('/api/check-subscription', { email },{
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
  });
    return ({error: false, msg:response.data.message, activeSubscriptions: response.data.activeSubscriptions, trialingSubscriptions:response.data.trialingSubscriptions});
  } catch (error) {
    if (error.response) {
      return ({error: true, msg:error.response.data.message});
    } else {
      return('Error checking subscription');
    }
  }
};


const hafCountSubscription = async (email,priceId) => {
  try {

    const response = await axios.post('/api/count-subscription', { email,priceId },{
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
  });
    return ({error: false, msg:response.data.message});
  } catch (error) {
    if (error.response) {
      return ({error: true, msg:error.response.data.message});
    } else {
      return('Error checking subscription');
    }
  }
};

const addUserToSubscription = async (userEmail,priceId) => {
  try {

    const response = await axios.post('/api/add-user-to-subscription', { userEmail,priceId },{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
  });
    return ({error: false, msg:response.data.message});
  } catch (error) {
    if (error.response) {
      return ({error: true, msg:error.response.data.message});
    } else {
      return('Error checking subscription');
    }
  }
};
const deleteUserFromSubscription = async (userEmail,priceId) => {
  try {

    const response = await axios.post('/api/delete-user-from-subscription', { userEmail,priceId },{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
  });
    return ({error: false, msg:response.data.message});
  } catch (error) {
    if (error.response) {
      return ({error: true, msg:error.response.data.message});
    } else {
      return('Error checking subscription');
    }
  }
};


const handleUpdateSubscription = async (customerEmail,trialDays) => {
  try {

    const response = await axios.post('/api/update-subscription', { customerEmail, trialDays },{
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
  });
    return ({error: false, msg:response.data.subscriptions});
  } catch (error) {
    if (error.response) {
      return ({error: true, msg:error.response.data.message});
    } else {
      return('Error checking subscription');
    }
  }
};

const findSubsByPriceId = (subsOfEmail,priceId) => {
  // console.log("subsOfEmail=",subsOfEmail,"priceId=",priceId)
  let {activeSubscriptions} = subsOfEmail;
  let {trialingSubscriptions} = subsOfEmail;
  // console.log("activeSubscriptions=",activeSubscriptions)
  // console.log("trialingSubscriptions=",trialingSubscriptions)
  for(let i=0; i<activeSubscriptions.length; i++){
    if(activeSubscriptions[i].plan.id===priceId)
      return "active";
  }

  for(let i=0; i<trialingSubscriptions.length; i++){
    // console.log("trialingSubscriptions el=",el.plan.id," ",priceId);
    if(trialingSubscriptions[i].plan.id===priceId)
      return "trialing";
  }

  return "none"
}

const getEmailsAndSubscriptionCount = async (priceId) => {
  try {

    const response = await axios.post('/api/list-and-count-subscription', {priceId},{
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
        },
  });
    // console.log("response.data=",response.data);
    return ({error: false, msg:response.data});
  } catch (error) {
    if (error.response) {
      return ({error: true, msg:error.response.data});
    } else {
      return('Error checking subscription');
    }
  }
};

// const getSubscriptionInStripe = async function (setupUser) {
//   let subscriptionsWithCustomersArr = [];
//   let userSubscriptionsArr = [];
//   const subscriptionsReplay = await axios.get("/api/subscriptions", {});
//   const customersReplay = await axios.get("/api/customers", {});
//   const subscriptionsTable = subscriptionsReplay.data.msg.data.map((el) => el);
//   const customersTable = customersReplay.data.msg.data.map((el) => el);
//   for (let i = 0; i < subscriptionsTable.length; i++) {
//     for (let j = 0; j < customersTable.length; j++) {
//       if (subscriptionsTable[i].customer === customersTable[j].id) {
//         subscriptionsWithCustomersArr.push({
//           id: subscriptionsTable[i].id,
//           email: customersTable[j].email,
//           created: subscriptionsTable[i].created,
//           current_period_start: subscriptionsTable[i].current_period_start,
//           current_period_end: subscriptionsTable[i].current_period_end,
//         });
//       }
//     }
//   }
//   const userSubscriptions =
//     subscriptionsWithCustomersArr.filter(
//       (el) =>
//         el.email === setupUser.email &&
//         el.current_period_start * 1000 < moment().valueOf() &&
//         el.current_period_end * 1000 > moment().valueOf()
//     ).length > 0
//       ? subscriptionsWithCustomersArr
//           .filter(
//             (el) =>
//               el.email === setupUser.email &&
//               el.current_period_start * 1000 < moment().valueOf() &&
//               el.current_period_end * 1000 > moment().valueOf()
//           )
//           .reduce((prev, cur) => {
//             if (prev.current_period_end > cur.current_period_end) {
//               return prev;
//             }
//             return cur;
//           })
//       : [];
//   userSubscriptionsArr = Array.isArray(userSubscriptions)
//     ? []
//     : [userSubscriptions];
//     return userSubscriptionsArr;
// };

export {
	getConnection,
	printKey,
	printKey1,
	decode,
	getRelatedFields,isFieldArrValid,
  checkReadyElArr,
	jsonCheckTopic,
	jsonCheck,
  jsonCheckNormal,
	calcFullYear,
	jsonElem,
	setJsonTopic,
	getJsonTopic,
	getJsonMaxTopic,
	updateBaseJsonVal,
  updateBaseJsonVal2,
	updateGenderFork,
	updateMarriageFork,
	updateBaseJsonDisabled,
	makeList,
	returnClearValue,
	ArrInArr,
	generateHafAssessment,
	u_atob,
	u_btoa,
	s2ab,
	getAllUrlParams,
	jsonShortElem,
	versionCheck,
	readMassive,
  readMassiveForExport,
  convertReadMassiveForExport,
  concatMassive,
  readMassiveForExportVer2,
	migrationFork,
	getElem,
	getAction,
	makeImpressionsArray,
	splitStr,
	readElement,
	custGenerate,
	isValueEmpty,
	formatMoney,
	isDeepFieldValid,
	isFieldValid,
	// sectionsToIgnore,
	getInternetExplorerVersion,
	updateSexFork,
	contains_rank,
	showField,
	showSearchField,
	getJsonTopicByNum,
	updateED8Fork,
  changeClassNames,
  addClass,
  delClass,
  injectGA,
  semantickInputControl,
  findInputControl,
  roundPlus,
  PrependZeros,
  ActNext,
  ActPrev,
  controlDiadTitle,
  controlDiadCode,
  jsonCheckboxElem,
  jsonCheckboxElemLink,
  jsonSearchType,
  putPlaceHolder,
  getPromoStatus,
  updateM1,
  getAssessmentPattern,
  getAllShortName,
  getAllShortNameWithStatus,
  getInArray,
  getInArrayWithAdolField,
  checkLengthEditor,
  getDetail,
  getSubscriptionInStripe,
  getOwnerSubscription,
  getEndPeriodSubscription,
  getStartPeriodSubscription,
  getIntervalSubscription,
  getExpireDateSubscription,
  checkIntervalSubscription,
  searchSubscriptionInStripeAndMySql,
  // searchSubscriptionInMySql,
  isNumber,
  paymentScmType,
  paymentScmRefund,
  paymentScmNarrative,
  compareJsonSection,
  generateReferral,
  getRankCircleStatus,
  calcSubscription,
  searchLocalSubscription,
  searchLocalContract,
  putTitleInTree,
  getTitleInTree,
  deleteItemInTree,
  removeNodeId,
  searchMaxId,
  incrementTreeId,
  searchMaxId1,
  putLevelInTitleInTree,
  checkJsonKeyAsam,
  handleCheckSubscription,
  handleUpdateSubscription,
  findSubsByPriceId,
  addUserToSubscription,
  deleteUserFromSubscription,
  hafCountSubscription,
  getEmailsAndSubscriptionCount
};




    // console.log(strObj);

    //1 нужно получить `${el.name} + ${el.prevalue}`
    // [parentName,parentShortName] = getParent([massive.name,massive])
    // while (parentShortName.length > 0) {
    //   [parentName,parentShortName] = getParent([curName,curShortName])
    // }

    // [tmp_state, arr_] = setDepNext([tmp_state, arr_]);
    // while (arr_.length > 0) {
    //   [tmp_state, arr_] = setDepNext([tmp_state, arr_]);
    // }

    // switch (massive[i].type) {
    //   case "select":

    //     break;
    //   case "checkbox":
    //   case "radio":

    //     break;
    //   case "content":
    //   case "number":
    //   case "money":

    //     break;
    //   case "content":
    //   case "number":
    //   case "money":

    //     break;
    //   case "year":
    //   case "month":
    //   case "date":
    //   case "textarea":
    //   case "editor":
    //   case "content":
    //   case "text":
    //   case "withcode":
    //   case "withtitle":
    //     console.log("massive[",i,"]=",massive[i])
    //     if (massive[i].prevalue===""){
    //       result.push({
    //         shortname: massive[i].shortname,
    //         q: massive[i].name,
    //         // prevalue: jsonState[i].body[j].prevalue,
    //         a: massive[i].value,
    //       });
    //     } else {
    //       result.push({
    //         shortname: massive[i].shortname,
    //         q: `${massive[i].name} ${massive[i].prevalue}`,
    //         // prevalue: jsonState[i].body[j].prevalue,
    //         a: massive[i].value,
    //       });
    //     }
    //     break;
    //   case "hour_minute":
    //   case "year_month":
    //         break
    //   default:
    //     break;
    // }
    // for (let j = 0; j < jsonState[i].body.length; j++) {
    // 	massive.push({
    //     name: jsonState[i].body[j].name,
    //     prevalue: jsonState[i].body[j].prevalue,
    // 		// shortname: jsonState[i].body[j].shortname,
    // 		value: jsonState[i].body[j].value,
    //     value2: jsonState[i].body[j].value2,
    // 		type: jsonState[i].body[j].type,
    // 		radio_options: jsonState[i].body[j].radio_options,
    // 	});
    // }
