const demographicInformationJSON =  {
  // section: "DEMO",
  // topic: "Demographic Information",
  // checked: "true",
  // // access: "public",
  // color: "secondary",
  section: "GI",
  topic: "GENERAL INFORMATION",
  checked: "true",
  // access: "public",
  color: "secondary",
  description: "",
  body: [
    {
      id: "G1",
      shortname: "gi1",
      name: "ID Number:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "text",
      act: "GI1",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "G4",
      shortname: "gi4",
      name: "Date of Admission:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "date",
      act: "GI4",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "G5",
      shortname: "gi5",
      name: "Date of Interview:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "date",
      act: "GI5",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "G6",
      onlyasi: "",  //G6
      shortname: "gi6",
      name: "Time Begun: Use 24 hr clock; code hours:minutes",
      prevalue: 'Hrs&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbspMins',
      value: "",
      value2: "",
      disabled: "false",
      type: "hour_minute",
      act: "GI6",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "G7",
      onlyasi: "",  //G7
      shortname: "gi7",
      name: "Time Ended: Use 24 hr clock; code hours:minutes",
      prevalue: 'Hrs&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbspMins',
      value: "",
      value2: "",
      disabled: "false",
      type: "hour_minute",
      act: "GI7",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "G8",
      shortname: "gi8",
      name: "Class:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - Intake",
        "2 - Follow-up",
      ],
      act: "GI8",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "G9",
      shortname: "gi9",
      name: "Contact Code:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - In person",
        "2 - Telephone (Intake ASI must be in person)",
        "3 - Mail"
      ],
      act: "GI9",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "G10",
      shortname: "demo4",
      name: "Gender:",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Male", "Female"],
      act: "DEMO4",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "G99",
      shortname: "gi99",
      name: "Treatment Episode Number:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "number",
      act: "GI99",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "G11",
      shortname: "gi11",
      name: "Interviewer Code Number:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "interviewer_code",
      act: "GI11",
      // mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "G12",
      shortname: "gi12",
      name: "Special:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - Patient terminated",
        "2 - Patient refused",
        "3 - Patient unable to respond (language or intellectual barrier, under the influences, etc.)",
        "N - Interview completed"
      ],
      act: "GI12",
      // mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "G14",
      shortname: "gi14",
      name: "How long have you lived at your current adress?",
      prevalue: 'Yrs&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbspMon',
      value: "",
      value2: "",
      disabled: "false",
      type: "year_month",
      act: "GI14",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "G15",
      onlyasi: "",  //G15
      shortname: "gi15",
      name: "Is this residence owned by you or your family?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "GI15",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "G16",
      shortname: "demo5",
      name: "Date of birth?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "date",
      act: "DEMO5",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "G17",
      shortname: "gi17",
      name: "Of what race do you consider yourself?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - White (not Hisp)",
        "2 - Black (not Hisp)",
        "3 - American Indian",
        "4 - Alaskan Native",
        "5 - Asian/Pacific",
        "6 - Hispanic-Mexican",
        "7 - Hispanic-Puerto Rican",
        "8 - Hispanic-Cuban",
        "9 - Unknown",
      ],
      act: "GI17",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "G18",
      shortname: "gi18",
      name: "Do you have a religious preference?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - Protestant",
        "2 - Catholic",
        "3 - Jewish",
        "4 - Islamic",
        "5 - Other",
        "6 - None",
      ],
      act: "GI18",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "G19",
      shortname: "pp6",
      name: "Have you been in a controlled environment in the past 30 days?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PP6",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "GI19",
    },
    {
      id: "G19",
      extend: "true",
      shortname: "gi19",
      name: "Have you been in a controlled environment in the past 30 days?",
      prevalue: "A place, theoretically, without access to drugs/alcohol.",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        // "1-No",
        "2 - Jail/prison",
        "3 - Alcohol or drug tx",
        "4 - Medical tx",
        "5 - Psychiatric tx",
        "6 - Other",
      ],
      act: "GI19",
      mandatory: "true",
      // depending: "",
      // exception: "",
      link: "GI20",
      depending: "PP6",
      exception: "Yes",
    },
    {
      id: "G20",
      extend: "true",
      shortname: "gi20",
      name: "",
      prevalue: "How many days? If G19 is No, code NN. Refers to total number of days detained in the past 30 days",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "GI20",
      mandatory: "true",
      // depending: "GI19",
      // exception: "1-No",
      depending: "PP6",
      exception: "Yes",
    },
    {
      id: "G-COMMENT",
      shortname: "gi-comment",
      access: "private",
      name: "GENERAL INFORMATION COMMENTS:",
      prevalue: "(Include the question number with your notes)",
      value: "",
      disabled: "false",
      type: "editor",
      act: "GI-COMMENT",
      depending: "",
      exception: "",
    },
  ],
}

export default demographicInformationJSON;
