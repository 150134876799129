import base64url from "base64url";
import moment from "moment";
import { Constants } from "./constants.js";
import HafServices from "./UserFunctions";
import axios from "axios";
import OpenAI from "openai";

const options = {
  temperature: 0.1,
  max_tokens: 100,
};

// async function createChatCompletion(messages, options = {}) {
//   try {
//     const response = await openai.post("/chat/completions", {
//       model: options.model || "gpt-4-turbo-preview",
//       messages,
//       ...options,
//     });

//     return response.data.choices;
//   } catch (error) {
//     console.error("Error creating chat completion:", error);
//   }
// }

async function createChatCompletion(messages, options = {}) {
  const openai_api_key = await axios.get(`/api/return_openai_api_key`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
    },
  });
  console.log("openai_api_key=", openai_api_key);
  const openai = new OpenAI({
    // apiKey: process.env["REACT_APP_OPENAI_API_KEY"],
    apiKey: openai_api_key.data.msg,
    dangerouslyAllowBrowser: true,
  });
  const completion = await openai.chat.completions.create({
    messages: messages,
    model: options.model || "gpt-4-turbo-preview",
  });
  console.log(completion.choices[0]);
  return completion.choices[0]
}

// async function createFileUploading(files, options = {}) {
//   try {
//     const response = await openaifile.post("/files", {
//       files
//     });

//     return response.data.choices;
//   } catch (error) {
//     console.error("Error creating chat completion:", error);
//   }
// }

//!========================================================================================
/*                                                                                      *
 *                             FUNCTION  createFileUploading                            *
 *                                                                                      */
//!========================================================================================

async function createFileUploading(CompanyId, filename, options = {}) {
  // You can also pass a `fetch` `Response`:
  //========================================================================================
  /*                                                                                      *
   *                                     LOAD API KEY                                     *
   *                                                                                      */
  //========================================================================================

  const openai_api_key = await axios.get(`/api/return_openai_api_key`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
    },
  });
  console.log("openai_api_key=", openai_api_key);
  const openai = new OpenAI({
    // apiKey: process.env["REACT_APP_OPENAI_API_KEY"],
    apiKey: openai_api_key.data.msg,
    dangerouslyAllowBrowser: true,
  });

  axios.post(`/api/downloadfile?id=${CompanyId}`,
    {
      id: CompanyId,
      filename: filename
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("usertoken")}`,
      },
    }
  ).then(async (res) => {
    const fileName = filename;
    var binary = atob(res.data);
    var array = new Uint8Array(binary.length);
    for (var i = 0; i < binary.length; i++) {
      array[i] = binary.charCodeAt(i);
    }
    var blob = new Blob([array]);
    // window.saveAs(blob, fileName);

    const file = await openai.files.create({
      file: new File([blob], fileName),
      purpose: "assistants",
    });

    console.log(">>>>>file=",file);
    return res;
  });
}

// export { createChatCompletion, createFileUploading };

export { createFileUploading, createChatCompletion };
