// import {
//   returnClearValue,
//   ArrInArr,
//   splitStr,
//   contains_rank,
//   Constants,
//   jsonElem,
//   jsonCheckboxElemLink,
//   jsonSearchType,
// } from "../api";
import initialState from "./state";
import adultPattern from "./adultPattern";
import adolescentPattern from "./adolescentPattern";
import blockAlcohol from "./jsonsAdolescent/blockAlcohol";
import blockCannabis from "./jsonsAdolescent/blockCannabis";
import blockCocaine from "./jsonsAdolescent/blockCocaine";
import blockCrack from "./jsonsAdolescent/blockCrack";
import blockCrank from "./jsonsAdolescent/blockCrank";
import blockEcstasy from "./jsonsAdolescent/blockEcstasy";
import blockHallucinogens from "./jsonsAdolescent/blockHallucinogens";
import blockHeroin from "./jsonsAdolescent/blockHeroin";
import blockInhalants from "./jsonsAdolescent/blockInhalants";
import blockMeth from "./jsonsAdolescent/blockMeth";
import blockMethadone from "./jsonsAdolescent/blockMethadone";
import blockOpiates from "./jsonsAdolescent/blockOpiates";
import blockSedative from "./jsonsAdolescent/blockSedative";
import blockTobacco from "./jsonsAdolescent/blockTobacco";
import blockOther from "./jsonsAdolescent/blockOther";
// import moment from "moment";

const transformrulereducer = (state = initialState, action) => {
  let act = action.type.split("_");
  let tmp_state = {};
  let objectTmp = {};
  let valueTmp = [];
  switch (action.type) {
    case "SET_TOOLTIP_TEXT": {
      return Object.assign({}, state, { toolTipDisorder: action.payload });
    }
    case "SET_TRANSFORMRULE_SECTION_LEFT": {
      return Object.assign({}, state, {
        transformruleSectionLeft: action.payload.section,
      });
    }
    case "SET_TRANSFORMRULE_SECTION_RIGHT": {
      return Object.assign({}, state, {
        transformruleSectionRight: action.payload.section,
      });
    }
    case "SET_TRANSFORMRULE_ID_LEFT": {
      return Object.assign({}, state, {
        transformruleIdLeft: {
          id: action.payload.id,
          shortname: action.payload.shortname,
        },
      });
    }
    case "SET_TRANSFORMRULE_ID_RIGHT": {
      return Object.assign({}, state, {
        transformruleIdRight: {
          id: action.payload.id,
          shortname: action.payload.shortname,
        },
      });
    }
    case "SET_TRANSFORMRULE_RESULT": {
      // console.log("state.transformruleResult=",state.transformruleResult);
      // return Object.assign({}, state, { transformruleResult: action.payload.result, transformruleResultPos: action.payload.position});
      return Object.assign({}, state, { transformruleResult: action.payload });
    }
    case "SET_TRANSFORMRULE_RESULT_POSITION": {
      // console.log("state.transformruleResult=",state.transformruleResult);
      // return Object.assign({}, state, { transformruleResult: action.payload.result, transformruleResultPos: action.payload.position});
      return Object.assign({}, state, {
        transformruleResultPos: action.payload,
      });
    }
    case "SET_TRANSFORMRULE_NAME": {
      return Object.assign({}, state, { transformruleName: action.payload });
    }

    case "SET_NEW_TRANSFORMRULE_PREDICATE_RIGHT": {
      const tmp_state = Object.assign({}, state, {
        newTransformrulePredicateRight: {
          ...state.newTransformrulePredicateRight,
          idKey: action.payload.idKey,
          id: action.payload.id,
          shortname: action.payload.shortname,
          title: action.payload.title,
          type: action.payload.type,
          scopeOperations: action.payload.scopeOperations,
          operation: action.payload.operation,
          scopeValues: action.payload.scopeValues,
          value: action.payload.value,
        },
      });
      if(state.transformruleName===""){
        return Object.assign({}, tmp_state, {
          transformruleName:action.payload.id
        });
      } else if(state.transformruleName===state.newTransformrulePredicateLeft.id) {
      return Object.assign({}, tmp_state, {
        transformruleName:state.transformruleName +"-"+ action.payload.id
      });
    } else return tmp_state;
    }
    case "SET_NEW_TRANSFORMRULE_PREDICATE_LEFT": {
      const tmp_state = Object.assign({}, state, {
        newTransformrulePredicateLeft: {
          ...state.newTransformrulePredicateLeft,
          idKey: action.payload.idKey,
          id: action.payload.id,
          shortname: action.payload.shortname,
          title: action.payload.title,
          type: action.payload.type,
          scopeOperations: action.payload.scopeOperations,
          operation: action.payload.operation,
          scopeValues: action.payload.scopeValues,
          value: action.payload.value,
        },
        // transformruleName:state.newTransformrulePredicateLeft.id
      });

      if(state.transformruleName===""){
        return Object.assign({}, tmp_state, {
          transformruleName:action.payload.id
        });
      } else if(state.transformruleName===state.newTransformrulePredicateRight.id) {
      return Object.assign({}, tmp_state, {
        transformruleName:action.payload.id +"-"+ state.transformruleName
      });
    } else return tmp_state;
  }
    case "ADD_TRANSFORMRULE_PREDICATE": {
      // return { ...state, posts: state.posts.concat([action.payload]) };
      // return { ...state, posts: [...state.posts, action.payload] };
      return Object.assign({}, state, {
        newTransformrulePredicateMas: [
          ...state.newTransformrulePredicateMas,
          action.payload,
        ],
      });
    }
    // case "ADD_TRANSFORMRULE_PREDICATE_RIGHT": {
    //   // return { ...state, posts: state.posts.concat([action.payload]) };
    //   // return { ...state, posts: [...state.posts, action.payload] };
    //   return Object.assign({}, state, {
    //     newTransformrulePredicateMasRight: [...state.newTransformrulePredicateMasRight, action.payload],
    //   });
    // }
    case "ADD_TRANSFORMRULE_PREDICATE_LEFT": {
      let stackLeft = [];
      let stackRight = state.newTransformrulePredicateMasRight;
      switch (action.payload.type.toUpperCase()) {
        case "CHECKBOX":
        case "SELECT":
        case "RADIO":
          stackLeft = [
            ...state.newTransformrulePredicateMasLeft,
            action.payload,
          ].concat(
            action.payload.scopeValues.map((el, index) => {
              if (el !== "") {
                return {
                  id: action.payload.id,
                  idKey: Date.now().toString() + index.toString(),
                  type: el,
                  title: action.payload.title,
                  operation: action.payload.operation,
                  scopeOperations: action.payload.scopeOperations,
                  scopeValues: action.payload.scopeValues,
                  shortname: action.payload.shortname,
                  value: action.payload.value,
                };
              }
            })
          );
          console.log("stackLeft=",stackLeft);
          if (state.newTransformrulePredicateMasRight.length > 0) {
            let firstEl = state.newTransformrulePredicateMasRight[0];
            console.log("firstEl=", firstEl);
            // stackRight.push(firstEl);
            stackRight = [...stackRight].concat(
              action.payload.scopeValues.map((el, index) => {
                if (el !== "") {
                  return {
                    id: state.newTransformrulePredicateMasRight[0].id,
                    idKey: Date.now().toString() + index.toString(),
                    type: el,
                    title: state.newTransformrulePredicateMasRight[0].title,
                    operation:
                      state.newTransformrulePredicateMasRight[0].operation,
                    scopeOperations:
                      state.newTransformrulePredicateMasRight[0]
                        .scopeOperations,
                    scopeValues:
                      state.newTransformrulePredicateMasRight[0].scopeValues,
                    shortname:
                      state.newTransformrulePredicateMasRight[0].shortname,
                    value: state.newTransformrulePredicateMasRight[0].value,
                  };
                }
                // }
              })
            );
          } else {
            stackRight = [...state.newTransformrulePredicateMasRight];
          }
          break;
        default:
          stackLeft = [
            ...state.newTransformrulePredicateMasLeft,
            action.payload,
          ];
          break;
      }
      stackLeft = stackLeft.filter((el) => el !== undefined);
      stackRight = stackRight.filter((el) => el !== undefined);

      // console.log("Left stackRight=", stackRight);
      // console.log("Left stackLeft=", stackLeft);
      let tRuleTable = [];
      if (stackLeft.length >= stackRight.length) {
        for (var i = 0; i < stackLeft.length; i++) {
          tRuleTable.push({
            idKeyL: stackLeft[i].idKey,
            idL: stackLeft[i].id,
            shortnameL: stackLeft[i].shortname,
            titleL: stackLeft[i].title,
            typeL: stackLeft[i].type,
            scopeOperationsL: stackLeft[i].scopeOperations,
            operationL: stackLeft[i].operation,
            scopeValuesL: stackLeft[i].scopeValues,
            valueL: stackLeft[i].value,
            idKeyR:
              stackRight[i]?.idKey === undefined ? "" : stackRight[i].idKey,
            idR: stackRight[i]?.id === undefined ? "" : stackRight[i].id,
            shortnameR:
              stackRight[i]?.shortname === undefined
                ? ""
                : stackRight[i].shortname,
            titleR:
              stackRight[i]?.shortname === undefined ? "" : stackRight[i].title,
            typeR: stackRight[i]?.type === undefined ? "" : stackRight[i].type,
            scopeOperationsR:
              stackRight[i]?.scopeOperations === undefined
                ? []
                : stackRight[i].scopeOperations,
            operationR:
              stackRight[i]?.operation === undefined
                ? ""
                : stackRight[i].operation,
            scopeValuesR:
              stackRight[i]?.scopeValues === undefined
                ? []
                : stackRight[i].scopeValues,
            valueR:
              stackRight[i]?.value === undefined ? "" : stackRight[i].value,
          });
        }
      } else {
        for (var i = 0; i < stackRight.length; i++) {
          tRuleTable.push({
            idKeyL: stackLeft[i]?.idKey === undefined ? "" : stackLeft[i].idKey,
            idL: stackLeft[i]?.id === undefined ? "" : stackLeft[i].id,
            shortnameL:
              stackLeft[i]?.shortname === undefined
                ? ""
                : stackLeft[i].shortname,
            titleL:
              stackLeft[i]?.shortname === undefined ? "" : stackLeft[i].title,
            typeL: stackLeft[i]?.type === undefined ? "" : stackLeft[i].type,
            scopeOperationsL:
              stackLeft[i]?.scopeOperations === undefined
                ? []
                : stackLeft[i].scopeOperations,
            operationL:
              stackLeft[i]?.operation === undefined
                ? ""
                : stackLeft[i].operation,
            scopeValuesL:
              stackLeft[i]?.scopeValues === undefined
                ? []
                : stackLeft[i].scopeValues,
            valueL: stackLeft[i]?.value === undefined ? "" : stackLeft[i].value,
            idKeyR: stackRight[i].idKey,
            idR: stackRight[i].id,
            shortnameR: stackRight[i].shortname,
            titleR: stackRight[i].title,
            typeR: stackRight[i].type,
            scopeOperationsR: stackRight[i].scopeOperations,
            operationR: stackRight[i].operation,
            scopeValuesR: stackRight[i].scopeValues,
            valueR: stackRight[i].value,
          });
        }
      }
      // console.log("Left transformruleTable=", tRuleTable);
      return Object.assign({}, state, {
        newTransformrulePredicateMasLeft:
          stackLeft /*[...state.newTransformrulePredicateMasLeft, action.payload]*/,
        newTransformrulePredicateMasRight: stackRight,
        transformruleTable: tRuleTable,
      });
      // return Object.assign({}, state, {
      //   newTransformrulePredicateMasLeft: [...state.newTransformrulePredicateMasLeft, action.payload],
      // });
    }
    case "ADD_TRANSFORMRULE_PREDICATE_RIGHT": {
      // return { ...state, posts: state.posts.concat([action.payload]) };
      // return { ...state, posts: [...state.posts, action.payload] };
      let stackRight = [];
      let stackLeft = state.newTransformrulePredicateMasLeft;
      switch (state.newTransformrulePredicateMasLeft.length) {
        case 0:
          stackRight = [
            ...state.newTransformrulePredicateMasRight,
            action.payload,
          ]
          break;
        case 1:
          stackRight = [
            ...state.newTransformrulePredicateMasRight,
            action.payload,
          ]
          break;
        default:
          switch (action.payload.type.toUpperCase()) {
            case "CHECKBOX":
            case "SELECT":
            case "RADIO":
              stackRight = [
                ...state.newTransformrulePredicateMasRight,
                action.payload,
              ].concat(
                state.newTransformrulePredicateMasLeft[0].scopeValues.map(
                  (el, index) => {
                    if (el !== "") {
                      return {
                        id: action.payload.id,
                        idKey: Date.now().toString() + index.toString(),
                        // type: action.payload.scopeValues,
                        type: "",
                        title: action.payload.title,
                        operation: action.payload.operation,
                        scopeOperations: action.payload.scopeOperations,
                        shortname: action.payload.shortname,
                        value: action.payload.value,
                        scopeValues:action.payload.scopeValues
                      };
                    }
                  }
                )
              );
              break;
            default:
              stackRight = [
                ...state.newTransformrulePredicateMasRight,
                action.payload,
              ];
              break;
          }
      }
      stackRight = stackRight.filter((el) => el !== undefined);
      // console.log("Right stackRight=", stackRight);
      // console.log("Right stackLeft=", stackLeft);
      let tRuleTable = [];
      if (stackLeft.length >= stackRight.length) {
        for (var i = 0; i < stackLeft.length; i++) {
          tRuleTable.push({
            idKeyL: stackLeft[i].idKey,
            idL: stackLeft[i].id,
            shortnameL: stackLeft[i].shortname,
            titleL: stackLeft[i].title,
            typeL: stackLeft[i].type,
            scopeOperationsL: stackLeft[i].scopeOperations,
            operationL: stackLeft[i].operation,
            scopeValuesL: stackLeft[i].scopeValues,
            valueL: stackLeft[i].value,
            idKeyR:stackRight[i]?.idKey === undefined ? "" : stackRight[i].idKey,
            idR: stackRight[i]?.id === undefined ? "" : stackRight[i].id,
            shortnameR:stackRight[i]?.shortname === undefined? "": stackRight[i].shortname,
            titleR:stackRight[i]?.shortname === undefined ? "" : stackRight[i].title,
            typeR: stackRight[i]?.type === undefined ? "" : stackRight[i].type,
            scopeOperationsR:stackRight[i]?.scopeOperations === undefined? []: stackRight[i].scopeOperations,
            operationR: stackRight[i]?.operation === undefined ? "": stackRight[i].operation,
            scopeValuesR:stackRight[i]?.scopeValues === undefined? [] : stackRight[i].scopeValues,
            valueR:stackRight[i]?.value === undefined ? "" : stackRight[i].value,
          });
        }
      } else {
        for (var i = 0; i < stackRight.length; i++) {
          tRuleTable.push({
            idKeyL: stackLeft[i]?.idKey === undefined ? "" : stackLeft[i].idKey,
            idL: stackLeft[i]?.id === undefined ? "" : stackLeft[i].id,
            shortnameL: stackLeft[i]?.shortname === undefined? "": stackLeft[i].shortname,
            titleL: stackLeft[i]?.shortname === undefined ? "" : stackLeft[i].title,
            typeL: stackLeft[i]?.type === undefined ? "" : stackLeft[i].type,
            scopeOperationsL: stackLeft[i]?.scopeOperations === undefined ? []: stackLeft[i].scopeOperations,
            operationL:stackLeft[i]?.operation === undefined? "" : stackLeft[i].operation,
            scopeValuesL: stackLeft[i]?.scopeValues === undefined ? []: stackLeft[i].scopeValues,
            valueL: stackLeft[i]?.value === undefined ? "" : stackLeft[i].value,
            idKeyR: stackRight[i].idKey,
            idR: stackRight[i].id,
            shortnameR: stackRight[i].shortname,
            titleR: stackRight[i].title,
            typeR: stackRight[i].type,
            scopeOperationsR: stackRight[i].scopeOperations,
            operationR: stackRight[i].operation,
            scopeValuesR: stackRight[i].scopeValues,
            valueR: stackRight[i].value,
          });
        }
      }
      // console.log("Right transformruleTable=", tRuleTable);
      return Object.assign({}, state, {
        newTransformrulePredicateMasRight:
          stackRight /*[...state.newTransformrulePredicateMasLeft, action.payload]*/,
        transformruleTable: tRuleTable,
      });
    }
    case "SET_SCOPE_TRANSFORMRULE_TYPE_RIGHT": {
      return Object.assign({}, state, {
        newTransformrulePredicateMasRight:
          state.newTransformrulePredicateMasLeft.map((el) =>
            el.idKey === action.payload.idKeyR
              ? {
                  ...el,
                  type: action.payload.typeR,
                }
              : el
          ),
        transformruleTable: state.transformruleTable.map((el) =>
          el.idKeyR === action.payload.idKeyR
            ? {
                ...el,
                typeR: action.payload.typeR,
              }
            : el
        ),
      });
      // return Object.assign({}, state, {
      //   newTransformrulePredicateMasRight:
      //     state.newTransformrulePredicateMasRight.map((el) =>
      //       action.payload.idKey === el.idKey
      //         ? { ...el, type: action.payload.type }
      //         : el
      //     ),
      // });
    }
    case "SET_ARRAY_TRANSFORMRULE_PREDICATES": {
      return Object.assign({}, state, {
        transformruleTable: action.payload,
      });
    }
    case "SET_SCOPE_TRANSFORMRULEMAS_LEFT": {
      // console.log("action.payload=",action.payload);
      return Object.assign({}, state, {
        newTransformrulePredicateMasLeft:
          state.newTransformrulePredicateMasLeft.map((el) =>
            el.idKey === action.payload.idKeyL
              ? {
                  ...el,
                  operation: action.payload.operationL,
                }
              : el
          ),
        transformruleTable: state.transformruleTable.map((el) =>
          el.idKeyL === action.payload.idKeyL
            ? {
                ...el,
                operationL: action.payload.operationL,
              }
            : el
        ),
      });
    }
    case "SET_SCOPE_TRANSFORMRULEMAS_RIGHT": {
      return Object.assign({}, state, {
        newTransformrulePredicateMasRight:
          state.newTransformrulePredicateMasRight.map((el) =>
            el.idKey === action.payload.idKey
              ? {
                  ...el,
                  operation: action.payload.operation,
                }
              : el
          ),
      });
    }
    // case "SET_SCOPE_VALUES": {
    //   return Object.assign({}, state, {
    //     newTransformrulePredicateMas: state.newTransformrulePredicateMas.map((el) =>
    //       el.idKey === action.payload.idKey
    //         ? {
    //             ...el,
    //             value: action.payload.value,
    //           }
    //         : el
    //     ),
    //   });
    // }
    case "SET_TRANSFORMRULEMAS_LEFT": {
      return Object.assign({}, state, { newTransformrulePredicateMasLeft: action.payload });
    }
    case "SET_TRANSFORMRULEMAS_RIGHT": {
      return Object.assign({}, state, { newTransformrulePredicateMasRight: action.payload });
    }
    case "CLEAR_TRANSFORMRULE_PREDICATE": {
      return Object.assign({}, state, {
        newTransformrulePredicateMas: [],
        transformruleResult: "",
        transformruleName: "",
        transformruleResultPos: 0,
        transformrulePlaceholder: "",
        newTransformrule: {
          id: 0,
          CompanyId: 0,
          UserId: 0,
          created: "",
          updated: "",
          rule_window: "",
          rule_body: "",
          result: "",
          rulename: "",
          subs: 1,
          mh: 1,
          cooc: 1,
          adult_type: "",
          priority: 1,
        },
        assessmentTypeFilter: {
          name: "Assessment Type :",
          prevalue: "Assessment Type :",
          disabled: "false",
          type: "checkbox",
          value: [true, true, true],
          radio_options: ["SUBS", "MH", "COOC"],
        },
      });
    }
    case "CLEAR_TRANSFORMRULE_DIALOG": {
      return Object.assign({}, state, {
        newTransformrulePredicateMas: [],
        transformruleResult: "",
        transformruleName: "",
        transformruleResultPos: 0,
        transformrulePlaceholder: "",
        newTransformrule: {
          ...state.newTransformrule,
          updated: "",
          rule_window: "",
          rule_body: "",
          result: "",
          rulename: "",
          subs: 1,
          mh: 1,
          cooc: 1,
          adult_type: "Adult",
        },
        assessmentTypeFilter: {
          name: "Assessment Type :",
          prevalue: "Assessment Type :",
          disabled: "false",
          type: "checkbox",
          value: [true, true, true],
          radio_options: ["SUBS", "MH", "COOC"],
        },
      });
    }
    case "CLEAR_TRANSFORMRULE_DIALOG_LEFT":
      {
        // console.log("transformruleTable=",state.transformruleTable)
        /*const tmp_state=*/ return Object.assign({}, state, {
          newTransformrulePredicateMasLeft: [],
          newTransformrulePredicateMasRight: [],
          transformruleTable: [],
          transformruleResult: "",
          // transformruleName: "",
          transformruleResultPos: 0,
          transformrulePlaceholder: "",
          newTransformrule: {
            ...state.newTransformrule,
            updated: "",
            rule_window: "",
            rule_body: "",
            result: "",
            rulename: "",
            subs: 1,
            mh: 1,
            cooc: 1,
            adult_type: "Adult",
          },
          assessmentTypeFilter: {
            name: "Assessment Type :",
            prevalue: "Assessment Type :",
            disabled: "false",
            type: "checkbox",
            value: [true, true, true],
            radio_options: ["SUBS", "MH", "COOC"],
          },
        });
      }

    case "CLEAR_TRANSFORMRULE_DIALOG_RIGHT": {
      return Object.assign({}, state, {
        newTransformrulePredicateMasLeft: [],
        newTransformrulePredicateMasRight: [],
        transformruleTable: [],
        transformruleResult: "",
        // transformruleName: "",
        transformruleResultPos: 0,
        transformrulePlaceholder: "",
        newTransformrule: {
          ...state.newTransformrule,
          updated: "",
          rule_window: "",
          rule_body: "",
          result: "",
          rulename: "",
          subs: 1,
          mh: 1,
          cooc: 1,
          adult_type: "Adult",
        },
        assessmentTypeFilter: {
          name: "Assessment Type :",
          prevalue: "Assessment Type :",
          disabled: "false",
          type: "checkbox",
          value: [true, true, true],
          radio_options: ["SUBS", "MH", "COOC"],
        },
      });
    }

    case "LOAD_TRANSFORMRULES1":
      // console.log(" action.payload=", action.payload);
      let tmpObj = action.payload.data.map((el) => {
        return {
          ...el,
          checked: action.payload.transformruleCheckedId.includes(el.id)
            ? true
            : false,
        };
      });
      return (tmpObj = Object.assign({}, state, {
        transformruleListArr: tmpObj,
      }));

    case "LOAD_TRANSFORMRULES_ALL_COUNT": {
      return Object.assign({}, state, { totalTransformrules: action.payload });
    }
    case "LOAD_PAGE_TRANSFORMRULE_COUNT": {
      return Object.assign({}, state, {
        pagesCountTransformrule: action.payload,
      });
    }
    case "SET_CURRENT_TRANSFORMRULE_PAGE": {
      return Object.assign({}, state, {
        currentPageTransformrule: action.payload,
      });
    }
    case "INIT_TRANSFORMRULE_PAGINATION": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesTransformrule,
        state.pagesCountTransformrule
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i;
      });
      return Object.assign({}, state, { visPagesTransformrule: arr });
    }

    case "SET_VIS_TRANSFORMRULE_PAGE": {
      let arr = [];
      const diapazonPages = Math.min(
        state.diapazonPagesTransformrule,
        state.pagesCountTransformrule
      );
      const minVis = Math.max(
        0,
        Math.min(
          action.payload - diapazonPages + 2,
          state.pagesCountTransformrule - diapazonPages
        )
      );
      arr = [...Array(diapazonPages)].map((page, i) => {
        return i + minVis;
      });
      return Object.assign({}, state, { visPagesTransformrule: arr });
    }

    case "OPEN_NEW_TRANSFORMRULE_DIALOG":
      if (action.payload === false) {
        const tmp = state.controlDialog.slice(
          0,
          state.controlDialog.length - 1
        ); //remove last element
        // console.log("tmp=",tmp)
        return Object.assign({}, state, {
          dialogOpenTransformrule: action.payload,
          controlDialog: tmp,
        });
      } else {
        return Object.assign({}, state, {
          dialogOpenTransformrule: action.payload,
        });
      }

    case "NEED_REFRESH_TRANSFORMRULE_LIST": {
      return Object.assign({}, state, {
        needRefresTransformruleList: !state.needRefresTransformruleList,
      });
    }
    case "TAKE_ITEM_TRANSFORMRULE":
      return Object.assign({}, state, {
        newTransformrule: {
          ...state.newTransformrule,
          id: action.payload.id,
          CompanyId: action.payload.CompanyId,
          UserId: action.payload.UserId,
          created: action.payload.created,
          updated: action.payload.updated,
          rule_window: action.payload.rule_window,
          rule_body: action.payload.rule_body,
          result: action.payload.result,
          rulename: action.payload.rulename,
          subs: action.payload.subs,
          mh: action.payload.mh,
          cooc: action.payload.cooc,
          adult_type: action.payload.adult_type,
          priority: action.payload.priority,
        },
      });
    case "SET_CONTROL_STATUS_TRANSFORMRULE_DIALOG": {
      const stage =
        action.payload === "Testing" &&
        state.controlDialog[state.controlDialog.length - 1] === "New"
          ? [
              ...state.controlDialog.slice(0, state.controlDialog.length - 1),
              "Update",
              action.payload,
            ]
          : [...state.controlDialog, action.payload];
      return Object.assign({}, state, {
        controlDialog: stage,
      });
    }

    case "SET_TRANSFORMRULE_PLACEHOLDER_SECTION": {
      return Object.assign({}, state, {
        transformrulePlaceholder: action.payload,
      });
    }
    case "SET_PLACEHOLDER_TO_RESULT": {
      return Object.assign({}, state, { transformruleResult: action.payload });
    }

    case "TYPEOFASSESSMENT_FILTER_CHECKBOX_CHANGE": {
      objectTmp = state.assessmentTypeFilter;
      valueTmp = objectTmp.value;

      if (valueTmp[action.payload] === true) valueTmp[action.payload] = false;
      else valueTmp[action.payload] = true;
      valueTmp.map((el, index) => {
        if (el === true) return objectTmp.radio_options[index];
        else return null;
      });

      let tempArr = [];
      objectTmp.value.forEach((el, index) => {
        if (el === true) tempArr.push(objectTmp.radio_options[index]);
      });

      // console.log("tempArr=",tempArr)
      return Object.assign({}, state, {
        assessmentTypeFilter: {
          ...state.assessmentTypeFilter,
          value: valueTmp.map((el) => el),
        },
        newTransformrule: {
          ...state.newTransformrule,
          subs: tempArr.includes("SUBS") ? true : false,
          mh: tempArr.includes("MH") ? true : false,
          cooc: tempArr.includes("COOC") ? true : false,
        },
      });
    }
    case "SET_CHECK_FOR_MOVE_TRANSFORMRULE": {
      const tmpObj = Object.assign({}, state, {
        transformruleCheckedId: state.transformruleListArr.find(
          (el) => el.id === action.payload.id
        ).checked
          ? state.transformruleCheckedId.filter(
              (el) => el !== action.payload.id
            )
          : [...state.transformruleCheckedId, action.payload.id],
        transformruleCheckedIdName: state.transformruleListArr.find(
          (el) => el.id === action.payload.id
        ).checked
          ? state.transformruleCheckedIdName.filter(
              (el) => el !== action.payload.transformruleheckedIdName
            )
          : [
              ...state.transformruleCheckedIdName,
              {
                rulename: action.payload.rulename,
                priority: action.payload.priority,
                page: action.payload.page,
              },
            ],
      });
      return tmpObj;
    }
    case "UPDATE_CHECKED_TRANSFORMRULE_LIST": {
      const tmpObj = Object.assign({}, state, {
        transformruleListArr: state.transformruleListArr.map((el1) => {
          if (action.payload.includes(el1.id)) {
            return {
              ...el1,
              checked: true,
            };
          } else {
            return {
              ...el1,
              checked: false,
            };
          }
        }),
      });
      return tmpObj;
    }
    case "CLEAR_CHECKED_TRANSFORMRULE_LIST": {
      const tmpObj = Object.assign({}, state, {
        transformruleCheckedId: [],
        transformruleCheckedIdName: [],
      });
      return tmpObj;
    }
    case "ENABLE_MOVE_TRANSFORMRULE": {
      const tmpObj = Object.assign({}, state, {
        enableMoveTransformrule: action.payload,
      });
      return tmpObj;
    }
    case "UNDO_SELECT_TRANSFORMRULE": {
      const tmpObj = Object.assign({}, state, {
        transformruleCheckedId: [],
        transformruleCheckedIdName: [],
      });
      return tmpObj;
    }
    case "LOAD_MYASSESSMENT_TO_TRANSFORMRULE": {
      return Object.assign({}, state, { myAssessmentArr: action.payload });
    }
    case "SET_TEST_TRANSFORMRULE_ASSESSMENT": {
      return Object.assign({}, state, {
        transformruleAssessment: action.payload,
      });
    }
    case "INIT_TRANSFORMRULE_ASSESSMENT": {
      return Object.assign({}, state, { transformruleAssessment: "" });
    }
    case "SET_EVENT_TRANSFORMRULE": {
      return Object.assign({}, state, { eventTransformrule: action.payload });
    }
    case "MAKE_TRANSFORMRULE_ANIMATION":
      return Object.assign({}, state, { makeAnimationStatus: action.payload });
    case "NEED_REFRESH_QUILL_POSITION": {
      return Object.assign({}, state, {
        needRefresQuillPosition: !state.needRefresQuillPosition,
      });
    }
    case "SET_ACTIVE_TRANSFORMRULE_TAB": {
      return Object.assign({}, state, {
        transformrulepromoActiveTab: action.payload,
      });
    }
    case "SET_TRANSFORMRULE_ADULT_TYPE":
      return Object.assign({}, state, {
        newTransformrule: {
          ...state.newTransformrule,
          adult_type: action.payload,
        },
      });
    case "SET_TRANSFORMRULE_ASSESSMENT_JSON":
      // console.log("action.payload=",action.payload);
      if (action.payload === "Adolescent") {
        // console.log("adolescentPattern=",adolescentPattern);
        const tmp = Object.assign([], {
          ...adolescentPattern.map((el) =>
            el.topic !== undefined
              ? {
                  ...el,
                  body: el.body
                    .map((el1) => {
                      switch (el1.shortname) {
                        case "startblockalcohol":
                          return [el1].concat(blockAlcohol);
                        case "startblockcannabis":
                          return [el1].concat(blockCannabis);
                        case "startblockcocaine":
                          return [el1].concat(blockCocaine);
                        case "startblockcrack":
                          return [el1].concat(blockCrack);
                        case "startblockcrank":
                          return [el1].concat(blockCrank);
                        case "startblockecstasy":
                          return [el1].concat(blockEcstasy);
                        case "startblockhallucinogens":
                          return [el1].concat(blockHallucinogens);
                        case "startblockheroin":
                          return [el1].concat(blockHeroin);
                        case "startblockinhalants":
                          return [el1].concat(blockInhalants);
                        case "startblockmeth":
                          return [el1].concat(blockMeth);
                        case "startblockmethadone":
                          return [el1].concat(blockMethadone);
                        case "startblockopiates":
                          return [el1].concat(blockOpiates);
                        case "startblocksedative":
                          return [el1].concat(blockSedative);
                        case "startblocktobacco":
                          return [el1].concat(blockTobacco);
                        case "startblockother":
                          return [el1].concat(blockOther);
                        default:
                          return el1;
                      }
                    })
                    .flat(),
                }
              : el
          ),
        });
        return Object.assign({}, state, { transformruleAssessmentJson: tmp });
      } else {
        if (action.payload === "Adult") {
          const tmp = Object.assign([], {
            ...adultPattern.map((el) =>
              el.topic !== undefined
                ? {
                    ...el,
                    body: el.body
                      .map((el1) => {
                        switch (el1.shortname) {
                          case "startblockalcohol":
                            return [el1].concat(blockAlcohol);
                          case "startblockcannabis":
                            return [el1].concat(blockCannabis);
                          case "startblockcocaine":
                            return [el1].concat(blockCocaine);
                          case "startblockcrack":
                            return [el1].concat(blockCrack);
                          case "startblockcrank":
                            return [el1].concat(blockCrank);
                          case "startblockecstasy":
                            return [el1].concat(blockEcstasy);
                          case "startblockhallucinogens":
                            return [el1].concat(blockHallucinogens);
                          case "startblockheroin":
                            return [el1].concat(blockHeroin);
                          case "startblockinhalants":
                            return [el1].concat(blockInhalants);
                          case "startblockmeth":
                            return [el1].concat(blockMeth);
                          case "startblockmethadone":
                            return [el1].concat(blockMethadone);
                          case "startblockopiates":
                            return [el1].concat(blockOpiates);
                          case "startblocksedative":
                            return [el1].concat(blockSedative);
                          case "startblocktobacco":
                            return [el1].concat(blockTobacco);
                          case "startblockother":
                            return [el1].concat(blockOther);
                          default:
                            return el1;
                        }
                      })
                      .flat(),
                  }
                : el
            ),
          });
          return Object.assign({}, state, { transformruleAssessmentJson: tmp });
          // return Object.assign({}, state, { transformruleAssessmentJson: adultPattern });
        } else {
          const tmp = Object.assign([], {
            ...adultPattern.map((el) =>
              el.topic !== undefined
                ? {
                    ...el,
                    body: el.body
                      .map((el1) => {
                        switch (el1.shortname) {
                          case "startblockalcohol":
                            return [el1].concat(blockAlcohol);
                          case "startblockcannabis":
                            return [el1].concat(blockCannabis);
                          case "startblockcocaine":
                            return [el1].concat(blockCocaine);
                          case "startblockcrack":
                            return [el1].concat(blockCrack);
                          case "startblockcrank":
                            return [el1].concat(blockCrank);
                          case "startblockecstasy":
                            return [el1].concat(blockEcstasy);
                          case "startblockhallucinogens":
                            return [el1].concat(blockHallucinogens);
                          case "startblockheroin":
                            return [el1].concat(blockHeroin);
                          case "startblockinhalants":
                            return [el1].concat(blockInhalants);
                          case "startblockmeth":
                            return [el1].concat(blockMeth);
                          case "startblockmethadone":
                            return [el1].concat(blockMethadone);
                          case "startblockopiates":
                            return [el1].concat(blockOpiates);
                          case "startblocksedative":
                            return [el1].concat(blockSedative);
                          case "startblocktobacco":
                            return [el1].concat(blockTobacco);
                          case "startblockother":
                            return [el1].concat(blockOther);
                          default:
                            return el1;
                        }
                      })
                      .flat(),
                  }
                : el
            ),
          });
          return Object.assign({}, state, { transformruleAssessmentJson: tmp });
        }
      }
    // case "SET_ADULT_TYPE"  :
    // if(action.payload==="Recommendations"){
    //   return Object.assign({}, state, { transformruleAdultType: "All"});
    // } else {
    //   if(action.payload==="Recommendations Adolescent"){
    //     return Object.assign({}, state, { transformruleAdultType: "Adolescent"});
    //   } else {
    //     return Object.assign({}, state, { transformruleAdultType: "Adult"});
    //   }
    // }
    case "SEARCH_TRANSFORMRULE_ADULT_TYPE":
      return Object.assign({}, state, {
        transformruleAdultType: action.payload,
      });
    default:
      return state;
  }
};

export default transformrulereducer;
