const behavioralHistoryJSON = {
  section: "PS",
  topic: "Behavioral Health History",
  checked: "true",
  // access: "public",
  color: "secondary",
  description: "",
  body: [
    {
      id: "",
      shortname: "ps-new0",
      name:
        "How many times have you been treated for any psychological or mental health problem ",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "PS-NEW0-SUBTOPIC",
      depending: "",
      exception: "",
    },
    {
      id: "BH.1",
      shortname: "ps1",
      name: "",
      prevalue: "How many times?",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
      ],
      act: "PS1",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "PS-NEW0-SUBTOPIC",
      suptopic_code: "PS-NEW0_HOSPITAL",
      subtopic_name: "Hospital/Residential/Inpatient Setting",
    },
    {
      id: "BH.1.1",
      shortname: "ps1-new2",
      name: "",
      prevalue:
        "If once or more, when was the most recent hospitalization and for what reason?",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS1-NEW2",
      depending: "PS1",
      exception: "0",
      subtopic_container_act: "PS-NEW0-SUBTOPIC",
      suptopic_code: "PS-NEW0_HOSPITAL",
      subtopic_name: "Hospital/Residential/Inpatient Setting",
    },
    {
      id: "BH.2",
      shortname: "ps2",
      name: "",
      prevalue: "How many times?",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
      ],
      act: "PS2",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "PS-NEW0-SUBTOPIC",
      suptopic_code: "PS-NEW0_OUTPATIENT",
      subtopic_name: "Outpatient/Private Patient Setting",
    },
    {
      id: "BH.2.1",
      shortname: "ps1-new4",
      name: "",
      prevalue:
        "If once or more, who is your current or most recent providers (medication/therapist provider)?",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS1-NEW4",
      depending: "PS2",
      exception: "0",
      subtopic_container_act: "PS-NEW0-SUBTOPIC",
      suptopic_code: "PS-NEW0_OUTPATIENT",
      subtopic_name: "Outpatient/Private Patient Setting",
    },
    {
      id: "BH.3",
      shortname: "ps3",
      name:
        "Do you receive any disability payments for a mental health problem?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS3",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "PS1-NEW6",
    },
    {
      id: "BH.3.3",
      shortname: "ps1-new6",
      name: "",
      prevalue:
        "If yes, what is the reason for the disability and what is the amount you receive?",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS1-NEW6",
      depending: "PS3",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "",
      shortname: "fr23-subtopic",
      name:
        "Have you experienced any of the following symptoms? All of the items below have been defaulted to NO. If your answer is YES, please change NO to YES. ",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "FR23-SUBTOPIC",
      depending: "",
      exception: "",
    },

    {
      id: "BH.4",
      shortname: "ps5",
      name: "serious anxiety",
      prevalue: "In the past 30 days?",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS5",
      depending: "",
      exception: "",
      link: "PS5-YES",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23-ANXIETY",
      subtopic_name: "Serious Anxiety",
    },
    {
      id: "BH.4.1",
      extend: "true",
      shortname: "ps5-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS5-YES",
      depending: "PS5",
      exception: "Yes",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23-ANXIETY",
      subtopic_name: "Serious Anxiety",
      link: "PS5-1",
    },

    {
      id: "BH.5",
      extend: "true",
      shortname: "ps5-1",
      name: "",
      prevalue: "In your lifetime?",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS5-1",
      depending: "",
      exception: "",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23-ANXIETY",
      subtopic_name: "Serious Anxiety",
      link: "PS5-1-YES",
    },
    {
      id: "BH.5.1",
      extend: "true",
      shortname: "ps5-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS5-1-YES",
      depending: "PS5-1",
      exception: "Yes",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23-ANXIETY",
      subtopic_name: "Serious Anxiety",
    },
  //-------------------------------------
    {
      id: "BH.6",
      shortname: "ps4",
      name: "serious depression",
      prevalue: "In the past 30 days?",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS4",
      depending: "",
      exception: "",
      link: "PS4-YES",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_DEPRESSION",
      subtopic_name: "Serious Depression",
    },
    {
      id: "BH.6.1",
      extend: "true",
      shortname: "ps4-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS4-YES",
      depending: "PS4",
      exception: "Yes",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_DEPRESSION",
      subtopic_name: "Serious Depression",
      link: "PS4-1",
    },
    {
      id: "BH.7",
      extend: "true",
      shortname: "ps4-1",
      name: "",
      prevalue: "In your lifetime",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS4-1",
      depending: "",
      exception: "",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_DEPRESSION",
      subtopic_name: "Serious Depression",
      link: "PS4-1-YES",
    },
    {
      id: "BH.7.1",
      extend: "true",
      shortname: "ps4-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS4-1-YES",
      depending: "PS4-1",
      exception: "Yes",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_DEPRESSION",
      subtopic_name: "Serious Depression",
    },
  //-------------------------------------
    {
      id: "BH.8",
      shortname: "ps11",
      name: "attempted suicide",
      prevalue: "In the past 30 days?",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS11",
      depending: "",
      exception: "",
      link: "PS11-YES",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_SUICIDE",
      subtopic_name: "Attempted Suicide",
    },
    {
      id: "BH.8.1",
      extend: "true",
      shortname: "ps11-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS11-YES",
      depending: "PS11",
      exception: "Yes",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_SUICIDE",
      subtopic_name: "Attempted Suicide",
      link: "PS11-1",
    },
    {
      id: "BH.9",
      extend: "true",
      shortname: "ps11-1",
      name: "",
      prevalue: "In your lifetime?",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS11-1",
      depending: "",
      exception: "",
      link: "PS11-1-YES",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_SUICIDE",
      subtopic_name: "Attempted Suicide",
    },
    {
      id: "BH.9.1",
      extend: "true",
      shortname: "ps11-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS11-1-YES",
      depending: "PS11-1",
      exception: "Yes",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_SUICIDE",
      subtopic_name: "Attempted Suicide",
    },
    //-------------------------------------
    {
      id: "BH.10",
      shortname: "ps10",
      name: "serious thoughts of suicide",
      prevalue: "In the past 30 days?",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS10",
      depending: "",
      exception: "",
      link: "PS10-YES",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_SUICIDETHOGUHTS",
      subtopic_name: "Serious thoughts of suicide",
    },
    {
      id: "BH.10.1",
      extend: "true",
      shortname: "ps10-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS10-YES",
      depending: "PS10",
      exception: "Yes",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_SUICIDETHOGUHTS",
      subtopic_name: "Serious thoughts of suicide",
      link: "PS10-1",
    },
    {
      id: "BH.11",
      extend: "true",
      shortname: "ps10-1",
      name: "",
      prevalue: "In your lifetime?",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS10-1",
      depending: "",
      exception: "",
      link: "PS10-1-YES",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_SUICIDETHOGUHTS",
      subtopic_name: "Serious thoughts of suicide",
    },
    {
      id: "BH.11.1",
      extend: "true",
      shortname: "ps10-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS10-1-YES",
      depending: "PS10-1",
      exception: "Yes",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_SUICIDETHOGUHTS",
      subtopic_name: "Serious thoughts of suicide",
    },
    //-------------------------------------
      {
        id: "BH.12",
        shortname: "ps6",
        name: "eating disorder such as anorexia, bulimia or over eating",
        prevalue: "In the past 30 days?",
        value: "No",
        disabled: "false",
        type: "radio",
        radio_options: ["Yes", "No"],
        act: "PS6",
        depending: "",
        exception: "",
        link: "PS6-YES",
        subtopic_container_act: "FR23-SUBTOPIC",
        suptopic_code: "FR23_ED",
        subtopic_name: "Eating disorder such as anorexia, bulimia or over eating",
      },
      {
        id: "BH.12.1",
        extend: "true",
        shortname: "ps6-yes",
        name: "",
        prevalue: "If yes, please explain",
        value: "",
        disabled: "true",
        type: "text",
        act: "PS6-YES",
        depending: "PS6",
        exception: "Yes",
        link: "PS6-1",
        subtopic_container_act: "FR23-SUBTOPIC",
        suptopic_code: "FR23_ED",
        subtopic_name: "Eating disorder such as anorexia, bulimia or over eating",
      },
      {
        id: "BH.13",
        extend: "true",
        shortname: "ps6-1",
        name: "",
        prevalue: "In your lifetime?",
        value: "No",
        disabled: "false",
        type: "radio",
        radio_options: ["Yes", "No"],
        act: "PS6-1",
        depending: "",
        exception: "",
        link: "PS6-1-YES",
        subtopic_container_act: "FR23-SUBTOPIC",
        suptopic_code: "FR23_ED",
        subtopic_name: "Eating disorder such as anorexia, bulimia or over eating",
      },
      {
        id: "BH.13.1",
        extend: "true",
        shortname: "ps6-1-yes",
        name: "",
        prevalue: "If yes, please explain",
        value: "",
        disabled: "true",
        type: "text",
        act: "PS6-1-YES",
        depending: "PS6-1",
        exception: "Yes",
        subtopic_container_act: "FR23-SUBTOPIC",
        suptopic_code: "FR23_ED",
        subtopic_name: "Eating disorder such as anorexia, bulimia or over eating",
      },
    //-------------------------------------
    {
      id: "BH.14",
      shortname: "ps9",
      name: "cut on yourself or did other self-injurious behaviors",
      prevalue: "In the past 30 days?",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS9",
      depending: "",
      exception: "",
      link: "PS9-YES",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_CUT",
      subtopic_name: "Cut on yourself or did other self-injurious behaviors",
    },
    {
      id: "BH.14.1",
      extend: "true",
      shortname: "ps9-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS9-YES",
      depending: "PS9",
      exception: "Yes",
      link: "PS9-1",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_CUT",
      subtopic_name: "Cut on yourself or did other self-injurious behaviors",
    },
    {
      id: "BH.15",
      extend: "true",
      shortname: "ps9-1",
      name: "",
      prevalue: "In your lifetime?",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS9-1",
      depending: "",
      exception: "",
      link: "PS9-1-YES",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_CUT",
      subtopic_name: "Cut on yourself or did other self-injurious behaviors",
    },
    {
      id: "BH.15.1",
      extend: "true",
      shortname: "ps9-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS9-1-YES",
      depending: "PS9-1",
      exception: "Yes",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_CUT",
      subtopic_name: "Cut on yourself or did other self-injurious behaviors",
    },
    //-------------------------------------
    {
      id: "BH.16",
      shortname: "ps8",
      name: "serious loss of temper, rage, or violence",
      prevalue: "In the past 30 days?",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS8",
      depending: "",
      exception: "",
      link: "PS8-YES",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_TEMPER",
      subtopic_name: "serious loss of temper, rage, or violence",
    },
    {
      id: "BH.16.1",
      extend: "true",
      shortname: "ps8-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS8-YES",
      depending: "PS8",
      exception: "Yes",
      link: "PS8-1",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_TEMPER",
      subtopic_name: "serious loss of temper, rage, or violence",
    },
    {
      id: "BH.17",
      extend: "true",
      shortname: "ps8-1",
      name: "",
      prevalue: "In your lifetime?",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS8-1",
      depending: "",
      exception: "",
      link: "PS8-1-YES",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_TEMPER",
      subtopic_name: "serious loss of temper, rage, or violence",
    },
    {
      id: "BH.17.1",
      extend: "true",
      shortname: "ps8-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS8-1-YES",
      depending: "PS8-1",
      exception: "Yes",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_TEMPER",
      subtopic_name: "serious loss of temper, rage, or violence",
    },
  //-------------------------------------
    {
      id: "BH.18",
      shortname: "ps12",
      name: "trouble concentrating, understanding or remembering things (ADHD)",
      prevalue: "In the past 30 days?",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS12",
      depending: "",
      exception: "",
      link: "PS12-YES",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_ADD",
      subtopic_name:
        "Trouble concentrating, understanding or remembering things (ADHD)",
    },
    {
      id: "BH.18.1",
      extend: "true",
      shortname: "ps12-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS12-YES",
      depending: "PS12",
      exception: "Yes",
      link: "PS12-1",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_ADD",
      subtopic_name: "Trouble concentrating, understanding or remembering things (ADHD)",
    },
    {
      id: "BH.19",
      extend: "true",
      shortname: "ps12-1",
      name: "",
      prevalue: "In your lifetime?",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS12-1",
      depending: "",
      exception: "",
      link: "PS12-1-YES",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_ADD",
      subtopic_name:
        "Trouble concentrating, understanding or remembering things (ADHD)",
    },
    {
      id: "BH.19.1",
      extend: "true",
      shortname: "ps12-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS12-1-YES",
      depending: "PS12-1",
      exception: "Yes",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_ADD",
      subtopic_name: "Trouble concentrating, understanding or remembering things (ADHD)",
    },

    //-------------------------------------
    {
      id: "BH.20",
      shortname: "ps7",
      name: "auditory or visual hallucinations",
      prevalue: "In the past 30 days?",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS7",
      depending: "",
      exception: "",
      link: "PS7-YES",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_HALL",
      subtopic_name: "Auditory or visual hallucinations",
    },
    {
      id: "BH.20.1",
      extend: "true",
      shortname: "ps7-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS7-YES",
      depending: "PS7",
      exception: "Yes",
      link: "PS7-1",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_HALL",
      subtopic_name: "Auditory or visual hallucinations",
    },
    {
      id: "BH.21",
      extend: "true",
      shortname: "ps7-1",
      name: "",
      prevalue: "In your lifetime?",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS7-1",
      depending: "",
      exception: "",
      link: "PS7-1-YES",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_HALL",
      subtopic_name: "Auditory or visual hallucinations",
    },
    {
      id: "BH.21.1",
      extend: "true",
      shortname: "ps7-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS7-1-YES",
      depending: "PS7-1",
      exception: "Yes",
      subtopic_container_act: "FR23-SUBTOPIC",
      suptopic_code: "FR23_HALL",
      subtopic_name: "Auditory or visual hallucinations",
    },
    {
      id: "BH.22",
      shortname: "pss12",
      name: "How many days in the past 30 have you experienced these psychological or emotional problems?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "PSS12",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "BH.22",
      shortname: "ps15",
      name:
        "Have you EVER been diagnosed with any mental health or psychological condition(s)? ",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS15",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "PS15-17",
    },
    {
      id: "BH.22.1",
      extend: "true",
      shortname: "ps15-17",
      name: "",
      prevalue:
        "If yes, what was the diagnosis? List current diagnoses and history of previous diagnoses.",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS15-17",
      depending: "PS15",
      exception: "Yes",
    },
    {
      id: "BH.23",
      shortname: "ps13",
      name:
        "Have you EVER been prescribed any medication for mental health or psychological condition(s)?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS13",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "PS13-19",
    },
    {
      id: "BH.23.1",
      extend: "true",
      shortname: "ps13-19",
      name: "",
      prevalue:
        "If yes, what medications have been prescribed? Indicate current prescription and history of other medications.",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS13-19",
      depending: "PS13",
      exception: "Yes",
    },
    // {
    //   id: "BH.24",
    //   shortname: "ps16",
    //   name:
    //     "How worried or bothered have you been about any mental health or psychological condition in the past 30 days?",
    //   prevalue: "",
    //   value: "",
    //   disabled: "false",
    //   type: "select",
    //   ruletype: "string",
    //   select_options: [
    //     "",
    //     "not at all",
    //     "a little bit",
    //     "a lot",
    //   ],
    //   act: "PS16",
    //   mandatory: "true",
    //   depending: "",
    //   exception: "",
    //   link: "PS16-1",
    // },
    {
      id: "BH.24",
      shortname: "ps16",
      name:
        "How worried or bothered have you been about any mental health or psychological condition in the past 30 days?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "slightly",
        "moderately",
        "considerably",
        "extremely",
      ],
      act: "PS16",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "PS16-1",
    },
    {
      id: "BH.24.1",
      shortname: "ps16-1",
      name: "",
      prevalue: "Please explain the reason you are worried or bothered?",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS16-1",
      depending: "PS16",
      exception: "not at all",
      extend: "true",
    },
    {
      id: "BH.25",
      shortname: "ps17",
      name:
        "How important is it for you to receive additional counseling or treatment for any mental health or psychological concerns?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "slightly",
        "moderately",
        "considerably",
        "extremely",
      ],
      act: "PS17",
      depending: "",
      exception: "",
    },
    {
      id: "BH.26.c",
      shortname: "ps18c",
      access: "private",
      name: "Additional comments:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "editor",
      act: "PS18C",
      depending: "",
      exception: "",
    },
    {
      id: "BH.26.d",
      shortname: "ps18d",
      access: "private",
      name: "INTERVIEWER SEVERITY RATING",
      prevalue: "How would you rate the patient’s need for psychiatric/ psychological treatment?",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "slightly",
        "moderately",
        "considerably",
        "extremely",
      ],
      act: "PS18D",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "",
      shortname: "pss22-pss23-subtopic",
      access: "private",
      name: "CONFIDENCE RATINGS. Is the above information significantly distorted by:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "PSS22-PSS23-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "BH.27",
      shortname: "pss22",
      access: "private",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PSS22",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "PSS22-PSS23-ACT",
      suptopic_code: "PSS22-CODE",
      subtopic_name:
        "Client's misrepresentation?",
    },
    {
      id: "BH.28",
      shortname: "pss23",
      access: "private",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PSS23",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "PSS22-PSS23-ACT",
      suptopic_code: "PSS23-CODE",
      subtopic_name:
        "Client’s inability to understand?",
    },
  ],
};

export default behavioralHistoryJSON;
