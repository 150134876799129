const medicalHistoryJSON = {
  section: "M",
  topic: "Medical History",
  checked: "true",
  color: "secondary",
  description: "",
  body: [
    {
      id: "MH.1",
      shortname: "m1",
      name: "Please rate your health",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Poor", "Fair", "Good", "Excellent", "Refused to answer"],
      act: "M1",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "MH.1.1",
      shortname: "m-new2",
      name: "Did you have any significant prenatal, birth or developmental problems?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "M-NEW2",
      mandatory: "true",
      depending: "M1",
      exception: "Refused to answer",
      link: "M-NEW3",
    },
    {
      id: "MH.1.1.1",
      shortname: "m-new3",
      name: "",
      prevalue: "If yes, explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "M-NEW3",
      depending: "M-NEW2",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "MH.1.2",
      shortname: "m-new4",
      name: "Do you have any history of serious injuries, illnesses, physical disabilities or handicapping conditions?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "M-NEW4",
      mandatory: "true",
      depending: "M1",
      exception: "Refused to answer",
      link: "M-NEW5",
    },
    {
      id: "MH.1.2.1",
      shortname: "m-new5",
      name: "",
      prevalue: "If yes, explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "M-NEW5",
      depending: "M-NEW4",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "MH.1.3",
      shortname: "m-new4-add",
      name: "Is there any family history of serious medical conditions like heart disease , diabetes, etc.?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "M-NEW4-ADD",
      mandatory: "true",
      depending: "M1",
      exception: "Refused to answer",
      link: "M-NEW5-ADD",
    },
    {
      id: "MH.1.3.1",
      shortname: "m-new5-add",
      name: "",
      prevalue: "If yes, explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "M-NEW5-ADD",
      depending: "M-NEW4-ADD",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "MH.1.4",
      shortname: "m2",
      name: "How many times have you been hospitalized overnight for a medical problem, not normal child birth?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "never",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "M2",
      mandatory: "true",
      depending: "M1",
      exception: "Refused to answer",
      link: "M3",
    },
    {
      id: "MH.1.4.1",
      shortname: "m3",
      name: "",
      prevalue: "When was your most recent hospitalization?",
      value: "",
      disabled: "true",
      type: "month",
      act: "M3",
      depending: "M2",
      exception: "never",
      extend: "true",
      link: "M4",
    },
    {
      id: "MH.1.4.2",
      shortname: "m4",
      name: "",
      prevalue: "What was the reason for this hospitalization?",
      value: "",
      disabled: "true",
      type: "text",
      act: "M4",
      depending: "M2",
      exception: "never",
      extend: "true",
    },
    {
      id: "MH.1.5",
      shortname: "m5-add",
      name: "Do you have any allergies?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "M5-ADD",
      mandatory: "true",
      depending: "M1",
      exception: "Refused to answer",
      link: "M6-ADD",
    },
    {
      id: "MH.1.5.1",
      shortname: "m6-add",
      name: "",
      prevalue: "If yes, explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "M6-ADD",
      depending: "M5-ADD",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "MH.1.6",
      shortname: "m5",
      name: "Do you have any current medical conditions, including communicable diseases such as TB, STD, Hepatitis, that interfere or impact your life?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "M5",
      mandatory: "true",
      depending: "M1",
      exception: "Refused to answer",
      link: "M6",
    },
    {
      id: "MH.1.6.1",
      shortname: "m6",
      name: "",
      prevalue: "If yes, explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "M6",
      depending: "M5",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "MH.1.7",
      shortname: "m7-add",
      name: "Are you taking any over-the-counter (OTC) medications/vitamins/supplements?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "M7-ADD",
      mandatory: "true",
      depending: "M1",
      exception: "Refused to answer",
      link: "M8-ADD",
    },
    {
      id: "MH.1.7.1",
      shortname: "m8-add",
      name: "",
      prevalue: "If Yes, please list",
      value: "",
      disabled: "true",
      type: "text",
      act: "M8-ADD",
      depending: "M7-ADD",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "MH.1.8",
      shortname: "m8-pain",
      name: "Are you currently experiencing any pain?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "M8-PAIN",
      mandatory: "true",
      depending: "M1",
      exception: "Refused to answer",
      link: "M8-PAIN-1",
    },
    {
      id: "MH.1.8.1",
      shortname: "m8-pain-1",
      name: "",
      prevalue:
        "If Yes, please rate your pain on a 1-10 scale with 10 the most severe?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
      ],
      act: "M8-PAIN-1",
      depending: "M8-PAIN",
      exception: "Yes",
      extend: "true",
      link: "M8-PAIN-2",
    },
    {
      id: "MH.1.8.2",
      shortname: "m8-pain-2",
      name: "",
      prevalue: "Please describe the pain and where it is located in your body",
      value: "",
      disabled: "true",
      type: "text",
      act: "M8-PAIN-2",
      depending: "M8-PAIN",
      exception: "Yes",
      extend: "true",
      link: "M8-PAIN-3",
    },
    {
      id: "MH.1.8.3",
      shortname: "m8-pain-3",
      name: "",
      prevalue: "Is this a chronic pain condition for you?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "M8-PAIN-3",
      depending: "M8-PAIN",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "MH.1.9",
      shortname: "m7",
      name: "Are you taking any prescribed medication for a medical (not psychiatric) problem?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "M7",
      mandatory: "true",
      depending: "M1",
      exception: "Refused to answer",
      link: "M8",
    },
    {
      id: "MH.1.9.1",
      shortname: "m8",
      name: "",
      prevalue: "If yes, please list?",
      value: "",
      disabled: "true",
      type: "text",
      act: "M8",
      depending: "M7",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "MH.1.10",
      shortname: "m17",
      name: "Do you receive any pension or disability payments for a physical disability?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "M17",
      mandatory: "true",
      depending: "M1",
      exception: "Refused to answer",
      link: "M18",
    },
    {
      id: "MH.1.10.1",
      shortname: "m18",
      name: "",
      prevalue: "If yes, specify the reason for the disability?",
      value: "",
      disabled: "true",
      type: "text",
      act: "M18",
      depending: "M17",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "MH.2",
      shortname: "m0",
      name: "Gender :",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Male", "Female", "Other"],
      act: "M0",
      depending: "",
      exception: "",
      depending1: "M1",
      exception1: "Refused to answer",
      link: "M-NEW14",
    },
    {
      id: "MH.2.1",
      shortname: "m-new14",
      name: "",
      prevalue: "Sex assigned at birth",
      value: "",
      disabled: "true",
      type: "text",
      act: "M-NEW14",
      extend: "true",
      depending: "DEMO4",
      exception: "Other",
      depending1: "M1",
      exception1: "Refused to answer",
      link: "M-NEW15-ADOL1",
    },
    {
      id: "MH.2.2",
      shortname: "m-new15-adol1",
      name: "",
      prevalue: "For Females: At what age did you begin your menstruation?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "N/A",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "M-NEW15-ADOL1",
      depending: "DEMO4",
      exception: "Female",
      condition: "in",
      depending1: "M1",
      exception1: "Refused to answer",
      condition1: "out",
      extend: "true",
      link: "M-NEW16",
    },
    {
      id: "MH.2.3",
      shortname: "m-new16",
      name: "",
      prevalue: "For Females: How many times have you been pregnant?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "never",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "M-NEW16",
      depending: "DEMO4",
      exception: "Female",
      depending1: "M1",
      exception1: "Refused to answer",
      extend: "true",
      link: "M14",
    },
    {
      id: "MH.2.4",
      shortname: "m14",
      name: "",
      prevalue: "For Females: Are you currently pregnant?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "M14",
      depending: "M-NEW16",
      exception: "never",
      extend: "true",
      link: "M-NEW18",
    },
    {
      id: "MH.2.5",
      shortname: "m-new18",
      name: "",
      prevalue: "For Females: How many births?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "none",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "10",
        "11",
        "12",
      ],
      act: "M-NEW18",
      depending: "M-NEW16",
      exception: "never",
      extend: "true",
      link: "M-NEW19",
    },
    {
      id: "MH.2.6",
      shortname: "m-new19",
      name: "",
      prevalue: "For Males: How many children have you fathered?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "none",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "10",
        "11",
        "12",
      ],
      act: "M-NEW19",
      depending: "DEMO4",
      exception: "Male",
      depending1: "M1",
      exception1: "Refused to answer",
      extend: "true",
    },
    {
      id: "MH.1.11",
      shortname: "m12",
      name: "Who is your Primary Care Physician or Family Doctor?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "text",
      act: "M12",
      depending: "M1",
      exception: "Refused to answer",
    },
    {
      id: "MH.1.12",
      shortname: "m12-0-adol1",
      name: "Do You know when approximately was your last dental exam? (YYYY)",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "Don’t know"],
      act: "M12-0-ADOL1",
      depending: "M1",
      exception: "Refused to answer",
      condition: "out",
      mandatory: "true",
      link: "M12-ADOL1",
    },
    {
      id: "MH.1.12.1",
      extend: "true",
      shortname: "m12-adol1",
      name: "",
      prevalue: "Approximately when was your last dental exam? (YYYY)",
      value: "",
      disabled: "true",
      type: "year",
      act: "M12-ADOL1",
      depending: "M12-0-ADOL1",
      exception: "Yes",
    },
    {
      id: "MH.1.13",
      shortname: "m12-0-adol2",
      name: "Do You know when approximately was your last hearing exam? (YYYY)",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "Don’t know"],
      act: "M12-0-ADOL2",
      depending: "M1",
      exception: "Refused to answer",
      condition: "out",
      mandatory: "true",
      link: "M12-ADOL2",
    },
    {
      id: "MH.1.13.1",
      extend: "true",
      shortname: "m12-adol2",
      name: "",
      prevalue: "Approximately when was your last hearing exam? (YYYY)",
      value: "",
      disabled: "true",
      type: "year",
      act: "M12-ADOL2",
      depending: "M12-0-ADOL2",
      exception: "Yes",
    },
    {
      id: "MH.1.13.1.1",
      shortname: "m12-0-adol4",
      name: "For females: Do You know when approximately was your last gynecological exam? (YYYY)",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "Don’t know"],
      act: "M12-0-ADOL4",
      depending: "DEMO4",
      exception: "Female",
      condition: "in",
      depending1: "M1",
      exception1: "Refused to answer",
      condition1: "out",
      mandatory: "true",
      link: "M12-ADOL4",
    },
    {
      id: "MH.1.13.1.2",
      extend: "true",
      shortname: "m12-adol4",
      name: "",
      prevalue:
        "For females: Approximately when was your last gynecological exam? (YYYY)",
      value: "",
      disabled: "true",
      type: "year",
      act: "M12-ADOL4",
      depending: "M12-0-ADOL4",
      exception: "Yes",
    },
    {
      id: "MH.1.14",
      shortname: "m13-0",
      name: "Do You know when approximately was your last physical exam? (YYYY)",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "Don’t know"],
      act: "M13-0",
      depending: "M1",
      exception: "Refused to answer",
      mandatory: "true",
      link: "M13",
    },
    {
      id: "MH.1.14.1",
      extend: "true",
      shortname: "m13",
      name: "",
      prevalue: "Approximately when was your last physical exam? (YYYY)",
      value: "",
      disabled: "true",
      type: "year",
      act: "M13",
      depending: "M13-0",
      exception: "Yes",
    },
    {
      id: "MH.1.15",
      shortname: "m15",
      name: "How many days in the past 30 days have you experienced any medical problems?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "zero",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "M15",
      mandatory: "true",
      depending: "M1",
      exception: "Refused to answer",
      link: "M-NEW26",
    },
    {
      id: "MH.1.15.1",
      shortname: "m-new26",
      name: "",
      prevalue:
        "If any medical problems in the past 30 days, what is the nature of your problem?",
      value: "",
      disabled: "true",
      type: "text",
      act: "M-NEW26",
      depending: "M15",
      exception: "zero",
      extend: "true",
    },
    {
      id: "MH.1.16",
      shortname: "m19",
      name: "How worried or bothered have you been about physical health problems in the past 30 days? ",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "a little bit",
        "a lot",
      ],
      act: "M19",
      mandatory: "true",
      depending: "M1",
      exception: "Refused to answer",
      link: "M-NEW28",
    },
    {
      id: "MH.1.16.1",
      shortname: "m-new28",
      name: "",
      prevalue:
        "If answer is something other than not at all, please explain the reason you are worried or bothered?",
      value: "",
      disabled: "true",
      type: "text",
      act: "M-NEW28",
      depending: "M19",
      exception: "not at all",
      extend: "true",
    },
    {
      id: "MH.1.17",
      shortname: "m20",
      name: "How important is it for you to receive additional treatment for any physical health problems?  ",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "a little bit",
        "a lot",
      ],
      act: "M20",
      depending: "M1",
      exception: "Refused to answer",
    },
    {
      id: "MH.3",
      shortname: "mh16c",
      access: "private",
      name: "Additional comments:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "editor",
      act: "MH16C",
      depending: "",
      exception: "",
    },
  ],
};

export default medicalHistoryJSON;
