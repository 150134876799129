const blockMethadone = [
  //!========================================================================================
  /*                                                                                      *
   *                                  STARTBLOCKMETHADONE                                 *
   *                                                                                      */
  //!========================================================================================
  {
    id: "DA.1.1.264",
    shortname: "methadone-block-subtopic",
    name: "Direction for Methadone: Please answer the items in the grid below. Have there EVER been significant periods during which you...",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "subtopic",
    act: "METHADONE-BLOCK-SUBTOPIC",
    depending: "DA0",
    exception: "Methadone",
    condition: "in",
  },
  {
    id: "DA.1.1.265",
    shortname: "methadone-block-6",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METHADONE-BLOCK-6",
    depending: "DA0",
    exception: "Methadone",
    condition: "in",
    link: "METHADONE-BLOCK-7",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.265.1",
    extend: "true",
    shortname: "methadone-block-7",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METHADONE-BLOCK-7",
    depending: "METHADONE-BLOCK-6",
    exception: "This has happened",
    link: "METHADONE-BLOCK-8",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.265.2",
    extend: "true",
    shortname: "methadone-block-8",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-8",
    depending: "METHADONE-BLOCK-6",
    exception: "This has happened",
    link: "METHADONE-BLOCK-9",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.265.3",
    extend: "true",
    shortname: "methadone-block-9",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-9",
    depending: "METHADONE-BLOCK-6",
    exception: "This has happened",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.266",
    shortname: "methadone-block-10",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METHADONE-BLOCK-10",
    depending: "DA0",
    exception: "Methadone",
    condition: "in",
    link: "METHADONE-BLOCK-11",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.266.1",
    extend: "true",
    shortname: "methadone-block-11",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METHADONE-BLOCK-11",
    depending: "METHADONE-BLOCK-10",
    exception: "This has happened",
    link: "METHADONE-BLOCK-12",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.266.2",
    extend: "true",
    shortname: "methadone-block-12",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-12",
    depending: "METHADONE-BLOCK-10",
    exception: "This has happened",
    link: "METHADONE-BLOCK-13",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.266.3",
    extend: "true",
    shortname: "methadone-block-13",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-13",
    depending: "METHADONE-BLOCK-10",
    exception: "This has happened",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.267",
    shortname: "methadone-block-14",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METHADONE-BLOCK-14",
    depending: "DA0",
    exception: "Methadone",
    condition: "in",
    link: "METHADONE-BLOCK-15",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.267.1",
    extend: "true",
    shortname: "methadone-block-15",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METHADONE-BLOCK-15",
    depending: "METHADONE-BLOCK-14",
    exception: "This has happened",
    link: "METHADONE-BLOCK-16",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.267.2",
    extend: "true",
    shortname: "methadone-block-16",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-16",
    depending: "METHADONE-BLOCK-14",
    exception: "This has happened",
    link: "METHADONE-BLOCK-17",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.267.3",
    extend: "true",
    shortname: "methadone-block-17",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-17",
    depending: "METHADONE-BLOCK-14",
    exception: "This has happened",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.268",
    shortname: "methadone-block-18",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METHADONE-BLOCK-18",
    depending: "DA0",
    exception: "Methadone",
    condition: "in",
    link: "METHADONE-BLOCK-19",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.268.1",
    extend: "true",
    shortname: "methadone-block-19",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METHADONE-BLOCK-19",
    depending: "METHADONE-BLOCK-18",
    exception: "This has happened",
    link: "METHADONE-BLOCK-30",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.268.2",
    extend: "true",
    shortname: "methadone-block-20",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-20",
    depending: "METHADONE-BLOCK-18",
    exception: "This has happened",
    link: "METHADONE-BLOCK-21",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.268.3",
    extend: "true",
    shortname: "methadone-block-21",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-21",
    depending: "METHADONE-BLOCK-18",
    exception: "This has happened",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.269",
    shortname: "methadone-block-22",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METHADONE-BLOCK-22",
    depending: "DA0",
    exception: "Methadone",
    condition: "in",
    link: "METHADONE-BLOCK-23",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.269.1",
    extend: "true",
    shortname: "methadone-block-23",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METHADONE-BLOCK-23",
    depending: "METHADONE-BLOCK-22",
    exception: "This has happened",
    link: "METHADONE-BLOCK-24",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.269.2",
    extend: "true",
    shortname: "methadone-block-24",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-24",
    depending: "METHADONE-BLOCK-22",
    exception: "This has happened",
    link: "METHADONE-BLOCK-25",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.269.3",
    extend: "true",
    shortname: "methadone-block-25",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-25",
    depending: "METHADONE-BLOCK-22",
    exception: "This has happened",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.270",
    shortname: "methadone-block-26",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METHADONE-BLOCK-26",
    depending: "DA0",
    exception: "Methadone",
    condition: "in",
    link: "METHADONE-BLOCK-27",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.270.1",
    extend: "true",
    shortname: "methadone-block-27",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METHADONE-BLOCK-27",
    depending: "METHADONE-BLOCK-26",
    exception: "This has happened",
    link: "METHADONE-BLOCK-28",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.270.2",
    extend: "true",
    shortname: "methadone-block-28",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-28",
    depending: "METHADONE-BLOCK-26",
    exception: "This has happened",
    link: "METHADONE-BLOCK-29",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.270.3",
    extend: "true",
    shortname: "methadone-block-29",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-29",
    depending: "METHADONE-BLOCK-26",
    exception: "This has happened",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.271",
    shortname: "methadone-block-30",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METHADONE-BLOCK-30",
    depending: "DA0",
    exception: "Methadone",
    condition: "in",
    link: "METHADONE-BLOCK-31",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.271.1",
    extend: "true",
    shortname: "methadone-block-31",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METHADONE-BLOCK-31",
    depending: "METHADONE-BLOCK-30",
    exception: "This has happened",
    link: "METHADONE-BLOCK-32",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.271.2",
    extend: "true",
    shortname: "methadone-block-32",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-32",
    depending: "METHADONE-BLOCK-30",
    exception: "This has happened",
    link: "METHADONE-BLOCK-33",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.271.3",
    extend: "true",
    shortname: "methadone-block-33",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-33",
    depending: "METHADONE-BLOCK-30",
    exception: "This has happened",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.272",
    shortname: "methadone-block-34",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METHADONE-BLOCK-34",
    depending: "DA0",
    exception: "Methadone",
    condition: "in",
    link: "METHADONE-BLOCK-35",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.272.1",
    extend: "true",
    shortname: "methadone-block-35",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METHADONE-BLOCK-35",
    depending: "METHADONE-BLOCK-34",
    exception: "This has happened",
    link: "METHADONE-BLOCK-36",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.272.2",
    extend: "true",
    shortname: "methadone-block-36",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-36",
    depending: "METHADONE-BLOCK-34",
    exception: "This has happened",
    link: "METHADONE-BLOCK-37",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.272.3",
    extend: "true",
    shortname: "methadone-block-37",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-37",
    depending: "METHADONE-BLOCK-34",
    exception: "This has happened",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.273",
    shortname: "methadone-block-38",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METHADONE-BLOCK-38",
    depending: "DA0",
    exception: "Methadone",
    condition: "in",
    link: "METHADONE-BLOCK-39",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.273.1",
    extend: "true",
    shortname: "methadone-block-39",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METHADONE-BLOCK-39",
    depending: "METHADONE-BLOCK-38",
    exception: "This has happened",
    link: "METHADONE-BLOCK0",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.273.2",
    extend: "true",
    shortname: "methadone-block-40",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-40",
    depending: "METHADONE-BLOCK-38",
    exception: "This has happened",
    link: "METHADONE-BLOCK-41",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.273.3",
    extend: "true",
    shortname: "methadone-block-41",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-41",
    depending: "METHADONE-BLOCK-38",
    exception: "This has happened",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.274",
    shortname: "methadone-block-42",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METHADONE-BLOCK-42",
    depending: "DA0",
    exception: "Methadone",
    condition: "in",
    link: "METHADONE-BLOCK-43",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.274.1",
    extend: "true",
    shortname: "methadone-block-43",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METHADONE-BLOCK-43",
    depending: "METHADONE-BLOCK-42",
    exception: "This has happened",
    link: "METHADONE-BLOCK-44",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.274.2",
    extend: "true",
    shortname: "methadone-block-44",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-44",
    depending: "METHADONE-BLOCK-42",
    exception: "This has happened",
    link: "METHADONE-BLOCK-45",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.274.3",
    extend: "true",
    shortname: "methadone-block-45",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-45",
    depending: "METHADONE-BLOCK-42",
    exception: "This has happened",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.275",
    shortname: "methadone-block-46",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METHADONE-BLOCK-46",
    depending: "DA0",
    exception: "Methadone",
    condition: "in",
    link: "METHADONE-BLOCK-47",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.275.1",
    extend: "true",
    shortname: "methadone-block-47",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METHADONE-BLOCK-47",
    depending: "METHADONE-BLOCK-46",
    exception: "This has happened",
    link: "METHADONE-BLOCK-48",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.275.2",
    extend: "true",
    shortname: "methadone-block-48",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-48",
    depending: "METHADONE-BLOCK-46",
    exception: "This has happened",
    link: "METHADONE-BLOCK-49",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.275.3",
    extend: "true",
    shortname: "methadone-block-49",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METHADONE-BLOCK-49",
    depending: "METHADONE-BLOCK-46",
    exception: "This has happened",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.276",
    shortname: "methadone-block-50",
    name: "Number of criteria met for:",
    prevalue: "Number of criteria met for Methadone:",
    value: "0",
    disabled: "true",
    type: "content",
    comment: "Consider no diagnosis for Methadone",
    act: "METHADONE-BLOCK-50",
    depending: "DA0",
    exception: "Methadone",
    condition: "in",
    meaningfulcount: "This has happened",
    subtopic_container_act: "METHADONE-BLOCK-SUBTOPIC",
    suptopic_code: "METHADONE-BLOCK-12-CONTENT",
    subtopic_name: "Content Criteria",
  },
  //!========================================================================================
  /*                                                                                      *
   *                                   ENDBLOCKMETHADONE                                  *
   *                                                                                      */
  //!========================================================================================
];

export default blockMethadone;
