import React, { Component } from 'react';
import { connect } from "react-redux";
import ErrorIndicator from '../error-indicator/error-indicator';

class ErrorBoundry extends Component {
  componentDidCatch() {   
    this.props.setErrorState(true);
  }

  render() {
    if (this.props.hasError) {
      return <ErrorIndicator />
    }

    return this.props.children;
  }
}

const MapStateToProps = state => {
  return {
    hasError: state.evtreducer.hasError    
  };
};

const MapDispatchToProps = dispatch => {
  return {
    setErrorState: (error) => {
      dispatch({ type: "SET_ERROR_STATE", payload: error  });       
  }
};
}
export default connect(MapStateToProps,MapDispatchToProps)(ErrorBoundry);