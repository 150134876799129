const collateralJSON =   {
  section: "CI",
  topic: "Collateral Information",
  checked: "true",
  // access: "private",
  color: "secondary",
  description: "(optional)",
  body: [
    {
      id: "CI.1",
      shortname: "ci1",
      name: "Collateral information was reviewed",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "CI1",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "CI1-1",
      // subtopic_container_act: "CI1",
      // suptopic_code: "CI1_INFO3",
      // subtopic_name: "",
    },
    {
      id: "CI.1.1",
      extend: "true",
      shortname: "ci1-1",
      name: "",
      prevalue: "Collateral information was reviewed from",
      value: "",
      disabled: "true",
      type: "textarea",
      act: "CI1-1",
      depending: "CI1",
      exception: "No",
      link: "CI1-2",
      subtopic_container_act: "CI1",
      suptopic_code: "CI1_INFO1",
      subtopic_name: "",
    },
    {
      id: "CI.1.2",
      extend: "true",
      shortname: "ci1-2",
      name: "",
      prevalue: "Collateral information was also reviewed from",
      value: "",
      disabled: "true",
      type: "textarea",
      act: "CI1-2",
      depending: "CI1-1",
      exception: "",
      link: "CI1-3",
      subtopic_container_act: "CI1",
      suptopic_code: "CI1_INFO2",
      subtopic_name: "",
    },
    {
      id: "CI.1.3",
      extend: "true",
      shortname: "ci1-3",
      name: "",
      prevalue: "Collateral information was also reviewed from",
      value: "",
      disabled: "true",
      type: "textarea",
      act: "CI1-3",
      depending: "CI1-2",
      exception: "",
      link: "CI1-4",
      subtopic_container_act: "CI1",
      suptopic_code: "CI1_INFO3",
      subtopic_name: "",
    },
    {
      id: "CI.1.4",
      extend: "true",
      shortname: "ci1-4",
      name: "",
      prevalue: "Collateral information was also reviewed from",
      value: "",
      disabled: "true",
      type: "textarea",
      act: "CI1-4",
      depending: "CI1-3",
      exception: "",
      link: "CI1-5",
      subtopic_container_act: "CI1",
      suptopic_code: "CI1_INFO4",
      subtopic_name: "",
    },
    {
      id: "CI.1.5",
      extend: "true",
      shortname: "ci1-5",
      name: "",
      prevalue: "Collateral information was also reviewed from",
      value: "",
      disabled: "true",
      type: "textarea",
      act: "CI1-5",
      depending: "CI1-4",
      exception: "",
      link: "CI1-6",
      subtopic_container_act: "CI1",
      suptopic_code: "CI1_INFO5",
      subtopic_name: "",
    },
    {
      id: "CI.1.6",
      extend: "true",
      shortname: "ci1-6",
      name: "",
      prevalue: "Collateral information was also reviewed from",
      value: "",
      disabled: "true",
      type: "textarea",
      act: "CI1-6",
      depending: "CI1-5",
      exception: "",
      link: "CI1-7",
      subtopic_container_act: "CI1",
      suptopic_code: "CI1_INFO6",
      subtopic_name: "",
    },
    {
      id: "CI.1.7",
      extend: "true",
      shortname: "ci1-7",
      name: "",
      prevalue: "Collateral information was also reviewed from",
      value: "",
      disabled: "true",
      type: "textarea",
      act: "CI1-7",
      depending: "CI1-6",
      exception: "",
      link: "CI1-8",
      subtopic_container_act: "CI1",
      suptopic_code: "CI1_INFO7",
      subtopic_name: "",
    },
    {
      id: "CI.1.8",
      extend: "true",
      shortname: "ci1-8",
      name: "",
      prevalue: "Collateral information was also reviewed from",
      value: "",
      disabled: "true",
      type: "textarea",
      act: "CI1-8",
      depending: "CI1-7",
      exception: "",
      link: "CI1-9",
      subtopic_container_act: "CI1",
      suptopic_code: "CI1_INFO8",
      subtopic_name: "",
    },
    {
      id: "CI.1.9",
      extend: "true",
      shortname: "ci1-9",
      name: "",
      prevalue: "Collateral information was also reviewed from",
      value: "",
      disabled: "true",
      type: "textarea",
      act: "CI1-9",
      depending: "CI1-8",
      exception: "",
      subtopic_container_act: "CI1",
      suptopic_code: "CI1_INFO9",
      subtopic_name: "",
    },

    /*
    {
      id: "CI.1.1",
      shortname: "ci1-1",
      name: "",
      prevalue: "Information was reviewed from",
      value: "",
      disabled: "true",
      type: "text",
      act: "CI1-1",
      depending: "CI1",
      exception: "Yes",
      extend: "true",
      link: "CI1-2",
    },
    {
      id: "CI.1.2",
      shortname: "ci1-2",
      name: "",
      prevalue: "Information was also  reviewed from",
      value: "",
      disabled: "true",
      type: "text",
      act: "CI1-2",
      depending: "CI1",
      exception: "Yes",
      extend: "true",
      link: "CI1-3",
    },
    {
      id: "CI.1.3",
      shortname: "ci1-3",
      name: "",
      prevalue: "Information was also  reviewed from ",
      value: "",
      disabled: "true",
      type: "text",
      act: "CI1-3",
      depending: "CI1",
      exception: "Yes",
      extend: "true",
    },
*/
  ],
}



export default collateralJSON
