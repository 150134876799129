const blockCannabis = [
  //!========================================================================================
  /*                                                                                      *
   *                                  STARTBLOCKCANNABIS                                  *
   *                                                                                      */
  //!========================================================================================
  {
    id: "DA.1.1.39",
    shortname: "cannabis-block-subtopic",
    name: "Direction for Cannabis: Please answer the items in the grid below. Have there EVER been significant periods during which you...",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "subtopic",
    act: "CANNABIS-BLOCK-SUBTOPIC",
    depending: "DA0",
    exception: "Cannabis products, including K2",
    condition: "in",
  },
  {
    id: "DA.1.1.40",
    shortname: "cannabis-block-6",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CANNABIS-BLOCK-6",
    depending: "DA0",
    exception: "Cannabis products, including K2",
    condition: "in",
    link: "CANNABIS-BLOCK-7",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.40.1",
    extend: "true",
    shortname: "cannabis-block-7",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CANNABIS-BLOCK-7",
    depending: "CANNABIS-BLOCK-6",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-8",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.40.2",
    extend: "true",
    shortname: "cannabis-block-8",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-8",
    depending: "CANNABIS-BLOCK-6",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-9",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.40.3",
    extend: "true",
    shortname: "cannabis-block-9",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-9",
    depending: "CANNABIS-BLOCK-6",
    exception: "This has happened",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.41",
    shortname: "cannabis-block-10",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CANNABIS-BLOCK-10",
    depending: "DA0",
    exception: "Cannabis products, including K2",
    condition: "in",
    link: "CANNABIS-BLOCK-11",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.41.1",
    extend: "true",
    shortname: "cannabis-block-11",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CANNABIS-BLOCK-11",
    depending: "CANNABIS-BLOCK-10",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-12",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.41.2",
    extend: "true",
    shortname: "cannabis-block-12",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-12",
    depending: "CANNABIS-BLOCK-10",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-13",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.41.3",
    extend: "true",
    shortname: "cannabis-block-13",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-13",
    depending: "CANNABIS-BLOCK-10",
    exception: "This has happened",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.42",
    shortname: "cannabis-block-14",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CANNABIS-BLOCK-14",
    depending: "DA0",
    exception: "Cannabis products, including K2",
    condition: "in",
    link: "CANNABIS-BLOCK-15",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.42.1",
    extend: "true",
    shortname: "cannabis-block-15",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CANNABIS-BLOCK-15",
    depending: "CANNABIS-BLOCK-14",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-16",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.42.2",
    extend: "true",
    shortname: "cannabis-block-16",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-16",
    depending: "CANNABIS-BLOCK-14",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-17",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.42.3",
    extend: "true",
    shortname: "cannabis-block-17",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-17",
    depending: "CANNABIS-BLOCK-14",
    exception: "This has happened",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.43",
    shortname: "cannabis-block-18",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CANNABIS-BLOCK-18",
    depending: "DA0",
    exception: "Cannabis products, including K2",
    condition: "in",
    link: "CANNABIS-BLOCK-19",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.43.1",
    extend: "true",
    shortname: "cannabis-block-19",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CANNABIS-BLOCK-19",
    depending: "CANNABIS-BLOCK-18",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-30",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.43.2",
    extend: "true",
    shortname: "cannabis-block-20",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-20",
    depending: "CANNABIS-BLOCK-18",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-21",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.43.3",
    extend: "true",
    shortname: "cannabis-block-21",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-21",
    depending: "CANNABIS-BLOCK-18",
    exception: "This has happened",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.44",
    shortname: "cannabis-block-22",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CANNABIS-BLOCK-22",
    depending: "DA0",
    exception: "Cannabis products, including K2",
    condition: "in",
    link: "CANNABIS-BLOCK-23",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.44.1",
    extend: "true",
    shortname: "cannabis-block-23",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CANNABIS-BLOCK-23",
    depending: "CANNABIS-BLOCK-22",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-24",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.44.2",
    extend: "true",
    shortname: "cannabis-block-24",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-24",
    depending: "CANNABIS-BLOCK-22",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-25",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.44.3",
    extend: "true",
    shortname: "cannabis-block-25",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-25",
    depending: "CANNABIS-BLOCK-22",
    exception: "This has happened",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.45",
    shortname: "cannabis-block-26",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CANNABIS-BLOCK-26",
    depending: "DA0",
    exception: "Cannabis products, including K2",
    condition: "in",
    link: "CANNABIS-BLOCK-27",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.45.1",
    extend: "true",
    shortname: "cannabis-block-27",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CANNABIS-BLOCK-27",
    depending: "CANNABIS-BLOCK-26",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-28",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.45.2",
    extend: "true",
    shortname: "cannabis-block-28",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-28",
    depending: "CANNABIS-BLOCK-26",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-29",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.45.3",
    extend: "true",
    shortname: "cannabis-block-29",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-29",
    depending: "CANNABIS-BLOCK-26",
    exception: "This has happened",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.46",
    shortname: "cannabis-block-30",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CANNABIS-BLOCK-30",
    depending: "DA0",
    exception: "Cannabis products, including K2",
    condition: "in",
    link: "CANNABIS-BLOCK-31",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.46.1",
    extend: "true",
    shortname: "cannabis-block-31",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CANNABIS-BLOCK-31",
    depending: "CANNABIS-BLOCK-30",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-32",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.46.2",
    extend: "true",
    shortname: "cannabis-block-32",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-32",
    depending: "CANNABIS-BLOCK-30",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-33",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.46.3",
    extend: "true",
    shortname: "cannabis-block-33",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-33",
    depending: "CANNABIS-BLOCK-30",
    exception: "This has happened",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.47",
    shortname: "cannabis-block-34",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CANNABIS-BLOCK-34",
    depending: "DA0",
    exception: "Cannabis products, including K2",
    condition: "in",
    link: "CANNABIS-BLOCK-35",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.47.1",
    extend: "true",
    shortname: "cannabis-block-35",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CANNABIS-BLOCK-35",
    depending: "CANNABIS-BLOCK-34",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-36",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.47.2",
    extend: "true",
    shortname: "cannabis-block-36",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-36",
    depending: "CANNABIS-BLOCK-34",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-37",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.47.3",
    extend: "true",
    shortname: "cannabis-block-37",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-37",
    depending: "CANNABIS-BLOCK-34",
    exception: "This has happened",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.48",
    shortname: "cannabis-block-38",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CANNABIS-BLOCK-38",
    depending: "DA0",
    exception: "Cannabis products, including K2",
    condition: "in",
    link: "CANNABIS-BLOCK-39",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.48.1",
    extend: "true",
    shortname: "cannabis-block-39",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CANNABIS-BLOCK-39",
    depending: "CANNABIS-BLOCK-38",
    exception: "This has happened",
    link: "CANNABIS-BLOCK0",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.48.2",
    extend: "true",
    shortname: "cannabis-block-40",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-40",
    depending: "CANNABIS-BLOCK-38",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-41",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.48.3",
    extend: "true",
    shortname: "cannabis-block-41",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-41",
    depending: "CANNABIS-BLOCK-38",
    exception: "This has happened",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.49",
    shortname: "cannabis-block-42",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CANNABIS-BLOCK-42",
    depending: "DA0",
    exception: "Cannabis products, including K2",
    condition: "in",
    link: "CANNABIS-BLOCK-43",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.49.1",
    extend: "true",
    shortname: "cannabis-block-43",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CANNABIS-BLOCK-43",
    depending: "CANNABIS-BLOCK-42",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-44",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.49.2",
    extend: "true",
    shortname: "cannabis-block-44",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-44",
    depending: "CANNABIS-BLOCK-42",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-45",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.49.3",
    extend: "true",
    shortname: "cannabis-block-45",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-45",
    depending: "CANNABIS-BLOCK-42",
    exception: "This has happened",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.50",
    shortname: "cannabis-block-46",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CANNABIS-BLOCK-46",
    depending: "DA0",
    exception: "Cannabis products, including K2",
    condition: "in",
    link: "CANNABIS-BLOCK-47",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.50.1",
    extend: "true",
    shortname: "cannabis-block-47",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CANNABIS-BLOCK-47",
    depending: "CANNABIS-BLOCK-46",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-48",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.50.2",
    extend: "true",
    shortname: "cannabis-block-48",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-48",
    depending: "CANNABIS-BLOCK-46",
    exception: "This has happened",
    link: "CANNABIS-BLOCK-49",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.50.3",
    extend: "true",
    shortname: "cannabis-block-49",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CANNABIS-BLOCK-49",
    depending: "CANNABIS-BLOCK-46",
    exception: "This has happened",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.51",
    shortname: "cannabis-block-50",
    name: "Number of criteria met for:",
    prevalue: "Number of criteria met for Cannabis products, including K2:",
    value: "0",
    disabled: "true",
    type: "content",
    comment: "Consider no diagnosis for Cannabis products, including K2",
    act: "CANNABIS-BLOCK-50",
    depending: "DA0",
    exception: "Cannabis products, including K2",
    condition: "in",
    meaningfulcount: "This has happened",
    subtopic_container_act: "CANNABIS-BLOCK-SUBTOPIC",
    suptopic_code: "CANNABIS-BLOCK-12-CONTENT",
    subtopic_name: "Content Criteria",
  },
  //!========================================================================================
  /*                                                                                      *
   *                                   ENDBLOCKCANNABIS                                   *
   *                                                                                      */
  //!========================================================================================
];

export default blockCannabis;
