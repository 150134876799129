const steps1 = [
  // {
  //   content: "Are you ready for a tour?",
  //   target: "body",
  //   placement: "center",
  // },
  {
    title: "My Assessments",
    target: ".my-assessments",
    content: "Here you'll sell all of your assessments for each of your patients",
    disableBeacon: true,
    event: "click",
    locale: { last: 'next' },
  },
  {
    target: ".my-chapters-panel",
    content: "",
    disableBeacon: true,
    event: "click",
  },
];

const steps2 = [
  {
    title: "Assessments Form Basic",
    target: ".my-chapters-panel",
    content: "An assessments is broken down into sections, each with their own set of questions",
    disableBeacon: true,
    event: "click",
    placement : "right"
  },
  {
    title: "Interview questions",
    target: ".interview-question",
    content:
      "These sections can be completed by the patient answering the questions online before your interview or you can complete during the interview. Click Next",
    disableBeacon: true,
    placement : "right",
    // isFixed:true
  },
  {
    title: "Post-Interview",
    target: ".post-interview-question",
    content:
      "These optional sections can only be seen by you. There are many neat features here to help you finish the assessment quickly and accurately. Click Next",
    disableBeacon: true,
    placement : "right"
  },
  {
    title: "Suggested Recommendations",
    target: ".suggested-recom",
    content:
      "Based on the patient’s answers to various questions and your post-interview assessment, click on Suggested Recommendations to see what other recommendations may be appropriate for this patient. You can choose the ones to keep in the narrative. Click Next",
    disableBeacon: true,
    placement : "right"
  },
  // {
  //   target: ".my-send-to-patient",
  //   content:
  //     "You can email an assessment to your patient to be completed by them online",
  //   disableBeacon: true,
  //   // placement : "top"
  //   // placement: "top",
  //   // locale: { skip: 'S-K-I-P' },
  // },
  // {
  //   target: ".my-diag-summary-recom",
  //   content:
  //     "Click on the Diagnosis/Summary/Recommendations chapter to complete the assessment",
  //   disableBeacon: true,
  //   // placement : "top"
  //   // placement: "top",
  // },
  {
    title: "Narrative",
    target: ".my-preview-narrative-button",
    content:
      "Once the assessment is finished, you can generate a professional-looking narrative with the click of a button. Click Next",
    disableBeacon: true,
    placement : "top"
  },
  {
    title: "",
    target: ".download-microsoft-word-version",
    content:
      "",
    disableBeacon: true,
    placement : "top"
  },
];

const steps3 = [
  {
    title: "Microsoft Word version",
    target: ".download-microsoft-word-version",
    content:
      "You can download this narrative to your computer and easily make changes, copy onto your letterhead, add your professional signature block and print or copy to your EHR.",
    disableBeacon: true,
    placement : "top"
  },
];

const steps4 = [
  {
    title: "New Assessments",
    target: ".new-assessment",
    content:
      "Now let's create a new assessment. Every new assessment deducts 1 credit from your account. Click Done and try it out!",
    disableBeacon: true,
    locale: {last: "Done", next: "Done"},
    placement : "right",
    // placement: "center",
    event: "click",
  },
  {
    target: ".my-chapters-panel",
    content: "",
    disableBeacon: true,
    event: "click",
  },
]

export { steps1, steps2, steps3,steps4 };
