const educationHistoryJSON = {
  section: "EDO",
  topic: "Education, Employment and Military History",
  checked: "true",
  color: "secondary",
  description: "",
  body: [
    {
      id: "ED.1",
      shortname: "ed1-adol1",
      name: "What grade are you in?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "dropped out",
        "HS graduate",
      ],
      act: "ED1-ADOL1",
      depending: "",
      exception: "",
      link: "ED1-ADOL1-1",
    },
    {
      id: "ED.1.1",
      shortname: "ed1-adol1-1",
      name: "",
      prevalue: 'If previous answer was "dropped out", please explain',
      value: "due to ",
      disabled: "true",
      type: "text",
      act: "ED1-ADOL1-1",
      depending: "ED1-ADOL1",
      exception: "dropped out",
      extend: "true",
    },
    {
      id: "ED.2",
      shortname: "ed1-adol3-0",
      name: "Have you EVER repeated a grade or grades? ",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED1-ADOL3-0",
      depending: "",
      exception: "",
      link: "ED1-ADOL3",
    },
    {
      id: "ED.2.1",
      shortname: "ed1-adol3",
      name: "",
      prevalue: "What grades have you repeated?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED1-ADOL3",
      depending: "ED1-ADOL3-0",
      exception: "Yes",
      extend: "true",
      link: "ED1-ADOL3-1",
    },
    {
      id: "ED.2.2",
      shortname: "ed1-adol3-1",
      name: "",
      prevalue: "What was the reason for repeating grade(s)?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "Academic", "Attendance", "Both", "Other"],
      act: "ED1-ADOL3-1",
      depending: "ED1-ADOL3-0",
      exception: "Yes",
      extend: "true",
      link: "ED1-ADOL3-2",
    },
    {
      id: "ED.2.2.1",
      extend: "true",
      shortname: "ed1-adol3-2",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED1-ADOL3-2",
      condition: "in",
      depending: "ED1-ADOL3-1",
      exception: "Other",
    },
    {
      id: "ED.3",
      shortname: "ed1-adol4",
      name: "What is the name of your current school or last school you attended?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "text",
      act: "ED1-ADOL4",
      depending: "",
      exception: "",
      link: "ED1-C-ADOL4",
    },
    // {
    //   id: "ED.3.c",
    //   extend: "true",
    //   shortname: "ed1-c-adol4",
    //   access: "private",
    //   name: "",
    //   prevalue: "Counselor comments:",
    //   value: "",
    //   disabled: "false",
    //   type: "editor",
    //   act: "ED1-C-ADOL4",
    //   depending: "",
    //   exception: "",
    // },
    {
      id: "ED.4",
      shortname: "ed1-adol2",
      name: "What type of school do you attend?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Public",
        "Private",
        "Special Education",
        "Vocational",
        "Alternative",
        "Boarding",
        "Other",
      ],
      act: "ED1-ADOL2",
      depending: "",
      exception: "",
      link: "ED1-1-ADOL2",
    },
    {
      id: "ED.4.1",
      extend: "true",
      shortname: "ed1-1-adol2",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED1-1-ADOL2",
      depending: "ED1-ADOL2",
      exception: "Other",
    },
    {
      id: "ED.5",
      shortname: "ed15-adol1-subtopic",
      name: "Direction: Please answer the items in the grid below. Have there EVER been significant periods during which you...",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "ED15-ADOL-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "ED.6",
      shortname: "ed15-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don't know"],
      act: "ED15-ADOL1",
      depending: "",
      exception: "",
      link: "ED15-1-ADOL1",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED15-ADOL-CODE",
      subtopic_name:
        "...attended remedial class/school/special education (Do not include gifted class/school)?",
    },
    {
      id: "ED.6.1",
      extend: "true",
      shortname: "ed15-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "ED15-1-ADOL1",
      condition: "in",
      depending: "ED15-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED15-ADOL-CODE",
      subtopic_name:
        "...attended remedial class/school/special education (Do not include gifted class/school)?",
    },
    {
      id: "ED.6.2",
      extend: "true",
      shortname: "ed15-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED15-2-ADOL1",
      condition: "in",
      depending: "ED15-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED15-ADOL-CODE",
      subtopic_name:
        "...attended remedial class/school/special education (Do not include gifted class/school)?",
    },
    {
      id: "ED.6.3",
      extend: "true",
      shortname: "ed15-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED15-3-ADOL1",
      condition: "in",
      depending: "ED15-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED15-ADOL-CODE",
      subtopic_name:
        "...attended remedial class/school/special education (Do not include gifted class/school)?",
    },
    {
      id: "ED.6.3.c",
      shortname: "ed15-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "ED15-C-ADOL1",
      depending: "ED15-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED15-ADOL-CODE",
      subtopic_name:
        "...attended remedial class/school/special education (Do not include gifted class/school)?",
    },
    {
      id: "ED.7",
      shortname: "ed16-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don't know"],
      act: "ED16-ADOL1",
      depending: "",
      exception: "",
      link: "ED16-1-ADOL1",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED16-ADOL-CODE",
      subtopic_name:
        "...had failing grades or had difficulty learning, paying attention or comprehending material?",
    },
    {
      id: "ED.7.1",
      extend: "true",
      shortname: "ed16-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "ED16-1-ADOL1",
      condition: "in",
      depending: "ED16-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED16-ADOL-CODE",
      subtopic_name:
        "...had failing grades or had difficulty learning, paying attention or comprehending material?",
    },
    {
      id: "ED.7.2",
      extend: "true",
      shortname: "ed16-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED16-2-ADOL1",
      condition: "in",
      depending: "ED16-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED16-ADOL-CODE",
      subtopic_name:
        "...had failing grades or had difficulty learning, paying attention or comprehending material?",
    },
    {
      id: "ED.7.3",
      extend: "true",
      shortname: "ed16-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED16-3-ADOL1",
      condition: "in",
      depending: "ED16-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED16-ADOL-CODE",
      subtopic_name:
        "...had failing grades or had difficulty learning, paying attention or comprehending material?",
    },
    {
      id: "ED.7.3.c",
      shortname: "ed16-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "ED16-C-ADOL1",
      depending: "ED16-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED16-ADOL-CODE",
      subtopic_name:
        "...had failing grades or had difficulty learning, paying attention or comprehending material?",
    },
    {
      id: "ED.8",
      shortname: "ed17-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don't know"],
      act: "ED17-ADOL1",
      depending: "",
      exception: "",
      link: "ED17-1-ADOL1",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED17-ADOL-CODE",
      subtopic_name:
        "Cut class/school or arrived late/left early (unauthorized on a consistent basis?",
    },
    {
      id: "ED.8.1",
      extend: "true",
      shortname: "ed17-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "ED17-1-ADOL1",
      condition: "in",
      depending: "ED17-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED17-ADOL-CODE",
      subtopic_name:
        "Cut class/school or arrived late/left early (unauthorized on a consistent basis?",
    },
    {
      id: "ED.8.2",
      extend: "true",
      shortname: "ed17-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED17-2-ADOL1",
      condition: "in",
      depending: "ED17-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED17-ADOL-CODE",
      subtopic_name:
        "Cut class/school or arrived late/left early (unauthorized on a consistent basis?",
    },
    {
      id: "ED.8.3",
      extend: "true",
      shortname: "ed17-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED17-3-ADOL1",
      condition: "in",
      depending: "ED17-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED17-ADOL-CODE",
      subtopic_name:
        "Cut class/school or arrived late/left early (unauthorized on a consistent basis?",
    },
    {
      id: "ED.8.3.c",
      shortname: "ed17-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "ED17-C-ADOL1",
      depending: "ED17-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED17-ADOL-CODE",
      subtopic_name:
        "Cut class/school or arrived late/left early (unauthorized on a consistent basis?",
    },
    {
      id: "ED.9",
      shortname: "ed18-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don't know"],
      act: "ED18-ADOL1",
      depending: "",
      exception: "",
      link: "ED18-1-ADOL1",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED18-ADOL-CODE",
      subtopic_name:
        "Were suspended, expelled, had numerous detentions, violated school rules, or your parent(s)/guardian(s) were contacted about your behavior?",
    },
    {
      id: "ED.9.1",
      extend: "true",
      shortname: "ed18-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "ED18-1-ADOL1",
      condition: "in",
      depending: "ED18-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED18-ADOL-CODE",
      subtopic_name:
        "Were suspended, expelled, had numerous detentions, violated school rules, or your parent(s)/guardian(s) were contacted about your behavior?",
    },
    {
      id: "ED.9.2",
      extend: "true",
      shortname: "ed18-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED18-2-ADOL1",
      condition: "in",
      depending: "ED18-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED18-ADOL-CODE",
      subtopic_name:
        "Were suspended, expelled, had numerous detentions, violated school rules, or your parent(s)/guardian(s) were contacted about your behavior?",
    },
    {
      id: "ED.9.3",
      extend: "true",
      shortname: "ed18-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED18-3-ADOL1",
      condition: "in",
      depending: "ED18-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED18-ADOL-CODE",
      subtopic_name:
        "Were suspended, expelled, had numerous detentions, violated school rules, or your parent(s)/guardian(s) were contacted about your behavior?",
    },
    {
      id: "ED.9.3.c",
      shortname: "ed18-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "ED18-C-ADOL1",
      depending: "ED18-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED18-ADOL-CODE",
      subtopic_name:
        "Were suspended, expelled, had numerous detentions, violated school rules, or your parent(s)/guardian(s) were contacted about your behavior?",
    },
    {
      id: "ED.10",
      shortname: "ed19-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don't know"],
      act: "ED19-ADOL1",
      depending: "",
      exception: "",
      link: "ED19-1-ADOL1",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED19-ADOL-CODE",
      subtopic_name:
        "Had little or no interest in school, refused to complete assignments/homework, seriously thought about dropping out of school?",
    },
    {
      id: "ED.10.1",
      extend: "true",
      shortname: "ed19-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "ED19-1-ADOL1",
      condition: "in",
      depending: "ED19-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED19-ADOL-CODE",
      subtopic_name:
        "Had little or no interest in school, refused to complete assignments/homework, seriously thought about dropping out of school?",
    },
    {
      id: "ED.10.2",
      extend: "true",
      shortname: "ed19-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED19-2-ADOL1",
      condition: "in",
      depending: "ED19-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED19-ADOL-CODE",
      subtopic_name:
        "Had little or no interest in school, refused to complete assignments/homework, seriously thought about dropping out of school?",
    },
    {
      id: "ED.10.3",
      extend: "true",
      shortname: "ed19-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED19-3-ADOL1",
      condition: "in",
      depending: "ED19-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED19-ADOL-CODE",
      subtopic_name:
        "Had little or no interest in school, refused to complete assignments/homework, seriously thought about dropping out of school?",
    },
    {
      id: "ED.10.3.c",
      shortname: "ed19-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "ED19-C-ADOL1",
      depending: "ED19-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED19-ADOL-CODE",
      subtopic_name:
        "Had little or no interest in school, refused to complete assignments/homework, seriously thought about dropping out of school?",
    },
    {
      id: "ED.11",
      shortname: "ed20-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don't know"],
      act: "ED20-ADOL1",
      depending: "",
      exception: "",
      link: "ED20-1-ADOL1",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED20-ADOL-CODE",
      subtopic_name:
        "Met regularly with a school psychologist, guidance counselor or social worker for reason other than career counseling?",
    },
    {
      id: "ED.11.1",
      extend: "true",
      shortname: "ed20-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "ED20-1-ADOL1",
      condition: "in",
      depending: "ED20-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED20-ADOL-CODE",
      subtopic_name:
        "Met regularly with a school psychologist, guidance counselor or social worker for reason other than career counseling?",
    },
    {
      id: "ED.11.2",
      extend: "true",
      shortname: "ed20-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED20-2-ADOL1",
      condition: "in",
      depending: "ED20-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED20-ADOL-CODE",
      subtopic_name:
        "Met regularly with a school psychologist, guidance counselor or social worker for reason other than career counseling?",
    },
    {
      id: "ED.11.3",
      extend: "true",
      shortname: "ed20-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED20-3-ADOL1",
      condition: "in",
      depending: "ED20-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED20-ADOL-CODE",
      subtopic_name:
        "Met regularly with a school psychologist, guidance counselor or social worker for reason other than career counseling?",
    },
    {
      id: "ED.11.3.c",
      shortname: "ed20-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "ED20-C-ADOL1",
      depending: "ED20-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED20-ADOL-CODE",
      subtopic_name:
        "Met regularly with a school psychologist, guidance counselor or social worker for reason other than career counseling?",
    },
    {
      id: "ED.12",
      shortname: "ed21-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don't know"],
      act: "ED21-ADOL1",
      depending: "",
      exception: "",
      link: "ED21-1-ADOL1",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED21-ADOL-CODE",
      subtopic_name:
        "Had security guards, police or metal detectors at school?",
    },
    {
      id: "ED.12.1",
      extend: "true",
      shortname: "ed21-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "ED21-1-ADOL1",
      condition: "in",
      depending: "ED21-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED21-ADOL-CODE",
      subtopic_name:
        "Had security guards, police or metal detectors at school?",
    },
    {
      id: "ED.12.2",
      extend: "true",
      shortname: "ed21-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED21-2-ADOL1",
      condition: "in",
      depending: "ED21-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED21-ADOL-CODE",
      subtopic_name:
        "Had security guards, police or metal detectors at school?",
    },
    {
      id: "ED.12.3",
      extend: "true",
      shortname: "ed21-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED21-3-ADOL1",
      condition: "in",
      depending: "ED21-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED21-ADOL-CODE",
      subtopic_name:
        "Had security guards, police or metal detectors at school?",
    },
    {
      id: "ED.12.3.c",
      shortname: "ed21-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "ED21-C-ADOL1",
      depending: "ED21-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED21-ADOL-CODE",
      subtopic_name:
        "Had security guards, police or metal detectors at school?",
    },
    {
      id: "ED.13",
      shortname: "ed22-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don't know"],
      act: "ED22-ADOL1",
      depending: "",
      exception: "",
      link: "ED22-1-ADOL1",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED22-ADOL-CODE",
      subtopic_name: "Went to school prepared?",
    },
    {
      id: "ED.13.1",
      extend: "true",
      shortname: "ed22-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "ED22-1-ADOL1",
      condition: "in",
      depending: "ED22-ADOL1",
      exception: "Ever;No",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED22-ADOL-CODE",
      subtopic_name: "Went to school prepared?",
    },
    {
      id: "ED.13.2",
      extend: "true",
      shortname: "ed22-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED22-2-ADOL1",
      condition: "in",
      depending: "ED22-ADOL1",
      exception: "Ever;No",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED22-ADOL-CODE",
      subtopic_name: "Went to school prepared?",
    },
    {
      id: "ED.13.3",
      extend: "true",
      shortname: "ed22-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED22-3-ADOL1",
      condition: "in",
      depending: "ED22-ADOL1",
      exception: "Ever;No",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED22-ADOL-CODE",
      subtopic_name: "Went to school prepared?",
    },
    {
      id: "ED.13.3.c",
      shortname: "ed22-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "ED22-C-ADOL1",
      depending: "ED22-ADOL1",
      exception: "Ever;No",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED22-ADOL-CODE",
      subtopic_name: "Went to school prepared?",
    },
    {
      id: "ED.14",
      shortname: "ed23-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don't know"],
      act: "ED23-ADOL1",
      depending: "",
      exception: "",
      link: "ED23-1-ADOL1",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED23-ADOL-CODE",
      subtopic_name: "Felt like you belonged in school?",
    },
    {
      id: "ED.14.1",
      extend: "true",
      shortname: "ed23-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "ED23-1-ADOL1",
      condition: "in",
      depending: "ED23-ADOL1",
      exception: "Ever;No",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED23-ADOL-CODE",
      subtopic_name: "Felt like you belonged in school?",
    },
    {
      id: "ED.14.2",
      extend: "true",
      shortname: "ed23-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED23-2-ADOL1",
      condition: "in",
      depending: "ED23-ADOL1",
      exception: "Ever;No",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED23-ADOL-CODE",
      subtopic_name: "Felt like you belonged in school?",
    },
    {
      id: "ED.14.3",
      extend: "true",
      shortname: "ed23-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED23-3-ADOL1",
      condition: "in",
      depending: "ED23-ADOL1",
      exception: "Ever;No",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED23-ADOL-CODE",
      subtopic_name: "Felt like you belonged in school?",
    },
    {
      id: "ED.14.3.c",
      shortname: "ed23-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "ED23-C-ADOL1",
      depending: "ED23-ADOL1",
      exception: "Ever;No",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED23-ADOL-CODE",
      subtopic_name: "Felt like you belonged in school?",
    },
    {
      id: "ED.15",
      shortname: "ed24-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes", "Don't know"],
      act: "ED24-ADOL1",
      depending: "",
      exception: "",
      link: "ED24-1-ADOL1",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED24-ADOL-CODE",
      subtopic_name: "Felt like you felt safe in school?",
    },
    {
      id: "ED.15.1",
      extend: "true",
      shortname: "ed24-1-adol1",
      name: "",
      prevalue: "Age this first occurred",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "ED24-1-ADOL1",
      condition: "in",
      depending: "ED24-ADOL1",
      exception: "Ever;No",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED24-ADOL-CODE",
      subtopic_name: "Felt like you felt safe in school?",
    },
    {
      id: "ED.15.2",
      extend: "true",
      shortname: "ed24-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED24-2-ADOL1",
      condition: "in",
      depending: "ED24-ADOL1",
      exception: "Ever;No",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED24-ADOL-CODE",
      subtopic_name: "Felt like you felt safe in school?",
    },
    {
      id: "ED.15.3",
      extend: "true",
      shortname: "ed24-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ED24-3-ADOL1",
      condition: "in",
      depending: "ED24-ADOL1",
      exception: "Ever;No",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED24-ADOL-CODE",
      subtopic_name: "Felt like you felt safe in school?",
    },
    {
      id: "ED.15.3.c",
      shortname: "ed24-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "ED24-C-ADOL1",
      depending: "ED24-ADOL1",
      exception: "Ever;No",
      subtopic_container_act: "ED15-ADOL-ACT",
      suptopic_code: "ED24-ADOL-CODE",
      subtopic_name: "Felt like you felt safe in school?",
    },
    {
      id: "ED.16",
      shortname: "ed25-adol1",
      name: "Approximately how many minutes do you typically spend doing homework or studying each day (in minutes)?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "number",
      act: "ED25-ADOL1",
      depending: "",
      exception: "",
    },
    {
      id: "ED.17",
      shortname: "ed5-add",
      name: "Do you have any Hobbies?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED5-ADD",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ED6-ADD",
    },
    {
      id: "ED.17.1",
      shortname: "ed6-add",
      name: "",
      prevalue: "If Yes please list",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED6-ADD",
      depending: "ED5-ADD",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "ED.18",
      shortname: "ed5-add1",
      name: "Are you involved in any community clubs or activities?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED5-ADD1",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ED6-ADD1",
    },
    {
      id: "ED.18.1",
      shortname: "ed6-add1",
      name: "",
      prevalue: "If Yes please explain.",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED6-ADD1",
      depending: "ED5-ADD1",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "ED.19",
      shortname: "ed6-adol1",
      name: "Are you currently employed?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED6-ADOL1",
      depending: "",
      exception: "",
      mandatory: "true",
      link: "ED6-ADOL1-1",
    },
    {
      id: "ED.19.1",
      shortname: "ed6-adol1-1",
      name: "",
      prevalue: "Where do you work?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED6-ADOL1-1",
      depending: "ED6-ADOL1",
      exception: "Yes",
      extend: "true",
      link: "ED6-ADOL1-2",
    },
    {
      id: "ED.19.2",
      shortname: "ed6-adol1-2",
      name: "",
      prevalue: "What is your position there?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED6-ADOL1-2",
      depending: "ED6-ADOL1",
      exception: "Yes",
      extend: "true",
      link: "ED6-ADOL1-3",
    },
    {
      id: "ED.19.3",
      shortname: "ed6-adol1-3",
      name: "",
      prevalue: "How many hours per week do you work?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED6-ADOL1-3",
      depending: "ED6-ADOL1",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "ED.20",
      shortname: "ed17",
      name: "Does anyone contribute to your support in any way?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED17",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ED18",
    },
    {
      id: "ED.20.1",
      shortname: "ed18",
      name: "",
      prevalue: "If yes, who helps support you and how?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED18",
      depending: "ED17",
      exception: "Yes",
      extend: "true",
      link: "ED19",
    },
    {
      id: "ED.20.2",
      shortname: "ed19",
      name: "",
      prevalue: "Does this constitute the majority of your support?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED19",
      depending: "ED17",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "ED.21",
      shortname: "ed21",
      name: "Do you have valid driver's license?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED21",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ED22",
    },
    {
      id: "ED.21.1",
      shortname: "ed22",
      name: "",
      prevalue: "Do you have an automobile available for you to use?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED22",
      depending: "ED21",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "ED.22",
      shortname: "ed26-adol1",
      name: "What are your high school plans?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Graduate with a diploma",
        "Graduate with a GED",
        "Do not plan to finish",
        "Undecided",
        "Unsure",
      ],
      act: "ED26-ADOL1",
      depending: "",
      exception: "",
    },
    {
      id: "ED.23",
      shortname: "ed27-adol1",
      name: "What are your future plans?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "College",
        "Trade school",
        "Work",
        "Military",
        "Undecided",
        "Other",
      ],
      act: "ED27-ADOL1",
      depending: "",
      exception: "",
      link: "ED27-1-ADOL1",
    },
    {
      id: "ED.23.1",
      extend: "true",
      shortname: "ed27-1-adol1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED27-1-ADOL1",
      condition: "in",
      depending: "ED27-ADOL1",
      exception: "Other",
    },
    {
      id: "ED.24",
      shortname: "ed32",
      name: "How many days in the past 30 days have you experienced any school or employment problems or issues?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "ED32",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ED-NEW52",
    },
    {
      id: "ED.24.1",
      shortname: "ed-new52",
      name: "",
      prevalue:
        "If any school or employment problems in the past 30 days, what is the nature of the problem?",
      value: "due to ",
      disabled: "true",
      type: "text",
      act: "ED-NEW52",
      mandatory: "true",
      depending: "ED32",
      exception: "0",
      extend: "true",
    },
    {
      id: "ED.25",
      shortname: "ed12-adol1",
      name: "Do you think you have any problems with education or school?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: ["", "No", "Maybe", "Definitely"],
      act: "ED12-ADOL1",
      depending: "",
      exception: "",
    },
    {
      id: "ED.26",
      shortname: "ed33",
      name: "How worried or bothered have you been about any school or employment problems or issues in the past 30 days?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "a little bit",
        "a lot",
      ],
      act: "ED33",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ED-NEW54",
    },
    {
      id: "ED.26.1",
      shortname: "ed-new54",
      name: "",
      prevalue:
        "Please explain the reason you are worried or bothered (if any)?",
      value: "due to ",
      disabled: "true",
      type: "text",
      act: "ED-NEW54",
      mandatory: "true",
      depending: "ED33",
      exception: "not at all",
      extend: "true",
    },
    {
      id: "ED.27",
      shortname: "ed34",
      name: "How important is it for you to receive counseling for any school or employment problems or issues?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "a little bit",
        "a lot",
      ],
      act: "ED34",
      depending: "",
      exception: "",
    },
    {
      id: "ED.28",
      shortname: "ed35c",
      access: "private",
      name: "Additional comments:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "editor",
      act: "ED35C",
      depending: "",
      exception: "",
    },
  ],
};

export default educationHistoryJSON;
