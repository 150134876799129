import { createStore } from "redux";
import { combineReducers } from "redux";
import evtreducer from "./evtreducer";
import hafreducer from "./hafreducer";
import dahreducer from "./dahreducer";
import transformrulereducer from "./transformrulereducer"

const combine = combineReducers({ hafreducer, evtreducer, dahreducer, transformrulereducer });
const store = createStore(combine);

export default store;
