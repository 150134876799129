const legalHistoryJSON =   {
  section: "LEG",
  topic: "Legal History",
  checked: "true",
  // access: "public",
  color: "secondary",
  description: "",
  body: [
    {
      id: "LE.1",
      shortname: "ls1",
      name: "Was this admission prompted or suggested by the criminal justice system?",
      prevalue: "Judge, probation/parole officer, etc.",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "LS1",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "LE.2",
      shortname: "le-new1",
      name: "Are you currently on Probation?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW1",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW2",
    },
    {
      id: "LE.2.1",
      shortname: "le-new2",
      name: "",
      prevalue: "Probation Officer's name",
      extend: "true",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE-NEW2",
      depending: "LE-NEW1",
      exception: "Yes",
      link: "LE-NEW3",
    },
    {
      id: "LE.2.2",
      shortname: "le-new3",
      name: "",
      prevalue: "Court/Jurisdiction",
      extend: "true",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE-NEW3",
      depending: "LE-NEW1",
      exception: "Yes",
    },
    {
      id: "LE.3",
      shortname: "le-new4",
      name: "Are you currently on Parole?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW4",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW5",
    },
    {
      id: "LE.3.1",
      shortname: "le-new5",
      name: "",
      prevalue: "Parole Officer's name",
      extend: "true",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE-NEW5",
      depending: "LE-NEW4",
      exception: "Yes",
      link: "LE-NEW6",
    },
    {
      id: "LE.3.2",
      shortname: "le-new6",
      name: "",
      prevalue: "Court/Jurisdiction",
      extend: "true",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE-NEW6",
      depending: "LE-NEW4",
      exception: "Yes",
    },

    {
      id: "LE.4",
      shortname: "le3-add",
      name: "Are you currently on Post-Release Supervision?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE3-ADD",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE31-ADD",
    },
    {
      id: "LE.4.1",
      shortname: "le31-add",
      name: "",
      prevalue: "Supervising Officer’s name",
      extend: "true",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE31-ADD",
      depending: "LE3-ADD",
      exception: "Yes",
      link: "LE32-ADD",
    },
    {
      id: "LE.4.2",
      shortname: "le32-add",
      name: "",
      prevalue: "Court/Jurisdiction",
      extend: "true",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE32-ADD",
      depending: "LE3-ADD",
      exception: "Yes",
    },

    {
      id: "",
      shortname: "le-new7",
      name:
        "Have you EVER been arrested, ticketed or charged with any of the following offenses? All of the items below have been defaulted to NO. If your answer is YES, please change NO to YES and answer the questions provided. ",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "LE-NEW7",
      depending: "",
      exception: "",
    },
    {
      id: "LE.5",
      shortname: "le-new8",
      name: "Arson",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW8",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW9",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_ARSON",
      subtopic_name: "Arson",
    },
    {
      id: "LE.5.1",
      extend: "true",
      shortname: "le-new9",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW9",
      depending: "LE-NEW8",
      exception: "Yes",
      link: "LE-NEW10",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_ARSON",
      subtopic_name: "Arson",
    },
    {
      id: "LE.5.2",
      extend: "true",
      shortname: "le-new10",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW10",
      depending: "LE-NEW8",
      exception: "Yes",
      link: "LE-NEW11",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_ARSON",
      subtopic_name: "Arson",
    },
    {
      id: "LE.5.3",
      extend: "true",
      shortname: "le-new11",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW11",
      depending: "LE-NEW8",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_ARSON",
      subtopic_name: "Arson",
    },
    {
      id: "LE.6",
      shortname: "le-new12",
      name: "Assault",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW12",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW13",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_ASSAULT",
      subtopic_name: "Assault",
    },
    {
      id: "LE.6.1",
      extend: "true",
      shortname: "le-new13",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW13",
      depending: "LE-NEW12",
      exception: "Yes",
      link: "LE-NEW14",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_ASSAULT",
      subtopic_name: "Assault",
    },
    {
      id: "LE.6.2",
      extend: "true",
      shortname: "le-new14",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW14",
      depending: "LE-NEW12",
      exception: "Yes",
      link: "LE-NEW15",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_ASSAULT",
      subtopic_name: "Assault",
    },
    {
      id: "LE.6.3",
      extend: "true",
      shortname: "le-new15",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW15",
      depending: "LE-NEW12",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_ASSAULT",
      subtopic_name: "Assault",
    },
    {
      id: "LE.7",
      shortname: "le-new16",
      name: "Burglary/Larceny/B&E",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW16",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW17",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_BURGLARY",
      subtopic_name: "Burglary/Larceny/B&E",
    },
    {
      id: "LE.7.1",
      extend: "true",
      shortname: "le-new17",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW17",
      depending: "LE-NEW16",
      exception: "Yes",
      link: "LE-NEW18",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_BURGLARY",
      subtopic_name: "Burglary/Larceny/B&E",
    },
    {
      id: "LE.7.2",
      extend: "true",
      shortname: "le-new18",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW18",
      depending: "LE-NEW16",
      exception: "Yes",
      link: "LE-NEW19",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_BURGLARY",
      subtopic_name: "Burglary/Larceny/B&E",
    },
    {
      id: "LE.7.3",
      extend: "true",
      shortname: "le-new19",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW19",
      depending: "LE-NEW16",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_BURGLARY",
      subtopic_name: "Burglary/Larceny/B&E",
    },
    {
      id: "LE.8",
      shortname: "le-new20",
      name: "Contempt of Court",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW20",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW21",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_CONTEMPT",
      subtopic_name: "Contempt of Court",
    },
    {
      id: "LE.8.1",
      extend: "true",
      shortname: "le-new21",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW21",
      depending: "LE-NEW20",
      exception: "Yes",
      link: "LE-NEW22",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_CONTEMPT",
      subtopic_name: "Contempt of Court",
    },
    {
      id: "LE.8.2",
      extend: "true",
      shortname: "le-new22",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW22",
      depending: "LE-NEW20",
      exception: "Yes",
      link: "LE-NEW23",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_CONTEMPT",
      subtopic_name: "Contempt of Court",
    },
    {
      id: "LE.8.3",
      extend: "true",
      shortname: "le-new23",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW23",
      depending: "LE-NEW20",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_CONTEMPT",
      subtopic_name: "Contempt of Court",
    },
    {
      id: "LE.9",
      shortname: "le-new24",
      name: "Disorderly Conduct",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW24",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW25",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_DISORDERLY",
      subtopic_name: "Disorderly Conduct",
    },
    {
      id: "LE.9.1",
      extend: "true",
      shortname: "le-new25",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW25",
      depending: "LE-NEW24",
      exception: "Yes",
      link: "LE-NEW26",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_DISORDERLY",
      subtopic_name: "Disorderly Conduct",
    },
    {
      id: "LE.9.2",
      extend: "true",
      shortname: "le-new26",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW26",
      depending: "LE-NEW24",
      exception: "Yes",
      link: "LE-NEW27",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_DISORDERLY",
      subtopic_name: "Disorderly Conduct",
    },
    {
      id: "LE.9.3",
      extend: "true",
      shortname: "le-new27",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW27",
      depending: "LE-NEW24",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_DISORDERLY",
      subtopic_name: "Disorderly Conduct",
    },
    {
      id: "LE.10",
      shortname: "le-new28",
      name: "Disturbing the peace",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW28",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW29",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_DISTURBANCE",
      subtopic_name: "Disturbing the peace",
    },
    {
      id: "LE.10.1",
      extend: "true",
      shortname: "le-new29",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW29",
      depending: "LE-NEW28",
      exception: "Yes",
      link: "LE-NEW30",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_DISTURBANCE",
      subtopic_name: "Disturbing the peace",
    },
    {
      id: "LE.10.2",
      extend: "true",
      shortname: "le-new30",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW30",
      depending: "LE-NEW28",
      exception: "Yes",
      link: "LE-NEW31",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_DISTURBANCE",
      subtopic_name: "Disturbing the peace",
    },
    {
      id: "LE.10.3",
      extend: "true",
      shortname: "le-new31",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW31",
      depending: "LE-NEW28",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_DISTURBANCE",
      subtopic_name: "Disturbing the peace",
    },
    {
      id: "LE.11",
      shortname: "le-new32",
      name: "Driving While Intoxicated",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW32",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW33",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_DWI",
      subtopic_name: "Driving While Intoxicated",
    },
    {
      id: "LE.11.1",
      extend: "true",
      shortname: "le-new33",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW33",
      depending: "LE-NEW32",
      exception: "Yes",
      link: "LE-NEW34",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_DWI",
      subtopic_name: "Driving While Intoxicated",
    },
    {
      id: "LE.11.2",
      extend: "true",
      shortname: "le-new34",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW34",
      depending: "LE-NEW32",
      exception: "Yes",
      link: "LE-NEW35",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_DWI",
      subtopic_name: "Driving While Intoxicated",
    },
    {
      id: "LE.11.3",
      extend: "true",
      shortname: "le-new35",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW35",
      depending: "LE-NEW32",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_DWI",
      subtopic_name: "Driving While Intoxicated",
    },
    {
      id: "LE.12",
      shortname: "le-new36",
      name: "Drug Charges",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW36",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW37",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_DRUGS",
      subtopic_name: "Drug Charges",
    },
    {
      id: "LE.12.1",
      extend: "true",
      shortname: "le-new37",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW37",
      depending: "LE-NEW36",
      exception: "Yes",
      link: "LE-NEW38",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_DRUGS",
      subtopic_name: "Drug Charges",
    },
    {
      id: "LE.12.2",
      extend: "true",
      shortname: "le-new38",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW38",
      depending: "LE-NEW36",
      exception: "Yes",
      link: "LE-NEW39",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_DRUGS",
      subtopic_name: "Drug Charges",
    },
    {
      id: "LE.12.3",
      extend: "true",
      shortname: "le-new39",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW39",
      depending: "LE-NEW36",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_DRUGS",
      subtopic_name: "Drug Charges",
    },
    {
      id: "LE.13",
      shortname: "le-new40",
      name: "Forgery",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW40",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW41",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_FORGERY",
      subtopic_name: "Forgery",
    },
    {
      id: "LE.13.1",
      extend: "true",
      shortname: "le-new41",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW41",
      depending: "LE-NEW40",
      exception: "Yes",
      link: "LE-NEW42",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_FORGERY",
      subtopic_name: "Forgery",
    },
    {
      id: "LE.13.2",
      extend: "true",
      shortname: "le-new42",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW42",
      depending: "LE-NEW40",
      exception: "Yes",
      link: "LE-NEW43",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_FORGERY",
      subtopic_name: "Forgery",
    },
    {
      id: "LE.13.3",
      extend: "true",
      shortname: "le-new43",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW43",
      depending: "LE-NEW40",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_FORGERY",
      subtopic_name: "Forgery",
    },
    {
      id: "LE.14",
      shortname: "le-new44",
      name: "Homicide/Manslaughter",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW44",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW45",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_HOMICIDE",
      subtopic_name: "Homicide/Manslaughter",
    },
    {
      id: "LE.14.1",
      extend: "true",
      shortname: "le-new45",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW45",
      depending: "LE-NEW44",
      exception: "Yes",
      link: "LE-NEW46",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_HOMICIDE",
      subtopic_name: "Homicide/Manslaughter",
    },
    {
      id: "LE.14.2",
      extend: "true",
      shortname: "le-new46",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW46",
      depending: "LE-NEW44",
      exception: "Yes",
      link: "LE-NEW47",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_HOMICIDE",
      subtopic_name: "Homicide/Manslaughter",
    },
    {
      id: "LE.14.3",
      extend: "true",
      shortname: "le-new47",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW47",
      depending: "LE-NEW44",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_HOMICIDE",
      subtopic_name: "Homicide/Manslaughter",
    },
    {
      id: "LE.15",
      shortname: "le-new48",
      name: "Major Driving Violation, including any moving violations(s)",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW48",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW49",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_MAJORDRIVING",
      subtopic_name:
        "Major Driving Violation, including any moving violations(s)",
    },
    {
      id: "LE.15.1",
      extend: "true",
      shortname: "le-new49",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW49",
      depending: "LE-NEW48",
      exception: "Yes",
      link: "LE-NEW50",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_MAJORDRIVING",
      subtopic_name:
        "Major Driving Violation, including any moving violations(s)",
    },
    {
      id: "LE.15.2",
      extend: "true",
      shortname: "le-new50",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW50",
      depending: "LE-NEW48",
      exception: "Yes",
      link: "LE-NEW51",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_MAJORDRIVING",
      subtopic_name:
        "Major Driving Violation, including any moving violations(s)",
    },
    {
      id: "LE.15.3",
      extend: "true",
      shortname: "le-new51",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW51",
      depending: "LE-NEW48",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_MAJORDRIVING",
      subtopic_name:
        "Major Driving Violation, including any moving violations(s)",
    },
    {
      id: "LE.16",
      shortname: "le-new52",
      name: "Minor in Possession",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW52",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW53",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_MINOR",
      subtopic_name: "Minor in Possession",
    },
    {
      id: "LE.16.1",
      extend: "true",
      shortname: "le-new53",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW53",
      depending: "LE-NEW52",
      exception: "Yes",
      link: "LE-NEW54",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_MINOR",
      subtopic_name: "Minor in Possession",
    },
    {
      id: "LE.16.2",
      extend: "true",
      shortname: "le-new54",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW54",
      depending: "LE-NEW52",
      exception: "Yes",
      link: "LE-NEW55",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_MINOR",
      subtopic_name: "Minor in Possession",
    },
    {
      id: "LE.16.3",
      extend: "true",
      shortname: "le-new55",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW55",
      depending: "LE-NEW52",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_MINOR",
      subtopic_name: "Minor in Possession",
    },
    {
      id: "LE.17",
      shortname: "le-new56",
      name: "Parole/Probation Violation",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW56",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW57",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_PAROLEVIOLATION",
      subtopic_name: "Parole/Probation Violation",
    },
    {
      id: "LE.17.1",
      extend: "true",
      shortname: "le-new57",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW57",
      depending: "LE-NEW56",
      exception: "Yes",
      link: "LE-NEW58",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_PAROLEVIOLATION",
      subtopic_name: "Parole/Probation Violation",
    },
    {
      id: "LE.17.2",
      extend: "true",
      shortname: "le-new58",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW58",
      depending: "LE-NEW56",
      exception: "Yes",
      link: "LE-NEW59",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_PAROLEVIOLATION",
      subtopic_name: "Parole/Probation Violation",
    },
    {
      id: "LE.17.3",
      extend: "true",
      shortname: "le-new59",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW59",
      depending: "LE-NEW56",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_PAROLEVIOLATION",
      subtopic_name: "Parole/Probation Violation",
    },
    {
      id: "LE.18",
      shortname: "le-new60",
      name: "Sex Worker",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW60",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW61",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_PROSTITUION",
      subtopic_name: "Sex Worker",
    },
    {
      id: "LE.18.1",
      extend: "true",
      shortname: "le-new61",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW61",
      depending: "LE-NEW60",
      exception: "Yes",
      link: "LE-NEW62",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_PROSTITUION",
      subtopic_name: "Sex Worker",
    },
    {
      id: "LE.18.2",
      extend: "true",
      shortname: "le-new62",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW62",
      depending: "LE-NEW60",
      exception: "Yes",
      link: "LE-NEW63",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_PROSTITUION",
      subtopic_name: "Sex Worker",
    },
    {
      id: "LE.18.3",
      extend: "true",
      shortname: "le-new63",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW63",
      depending: "LE-NEW60",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_PROSTITUION",
      subtopic_name: "Sex Worker",
    },
    {
      id: "LE.19",
      shortname: "le-new64",
      name: "Public Intoxication",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW64",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW65",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_PUBLICINTOXICATION",
      subtopic_name: "Public Intoxication",
    },
    {
      id: "LE.19.1",
      extend: "true",
      shortname: "le-new65",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW65",
      depending: "LE-NEW64",
      exception: "Yes",
      link: "LE-NEW66",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_PUBLICINTOXICATION",
      subtopic_name: "Public Intoxication",
    },
    {
      id: "LE.19.2",
      extend: "true",
      shortname: "le-new66",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW66",
      depending: "LE-NEW64",
      exception: "Yes",
      link: "LE-NEW67",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_PUBLICINTOXICATION",
      subtopic_name: "Public Intoxication",
    },
    {
      id: "LE.19.3",
      extend: "true",
      shortname: "le-new67",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW67",
      depending: "LE-NEW64",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_PUBLICINTOXICATION",
      subtopic_name: "Public Intoxication",
    },
    {
      id: "LE.20",
      shortname: "le-new68",
      name: "Rape or other sex related crime",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW68",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW69",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_RAPE",
      subtopic_name: "Rape or other sex related crime",
    },
    {
      id: "LE.20.1",
      extend: "true",
      shortname: "le-new69",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW69",
      depending: "LE-NEW68",
      exception: "Yes",
      link: "LE-NEW70",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_RAPE",
      subtopic_name: "Rape or other sex related crime",
    },
    {
      id: "LE.20.2",
      extend: "true",
      shortname: "le-new70",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW70",
      depending: "LE-NEW68",
      exception: "Yes",
      link: "LE-NEW71",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_RAPE",
      subtopic_name: "Rape or other sex related crime",
    },
    {
      id: "LE.20.3",
      extend: "true",
      shortname: "le-new71",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW71",
      depending: "LE-NEW68",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_RAPE",
      subtopic_name: "Rape or other sex related crime",
    },
    {
      id: "LE.21",
      shortname: "le-new72",
      name: "Robbery",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW72",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW73",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_ROBBERY",
      subtopic_name: "Robbery",
    },
    {
      id: "LE.21.1",
      extend: "true",
      shortname: "le-new73",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW73",
      depending: "LE-NEW72",
      exception: "Yes",
      link: "LE-NEW74",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_ROBBERY",
      subtopic_name: "Robbery",
    },
    {
      id: "LE.21.2",
      extend: "true",
      shortname: "le-new74",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW74",
      depending: "LE-NEW72",
      exception: "Yes",
      link: "LE-NEW75",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_ROBBERY",
      subtopic_name: "Robbery",
    },
    {
      id: "LE.21.3",
      extend: "true",
      shortname: "le-new75",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW75",
      depending: "LE-NEW72",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_ROBBERY",
      subtopic_name: "Robbery",
    },
    {
      id: "LE.22",
      shortname: "le-new76",
      name: "Shoplifting/Vandalism or Theft",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW76",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW77",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_THEFT",
      subtopic_name: "Shoplifting/Vandalism or Theft",
    },
    {
      id: "LE.22.1",
      extend: "true",
      shortname: "le-new77",
      name: "",
      prevalue: "If Yes, list what the offense was.",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE-NEW77",
      depending: "LE-NEW76",
      exception: "Yes",
      link: "LE-NEW78",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_THEFT",
      subtopic_name: "Shoplifting/Vandalism or Theft",
    },
    {
      id: "LE.22.2",
      extend: "true",
      shortname: "le-new78",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW78",
      depending: "LE-NEW76",
      exception: "Yes",
      link: "LE-NEW79",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_THEFT",
      subtopic_name: "Shoplifting/Vandalism or Theft",
    },
    {
      id: "LE.22.3",
      extend: "true",
      shortname: "le-new79",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW79",
      depending: "LE-NEW76",
      exception: "Yes",
      link: "LE-NEW80",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_THEFT",
      subtopic_name: "Shoplifting/Vandalism or Theft",
    },
    {
      id: "LE.22.4",
      extend: "true",
      shortname: "le-new80",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW80",
      depending: "LE-NEW76",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_THEFT",
      subtopic_name: "Shoplifting/Vandalism or Theft",
    },
    {
      id: "LE.23",
      shortname: "le-new81",
      name: "Vagrancy",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW81",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW82",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_VAGRANCY",
      subtopic_name: "Vagrancy",
    },
    {
      id: "LE.23.1",
      extend: "true",
      shortname: "le-new82",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW82",
      depending: "LE-NEW81",
      exception: "Yes",
      link: "LE-NEW83",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_VAGRANCY",
      subtopic_name: "Vagrancy",
    },
    {
      id: "LE.23.2",
      extend: "true",
      shortname: "le-new83",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW83",
      depending: "LE-NEW81",
      exception: "Yes",
      link: "LE-NEW84",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_VAGRANCY",
      subtopic_name: "Vagrancy",
    },
    {
      id: "LE.23.3",
      extend: "true",
      shortname: "le-new84",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW84",
      depending: "LE-NEW81",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_VAGRANCY",
      subtopic_name: "Vagrancy",
    },
    {
      id: "LE.24",
      shortname: "le-new85",
      name: "Weapons offense",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW85",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW86",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_WEAPONS",
      subtopic_name: "Weapons offense",
    },
    {
      id: "LE.24.1",
      extend: "true",
      shortname: "le-new86",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW86",
      depending: "LE-NEW85",
      exception: "Yes",
      link: "LE-NEW87",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_WEAPONS",
      subtopic_name: "Weapons offense",
    },
    {
      id: "LE.24.2",
      extend: "true",
      shortname: "le-new87",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW87",
      depending: "LE-NEW85",
      exception: "Yes",
      link: "LE-NEW88",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_WEAPONS",
      subtopic_name: "Weapons offense",
    },
    {
      id: "LE.24.3",
      extend: "true",
      shortname: "le-new88",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW88",
      depending: "LE-NEW85",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_WEAPONS",
      subtopic_name: "Weapons offense",
    },
    {
      id: "LE.25",
      shortname: "le-new89",
      name: "Other",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW89",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW90",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_OTHER",
      subtopic_name: "Other",
    },
    {
      id: "LE.25.1",
      extend: "true",
      shortname: "le-new90",
      name: "",
      prevalue: "List the other offense that was not listed above",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE-NEW90",
      depending: "LE-NEW89",
      exception: "Yes",
      link: "LE-NEW91",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_OTHER",
      subtopic_name: "Other",
    },
    {
      id: "LE.25.2",
      extend: "true",
      shortname: "le-new91",
      name: "",
      prevalue: "Number of times you have been arrested, ticketed or charged",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW91",
      depending: "LE-NEW89",
      exception: "Yes",
      link: "LE-NEW92",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_OTHER",
      subtopic_name: "Other",
    },
    {
      id: "LE.25.3",
      extend: "true",
      shortname: "le-new92",
      name: "",
      prevalue: "Number of convictions",
      value: "",
      disabled: "true",
      type: "number",
      act: "LE-NEW92",
      depending: "LE-NEW89",
      exception: "Yes",
      link: "LE-NEW93",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_OTHER",
      subtopic_name: "Other",
    },
    {
      id: "LE.25.4",
      extend: "true",
      shortname: "le-new93",
      name: "",
      prevalue: "Was alcohol or drugs involved with any of the offenses?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW93",
      depending: "LE-NEW89",
      exception: "Yes",
      subtopic_container_act: "LE-NEW7",
      suptopic_code: "LE-NEW7_OTHER",
      subtopic_name: "Other",
    },
    {
      id: "LE.26",
      shortname: "le-new94-0",
      name: "Have you EVER been incarcerated?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW94-0",
      mandatory: "true",
      link: "LE-NEW94",
      depending: "",
      exception: "",
    },
    {
      id: "LE.26.1",
      shortname: "le-new94",
      extend: "true",
      name: "",
      prevalue: "How long in total have you been incarcerated in your life?",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE-NEW94",
      depending: "LE-NEW94-0",
      exception: "Yes",
      link: "LE-NEW95",
    },
    {
      id: "LE.26.2",
      shortname: "le-new95",
      name: "",
      prevalue: "How long was your last incarceration?",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE-NEW95",
      depending: "LE-NEW94-0",
      exception: "Yes",
      extend: "true",
      link: "LE-NEW96",
    },
    {
      id: "LE.26.3",
      shortname: "le-new96",
      name: "",
      prevalue: "What was it for?",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE-NEW96",
      depending: "LE-NEW94-0",
      exception: "Yes",
      extend: "true",
      link: "LE-NEW99",
    },
    {
      id: "LE.26.4",
      shortname: "le-new99",
      name: "",
      prevalue:
        "How many days in the past 30 days where you detained or incarcerated?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "LE-NEW99",
      depending: "LE-NEW94-0",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "LE.27",
      shortname: "le-new97",
      name: "Are you presently awaiting charges, trial or sentencing?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW97",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW98",
    },
    {
      id: "LE.27.1",
      shortname: "le-new98",
      name: "",
      prevalue: "For what charge?",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE-NEW98",
      depending: "LE-NEW97",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "LE.28",
      shortname: "le-new100",
      name:
        "How many days in the past 30 days have you engaged in illegal activities for profit?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "LE-NEW100",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW100A",
    },
    {
      id: "LE.28.1",
      shortname: "le-new100a",
      name: "",
      prevalue: "What is the nature of this activity?",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE-NEW100A",
      depending: "LE-NEW100",
      exception: "0",
      extend: "true",
    },
    {
      id: "LE.29",
      shortname: "le-new101",
      name:
        "Are you currently involved in Juvenile or Family Court for a non-criminal matter?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW101",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW102",
    },
    {
      id: "LE.29.1",
      extend: "true",
      shortname: "le-new102",
      name: "",
      prevalue: "If yes, explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE-NEW102",
      depending: "LE-NEW101",
      exception: "Yes",
    },
    {
      id: "LE.30",
      shortname: "le-new103",
      name:
        "How worried or bothered have you been about any legal-related problems in the past 30 days?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "slightly",
        "moderately",
        "considerably",
        "extremely",
      ],
      act: "LE-NEW103",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW103-1",
    },
    {
      id: "LE.30.1",
      shortname: "le-new103-1",
      name: "",
      prevalue: "Please explain the reason you are worried or bothered?",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE-NEW103-1",
      depending: "LE-NEW103",
      exception: "not at all",
      extend: "true",
    },
    {
      id: "LE.31",
      shortname: "le-new104",
      name:
        "How important is it for you to receive additional legal help, more than you may already have?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "slightly",
        "moderately",
        "considerably",
        "extremely",
      ],
      act: "LE-NEW104",
      depending: "",
      exception: "",
    },
    {
      id: "LE.32.c",
      shortname: "le30c",
      access: "private",
      name: "Additional comments:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "editor",
      act: "LE30C",
      depending: "",
      exception: "",
    },
    {
      id: "LE.32.d",
      shortname: "le30d",
      access: "private",
      name: "INTERVIEWER SEVERITY RATING",
      prevalue: "How would you rate the patient’s need for legal services or counseling?",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "slightly",
        "moderately",
        "considerably",
        "extremely",
      ],
      act: "LE30D",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "",
      shortname: "ls31-ls32-subtopic",
      access: "private",
      name: "CONFIDENCE RATINGS. Is the above information significantly distorted by:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "LS31-LS32-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "LE.33",
      shortname: "ls31",
      access: "private",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "LS31",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS31-LS32-ACT",
      suptopic_code: "LS31-CODE",
      subtopic_name:
        "Client’s misrepresentation?",
    },
    {
      id: "LE.34",
      shortname: "ls32",
      access: "private",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "LS32",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS31-LS32-ACT",
      suptopic_code: "LS32-CODE",
      subtopic_name:
        "Client’s inability to understand?",
    },
  ],
}



export default legalHistoryJSON;
