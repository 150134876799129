const educationHistoryJSON =   {
  section: "EDO",
  topic: "Education, Employment and Military History",
  checked: "true",
  // access: "public",
  color: "secondary",
  description: "",
  body: [
    {
      id: "ED.1",
      shortname: "ed1",
      name: "What is the highest level of education you completed? ",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "less than 12 years of schooling",
        "GED",
        "high school diploma",
        "some college",
        "AA degree",
        "bachelor's degree",
        "master's degree",
        "advanced degree (PhD, MD, JD etc.)",
      ],
      act: "ED1",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ED-NEW2",
    },
    {
      id: "ED.1.1",
      shortname: "ed-new2",
      name: "",
      prevalue:
        "If less than 12 years explain (example currently in 12th grade or dropped out and reason you dropped out)",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED-NEW2",
      depending: "ED1",
      exception: "less than 12 years of schooling",
      extend: "true",
      link: "ED2",
    },
    {
      id: "ED.1.2",
      shortname: "ed2",
      name: "",
      prevalue: "What was the name of the last high school/middle school/elementary school you last attended?",
      value: "",
      disabled: "false",
      type: "text",
      act: "ED2",
      depending: "",
      exception: "",
      extend: "true",
      link: "ED3",
    },
    {
      id: "ED.1.3",
      shortname: "ed3",
      name: "",
      prevalue: "If you graduated or received your GED, what year?",
      value: "",
      disabled: "true",
      type: "year",
      act: "ED3",
      depending: "",
      exception: "",
      depending1: "ED1",
      exception1: "less than 12 years of schooling",
      extend: "true",
    },
    {
      id: "ED.2",
      shortname: "ed-new3",
      name: "Were you in special education?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED-NEW3",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ED-NEW4",
    },
    {
      id: "ED.2.1",
      shortname: "ed-new4",
      name: "",
      prevalue: "If yes, what was your disability in school?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED-NEW4",
      depending: "ED-NEW3",
      exception: "Yes",
      extend: "true",
    },

    {
      id: "ED.3",
      shortname: "ed4",
      name:
        "Please list any post high school training, college or university attended. List name, degree/major and year graduated? (Example USA University 2000 BS in History)",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "text",
      act: "ED4",
      depending: "",
      exception: "",
    },
    {
      id: "ED.4",
      shortname: "ed5",
      name: "Do you have any professional skills and/or trade?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED5",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ED6",
    },
    {
      id: "ED.4.1",
      shortname: "ed6",
      name: "",
      prevalue: "If yes, specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED6",
      depending: "ED5",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "ED.5",
      shortname: "ed5-add",
      name: "Do you have any Hobbies?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED5-ADD",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ED6-ADD",
    },
    {
      id: "ED.5.1",
      shortname: "ed6-add",
      name: "",
      prevalue: "If Yes please list",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED6-ADD",
      depending: "ED5-ADD",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "ED.6",
      shortname: "ed5-add1",
      name: "Are you involved in any community clubs or activities?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED5-ADD1",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ED6-ADD1",
    },
    {
      id: "ED.6.1",
      shortname: "ed6-add1",
      name: "",
      prevalue: "If Yes please explain.",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED6-ADD1",
      depending: "ED5-ADD1",
      exception: "Yes",
      extend: "true",
    },

    {
      id: "ED.7",
      shortname: "ed10",
      name:
        "What has been your usual employment status for the majority (18+ months) of the past 3 years?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "unemployed - not looking for work",
        "unemployed - looking for work",
        " employed full time",
        " employed part time",
        "self - employed",
        "student",
        "retired",
        "on disability",
      ],
      act: "ED10",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ED11A",
    },
    {
      id: "ED.7.1",
      shortname: "ed11a",
      name: "",
      prevalue: "How long was your longest full time employment?",
      placeholder: "Years",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      act: "ED11A",
      depending: "ED10",
      exception: " employed",
      link: "ED11A-NEW1",
      extend: "true",
    },
    {
      id: "ED.7.2",
      extend: "true",
      shortname: "ed11a-new1",
      name: "",
      prevalue: "",
      placeholder: "Months",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "ED11A-NEW1",
      depending: "ED10",
      exception: " employed",
      link: "ED11B",
    },
    {
      id: "ED.7.3",
      shortname: "ed11b",
      name: "",
      prevalue: "Where was this employment?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED11B",
      depending: "ED10",
      exception: " employed",
      extend: "true",
      link: "ED12",
    },
    {
      id: "ED.7.4",
      shortname: "ed12",
      name: "",
      prevalue: "What was your position or job title there?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED12",
      depending: "ED10",
      exception: " employed",
      extend: "true",
      link: "ED13",
    },
    {
      id: "ED.7.5",
      shortname: "ed13",
      name: "",
      prevalue:
        "What was your estimated gross income for the past calendar year? ",
      value: "",
      disabled: "true",
      type: "money",
      act: "ED13",
      depending: "ED10",
      exception: " employed",
      extend: "true",
    },
    {
      id: "ED.8",
      shortname: "ed7",
      name: "What is your current employment status? ",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "unemployed - not looking for work",
        "unemployed - looking for work",
        " employed full time",
        " employed part time",
        "self - employed",
        "student",
        "retired",
        "on disability",
      ],
      act: "ED7",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ED8",
    },
    {
      id: "ED.8.1",
      shortname: "ed8",
      name: "",
      prevalue: "Where are you currently employed?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED8",
      depending: "ED7",
      exception: " employed",
      extend: "true",
      link: "ED9",
    },
    {
      id: "ED.8.2",
      shortname: "ed9",
      name: "",
      prevalue: "What is your position or job title there?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED9",
      depending: "ED7",
      exception: " employed",
      extend: "true",
      link: "ED14",
    },
    {
      id: "ED.8.3",
      shortname: "ed14",
      name: "",
      prevalue:
        "How many days were you paid for working in the past 30 days?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "zero",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "ED14",
      depending: "ED7",
      exception: " employed",
      extend: "true",
      link: "ED-NEW19",
    },
    {
      id: "ED.8.4",
      shortname: "ed-new19",
      name: "",
      prevalue: "If hourly, what is your hourly rate of pay (per hour)?",
      value: "",
      disabled: "true",
      type: "money",
      act: "ED-NEW19",
      mandatory: "true",
      depending: "ED7",
      exception: " employed",
      depending1: "ED-NEW20",
      exception1: "",
      extend: "true",
      link: "ED-NEW20",
    },
    {
      id: "ED.8.5",
      shortname: "ed-new20",
      name: "",
      prevalue: "If salaried, what is your monthly gross pay (per month)?",
      value: "",
      disabled: "true",
      type: "money",
      act: "ED-NEW20",
      mandatory: "true",
      depending: "ED7",
      exception: " employed",
      depending1: "ED-NEW19",
      exception1: "",
      extend: "true",
      link: "ED15",
    },
    {
      id: "ED.8.6",
      shortname: "ed15",
      name: "",
      prevalue:
        "What was your net income/take home after all withholdings for the past 30 days? ",
      value: "",
      disabled: "true",
      type: "money",
      act: "ED15",
      depending: "ED7",
      exception: " employed",
      extend: "true",
    },
    {
      id: "ED.9",
      shortname: "ed-new22",
      name:
        "Have you received any money from any other sources in the past 30 days?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED-NEW22",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "ED.9.SUBTOPIC",
      shortname: "ed9-subtopic",
      name: "Please specify about the other sources of income",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "subtopic",
      act: "ED9-SUBTOPIC",
      depending: "ED-NEW22",
      exception: "Yes",
    },

    {
      id: "ED.9.1",
      shortname: "ed16a",
      name: "",
      prevalue: "Recieved?",
      value: "No",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED16A",
      mandatory: "true",
      depending: "ED-NEW22",
      exception: "Yes",
      link: "ED-NEW24",
      subtopic_container_act: "ED9-SUBTOPIC",
      suptopic_code: "ED9_FRIENDSFAMILY",
      subtopic_name: "Family, friends or significant other",
    },
    {
      id: "ED.9.1.1",
      extend: "true",
      shortname: "ed-new24",
      name: "",
      prevalue: "If yes, list who from?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED-NEW24",
      depending: "ED16A",
      exception: "Yes",
      link: "ED-NEW24A",
      subtopic_container_act: "ED9-SUBTOPIC",
      suptopic_code: "ED9_FRIENDSFAMILY",
      subtopic_name: "Family, friends or significant other",
    },
    {
      id: "ED.9.1.2",
      extend: "true",
      shortname: "ed-new24a",
      name: "",
      prevalue: "... and amount?",
      value: "",
      disabled: "true",
      type: "money",
      act: "ED-NEW24A",
      depending: "ED16A",
      exception: "Yes",
      subtopic_container_act: "ED9-SUBTOPIC",
      suptopic_code: "ED9_FRIENDSFAMILY",
      subtopic_name: "Family, friends or significant other",
    },
    {
      id: "ED.9.2",
      shortname: "ed16b",
      name: "Received?",
      prevalue: "",
      value: "No",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED16B",
      mandatory: "true",
      depending: "ED-NEW22",
      exception: "Yes",
      link: "ED-NEW26",
      subtopic_container_act: "ED9-SUBTOPIC",
      suptopic_code: "ED9_ILLEGAL",
      subtopic_name: "Illegal sources",
    },
    {
      id: "ED.9.2.1",
      extend: "true",
      shortname: "ed-new26",
      name: "",
      prevalue: "If yes, list activity ?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED-NEW26",
      depending: "ED16B",
      exception: "Yes",
      link: "ED-NEW26A",
      subtopic_container_act: "ED9-SUBTOPIC",
      suptopic_code: "ED9_ILLEGAL",
      subtopic_name: "Illegal sources",
    },
    {
      id: "ED.9.2.2",
      extend: "true",
      shortname: "ed-new26a",
      name: "",
      prevalue: "... and amount?",
      value: "",
      disabled: "true",
      type: "money",
      act: "ED-NEW26A",
      depending: "ED16B",
      exception: "Yes",
      subtopic_container_act: "ED9-SUBTOPIC",
      suptopic_code: "ED9_ILLEGAL",
      subtopic_name: "Illegal sources",
    },

    {
      id: "ED.9.3",
      shortname: "ed16c",
      name: "Received?",
      prevalue: "",
      value: "No",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED16C",
      mandatory: "true",
      depending: "ED-NEW22",
      exception: "Yes",
      link: "ED-NEW28",
      subtopic_container_act: "ED9-SUBTOPIC",
      suptopic_code: "ED9_SOCIAL",
      subtopic_name: "Social Security, pension, or disability benefits",
    },
    {
      id: "ED.9.3.1",
      extend: "true",
      shortname: "ed-new28",
      name: "",
      prevalue: "If yes, identify source ?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED-NEW28",
      depending: "ED16C",
      exception: "Yes",
      link: "ED-NEW28A",
      subtopic_container_act: "ED9-SUBTOPIC",
      suptopic_code: "ED9_SOCIAL",
      subtopic_name: "Social Security, pension, or disability benefits",
    },
    {
      id: "ED.9.3.2",
      extend: "true",
      shortname: "ed-new28a",
      name: "",
      prevalue: "... and amount received?",
      value: "",
      disabled: "true",
      type: "money",
      act: "ED-NEW28A",
      depending: "ED16C",
      exception: "Yes",
      subtopic_container_act: "ED9-SUBTOPIC",
      suptopic_code: "ED9_SOCIAL",
      subtopic_name: "Social Security, pension, or disability benefits",
    },
    {
      id: "ED.9.4",
      shortname: "ed16d",
      name: "Received?",
      prevalue: "",
      value: "No",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED16D",
      mandatory: "true",
      depending: "ED-NEW22",
      exception: "Yes",
      link: "ED-NEW29",
      subtopic_container_act: "ED9-SUBTOPIC",
      suptopic_code: "ED9_UNEMPLOYMENT",
      subtopic_name: "Unemployment Compensation",
    },
    {
      id: "ED.9.4.1",
      extend: "true",
      shortname: "ed-new29",
      name: "",
      prevalue: "If yes, identify source ?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED-NEW29",
      depending: "ED16D",
      exception: "Yes",
      link: "ED-NEW29A",
      subtopic_container_act: "ED9-SUBTOPIC",
      suptopic_code: "ED9_UNEMPLOYMENT",
      subtopic_name: "Unemployment Compensation",
    },
    {
      id: "ED.9.4.2",
      extend: "true",
      shortname: "ed-new29a",
      name: "",
      prevalue: "... and amount received?",
      value: "",
      disabled: "true",
      type: "money",
      act: "ED-NEW29A",
      depending: "ED16D",
      exception: "Yes",
      subtopic_container_act: "ED9-SUBTOPIC",
      suptopic_code: "ED9_UNEMPLOYMENT",
      subtopic_name: "Unemployment Compensation",
    },
    {
      id: "ED.9.5",
      shortname: "ed16e",
      name: "Received?",
      prevalue: "",
      value: "No",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED16E",
      mandatory: "true",
      depending: "ED-NEW22",
      exception: "Yes",
      link: "ED-NEW31",
      subtopic_container_act: "ED9-SUBTOPIC",
      suptopic_code: "ED9_WELFARE",
      subtopic_name: "Welfare, food stamps or other public assistance",
    },
    {
      id: "ED.9.5.1",
      extend: "true",
      shortname: "ed-new31",
      name: "",
      prevalue: "If yes, identify source ?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED-NEW31",
      depending: "ED16E",
      exception: "Yes",
      link: "ED-NEW31A",
      subtopic_container_act: "ED9-SUBTOPIC",
      suptopic_code: "ED9_WELFARE",
      subtopic_name: "Welfare, food stamps or other public assistance",
    },
    {
      id: "ED.9.5.2",
      extend: "true",
      shortname: "ed-new31a",
      name: "",
      prevalue: "... and amount received?",
      value: "",
      disabled: "true",
      type: "money",
      act: "ED-NEW31A",
      depending: "ED16E",
      exception: "Yes",
      subtopic_container_act: "ED9-SUBTOPIC",
      suptopic_code: "ED9_WELFARE",
      subtopic_name: "Welfare, food stamps or other public assistance",
    },
    {
      id: "ED.9.6",
      shortname: "ed-new32",
      name: "Received?",
      prevalue: "",
      value: "No",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED-NEW32",
      mandatory: "true",
      depending: "ED-NEW22",
      exception: "Yes",
      link: "ED-NEW33",
      subtopic_container_act: "ED9-SUBTOPIC",
      suptopic_code: "ED9_OTHER",
      subtopic_name: "Other sources of income",
    },
    {
      id: "ED.9.6.1",
      extend: "true",
      shortname: "ed-new33",
      name: "",
      prevalue: "If yes, list from who or type ?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED-NEW33",
      depending: "ED-NEW32",
      exception: "Yes",
      link: "ED-NEW33A",
      subtopic_container_act: "ED9-SUBTOPIC",
      suptopic_code: "ED9_OTHER",
      subtopic_name: "Other sources of income",
    },
    {
      id: "ED.9.6.2",
      extend: "true",
      shortname: "ed-new33a",
      name: "",
      prevalue: "... and amount?",
      value: "",
      disabled: "true",
      type: "money",
      act: "ED-NEW33A",
      depending: "ED-NEW32",
      exception: "Yes",
      subtopic_container_act: "ED9-SUBTOPIC",
      suptopic_code: "ED9_OTHER",
      subtopic_name: "Other sources of income",
    },
    {
      id: "ED.10",
      shortname: "ed17",
      name: "Does anyone contribute to your support in any way?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED17",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ED18",
    },
    {
      id: "ED.10.1",
      shortname: "ed18",
      name: "",
      prevalue: "If yes, who helps support you and how?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED18",
      depending: "ED17",
      exception: "Yes",
      extend: "true",
      link: "ED19",
    },
    {
      id: "ED.10.2",
      shortname: "ed19",
      name: "",
      prevalue: "Does this constitute the majority of your support?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED19",
      depending: "ED17",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "ED.11",
      shortname: "ed20",
      name:
        "Not including yourself, how many people depend on you for the majority of their food, shelter, etc.?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "ED20",
      depending: "",
      exception: "",
    },
    {
      id: "ED.12",
      shortname: "ed21",
      name: "Do you have valid driver's license?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED21",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ED22",
    },
    {
      id: "ED.12.1",
      shortname: "ed22",
      name: "",
      prevalue: "Do you have an automobile available for you to use?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED22",
      depending: "ED21",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "ED.13",
      shortname: "ed23",
      name: "Are you or were you a member of the Armed Forces?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED23",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ED24",
    },
    {
      id: "ED.13.1",
      shortname: "ed24",
      name: "",
      prevalue: "If yes, specify Branch?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Army",
        "Navy",
        "Air Force",
        "Marine Corp",
        "Coast Guard",
      ],
      act: "ED24",
      depending: "ED23",
      exception: "Yes",
      extend: "true",
      link: "ED25",
    },
    {
      id: "ED.13.2",
      shortname: "ed25",
      name: "",
      prevalue: "Length of service in years and months?",
      placeholder: "Years",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      act: "ED25",
      depending: "ED23",
      exception: "Yes",
      link: "ED25-NEW1",
      extend: "true",
    },
    {
      id: "ED.13.3",
      extend: "true",
      shortname: "ed25-new1",
      name: "",
      placeholder: "Months",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "ED25-NEW1",
      depending: "ED23",
      exception: "Yes",
      link: "ED26",
    },
    {
      id: "ED.13.4",
      shortname: "ed26",
      name: "",
      prevalue: "Date discharged or retired (YYYY)?",
      value: "",
      disabled: "true",
      type: "year",
      act: "ED26",
      depending: "ED23",
      exception: "Yes",
      extend: "true",
      link: "ED27",
    },
    {
      id: "ED.13.5",
      shortname: "ed27",
      name: "",
      prevalue: "Type of discharged",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "honorable",
        "general (under honorable conditions)",
        "other than honorable",
        "bad conduct",
        "dishonorable",
      ],
      act: "ED27",
      depending: "ED23",
      exception: "Yes",
      extend: "true",
      link: "ED28",
    },
    {
      id: "ED.13.6",
      shortname: "ed28",
      name: "",
      prevalue: "Were you incarcerated while in the Armed Forces?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED28",
      mandatory: "true",
      depending: "ED23",
      exception: "Yes",
      extend: "true",
      link: "ED29",
    },
    {
      id: "ED.13.6.1",
      shortname: "ed29",
      name: "",
      prevalue: "If Yes what was the charge or offense?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED29",
      depending: "ED28",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "ED.13.7",
      shortname: "ed30",
      name:
        "Are you currently under treatment for any diseases or conditions incurred during military service?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "ED30",
      mandatory: "true",
      depending: "ED23",
      exception: "Yes",
      link: "ED31A",
    },
    {
      id: "ED.13.7.1",
      shortname: "ed31a",
      name: "",
      prevalue: "If yes, please indicate the type of disease or condition?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED31A",
      depending: "ED30",
      exception: "Yes",
      extend: "true",
      link: "ED31B",
    },
    {
      id: "ED.13.7.2",
      shortname: "ed31b",
      name: "",
      prevalue: "What is the type of treatment?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED31B",
      depending: "ED30",
      exception: "Yes",
      extend: "true",
      link: "ED31C",
    },
    {
      id: "ED.13.7.3",
      shortname: "ed31c",
      name: "",
      prevalue: "Who is treating you and where?",
      value: "",
      disabled: "true",
      type: "text",
      act: "ED31C",
      depending: "ED30",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "ED.14",
      shortname: "ed32",
      name:
        "How many days in the past 30 days have you experienced any school or employment problems or issues?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "ED32",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ED-NEW52",
    },
    {
      id: "ED.14.1",
      shortname: "ed-new52",
      name: "",
      prevalue:
        "If any school or employment problems in the past 30 days, what is the nature of the problem?",
      value: "due to ",
      // value: "",
      disabled: "true",
      type: "text",
      act: "ED-NEW52",
      mandatory: "true",
      depending: "ED32",
      exception: "0",
      extend: "true",
    },
    {
      id: "ED.15",
      shortname: "ed33",
      name:
        "How worried or bothered have you been about any school or employment problems or issues in the past 30 days?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "slightly",
        "moderately",
        "considerably",
        "extremely",
      ],
      act: "ED33",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "ED-NEW54",
    },
    {
      id: "ED.15.1",
      shortname: "ed-new54",
      name: "",
      prevalue:
        "Please explain the reason you are worried or bothered (if any)?",
      value: "due to ",
      disabled: "true",
      type: "text",
      act: "ED-NEW54",
      mandatory: "true",
      depending: "ED33",
      exception: "not at all",
      extend: "true",
    },
    {
      id: "ED.16",
      shortname: "ed34",
      name:
        "How important is it for you to receive counseling for any school or employment problems or issues?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "slightly",
        "moderately",
        "considerably",
        "extremely",
      ],
      act: "ED34",
      depending: "",
      exception: "",
    },
    {
      id: "ED.17.c",
      shortname: "ed35c",
      access: "private",
      name: "Additional comments:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "editor",
      act: "ED35C",
      depending: "",
      exception: "",
    },
    {
      id: "ED.17.b",
      shortname: "ed35b",
      access: "private",
      name: "INTERVIEWER SEVERITY RATING",
      prevalue: "How would you rate the patient’s need for employment counseling?",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "slightly",
        "moderately",
        "considerably",
        "extremely",
      ],
      act: "ED35B",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "",
      shortname: "es23-es24-subtopic",
      access: "private",
      name: "CONFIDENCE RATINGS. Is the above information significantly distorted by:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "ES23-ES24-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "ED.18",
      shortname: "es23",
      access: "private",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ES23",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "ES23-ES24-ACT",
      suptopic_code: "ES23-CODE",
      subtopic_name:
        "Client's misrepresentation?",
    },
    {
      id: "ED.19",
      shortname: "es24",
      access: "private",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "ES24",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "ES23-ES24-ACT",
      suptopic_code: "ES24-CODE",
      subtopic_name:
        "Client’s inability to understand?",
    },
  ],
}

export default educationHistoryJSON;
