const summaryRecomendationsJSON = {
  section: "SUM",
  topic: "Diagnosis/Summary/Recommendations",
  checked: "true",
  // access: "private",
  color: "secondary",
  description: "",
  body: [
    {
      id: "",
      access: "private",
      shortname: "sum0",
      name: "Diagnostic Impression. ",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "SUM0",
      depending: "",
      exception: "",
      special_rendering: "full_width_subtopics",
    },
    {
      id: "SUM.1.Pri",
      shortname: "sum-pri1",
      name: "",
      prevalue: "Code",
      value: "",
      disabled: "false",
      type: "withcode",
      act: "SUM-PRI1",
      acttitle:"SUM-PRI2",
      depending: "",
      exception: "",
      link: "SUM-PRI2",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_Primary",
      subtopic_name: "Primary Diagnosis",
      special_rendering: "code",
    },
    //47
    {
      id: "SUM.2.Pri",
      extend: "true",
      shortname: "sum-pri2",
      name: "",
      prevalue: "Description",
      value: "",
      disabled: "false",
      type: "withtitle",
      act: "SUM-PRI2",
      actcode:"SUM-PRI1",
      depending: "",
      exception: "",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_Primary",
      subtopic_name: "Primary Diagnosis",
    },
    //========================================================================================
    /*                                                                                      *
     *                                 Additional Diagnosis:                                *
     *                                                                                      */
    //========================================================================================
    {
      id: "SUM.1.Add",
      shortname: "sum-add1",
      name: "",
      prevalue: "Code",
      value: "",
      disabled: "false",
      type: "withcode",
      act: "SUM-ADD1",
      acttitle:"SUM-ADD2",
      depending: "",
      exception: "",
      link: "SUM-ADD2",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL1",
      subtopic_name: "Additional Diagnosis",
      special_rendering: "code",
    },
    {
      id: "SUM.2.Add",
      extend: "true",
      shortname: "sum-add2",
      name: "",
      prevalue: "Description",
      value: "",
      disabled: "false",
      type: "withtitle",
      act: "SUM-ADD2",
      actcode:"SUM-ADD1",
      depending: "",
      exception: "",
      link: "SUM-ADD3",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL1",
      subtopic_name: "Additional Diagnosis",
    },
    //----------------------------------------------------
    {
      id: "SUM.3.Add",
      extend: "true",
      shortname: "sum-add3",
      name: "",
      prevalue: "Code",
      value: "",
      disabled: "false",
      type: "withcode",
      act: "SUM-ADD3",
      acttitle:"SUM-ADD4",
      depending: "",
      exception: "",
      link: "SUM-ADD4",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL2",
      subtopic_name: "",
      special_rendering: "code",
    },
    {
      id: "SUM.4.Add",
      extend: "true",
      shortname: "sum-add4",
      name: "",
      prevalue: "Description",
      value: "",
      disabled: "false",
      type: "withtitle",
      act: "SUM-ADD4",
      actcode:"SUM-ADD3",
      depending: "",
      exception: "",
      link: "SUM-ADD5",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL2",
      subtopic_name: "",
    },
    //----------------------------------------------------
    {
      id: "SUM.5.Add",
      extend: "true",
      shortname: "sum-add5",
      name: "",
      prevalue: "Code",
      value: "",
      disabled: "false",
      type: "withcode",
      act: "SUM-ADD5",
      acttitle:"SUM-ADD6",
      depending: "",
      exception: "",
      link: "SUM-ADD6",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL3",
      subtopic_name: "",
      special_rendering: "code",
    },
    {
      id: "SUM.6.Add",
      extend: "true",
      shortname: "sum-add6",
      name: "",
      prevalue: "Description",
      value: "",
      disabled: "false",
      type: "withtitle",
      act: "SUM-ADD6",
      actcode:"SUM-ADD5",
      depending: "",
      exception: "",
      link: "SUM-ADD7",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL3",
      subtopic_name: "",
    },
    //----------------------------------------------------
    {
      id: "SUM.7.Add",
      extend: "true",
      shortname: "sum-add7",
      name: "",
      prevalue: "Code",
      value: "",
      disabled: "false",
      type: "withcode",
      act: "SUM-ADD7",
      acttitle:"SUM-ADD8",
      depending: "",
      exception: "",
      link: "SUM-ADD8",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL4",
      subtopic_name: "",
      special_rendering: "code",
    },
    {
      id: "SUM.8.Add",
      extend: "true",
      shortname: "sum-add8",
      name: "",
      prevalue: "Description",
      value: "",
      disabled: "false",
      type: "withtitle",
      act: "SUM-ADD8",
      actcode:"SUM-ADD7",
      depending: "",
      exception: "",
      link: "SUM-ADD9",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL4",
      subtopic_name: "",
    },
    //----------------------------------------------------
    {
      id: "SUM.9.Add",
      extend: "true",
      shortname: "sum-add9",
      name: "",
      prevalue: "Code",
      value: "",
      disabled: "true",
      type: "withcode",
      act: "SUM-ADD9",
      acttitle:"SUM-ADD10",
      depending: "SUM-ADD8",
      exception: "",
      link: "SUM-ADD10",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL5",
      subtopic_name: "",
      special_rendering: "code",
    },
    {
      id: "SUM.10.Add",
      extend: "true",
      shortname: "sum-add10",
      name: "",
      prevalue: "Description",
      value: "",
      disabled: "true",
      type: "withtitle",
      act: "SUM-ADD10",
      actcode:"SUM-ADD9",
      depending: "SUM-ADD8",
      exception: "",
      link: "SUM-ADD11",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL5",
      subtopic_name: "",
    },
    //----------------------------------------------------
    {
      id: "SUM.11.Add",
      extend: "true",
      shortname: "sum-add11",
      name: "",
      prevalue: "Code",
      value: "",
      disabled: "true",
      type: "withcode",
      act: "SUM-ADD11",
      acttitle:"SUM-ADD12",
      depending: "SUM-ADD10",
      exception: "",
      link: "SUM-ADD12",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL6",
      subtopic_name: "",
      special_rendering: "code",
    },
    {
      id: "SUM.12.Add",
      extend: "true",
      shortname: "sum-add12",
      name: "",
      prevalue: "Description",
      value: "",
      disabled: "true",
      type: "withtitle",
      act: "SUM-ADD12",
      actcode:"SUM-ADD11",
      depending: "SUM-ADD10",
      exception: "",
      link: "SUM-ADD13",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL6",
      subtopic_name: "",
    },
    //----------------------------------------------------
    {
      id: "SUM.13.Add",
      extend: "true",
      shortname: "sum-add13",
      name: "",
      prevalue: "Code",
      value: "",
      disabled: "true",
      type: "withcode",
      act: "SUM-ADD13",
      acttitle:"SUM-ADD14",
      depending: "SUM-ADD12",
      exception: "",
      link: "SUM-ADD14",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL7",
      subtopic_name: "",
      special_rendering: "code",
    },
    {
      id: "SUM.14.Add",
      extend: "true",
      shortname: "sum-add14",
      name: "",
      prevalue: "Description",
      value: "",
      disabled: "true",
      type: "withtitle",
      act: "SUM-ADD14",
      actcode:"SUM-ADD13",
      depending: "SUM-ADD12",
      exception: "",
      link: "SUM-ADD15",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL7",
      subtopic_name: "",
    },
    //----------------------------------------------------
    {
      id: "SUM.15.Add",
      extend: "true",
      shortname: "sum-add15",
      name: "",
      prevalue: "Code",
      value: "",
      disabled: "true",
      type: "withcode",
      act: "SUM-ADD15",
      acttitle:"SUM-ADD16",
      depending: "SUM-ADD14",
      exception: "",
      link: "SUM-ADD16",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL8",
      subtopic_name: "",
      special_rendering: "code",
    },
    {
      id: "SUM.16.Add",
      extend: "true",
      shortname: "sum-add16",
      name: "",
      prevalue: "Description",
      value: "",
      disabled: "true",
      type: "withtitle",
      act: "SUM-ADD16",
      actcode:"SUM-ADD15",
      depending: "SUM-ADD14",
      exception: "",
      link: "SUM-ADD17",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL8",
      subtopic_name: "",
    },
    //----------------------------------------------------
    {
      id: "SUM.17.Add",
      extend: "true",
      shortname: "sum-add17",
      name: "",
      prevalue: "Code",
      value: "",
      disabled: "true",
      type: "withcode",
      act: "SUM-ADD17",
      acttitle:"SUM-ADD18",
      depending: "SUM-ADD16",
      exception: "",
      link: "SUM-ADD18",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL9",
      subtopic_name: "",
      special_rendering: "code",
    },
    {
      id: "SUM.18.Add",
      extend: "true",
      shortname: "sum-add18",
      name: "",
      prevalue: "Description",
      value: "",
      disabled: "true",
      type: "withtitle",
      act: "SUM-ADD18",
      actcode:"SUM-ADD17",
      depending: "SUM-ADD16",
      exception: "",
      link: "SUM-ADD19",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL9",
      subtopic_name: "",
    },
    //----------------------------------------------------
    {
      id: "SUM.19.Add",
      extend: "true",
      shortname: "sum-add19",
      name: "",
      prevalue: "Code",
      value: "",
      disabled: "true",
      type: "withcode",
      act: "SUM-ADD19",
      acttitle:"SUM-ADD20",
      depending: "SUM-ADD18",
      exception: "",
      link: "SUM-ADD20",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL10",
      subtopic_name: "",
      special_rendering: "code",
    },
    {
      id: "SUM.20.Add",
      extend: "true",
      shortname: "sum-add20",
      name: "",
      prevalue: "Description",
      value: "",
      disabled: "true",
      type: "withtitle",
      act: "SUM-ADD20",
      actcode:"SUM-ADD19",
      depending: "SUM-ADD18",
      exception: "",
      subtopic_container_act: "SUM0",
      suptopic_code: "SUM0_ADDITIONAL10",
      subtopic_name: "",
    },

    //========================================================================================
    /*                                                                                      *
     *                                        Ration                                        *
     *                                                                                      */
    //========================================================================================

    {
      id: "SUM.1.Ration",
      shortname: "sum01-rat",
      name: "Summary and Rationale",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "editor",
      act: "SUM01-RAT",
      depending: "",
      exception: "",
    },
    //========================================================================================
    /*                                                                                      *
     *                                    Recommendations                                   *
     *                                                                                      */
    //========================================================================================

    {
      id: "SUM.3",
      shortname: "sum00",
      name: "Recommendations",
      prevalue: "",
      value: [],
      disabled: "false",
      type: "checkbox",
      radio_options: [],
      act: "SUM00",
      depending: "",
      exception: "",
    },
    //========================================================================================
    /*                                                                                      *
     *                                  Field for counselor                                 *
     *                                                                                      */
    //========================================================================================
    // {
    //   id: "SUM.4.rule",
    //   shortname: "sum04rule",
    //   name: "General recommendations",
    //   prevalue: "Rules",
    //   value: [],
    //   disabled: "true",
    //   type: "checkbox",
    //   radio_options: [],
    //   act: "SUM04RULE",
    //   depending: "",
    //   exception: "",
    //   link :"SUM04BUT"
    // },
    // {
    //   id: "SUM.4.but",
    //   shortname: "sum04but",
    //   access: "private",
    //   name: "",
    //   prevalue: "Please press button to get result",
    //   buttonname : "Run Rule",
    //   value: "",
    //   disabled: "false",
    //   type: "button",
    //   act: "SUM04BUT",
    //   depending: "",
    //   exception: "",
    //   link :"SUM04RES",
    //   extend: "true",
    // },
    // {
    //   id: "SUM.4.result",
    //   shortname: "sum04res",
    //   name: "",
    //   prevalue: "Suggested Recomendation",
    //   value: [],
    //   disabled: "false",
    //   type: "checkbox",
    //   radio_options: [],
    //   act: "SUM04RES",
    //   depending: "",
    //   exception: "",
    //   link :"SUM04GEN",
    //   extend: "true",
    // },

    // {
    //   id: "SUM.4.c",
    //   shortname: "sum04gen",
    //   access: "private",
    //   name: "",
    //   prevalue: "Result",
    //   value: "",
    //   disabled: "false",
    //   type: "editor",
    //   act: "SUM04GEN",
    //   // mandatory: "true",
    //   depending: "",
    //   exception: "",
    //   extend: "true",
    // },
    {
      id: "SUM.5.c",
      shortname: "sum02c",
      access: "private",
      name: "Additional recommendations:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "editor",
      act: "SUM02C",
      // mandatory: "true",
      depending: "",
      exception: "",
    },
  ],
};

export default summaryRecomendationsJSON;
