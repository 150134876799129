/*
import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Email App
const TheEmailApp = React.lazy(() => import('./views/apps/email/TheEmailApp'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/apps/email" name="Email App" render={props => <TheEmailApp {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
*/

// import { hot } from 'react-hot-loader/root';
import React, { Component } from "react";
import store from "./store";
import { Provider } from "react-redux";
import { HashRouter, Route, Switch } from "react-router-dom";
import ErrorBoundry from "../src/components/error-boundry"
import ErrorToken from "../src/components/error-token"
import { ContextMenuTrigger } from "react-contextmenu";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './scss/style.scss';
import './App.scss';
import './index.css';
// import './App.css';

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);
// const DefaultLayout = React.lazy(() =>
//   import("./components/Layout/DefaultLayout")
// );

const TheLayout = React.lazy(() => import('./containers/TheLayout'));

const Login = React.lazy(() => import("./components/pages/Login"));
const Register = React.lazy(() => import("./components/pages/Register"));
const Forgetpassword = React.lazy(() => import("./components/pages/Forgetpassword"));
const Page404 = React.lazy(() => import("./components/pages/Page404"));
const Page500 = React.lazy(() => import("./components/pages/Page500"));
const RemoteSession = React.lazy(() => import("./components/pages/RemoteSession"));
const RemoteExit = React.lazy(() => import("./components/pages/RemoteExit"));
const PaymentSuccess = React.lazy(() => import("./components/pages/PaymentSuccess"));
const ReturnToHerdmannHealth = React.lazy(() => import("./components/pages/ReturnToHerdmannHealth"));

class App extends Component {
  render() {
    return (
      <div className="App">
        <Provider store={store}>
          <ErrorBoundry>
            <ErrorToken>
            <ContextMenuTrigger id="some_unique_identifier">
          <ToastContainer hideProgressBar position="top-center"/>
          <HashRouter>
            <React.Suspense fallback={loading()}>
              <Switch>
              <Route
                  path="/ReturnToHerdmannHealth"
                  name="Return To HerdmannHealth"
                  render={props => <ReturnToHerdmannHealth {...props} />}
                />
              <Route
                  path="/paymentsuccess"
                  name="Payment Success"
                  render={props => <PaymentSuccess {...props} />}
                />
              <Route
                  path="/remote"
                  name="Remote Session"
                  render={props => <RemoteSession {...props} />}
                />
                <Route
                  path="/remoteexit"
                  name="Close Remote Session"
                  render={props => <RemoteExit {...props} />}
                />
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={() => <Login />}
                />
                <Route
                  exact
                  path="/register"
                  name="Register Page"
                  render={() => <Register />}
                />
                <Route
                  exact
                  path="/forgetpassword"
                  name="Forget Password"
                  render={() => <Forgetpassword />}
                />
                <Route
                  exact
                  path="/404"
                  name="Page 404"
                  render={props => <Page404 {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  name="Page 500"
                  render={props => <Page500 {...props} />}
                />
                <Route
                  path="/"
                  name="Home"
                  render={props => <TheLayout {...props} />}
                />
              </Switch>
            </React.Suspense>
          </HashRouter>
          </ContextMenuTrigger>
          </ErrorToken>
          </ErrorBoundry>
        </Provider>
      </div>
    );
  }
}

export default App;

// export default hot(App);

