const medicalHistoryJSON = {
  // section: "M",
  // topic: "Medical History",
  // checked: "true",
  // // access: "public",
  // color: "secondary",
  section: "MS",
  topic: "MEDICAL STATUS",
  checked: "true",
  // access: "public",
  color: "secondary",
  description: "",
  body: [
    // {
    //   id: "M1",
    //   shortname: "ms1",
    //   name: "How many times in your life have you been hospitalized for medical problems?",
    //   prevalue: "Include O.D.’s and D.T.’s. Exclude detox, alcohol/drug, psychiatric treatment and childbirth (if no complications). Enter the number of overnight hospitalizations for medical problem",
    //   value: "",
    //   disabled: "false",
    //   type: "number",
    //   act: "MS1",
    //   mandatory: "true",
    //   depending: "",
    //   exception: "",
    // },
    {
      id: "M1",
      shortname: "ms1",
      name: "How many times in your life have you been hospitalized for medical problems?",
      prevalue: "Include O.D.’s and D.T.’s. Exclude detox, alcohol/drug, psychiatric treatment and childbirth (if no complications). Enter the number of overnight hospitalizations for medical problem",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "MS1",
      mandatory: "true",
      depending: "",
      exception: "",
    },

    {
      id: "M2",
      onlyasi: "",  //M2
      shortname: "ms2",
      name: "How long ago was your last hospitalization for a physical problem? no hospitalizations in Question M1, then code NN / NN.",
      prevalue: 'Yrs&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbspMon',
      value: "",
      value2: "",
      disabled: "true",
      type: "year_month",
      act: "MS2",
      mandatory: "true",
      depending: "MS1",
      exception: "0",
    },
    {
      id: "M3",
      shortname: "ms3",
      name: "Do you have any chronic medical problems which continue to interfere with your life?",
      prevalue: "If Yes, specify in comments. A chronic medical condition is a serious physical condition that requires regular care (i.e., medication, dietary restriction) preventing full advantage of their abilities",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "MS3",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "MS3-1",
    },
    {
      id: "M3.1",
      extend: "true",
      shortname: "ms3-1",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MS3-1",
      mandatory: "true",
      depending: "MS3",
      exception: "Yes",
    },
    {
      id: "M4",
      shortname: "ms4",
      name: "Are you taking any prescribed medication on a regular basis for a physical problem?",
      prevalue: "If Yes, specify in comments. Medication prescribed by a MD for medical conditions; not psychiatric medicines. Include medicines prescribed whether or not the patient is currently taking them. The intent is to verify chronic medical problems",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "MS4",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "MS4-1",
    },
    {
      id: "M4.1",
      extend: "true",
      shortname: "ms4-1",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MS4-1",
      mandatory: "true",
      depending: "MS4",
      exception: "Yes",
    },
    {
      id: "M5",
      shortname: "ms5",
      name: "Do you receive a pension for a physical disability?",
      prevalue: "If Yes, specify in comments. Include Workers’ compensation, exclude psychiatric disability",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "MS5",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "MS5-1",
    },
    {
      id: "M5.1",
      extend: "true",
      shortname: "ms5-1",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MS5-1",
      mandatory: "true",
      depending: "MS5",
      exception: "Yes",
    },
    {
      id: "M6",
      shortname: "ms6",
      name: "How many days have you experienced medical problems in the past 30 days?",
      prevalue: "Include flu, colds, etc. Include serious ailments related to drugs/alcohol, which would continue even if the patient were abstinent (e.g., cirrhosis of liver, abscesses from needles, etc.).",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "MS6",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "",
      shortname: "ms7-ms8-subtopic",
      name: "For Questions M7 & M8, ask patient to use the Patient Rating Scale.",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "MS7-MS8-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "M7",
      shortname: "ms7",
      name: "",
      prevalue: "Restrict response to problem days in Question M6",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0 - not at all",
        "1 - slightly",
        "2 - moderately",
        "3 - considerably",
        "4 - extremely",
      ],
      act: "MS7",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "MS7-MS8-ACT",
      suptopic_code: "MS7-CODE",
      subtopic_name:
        "How troubled or bothered have you been by these medical problems in the past 30 days?",
    },
    {
      id: "M8",
      shortname: "ms8",
      name: "",
      prevalue: "If client is currently receiving medical treatment, refer to the need for additional medical treatment by the patient.",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0 - not at all",
        "1 - slightly",
        "2 - moderately",
        "3 - considerably",
        "4 - extremely",
      ],
      act: "MS8",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "MS7-MS8-ACT",
      suptopic_code: "MS8-CODE",
      subtopic_name:
        "How important to you now is treatment for these medical problems?",
    },
    {
      id: "M9",
      onlyasi: "",  //M9
      shortname: "ms9",
      access: "private",
      name: "INTERVIEWER SEVERITY RATING: How would you rate the patient’s need for medical treatment?",
      prevalue: "Refers to the patient’s need for additional medical treatment",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
      ],
      act: "MS9",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "",
      shortname: "ms10-ms11-subtopic",
      access: "private",
      name: "CONFIDENCE RATINGS. Is the above information significantly distorted by:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "MS10-MS11-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "M10",
      shortname: "ms10",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "MS10",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "MS10-MS11-ACT",
      suptopic_code: "MS10-CODE",
      subtopic_name:
        "Patient’s misrepresentation?",
    },
    {
      id: "M11",
      shortname: "ms11",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "MS11",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "MS10-MS11-ACT",
      suptopic_code: "MS11-CODE",
      subtopic_name:
        "Patient's inability to understand?",
    },
    {
      id: "M-COMMENT",
      shortname: "ms-comment",
      access: "private",
      name: "MEDICAL COMMENTS:",
      prevalue: "(Include the question number with your notes)",
      value: "",
      disabled: "false",
      type: "editor",
      act: "MS-COMMENT",
      depending: "",
      exception: "",
    },
  ],
}

export default medicalHistoryJSON;
