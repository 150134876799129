const summaryRecomendationsJSON = {
  section: "SUG",
  topic: "Suggested Recommendations",
  checked: "true",
  // access: "private",
  color: "secondary",
  description: "(optional)",
  body: [
    //========================================================================================
    /*                                                                                      *
     *                                  Field for counselor                                 *
     *                                                                                      */
    //========================================================================================
    {
      id: "SUM.4.rule",
      shortname: "sum04rule",
      name: "Suggested Recommendations",
      prevalue: "Rules",
      value: [],
      disabled: "true",
      type: "checkbox",
      radio_options: [],
      act: "SUM04RULE",
      depending: "",
      exception: "",
      link :"SUM04BUT"
    },
    {
      id: "SUM.4.but",
      shortname: "sum04but",
      access: "private",
      name: "",
      prevalue: "Please press button to get result",
      buttonname : "Run Rule",
      value: "",
      disabled: "true",
      type: "button",
      act: "SUM04BUT",
      depending: "",
      exception: "",
      link :"SUM04RES",
      extend: "true",
    },
    {
      id: "SUM.4.result",
      shortname: "sum04res",
      name: "",
      prevalue: "",
      value: [],
      disabled: "false",
      type: "checkbox",
      radio_options: [],
      tooltip :[],
      act: "SUM04RES",
      depending: "",
      exception: "",
      // link :"SUM04GEN",
      extend: "true",
    },
    // {
    //   id: "SUM.4.c",
    //   shortname: "sum04gen",
    //   access: "private",
    //   name: "",
    //   prevalue: "Result",
    //   value: "",
    //   disabled: "false",
    //   type: "editor",
    //   act: "SUM04GEN",
    //   // mandatory: "true",
    //   depending: "",
    //   exception: "",
    //   extend: "true",
    // },
  ],
};

export default summaryRecomendationsJSON;
