const problemList =  {
  section: "PL",
  topic: "Problem List",
  checked: "true",
  // access: "public",
  color: "secondary",
  description: "(optional)",
  body: [
    {
      id: "PL.1",
      shortname: "pl0-subtopic",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "PL0-SUBTOPIC",
      depending: "",
      exception: "",
    },
    {
      id: "PL.1",
      shortname: "pl0",
      name: "Problem List should be reviewed",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PL0",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "PL0-SUBTOPIC",
      suptopic_code: "PL0-PROBLEM-LIST-SHOULD-BE-REVIEWED",
      subtopic_name: "Problem List should be reviewed",
      // link: "PL1",
      // subtopic_container_act: "CI1",
      // suptopic_code: "CI1_INFO3",
      // subtopic_name: "",
    },
    {
      id: "PL.1.but",
      shortname: "pl0-but",
      access: "private",
      name: "",
      prevalue: "",
      buttonname : "Get Advice",
      value: "100",
      disabled: "false",
      type: "buttonadvisor",
      act: "PL0-BUT",
      depending: "",
      exception: "",
      subtopic_container_act: "PL0-SUBTOPIC",
      suptopic_code: "PL0-PLEASE-PRESS-BUTTON-TO-GET-RESULT",
      subtopic_name: "Artificial Intelligence Assistant",
      // link :"SUM04RES",
      // extend: "true",
    },
    {
      id: "PL.1.1",
      shortname: "pl1-subtopic",
      name: "Problem List",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "subtopic",
      act: "PL1-SUBTOPIC",
      depending: "PL0",
      exception: "Yes",
    },
    //?========================================================================================
    /*                                                                                      *
     *                                       Alcohol Use Disorder                           *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.1.1",
      shortname: "pl1-alcohol-use-disorder-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL1-ALCOHOL-USE-DISORDER-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL1-ALCOHOL-USE-DISORDER-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL1-ALCOHOL-USE-DISORDER",
      subtopic_name: "Alcohol Use Disorder",
    },
    //?========================================================================================
    /*                                                                                      *
     *                                       Drug Use Disorder                           *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.2.1",
      shortname: "pl2-drug-use-disorder-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL2-DRUG-USE-DISORDER-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL2-DRUG-USE-DISORDER-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL2-DRUG-USE-DISORDER",
      subtopic_name: "Drug Use Disorder",
    },
    //?========================================================================================
    /*                                                                                      *
     *                                       Abuse/Neglect                           *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.3.1",
      shortname: "pl3-abuse-neglect-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL3-ABUSE-NEGLECT-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL3-ABUSE-NEGLECT-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL3-ABUSE-NEGLECT",
      subtopic_name: "Abuse/Neglect",
    },
    //?========================================================================================
    /*                                                                                      *
     *                                       Anger                           *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.4.1",
      shortname: "pl4-anger-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL4-ANGER-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL4-ANGER-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL4-ANGER",
      subtopic_name: "Anger",
    },
    //?========================================================================================
    /*                                                                                      *
     *                                       Anxiety                           *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.5.1",
      shortname: "pl5-anxiety-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL5-ANXIETY-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL5-ANXIETY-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL5-ANXIETY",
      subtopic_name: "Anxiety",
    },
    //?========================================================================================
    /*                                                                                      *
     *                                       Decision Making                           *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.6.1",
      shortname: "pl6-decision-making-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL6-DECISION-MAKING-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL6-DECISION-MAKING-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL6-DECISION-MAKING",
      subtopic_name: "Decision Making",
    },
    //?========================================================================================
    /*                                                                                      *
     *                                       Behavior Problems                           *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.7.1",
      shortname: "pl7-behavior-problems-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL7-BEHAVIOR-PROBLEMS-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL7-BEHAVIOR-PROBLEMS-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL7-BEHAVIOR-PROBLEMS",
      subtopic_name: "Behavior Problems",
    },
//?========================================================================================
    /*                                                                                      *
     *                                       Communication Skills                           *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.8.1",
      shortname: "pl8-communication-skills-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL8-COMMUNICATION-SKILLS-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL8-COMMUNICATION-SKILLS-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL8-COMMUNICATION-SKILLS",
      subtopic_name: "Communication Skills",
    },
//?========================================================================================
    /*                                                                                      *
     *                                       Crime/Criminal Thinking                           *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.9.1",
      shortname: "pl9-crime-criminal-thinking-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL9-CRIME-CRIMINAL-THINKING-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL9-CRIME-CRIMINAL-THINKING-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL9-CRIME-CRIMINAL-THINKING",
      subtopic_name: "Crime/Criminal Thinking",
    },
//?========================================================================================
    /*                                                                                      *
     *                                       Depression                           *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.10.1",
      shortname: "pl10-depression-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL10-DEPRESSION-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL10-DEPRESSION-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL10-DEPRESSION",
      subtopic_name: "Depression",
    },
    //?========================================================================================
    /*                                                                                      *
     *                                       Expression of Feelings, Wants and Needs                           *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.11.1",
      shortname: "pl11-expression-of-feelings-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL11-EXPRESSION-OF-FEELINGS-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL11-EXPRESSION-OF-FEELINGS-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL11-EXPRESSION-OF-FEELINGS",
      subtopic_name: "Expression of Feelings, Wants and Needs",
    },
    //?========================================================================================
    /*                                                                                      *
     *                                      Family Conflict                           *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.12.1",
      shortname: "pl12-family-conflict-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL12-FAMILY-CONFLICT-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL12-FAMILY-CONFLICT-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL12-FAMILY-CONFLICT",
      subtopic_name: "Family Conflict",
    },
//?========================================================================================
    /*                                                                                      *
     *                                      Grief and Loss                           *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.13.1",
      shortname: "pl13-grief-and-loss-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL13-GRIEF-AND-LOSS-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL13-GRIEF-AND-LOSS-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL13-GRIEF-AND-LOSS",
      subtopic_name: "Grief and Loss",
    },
//?========================================================================================
    /*                                                                                      *
     *                                      Harm to self or others                           *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.14.1",
      shortname: "pl14-harm-to-self-or-others-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL14-HARM-TO-SELF-OR-OTHERS-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL14-HARM-TO-SELF-OR-OTHERS-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL14-HARM-TO-SELF-OR-OTHERS",
      subtopic_name: "Harm to self or others",
    },
    //?========================================================================================
    /*                                                                                      *
     *                                      Health Issues                           *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.15.1",
      shortname: "pl15-health-issues-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL15-HEALTH-ISSUES-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL15-HEALTH-ISSUES-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL15-HEALTH-ISSUES",
      subtopic_name: "Health Issues",
    },
    //?========================================================================================
    /*                                                                                      *
     *                                      Parenting                           *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.16.1",
      shortname: "pl16-parenting-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL16-PARENTING-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL16-PARENTING-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL16-PARENTING",
      subtopic_name: "Parenting",
    },
    //?========================================================================================
    /*                                                                                      *
     *                                      Personal Hygiene and Self-care                           *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.17.1",
      shortname: "pl17-personal-hygiene-and-self-care-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL17-PERSONAL-HYGIENE-AND-SELF-CARE-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL17-PERSONAL-HYGIENE-AND-SELF-CARE-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL17-PERSONAL-HYGIENE-AND-SELF-CARE",
      subtopic_name: "Personal Hygiene and Self-care",
    },
//?========================================================================================
    /*                                                                                      *
     *                                      Relationships                           *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.18.1",
      shortname: "pl18-relationships-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL18-RELATIONSHIPS-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL18-RELATIONSHIPS-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL18-RELATIONSHIPS",
      subtopic_name: "Relationships",
    },
    //?========================================================================================
    /*                                                                                      *
     *                                      Self-Image                           *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.19.1",
      shortname: "pl19-self-image-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL19-SELF-IMAGE-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL19-SELF-IMAGE-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL19-SELF-IMAGE",
      subtopic_name: "Self-Image",
    },
    //?========================================================================================
    /*                                                                                      *
     *                                      Sleep Problems                          *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.20.1",
      shortname: "pl20-sleep-problems-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL20-SLEEP-PROBLEMS-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL20-SLEEP-PROBLEMS-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL20-SLEEP-PROBLEMS",
      subtopic_name: "Sleep Problems",
    },
    //?========================================================================================
    /*                                                                                      *
     *                                      Social Skills                          *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.21.1",
      shortname: "pl21-social-skills-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL21-SOCIAL-SKILLS-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL21-SOCIAL-SKILLS-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL21-SOCIAL-SKILLS",
      subtopic_name: "Social Skills",
    },
//?========================================================================================
    /*                                                                                      *
     *                                      Stress                          *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.22.1",
      shortname: "pl22-stress-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL22-STRESS-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL22-STRESS-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL22-STRESS",
      subtopic_name: "Stress",
    },
//?========================================================================================
    /*                                                                                      *
     *                                      Suicide                          *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.23.1",
      shortname: "pl23-suicide-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL23-SUICIDE-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL23-SUICIDE-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL23-SUICIDE",
      subtopic_name: "Suicide",
    },
//?========================================================================================
    /*                                                                                      *
     *                                      Trauma                          *
     *                                                                                      */
    //?========================================================================================
    {
      id: "PL.1.24.1",
      shortname: "pl24-trauma-1",
      name: "",
      prevalue: "",
      value: "n/a",
      disabled: "true",
      type: "radio",
      radio_options: ["Treat","Case Manage","Refer","n/a"],
      act: "PL24-TRAUMA-1",
      depending: "PL0",
      exception: "Yes",
      // link: "PL24-TRAUMA-2",
      subtopic_container_act: "PL1-SUBTOPIC",
      suptopic_code: "PL24-TRAUMA",
      subtopic_name: "Trauma",
    },
     {
      id: "PL.2",
      shortname: "pl2",
      access: "private",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PL2",
      // mandatory: "true",
      depending: "",
      exception: "",
    },
    // {
    //   id: "PL.1.1",
    //   extend: "true",
    //   shortname: "pl1",
    //   name: "",
    //   prevalue: "Problem List",
    //   value: [
    //     false,
    //     false,
    //     false,
    //     false,
    //     false,
    //     false,
    //     false,
    //     false,
    //     false,
    //     false,
    //     false,
    //     false,
    //     false,
    //     false,
    //     false,
    //     false,
    //     false,
    //     false,
    //     false,
    //     false,
    //     false,
    //     false,
    //     false,
    //   ],
    //   disabled: "true",
    //   type: "checkbox",
    //   radio_options: [
    //     "Alcohol/Drugs and Other Addictions",
    //     "Abuse/Neglect",
    //     "Anger",
    //     "Anxiety",
    //     "Decision Making",
    //     "Behavior Problems",
    //     "Communication Skills",
    //     "Crime/Criminal Thinking",
    //     "Depression",
    //     "Expression of Feelings, Wants and Needs",
    //     "Family Conflict",
    //     "Grief and Loss",
    //     "Harm to self or others",
    //     "Health Issues",
    //     "Parenting",
    //     "Personal Hygiene and Self-care",
    //     "Relationships",
    //     "Self-Image",
    //     "Sleep Problems",
    //     "Social Skills",
    //     "Stress",
    //     "Suicide",
    //     "Trauma",
    //   ],
    //   act: "PL1",
    //   depending: "PL0",
    //   exception: "Yes",
    // },
  ]
}

export default problemList;
