import demographicInformationJSON from "./jsonsAdult/demographicInformationJSON";
import presentingProblemJSON from "./jsonsAdult/presentingProblemJSON";
import medicalHistoryJSON from "./jsonsAdult/medicalHistoryJSON";
import educationHistoryJSON from "./jsonsAdult/educationHistorJSON";
import alcoholHistoryJSON from "./jsonsAdult/alcoholHistoryJSON";
import legalHistoryJSON from "./jsonsAdult/legalHistoryJSON";
import familyHistoryJSON from "./jsonsAdult/familyHistoryJSON";
import behavioralHistoryJSON from "./jsonsAdult/behavioralHistoryJSON";
import summaryRecomendationsJSON from "./jsonsAdult/summaryRecomendationsJSON";
import asamCriteriaJSON from "./jsonsAdult/asamCriteriaJSON";
import mseJSON from "./jsonsAdult/mseJSON";
import collateralJSON from "./jsonsAdult/collateralJSON";
import strengthsJSON from "./jsonsAdult/strengthsJSON";
import suggestedRecomendation from "./jsonsAdult/suggestedRecomendation"
import problemList from "./jsonsAdult/problemList"
import caseManagementGoals from "./jsonsAdult/caseManagementGoals"

const adultPattern = [
  //========================================================================================
  /*                                                                                      *
   *                                Demographic Information                               *
   *                                                                                      */
  //========================================================================================
  demographicInformationJSON,
  //========================================================================================
  /*                                                                                      *
   *                         Presenting Problem/Primary Complaint                         *
   *                                                                                      */
  //========================================================================================

  presentingProblemJSON,
  //========================================================================================
  /*                                                                                      *
   *                                    Medical History                                   *
   *                                                                                      */
  //========================================================================================
  medicalHistoryJSON,
  //========================================================================================
  /*                                                                                      *
   *                      Education, Employment and Military History                      *
   *                                                                                      */
  //========================================================================================
  educationHistoryJSON,
  //========================================================================================
  /*                                                                                      *
   *                               Drug and Alcohol History                               *
   *                                                                                      */
  //========================================================================================

  alcoholHistoryJSON,
  //========================================================================================
  /*                                                                                      *
   *                                         Legal History                                       *
   *                                                                                      */
  //========================================================================================
  legalHistoryJSON,
  //========================================================================================
  /*                                                                                      *
   *                                    Family History                                    *
   *                                                                                      */
  //========================================================================================
  familyHistoryJSON,
  //========================================================================================
  /*                                                                                      *
   *                               Behavioral Health History                              *
   *                                                                                      */
  //========================================================================================
  behavioralHistoryJSON,
  //========================================================================================
  /*                                                                                      *
   *                           Diagnosis/Summary/Recommendations                          *
   *                                                                                      */
  //========================================================================================
  // summaryRecomendationsJSON,
  //========================================================================================
  /*                                                                                      *
   *                                       OPTIONAL                                       *
   *                                                                                      */
  //========================================================================================

  // {
  //   section: "OPTIONAL",
  //   topic: "OPTIONAL SECTIONS. CHECK TO INCLUDE",
  //   checked: "false",
  //   // access: "private",
  //   color: "info",
  //   body: [],
  // },
  //========================================================================================
  /*                                                                                      *
   *                                     ASAM Criteria                                    *
   *                                                                                      */
  //========================================================================================
  asamCriteriaJSON,
  //========================================================================================
  /*                                                                                      *
   *                            Mental Status Examination (MSE)                           *
   *                                                                                      */
  //========================================================================================

  mseJSON,
  //========================================================================================
  /*                                                                                      *
   *                                Collateral Information                                *
   *                                                                                      */
  //========================================================================================

  collateralJSON,
  //========================================================================================
  /*                                                                                      *
   *                               Strengths and Challenges                                *
   *                                                                                      */
  //========================================================================================
  strengthsJSON,
//========================================================================================
/*                                                                                      *
 *                                     Problem List                                     *
 *                                                                                      */
//========================================================================================

  problemList,
  //========================================================================================
  /*                                                                                      *
   *                           Diagnosis/Summary/Recommendations                          *
   *                                                                                      */
  //========================================================================================
  summaryRecomendationsJSON,
  //========================================================================================
  /*                                                                                      *
   *                           Diagnosis/Summary/Recommendations                          *
   *                                                                                      */
  //========================================================================================
  suggestedRecomendation,
  caseManagementGoals
];

export default adultPattern;
