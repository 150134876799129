// import assessmentPattern from "../store/assessmentPattern_";
import adultPattern from "../store/adultPattern";
// import adolescentPattern from "../store/adolescentPattern";
import { ruleOperations,getAssessmentPattern,getDetail } from "../api";

function transformToNumber(str){
  if(str==="") return str;
  switch(str.toLowerCase()){
    case "one" : return "1";
    case "two" : return "2";
    case "three": return "3";
    case "four" : return "4";
    case "five" : return "5";
    case "six": return "6";
    case "seven": return "7";
    case "eight": return "8";
    case "nine" : return "9";
    case "ten" : return "10";
    case "less than one year" : return "0";
    case "more than ten" : return "11";
    case "zero" : return "0";
    case "never" : return "0";
    case "none" : return "0";
    case "never regular" : return "0";
    case "3 feet" : return "3";
    case "4 feet" : return "4";
    case "5 feet" : return "5";
    case "6 feet" : return "6";
    case "0 inches" : return "0";
    case "1 inches" : return "1";
    case "2 inches" : return "2";
    case "3 inches" : return "3";
    case "4 inches" : return "4";
    case "5 inches" : return "5";
    case "6 inches" : return "6";
    case "7 inches" : return "7";
    case "8 inches" : return "8";
    case "9 inches" : return "9";
    case "10 inches" : return "10";
    case "11 inches" : return "11";
    case "12 inches" : return "12";
    default : return str;
  }
}


const getConditionByJson = (arr_json, shortname, operation, value) => {
  for (var i = 0; i < arr_json.length; i++) {
    for (var j = 0; j < arr_json[i].body.length; j++) {
      if (arr_json[i].body[j].shortname === shortname) {
        switch (operation) {
          case "EQU": {
            if (arr_json[i].body[j].value === value) return true;
            return false;
          }
          case "NE": {
            if (arr_json[i].body[j].value !== value) return true;
            return false;
          }
          case "GTE": {
            if (Number(transformToNumber(arr_json[i].body[j].value)) >= Number(transformToNumber(value)) && arr_json[i].body[j].value!=="" && value!=="") return true;
            return false;
          }
          case "LTE": {
            console.log("arr_json[i].body[j].value=",arr_json[i].body[j].value,"value=",value);
            if (Number(transformToNumber(arr_json[i].body[j].value)) <= Number(transformToNumber(value)) && arr_json[i].body[j].value!=="" && value!=="") return true;
            return false;
          }
          default:
            return false;
        }
      }
    }
  }
  return false;
};

function createRuleRecord(newRulePredicateMas) {
  // console.log("newRulePredicateMas=", newRulePredicateMas);
  let rule_window = "";
  let rule_body = "";
  let result = "";

  newRulePredicateMas.forEach((el, index) => {
    if (index % 2 === 0)
      rule_window += `<b>IF (</b> ${el.id} <b>${el.operation}</b> "${el.value}" <b>)</b>`;
    else rule_window += ` <b>${el.operation}</b> `;
  });

  newRulePredicateMas.forEach((el, index) => {
    if (index % 2 === 0)
      rule_body += `IF ( ${el.shortname} ${el.operation} "${el.value}" )`;
    else rule_body += ` ${el.operation} `;
  });

  // console.log("rule_window=", rule_body);
  return { rule_window, rule_body, result };
}

function checkRuleError(newRulePredicateMas) {
  for (let i = 0; i < newRulePredicateMas.length; i++) {
    if (i % 2 === 0) {
      if (
        newRulePredicateMas[i].id === "" ||
        newRulePredicateMas[i].operation === "" ||
        newRulePredicateMas[i].value === ""
      ) {
        return {
          error: true,
          msg: "Error: Please fill all field in predicates",
        };
      }
    }
  }
  if (newRulePredicateMas.length % 2 === 0)
    return { error: true, msg: "Error: Please add new predicate" };
  else return { error: false, msg: "Predicate Correct" };
}

// const getDetail = (arr_json, shortname) => {
//   for (var i = 0; i < arr_json.length; i++) {
//     for (var j = 0; j < arr_json[i].body.length; j++) {
//       if (arr_json[i].body[j].shortname === shortname) {
//         return arr_json[i].body[j];
//       }
//     }
//   }
//   return { type: "NULL", payload: null };
// };

function createRulePredicateMas(rule) {
  console.log("createRulePredicateMas rule=", rule);
  const newRulePredicateMas = [];
  const parserBody = rule.rule_body.split("IF ( ");
  let predicatesInString = [];
  let tmp = [];
  for (let i = 1; i < parserBody.length; i++) {
    tmp = parserBody[i].split(" )");
    for (let j = 0; j < tmp.length; j++) {
      if (tmp[j] !== "") predicatesInString.push(tmp[j]);
    }
  }
  console.log("predicatesInString=",predicatesInString);
  // console.log("---------START-----------------")
  /*************************************************** */
  for (let i = 0; i < predicatesInString.length; i++) {
    if (i % 2 === 0) {
      let shortname = predicatesInString[i].split(" ")[0];
      let operation = predicatesInString[i].split(" ")[1];
      let value = predicatesInString[i].split('"')[1];
      // console.log("shortname=",shortname,"operation=",operation);
      // const el = getDetail(rule.adult_type==="Adolescent"?adolescentPattern:adultPattern, shortname);
      const el = getDetail(rule.adult_type==="Adolescent"?getAssessmentPattern("Adolescent"):adultPattern, shortname);
      let scopeValue = [];
      let scopeOperations = [];
      switch (el.type) {
        case "select": {
          if(el.ruletype==="number")
            scopeOperations = ruleOperations.selectOper;
          else if(el.ruletype==="string")
            scopeOperations = ruleOperations.radioOper;
          scopeValue = el.select_options;
          break;
        }
        case "checkbox":
        case "radio": {
          const scopeRadio = ["", ...el.radio_options];
          scopeOperations = ruleOperations.radioOper;
          scopeValue = scopeRadio;
          break;
        }
        case "number": case "money": case "content" : {
          scopeOperations = ruleOperations.numberOper;
          scopeValue = [];
          break;
        }
        case "year": case "month" : case "date" : {
          scopeOperations = ruleOperations.yearOper;
          scopeValue = [];
          break;
        }
        default:
          return {
            scopeOperations: [],
            scopeValue: [],
          };
      }

      const newRulePredicate = {
        idKey: i.toString(),
        id: el.id,
        shortname: shortname,
        title:
          el.name === "" ? `${el.id} ${el.prevalue}` : `${el.id} ${el.name}`,
        type: el.type,
        scopeOperations: scopeOperations,
        operation: operation,
        scopeValues: scopeValue,
        value: value,
      };
      // console.log("newRulePredicate=",newRulePredicate);
      newRulePredicateMas.push(newRulePredicate);
    } else {
      let operation = predicatesInString[i].trim();
      // console.log(operation, " ", operation.length);
      const newRulePredicate = {
        idKey: Date.now().toString() + i.toString(),
        id: "",
        shortname: "",
        title: "",
        type: "OR-AND",
        scopeOperations: [],
        operation: operation,
        scopeValues: [],
        value: "",
      };
      newRulePredicateMas.push(newRulePredicate);
    }
  }
  // console.log("---------END-----------------")
  return newRulePredicateMas;
}

export { createRuleRecord, checkRuleError, createRulePredicateMas, getConditionByJson,transformToNumber };
