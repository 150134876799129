const legalHistoryJSON =   {
  // section: "LEG",
  // topic: "Legal History",
  // checked: "true",
  // // access: "public",
  // color: "secondary",
  section: "LS",
  topic: "LEGAL STATUS",
  checked: "true",
  // access: "public",
  color: "secondary",
  description: "",
  body: [
    {
      id: "L1",
      shortname: "ls1",
      name: "Was this admission prompted or suggested by the criminal justice system?",
      prevalue: "Judge, probation/parole officer, etc.",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "LS1",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "L2",
      shortname: "ls2",
      name: "Are you on probation or parole? Note duration and level in comments.",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "LS2",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LS2-1",
    },
    {
      id: "L2.1",
      extend: "true",
      shortname: "ls2-1",
      name: "",
      prevalue: "Comment:",
      value: "",
      disabled: "true",
      type: "text",
      act: "LS2-1",
      mandatory: "true",
      depending: "LS2",
      exception: "Yes",
    },
    {
      id: "",
      shortname: "ls3-ls16-subtopic",
      name: "How many times in your life have you been arrested and charged with the following? Include total number of counts, not just convictions. Do not include juvenile (pre-age 18) crimes, unless they were tried as an adult. Include formal charges only",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "LS3-LS16-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "L3",
      shortname: "ls3",
      name: "",
      prevalue: "",
      value: "0",
      disabled: "false",
      type: "number",
      act: "LS3",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS3-LS16-ACT",
      suptopic_code: "LS3-CODE",
      subtopic_name:
        "Shoplifting/Vandalism",
    },
    {
      id: "L4",
      shortname: "ls4",
      name: "",
      prevalue: "",
      value: "0",
      disabled: "false",
      type: "number",
      act: "LS4",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS3-LS16-ACT",
      suptopic_code: "LS4-CODE",
      subtopic_name:
        "Parole/Probation Violations",
    },
    {
      id: "L5",
      shortname: "ls5",
      name: "",
      prevalue: "",
      value: "0",
      disabled: "false",
      type: "number",
      act: "LS5",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS3-LS16-ACT",
      suptopic_code: "LS5-CODE",
      subtopic_name:
        "Drug Charges",
    },
    {
      id: "L6",
      shortname: "ls6",
      name: "",
      prevalue: "",
      value: "0",
      disabled: "false",
      type: "number",
      act: "LS6",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS3-LS16-ACT",
      suptopic_code: "LS6-CODE",
      subtopic_name:
        "Forgery",
    },
    {
      id: "L7",
      shortname: "ls7",
      name: "",
      prevalue: "",
      value: "0",
      disabled: "false",
      type: "number",
      act: "LS7",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS3-LS16-ACT",
      suptopic_code: "LS7-CODE",
      subtopic_name:
        "Weapons Offense <br/><br/>",
    },
    {
      id: "L8",
      shortname: "ls8",
      name: "",
      prevalue: "",
      value: "0",
      disabled: "false",
      type: "number",
      act: "LS8",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS3-LS16-ACT",
      suptopic_code: "LS8-CODE",
      subtopic_name:
        "Burglary/Larceny/Breaking & Entering",
    },
    {
      id: "L9",
      shortname: "ls9",
      name: "",
      prevalue: "",
      value: "0",
      disabled: "false",
      type: "number",
      act: "LS9",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS3-LS16-ACT",
      suptopic_code: "LS9-CODE",
      subtopic_name:
        "Robbery",
    },
    {
      id: "L10",
      shortname: "ls10",
      name: "",
      prevalue: "",
      value: "0",
      disabled: "false",
      type: "number",
      act: "LS10",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS3-LS16-ACT",
      suptopic_code: "LS10-CODE",
      subtopic_name:
        "Assault",
    },
    {
      id: "L11",
      shortname: "ls11",
      name: "",
      prevalue: "",
      value: "0",
      disabled: "false",
      type: "number",
      act: "LS11",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS3-LS16-ACT",
      suptopic_code: "LS11-CODE",
      subtopic_name:
        "Arson",
    },
    {
      id: "L12",
      shortname: "ls12",
      name: "",
      prevalue: "",
      value: "0",
      disabled: "false",
      type: "number",
      act: "LS12",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS3-LS16-ACT",
      suptopic_code: "LS12-CODE",
      subtopic_name:
        "Rape",
    },
    {
      id: "L13",
      shortname: "ls13",
      name: "",
      prevalue: "",
      value: "0",
      disabled: "false",
      type: "number",
      act: "LS13",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS3-LS16-ACT",
      suptopic_code: "LS13-CODE",
      subtopic_name:
        "Homicide/Manslaughter",
    },
    {
      id: "L14",
      shortname: "ls14",
      name: "",
      prevalue: "",
      value: "0",
      disabled: "false",
      type: "number",
      act: "LS14",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS3-LS16-ACT",
      suptopic_code: "LS14-CODE",
      subtopic_name:
        "Prostitution",
    },
    {
      id: "L15",
      shortname: "ls15",
      name: "",
      prevalue: "",
      value: "0",
      disabled: "false",
      type: "number",
      act: "LS15",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS3-LS16-ACT",
      suptopic_code: "LS15-CODE",
      subtopic_name:
        "Contempt of Court",
    },
    {
      id: "L16",
      shortname: "ls16",
      name: "",
      prevalue: "",
      value: "0",
      disabled: "false",
      type: "number",
      act: "LS16",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS3-LS16-ACT",
      suptopic_code: "LS16-CODE",
      subtopic_name:
        "Other",
    },
    {
      id: "L17",
      shortname: "ls17",
      name: "How many of these charges resulted in convictions?",
      prevalue: "If L3-16 = 00, then Question L17 = NN. Do not include misdemeanor offenses from questions L18-20 below. Convictions include fines, probation, incarcerations, suspended sentences, guilty please, and plea bargaining.",
      value: "0",
      disabled: "true",
      type: "number",
      act: "LS17",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "",
      shortname: "ls18-ls20-subtopic",
      name: "How many times in your life have you been charged with the following...Moving violations: speeding, reckless driving, no license, etc.",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "LS18-LS20-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "L18",
      shortname: "ls18",
      name: "",
      prevalue: "",
      value: "0",
      disabled: "false",
      type: "number",
      act: "LS18",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS18-LS20-ACT",
      suptopic_code: "LS18-CODE",
      subtopic_name:
        "Disorderly conduct, vagrancy, public intoxication",
    },
    {
      id: "L19",
      shortname: "ls19",
      name: "",
      prevalue: "",
      value: "0",
      disabled: "false",
      type: "number",
      act: "LS19",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS18-LS20-ACT",
      suptopic_code: "LS19-CODE",
      subtopic_name:
        "Driving while intoxicated <br/><br/>",
    },
    {
      id: "L20",
      shortname: "ls20",
      name: "",
      prevalue: "",
      value: "0",
      disabled: "false",
      type: "number",
      act: "LS20",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS18-LS20-ACT",
      suptopic_code: "LS20-CODE",
      subtopic_name:
        "Major driving violations",
    },
    {
      id: "L21",
      shortname: "ls21",
      name: "How many months were you incarcerated in your life?",
      prevalue: "If incarcerated 2 weeks or more, round this up to 1 month. List total number of months incarcerated",
      value: "0",
      disabled: "false",
      type: "number",
      act: "LS21",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LS22",
    },
    {
      id: "L22",
      onlyasi: "",  //L22
      extend: "true",
      shortname: "ls22",
      name: "",
      prevalue: "How long was your last incarceration? Of 2 weeks or more. Code NN if never incarcerated.",
      value: "",
      disabled: "true",
      type: "number",
      act: "LS22",
      mandatory: "true",
      // depending: "",
      // exception: "",
      depending: "LS21",
      exception: "0",
      link: "LS23",
    },
    {
      id: "L23",
      onlyasi: "",  //L23
      extend: "true",
      shortname: "ls23",
      name: "",
      prevalue: "What was it for? Use codes 03–16, 18–20 If multiple charges, code most severe.Code NN if never incarcerated.",
      value: "",
      disabled: "true",
      type: "number",
      act: "LS23",
      mandatory: "true",
      depending: "LS21",
      exception: "0",
      // depending: "",
      // exception: "",
    },
    {
      id: "L24",
      shortname: "ls24",
      name: "Are you presently awaiting charges, trial, or sentence?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "LS24",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LS25",
    },
    {
      id: "L25",
      extend: "true",
      shortname: "ls25",
      name: "",
      prevalue: "What for? Refers to Question L24. Use the number of the type of crime committed: 03-16 and 18-20. If multiple charges, code most severe",
      value: "",
      disabled: "true",
      type: "number",
      act: "LS25",
      mandatory: "true",
      depending: "LS24",
      exception: "Yes",
    },
    {
      id: "L26",
      shortname: "ls26",
      name: "How many days in the past 30 were you detained or incarcerated?",
      prevalue: "Include being arrested and released on the same day",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "LS26",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "L27",
      shortname: "ls27",
      name: "How many days in the past 30 have you engaged in illegal activities for profit?",
      prevalue: "Exclude simple drug possession. Include drug dealing, prostitution, selling stolen goods, etc. May be cross-checked with E17 under Employment section",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "LS27",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "",
      shortname: "ls28-ls29-subtopic",
      name: "For Questions L28 & L29, ask patient to use the Patient Rating Scale",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "LS28-LS29-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "L28",
      shortname: "ls28",
      name: "",
      prevalue: "Exclude civil problems<br/><br/><br/>",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0 - not at all",
        "1 - slightly",
        "2 - moderately",
        "3 - considerably",
        "4 - extremely",
      ],
      act: "LS28",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS28-LS29-ACT",
      suptopic_code: "LS28-CODE",
      subtopic_name:
        "How serious do you feel your present legal problems are?<br/><br/>",
    },
    {
      id: "L29",
      shortname: "ls29",
      name: "",
      prevalue: "Patient is rating a need for additional referral to legal counsel for defense against criminal charges.",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0 - not at all",
        "1 - slightly",
        "2 - moderately",
        "3 - considerably",
        "4 - extremely",
      ],
      act: "LS29",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS28-LS29-ACT",
      suptopic_code: "LS29-CODE",
      subtopic_name:
        "How important to you now is counseling or referral for these legal problems?",
    },
    {
      id: "L30",
      onlyasi: "",  //L30
      shortname: "ls30",
      access: "private",
      name: "INTERVIEWER SEVERITY RATING",
      prevalue: "How would you rate the patient’s need for legal services or counseling?",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
      ],
      act: "LS30",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "",
      shortname: "ls31-ls32-subtopic",
      access: "private",
      name: "CONFIDENCE RATINGS. Is the above information significantly distorted by:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "LS31-LS32-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "L31",
      shortname: "ls31",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "LS31",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS31-LS32-ACT",
      suptopic_code: "LS31-CODE",
      subtopic_name:
        "Client’s misrepresentation?",
    },
    {
      id: "L32",
      shortname: "ls32",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "LS32",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "LS31-LS32-ACT",
      suptopic_code: "LS32-CODE",
      subtopic_name:
        "Client’s inability to understand?",
    },
    {
      id: "L-COMMENT",
      shortname: "ls-comment",
      access: "private",
      name: "LEGAL COMMENTS:",
      prevalue: "(Include the question number with your notes)",
      value: "",
      disabled: "false",
      type: "editor",
      act: "LS-COMMENT",
      depending: "",
      exception: "",
    },
  ],
}



export default legalHistoryJSON;
