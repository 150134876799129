// import assessmentPattern from "../store/assessmentPattern_";
// import adultPattern from "../store/adultPattern";
// import adolescentPattern from "../store/adolescentPattern";
import ASIPattern from "../store/ASIPattern";
import { Constants } from "./index";

import {
  transformruleOperations,
  getAssessmentPattern,
  phoneTypeGroup,
  hourminuteTypeGroup,
  yearmonthTypeGroup,
  yearTypeGroup,
  numberTypeGroup,
  textTypeGroup,
  dateTypeGroup,
  complexTypeGroup,
  codeTypeGroup,
  selectTypeGroup,
  needTransformruleClarification,
  getDetail,
} from "../api";

function transformToNumber(str) {
  if (str === "") return str;
  switch (str.toLowerCase()) {
    case "one":
      return "1";
    case "two":
      return "2";
    case "three":
      return "3";
    case "four":
      return "4";
    case "five":
      return "5";
    case "six":
      return "6";
    case "seven":
      return "7";
    case "eight":
      return "8";
    case "nine":
      return "9";
    case "ten":
      return "10";
    case "less than one year":
      return "0";
    case "more than ten":
      return "11";
    case "zero":
      return "0";
    case "never":
      return "0";
    case "none":
      return "0";
    case "never regular" :
      return "0";
    case "3 feet":
      return "3";
    case "4 feet":
      return "4";
    case "5 feet":
      return "5";
    case "6 feet":
      return "6";
    case "0 inches":
      return "0";
    case "1 inches":
      return "1";
    case "2 inches":
      return "2";
    case "3 inches":
      return "3";
    case "4 inches":
      return "4";
    case "5 inches":
      return "5";
    case "6 inches":
      return "6";
    case "7 inches":
      return "7";
    case "8 inches":
      return "8";
    case "9 inches":
      return "9";
    case "10 inches":
      return "10";
    case "11 inches":
      return "11";
    case "12 inches":
      return "12";
    default:
      return str;
  }
}

const getConditionByJson = (arr_json, shortname, operation, value) => {
  for (var i = 0; i < arr_json.length; i++) {
    for (var j = 0; j < arr_json[i].body.length; j++) {
      if (arr_json[i].body[j].shortname === shortname) {
        switch (operation) {
          case "EQU": {
            if (arr_json[i].body[j].value === value) return true;
            return false;
          }
          case "NE": {
            if (arr_json[i].body[j].value !== value) return true;
            return false;
          }
          case "GTE": {
            if (
              Number(transformToNumber(arr_json[i].body[j].value)) >=
                Number(transformToNumber(value)) &&
              arr_json[i].body[j].value !== "" &&
              value !== ""
            )
              return true;
            return false;
          }
          case "LTE": {
            console.log(
              "arr_json[i].body[j].value=",
              arr_json[i].body[j].value,
              "value=",
              value
            );
            if (
              Number(transformToNumber(arr_json[i].body[j].value)) <=
                Number(transformToNumber(value)) &&
              arr_json[i].body[j].value !== "" &&
              value !== ""
            )
              return true;
            return false;
          }
          default:
            return false;
        }
      }
    }
  }
  return false;
};

function createTransformruleRecord(transformruleTable) {
  let rule_window = "";
  let rule_body = "";
  let result = "";

  // if(complexTypeGroup.includes(el.typeL)){
  //   transformruleTable.forEach((el, index) => {
  //     rule_window += `( <b>${el.idL}</b> <b>${el.operationL}</b> <b>${el.idR}</b> ) <b>${el.typeL}</b>`
  //     rule_body += `( ${el.shortnameL} ${el.operationL} ${el.shortnameR} ) ${el.typeL}`
  //   })
  // } else {
  transformruleTable.forEach((el, index) => {
    if (index === 0) {
      rule_window +=
        el.operationL === ""
          ? `( <b>${el.idL}</b> <b>COMPLEX</b> <b>${el.idR}</b> )`
          : `( <b>${el.idL}</b> <b>${el.operationL}</b> <b>${el.idR}</b> )`;
      // rule_window += ` <b>${el.typeL}</b> `
    }
    if (index > 0) {
      rule_window += `( <b>"${el.typeL}"</b> <b>${el.operationL}</b> <b>"${
        el.typeR === "" ? Constants.EMPTY_TRULE : el.typeR
      }"</b> )`;
    }
    if (index === 0) {
      rule_body +=
        el.operationL === ""
          ? `( ${el.shortnameL} COMPLEX ${el.shortnameR} )`
          : `( ${el.shortnameL} ${el.operationL} ${el.shortnameR} )`;
      // rule_body += ` ${el.typeL} `
    }
    if (index > 0) {
      rule_body += `( "${el.typeL}" ${el.operationL} "${
        el.typeR === "" ? Constants.EMPTY_TRULE : el.typeR
      }" )`;
    }
  });

  // transformruleTable.forEach((el, index) => {
  //   if(index ===0 ){
  //     rule_window += el.operationL==="" ? `( <b>${el.idL}</b> <b>COMPLEX</b> <b>${el.idR}</b> )` : `( <b>${el.idL}</b> <b>${el.operationL}</b> <b>${el.idR}</b> )`
  //     rule_window += ` <b>${el.typeL}</b> `
  //   }
  //   if(index > 0 ){
  //     rule_window += `( <b>"${el.typeL}"</b> <b>${el.operationL}</b> <b>"${el.typeR}"</b> )`
  //   }
  //   if(index ===0 ){
  //     rule_body +=  el.operationL==="" ? `( ${el.shortnameL} COMPLEX ${el.shortnameR} )` : `( ${el.shortnameL} ${el.operationL} ${el.shortnameR} )`
  //     rule_body += ` ${el.typeL} `
  //   }
  //   if(index > 0 ){
  //     rule_body += `( "${el.typeL}" ${el.operationL} "${el.typeR}" )`
  //   }
  // })

  // }
  return { rule_window, rule_body, result };

  // idL idR typeL typeR
  // shortnameL shortnameR typeL typeR

  // let rule_window = "";
  // let rule_body = "";
  // let result = "";

  // transformruleTable.forEach((el, index) => {
  //   if (index % 2 === 0)
  //     rule_window += `<b>IF (</b> ${el.id} <b>${el.operation}</b> "${el.value}" <b>)</b>`;
  //   else rule_window += ` <b>${el.operation}</b> `;
  // });

  // transformruleTable.forEach((el, index) => {
  //   if (index % 2 === 0)
  //     rule_body += `IF ( ${el.shortname} ${el.operation} "${el.value}" )`;
  //   else rule_body += ` ${el.operation} `;
  // });

  // // console.log("rule_window=", rule_body);
  // return { rule_window, rule_body, result };
}

function checkTransformruleError(transformruleTable) {
  // console.log("checkTransformruleError transformruleTable=",transformruleTable)
  let statusObj = { error: false, msg: "Predicate Correct" };
  transformruleTable.forEach((el, index) => {
    if (el.operationL === "" && !complexTypeGroup.includes(el.typeL)) {
      statusObj = { error: true, msg: "Error: Please fill Operations field." };
      return;
    }
    // console.log("transformruleTable=",transformruleTable);
    if (index === 0) {
      if (
        (!phoneTypeGroup.includes(el.typeL) || !phoneTypeGroup.includes(el.typeR)) &&
        (!hourminuteTypeGroup.includes(el.typeL) || !hourminuteTypeGroup.includes(el.typeR)) &&
        (!yearmonthTypeGroup.includes(el.typeL) || !yearmonthTypeGroup.includes(el.typeR)) &&
        (!yearTypeGroup.includes(el.typeL) || !yearTypeGroup.includes(el.typeR)) &&
        (!numberTypeGroup.includes(el.typeL) || !numberTypeGroup.includes(el.typeR)) &&
        (!textTypeGroup.includes(el.typeL) || !textTypeGroup.includes(el.typeR)) &&
        (!dateTypeGroup.includes(el.typeL) || !dateTypeGroup.includes(el.typeR)) &&
        (!complexTypeGroup.includes(el.typeL) || !complexTypeGroup.includes(el.typeR)) &&
        (!codeTypeGroup.includes(el.typeL) || !codeTypeGroup.includes(el.typeR)) &&
        (!numberTypeGroup.includes(el.typeL) || !selectTypeGroup.includes(el.typeR)) && //for excludes
        (!yearmonthTypeGroup.includes(el.typeL) || !selectTypeGroup.includes(el.typeR)) //for excludes exceptionSelectToYear exceptionSelectToMonth
      ) {
        statusObj = { error: true, msg: "Error: fields type mismatch." };
        return;
      }
    }

    // if(index>0 && !complexTypeGroup.includes(el.typeR) && el.typeR===""){
    //   statusObj={error: true, msg: "Error: Please fill TYPE fields."};
    //   return
    // }
  });
  return statusObj;
  //check operationLeft
  //check
  // for (let i = 0; i < newTransformrulePredicateMas.length; i++) {
  //   if (i % 2 === 0) {
  //     if (
  //       newTransformrulePredicateMas[i].id === "" ||
  //       newTransformrulePredicateMas[i].operation === "" ||
  //       newTransformrulePredicateMas[i].value === ""
  //     ) {
  //       return {
  //         error: true,
  //         msg: "Error: Please fill all field in predicates",
  //       };
  //     }
  //   }
  // }
  // if (newTransformrulePredicateMas.length % 2 === 0)
  //   return { error: true, msg: "Error: Please add new predicate" };
  // else return { error: false, msg: "Predicate Correct" };
}

// const getDetail = (arr_json, shortname) => {
//   for (var i = 0; i < arr_json.length; i++) {
//     for (var j = 0; j < arr_json[i].body.length; j++) {
//       if (arr_json[i].body[j].shortname === shortname) {
//         return arr_json[i].body[j];
//       }
//     }
//   }
//   return { type: "NULL", payload: null };
// };

function createTransformrulePredicateMasLeftRight(transformruleTable) {
  let leftMas = transformruleTable.map((el) => {
    return {
      idKey: el.idKeyL,
      id: el.idL,
      shortname: el.shortnameL,
      title: el.titleL,
      type: el.typeL,
      scopeOperations: el.scopeOperationsL,
      operation: el.operationL,
      scopeValues: el.scopeValuesL,
      value: el.valueL,
    };
  });
  let rightMas = transformruleTable.map((el) => {
    return {
      idKey: el.idKeyR,
      id: el.idR,
      shortname: el.shortnameR,
      title: el.titleR,
      type: el.typeR === "" ? (el.typeR = Constants.EMPTY_TRULE) : el.typeR,
      scopeOperations: el.scopeOperationsR,
      operation: el.operationR,
      scopeValues: el.scopeValuesR,
      value: el.valueR,
    };
  });

  return { leftMas: leftMas, rightMas: rightMas };
}
function createTransformrulePredicateMas(transformrule) {
  // console.log("createTransformrulePredicateMas transformrule=", transformrule);
  // transformruleTable
  let table = [];
  let tableObj = {};
  const adultPattern = getAssessmentPattern("Adult");
  const asiPattern = ASIPattern;
  let asiEl = {};
  let adultEl = {};

  const newTransformrulePredicateMas = [];
  const parserBody = transformrule.rule_body.split("( ");
  let predicatesInString = [];
  let tmp = [];
  for (let i = 1; i < parserBody.length; i++) {
    tmp = parserBody[i].split(" )");
    for (let j = 0; j < tmp.length; j++) {
      if (tmp[j] !== "") predicatesInString.push(tmp[j]);
    }
  }

  asiEl = getDetail(asiPattern, predicatesInString[0].split(" ")[0]);
  adultEl = getDetail(adultPattern, predicatesInString[0].split(" ")[2]);

  // console.log("asiEl=",asiEl,"adultEl=",adultEl)
  for (let i = 0; i < predicatesInString.length; i++) {
    // if (i === 0) {
    // switch (asiEl.type) {
    //   case "select":
    //     tableObj.scopeOperationsL =
    //       asiEl.ruletype === "number"
    //         ? transformruleOperations.selectOper
    //         : transformruleOperations.radioOper;
    //     break;
    //   case "checkbox":
    //   case "radio":
    //     tableObj.scopeOperationsL = transformruleOperations.radioOper;
    //     break;
    //   case "content":
    //   case "number":
    //   case "money":
    //     tableObj.scopeOperationsL = transformruleOperations.numberOper;
    //     break;
    //   case "year":
    //   case "month":
    //   case "date":
    //     tableObj.scopeOperationsL = transformruleOperations.yearOper;
    //     break;
    //   default:
    //     tableObj.scopeOperationsL = [];
    //     break;
    // }

    // switch (adultEl.type) {
    //   case "select":
    //     tableObj.scopeOperationsR =
    //       adultEl.ruletype === "number"
    //         ? transformruleOperations.selectOper
    //         : transformruleOperations.radioOper;
    //     break;
    //   case "checkbox":
    //   case "radio":
    //     tableObj.scopeOperationsR = transformruleOperations.radioOper;
    //     break;
    //   case "content":
    //   case "number":
    //   case "money":
    //     tableObj.scopeOperationsR = transformruleOperations.numberOper;
    //     break;
    //   case "year":
    //   case "month":
    //   case "date":
    //     tableObj.scopeOperationsR = transformruleOperations.yearOper;
    //     break;
    //   default:
    //     tableObj.scopeOperationsR = [];
    //     break;
    // }
    // tableObj.idKeyL = Date.now().toString();
    // tableObj.idL = asiEl.id;
    // tableObj.shortnameL = asiEl.shortname;
    // tableObj.titleL = needTransformruleClarification.includes(asiEl.shortname)
    //   ? asiEl.name === ""
    //     ? `${asiEl.id} ${asiEl.subtopic_name}. ${asiEl.prevalue}`
    //     : `${asiEl.id} ${asiEl.subtopic_name}. ${asiEl.prevalue}`
    //   : asiEl.name === ""
    //   ? `${asiEl.id} ${asiEl.prevalue}`
    //   : `${asiEl.id} ${asiEl.name} ${asiEl.prevalue}`;
    // tableObj.typeL = asiEl.type;
    // tableObj.operationL = predicatesInString[i].split(" ")[1];
    // tableObj.scopeValuesL = [];
    // tableObj.valueL = asiEl.value;

    // tableObj.idKeyR = Date.now().toString() + i.toString();
    // tableObj.idR = adultEl.id;
    // tableObj.shortnameL = adultEl.shortname;
    // tableObj.titleR = needTransformruleClarification.includes(
    //   adultEl.shortname
    // )
    //   ? adultEl.name === ""
    //     ? `${adultEl.id} ${adultEl.subtopic_name}. ${adultEl.prevalue}`
    //     : `${adultEl.id} ${adultEl.subtopic_name}. ${adultEl.prevalue}`
    //   : adultEl.name === ""
    //   ? `${adultEl.id} ${adultEl.prevalue}`
    //   : `${adultEl.id} ${adultEl.name} ${adultEl.prevalue}`;
    // tableObj.typeR = adultEl.type;
    // tableObj.operationR = "";
    // tableObj.scopeValuesR = [];
    // tableObj.valueR = adultEl.value;
    table.push(putAsiTable(asiEl, adultEl, predicatesInString[i], i));
    // table.push(tableObj);
    // }
    // if (i > 0) {
    //  table.push(putAsiTable(asiEl,adultEl,predicatesInString[i],i));
    // }
  }
  const transformruleLeftRightMas =
    createTransformrulePredicateMasLeftRight(table);

  return {
    table: table,
    leftMas: transformruleLeftRightMas.leftMas,
    rightMas: transformruleLeftRightMas.rightMas,
  };
}
//========================================================================================
/*                                                                                      *
 *                                      putAsiTable                                     *
 *                                                                                      */
//========================================================================================

function putAsiTable(asiEl, adultEl, predicatesInString, i) {
  // console.log("asiEl=",asiEl,"adultEl=",adultEl,)
  let tableObj = {};
  // console.log(
  //   "predicatesInString[i].split()[1]=",
  //   predicatesInString.split('"')[1]
  // );
  // console.log(
  //   "predicatesInString[i].split()[3]=",
  //   predicatesInString.split('"')[3]
  // );
  // console.log(
  //   "predicatesInString[i].split()[2]=",
  //   predicatesInString.split('"')[2]
  // );

  switch (asiEl.type) {
    case "select":
      tableObj.scopeOperationsL =
        asiEl.ruletype === "number"
          ? transformruleOperations.selectOper
          : transformruleOperations.radioOper;
      break;
    case "checkbox":
    case "radio":
      tableObj.scopeOperationsL = transformruleOperations.radioOper;
      break;
    case "content":
    case "number":
    case "money":
      tableObj.scopeOperationsL = transformruleOperations.numberOper;
      break;
    case "content":
    case "number":
    case "money":
      tableObj.scopeOperationsL = transformruleOperations.numberOper;
      break;
    case "year":
    case "month":
    case "date":
    case "textarea":
    case "editor":
    case "content":
    case "text":
    case "withcode":
    case "withtitle":
      tableObj.scopeOperationsL = transformruleOperations.contentOper;
      break;
      tableObj.scopeOperationsL = transformruleOperations.yearOper;
      break;
    default:
      tableObj.scopeOperationsL = transformruleOperations.defaultOper;
      break;
  }

  switch (adultEl.type) {
    case "select":
      tableObj.scopeOperationsR =
        adultEl.ruletype === "number"
          ? transformruleOperations.selectOper
          : transformruleOperations.radioOper;
      break;
    case "checkbox":
    case "radio":
      tableObj.scopeOperationsR = transformruleOperations.radioOper;
      break;
    case "content":
    case "number":
    case "money":
      tableObj.scopeOperationsR = transformruleOperations.numberOper;
      break;
    case "year":
    case "month":
    case "date":
      tableObj.scopeOperationsR = transformruleOperations.yearOper;
      break;
    case "textarea":
    case "editor":
    case "content":
    case "text":
    case "withcode":
    case "withtitle":
      tableObj.scopeOperationsL = transformruleOperations.contentOper;
      break;
    default:
      tableObj.scopeOperationsL = transformruleOperations.defaultOper;
      break;
  }

  tableObj.idKeyL = Date.now().toString();
  tableObj.idL = asiEl.id;
  tableObj.shortnameL = asiEl.shortname;
  tableObj.titleL = needTransformruleClarification.includes(asiEl.shortname)
    ? asiEl.name === ""
      ? `${asiEl.id} ${asiEl.subtopic_name}. ${asiEl.name} ${asiEl.prevalue}`
      : `${asiEl.id} ${asiEl.subtopic_name}. ${asiEl.name} ${asiEl.prevalue}`
    : asiEl.name === ""
    ? `${asiEl.id} ${asiEl.name} ${asiEl.prevalue}`
    : `${asiEl.id} ${asiEl.name} ${asiEl.prevalue}`;
  tableObj.typeL = i == 0 ? asiEl.type : predicatesInString.split('"')[1];
  // tableObj.operationL = predicatesInString[i].split(" ")[1];
  tableObj.operationL =
    i === 0
      ? predicatesInString.split(" ")[1]
      : predicatesInString.split('"')[2].trim();
  tableObj.scopeValuesL =
    i === 0
      ? []
      : asiEl.type === "select"
      ? asiEl.select_options
      : asiEl.radio_options;
  tableObj.valueL = asiEl.value;

  tableObj.idKeyR = Date.now().toString() + i.toString();
  tableObj.idR = adultEl.id;
  tableObj.shortnameR = adultEl.shortname;
  tableObj.titleR = needTransformruleClarification.includes(adultEl.shortname)
    ? adultEl.name === ""
      ? `${adultEl.id} ${adultEl.subtopic_name}. ${adultEl.name} ${adultEl.prevalue}`
      : `${adultEl.id} ${adultEl.subtopic_name}. ${adultEl.name} ${adultEl.prevalue}`
    : adultEl.name === ""
    ? `${adultEl.id} ${adultEl.name} ${adultEl.prevalue}`
    : `${adultEl.id} ${adultEl.name} ${adultEl.prevalue}`;
  tableObj.typeR =
    i == 0
      ? adultEl.type
      : adultEl.type === Constants.EMPTY_TRULE
      ? ""
      : predicatesInString.split('"')[3];
  tableObj.operationR = "";
  tableObj.scopeValuesR =
    i === 0
      ? []
      : adultEl.type === "select"
      ? adultEl.select_options
      : adultEl.radio_options;
  tableObj.valueR = adultEl.value;
  // console.log("tableObj=", tableObj);
  return tableObj;
}

export {
  createTransformruleRecord,
  checkTransformruleError,
  createTransformrulePredicateMas,
  getConditionByJson,
};
