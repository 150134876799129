const demographicInformationJSON = {
  section: "DEMO",
  topic: "Demographic Information",
  checked: "true",
  color: "secondary",
  description: "",
  body: [
    {
      id: "DI.1",
      shortname: "demo5",
      name: "Client date of birth (defined in Patient Information section)",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "date",
      act: "DEMO5",
      depending: "",
      exception: "",
    },
    {
      id: "DI.2",
      shortname: "demo6",
      name: "What was your current age on the moment of the last information update?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "DEMO6",
      depending: "",
      exception: "",
    },
    {
      id: "DI.3",
      shortname: "demo4",
      name: "Client gender :",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Male", "Female", "Other"],
      act: "DEMO4",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "DEMO4-NEW0",
    },
    {
      id: "DI.3.1",
      shortname: "demo4-new0",
      name: "",
      prevalue: "Sex assigned at birth",
      value: "",
      disabled: "true",
      type: "text",
      act: "DEMO4-NEW0",
      extend: "true",
      depending: "DEMO4",
      exception: "Other",
      link: "DEMO4-NEW1",
    },
    {
      id: "DI.3.2",
      shortname: "demo4-new1",
      name: "",
      prevalue: "If other, explain ?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DEMO4-NEW1",
      extend: "true",
      depending: "DEMO4",
      exception: "Other",
    },
    {
      id: "DI.4",
      shortname: "demo3",
      name: "Of what race do you consider yourself?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "White",
        "African American",
        "Native American",
        "Pacific Islander",
        "Hispanic",
        "Asian",
        "Mixed",
      ],
      act: "DEMO3",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "DEMO3-1",
    },
    {
      id: "DI.4.1",
      shortname: "demo3-1",
      name: "",
      prevalue: "If Mixed, explain ?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DEMO3-1",
      depending: "DEMO3",
      exception: "Mixed",
      extend: "true",
      link: "DEMO3-2",
    },
    {
      id: "DI.4.2",
      shortname: "demo3-2",
      name: "",
      prevalue: "Hispanic:",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "Mexican", "Puerto Rican", "Cuban", "Other"],
      act: "DEMO3-2",
      depending: "DEMO3",
      exception: "Hispanic",
      extend: "true",
    },
    {
      id: "DI.5",
      shortname: "demo1",
      name: "What is your marital status?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "single",
        "married",
        "divorced",
        "widowed",
        "in a committed relationship",
        "separated",
      ],
      act: "DEMO1",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "DI.6",
      shortname: "demo2",
      name: "Are you satisfied with this status?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["satisfied", "not satisfied", "ambivalent"],
      act: "DEMO2",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "DEMO2-ADD1",
    },
    {
      id: "DI.6.1",
      extend: "true",
      shortname: "demo2-add1",
      name: "",
      prevalue: "if not satisfied/ambivalent, explain?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DEMO2-ADD1",
      depending: "DEMO2",
      exception: "satisfied",
    },
    {
      id: "DI.7",
      shortname: "demo7",
      name: "Where were you born? (City/State/Country)",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "text",
      act: "DEMO7",
      depending: "",
      exception: "",
      link: "DEMO7-ADD",
    },
    {
      id: "DI.8",
      extend: "true",
      shortname: "demo7-add",
      name: "",
      prevalue: "Is this the same location where you were raised?",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DEMO7-ADD",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "DEMO8",
    },
    {
      id: "DI.8.1",
      shortname: "demo8",
      name: "",
      prevalue: "Where were you mostly raised? (City/State/Country)",
      value: "",
      disabled: "true",
      type: "text",
      act: "DEMO8",
      depending: "DEMO7-ADD",
      exception: "No",
      extend: "true",
    },
    {
      id: "DI.9",
      shortname: "demo9",
      name: "What is your religious preference?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "none",
        "Catholic",
        "Protestant",
        "Judaism",
        "Buddhism",
        "Hinduism ",
        "Islam",
        "Other",
      ],
      act: "DEMO9",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "DEMO9-NEW1",
    },
    {
      id: "DI.9.1",
      shortname: "demo9-new1",
      name: "",
      prevalue: "If other, explain?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DEMO9-NEW1",
      depending: "DEMO9",
      exception: "Other",
      extend: "true",
      link: "DEMO9-NEW2",
    },
    {
      id: "DI.9.2",
      shortname: "demo9-new2",
      name: "",
      prevalue: "What specific Protestant denomination do you attend?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DEMO9-NEW2",
      depending: "DEMO9",
      exception: "Protestant",
      extend: "true",
    },
    {
      id: "DI.10",
      shortname: "demo9-adol1",
      name: "Have you EVER had a belief in a “God” or a “Higher Power?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DEMO9-ADOL1",
      depending: "",
      exception: "",
    },
    {
      id: "DI.11",
      shortname: "demo9-adol2",
      name: "Have you EVER prayed or mediated in line with your spirituality/beliefs?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DEMO9-ADOL2",
      depending: "",
      exception: "",
    },
    {
      id: "DI.12",
      shortname: "demo9-adol3",
      name: "Do you want to learn more about a particular religion?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DEMO9-ADOL3",
      depending: "",
      exception: "",
      link: "DEMO9-ADOL3-1",
    },
    {
      id: "DI.12.1",
      shortname: "demo9-adol3-1",
      name: "",
      prevalue: "If yes, explain ?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DEMO9-ADOL3-1",
      extend: "true",
      depending: "DEMO9-ADOL3",
      exception: "Yes",
    },
    {
      id: "DI.13",
      shortname: "demo10",
      name: "How many years have you lived at the address you provided us?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "less than one year",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
        "more than ten",
      ],
      act: "DEMO10",
      depending: "",
      exception: "",
    },
    {
      id: "DI.14",
      shortname: "demo11",
      name: "Is this residence owned by you or your family?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DEMO11",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "DEMO11-NEW1",
    },
    {
      id: "DI.14.1",
      shortname: "demo11-new1",
      name: "",
      prevalue: "If Yes, please specify Family or Self ?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Family", "Self"],
      act: "DEMO11-NEW1",
      depending: "DEMO11",
      exception: "Yes",
      link: "DEMO11-NEW2",
      extend: "true",
    },
    {
      id: "DI.14.2",
      shortname: "demo11-new2",
      name: "",
      prevalue:
        "If No, please specify: rent, dormitory, stay with others, prison/jail?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["rent", "dormitory", " stay with others", "prison/jail"],
      act: "DEMO11-NEW2",
      depending: "DEMO11",
      exception: "No",
      extend: "true",
    },
    {
      id: "DI.15",
      shortname: "demo11-adol1",
      name: "What is the geographic type of your primary residence?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: ["", "Urban", "Suburban", "Rural"],
      act: "DEMO11-ADOL1",
      depending: "",
      exception: "",
      mandatory: "true",
    },
    {
      id: "DI.16",
      shortname: "demo11-adol2",
      name: "What is your primary language?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "English",
        "Spanish",
        "Chinese",
        "French",
        "Russian",
        "German",
        "Vietnamese",
        "Portuguese",
        "Farsi",
        "Arabic",
        "Other",
      ],
      act: "DEMO11-ADOL2",
      depending: "",
      exception: "",
      mandatory: "true",
      link: "DEMO11-ADOL2-1",
    },
    {
      id: "DI.16.1",
      shortname: "demo11-adol2-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DEMO11-ADOL2-1",
      depending: "DEMO11-ADOL2",
      exception: "Other",
      extend: "true",
    },
  ],
};

export default demographicInformationJSON;
