const blockMeth = [
  //!========================================================================================
  /*                                                                                      *
   *                                    STARTBLOCKMETH                                    *
   *                                                                                      */
  //!========================================================================================
  {
    id: "DA.1.1.239",
    shortname: "meth-block-subtopic",
    name: "Direction for Meth: Please answer the items in the grid below. Have there EVER been significant periods during which you...",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "subtopic",
    act: "METH-BLOCK-SUBTOPIC",
    depending: "DA0",
    exception: "Meth or other Amphetamines",
    condition: "in",
  },
  {
    id: "DA.1.1.240",
    shortname: "meth-block-6",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METH-BLOCK-6",
    depending: "DA0",
    exception: "Meth or other Amphetamines",
    condition: "in",
    link: "METH-BLOCK-7",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.240.1",
    extend: "true",
    shortname: "meth-block-7",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METH-BLOCK-7",
    depending: "METH-BLOCK-6",
    exception: "This has happened",
    link: "METH-BLOCK-8",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.240.2",
    extend: "true",
    shortname: "meth-block-8",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-8",
    depending: "METH-BLOCK-6",
    exception: "This has happened",
    link: "METH-BLOCK-9",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.240.3",
    extend: "true",
    shortname: "meth-block-9",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-9",
    depending: "METH-BLOCK-6",
    exception: "This has happened",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.241",
    shortname: "meth-block-10",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METH-BLOCK-10",
    depending: "DA0",
    exception: "Meth or other Amphetamines",
    condition: "in",
    link: "METH-BLOCK-11",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.241.1",
    extend: "true",
    shortname: "meth-block-11",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METH-BLOCK-11",
    depending: "METH-BLOCK-10",
    exception: "This has happened",
    link: "METH-BLOCK-12",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.241.2",
    extend: "true",
    shortname: "meth-block-12",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-12",
    depending: "METH-BLOCK-10",
    exception: "This has happened",
    link: "METH-BLOCK-13",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.241.3",
    extend: "true",
    shortname: "meth-block-13",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-13",
    depending: "METH-BLOCK-10",
    exception: "This has happened",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.242",
    shortname: "meth-block-14",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METH-BLOCK-14",
    depending: "DA0",
    exception: "Meth or other Amphetamines",
    condition: "in",
    link: "METH-BLOCK-15",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.242.1",
    extend: "true",
    shortname: "meth-block-15",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METH-BLOCK-15",
    depending: "METH-BLOCK-14",
    exception: "This has happened",
    link: "METH-BLOCK-16",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.242.2",
    extend: "true",
    shortname: "meth-block-16",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-16",
    depending: "METH-BLOCK-14",
    exception: "This has happened",
    link: "METH-BLOCK-17",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.242.3",
    extend: "true",
    shortname: "meth-block-17",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-17",
    depending: "METH-BLOCK-14",
    exception: "This has happened",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.243",
    shortname: "meth-block-18",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METH-BLOCK-18",
    depending: "DA0",
    exception: "Meth or other Amphetamines",
    condition: "in",
    link: "METH-BLOCK-19",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.243.1",
    extend: "true",
    shortname: "meth-block-19",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METH-BLOCK-19",
    depending: "METH-BLOCK-18",
    exception: "This has happened",
    link: "METH-BLOCK-30",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.243.2",
    extend: "true",
    shortname: "meth-block-20",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-20",
    depending: "METH-BLOCK-18",
    exception: "This has happened",
    link: "METH-BLOCK-21",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.243.3",
    extend: "true",
    shortname: "meth-block-21",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-21",
    depending: "METH-BLOCK-18",
    exception: "This has happened",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.244",
    shortname: "meth-block-22",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METH-BLOCK-22",
    depending: "DA0",
    exception: "Meth or other Amphetamines",
    condition: "in",
    link: "METH-BLOCK-23",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.244.1",
    extend: "true",
    shortname: "meth-block-23",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METH-BLOCK-23",
    depending: "METH-BLOCK-22",
    exception: "This has happened",
    link: "METH-BLOCK-24",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.244.2",
    extend: "true",
    shortname: "meth-block-24",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-24",
    depending: "METH-BLOCK-22",
    exception: "This has happened",
    link: "METH-BLOCK-25",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.244.3",
    extend: "true",
    shortname: "meth-block-25",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-25",
    depending: "METH-BLOCK-22",
    exception: "This has happened",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.245",
    shortname: "meth-block-26",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METH-BLOCK-26",
    depending: "DA0",
    exception: "Meth or other Amphetamines",
    condition: "in",
    link: "METH-BLOCK-27",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.245.1",
    extend: "true",
    shortname: "meth-block-27",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METH-BLOCK-27",
    depending: "METH-BLOCK-26",
    exception: "This has happened",
    link: "METH-BLOCK-28",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.245.2",
    extend: "true",
    shortname: "meth-block-28",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-28",
    depending: "METH-BLOCK-26",
    exception: "This has happened",
    link: "METH-BLOCK-29",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.245.3",
    extend: "true",
    shortname: "meth-block-29",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-29",
    depending: "METH-BLOCK-26",
    exception: "This has happened",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.246",
    shortname: "meth-block-30",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METH-BLOCK-30",
    depending: "DA0",
    exception: "Meth or other Amphetamines",
    condition: "in",
    link: "METH-BLOCK-31",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.246.1",
    extend: "true",
    shortname: "meth-block-31",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METH-BLOCK-31",
    depending: "METH-BLOCK-30",
    exception: "This has happened",
    link: "METH-BLOCK-32",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.246.2",
    extend: "true",
    shortname: "meth-block-32",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-32",
    depending: "METH-BLOCK-30",
    exception: "This has happened",
    link: "METH-BLOCK-33",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.246.3",
    extend: "true",
    shortname: "meth-block-33",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-33",
    depending: "METH-BLOCK-30",
    exception: "This has happened",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.247",
    shortname: "meth-block-34",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METH-BLOCK-34",
    depending: "DA0",
    exception: "Meth or other Amphetamines",
    condition: "in",
    link: "METH-BLOCK-35",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.247.1",
    extend: "true",
    shortname: "meth-block-35",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METH-BLOCK-35",
    depending: "METH-BLOCK-34",
    exception: "This has happened",
    link: "METH-BLOCK-36",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.247.2",
    extend: "true",
    shortname: "meth-block-36",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-36",
    depending: "METH-BLOCK-34",
    exception: "This has happened",
    link: "METH-BLOCK-37",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.247.3",
    extend: "true",
    shortname: "meth-block-37",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-37",
    depending: "METH-BLOCK-34",
    exception: "This has happened",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.248",
    shortname: "meth-block-38",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METH-BLOCK-38",
    depending: "DA0",
    exception: "Meth or other Amphetamines",
    condition: "in",
    link: "METH-BLOCK-39",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.248.1",
    extend: "true",
    shortname: "meth-block-39",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METH-BLOCK-39",
    depending: "METH-BLOCK-38",
    exception: "This has happened",
    link: "METH-BLOCK0",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.248.2",
    extend: "true",
    shortname: "meth-block-40",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-40",
    depending: "METH-BLOCK-38",
    exception: "This has happened",
    link: "METH-BLOCK-41",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.248.3",
    extend: "true",
    shortname: "meth-block-41",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-41",
    depending: "METH-BLOCK-38",
    exception: "This has happened",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.249",
    shortname: "meth-block-42",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METH-BLOCK-42",
    depending: "DA0",
    exception: "Meth or other Amphetamines",
    condition: "in",
    link: "METH-BLOCK-43",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.249.1",
    extend: "true",
    shortname: "meth-block-43",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METH-BLOCK-43",
    depending: "METH-BLOCK-42",
    exception: "This has happened",
    link: "METH-BLOCK-44",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.249.2",
    extend: "true",
    shortname: "meth-block-44",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-44",
    depending: "METH-BLOCK-42",
    exception: "This has happened",
    link: "METH-BLOCK-45",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.249.3",
    extend: "true",
    shortname: "meth-block-45",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-45",
    depending: "METH-BLOCK-42",
    exception: "This has happened",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.250",
    shortname: "meth-block-46",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "METH-BLOCK-46",
    depending: "DA0",
    exception: "Meth or other Amphetamines",
    condition: "in",
    link: "METH-BLOCK-47",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.250.1",
    extend: "true",
    shortname: "meth-block-47",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "METH-BLOCK-47",
    depending: "METH-BLOCK-46",
    exception: "This has happened",
    link: "METH-BLOCK-48",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.250.2",
    extend: "true",
    shortname: "meth-block-48",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-48",
    depending: "METH-BLOCK-46",
    exception: "This has happened",
    link: "METH-BLOCK-49",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.250.3",
    extend: "true",
    shortname: "meth-block-49",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "METH-BLOCK-49",
    depending: "METH-BLOCK-46",
    exception: "This has happened",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.251",
    shortname: "meth-block-50",
    name: "Number of criteria met for:",
    prevalue: "Number of criteria met for Meth or other Amphetamines:",
    value: "0",
    disabled: "true",
    type: "content",
    comment: "Consider no diagnosis for Meth or other Amphetamines",
    act: "METH-BLOCK-50",
    depending: "DA0",
    exception: "Meth or other Amphetamines",
    condition: "in",
    meaningfulcount: "This has happened",
    subtopic_container_act: "METH-BLOCK-SUBTOPIC",
    suptopic_code: "METH-BLOCK-12-CONTENT",
    subtopic_name: "Content Criteria",
  },
  //!========================================================================================
  /*                                                                                      *
   *                                     ENDBLOCKMETH                                     *
   *                                                                                      */
  //!========================================================================================
];

export default blockMeth;
