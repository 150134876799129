const legalHistoryJSON = {
  section: "LEG",
  topic: "Legal History",
  checked: "true",
  color: "secondary",
  description: "",
  body: [
    {
      id: "",
      shortname: "le-0-adol1",
      name: "NOTE: Items have been defaulted to (  ) No. Please change to (  ) Yes if the item applies to you and answer the additional questions.",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "text",
      act: "LE-0-ADOL1",
      depending: "",
      exception: "",
    },
    {
      id: "LEG.1",
      shortname: "le-new1-adol1",
      name: "Have you EVER committed a crime?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW1-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW1-1-ADOL1",
    },
    {
      id: "LEG.1.1",
      extend: "true",
      shortname: "le-new1-1-adol1",
      name: "",
      prevalue: "At what age did you first commit a crime?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW1-1-ADOL1",
      depending: "LE-NEW1-ADOL1",
      exception: "Yes",
      link: "LE-NEW1-2-ADOL1",
    },
    {
      id: "LEG.1.2",
      extend: "true",
      shortname: "le-new1-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW1-2-ADOL1",
      depending: "LE-NEW1-ADOL1",
      exception: "Yes",
      link: "LE-NEW1-3-ADOL1",
    },
    {
      id: "LEG.1.3",
      extend: "true",
      shortname: "le-new1-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW1-3-ADOL1",
      depending: "LE-NEW1-ADOL1",
      exception: "Yes",
      link: "LE-NEW1-C-ADOL1",
    },
    {
      id: "LEG.1.4",
      extend: "true",
      shortname: "le-new1-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW1-C-ADOL1",
      depending: "LE-NEW1-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.2",
      shortname: "le-new2-adol1",
      name: "Have you EVER been picked up by the police?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW2-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW2-1-ADOL1",
    },
    {
      id: "LEG.2.1",
      extend: "true",
      shortname: "le-new2-1-adol1",
      name: "",
      prevalue: "At what age were you first picked up?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW2-1-ADOL1",
      depending: "LE-NEW2-ADOL1",
      exception: "Yes",
      link: "LE-NEW2-2-ADOL1",
    },
    {
      id: "LEG.2.2",
      extend: "true",
      shortname: "le-new2-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW2-2-ADOL1",
      depending: "LE-NEW2-ADOL1",
      exception: "Yes",
      link: "LE-NEW2-3-ADOL1",
    },
    {
      id: "LEG.2.3",
      extend: "true",
      shortname: "le-new2-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW2-3-ADOL1",
      depending: "LE-NEW2-ADOL1",
      exception: "Yes",
      link: "LE-NEW2-C-ADOL1",
    },
    {
      id: "LEG.2.4",
      extend: "true",
      shortname: "le-new2-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW2-C-ADOL1",
      depending: "LE-NEW2-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.3",
      shortname: "le-new3-adol1",
      name: "Have you EVER been charged with a crime?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW3-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW3-1-ADOL1",
    },
    {
      id: "LEG.3.1",
      extend: "true",
      shortname: "le-new3-1-adol1",
      name: "",
      prevalue: "At what age did you first get charged with a crime?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW3-1-ADOL1",
      depending: "LE-NEW3-ADOL1",
      exception: "Yes",
      link: "LE-NEW3-2-ADOL1",
    },
    {
      id: "LEG.3.2",
      extend: "true",
      shortname: "le-new3-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW3-2-ADOL1",
      depending: "LE-NEW3-ADOL1",
      exception: "Yes",
      link: "LE-NEW3-3-ADOL1",
    },
    {
      id: "LEG.3.3",
      extend: "true",
      shortname: "le-new3-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW3-3-ADOL1",
      depending: "LE-NEW3-ADOL1",
      exception: "Yes",
      link: "LE-NEW3-C-ADOL1",
    },
    {
      id: "LEG.3.4",
      extend: "true",
      shortname: "le-new3-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW3-C-ADOL1",
      depending: "LE-NEW3-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.4",
      shortname: "le-new4-adol1",
      name: "Have you EVER been convicted of a crime?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW4-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW4-1-ADOL1",
    },
    {
      id: "LEG.4.1",
      extend: "true",
      shortname: "le-new4-1-adol1",
      name: "",
      prevalue: "At what age did you first get convicted of a crime?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW4-1-ADOL1",
      depending: "LE-NEW4-ADOL1",
      exception: "Yes",
      link: "LE-NEW4-2-ADOL1",
    },
    {
      id: "LEG.4.2",
      extend: "true",
      shortname: "le-new4-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW4-2-ADOL1",
      depending: "LE-NEW4-ADOL1",
      exception: "Yes",
      link: "LE-NEW4-3-ADOL1",
    },
    {
      id: "LEG.4.3",
      extend: "true",
      shortname: "le-new4-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW4-3-ADOL1",
      depending: "LE-NEW4-ADOL1",
      exception: "Yes",
      link: "LE-NEW4-C-ADOL1",
    },
    {
      id: "LEG.4.4",
      extend: "true",
      shortname: "le-new4-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW4-C-ADOL1",
      depending: "LE-NEW4-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.5",
      shortname: "le-new5-adol1",
      name: "Have you EVER spent time in a juvenile detention facility or jail?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW5-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW5-1-ADOL1",
    },
    {
      id: "LEG.5.1",
      extend: "true",
      shortname: "le-new5-1-adol1",
      name: "",
      prevalue:
        "At what age did you first spend time in a juvenile facility or jail?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW5-1-ADOL1",
      depending: "LE-NEW5-ADOL1",
      exception: "Yes",
      link: "LE-NEW5-2-ADOL1",
    },
    {
      id: "LEG.5.2",
      extend: "true",
      shortname: "le-new5-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW5-2-ADOL1",
      depending: "LE-NEW5-ADOL1",
      exception: "Yes",
      link: "LE-NEW5-3-ADOL1",
    },
    {
      id: "LEG.5.3",
      extend: "true",
      shortname: "le-new5-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW5-3-ADOL1",
      depending: "LE-NEW5-ADOL1",
      exception: "Yes",
      link: "LE-NEW5-C-ADOL1",
    },
    {
      id: "LEG.5.4",
      extend: "true",
      shortname: "le-new5-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW5-C-ADOL1",
      depending: "LE-NEW5-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.6",
      shortname: "le-new6-adol1",
      name: "Have you EVER been on probation?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW6-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW6-1-ADOL1",
    },
    {
      id: "LEG.6.1",
      extend: "true",
      shortname: "le-new6-1-adol1",
      name: "",
      prevalue: "At what age did you first get put on probation?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW6-1-ADOL1",
      depending: "LE-NEW6-ADOL1",
      exception: "Yes",
      link: "LE-NEW6-2-ADOL1",
    },
    {
      id: "LEG.6.2",
      extend: "true",
      shortname: "le-new6-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW6-2-ADOL1",
      depending: "LE-NEW6-ADOL1",
      exception: "Yes",
      link: "LE-NEW6-3-ADOL1",
    },
    {
      id: "LEG.6.3",
      extend: "true",
      shortname: "le-new6-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW6-3-ADOL1",
      depending: "LE-NEW6-ADOL1",
      exception: "Yes",
      link: "LE-NEW6-C-ADOL1",
    },
    {
      id: "LEG.6.4",
      extend: "true",
      shortname: "le-new6-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW6-C-ADOL1",
      depending: "LE-NEW6-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.7",
      shortname: "le-new1",
      name: "Are you currently on Probation?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW1",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "LE-NEW2",
    },
    {
      id: "LEG.7.1",
      shortname: "le-new2",
      name: "",
      prevalue: "Probation Officer's name",
      extend: "true",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE-NEW2",
      depending: "LE-NEW1",
      exception: "Yes",
      link: "LE-NEW3",
    },
    {
      id: "LEG.7.2",
      shortname: "le-new3",
      name: "",
      prevalue: "Court/Jurisdiction",
      extend: "true",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE-NEW3",
      depending: "LE-NEW1",
      exception: "Yes",
    },
    {
      id: "LEG.8",
      shortname: "le-new7-adol1",
      name: "Have you filed or obtained a restraining order against someone else?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW7-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW7-1-ADOL1",
    },
    {
      id: "LEG.8.1",
      extend: "true",
      shortname: "le-new7-1-adol1",
      name: "",
      prevalue: "At what age did you first obtain a restraining order?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW7-1-ADOL1",
      depending: "LE-NEW7-ADOL1",
      exception: "Yes",
      link: "LE-NEW7-2-ADOL1",
    },
    {
      id: "LEG.8.2",
      extend: "true",
      shortname: "le-new7-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW7-2-ADOL1",
      depending: "LE-NEW7-ADOL1",
      exception: "Yes",
      link: "LE-NEW7-3-ADOL1",
    },
    {
      id: "LEG.8.3",
      extend: "true",
      shortname: "le-new7-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW7-3-ADOL1",
      depending: "LE-NEW7-ADOL1",
      exception: "Yes",
      link: "LE-NEW7-C-ADOL1",
    },
    {
      id: "LEG.8.4",
      extend: "true",
      shortname: "le-new7-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW7-C-ADOL1",
      depending: "LE-NEW7-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.9",
      shortname: "le-new8-adol1",
      name: "Have you EVER had a restraining order filed or obtained against you?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW8-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW8-1-ADOL1",
    },
    {
      id: "LEG.9.1",
      extend: "true",
      shortname: "le-new8-1-adol1",
      name: "",
      prevalue:
        "At what age did you first have a restraining order against you?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW8-1-ADOL1",
      depending: "LE-NEW8-ADOL1",
      exception: "Yes",
      link: "LE-NEW8-2-ADOL1",
    },
    {
      id: "LEG.9.2",
      extend: "true",
      shortname: "le-new8-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW8-2-ADOL1",
      depending: "LE-NEW8-ADOL1",
      exception: "Yes",
      link: "LE-NEW8-3-ADOL1",
    },
    {
      id: "LEG.9.3",
      extend: "true",
      shortname: "le-new8-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW8-3-ADOL1",
      depending: "LE-NEW8-ADOL1",
      exception: "Yes",
      link: "LE-NEW8-C-ADOL1",
    },
    {
      id: "LEG.9.4",
      extend: "true",
      shortname: "le-new8-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW8-C-ADOL1",
      depending: "LE-NEW8-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.10",
      shortname: "le-new9-adol1",
      name: "Have you EVER committed, been charged with or been convicted of running away?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW9-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW9-1-ADOL1",
    },
    {
      id: "LEG.10.1",
      extend: "true",
      shortname: "le-new9-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW9-1-ADOL1",
      depending: "LE-NEW9-ADOL1",
      exception: "Yes",
      link: "LE-NEW9-2-ADOL1",
    },
    {
      id: "LEG.10.2",
      extend: "true",
      shortname: "le-new9-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW9-2-ADOL1",
      depending: "LE-NEW9-ADOL1",
      exception: "Yes",
      link: "LE-NEW9-3-ADOL1",
    },
    {
      id: "LEG.10.3",
      extend: "true",
      shortname: "le-new9-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW9-3-ADOL1",
      depending: "LE-NEW9-ADOL1",
      exception: "Yes",
      link: "LE-NEW9-C-ADOL1",
    },
    {
      id: "LEG.10.4",
      extend: "true",
      shortname: "le-new9-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW9-C-ADOL1",
      depending: "LE-NEW9-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.11",
      shortname: "le-new10-adol1",
      name: "Have you EVER committed, been charged with or been convicted of incorrigibility?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW10-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW10-1-ADOL1",
    },
    {
      id: "LEG.11.1",
      extend: "true",
      shortname: "le-new10-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW10-1-ADOL1",
      depending: "LE-NEW10-ADOL1",
      exception: "Yes",
      link: "LE-NEW10-2-ADOL1",
    },
    {
      id: "LEG.11.2",
      extend: "true",
      shortname: "le-new10-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW10-2-ADOL1",
      depending: "LE-NEW10-ADOL1",
      exception: "Yes",
      link: "LE-NEW10-3-ADOL1",
    },
    {
      id: "LEG.11.3",
      extend: "true",
      shortname: "le-new10-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW10-3-ADOL1",
      depending: "LE-NEW10-ADOL1",
      exception: "Yes",
      link: "LE-NEW10-C-ADOL1",
    },
    {
      id: "LEG.11.4",
      extend: "true",
      shortname: "le-new10-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW10-C-ADOL1",
      depending: "LE-NEW10-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.12",
      shortname: "le-new11-adol1",
      name: "Have you EVER committed, been charged with or been convicted of truancy?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW11-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW11-1-ADOL1",
    },
    {
      id: "LEG.12.1",
      extend: "true",
      shortname: "le-new11-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW11-1-ADOL1",
      depending: "LE-NEW11-ADOL1",
      exception: "Yes",
      link: "LE-NEW11-2-ADOL1",
    },
    {
      id: "LEG.12.2",
      extend: "true",
      shortname: "le-new11-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW11-2-ADOL1",
      depending: "LE-NEW11-ADOL1",
      exception: "Yes",
      link: "LE-NEW11-3-ADOL1",
    },
    {
      id: "LEG.12.3",
      extend: "true",
      shortname: "le-new11-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW11-3-ADOL1",
      depending: "LE-NEW11-ADOL1",
      exception: "Yes",
      link: "LE-NEW11-C-ADOL1",
    },
    {
      id: "LEG.12.4",
      extend: "true",
      shortname: "le-new11-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW11-C-ADOL1",
      depending: "LE-NEW11-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.13",
      shortname: "le-new12-adol1",
      name: "Have you EVER committed, been charged with or been convicted of any driving violations (including DWI/DUI)?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW12-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW12-1-ADOL1",
    },
    {
      id: "LEG.13.1",
      extend: "true",
      shortname: "le-new12-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW12-1-ADOL1",
      depending: "LE-NEW12-ADOL1",
      exception: "Yes",
      link: "LE-NEW12-2-ADOL1",
    },
    {
      id: "LEG.13.2",
      extend: "true",
      shortname: "le-new12-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW12-2-ADOL1",
      depending: "LE-NEW12-ADOL1",
      exception: "Yes",
      link: "LE-NEW12-3-ADOL1",
    },
    {
      id: "LEG.13.3",
      extend: "true",
      shortname: "le-new12-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW12-3-ADOL1",
      depending: "LE-NEW12-ADOL1",
      exception: "Yes",
      link: "LE-NEW12-C-ADOL1",
    },
    {
      id: "LEG.13.4",
      extend: "true",
      shortname: "le-new12-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW12-C-ADOL1",
      depending: "LE-NEW12-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.14",
      shortname: "le-new13-adol1",
      name: "Have you EVER committed, been charged with or been convicted of loitering, vagrancy?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW13-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW13-1-ADOL1",
    },
    {
      id: "LEG.14.1",
      extend: "true",
      shortname: "le-new13-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW13-1-ADOL1",
      depending: "LE-NEW13-ADOL1",
      exception: "Yes",
      link: "LE-NEW13-2-ADOL1",
    },
    {
      id: "LEG.14.2",
      extend: "true",
      shortname: "le-new13-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW13-2-ADOL1",
      depending: "LE-NEW13-ADOL1",
      exception: "Yes",
      link: "LE-NEW13-3-ADOL1",
    },
    {
      id: "LEG.14.3",
      extend: "true",
      shortname: "le-new13-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW13-3-ADOL1",
      depending: "LE-NEW13-ADOL1",
      exception: "Yes",
      link: "LE-NEW13-C-ADOL1",
    },
    {
      id: "LEG.14.4",
      extend: "true",
      shortname: "le-new13-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW13-C-ADOL1",
      depending: "LE-NEW13-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.15",
      shortname: "le-new14-adol1",
      name: "Have you EVER committed, been charged with or been convicted of Disorderly Conduct, Public Drunkenness?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW14-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW14-1-ADOL1",
    },
    {
      id: "LEG.15.1",
      extend: "true",
      shortname: "le-new14-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW14-1-ADOL1",
      depending: "LE-NEW14-ADOL1",
      exception: "Yes",
      link: "LE-NEW14-2-ADOL1",
    },
    {
      id: "LEG.15.2",
      extend: "true",
      shortname: "le-new14-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW14-2-ADOL1",
      depending: "LE-NEW14-ADOL1",
      exception: "Yes",
      link: "LE-NEW14-3-ADOL1",
    },
    {
      id: "LEG.15.3",
      extend: "true",
      shortname: "le-new14-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW14-3-ADOL1",
      depending: "LE-NEW14-ADOL1",
      exception: "Yes",
      link: "LE-NEW14-C-ADOL1",
    },
    {
      id: "LEG.15.4",
      extend: "true",
      shortname: "le-new14-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW14-C-ADOL1",
      depending: "LE-NEW14-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.16",
      shortname: "le-new15-adol1",
      name: "Have you EVER committed, been charged with or been convicted of Vandalism, Criminal Mischief?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW15-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW15-1-ADOL1",
    },
    {
      id: "LEG.16.1",
      extend: "true",
      shortname: "le-new15-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW15-1-ADOL1",
      depending: "LE-NEW15-ADOL1",
      exception: "Yes",
      link: "LE-NEW15-2-ADOL1",
    },
    {
      id: "LEG.16.2",
      extend: "true",
      shortname: "le-new15-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW15-2-ADOL1",
      depending: "LE-NEW15-ADOL1",
      exception: "Yes",
      link: "LE-NEW15-3-ADOL1",
    },
    {
      id: "LEG.16.3",
      extend: "true",
      shortname: "le-new15-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW15-3-ADOL1",
      depending: "LE-NEW15-ADOL1",
      exception: "Yes",
      link: "LE-NEW15-C-ADOL1",
    },
    {
      id: "LEG.16.4",
      extend: "true",
      shortname: "le-new15-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW15-C-ADOL1",
      depending: "LE-NEW15-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.17",
      shortname: "le-new16-adol1",
      name: "Have you EVER committed, been charged with or been convicted of Terroristic Threats/Harassment?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW16-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW16-1-ADOL1",
    },
    {
      id: "LEG.17.1",
      extend: "true",
      shortname: "le-new16-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW16-1-ADOL1",
      depending: "LE-NEW16-ADOL1",
      exception: "Yes",
      link: "LE-NEW16-2-ADOL1",
    },
    {
      id: "LEG.17.2",
      extend: "true",
      shortname: "le-new16-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW16-2-ADOL1",
      depending: "LE-NEW16-ADOL1",
      exception: "Yes",
      link: "LE-NEW16-3-ADOL1",
    },
    {
      id: "LEG.17.3",
      extend: "true",
      shortname: "le-new16-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW16-3-ADOL1",
      depending: "LE-NEW16-ADOL1",
      exception: "Yes",
      link: "LE-NEW16-C-ADOL1",
    },
    {
      id: "LEG.17.4",
      extend: "true",
      shortname: "le-new16-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW16-C-ADOL1",
      depending: "LE-NEW16-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.18",
      shortname: "le-new17-adol1",
      name: "Have you EVER committed, been charged with or been convicted of Drug Charges?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW17-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW17-1-ADOL1",
    },
    {
      id: "LEG.18.1",
      extend: "true",
      shortname: "le-new17-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW17-1-ADOL1",
      depending: "LE-NEW17-ADOL1",
      exception: "Yes",
      link: "LE-NEW17-2-ADOL1",
    },
    {
      id: "LEG.18.2",
      extend: "true",
      shortname: "le-new17-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW17-2-ADOL1",
      depending: "LE-NEW17-ADOL1",
      exception: "Yes",
      link: "LE-NEW17-3-ADOL1",
    },
    {
      id: "LEG.18.3",
      extend: "true",
      shortname: "le-new17-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW17-3-ADOL1",
      depending: "LE-NEW17-ADOL1",
      exception: "Yes",
      link: "LE-NEW17-C-ADOL1",
    },
    {
      id: "LEG.18.4",
      extend: "true",
      shortname: "le-new17-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW17-C-ADOL1",
      depending: "LE-NEW17-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.19",
      shortname: "le-new18-adol1",
      name: "Have you EVER committed, been charged with or been convicted of Drug Dealing?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW18-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW18-1-ADOL1",
    },
    {
      id: "LEG.19.1",
      extend: "true",
      shortname: "le-new18-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW18-1-ADOL1",
      depending: "LE-NEW18-ADOL1",
      exception: "Yes",
      link: "LE-NEW18-2-ADOL1",
    },
    {
      id: "LEG.19.2",
      extend: "true",
      shortname: "le-new18-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW18-2-ADOL1",
      depending: "LE-NEW18-ADOL1",
      exception: "Yes",
      link: "LE-NEW18-3-ADOL1",
    },
    {
      id: "LEG.19.3",
      extend: "true",
      shortname: "le-new18-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW18-3-ADOL1",
      depending: "LE-NEW18-ADOL1",
      exception: "Yes",
      link: "LE-NEW18-C-ADOL1",
    },
    {
      id: "LEG.19.4",
      extend: "true",
      shortname: "le-new18-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW18-C-ADOL1",
      depending: "LE-NEW18-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.20",
      shortname: "le-new19-adol1",
      name: "Have you EVER committed, been charged with or been convicted of Shoplifting, Petty Theft?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW19-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW19-1-ADOL1",
    },
    {
      id: "LEG.20.1",
      extend: "true",
      shortname: "le-new19-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW19-1-ADOL1",
      depending: "LE-NEW19-ADOL1",
      exception: "Yes",
      link: "LE-NEW19-2-ADOL1",
    },
    {
      id: "LEG.20.2",
      extend: "true",
      shortname: "le-new19-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW19-2-ADOL1",
      depending: "LE-NEW19-ADOL1",
      exception: "Yes",
      link: "LE-NEW19-3-ADOL1",
    },
    {
      id: "LEG.20.3",
      extend: "true",
      shortname: "le-new19-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW19-3-ADOL1",
      depending: "LE-NEW19-ADOL1",
      exception: "Yes",
      link: "LE-NEW19-C-ADOL1",
    },
    {
      id: "LEG.20.4",
      extend: "true",
      shortname: "le-new19-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW19-C-ADOL1",
      depending: "LE-NEW19-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.21",
      shortname: "le-new20-adol1",
      name: "Have you EVER committed, been charged with or been convicted of Receiving Stolen Good or Property?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW20-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW20-1-ADOL1",
    },
    {
      id: "LEG.21.1",
      extend: "true",
      shortname: "le-new20-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW20-1-ADOL1",
      depending: "LE-NEW20-ADOL1",
      exception: "Yes",
      link: "LE-NEW20-2-ADOL1",
    },
    {
      id: "LEG.21.2",
      extend: "true",
      shortname: "le-new20-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW20-2-ADOL1",
      depending: "LE-NEW20-ADOL1",
      exception: "Yes",
      link: "LE-NEW20-3-ADOL1",
    },
    {
      id: "LEG.21.3",
      extend: "true",
      shortname: "le-new20-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW20-3-ADOL1",
      depending: "LE-NEW20-ADOL1",
      exception: "Yes",
      link: "LE-NEW20-C-ADOL1",
    },
    {
      id: "LEG.21.4",
      extend: "true",
      shortname: "le-new20-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW20-C-ADOL1",
      depending: "LE-NEW20-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.22",
      shortname: "le-new21-adol1",
      name: "Have you EVER committed, been charged with or been convicted of Breaking & Entering, Burglary, Larceny, Theft?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW21-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW21-1-ADOL1",
    },
    {
      id: "LEG.22.1",
      extend: "true",
      shortname: "le-new21-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW21-1-ADOL1",
      depending: "LE-NEW21-ADOL1",
      exception: "Yes",
      link: "LE-NEW21-2-ADOL1",
    },
    {
      id: "LEG.22.2",
      extend: "true",
      shortname: "le-new21-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW21-2-ADOL1",
      depending: "LE-NEW21-ADOL1",
      exception: "Yes",
      link: "LE-NEW21-3-ADOL1",
    },
    {
      id: "LEG.22.3",
      extend: "true",
      shortname: "le-new21-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW21-3-ADOL1",
      depending: "LE-NEW21-ADOL1",
      exception: "Yes",
      link: "LE-NEW21-C-ADOL1",
    },
    {
      id: "LEG.22.4",
      extend: "true",
      shortname: "le-new21-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW21-C-ADOL1",
      depending: "LE-NEW21-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.23",
      shortname: "le-new22-adol1",
      name: "Have you EVER committed, been charged with or been convicted of Robbery?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW22-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW22-1-ADOL1",
    },
    {
      id: "LEG.23.1",
      extend: "true",
      shortname: "le-new22-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW22-1-ADOL1",
      depending: "LE-NEW22-ADOL1",
      exception: "Yes",
      link: "LE-NEW22-2-ADOL1",
    },
    {
      id: "LEG.23.2",
      extend: "true",
      shortname: "le-new22-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW22-2-ADOL1",
      depending: "LE-NEW22-ADOL1",
      exception: "Yes",
      link: "LE-NEW22-3-ADOL1",
    },
    {
      id: "LEG.23.3",
      extend: "true",
      shortname: "le-new22-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW22-3-ADOL1",
      depending: "LE-NEW22-ADOL1",
      exception: "Yes",
      link: "LE-NEW22-C-ADOL1",
    },
    {
      id: "LEG.23.4",
      extend: "true",
      shortname: "le-new22-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW22-C-ADOL1",
      depending: "LE-NEW22-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.24",
      shortname: "le-new23-adol1",
      name: "Have you EVER committed, been charged with or been convicted of Auto Theft, Possession of a Stolen Auto?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW23-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW23-1-ADOL1",
    },
    {
      id: "LEG.24.1",
      extend: "true",
      shortname: "le-new23-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW23-1-ADOL1",
      depending: "LE-NEW23-ADOL1",
      exception: "Yes",
      link: "LE-NEW23-2-ADOL1",
    },
    {
      id: "LEG.24.2",
      extend: "true",
      shortname: "le-new23-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW23-2-ADOL1",
      depending: "LE-NEW23-ADOL1",
      exception: "Yes",
      link: "LE-NEW23-3-ADOL1",
    },
    {
      id: "LEG.24.3",
      extend: "true",
      shortname: "le-new23-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW23-3-ADOL1",
      depending: "LE-NEW23-ADOL1",
      exception: "Yes",
      link: "LE-NEW23-C-ADOL1",
    },
    {
      id: "LEG.24.4",
      extend: "true",
      shortname: "le-new23-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW23-C-ADOL1",
      depending: "LE-NEW23-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.25",
      shortname: "le-new24-adol1",
      name: "Have you EVER committed, been charged with or been convicted of Carjacking?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW24-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW24-1-ADOL1",
    },
    {
      id: "LEG.25.1",
      extend: "true",
      shortname: "le-new24-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW24-1-ADOL1",
      depending: "LE-NEW24-ADOL1",
      exception: "Yes",
      link: "LE-NEW24-2-ADOL1",
    },
    {
      id: "LEG.25.2",
      extend: "true",
      shortname: "le-new24-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW24-2-ADOL1",
      depending: "LE-NEW24-ADOL1",
      exception: "Yes",
      link: "LE-NEW24-3-ADOL1",
    },
    {
      id: "LEG.25.3",
      extend: "true",
      shortname: "le-new24-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW24-3-ADOL1",
      depending: "LE-NEW24-ADOL1",
      exception: "Yes",
      link: "LE-NEW24-C-ADOL1",
    },
    {
      id: "LEG.25.4",
      extend: "true",
      shortname: "le-new24-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW24-C-ADOL1",
      depending: "LE-NEW24-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.26",
      shortname: "le-new25-adol1",
      name: "Have you EVER committed, been charged with or been convicted of Simple Assault?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW25-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW25-1-ADOL1",
    },
    {
      id: "LEG.26.1",
      extend: "true",
      shortname: "le-new25-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW25-1-ADOL1",
      depending: "LE-NEW25-ADOL1",
      exception: "Yes",
      link: "LE-NEW25-2-ADOL1",
    },
    {
      id: "LEG.26.2",
      extend: "true",
      shortname: "le-new25-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW25-2-ADOL1",
      depending: "LE-NEW25-ADOL1",
      exception: "Yes",
      link: "LE-NEW25-3-ADOL1",
    },
    {
      id: "LEG.26.3",
      extend: "true",
      shortname: "le-new25-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW25-3-ADOL1",
      depending: "LE-NEW25-ADOL1",
      exception: "Yes",
      link: "LE-NEW25-C-ADOL1",
    },
    {
      id: "LEG.26.4",
      extend: "true",
      shortname: "le-new25-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW25-C-ADOL1",
      depending: "LE-NEW25-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.27",
      shortname: "le-new26-adol1",
      name: "Have you EVER committed, been charged with or been convicted of Aggravated Assault?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW26-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW26-1-ADOL1",
    },
    {
      id: "LEG.27.1",
      extend: "true",
      shortname: "le-new26-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW26-1-ADOL1",
      depending: "LE-NEW26-ADOL1",
      exception: "Yes",
      link: "LE-NEW26-2-ADOL1",
    },
    {
      id: "LEG.27.2",
      extend: "true",
      shortname: "le-new26-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW26-2-ADOL1",
      depending: "LE-NEW26-ADOL1",
      exception: "Yes",
      link: "LE-NEW26-3-ADOL1",
    },
    {
      id: "LEG.27.3",
      extend: "true",
      shortname: "le-new26-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW26-3-ADOL1",
      depending: "LE-NEW26-ADOL1",
      exception: "Yes",
      link: "LE-NEW26-C-ADOL1",
    },
    {
      id: "LEG.27.4",
      extend: "true",
      shortname: "le-new26-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW26-C-ADOL1",
      depending: "LE-NEW26-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.28",
      shortname: "le-new27-adol1",
      name: "Have you EVER committed, been charged with or been convicted of Weapons Violation?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW27-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW27-1-ADOL1",
    },
    {
      id: "LEG.28.1",
      extend: "true",
      shortname: "le-new27-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW27-1-ADOL1",
      depending: "LE-NEW27-ADOL1",
      exception: "Yes",
      link: "LE-NEW27-2-ADOL1",
    },
    {
      id: "LEG.28.2",
      extend: "true",
      shortname: "le-new27-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW27-2-ADOL1",
      depending: "LE-NEW27-ADOL1",
      exception: "Yes",
      link: "LE-NEW27-3-ADOL1",
    },
    {
      id: "LEG.28.3",
      extend: "true",
      shortname: "le-new27-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW27-3-ADOL1",
      depending: "LE-NEW27-ADOL1",
      exception: "Yes",
      link: "LE-NEW27-C-ADOL1",
    },
    {
      id: "LEG.28.4",
      extend: "true",
      shortname: "le-new27-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW27-C-ADOL1",
      depending: "LE-NEW27-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.29",
      shortname: "le-new28-adol1",
      name: "Have you ever committed, been charged with or been convicted of actions as a Sex Worker?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW28-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW28-1-ADOL1",
    },
    {
      id: "LEG.29.1",
      extend: "true",
      shortname: "le-new28-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW28-1-ADOL1",
      depending: "LE-NEW28-ADOL1",
      exception: "Yes",
      link: "LE-NEW28-2-ADOL1",
    },
    {
      id: "LEG.29.2",
      extend: "true",
      shortname: "le-new28-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW28-2-ADOL1",
      depending: "LE-NEW28-ADOL1",
      exception: "Yes",
      link: "LE-NEW28-3-ADOL1",
    },
    {
      id: "LEG.29.3",
      extend: "true",
      shortname: "le-new28-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW28-3-ADOL1",
      depending: "LE-NEW28-ADOL1",
      exception: "Yes",
      link: "LE-NEW28-C-ADOL1",
    },
    {
      id: "LEG.29.4",
      extend: "true",
      shortname: "le-new28-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW28-C-ADOL1",
      depending: "LE-NEW28-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.30",
      shortname: "le-new29-adol1",
      name: "Have you EVER committed, been charged with or been convicted of Arson?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW29-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW29-1-ADOL1",
    },
    {
      id: "LEG.30.1",
      extend: "true",
      shortname: "le-new29-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW29-1-ADOL1",
      depending: "LE-NEW29-ADOL1",
      exception: "Yes",
      link: "LE-NEW29-2-ADOL1",
    },
    {
      id: "LEG.30.2",
      extend: "true",
      shortname: "le-new29-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW29-2-ADOL1",
      depending: "LE-NEW29-ADOL1",
      exception: "Yes",
      link: "LE-NEW29-3-ADOL1",
    },
    {
      id: "LEG.30.3",
      extend: "true",
      shortname: "le-new29-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW29-3-ADOL1",
      depending: "LE-NEW29-ADOL1",
      exception: "Yes",
      link: "LE-NEW29-C-ADOL1",
    },
    {
      id: "LEG.30.4",
      extend: "true",
      shortname: "le-new29-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW29-C-ADOL1",
      depending: "LE-NEW29-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.31",
      shortname: "le-new30-adol1",
      name: "Have you EVER committed, been charged with or been convicted of Rape?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW30-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW30-1-ADOL1",
    },
    {
      id: "LEG.31.1",
      extend: "true",
      shortname: "le-new30-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW30-1-ADOL1",
      depending: "LE-NEW30-ADOL1",
      exception: "Yes",
      link: "LE-NEW30-2-ADOL1",
    },
    {
      id: "LEG.31.2",
      extend: "true",
      shortname: "le-new30-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW30-2-ADOL1",
      depending: "LE-NEW30-ADOL1",
      exception: "Yes",
      link: "LE-NEW30-3-ADOL1",
    },
    {
      id: "LEG.31.3",
      extend: "true",
      shortname: "le-new30-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW30-3-ADOL1",
      depending: "LE-NEW30-ADOL1",
      exception: "Yes",
      link: "LE-NEW30-C-ADOL1",
    },
    {
      id: "LEG.31.4",
      extend: "true",
      shortname: "le-new30-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW30-C-ADOL1",
      depending: "LE-NEW30-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.32",
      shortname: "le-new31-adol1",
      name: "Have you EVER committed, been charged with or been convicted of Homicide?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW31-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW31-1-ADOL1",
    },
    {
      id: "LEG.32.1",
      extend: "true",
      shortname: "le-new31-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW31-1-ADOL1",
      depending: "LE-NEW31-ADOL1",
      exception: "Yes",
      link: "LE-NEW31-2-ADOL1",
    },
    {
      id: "LEG.32.2",
      extend: "true",
      shortname: "le-new31-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW31-2-ADOL1",
      depending: "LE-NEW31-ADOL1",
      exception: "Yes",
      link: "LE-NEW31-3-ADOL1",
    },
    {
      id: "LEG.32.3",
      extend: "true",
      shortname: "le-new31-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW31-3-ADOL1",
      depending: "LE-NEW31-ADOL1",
      exception: "Yes",
      link: "LE-NEW31-C-ADOL1",
    },
    {
      id: "LEG.32.4",
      extend: "true",
      shortname: "le-new31-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW31-C-ADOL1",
      depending: "LE-NEW31-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.33",
      shortname: "le-new32-adol1",
      name: "Have you EVER committed, been charged with or been convicted of any other offenses?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW32-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW32-C-ADOL1",
    },
    {
      id: "LEG.33.1",
      extend: "true",
      shortname: "le-new32-c-adol1",
      name: "",
      prevalue: "Please, explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE-NEW32-C-ADOL1",
      depending: "LE-NEW32-ADOL1",
      exception: "Yes",
      link: "LE-NEW32-1-ADOL1",
    },
    {
      id: "LEG.33.2",
      extend: "true",
      shortname: "le-new32-1-adol1",
      name: "",
      prevalue: "At what age did this first happen?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "LE-NEW32-1-ADOL1",
      depending: "LE-NEW32-ADOL1",
      exception: "Yes",
      link: "LE-NEW32-2-ADOL1",
    },
    {
      id: "LEG.33.3",
      extend: "true",
      shortname: "le-new32-2-adol1",
      name: "",
      prevalue: "Has this been in the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW32-2-ADOL1",
      depending: "LE-NEW32-ADOL1",
      exception: "Yes",
      link: "LE-NEW32-3-ADOL1",
    },
    {
      id: "LEG.33.4",
      extend: "true",
      shortname: "le-new32-3-adol1",
      name: "",
      prevalue: "Has this been in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW32-3-ADOL1",
      depending: "LE-NEW32-ADOL1",
      exception: "Yes",
      link: "LE-NEW32-COM-ADOL1",
    },
    {
      id: "LEG.33.5",
      extend: "true",
      shortname: "le-new32-com-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "LE-NEW32-COM-ADOL1",
      depending: "LE-NEW32-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.34",
      shortname: "le-new33-adol1",
      name: "Are you currently awaiting charges, a trial or sentencing?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "LE-NEW33-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW33-ADOL1-1",
    },
    {
      id: "LEG.34.1",
      extend: "true",
      shortname: "le-new33-adol1-1",
      name: "",
      prevalue: "What charges, please, explain:",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE-NEW33-ADOL1-1",
      depending: "LE-NEW33-ADOL1",
      exception: "Yes",
    },
    {
      id: "LEG.35",
      shortname: "le-new34-adol1",
      name: "Do you think you have any problems with the law?",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Maybe", "Definitely"],
      act: "LE-NEW34-ADOL1",
      depending: "",
      exception: "",
    },
    {
      id: "LEG.36",
      shortname: "le-new35-adol1",
      name: "In the past month, how often have you experienced issues with the law that bothered you?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: ["", "Never", "Sometimes", "Almost always", "Always"],
      act: "LE-NEW35-ADOL1",
      depending: "",
      exception: "",
    },
    {
      id: "LEG.37",
      shortname: "le-new36-adol1",
      name: "Have you been troubled or bothered during the past month by any legal issues, and if so, how much?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: ["", "not at all", "a little bit", "a lot"],
      act: "LE-NEW36-ADOL1",
      depending: "",
      exception: "",
      link: "LE-NEW36-ADOL1-1",
    },
    {
      id: "LEG.37.1",
      shortname: "le-new36-adol1-1",
      name: "",
      prevalue: "Please explain the reason you are troubled or bothered?",
      value: "",
      disabled: "true",
      type: "text",
      act: "LE-NEW36-ADOL1-1",
      depending: "LE-NEW36-ADOL1",
      exception: "not at all",
      extend: "true",
    },
    {
      id: "LEG.38",
      shortname: "le-new37-adol1",
      name: "How important is legal counseling to you?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: ["", "not at all", "a little bit", "a lot"],
      act: "LE-NEW37-ADOL1",
      depending: "",
      exception: "",
    },
    {
      id: "LEG.39",
      shortname: "le30c",
      access: "private",
      name: "Additional comments:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "editor",
      act: "LE30C",
      depending: "",
      exception: "",
    },
  ],
};

export default legalHistoryJSON;
