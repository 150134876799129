const blockSedative = [
  //!========================================================================================
  /*                                                                                      *
   *                                  STARTBLOCKSEDATIVE                                  *
   *                                                                                      */
  //!========================================================================================
  {
    id: "DA.1.1.314",
    shortname: "sedative-block-subtopic",
    name: "Direction for Sedative: Please answer the items in the grid below. Have there EVER been significant periods during which you...",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "subtopic",
    act: "SEDATIVE-BLOCK-SUBTOPIC",
    depending: "DA0",
    exception: "Sedative/ Hypnotic/Anxiolytics",
    condition: "in",
  },
  {
    id: "DA.1.1.315",
    shortname: "sedative-block-6",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "SEDATIVE-BLOCK-6",
    depending: "DA0",
    exception: "Sedative/ Hypnotic/Anxiolytics",
    condition: "in",
    link: "SEDATIVE-BLOCK-7",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.315.1",
    extend: "true",
    shortname: "sedative-block-7",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "SEDATIVE-BLOCK-7",
    depending: "SEDATIVE-BLOCK-6",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-8",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.315.2",
    extend: "true",
    shortname: "sedative-block-8",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-8",
    depending: "SEDATIVE-BLOCK-6",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-9",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.315.3",
    extend: "true",
    shortname: "sedative-block-9",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-9",
    depending: "SEDATIVE-BLOCK-6",
    exception: "This has happened",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.316",
    shortname: "sedative-block-10",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "SEDATIVE-BLOCK-10",
    depending: "DA0",
    exception: "Sedative/ Hypnotic/Anxiolytics",
    condition: "in",
    link: "SEDATIVE-BLOCK-11",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.316.1",
    extend: "true",
    shortname: "sedative-block-11",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "SEDATIVE-BLOCK-11",
    depending: "SEDATIVE-BLOCK-10",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-12",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.316.2",
    extend: "true",
    shortname: "sedative-block-12",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-12",
    depending: "SEDATIVE-BLOCK-10",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-13",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.316.3",
    extend: "true",
    shortname: "sedative-block-13",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-13",
    depending: "SEDATIVE-BLOCK-10",
    exception: "This has happened",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.317",
    shortname: "sedative-block-14",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "SEDATIVE-BLOCK-14",
    depending: "DA0",
    exception: "Sedative/ Hypnotic/Anxiolytics",
    condition: "in",
    link: "SEDATIVE-BLOCK-15",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.317.1",
    extend: "true",
    shortname: "sedative-block-15",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "SEDATIVE-BLOCK-15",
    depending: "SEDATIVE-BLOCK-14",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-16",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.317.2",
    extend: "true",
    shortname: "sedative-block-16",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-16",
    depending: "SEDATIVE-BLOCK-14",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-17",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.317.3",
    extend: "true",
    shortname: "sedative-block-17",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-17",
    depending: "SEDATIVE-BLOCK-14",
    exception: "This has happened",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.318",
    shortname: "sedative-block-18",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "SEDATIVE-BLOCK-18",
    depending: "DA0",
    exception: "Sedative/ Hypnotic/Anxiolytics",
    condition: "in",
    link: "SEDATIVE-BLOCK-19",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.318.1",
    extend: "true",
    shortname: "sedative-block-19",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "SEDATIVE-BLOCK-19",
    depending: "SEDATIVE-BLOCK-18",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-30",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.318.2",
    extend: "true",
    shortname: "sedative-block-20",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-20",
    depending: "SEDATIVE-BLOCK-18",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-21",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.318.3",
    extend: "true",
    shortname: "sedative-block-21",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-21",
    depending: "SEDATIVE-BLOCK-18",
    exception: "This has happened",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.319",
    shortname: "sedative-block-22",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "SEDATIVE-BLOCK-22",
    depending: "DA0",
    exception: "Sedative/ Hypnotic/Anxiolytics",
    condition: "in",
    link: "SEDATIVE-BLOCK-23",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.319.1",
    extend: "true",
    shortname: "sedative-block-23",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "SEDATIVE-BLOCK-23",
    depending: "SEDATIVE-BLOCK-22",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-24",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.319.2",
    extend: "true",
    shortname: "sedative-block-24",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-24",
    depending: "SEDATIVE-BLOCK-22",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-25",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.319.3",
    extend: "true",
    shortname: "sedative-block-25",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-25",
    depending: "SEDATIVE-BLOCK-22",
    exception: "This has happened",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.320",
    shortname: "sedative-block-26",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "SEDATIVE-BLOCK-26",
    depending: "DA0",
    exception: "Sedative/ Hypnotic/Anxiolytics",
    condition: "in",
    link: "SEDATIVE-BLOCK-27",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.320.1",
    extend: "true",
    shortname: "sedative-block-27",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "SEDATIVE-BLOCK-27",
    depending: "SEDATIVE-BLOCK-26",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-28",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.320.2",
    extend: "true",
    shortname: "sedative-block-28",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-28",
    depending: "SEDATIVE-BLOCK-26",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-29",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.320.3",
    extend: "true",
    shortname: "sedative-block-29",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-29",
    depending: "SEDATIVE-BLOCK-26",
    exception: "This has happened",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.321",
    shortname: "sedative-block-30",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "SEDATIVE-BLOCK-30",
    depending: "DA0",
    exception: "Sedative/ Hypnotic/Anxiolytics",
    condition: "in",
    link: "SEDATIVE-BLOCK-31",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.321.1",
    extend: "true",
    shortname: "sedative-block-31",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "SEDATIVE-BLOCK-31",
    depending: "SEDATIVE-BLOCK-30",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-32",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.321.2",
    extend: "true",
    shortname: "sedative-block-32",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-32",
    depending: "SEDATIVE-BLOCK-30",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-33",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.321.3",
    extend: "true",
    shortname: "sedative-block-33",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-33",
    depending: "SEDATIVE-BLOCK-30",
    exception: "This has happened",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.322",
    shortname: "sedative-block-34",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "SEDATIVE-BLOCK-34",
    depending: "DA0",
    exception: "Sedative/ Hypnotic/Anxiolytics",
    condition: "in",
    link: "SEDATIVE-BLOCK-35",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.322.1",
    extend: "true",
    shortname: "sedative-block-35",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "SEDATIVE-BLOCK-35",
    depending: "SEDATIVE-BLOCK-34",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-36",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.322.2",
    extend: "true",
    shortname: "sedative-block-36",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-36",
    depending: "SEDATIVE-BLOCK-34",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-37",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.322.3",
    extend: "true",
    shortname: "sedative-block-37",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-37",
    depending: "SEDATIVE-BLOCK-34",
    exception: "This has happened",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.323",
    shortname: "sedative-block-38",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "SEDATIVE-BLOCK-38",
    depending: "DA0",
    exception: "Sedative/ Hypnotic/Anxiolytics",
    condition: "in",
    link: "SEDATIVE-BLOCK-39",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.323.1",
    extend: "true",
    shortname: "sedative-block-39",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "SEDATIVE-BLOCK-39",
    depending: "SEDATIVE-BLOCK-38",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK0",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.323.2",
    extend: "true",
    shortname: "sedative-block-40",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-40",
    depending: "SEDATIVE-BLOCK-38",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-41",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.323.3",
    extend: "true",
    shortname: "sedative-block-41",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-41",
    depending: "SEDATIVE-BLOCK-38",
    exception: "This has happened",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.324",
    shortname: "sedative-block-42",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "SEDATIVE-BLOCK-42",
    depending: "DA0",
    exception: "Sedative/ Hypnotic/Anxiolytics",
    condition: "in",
    link: "SEDATIVE-BLOCK-43",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.324.1",
    extend: "true",
    shortname: "sedative-block-43",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "SEDATIVE-BLOCK-43",
    depending: "SEDATIVE-BLOCK-42",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-44",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.324.2",
    extend: "true",
    shortname: "sedative-block-44",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-44",
    depending: "SEDATIVE-BLOCK-42",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-45",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.324.3",
    extend: "true",
    shortname: "sedative-block-45",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-45",
    depending: "SEDATIVE-BLOCK-42",
    exception: "This has happened",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.325",
    shortname: "sedative-block-46",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "SEDATIVE-BLOCK-46",
    depending: "DA0",
    exception: "Sedative/ Hypnotic/Anxiolytics",
    condition: "in",
    link: "SEDATIVE-BLOCK-47",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.325.1",
    extend: "true",
    shortname: "sedative-block-47",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "SEDATIVE-BLOCK-47",
    depending: "SEDATIVE-BLOCK-46",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-48",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.325.2",
    extend: "true",
    shortname: "sedative-block-48",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-48",
    depending: "SEDATIVE-BLOCK-46",
    exception: "This has happened",
    link: "SEDATIVE-BLOCK-49",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.325.3",
    extend: "true",
    shortname: "sedative-block-49",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "SEDATIVE-BLOCK-49",
    depending: "SEDATIVE-BLOCK-46",
    exception: "This has happened",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.326",
    shortname: "sedative-block-50",
    name: "Number of criteria met for:",
    prevalue: "Number of criteria met for Sedative/ Hypnotic/Anxiolytics:",
    value: "0",
    disabled: "true",
    type: "content",
    comment: "Consider no diagnosis for Sedative/ Hypnotic/Anxiolytics",
    act: "SEDATIVE-BLOCK-50",
    depending: "DA0",
    exception: "Sedative/ Hypnotic/Anxiolytics",
    condition: "in",
    meaningfulcount: "This has happened",
    subtopic_container_act: "SEDATIVE-BLOCK-SUBTOPIC",
    suptopic_code: "SEDATIVE-BLOCK-12-CONTENT",
    subtopic_name: "Content Criteria",
  },
  //!========================================================================================
  /*                                                                                      *
   *                                   ENDBLOCKSEDATIVE                                   *
   *                                                                                      */
  //!========================================================================================
];

export default blockSedative;
