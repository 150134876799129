const presentingProblemJSON = {
  section: "PP",
  topic: "Presenting Problem/Primary Complaint",
  checked: "true",
  color: "secondary",
  description: "",
  body: [
    {
      id: "PP.1",
      shortname: "pp-new1",
      name: "When were you first referred to have this assessment?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "date",
      act: "PP-NEW1",
      depending: "",
      exception: "",
    },
    {
      id: "PP.2",
      shortname: "pp1",
      name: "What is the PRIMARY reason for your having this assessment?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "text",
      act: "PP1",
      depending: "",
      exception: "",
    },
    {
      id: "PP.3",
      shortname: "pp-new3",
      name: "What led you/motivated you to schedule this evaluation?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "text",
      act: "PP-NEW3",
      depending: "",
      exception: "",
    },
    {
      id: "PP.4",
      shortname: "pp2",
      name: "Was this assessment because of a DUI/DWI",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PP2",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "PP3",
    },
    {
      id: "PP.4.1",
      shortname: "pp3",
      name: "",
      prevalue: "If YES, what was your Blood-Alcohol (BAC) level?",
      value: "",
      disabled: "true",
      type: "text",
      act: "PP3",
      depending: "PP2",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "PP.5",
      shortname: "pp4-0",
      name: "Who referred you to this Agency for this assessment or admission?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Self", "Other"],
      act: "PP4-0",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "PP4-1",
    },
    {
      id: "PP.5.1",
      extend: "true",
      shortname: "pp4-1",
      name: "",
      prevalue: "Referral Name",
      value: "",
      disabled: "true",
      type: "text",
      act: "PP4-1",
      depending: "PP4-0",
      exception: "Other",
      link: "PP4-2",
    },
    {
      id: "PP.5.2",
      extend: "true",
      shortname: "pp4-2",
      name: "",
      prevalue: "Referral Agency",
      value: "",
      disabled: "true",
      type: "text",
      act: "PP4-2",
      depending: "PP4-0",
      exception: "Other",
    },
    {
      id: "PP.6",
      shortname: "pp6",
      name: "Have you been in a controlled environment in the past 30 days?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PP6",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "PP7",
    },
    {
      id: "PP.6.1",
      shortname: "pp7",
      name: "",
      prevalue: "If Yes, how many days in the past 30 days?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "PP7",
      depending: "PP6",
      exception: "Yes",
      extend: "true",
      link: "PP8",
    },
    {
      id: "PP.6.2",
      shortname: "pp8",
      name: "",
      prevalue:
        "Where was this controlled environment? (jail or other locked facility like a hospital)",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Jail/Youth Detention",
        "Youth Shelter",
        "Drug/Alcohol Treatment",
        "Medical Treatment",
        "Psychological Treatment",
        "Other",
      ],
      act: "PP8",
      depending: "PP6",
      exception: "Yes",
      extend: "true",
      link: "PP8-1",
    },
    {
      id: "PP.6.2.1",
      shortname: "pp8-1",
      name: "",
      prevalue: "If other, explain ?",
      value: "",
      disabled: "true",
      type: "text",
      act: "PP8-1",
      extend: "true",
      depending: "PP8",
      exception: "Other",
      link: "PP-NEW11",
    },
    {
      id: "PP.6.3",
      shortname: "pp-new11",
      name: "",
      prevalue: "What was the first day?",
      value: "",
      disabled: "true",
      type: "date",
      act: "PP-NEW11",
      depending: "PP6",
      exception: "Yes",
      extend: "true",
      link: "PP-NEW12",
    },
    {
      id: "PP.6.4",
      shortname: "pp-new12",
      name: "",
      prevalue: "What was the last day?",
      value: "",
      disabled: "true",
      type: "date",
      act: "PP-NEW12",
      depending: "PP6",
      exception: "Yes",
      extend: "true",
    },
  ],
};

export default presentingProblemJSON;
