const behavioralHistoryJSON = {
  // section: "PS",
  // topic: "Behavioral Health History",
  // checked: "true",
  // // access: "public",
  // color: "secondary",
  section: "PSS",
  topic: "PSYCHIATRIC STATUS",
  checked: "true",
  // access: "public",
  color: "secondary",
  description: "",
  body: [
//========================================================================================
/*                                                                                      *
 *                PSS1-PSS2                                                             *
 *                                                                                      */
//========================================================================================

{
  id: "",
  shortname: "pss1-pss2-subtopic",
  name: "How many times have you been treated for any psychological or emotional problems: Do not include substance abuse, employment, or family counseling. Treatment episode = a series of more or less continuous visits or treatment days, not the number of visits or treatment days. Enter diagnosis in comments if known. ",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "subtopic",
  act: "PSS1-PSS2-SUBTOPIC",
  depending: "",
  exception: "",
},
// A
{
  id: "P1",
  shortname: "pss1",
  name: "",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
      ],
  act: "PSS1",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "PSS1-PSS2-SUBTOPIC",
  suptopic_code: "PSS1_CODE",
  subtopic_name: "In a hospital or inpatient setting?",
},
{
  id: "P2",
  shortname: "pss2",
  name: "",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
      ],
  act: "PSS2",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "PSS1-PSS2-SUBTOPIC",
  suptopic_code: "PSS2_CODE",
  subtopic_name: "Outpatient/private patient?",
},
{
  id: "P3",
  shortname: "pss3",
  name: "Do you receive a pension for a psychiatric disability?",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["No", "Yes"],
  act: "PSS3",
  mandatory: "true",
  depending: "",
  exception: "",
},
//========================================================================================
/*                                                                                      *
 *                                        "PSS4-PSS11"                                        *
 *                                                                                      */
//========================================================================================
{
  id: "",
  shortname: "pss4-pss11-subtopic",
  name: "Have you had a significant period of time (that was not a direct result of drug/alcohol use) in which you have?",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "subtopic",
  act: "PSS4-PSS11-SUBTOPIC",
  depending: "",
  exception: "",
},
// A
{
  id: "P4",
  shortname: "pss4-1",
  name: "Past 30 day",
  prevalue: "Experienced serious depression. Sadness, hopelessness, loss of interest, difficulty with daily functioning.",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS4-1",
  // mandatory: "true",
  depending: "",
  exception: "",
  link: "PSS5-1",
  subtopic_container_act: "PSS4-PSS11-SUBTOPIC",
  suptopic_code: "PSS4-PSS11_30DAYS",
  subtopic_name: "Past 30 day",
},
{
  id: "P5",
  extend: "true",
  shortname: "pss5-1",
  name: "Past 30 day",
  prevalue: "Experienced serious anxiety or tension. Uptight, unreasonably worried, inability to feel relaxed.",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS5-1",
  // mandatory: "true",
  depending: "",
  exception: "",
  link: "PSS6-1",
  subtopic_container_act: "PSS4-PSS11-SUBTOPIC",
  suptopic_code: "PSS4-PSS11_30DAYS",
  subtopic_name: "Past 30 day",
},
{
  id: "P6",
  extend: "true",
  shortname: "pss6-1",
  name: "Past 30 day",
  prevalue: "Experienced hallucinations. Saw things/heard voices that others didn't see/hear.",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS6-1",
  // mandatory: "true",
  depending: "",
  exception: "",
  link: "PSS7-1",
  subtopic_container_act: "PSS4-PSS11-SUBTOPIC",
  suptopic_code: "PSS4-PSS11_30DAYS",
  subtopic_name: "Past 30 day",
},
{
  id: "P7",
  extend: "true",
  shortname: "pss7-1",
  name: "Past 30 day",
  prevalue: "Experienced trouble understanding concentrating or remembering.",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS7-1",
  // mandatory: "true",
  depending: "",
  exception: "",
  link: "PSS8-1",
  subtopic_container_act: "PSS4-PSS11-SUBTOPIC",
  suptopic_code: "PSS4-PSS11_30DAYS",
  subtopic_name: "Past 30 day",
},
{
  id: "P8",
  extend: "true",
  shortname: "pss8-1",
  name: "Past 30 day",
  prevalue: "Experienced trouble controlling violent behavior including episodes or rage or violence.",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS8-1",
  // mandatory: "true",
  depending: "",
  exception: "",
  link: "PSS9-1",
  subtopic_container_act: "PSS4-PSS11-SUBTOPIC",
  suptopic_code: "PSS4-PSS11_30DAYS",
  subtopic_name: "Past 30 day",
},
{
  id: "P9",
  extend: "true",
  shortname: "pss9-1",
  name: "Past 30 day",
  prevalue: "Experienced serious thoughts of suicide. Patient seriously considered a plan for taking his/ her life. Patient can be under the influence of alcohol/drugs.",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS9-1",
  // mandatory: "true",
  depending: "",
  exception: "",
  link: "PSS10-1",
  subtopic_container_act: "PSS4-PSS11-SUBTOPIC",
  suptopic_code: "PSS4-PSS11_30DAYS",
  subtopic_name: "Past 30 day",
},
{
  id: "P10",
  extend: "true",
  shortname: "pss10-1",
  name: "Past 30 day",
  prevalue: "Attempted suicide. Include actual suicidal gestures or attempts. Patient can be under the influence of alcohol/drugs.",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS10-1",
  // mandatory: "true",
  depending: "",
  exception: "",
  link: "PSS11-1",
  subtopic_container_act: "PSS4-PSS11-SUBTOPIC",
  suptopic_code: "PSS4-PSS11_30DAYS",
  subtopic_name: "Past 30 day",
},
{
  id: "P11",
  extend: "true",
  shortname: "pss11-1",
  name: "Past 30 day",
  prevalue: 'Been prescribed medication for any psychological or emotional problems. Prescribed for the patient by a physician. Record "Yes" if a medication was prescribed even if the patient is not taking it. ',
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS11-1",
  // mandatory: "true",
  depending: "",
  exception: "",
  // link: "PSS12-1",
  subtopic_container_act: "PSS4-PSS11-SUBTOPIC",
  suptopic_code: "PSS4-PSS11_30DAYS",
  subtopic_name: "Past 30 day",
},
{
  // id: "P4",
  id: "P4-2",
  shortname: "pss4-2",
  // name: "Past 30 day",
  name: "In lifetime",
  prevalue: "Experienced serious depression. Sadness, hopelessness, loss of interest, difficulty with daily functioning.",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS4-2",
  // mandatory: "true",
  depending: "",
  exception: "",
  link: "PSS5-2",
  subtopic_container_act: "PSS4-PSS11-SUBTOPIC",
  suptopic_code: "PSS4-PSS11_LIFETIME",
  subtopic_name: "In lifetime",
},
{
  // id: "P5",
  id: "P5-2",
  extend: "true",
  shortname: "pss5-2",
  // name: "Past 30 day",
  name: "In lifetime",
  prevalue: "Experienced serious anxiety or tension. Uptight, unreasonably worried, inability to feel relaxed.",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS5-2",
  // mandatory: "true",
  depending: "",
  exception: "",
  link: "PSS6-2",
  subtopic_container_act: "PSS4-PSS11-SUBTOPIC",
  suptopic_code: "PSS4-PSS11_LIFETIME",
  subtopic_name: "In lifetime",
},
{
  // id: "P6",
  id: "P6-2",
  extend: "true",
  shortname: "pss6-2",
  // name: "Past 30 day",
  name: "In lifetime",
  prevalue: "Experienced hallucinations. Saw things/heard voices that others didn't see/hear.",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS6-2",
  // mandatory: "true",
  depending: "",
  exception: "",
  link: "PSS7-2",
  subtopic_container_act: "PSS4-PSS11-SUBTOPIC",
  suptopic_code: "PSS4-PSS11_LIFETIME",
  subtopic_name: "In lifetime",
},
{
  // id: "P7",
  id: "P7-2",
  extend: "true",
  shortname: "pss7-2",
  // name: "Past 30 day",
  name: "In lifetime",
  prevalue: "Experienced trouble understanding concentrating or remembering.",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS7-2",
  // mandatory: "true",
  depending: "",
  exception: "",
  link: "PSS8-2",
  subtopic_container_act: "PSS4-PSS11-SUBTOPIC",
  suptopic_code: "PSS4-PSS11_LIFETIME",
  subtopic_name: "In lifetime",
},
{
  // id: "P8",
  id: "P8-2",
  extend: "true",
  shortname: "pss8-2",
  // name: "Past 30 day",
  name: "In lifetime",
  prevalue: "Experienced trouble controlling violent behavior including episodes or rage or violence.",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS8-2",
  // mandatory: "true",
  depending: "",
  exception: "",
  link: "PSS9-2",
  subtopic_container_act: "PSS4-PSS11-SUBTOPIC",
  suptopic_code: "PSS4-PSS11_LIFETIME",
  subtopic_name: "In lifetime",
},
{
  // id: "P9",
  id: "P9-2",
  extend: "true",
  shortname: "pss9-2",
  // name: "Past 30 day",
  name: "In lifetime",
  prevalue: "Experienced serious thoughts of suicide. Patient seriously considered a plan for taking his/ her life. Patient can be under the influence of alcohol/drugs.",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS9-2",
  // mandatory: "true",
  depending: "",
  exception: "",
  link: "PSS10-2",
  subtopic_container_act: "PSS4-PSS11-SUBTOPIC",
  suptopic_code: "PSS4-PSS11_LIFETIME",
  subtopic_name: "In lifetime",
},
{
  // id: "P10",
  id: "P10-2",
  extend: "true",
  shortname: "pss10-2",
  // name: "Past 30 day",
  name: "In lifetime",
  prevalue: "Attempted suicide. Include actual suicidal gestures or attempts. Patient can be under the influence of alcohol/drugs.",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS10-2",
  // mandatory: "true",
  depending: "",
  exception: "",
  link: "PSS11-2",
  subtopic_container_act: "PSS4-PSS11-SUBTOPIC",
  suptopic_code: "PSS4-PSS11_LIFETIME",
  subtopic_name: "In lifetime",
},
{
  // id: "P11",
  id: "P11-2",
  extend: "true",
  shortname: "pss11-2",
  // name: "Past 30 day",
  name: "In lifetime",
  prevalue: 'Been prescribed medication for any psychological or emotional problems. Prescribed for the patient by a physician. Record "Yes" if a medication was prescribed even if the patient is not taking it. ',
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS11-2",
  // mandatory: "true",
  depending: "",
  exception: "",
  // link: "PSS12-1",
  subtopic_container_act: "PSS4-PSS11-SUBTOPIC",
  suptopic_code: "PSS4-PSS11_LIFETIME",
  subtopic_name: "In lifetime",
},

//========================================================================================
/*                                                                                      *
 *                                        "PSS3PLUS-PSS4PLUS"                                        *
 *                                                                                      */
//========================================================================================
// {
//   id: "",
//   shortname: "pss3plus-subtopic",
//   name: "Have you had a significant period of time (that was not a direct result of drug/alcohol use) in which you have:",
//   prevalue: "",
//   value: "",
//   disabled: "false",
//   type: "subtopic",
//   act: "PSS3PLUS-SUBTOPIC",
//   depending: "",
//   exception: "",
// },
// A
// {
//   id: "",
//   shortname: "pss3plus1",
//   name: "Past 30 day",
//   prevalue: "",
//   value: "n/a",
//   disabled: "false",
//   type: "radio",
//   radio_options: ["Yes", "No"],
//   act: "PSS3PLUS1",
//   depending: "",
//   exception: "",
//   subtopic_container_act: "PSS3PLUS-SUBTOPIC",
//   suptopic_code: "PSS3PLUS_30DAYS",
//   subtopic_name: "Past 30 day",
// },
// {
//   id: "",
//   shortname: "pss3plus2",
//   name: "In lifetime",
//   prevalue: "",
//   value: "n/a",
//   disabled: "false",
//   type: "radio",
//   radio_options: ["Yes", "No"],
//   act: "PSS3PLUS2",
//   depending: "",
//   exception: "",
//   subtopic_container_act: "PSS3PLUS-SUBTOPIC",
//   suptopic_code: "PSS3PLUS_LIFETIME",
//   subtopic_name: "In lifetime",
// },
// {
//   id: "P4",
//   shortname: "pss4",
//   name: "Experienced serious depression",
//   prevalue: "Sadness, hopelessness, loss of interest difficulty with daily functioning",
//   value: "",
//   disabled: "false",
//   type: "text",
//   act: "PSS4",
//   mandatory: "true",
//   depending: "",
//   exception: "",
// },
// {
//   id: "P5",
//   shortname: "pss5",
//   name: "Experienced serious anxiety or tension",
//   prevalue: "Uptight, unreasonably worried, inability to feel relaxed",
//   value: "",
//   disabled: "false",
//   type: "text",
//   act: "PSS5",
//   mandatory: "true",
//   depending: "",
//   exception: "",
// },
// {
//   id: "P6",
//   shortname: "pss6",
//   name: "Experienced hallucinations",
//   prevalue: "Saw things/heard voices that others didn’t see/hear",
//   value: "",
//   disabled: "false",
//   type: "text",
//   act: "PSS6",
//   mandatory: "true",
//   depending: "",
//   exception: "",
// },
// {
//   id: "P7",
//   shortname: "pss7",
//   name: "Experienced trouble understanding, concentrating or remembering",
//   prevalue: "",
//   value: "",
//   disabled: "false",
//   type: "text",
//   act: "PSS7",
//   mandatory: "true",
//   depending: "",
//   exception: "",
// },
// {
//   id: "P8",
//   shortname: "pss8",
//   name: "Experienced trouble controlling violent behavior including episodes or rage or violence",
//   prevalue: "Patient can be under the influence of alcohol/drugs",
//   value: "",
//   disabled: "false",
//   type: "text",
//   act: "PSS8",
//   mandatory: "true",
//   depending: "",
//   exception: "",
// },
// {
//   id: "P9",
//   shortname: "pss9",
//   name: "Experienced serious thoughts of suicide",
//   prevalue: "Patient can be under the influence of alcohol/drugs",
//   value: "",
//   disabled: "false",
//   type: "text",
//   act: "PSS9",
//   mandatory: "true",
//   depending: "",
//   exception: "",
// },
// {
//   id: "P10",
//   shortname: "pss10",
//   name: "Attempted suicide",
//   prevalue: "Include actual suicidal gestures or attempts. Patient can be under the influence of alcohol / drugs.",
//   value: "",
//   disabled: "false",
//   type: "text",
//   act: "PSS10",
//   mandatory: "true",
//   depending: "",
//   exception: "",
// },
// {
//   id: "P11",
//   shortname: "pss11",
//   name: "Been prescribed medication for any psychological or emotional problems",
//   prevalue: "Prescribed for the   patient by a physician. Record “Yes” if a medication was prescribed even if the patient is not taking it.",
//   value: "",
//   disabled: "false",
//   type: "text",
//   act: "PSS11",
//   mandatory: "true",
//   depending: "",
//   exception: "",
// },
{
  id: "P12",
  shortname: "pss12",
  name: "How many days in the past 30 have you experienced these psychological or emotional problems?",
  prevalue: "Refers to problems noted in Questions P4-P10.",
  value: "",
  disabled: "false",
  type: "select",
  ruletype: "number",
  select_options: [
    "",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ],
  act: "PSS12",
  mandatory: "true",
  depending: "",
  exception: "",
},
{
  id: "",
  shortname: "pss13-pss14-subtopic",
  name: "For Questions P13 & P14, ask the patient to use the Patient Rating Scale",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "subtopic",
  act: "PSS13-PSS14-ACT",
  depending: "",
  exception: "",
},
{
  id: "P13",
  shortname: "pss13",
  name: "",
  prevalue: 'Patient should be rating the problem days from Question P12',
  value: "",
  disabled: "false",
  type: "select",
  ruletype: "string",
  select_options: [
    "",
    "0 - not at all",
    "1 - slightly",
    "2 - moderately",
    "3 - considerably",
    "4 - extremely",
  ],
  act: "PSS13",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "PSS13-PSS14-ACT",
  suptopic_code: "PSS13-CODE",
  subtopic_name:
    "How much have you been troubled or bothered by these psychological or emotional problems in the past 30 days?",
},
{
  id: "P14",
  shortname: "pss14",
  name: "",
  prevalue: '',
  value: "",
  disabled: "false",
  type: "select",
  ruletype: "string",
  select_options: [
    "",
    "0 - not at all",
    "1 - slightly",
    "2 - moderately",
    "3 - considerably",
    "4 - extremely",
  ],
  act: "PSS14",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "PSS13-PSS14-ACT",
  suptopic_code: "PSS14-CODE",
  subtopic_name:
    "How important to you now is treatment for these psychological problems?",
},


{
  id: "",
  onlyasi: "", //subs P15 - P20
  shortname: "pss15-pss20-subtopic",
  name: "The following items are to be completed by the interviewer: At the time of the interview, the patient was: 0 – No   1 - Yes",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "subtopic",
  act: "PSS15-PSS20-ACT",
  depending: "",
  exception: "",
},
{
  id: "P15",
  onlyasi: "",  //P15
  shortname: "pss15",
  name: "",
  prevalue: '',
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS15",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "PSS15-PSS20-ACT",
  suptopic_code: "PSS15-CODE",
  subtopic_name:
    "Obviously depressed/withdrawn",
},
{
  id: "P16",
  onlyasi: "",  //P16
  shortname: "pss16",
  name: "",
  prevalue: '',
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS16",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "PSS15-PSS20-ACT",
  suptopic_code: "PSS16-CODE",
  subtopic_name:
    "Obviously hostile",
},
{
  id: "P17",
  onlyasi: "",  //P17
  shortname: "pss17",
  name: "",
  prevalue: '',
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS17",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "PSS15-PSS20-ACT",
  suptopic_code: "PSS17-CODE",
  subtopic_name:
    "Obviously anxious/nervous<br/><br/>",
},
{
  id: "P18",
  onlyasi: "",  //P18
  shortname: "pss18",
  name: "",
  prevalue: '',
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS18",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "PSS15-PSS20-ACT",
  suptopic_code: "PSS18-CODE",
  subtopic_name:
    "Having trouble with reality testing, thought disorders, paranoid thinking",
},
{
  id: "P19",
  onlyasi: "",  //P19
  shortname: "pss19",
  name: "",
  prevalue: '',
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS19",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "PSS15-PSS20-ACT",
  suptopic_code: "PSS19-CODE",
  subtopic_name:
    "Having trouble comprehending, concentrating, remembering",
},
{
  id: "P20",
  onlyasi: "",  //P20
  shortname: "pss20",
  name: "",
  prevalue: '',
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["Yes", "No"],
  act: "PSS20",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "PSS15-PSS20-ACT",
  suptopic_code: "PSS20-CODE",
  subtopic_name:
    "Having suicidal thoughts<br/><br/>",
},
// {
//   id: "P20-1",
//   shortname: "pss20-1",
//   name:
//     "How much have you been troubled or bothered by these psychological or emotional problems in the past 30 days?",
//   prevalue: "",
//   value: "",
//   disabled: "false",
//   type: "select",
//   ruletype: "string",
//   select_options: [
//     "",
//     "0 - not at all",
//     "1 - slightly",
//     "2 - moderately",
//     "3 - considerably",
//     "4 - extremely",
//   ],
//   act: "PSS20-1",
//   mandatory: "true",
//   depending: "",
//   exception: "",
//   link: "PSS20-1-1",
// },
// {
//   id: "P20-1.1",
//   shortname: "pss20-1-1",
//   name: "",
//   prevalue: "Please explain the reason you are worried or bothered?",
//   value: "",
//   disabled: "true",
//   type: "text",
//   act: "PSS20-1-1",
//   depending: "PSS20-1",
//   exception: "0 - not at all",
//   extend: "true",
// },
// {
//   id: "P20-2",
//   shortname: "pss20-2",
//   name:
//     "How important to you now is treatment for these psychological or emotional problems?",
//   prevalue: "",
//   value: "",
//   disabled: "false",
//   type: "select",
//   ruletype: "string",
//   select_options: [
//     "",
//     "0 - not at all",
//     "1 - slightly",
//     "2 - moderately",
//     "3 - considerably",
//     "4 - extremely",
//   ],
//   act: "PSS20-2",
//   depending: "",
//   exception: "",
// },
{
  id: "P21",
  onlyasi: "",  //P21
  shortname: "pss21",
  access: "private",
  name: "INTERVIEWER SEVERITY RATING",
  prevalue: "How would you rate the patient’s need for psychiatric/ psychological treatment?",
  value: "",
  disabled: "false",
  type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
      ],
  act: "PSS21",
  mandatory: "true",
  depending: "",
  exception: "",
},
{
  id: "",
  shortname: "pss22-pss23-subtopic",
  access: "private",
  name: "CONFIDENCE RATINGS. Is the above information significantly distorted by:",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "subtopic",
  act: "PSS22-PSS23-ACT",
  depending: "",
  exception: "",
},
{
  id: "P22",
  shortname: "pss22",
  name: "",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["No", "Yes"],
  act: "PSS22",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "PSS22-PSS23-ACT",
  suptopic_code: "PSS22-CODE",
  subtopic_name:
    "Client's misrepresentation?",
},
{
  id: "P23",
  shortname: "pss23",
  name: "",
  prevalue: "",
  value: "",
  disabled: "false",
  type: "radio",
  radio_options: ["No", "Yes"],
  act: "PSS23",
  mandatory: "true",
  depending: "",
  exception: "",
  subtopic_container_act: "PSS22-PSS23-ACT",
  suptopic_code: "PSS23-CODE",
  subtopic_name:
    "Client’s inability to understand?",
},
{
  id: "P-COMMENT",
  shortname: "pss-comment",
  access: "private",
  name: "PSYCHIATRIC STATUS COMMENTS:",
  prevalue: "(Include the question number with your notes)",
  value: "",
  disabled: "false",
  type: "editor",
  act: "PSS-COMMENT",
  depending: "",
  exception: "",
},
  ],
};

export default behavioralHistoryJSON;
