
const telCountryList = [
  {id: 0, country: "", code: "", mask: "(999) 999-9999"},
  {id: 1, country: "Afghanistan", code: "(+93)", mask: "(999) 999-9999"},
  {id: 2, country: "Albania", code: "(+355)", mask: "(999) 999-9999"},
  {id: 3, country: "Algeria", code: "(+213)", mask: "(999) 999-9999"},
  {id: 4, country: "AmericanSamoa", code: "(+1 684)", mask: "(999) 999-9999"},
  {id: 5, country: "Andorra", code: "(+376)", mask: "(999) 999-9999"},
  {id: 6, country: "Angola", code: "(+244)", mask: "(999) 999-9999"},
  {id: 7, country: "Anguilla", code: "(+1 264)", mask: "(999) 999-9999"},
  {id: 8, country: "Antarctica", code: "(+)", mask: "(999) 999-9999"},
  {id: 9, country: "Antigua and Barbuda", code: "(+1268)", mask: "(999) 999-9999"},
  {id: 10, country: "Argentina", code: "(+54)", mask: "999 9999-9999"},
  {id: 11, country: "Armenia", code: "(+374)", mask: "(999) 999-9999"},
  {id: 12, country: "Aruba", code: "(+297)", mask: "(999) 999-9999"},
  {id: 13, country: "Australia", code: "(+61)", mask: "(99) 9999 9999"},
  {id: 14, country: "Austria", code: "(+43)", mask: "9999 999999"},
  {id: 15, country: "Azerbaijan", code: "(+994)", mask: "(999) 999-9999"},
  {id: 16, country: "Bahamas", code: "(+1 242)", mask: "(999) 999-9999"},
  {id: 17, country: "Bahrain", code: "(+973)", mask: "(999) 999-9999"},
  {id: 18, country: "Bangladesh", code: "(+880)", mask: "(999) 999-9999"},
  {id: 19, country: "Barbados", code: "(+1 246)", mask: "(999) 999-9999"},
  {id: 20, country: "Belarus", code: "(+375)", mask: "(999) 999-9999"},
  {id: 21, country: "Belgium", code: "(+32)", mask: "99 999 99 99"},
  {id: 22, country: "Belize", code: "(+501)", mask: "(999) 999-9999"},
  {id: 23, country: "Benin", code: "(+229)", mask: "(999) 999-9999"},
  {id: 24, country: "Bermuda", code: "(+1 441)", mask: "(999) 999-9999"},
  {id: 25, country: "Bhutan", code: "(+975)", mask: "(999) 999-9999"},
  {id: 26, country: "Bolivia Plurinational State of", code: "(+591)", mask: "(999) 999-9999"},
  {id: 27, country: "Bosnia and Herzegovina", code: "(+387)", mask: "(999) 999-9999"},
  {id: 28, country: "Botswana", code: "(+267)", mask: "(999) 999-9999"},
  {id: 29, country: "Brazil", code: "(+55)", mask: "(99) 9999-9999"},
  {id: 30, country: "British Indian Ocean Territory", code: "(+246)", mask: "(999) 999-9999"},
  {id: 31, country: "Brunei Darussalam", code: "(+673)", mask: "(999) 999-9999"},
  {id: 32, country: "Bulgaria", code: "(+359)", mask: "99 999 9999"},
  {id: 33, country: "Burkina Faso", code: "(+226)", mask: "(999) 999-9999"},
  {id: 34, country: "Burundi", code: "(+257)", mask: "(999) 999-9999"},
  {id: 35, country: "Cambodia", code: "(+855)", mask: "(999) 999-9999"},
  {id: 36, country: "Cameroon", code: "(+237)", mask: "(999) 999-9999"},
  {id: 37, country: "Canada", code: "(+1)", mask: "(999) 999-9999"},
  {id: 38, country: "Cape Verde", code: "(+238)", mask: "(999) 999-9999"},
  {id: 39, country: "Cayman Islands", code: "(+345)", mask: "(999) 999-9999"},
  {id: 40, country: "Central African Republic", code: "(+236)", mask: "(999) 999-9999"},
  {id: 41, country: "Chad", code: "(+235)", mask: "(999) 999-9999"},
  {id: 42, country: "Chile", code: "(+56)", mask: "(999) 999-9999"},
  {id: 43, country: "China", code: "(+86)", mask: "(999) 999-9999"},
  {id: 44, country: "Christmas Island", code: "(+61)", mask: "(999) 999-9999"},
  {id: 45, country: "Cocos (Keeling) Islands", code: "(+61)", mask: "(999) 999-9999"},
  {id: 46, country: "Colombia", code: "(+57)", mask: "(9) 9999999"},
  {id: 47, country: "Comoros", code: "(+269)", mask: "(999) 999-9999"},
  {id: 48, country: "Congo The Democratic Republic of the", code: "(+243)", mask: "(999) 999-9999"},
  {id: 49, country: "Congo", code: "(+242)", mask: "(999) 999-9999"},
  {id: 50, country: "Cook Islands", code: "(+682)", mask: "(999) 999-9999"},
  {id: 51, country: "Costa Rica", code: "(+506)", mask: "(999) 999-9999"},
  {id: 52, country: "Cote d'Ivoire", code: "(+225)", mask: "(999) 999-9999"},
  {id: 53, country: "Croatia", code: "(+385)", mask: "99 9999 999"},
  {id: 54, country: "Cuba", code: "(+53)", mask: "(999) 999-9999"},
  {id: 55, country: "Cyprus", code: "(+537)", mask: "99 999999"},
  {id: 56, country: "Czech Republic", code: "(+420)", mask: "999 999 999"},
  {id: 57, country: "Denmark", code: "(+45)", mask: "99 99 99 99"},
  {id: 58, country: "Djibouti", code: "(+253)", mask: "(999) 999-9999"},
  {id: 59, country: "Dominica", code: "(+1 767)", mask: "(999) 999-9999"},
  {id: 60, country: "Dominican Republic", code: "(+1 849)", mask: "(999) 999-9999"},
  {id: 61, country: "Ecuador", code: "(+593)", mask: "(999) 999-9999"},
  {id: 62, country: "Egypt", code: "(+20)", mask: "(999) 999-9999"},
  {id: 63, country: "El Salvador", code: "(+503)", mask: "9999 9999"},
  {id: 64, country: "Equatorial Guinea", code: "(+240)", mask: "(999) 999-9999"},
  {id: 65, country: "Eritrea", code: "(+291)", mask: "(999) 999-9999"},
  {id: 66, country: "Estonia", code: "(+372)", mask: "999 9999"},
  {id: 67, country: "Ethiopia", code: "(+251)", mask: "(999) 999-9999"},
  {id: 68, country: "Falkland Islands (Malvinas)", code: "(+500)", mask: "(999) 999-9999"},
  {id: 69, country: "Faroe Islands", code: "(+298)", mask: "(999) 999-9999"},
  {id: 70, country: "Fiji", code: "(+679)", mask: "(999) 999-9999"},
  {id: 71, country: "Finland", code: "(+358)", mask: "99 99999999"},
  {id: 72, country: "France", code: "(+33)", mask: "99 99 99 99 99"},
  {id: 73, country: "French Guiana", code: "(+594)", mask: "(999) 999-9999"},
  {id: 74, country: "French Polynesia", code: "(+689)", mask: "(999) 999-9999"},
  {id: 75, country: "Gabon", code: "(+241)", mask: "(999) 999-9999"},
  {id: 76, country: "Gambia", code: "(+220)", mask: "(999) 999-9999"},
  {id: 77, country: "Georgia", code: "(+995)", mask: "(999) 999-9999"},
  {id: 78, country: "Germany", code: "(+49)", mask: "999 99999999"},
  {id: 79, country: "Ghana", code: "(+233)", mask: "(999) 999-9999"},
  {id: 80, country: "Gibraltar", code: "(+350)", mask: "(999) 999-9999"},
  {id: 81, country: "Greece", code: "(+30)", mask: "99 9999 9999"},
  {id: 82, country: "Greenland", code: "(+299)", mask: "(999) 999-9999"},
  {id: 83, country: "Grenada", code: "(+1 473)", mask: "(999) 999-9999"},
  {id: 84, country: "Guadeloupe", code: "(+590)", mask: "(999) 999-9999"},
  {id: 85, country: "Guam", code: "(+1 671)", mask: "(999) 999-9999"},
  {id: 86, country: "Guatemala", code: "(+502)", mask: "(999) 999-9999"},
  {id: 87, country: "Guernsey", code: "(+44)", mask: "(999) 999-9999"},
  {id: 88, country: "Guinea", code: "(+224)", mask: "(999) 999-9999"},
  {id: 89, country: "Guinea-Bissau", code: "(+245)", mask: "(999) 999-9999"},
  {id: 90, country: "Guyana", code: "(+595)", mask: "(999) 999-9999"},
  {id: 91, country: "Haiti", code: "(+509)", mask: "(999) 999-9999"},
  {id: 92, country: "Holy See (Vatican City State)", code: "(+379)", mask: "(999) 999-9999"},
  {id: 93, country: "Honduras", code: "(+504)", mask: "(999) 999-9999"},
  {id: 94, country: "Hong Kong", code: "(+852)", mask: "9999 9999"},
  {id: 95, country: "Hungary", code: "(+36)", mask: "(9) 999 9999"},
  {id: 96, country: "Iceland", code: "(+354)", mask: "(999) 999-9999"},
  {id: 97, country: "India", code: "(+91)", mask: "(999) 999-9999"},
  {id: 98, country: "Indonesia", code: "(+62)", mask: "(999) 999-9999"},
  {id: 99, country: "Iran Islamic Republic of", code: "(+98)", mask: "(999) 999-9999"},
  {id: 100, country: "Iraq", code: "(+964)", mask: "(999) 999-9999"},
  {id: 101, country: "Ireland", code: "(+353)", mask: "(99) 999 9999"},
  {id: 102, country: "Isle of Man", code: "(+44)", mask: "(999) 999-9999"},
  {id: 103, country: "Israel", code: "(+972)", mask: "999-999-9999"},
  {id: 104, country: "Italy", code: "(+39)", mask: "99 9999 9999"},
  {id: 105, country: "Jamaica", code: "(+1 876)", mask: "(999) 999-9999"},
  {id: 106, country: "Japan", code: "(+81)", mask: "99-9999-9999"},
  {id: 107, country: "Jersey", code: "(+44)", mask: "(999) 999-9999"},
  {id: 108, country: "Jordan", code: "(+962)", mask: "(999) 999-9999"},
  {id: 109, country: "Kazakhstan", code: "(+77)", mask: "(999) 999-9999"},
  {id: 110, country: "Kenya", code: "(+254)", mask: "(999) 999-9999"},
  {id: 111, country: "Kiribati", code: "(+686)", mask: "(999) 999-9999"},
  {id: 112, country: "Korea Democratic People's Republic of", code: "(+850)", mask: "(999) 999-9999"},
  {id: 113, country: "Korea Republic of", code: "(+82)", mask: "(999) 999-9999"},
  {id: 114, country: "Kuwait", code: "(+965)", mask: "(999) 999-9999"},
  {id: 115, country: "Kyrgyzstan", code: "(+996)", mask: "(999) 999-9999"},
  {id: 116, country: "land Islands", code: "(+)", mask: "(999) 999-9999"},
  {id: 117, country: "Lao People's Democratic Republic", code: "(+856)", mask: "(999) 999-9999"},
  {id: 118, country: "Latvia", code: "(+371)", mask: "99 999 999"},
  {id: 119, country: "Lebanon", code: "(+961)", mask: "(999) 999-9999"},
  {id: 120, country: "Lesotho", code: "(+266)", mask: "(999) 999-9999"},
  {id: 121, country: "Liberia", code: "(+231)", mask: "(999) 999-9999"},
  {id: 122, country: "Libyan Arab Jamahiriya", code: "(+218)", mask: "(999) 999-9999"},
  {id: 123, country: "Liechtenstein", code: "(+423)", mask: "(999) 999-9999"},
  {id: 124, country: "Lithuania", code: "(+370)", mask: "(9-9) 999 9999"},
  {id: 125, country: "Luxembourg", code: "(+352)", mask: "99 99 99 99"},
  {id: 126, country: "Macao", code: "(+853)", mask: "(999) 999-9999"},
  {id: 127, country: "Macedonia The Former Yugoslav Republic of", code: "(+389)", mask: "(999) 999-9999"},
  {id: 128, country: "Madagascar", code: "(+261)", mask: "(999) 999-9999"},
  {id: 129, country: "Malawi", code: "(+265)", mask: "(999) 999-9999"},
  {id: 130, country: "Malaysia", code: "(+60)", mask: "99-9999 9999"},
  {id: 131, country: "Maldives", code: "(+960)", mask: "(999) 999-9999"},
  {id: 132, country: "Mali", code: "(+223)", mask: "(999) 999-9999"},
  {id: 133, country: "Malta", code: "(+356)", mask: "9999 9999"},
  {id: 134, country: "Marshall Islands", code: "(+692)", mask: "(999) 999-9999"},
  {id: 135, country: "Martinique", code: "(+596)", mask: "(999) 999-9999"},
  {id: 136, country: "Mauritania", code: "(+222)", mask: "(999) 999-9999"},
  {id: 137, country: "Mauritius", code: "(+230)", mask: "(999) 999-9999"},
  {id: 138, country: "Mayotte", code: "(+262)", mask: "(999) 999-9999"},
  {id: 139, country: "Mexico", code: "(+52)", mask: "99 99 9999 9999"},
  {id: 140, country: "Micronesia Federated States of", code: "(+691)", mask: "(999) 999-9999"},
  {id: 141, country: "Moldova Republic of", code: "(+373)", mask: "(999) 999-9999"},
  {id: 142, country: "Monaco", code: "(+377)", mask: "(999) 999-9999"},
  {id: 143, country: "Mongolia", code: "(+976)", mask: "(999) 999-9999"},
  {id: 144, country: "Montenegro", code: "(+382)", mask: "(999) 999-9999"},
  {id: 145, country: "Montserrat", code: "(+1664)", mask: "(999) 999-9999"},
  {id: 146, country: "Morocco", code: "(+212)", mask: "(999) 999-9999"},
  {id: 147, country: "Mozambique", code: "(+258)", mask: "(999) 999-9999"},
  {id: 148, country: "Myanmar", code: "(+95)", mask: "(999) 999-9999"},
  {id: 149, country: "Namibia", code: "(+264)", mask: "(999) 999-9999"},
  {id: 150, country: "Nauru", code: "(+674)", mask: "(999) 999-9999"},
  {id: 151, country: "Nepal", code: "(+977)", mask: "(999) 999-9999"},
  {id: 152, country: "Netherlands Antilles", code: "(+599)", mask: "(999) 999-9999"},
  {id: 153, country: "Netherlands", code: "(+31)", mask: "999 999 9999"},
  {id: 154, country: "New Caledonia", code: "(+687)", mask: "(999) 999-9999"},
  {id: 155, country: "New Zealand", code: "(+64)", mask: "99-999 9999"},
  {id: 156, country: "Nicaragua", code: "(+505)", mask: "(999) 999-9999"},
  {id: 157, country: "Niger", code: "(+227)", mask: "(999) 999-9999"},
  {id: 158, country: "Nigeria", code: "(+234)", mask: "(999) 999-9999"},
  {id: 159, country: "Niue", code: "(+683)", mask: "(999) 999-9999"},
  {id: 160, country: "Norfolk Island", code: "(+672)", mask: "(999) 999-9999"},
  {id: 161, country: "Northern Mariana Islands", code: "(+1 670)", mask: "(999) 999-9999"},
  {id: 162, country: "Norway", code: "(+47)", mask: "99 99 99 99"},
  {id: 163, country: "Oman", code: "(+968)", mask: "(999) 999-9999"},
  {id: 164, country: "Pakistan", code: "(+92)", mask: "(999) 999-9999"},
  {id: 165, country: "Palau", code: "(+680)", mask: "(999) 999-9999"},
  {id: 166, country: "Palestinian Territory Occupied", code: "(+970)", mask: "(999) 999-9999"},
  {id: 167, country: "Panama", code: "(+507)", mask: "999-9999"},
  {id: 168, country: "Papua New Guinea", code: "(+675)", mask: "(999) 999-9999"},
  {id: 169, country: "Paraguay", code: "(+595)", mask: "(999) 999-9999"},
  {id: 170, country: "Peru", code: "(+51)", mask: "(99) 9999999"},
  {id: 171, country: "Philippines", code: "(+63)", mask: "(999) 999-9999"},
  {id: 172, country: "Pitcairn", code: "(+872)", mask: "(999) 999-9999"},
  {id: 173, country: "Poland", code: "(+48)", mask: "99 999 99 99"},
  {id: 174, country: "Portugal", code: "(+351)", mask: "999 999 999"},
  {id: 175, country: "Puerto Rico", code: "(+1 939)", mask: "(999) 999-9999"},
  {id: 176, country: "Qatar", code: "(+974)", mask: "(999) 999-9999"},
  {id: 177, country: "Reunion", code: "(+262)", mask: "(999) 999-9999"},
  {id: 178, country: "Romania", code: "(+40)", mask: "999 999 9999"},
  {id: 179, country: "Russia", code: "(+7)", mask: "(999) 999-9999"},
  {id: 180, country: "Rwanda", code: "(+250)", mask: "(999) 999-9999"},
  {id: 181, country: "Saint Barthelemy", code: "(+590)", mask: "(999) 999-9999"},
  {id: 182, country: "Saint Helena Ascension and Tristan Da Cunha", code: "(+290)", mask: "(999) 999-9999"},
  {id: 183, country: "Saint Kitts and Nevis", code: "(+1 869)", mask: "(999) 999-9999"},
  {id: 184, country: "Saint Lucia", code: "(+1 758)", mask: "(999) 999-9999"},
  {id: 185, country: "Saint Martin", code: "(+590)", mask: "(999) 999-9999"},
  {id: 186, country: "Saint Pierre and Miquelon", code: "(+508)", mask: "(999) 999-9999"},
  {id: 187, country: "Saint Vincent and the Grenadines", code: "(+1 784)", mask: "(999) 999-9999"},
  {id: 188, country: "Samoa", code: "(+685)", mask: "(999) 999-9999"},
  {id: 189, country: "San Marino", code: "(+378)", mask: "(999) 999-9999"},
  {id: 190, country: "Sao Tome and Principe", code: "(+239)", mask: "(999) 999-9999"},
  {id: 191, country: "Saudi Arabia", code: "(+966)", mask: "(999) 999-9999"},
  {id: 192, country: "Senegal", code: "(+221)", mask: "(999) 999-9999"},
  {id: 193, country: "Serbia", code: "(+381)", mask: "(999) 999-9999"},
  {id: 194, country: "Seychelles", code: "(+248)", mask: "(999) 999-9999"},
  {id: 195, country: "Sierra Leone", code: "(+232)", mask: "(999) 999-9999"},
  {id: 196, country: "Singapore", code: "(+65)", mask: "9999 9999"},
  {id: 197, country: "Slovakia", code: "(+421)", mask: "99/999 999 99"},
  {id: 198, country: "Slovenia", code: "(+386)", mask: "(99) 999 99 99"},
  {id: 199, country: "Solomon Islands", code: "(+677)", mask: "(999) 999-9999"},
  {id: 200, country: "Somalia", code: "(+252)", mask: "(999) 999-9999"},
  {id: 201, country: "South Africa", code: "(+27)", mask: "999 999 9999"},
  {id: 202, country: "South Georgia and the South Sandwich Islands", code: "(+500)", mask: "(999) 999-9999"},
  {id: 203, country: "Spain", code: "(+34)", mask: "999 99 99 99"},
  {id: 204, country: "Sri Lanka", code: "(+94)", mask: "(999) 999-9999"},
  {id: 205, country: "Sudan", code: "(+249)", mask: "(999) 999-9999"},
  {id: 206, country: "Suriname", code: "(+597)", mask: "(999) 999-9999"},
  {id: 207, country: "Svalbard and Jan Mayen", code: "(+47)", mask: "(999) 999-9999"},
  {id: 208, country: "Swaziland", code: "(+268)", mask: "(999) 999-9999"},
  {id: 209, country: "Sweden", code: "(+46)", mask: "99-999 999 99"},
  {id: 210, country: "Switzerland", code: "(+41)", mask: "999 999 99 99"},
  {id: 211, country: "Syrian Arab Republic", code: "(+963)", mask: "(999) 999-9999"},
  {id: 212, country: "Taiwan Province of China", code: "(+886)", mask: "(999) 999-9999"},
  {id: 213, country: "Tajikistan", code: "(+992)", mask: "(999) 999-9999"},
  {id: 214, country: "Tanzania United Republic of", code: "(+255)", mask: "(999) 999-9999"},
  {id: 215, country: "Thailand", code: "(+66)", mask: "(999) 999-9999"},
  {id: 216, country: "Timor-Leste", code: "(+670)", mask: "(999) 999-9999"},
  {id: 217, country: "Togo", code: "(+228)", mask: "(999) 999-9999"},
  {id: 218, country: "Tokelau", code: "(+690)", mask: "(999) 999-9999"},
  {id: 219, country: "Tonga", code: "(+676)", mask: "(999) 999-9999"},
  {id: 220, country: "Trinid:ad and Tobago", code: "(+1 868)", mask: "(999) 999-9999"},
  {id: 221, country: "Tunisia", code: "(+216)", mask: "(999) 999-9999"},
  {id: 222, country: "Turkey", code: "(+90)", mask: "(999) 999-9999"},
  {id: 223, country: "Turkmenistan", code: "(+993)", mask: "(999) 999-9999"},
  {id: 224, country: "Turks and Caicos Islands", code: "(+1 649)", mask: "(999) 999-9999"},
  {id: 225, country: "Tuvalu", code: "(+688)", mask: "(999) 999-9999"},
  {id: 226, country: "Uganda", code: "(+256)", mask: "(999) 999-9999"},
  {id: 227, country: "Ukraine", code: "(+380)", mask: "(999) 999-9999"},
  {id: 228, country: "United Arab Emirates", code: "(+971)", mask: "(999) 999-9999"},
  {id: 229, country: "United Kingdom", code: "(+44)", mask: "999 9999 9999"},
  {id: 230, country: "United States", code: "(+1)", mask: "(999) 999-999"},
  {id: 231, country: "Uruguay", code: "(+598)", mask: "(999) 999-9999"},
  {id: 232, country: "Uzbekistan", code: "(+998)", mask: "(999) 999-9999"},
  {id: 233, country: "Vanuatu", code: "(+678)", mask: "(999) 999-9999"},
  {id: 234, country: "Venezuela Bolivarian Republic of", code: "(+58)", mask: "(999) 999-9999"},
  {id: 235, country: "Viet Nam", code: "(+84)", mask: "(999) 999-9999"},
  {id: 236, country: "Virgin Islands British", code: "(+1 284)", mask: "(999) 999-9999"},
  {id: 237, country: "Virgin Islands U.S.", code: "(+1 340)", mask: "(999) 999-9999"},
  {id: 238, country: "Wallis and Futuna", code: "(+681)", mask: "(999) 999-9999"},
  {id: 239, country: "Yemen", code: "(+967)", mask: "(999) 999-9999"},
  {id: 240, country: "Zambia", code: "(+260)", mask: "(999) 999-9999"},
  {id: 241, country: "Zimbabwe", code: "(+263)", mask: "(999) 999-9999"}
  ]
/*
const telCountryList = [
  { id: 0, country: "", code: "", mask:"(999) 999-9999"},
  { id: 1, country: "Afghanistan", code: "(+93)" , mask:"(999) 999-9999"},
  { id: 2, country: "Albania", code: "(+355)" , mask:"(999) 999-9999"},
  { id: 3, country: "Algeria", code: "(+213)" , mask:"(999) 999-9999"},
  { id: 4, country: "AmericanSamoa", code: "(+1 684)" , mask:"(999) 999-9999"},
  { id: 5, country: "Andorra", code: "(+376)" , mask:"(999) 999-9999"},
  { id: 6, country: "Angola", code: "(+244)" , mask:"(999) 999-9999"},
  { id: 7, country: "Anguilla", code: "(+1 264)" , mask:"(999) 999-9999"},
  { id: 8, country: "Antarctica", code: "(+)" , mask:"(999) 999-9999"},
  { id: 9, country: "Antigua and Barbuda", code: "(+1268)" , mask:"(999) 999-9999"},
  { id: 10, country: "Argentina", code: "(+54)" , mask:"999 9999-9999"},
  { id: 11, country: "Armenia", code: "(+374)" , mask:"(999) 999-9999"},
  { id: 12, country: "Aruba", code: "(+297)" , mask:"(999) 999-9999"},
  { id: 13, country: "Australia", code: "(+61)" , mask:"(999) 999-9999"},
  { id: 14, country: "Austria", code: "(+43)" , mask:"(999) 999-9999"},
  { id: 15, country: "Azerbaijan", code: "(+994)" , mask:"(999) 999-9999"},
  { id: 16, country: "Bahamas", code: "(+1 242)" , mask:"(999) 999-9999"},
  { id: 17, country: "Bahrain", code: "(+973)" , mask:"(999) 999-9999"},
  { id: 18, country: "Bangladesh", code: "(+880)" , mask:"(999) 999-9999"},
  { id: 19, country: "Barbados", code: "(+1 246)" , mask:"(999) 999-9999"},
  { id: 20, country: "Belarus", code: "(+375)" , mask:"(999) 999-9999"},
  { id: 21, country: "Belgium", code: "(+32)" , mask:"(999) 999-9999"},
  { id: 22, country: "Belize", code: "(+501)" , mask:"(999) 999-9999"},
  { id: 23, country: "Benin", code: "(+229)" , mask:"(999) 999-9999"},
  { id: 24, country: "Bermuda", code: "(+1 441)" , mask:"(999) 999-9999"},
  { id: 25, country: "Bhutan", code: "(+975)" , mask:"(999) 999-9999"},
  { id: 26, country: "Bolivia Plurinational State of", code: "(+591)" , mask:"(999) 999-9999"},
  { id: 27, country: "Bosnia and Herzegovina", code: "(+387)" , mask:"(999) 999-9999"},
  { id: 28, country: "Botswana", code: "(+267)" , mask:"(999) 999-9999"},
  { id: 29, country: "Brazil", code: "(+55)" , mask:"(999) 999-9999"},
  { id: 30, country: "British Indian Ocean Territory", code: "(+246)" , mask:"(999) 999-9999"},
  { id: 31, country: "Brunei Darussalam", code: "(+673)" , mask:"(999) 999-9999"},
  { id: 32, country: "Bulgaria", code: "(+359)" , mask:"(999) 999-9999"},
  { id: 33, country: "Burkina Faso", code: "(+226)" , mask:"(999) 999-9999"},
  { id: 34, country: "Burundi", code: "(+257)" , mask:"(999) 999-9999"},
  { id: 35, country: "Cambodia", code: "(+855)" , mask:"(999) 999-9999"},
  { id: 36, country: "Cameroon", code: "(+237)" , mask:"(999) 999-9999"},
  { id: 37, country: "Canada", code: "(+1)" , mask:"(999) 999-9999"},
  { id: 38, country: "Cape Verde", code: "(+238)" , mask:"(999) 999-9999"},
  { id: 39, country: "Cayman Islands", code: "(+345)" , mask:"(999) 999-9999"},
  { id: 40, country: "Central African Republic", code: "(+236)" , mask:"(999) 999-9999"},
  { id: 41, country: "Chad", code: "(+235)" , mask:"(999) 999-9999"},
  { id: 42, country: "Chile", code: "(+56)" , mask:"(999) 999-9999"},
  { id: 43, country: "China", code: "(+86)" , mask:"(999) 999-9999"},
  { id: 44, country: "Christmas Island", code: "(+61)" , mask:"(999) 999-9999"},
  { id: 45, country: "Cocos (Keeling) Islands", code: "(+61)" , mask:"(999) 999-9999"},
  { id: 46, country: "Colombia", code: "(+57)" , mask:"(999) 999-9999"},
  { id: 47, country: "Comoros", code: "(+269)" , mask:"(999) 999-9999"},
  { id: 48, country: "Congo The Democratic Republic of the", code: "(+243)" , mask:"(999) 999-9999"},
  { id: 49, country: "Congo", code: "(+242)" , mask:"(999) 999-9999"},
  { id: 50, country: "Cook Islands", code: "(+682)" , mask:"(999) 999-9999"},
  { id: 51, country: "Costa Rica", code: "(+506)" , mask:"(999) 999-9999"},
  { id: 52, country: "Cote d'Ivoire", code: "(+225)" , mask:"(999) 999-9999"},
  { id: 53, country: "Croatia", code: "(+385)" , mask:"(999) 999-9999"},
  { id: 54, country: "Cuba", code: "(+53)" , mask:"(999) 999-9999"},
  { id: 55, country: "Cyprus", code: "(+537)" , mask:"(999) 999-9999"},
  { id: 56, country: "Czech Republic", code: "(+420)" , mask:"(999) 999-9999"},
  { id: 57, country: "Denmark", code: "(+45)" , mask:"(999) 999-9999"},
  { id: 58, country: "Djibouti", code: "(+253)" , mask:"(999) 999-9999"},
  { id: 59, country: "Dominica", code: "(+1 767)" , mask:"(999) 999-9999"},
  { id: 60, country: "Dominican Republic", code: "(+1 849)" , mask:"(999) 999-9999"},
  { id: 61, country: "Ecuador", code: "(+593)" , mask:"(999) 999-9999"},
  { id: 62, country: "Egypt", code: "(+20)" , mask:"(999) 999-9999"},
  { id: 63, country: "El Salvador", code: "(+503)" , mask:"(999) 999-9999"},
  { id: 64, country: "Equatorial Guinea", code: "(+240)" , mask:"(999) 999-9999"},
  { id: 65, country: "Eritrea", code: "(+291)" , mask:"(999) 999-9999"},
  { id: 66, country: "Estonia", code: "(+372)" , mask:"(999) 999-9999"},
  { id: 67, country: "Ethiopia", code: "(+251)" , mask:"(999) 999-9999"},
  { id: 68, country: "Falkland Islands (Malvinas)", code: "(+500)" , mask:"(999) 999-9999"},
  { id: 69, country: "Faroe Islands", code: "(+298)" , mask:"(999) 999-9999"},
  { id: 70, country: "Fiji", code: "(+679)" , mask:"(999) 999-9999"},
  { id: 71, country: "Finland", code: "(+358)" , mask:"(999) 999-9999"},
  { id: 72, country: "France", code: "(+33)" , mask:"(999) 999-9999"},
  { id: 73, country: "French Guiana", code: "(+594)" , mask:"(999) 999-9999"},
  { id: 74, country: "French Polynesia", code: "(+689)" , mask:"(999) 999-9999"},
  { id: 75, country: "Gabon", code: "(+241)" , mask:"(999) 999-9999"},
  { id: 76, country: "Gambia", code: "(+220)" , mask:"(999) 999-9999"},
  { id: 77, country: "Georgia", code: "(+995)" , mask:"(999) 999-9999"},
  { id: 78, country: "Germany", code: "(+49)" , mask:"(999) 999-9999"},
  { id: 79, country: "Ghana", code: "(+233)" , mask:"(999) 999-9999"},
  { id: 80, country: "Gibraltar", code: "(+350)" , mask:"(999) 999-9999"},
  { id: 81, country: "Greece", code: "(+30)" , mask:"(999) 999-9999"},
  { id: 82, country: "Greenland", code: "(+299)" , mask:"(999) 999-9999"},
  { id: 83, country: "Grenada", code: "(+1 473)" , mask:"(999) 999-9999"},
  { id: 84, country: "Guadeloupe", code: "(+590)" , mask:"(999) 999-9999"},
  { id: 85, country: "Guam", code: "(+1 671)" , mask:"(999) 999-9999"},
  { id: 86, country: "Guatemala", code: "(+502)" , mask:"(999) 999-9999"},
  { id: 87, country: "Guernsey", code: "(+44)" , mask:"(999) 999-9999"},
  { id: 88, country: "Guinea", code: "(+224)" , mask:"(999) 999-9999"},
  { id: 89, country: "Guinea-Bissau", code: "(+245)" , mask:"(999) 999-9999"},
  { id: 90, country: "Guyana", code: "(+595)" , mask:"(999) 999-9999"},
  { id: 91, country: "Haiti", code: "(+509)" , mask:"(999) 999-9999"},
  { id: 92, country: "Holy See (Vatican City State)", code: "(+379)" , mask:"(999) 999-9999"},
  { id: 93, country: "Honduras", code: "(+504)" , mask:"(999) 999-9999"},
  { id: 94, country: "Hong Kong", code: "(+852)" , mask:"(999) 999-9999"},
  { id: 95, country: "Hungary", code: "(+36)" , mask:"(999) 999-9999"},
  { id: 96, country: "Iceland", code: "(+354)" , mask:"(999) 999-9999"},
  { id: 97, country: "India", code: "(+91)" , mask:"(999) 999-9999"},
  { id: 98, country: "Indonesia", code: "(+62)" , mask:"(999) 999-9999"},
  { id: 99, country: "Iran Islamic Republic of", code: "(+98)" , mask:"(999) 999-9999"},
  { id: 100, country: "Iraq", code: "(+964)" , mask:"(999) 999-9999"},
  { id: 101, country: "Ireland", code: "(+353)" , mask:"(999) 999-9999"},
  { id: 102, country: "Isle of Man", code: "(+44)" , mask:"(999) 999-9999"},
  { id: 103, country: "Israel", code: "(+972)" , mask:"(999) 999-9999"},
  { id: 104, country: "Italy", code: "(+39)" , mask:"(999) 999-9999"},
  { id: 105, country: "Jamaica", code: "(+1 876)" , mask:"(999) 999-9999"},
  { id: 106, country: "Japan", code: "(+81)" , mask:"(999) 999-9999"},
  { id: 107, country: "Jersey", code: "(+44)" , mask:"(999) 999-9999"},
  { id: 108, country: "Jordan", code: "(+962)" , mask:"(999) 999-9999"},
  { id: 109, country: "Kazakhstan", code: "(+77)" , mask:"(999) 999-9999"},
  { id: 110, country: "Kenya", code: "(+254)" , mask:"(999) 999-9999"},
  { id: 111, country: "Kiribati", code: "(+686)" , mask:"(999) 999-9999"},
  { id: 112, country: "Korea Democratic People's Republic of", code: "(+850)" , mask:"(999) 999-9999"},
  { id: 113, country: "Korea Republic of", code: "(+82)" , mask:"(999) 999-9999"},
  { id: 114, country: "Kuwait", code: "(+965)" , mask:"(999) 999-9999"},
  { id: 115, country: "Kyrgyzstan", code: "(+996)" , mask:"(999) 999-9999"},
  { id: 116, country: "land Islands", code: "(+)" , mask:"(999) 999-9999"},
  { id: 117, country: "Lao People's Democratic Republic", code: "(+856)" , mask:"(999) 999-9999"},
  { id: 118, country: "Latvia", code: "(+371)" , mask:"(999) 999-9999"},
  { id: 119, country: "Lebanon", code: "(+961)" , mask:"(999) 999-9999"},
  { id: 120, country: "Lesotho", code: "(+266)" , mask:"(999) 999-9999"},
  { id: 121, country: "Liberia", code: "(+231)" , mask:"(999) 999-9999"},
  { id: 122, country: "Libyan Arab Jamahiriya", code: "(+218)" , mask:"(999) 999-9999"},
  { id: 123, country: "Liechtenstein", code: "(+423)" , mask:"(999) 999-9999"},
  { id: 124, country: "Lithuania", code: "(+370)" , mask:"(999) 999-9999"},
  { id: 125, country: "Luxembourg", code: "(+352)" , mask:"(999) 999-9999"},
  { id: 126, country: "Macao", code: "(+853)" , mask:"(999) 999-9999"},
  { id: 127, country: "Macedonia The Former Yugoslav Republic of", code: "(+389)", mask:"(999) 999-9999"},
  { id: 128, country: "Madagascar", code: "(+261)" , mask:"(999) 999-9999"},
  { id: 129, country: "Malawi", code: "(+265)" , mask:"(999) 999-9999"},
  { id: 130, country: "Malaysia", code: "(+60)" , mask:"(999) 999-9999"},
  { id: 131, country: "Maldives", code: "(+960)" , mask:"(999) 999-9999"},
  { id: 132, country: "Mali", code: "(+223)" , mask:"(999) 999-9999"},
  { id: 133, country: "Malta", code: "(+356)" , mask:"(999) 999-9999"},
  { id: 134, country: "Marshall Islands", code: "(+692)" , mask:"(999) 999-9999"},
  { id: 135, country: "Martinique", code: "(+596)" , mask:"(999) 999-9999"},
  { id: 136, country: "Mauritania", code: "(+222)" , mask:"(999) 999-9999"},
  { id: 137, country: "Mauritius", code: "(+230)" , mask:"(999) 999-9999"},
  { id: 138, country: "Mayotte", code: "(+262)" , mask:"(999) 999-9999"},
  { id: 139, country: "Mexico", code: "(+52)" , mask:"(999) 999-9999"},
  { id: 140, country: "Micronesia Federated States of", code: "(+691)" , mask:"(999) 999-9999"},
  { id: 141, country: "Moldova Republic of", code: "(+373)" , mask:"(999) 999-9999"},
  { id: 142, country: "Monaco", code: "(+377)" , mask:"(999) 999-9999"},
  { id: 143, country: "Mongolia", code: "(+976)" , mask:"(999) 999-9999"},
  { id: 144, country: "Montenegro", code: "(+382)" , mask:"(999) 999-9999"},
  { id: 145, country: "Montserrat", code: "(+1664)" , mask:"(999) 999-9999"},
  { id: 146, country: "Morocco", code: "(+212)" , mask:"(999) 999-9999"},
  { id: 147, country: "Mozambique", code: "(+258)" , mask:"(999) 999-9999"},
  { id: 148, country: "Myanmar", code: "(+95)" , mask:"(999) 999-9999"},
  { id: 149, country: "Namibia", code: "(+264)" , mask:"(999) 999-9999"},
  { id: 150, country: "Nauru", code: "(+674)" , mask:"(999) 999-9999"},
  { id: 151, country: "Nepal", code: "(+977)" , mask:"(999) 999-9999"},
  { id: 152, country: "Netherlands Antilles", code: "(+599)" , mask:"(999) 999-9999"},
  { id: 153, country: "Netherlands", code: "(+31)" , mask:"(999) 999-9999"},
  { id: 154, country: "New Caledonia", code: "(+687)" , mask:"(999) 999-9999"},
  { id: 155, country: "New Zealand", code: "(+64)" , mask:"(999) 999-9999"},
  { id: 156, country: "Nicaragua", code: "(+505)" , mask:"(999) 999-9999"},
  { id: 157, country: "Niger", code: "(+227)" , mask:"(999) 999-9999"},
  { id: 158, country: "Nigeria", code: "(+234)" , mask:"(999) 999-9999"},
  { id: 159, country: "Niue", code: "(+683)" , mask:"(999) 999-9999"},
  { id: 160, country: "Norfolk Island", code: "(+672)" , mask:"(999) 999-9999"},
  { id: 161, country: "Northern Mariana Islands", code: "(+1 670)" , mask:"(999) 999-9999"},
  { id: 162, country: "Norway", code: "(+47)" , mask:"(999) 999-9999"},
  { id: 163, country: "Oman", code: "(+968)" , mask:"(999) 999-9999"},
  { id: 164, country: "Pakistan", code: "(+92)" , mask:"(999) 999-9999"},
  { id: 165, country: "Palau", code: "(+680)" , mask:"(999) 999-9999"},
  { id: 166, country: "Palestinian Territory Occupied", code: "(+970)" , mask:"(999) 999-9999"},
  { id: 167, country: "Panama", code: "(+507)" , mask:"(999) 999-9999"},
  { id: 168, country: "Papua New Guinea", code: "(+675)" , mask:"(999) 999-9999"},
  { id: 169, country: "Paraguay", code: "(+595)" , mask:"(999) 999-9999"},
  { id: 170, country: "Peru", code: "(+51)" , mask:"(999) 999-9999"},
  { id: 171, country: "Philippines", code: "(+63)" , mask:"(999) 999-9999"},
  { id: 172, country: "Pitcairn", code: "(+872)" , mask:"(999) 999-9999"},
  { id: 173, country: "Poland", code: "(+48)" , mask:"(999) 999-9999"},
  { id: 174, country: "Portugal", code: "(+351)" , mask:"(999) 999-9999"},
  { id: 175, country: "Puerto Rico", code: "(+1 939)" , mask:"(999) 999-9999"},
  { id: 176, country: "Qatar", code: "(+974)" , mask:"(999) 999-9999"},
  { id: 177, country: "Reunion", code: "(+262)" , mask:"(999) 999-9999"},
  { id: 178, country: "Romania", code: "(+40)" , mask:"(999) 999-9999"},
  { id: 179, country: "Russia", code: "(+7)" , mask:"(999) 999-9999"},
  { id: 180, country: "Rwanda", code: "(+250)" , mask:"(999) 999-9999"},
  { id: 181, country: "Saint Barthelemy", code: "(+590)" , mask:"(999) 999-9999"},
  { id: 182, country: "Saint Helena Ascension and Tristan Da Cunha", code: "(+290)", mask:"(999) 999-9999"},
  { id: 183, country: "Saint Kitts and Nevis", code: "(+1 869)" , mask:"(999) 999-9999"},
  { id: 184, country: "Saint Lucia", code: "(+1 758)" , mask:"(999) 999-9999"},
  { id: 185, country: "Saint Martin", code: "(+590)" , mask:"(999) 999-9999"},
  { id: 186, country: "Saint Pierre and Miquelon", code: "(+508)" , mask:"(999) 999-9999"},
  { id: 187, country: "Saint Vincent and the Grenadines", code: "(+1 784)" , mask:"(999) 999-9999"},
  { id: 188, country: "Samoa", code: "(+685)" , mask:"(999) 999-9999"},
  { id: 189, country: "San Marino", code: "(+378)" , mask:"(999) 999-9999"},
  { id: 190, country: "Sao Tome and Principe", code: "(+239)" , mask:"(999) 999-9999"},
  { id: 191, country: "Saudi Arabia", code: "(+966)" , mask:"(999) 999-9999"},
  { id: 192, country: "Senegal", code: "(+221)" , mask:"(999) 999-9999"},
  { id: 193, country: "Serbia", code: "(+381)" , mask:"(999) 999-9999"},
  { id: 194, country: "Seychelles", code: "(+248)" , mask:"(999) 999-9999"},
  { id: 195, country: "Sierra Leone", code: "(+232)" , mask:"(999) 999-9999"},
  { id: 196, country: "Singapore", code: "(+65)" , mask:"(999) 999-9999"},
  { id: 197, country: "Slovakia", code: "(+421)" , mask:"(999) 999-9999"},
  { id: 198, country: "Slovenia", code: "(+386)" , mask:"(999) 999-9999"},
  { id: 199, country: "Solomon Islands", code: "(+677)" , mask:"(999) 999-9999"},
  { id: 200, country: "Somalia", code: "(+252)" , mask:"(999) 999-9999"},
  { id: 201, country: "South Africa", code: "(+27)" , mask:"(999) 999-9999"},
  { id: 202,country: "South Georgia and the South Sandwich Islands", code: "(+500)",mask:"(999) 999-9999"},
  { id: 203, country: "Spain", code: "(+34)" , mask:"(999) 999-9999"},
  { id: 204, country: "Sri Lanka", code: "(+94)" , mask:"(999) 999-9999"},
  { id: 205, country: "Sudan", code: "(+249)" , mask:"(999) 999-9999"},
  { id: 206, country: "Suriname", code: "(+597)" , mask:"(999) 999-9999"},
  { id: 207, country: "Svalbard and Jan Mayen", code: "(+47)" , mask:"(999) 999-9999"},
  { id: 208, country: "Swaziland", code: "(+268)" , mask:"(999) 999-9999"},
  { id: 209, country: "Sweden", code: "(+46)" , mask:"(999) 999-9999"},
  { id: 210, country: "Switzerland", code: "(+41)" , mask:"(999) 999-9999"},
  { id: 211, country: "Syrian Arab Republic", code: "(+963)" , mask:"(999) 999-9999"},
  { id: 212, country: "Taiwan Province of China", code: "(+886)" , mask:"(999) 999-9999"},
  { id: 213, country: "Tajikistan", code: "(+992)" , mask:"(999) 999-9999"},
  { id: 214, country: "Tanzania United Republic of", code: "(+255)" , mask:"(999) 999-9999"},
  { id: 215, country: "Thailand", code: "(+66)" , mask:"(999) 999-9999"},
  { id: 216, country: "Timor-Leste", code: "(+670)" , mask:"(999) 999-9999"},
  { id: 217, country: "Togo", code: "(+228)" , mask:"(999) 999-9999"},
  { id: 218, country: "Tokelau", code: "(+690)" , mask:"(999) 999-9999"},
  { id: 219, country: "Tonga", code: "(+676)" , mask:"(999) 999-9999"},
  { id: 220, country: "Trinid:ad and Tobago", code: "(+1 868)" , mask:"(999) 999-9999"},
  { id: 221, country: "Tunisia", code: "(+216)" , mask:"(999) 999-9999"},
  { id: 222, country: "Turkey", code: "(+90)" , mask:"(999) 999-9999"},
  { id: 223, country: "Turkmenistan", code: "(+993)" , mask:"(999) 999-9999"},
  { id: 224, country: "Turks and Caicos Islands", code: "(+1 649)" , mask:"(999) 999-9999"},
  { id: 225, country: "Tuvalu", code: "(+688)" , mask:"(999) 999-9999"},
  { id: 226, country: "Uganda", code: "(+256)" , mask:"(999) 999-9999"},
  { id: 227, country: "Ukraine", code: "(+380)" , mask:"(999) 999-9999"},
  { id: 228, country: "United Arab Emirates", code: "(+971)" , mask:"(999) 999-9999"},
  { id: 229, country: "United Kingdom", code: "(+44)" , mask:"(999) 999-9999"},
  { id: 230, country: "United States", code: "(+1)" , mask:"(999) 999-9999"},
  { id: 231, country: "Uruguay", code: "(+598)" , mask:"(999) 999-9999"},
  { id: 232, country: "Uzbekistan", code: "(+998)" , mask:"(999) 999-9999"},
  { id: 233, country: "Vanuatu", code: "(+678)" , mask:"(999) 999-9999"},
  { id: 234, country: "Venezuela Bolivarian Republic of", code: "(+58)" , mask:"(999) 999-9999"},
  { id: 235, country: "Viet Nam", code: "(+84)" , mask:"(999) 999-9999"},
  { id: 236, country: "Virgin Islands British", code: "(+1 284)" , mask:"(999) 999-9999"},
  { id: 237, country: "Virgin Islands U.S.", code: "(+1 340)" , mask:"(999) 999-9999"},
  { id: 238, country: "Wallis and Futuna", code: "(+681)" , mask:"(999) 999-9999"},
  { id: 239, country: "Yemen", code: "(+967)" , mask:"(999) 999-9999"},
  { id: 240, country: "Zambia", code: "(+260)" , mask:"(999) 999-9999"},
  { id: 241, country: "Zimbabwe", code: "(+263)" , mask:"(999) 999-9999"},
];
*/
export default telCountryList;



