const alcoholHistoryJSON =  {
  section: "DS",
  topic: "ALCOHOL/DRUGS",
  checked: "true",
  // access: "public",
  color: "secondary",
  description: "",
  // section: "D",
  // topic: "ALCOHOL/DRUGS",
  // checked: "true",
  // // access: "public",
  // color: "secondary",
  body: [
    {
      id: "D1",
      shortname: "ds1",
      name: "What substances have been used even once?",
      prevalue: "",
      value: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      disabled: "false",
      type: "checkbox",
      radio_options: [
        "Alcohol (any use at all)",
        "Alcohol (to intoxication)",
        "Heroin",
        "Methadone",
        "Other Opiates/Analgesics",
        "Barbiturates",
        "Other Sedatives/Hypnotics/ Tranquilizers",
        "Cocaine",
        "Amphetamines",
        "Cannabis",
        "Hallucinogens",
        "Inhalants",
        "More than one substance per day",
      ],
      act: "DS1",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "",
      shortname: "ds1-1-13-subtopic",
      // name: "For Questions M7 & M8, ask patient to use the Patient Rating Scale.",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "subtopic",
      act: "DS1-1-13-ACT",
      depending: "DS1",
      exception: "Alcohol (any use at all);Alcohol (to intoxication);Heroin;Methadone;Other Opiates/Analgesics;Barbiturates;Other Sedatives/Hypnotics/ Tranquilizers;Cocaine;Amphetamines;Cannabis;Hallucinogens;Inhalants;More than one substance per day",
      // "Cannabis products, including K2;Cocaine;Crack;Crank;Ecstasy;Hallucinogens /LSD/mushrooms, etc.;Heroin;Inhalants;Meth or other Amphetamines;Methadone;Opiates: Hydrocodone/ Oxycontin/ Morphine/other;Sedative/ Hypnotic/Anxiolytics;Other Substance",
    },
//========================================================================================
/*                                                                                      *
 *                        D1 Alcohol (any use at all)                               *
 *                                                                                      */
//========================================================================================

    {
      id: "D1",
      shortname: "ds1-1-1",
      name: "",
      prevalue: "",
      value: [
        true,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "A. Past 30 Days",
      ],
      act: "DS1-1-1",
      // mandatory: "true",
      depending: "DS1",
      exception: "Alcohol (any use at all)",
      link: "DS1-1-1-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-1-CODE",
      subtopic_name:
        "Alcohol (any use at all)",
    },
    {
      id: "D1",
      extend: "true",
      shortname: "ds1-1-1-1",
      name: "",
      prevalue: "How many days in the past 30 you use Alcohol (any use at all)?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-1-1-1",
      depending: "DS1-1-1",
      exception: "A. Past 30 Days",
      link: "DS1-1-2",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-1-CODE",
      subtopic_name:
        "Alcohol (any use at all)",
    },
    {
      id: "D1",
      extend: "true",
      shortname: "ds1-1-2",
      name: "",
      prevalue: "",
      value: [
        true,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "B. Lifetime (Years)",
      ],
      act: "DS1-1-2",
      // mandatory: "true",
      depending: "DS1",
      exception: "Alcohol (any use at all)",
      link: "DS1-1-2-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-1-CODE",
      subtopic_name:
        "Alcohol (any use at all)",
    },
    {
      id: "D1",
      extend: "true",
      shortname: "ds1-1-2-1",
      name: "",
      prevalue: "How many years in the Lifetime you use Alcohol (any use at all)?",
      value: "",
      disabled: "true",
      type: "number",
      // type: "select",
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-1-2-1",
      depending: "DS1-1-2",
      exception: "B. Lifetime (Years)",
      link: "DS1-1-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-1-CODE",
      subtopic_name:
        "Alcohol (any use at all)",
    },
    {
      id: "D1",
      extend: "true",
      shortname: "ds1-1-3",
      name: "",
      prevalue: "",
      value: [
        true,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "C. Route of Admin",
      ],
      act: "DS1-1-3",
      // mandatory: "true",
      depending: "DS1",
      exception: "Alcohol (any use at all)",
      link: "DS1-1-3-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-1-CODE",
      subtopic_name:
        "Alcohol (any use at all)",
    },
    {
      id: "D1",
      extend: "true",
      shortname: "ds1-1-3-1",
      name: "",
      prevalue: "What is the usual way you use Alcohol (any use at all)?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - oral",
        "2 - nasal",
        "3 - smoking",
        "4 - non-IV injection",
        "5 - IV injection",
      ],
      act: "DS1-1-3-1",
      depending: "DS1-1-3",
      exception: "C. Route of Admin",
      // link: "DS1-1-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-1-CODE",
      subtopic_name:
        "Alcohol (any use at all)",
    },

    // {
    //   id: "D1",
    //   shortname: "ds1-1",
    //   name: "Alcohol (any use at all)",
    //   prevalue: "",
    //   value: [
    //     false,
    //     false,
    //     false,
    //   ],
    //   disabled: "true",
    //   type: "checkbox",
    //   radio_options: [
    //     "A. Past 30 Days",
    //     "B. Lifetime (Years)",
    //     "C. Route of Admin",
    //   ],
    //   act: "DS1-1",
    //   mandatory: "true",
    //   depending: "DS1",
    //   exception: "Alcohol (any use at all)",
    //   subtopic_container_act: "DS1-1-13-ACT",
    //   suptopic_code: "DS1-1-CODE",
    //   subtopic_name:
    //     "Alcohol (any use at all)",
    // },
//========================================================================================
/*                                                                                      *
 *                             D2 Alcohol (to intoxication)                              *
 *                                                                                      */
//========================================================================================

    {
      id: "D2",
      shortname: "ds1-2-1",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "A. Past 30 Days",
      ],
      act: "DS1-2-1",
      // mandatory: "true",
      depending: "DS1",
      exception: "Alcohol (to intoxication)",
      link: "DS1-2-1-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-2-CODE",
      subtopic_name:
        "Alcohol (to intoxication)",
    },
    {
      id: "D2",
      extend: "true",
      shortname: "ds1-2-1-1",
      name: "",
      prevalue: "How many days in the past 30 you use Alcohol (to intoxication)?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-2-1-1",
      depending: "DS1-2-1",
      exception: "A. Past 30 Days",
      link: "DS1-2-2",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-2-CODE",
      subtopic_name:
        "Alcohol (to intoxication)",
    },
    {
      id: "D2",
      extend: "true",
      shortname: "ds1-2-2",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "B. Lifetime (Years)",
      ],
      act: "DS1-2-2",
      // mandatory: "true",
      depending: "DS1",
      exception: "Alcohol (to intoxication)",
      link: "DS1-2-2-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-2-CODE",
      subtopic_name:
        "Alcohol (to intoxication)",
    },
    {
      id: "D2",
      extend: "true",
      shortname: "ds1-2-2-1",
      name: "",
      prevalue: "How many years in the Lifetime you use Alcohol (to intoxication)?",
      value: "",
      disabled: "true",
      type: "number",
      // type: "select",
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-2-2-1",
      depending: "DS1-2-2",
      exception: "B. Lifetime (Years)",
      link: "DS1-2-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-2-CODE",
      subtopic_name:
        "Alcohol (to intoxication)",
    },
    {
      id: "D2",
      extend: "true",
      shortname: "ds1-2-3",
      name: "",
      prevalue: "",
      value: [
        true,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "C. Route of Admin",
      ],
      act: "DS1-2-3",
      // mandatory: "true",
      depending: "DS1",
      exception: "Alcohol (to intoxication)",
      link: "DS1-2-3-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-2-CODE",
      subtopic_name:
        "Alcohol (to intoxication)",
    },
    {
      id: "D2",
      extend: "true",
      shortname: "ds1-2-3-1",
      name: "",
      prevalue: "What is the usual way you use Alcohol (to intoxication)?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - oral",
        "2 - nasal",
        "3 - smoking",
        "4 - non-IV injection",
        "5 - IV injection",
      ],
      act: "DS1-2-3-1",
      depending: "DS1-2-3",
      exception: "C. Route of Admin",
      // link: "DS1-1-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-2-CODE",
      subtopic_name:
        "Alcohol (to intoxication)",
    },
    // {
    //   id: "D2",
    //   shortname: "ds1-2",
    //   name: "Alcohol (to intoxication)",
    //   prevalue: "",
    //   value: [
    //     false,
    //     false,
    //     false,
    //   ],
    //   disabled: "true",
    //   type: "checkbox",
    //   radio_options: [
    //     "A. Past 30 Days",
    //     "B. Lifetime (Years)",
    //     "C. Route of Admin",
    //   ],
    //   act: "DS1-2",
    //   mandatory: "true",
    //   depending: "DS1",
    //   exception: "Alcohol (to intoxication)",
      // subtopic_container_act: "DS1-1-13-ACT",
      // suptopic_code: "DS1-2-CODE",
      // subtopic_name:
      //   "Alcohol (to intoxication)",
    // },
//========================================================================================
/*                                                                                      *
 *                                       D3 Heroin                                        *
 *                                                                                      */
//========================================================================================

    {
      id: "D3",
      shortname: "ds1-3-1",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "A. Past 30 Days",
      ],
      act: "DS1-3-1",
      // mandatory: "true",
      depending: "DS1",
      exception: "Heroin",
      link: "DS1-3-1-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-3-CODE",
      subtopic_name:
        "Heroin",

    },
    {
      id: "D3",
      extend: "true",
      shortname: "ds1-3-1-1",
      name: "",
      prevalue: "How many days in the past 30 you use Heroin?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-3-1-1",
      depending: "DS1-3-1",
      exception: "A. Past 30 Days",
      link: "DS1-3-2",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-3-CODE",
      subtopic_name:
        "Heroin",
    },
    {
      id: "D3",
      extend: "true",
      shortname: "ds1-3-2",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "B. Lifetime (Years)",
      ],
      act: "DS1-3-2",
      // mandatory: "true",
      depending: "DS1",
      exception: "Heroin",
      link: "DS1-3-2-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-3-CODE",
      subtopic_name:
        "Heroin",
    },
    {
      id: "D3",
      extend: "true",
      shortname: "ds1-3-2-1",
      name: "",
      prevalue: "How many years in the Lifetime you use Heroin?",
      value: "",
      disabled: "true",
      type: "number",
      // type: "select",
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-3-2-1",
      depending: "DS1-3-2",
      exception: "B. Lifetime (Years)",
      link: "DS1-3-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-3-CODE",
      subtopic_name:
        "Heroin",
    },
    {
      id: "D3",
      extend: "true",
      shortname: "ds1-3-3",
      name: "",
      prevalue: "",
      value: [
        true,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "C. Route of Admin",
      ],
      act: "DS1-3-3",
      // mandatory: "true",
      depending: "DS1",
      exception: "Heroin",
      link: "DS1-3-3-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-3-CODE",
      subtopic_name:
        "Heroin",
    },
    {
      id: "D3",
      extend: "true",
      shortname: "ds1-3-3-1",
      name: "",
      prevalue: "What is the usual way you use Heroin?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - oral",
        "2 - nasal",
        "3 - smoking",
        "4 - non-IV injection",
        "5 - IV injection",
      ],
      act: "DS1-3-3-1",
      depending: "DS1-3-3",
      exception: "C. Route of Admin",
      // link: "DS1-1-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-3-CODE",
      subtopic_name:
        "Heroin",
    },
    // {
    //   id: "D3",
    //   shortname: "ds1-3",
    //   name: "Heroin",
    //   prevalue: "",
    //   value: [
    //     false,
    //     false,
    //     false,
    //   ],
    //   disabled: "true",
    //   type: "checkbox",
    //   radio_options: [
    //     "A. Past 30 Days",
    //     "B. Lifetime (Years)",
    //     "C. Route of Admin",
    //   ],
    //   act: "DS1-3",
    //   mandatory: "true",
    //   depending: "DS1",
    //   exception: "Heroin",
      // subtopic_container_act: "DS1-1-13-ACT",
      // suptopic_code: "DS1-3-CODE",
      // subtopic_name:
      //   "Heroin",
    // },
//========================================================================================
/*                                                                                      *
 *                                     D4 Methadone                                      *
 *                                                                                      */
//========================================================================================

    {
      id: "D4",
      shortname: "ds1-4-1",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "A. Past 30 Days",
      ],
      act: "DS1-4-1",
      // mandatory: "true",
      depending: "DS1",
      exception: "Methadone",
      link: "DS1-4-1-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-4-CODE",
      subtopic_name:
        "Methadone",

    },
    {
      id: "D4",
      extend: "true",
      shortname: "ds1-4-1-1",
      name: "",
      prevalue: "How many days in the past 30 you use Methadone?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-4-1-1",
      depending: "DS1-4-1",
      exception: "A. Past 30 Days",
      link: "DS1-4-2",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-4-CODE",
      subtopic_name:
        "Methadone",
    },
    {
      id: "D4",
      extend: "true",
      shortname: "ds1-4-2",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "B. Lifetime (Years)",
      ],
      act: "DS1-4-2",
      // mandatory: "true",
      depending: "DS1",
      exception: "Methadone",
      link: "DS1-4-2-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-4-CODE",
      subtopic_name:
        "Methadone",
    },
    {
      id: "D4",
      extend: "true",
      shortname: "ds1-4-2-1",
      name: "",
      prevalue: "How many years in the Lifetime you use Methadone?",
      value: "",
      disabled: "true",
      type: "number",
      // type: "select",
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-4-2-1",
      depending: "DS1-4-2",
      exception: "B. Lifetime (Years)",
      link: "DS1-4-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-4-CODE",
      subtopic_name:
        "Methadone",
    },
    {
      id: "D4",
      extend: "true",
      shortname: "ds1-4-3",
      name: "",
      prevalue: "",
      value: [
        true,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "C. Route of Admin",
      ],
      act: "DS1-4-3",
      // mandatory: "true",
      depending: "DS1",
      exception: "Methadone",
      link: "DS1-4-3-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-4-CODE",
      subtopic_name:
        "Methadone",
    },
    {
      id: "D4",
      extend: "true",
      shortname: "ds1-4-3-1",
      name: "",
      prevalue: "What is the usual way you use Methadone?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - oral",
        "2 - nasal",
        "3 - smoking",
        "4 - non-IV injection",
        "5 - IV injection",
      ],
      act: "DS1-4-3-1",
      depending: "DS1-4-3",
      exception: "C. Route of Admin",
      // link: "DS1-1-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-4-CODE",
      subtopic_name:
        "Methadone",
    },
    // {
    //   id: "D4",
    //   shortname: "ds1-4",
    //   name: "Methadone",
    //   prevalue: "",
    //   value: [
    //     false,
    //     false,
    //     false,
    //   ],
    //   disabled: "true",
    //   type: "checkbox",
    //   radio_options: [
    //     "A. Past 30 Days",
    //     "B. Lifetime (Years)",
    //     "C. Route of Admin",
    //   ],
    //   act: "DS1-4",
    //   mandatory: "true",
    //   depending: "DS1",
    //   exception: "Methadone",
    //   subtopic_container_act: "DS1-1-13-ACT",
    //   suptopic_code: "DS1-4-CODE",
    //   subtopic_name:
    //     "Methadone",
    // },
//========================================================================================
/*                                                                                      *
 *                              D5 Other Opiates/Analgesics                               *
 *                                                                                      */
//========================================================================================

    {
      id: "D5",
      shortname: "ds1-5-1",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "A. Past 30 Days",
      ],
      act: "DS1-5-1",
      // mandatory: "true",
      depending: "DS1",
      exception: "Other Opiates/Analgesics",
      link: "DS1-5-1-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-5-CODE",
      subtopic_name:
        "Other Opiates/Analgesics",
    },
    {
      id: "D5",
      extend: "true",
      shortname: "ds1-5-1-1",
      name: "",
      prevalue: "How many days in the past 30 you use Other Opiates/Analgesics?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-5-1-1",
      depending: "DS1-5-1",
      exception: "A. Past 30 Days",
      link: "DS1-5-2",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-5-CODE",
      subtopic_name:
        "Other Opiates/Analgesics",
    },
    {
      id: "D5",
      extend: "true",
      shortname: "ds1-5-2",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "B. Lifetime (Years)",
      ],
      act: "DS1-5-2",
      // mandatory: "true",
      depending: "DS1",
      exception: "Other Opiates/Analgesics",
      link: "DS1-5-2-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-5-CODE",
      subtopic_name:
        "Other Opiates/Analgesics",
    },
    {
      id: "D5",
      extend: "true",
      shortname: "ds1-5-2-1",
      name: "",
      prevalue: "How many years in the Lifetime you use Other Opiates/Analgesics?",
      value: "",
      disabled: "true",
      type: "number",
      // type: "select",
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-5-2-1",
      depending: "DS1-5-2",
      exception: "B. Lifetime (Years)",
      link: "DS1-5-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-5-CODE",
      subtopic_name:
        "Other Opiates/Analgesics",
    },
    {
      id: "D5",
      extend: "true",
      shortname: "ds1-5-3",
      name: "",
      prevalue: "",
      value: [
        true,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "C. Route of Admin",
      ],
      act: "DS1-5-3",
      // mandatory: "true",
      depending: "DS1",
      exception: "Other Opiates/Analgesics",
      link: "DS1-5-3-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-5-CODE",
      subtopic_name:
        "Other Opiates/Analgesics",
    },
    {
      id: "D5",
      extend: "true",
      shortname: "ds1-5-3-1",
      name: "",
      prevalue: "What is the usual way you use Other Opiates/Analgesics?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - oral",
        "2 - nasal",
        "3 - smoking",
        "4 - non-IV injection",
        "5 - IV injection",
      ],
      act: "DS1-5-3-1",
      depending: "DS1-5-3",
      exception: "C. Route of Admin",
      // link: "DS1-1-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-5-CODE",
      subtopic_name:
        "Other Opiates/Analgesics",
    },
    // {
    //   id: "D5",
    //   shortname: "ds1-5",
    //   name: "Other Opiates/Analgesics",
    //   prevalue: "",
    //   value: [
    //     false,
    //     false,
    //     false,
    //   ],
    //   disabled: "true",
    //   type: "checkbox",
    //   radio_options: [
    //     "A. Past 30 Days",
    //     "B. Lifetime (Years)",
    //     "C. Route of Admin",
    //   ],
    //   act: "DS1-5",
    //   mandatory: "true",
    //   depending: "DS1",
    //   exception: "Other Opiates/Analgesics",
    //   subtopic_container_act: "DS1-1-13-ACT",
    //   suptopic_code: "DS1-5-CODE",
    //   subtopic_name:
    //     "Other Opiates/Analgesics",
    // },
//========================================================================================
/*                                                                                      *
 *                                    D6 Barbiturates                                     *
 *                                                                                      */
//========================================================================================

    {
      id: "D6",
      shortname: "ds1-6-1",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "A. Past 30 Days",
      ],
      act: "DS1-6-1",
      // mandatory: "true",
      depending: "DS1",
      exception: "Barbiturates",
      link: "DS1-6-1-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-6-CODE",
      subtopic_name:
        "Barbiturates",
    },
    {
      id: "D6",
      extend: "true",
      shortname: "ds1-6-1-1",
      name: "",
      prevalue: "How many days in the past 30 you use Barbiturates?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-6-1-1",
      depending: "DS1-6-1",
      exception: "A. Past 30 Days",
      link: "DS1-6-2",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-6-CODE",
      subtopic_name:
        "Barbiturates",
    },
    {
      id: "D6",
      extend: "true",
      shortname: "ds1-6-2",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "B. Lifetime (Years)",
      ],
      act: "DS1-6-2",
      // mandatory: "true",
      depending: "DS1",
      exception: "Barbiturates",
      link: "DS1-6-2-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-6-CODE",
      subtopic_name:
        "Barbiturates",
    },
    {
      id: "D6",
      extend: "true",
      shortname: "ds1-6-2-1",
      name: "",
      prevalue: "How many years in the Lifetime you use Barbiturates?",
      value: "",
      disabled: "true",
      type: "number",
      // type: "select",
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-6-2-1",
      depending: "DS1-6-2",
      exception: "B. Lifetime (Years)",
      link: "DS1-6-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-6-CODE",
      subtopic_name:
        "Barbiturates",
    },
    {
      id: "D6",
      extend: "true",
      shortname: "ds1-6-3",
      name: "",
      prevalue: "",
      value: [
        true,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "C. Route of Admin",
      ],
      act: "DS1-6-3",
      // mandatory: "true",
      depending: "DS1",
      exception: "Barbiturates",
      link: "DS1-6-3-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-6-CODE",
      subtopic_name:
        "Barbiturates",
    },
    {
      id: "D6",
      extend: "true",
      shortname: "ds1-6-3-1",
      name: "",
      prevalue: "What is the usual way you use Barbiturates?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - oral",
        "2 - nasal",
        "3 - smoking",
        "4 - non-IV injection",
        "5 - IV injection",
      ],
      act: "DS1-6-3-1",
      depending: "DS1-6-3",
      exception: "C. Route of Admin",
      // link: "DS1-1-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-6-CODE",
      subtopic_name:
        "Barbiturates",
    },
    // {
    //   id: "D6",
    //   shortname: "ds1-6",
    //   name: "Barbiturates",
    //   prevalue: "",
    //   value: [
    //     false,
    //     false,
    //     false,
    //   ],
    //   disabled: "true",
    //   type: "checkbox",
    //   radio_options: [
    //     "A. Past 30 Days",
    //     "B. Lifetime (Years)",
    //     "C. Route of Admin",
    //   ],
    //   act: "DS1-6",
    //   mandatory: "true",
    //   depending: "DS1",
    //   exception: "Barbiturates",
    //   subtopic_container_act: "DS1-1-13-ACT",
    //   suptopic_code: "DS1-6-CODE",
    //   subtopic_name:
    //     "Barbiturates",
    // },
//========================================================================================
/*                                                                                      *
 *                      D7 Other Sedatives/Hypnotics/ Tranquilizers                       *
 *                                                                                      */
//========================================================================================

    {
      id: "D7",
      shortname: "ds1-7-1",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "A. Past 30 Days",
      ],
      act: "DS1-7-1",
      // mandatory: "true",
      depending: "DS1",
      exception: "Other Sedatives/Hypnotics/ Tranquilizers",
      link: "DS1-7-1-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-7-CODE",
      subtopic_name:
        "Other Sedatives/Hypnotics/ Tranquilizers",
    },
    {
      id: "D7",
      extend: "true",
      shortname: "ds1-7-1-1",
      name: "",
      prevalue: "How many days in the past 30 you use Other Sedatives/Hypnotics/ Tranquilizers?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-7-1-1",
      depending: "DS1-7-1",
      exception: "A. Past 30 Days",
      link: "DS1-7-2",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-7-CODE",
      subtopic_name:
        "Other Sedatives/Hypnotics/ Tranquilizers",
    },
    {
      id: "D7",
      extend: "true",
      shortname: "ds1-7-2",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "B. Lifetime (Years)",
      ],
      act: "DS1-7-2",
      // mandatory: "true",
      depending: "DS1",
      exception: "Other Sedatives/Hypnotics/ Tranquilizers",
      link: "DS1-7-2-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-7-CODE",
      subtopic_name:
        "Other Sedatives/Hypnotics/ Tranquilizers",
    },
    {
      id: "D7",
      extend: "true",
      shortname: "ds1-7-2-1",
      name: "",
      prevalue: "How many years in the Lifetime you use Other Sedatives/Hypnotics/ Tranquilizers?",
      value: "",
      disabled: "true",
      type: "number",
      // type: "select",
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-7-2-1",
      depending: "DS1-7-2",
      exception: "B. Lifetime (Years)",
      link: "DS1-7-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-7-CODE",
      subtopic_name:
        "Other Sedatives/Hypnotics/ Tranquilizers",
    },
    {
      id: "D7",
      extend: "true",
      shortname: "ds1-7-3",
      name: "",
      prevalue: "",
      value: [
        true,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "C. Route of Admin",
      ],
      act: "DS1-7-3",
      // mandatory: "true",
      depending: "DS1",
      exception: "Other Sedatives/Hypnotics/ Tranquilizers",
      link: "DS1-7-3-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-7-CODE",
      subtopic_name:
        "Other Sedatives/Hypnotics/ Tranquilizers",
    },
    {
      id: "D7",
      extend: "true",
      shortname: "ds1-7-3-1",
      name: "",
      prevalue: "What is the usual way you use Other Sedatives/Hypnotics/ Tranquilizers?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - oral",
        "2 - nasal",
        "3 - smoking",
        "4 - non-IV injection",
        "5 - IV injection",
      ],
      act: "DS1-7-3-1",
      depending: "DS1-7-3",
      exception: "C. Route of Admin",
      // link: "DS1-1-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-7-CODE",
      subtopic_name:
        "Other Sedatives/Hypnotics/ Tranquilizers",
    },
    {
      id: "D7",
      shortname: "ds1-7",
      name: "Other Sedatives/Hypnotics/ Tranquilizers",
      prevalue: "",
      value: [
        false,
        false,
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "A. Past 30 Days",
        "B. Lifetime (Years)",
        "C. Route of Admin",
      ],
      act: "DS1-7",
      mandatory: "true",
      depending: "DS1",
      exception: "Other Sedatives/Hypnotics/ Tranquilizers",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-7-CODE",
      subtopic_name:
        "Other Sedatives/Hypnotics/ Tranquilizers",
    },
//========================================================================================
/*                                                                                      *
 *                                      D8 Cocaine                                       *
 *                                                                                      */
//========================================================================================

    {
      id: "D8",
      shortname: "ds1-8-1",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "A. Past 30 Days",
      ],
      act: "DS1-8-1",
      // mandatory: "true",
      depending: "DS1",
      exception: "Cocaine",
      link: "DS1-8-1-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-8-CODE",
      subtopic_name:
        "Cocaine",

    },
    {
      id: "D8",
      extend: "true",
      shortname: "ds1-8-1-1",
      name: "",
      prevalue: "How many days in the past 30 you use Cocaine?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-8-1-1",
      depending: "DS1-8-1",
      exception: "A. Past 30 Days",
      link: "DS1-8-2",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-8-CODE",
      subtopic_name:
        "Cocaine",
    },
    {
      id: "D8",
      extend: "true",
      shortname: "ds1-8-2",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "B. Lifetime (Years)",
      ],
      act: "DS1-8-2",
      // mandatory: "true",
      depending: "DS1",
      exception: "Cocaine",
      link: "DS1-8-2-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-8-CODE",
      subtopic_name:
        "Cocaine",
    },
    {
      id: "D8",
      extend: "true",
      shortname: "ds1-8-2-1",
      name: "",
      prevalue: "How many years in the Lifetime you use Cocaine?",
      value: "",
      disabled: "true",
      type: "number",
      // type: "select",
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-8-2-1",
      depending: "DS1-8-2",
      exception: "B. Lifetime (Years)",
      link: "DS1-8-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-8-CODE",
      subtopic_name:
        "Cocaine",
    },
    {
      id: "D8",
      extend: "true",
      shortname: "ds1-8-3",
      name: "",
      prevalue: "",
      value: [
        true,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "C. Route of Admin",
      ],
      act: "DS1-8-3",
      // mandatory: "true",
      depending: "DS1",
      exception: "Cocaine",
      link: "DS1-8-3-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-8-CODE",
      subtopic_name:
        "Cocaine",
    },
    {
      id: "D8",
      extend: "true",
      shortname: "ds1-8-3-1",
      name: "",
      prevalue: "What is the usual way you use Cocaine?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - oral",
        "2 - nasal",
        "3 - smoking",
        "4 - non-IV injection",
        "5 - IV injection",
      ],
      act: "DS1-8-3-1",
      depending: "DS1-8-3",
      exception: "C. Route of Admin",
      // link: "DS1-1-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-8-CODE",
      subtopic_name:
        "Cocaine",
    },
    // {
    //   id: "D8",
    //   shortname: "ds1-8",
    //   name: "Cocaine",
    //   prevalue: "",
    //   value: [
    //     false,
    //     false,
    //     false,
    //   ],
    //   disabled: "true",
    //   type: "checkbox",
    //   radio_options: [
    //     "A. Past 30 Days",
    //     "B. Lifetime (Years)",
    //     "C. Route of Admin",
    //   ],
    //   act: "DS1-8",
    //   mandatory: "true",
    //   depending: "DS1",
    //   exception: "Cocaine",
    //   subtopic_container_act: "DS1-1-13-ACT",
    //   suptopic_code: "DS1-8-CODE",
    //   subtopic_name:
    //     "Cocaines",
    // },
//========================================================================================
/*                                                                                      *
 *                                   D9 Amphetamines                                     *
 *                                                                                      */
//========================================================================================

    {
      id: "D9",
      shortname: "ds1-9-1",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "A. Past 30 Days",
      ],
      act: "DS1-9-1",
      // mandatory: "true",
      depending: "DS1",
      exception: "Amphetamines",
      link: "DS1-9-1-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-9-CODE",
      subtopic_name:
        "Amphetamines",

    },
    {
      id: "D9",
      extend: "true",
      shortname: "ds1-9-1-1",
      name: "",
      prevalue: "How many days in the past 30 you use Amphetamines?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-9-1-1",
      depending: "DS1-9-1",
      exception: "A. Past 30 Days",
      link: "DS1-9-2",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-9-CODE",
      subtopic_name:
        "Amphetamines",
    },
    {
      id: "D9",
      extend: "true",
      shortname: "ds1-9-2",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "B. Lifetime (Years)",
      ],
      act: "DS1-9-2",
      // mandatory: "true",
      depending: "DS1",
      exception: "Amphetamines",
      link: "DS1-9-2-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-9-CODE",
      subtopic_name:
        "Amphetamines",

    },
    {
      id: "D9",
      extend: "true",
      shortname: "ds1-9-2-1",
      name: "",
      prevalue: "How many years in the Lifetime you use Amphetamines?",
      value: "",
      disabled: "true",
      type: "number",
      // type: "select",
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-9-2-1",
      depending: "DS1-9-2",
      exception: "B. Lifetime (Years)",
      link: "DS1-9-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-9-CODE",
      subtopic_name:
        "Amphetamines",
    },
    {
      id: "D9",
      extend: "true",
      shortname: "ds1-9-3",
      name: "",
      prevalue: "",
      value: [
        true,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "C. Route of Admin",
      ],
      act: "DS1-9-3",
      // mandatory: "true",
      depending: "DS1",
      exception: "Amphetamines",
      link: "DS1-9-3-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-9-CODE",
      subtopic_name:
        "Amphetamines",
    },
    {
      id: "D9",
      extend: "true",
      shortname: "ds1-9-3-1",
      name: "",
      prevalue: "What is the usual way you use Amphetamines?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - oral",
        "2 - nasal",
        "3 - smoking",
        "4 - non-IV injection",
        "5 - IV injection",
      ],
      act: "DS1-9-3-1",
      depending: "DS1-9-3",
      exception: "C. Route of Admin",
      // link: "DS1-1-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-9-CODE",
      subtopic_name:
        "Amphetamines",
    },
    // {
    //   id: "D9",
    //   shortname: "ds1-9-1",
    //   name: "",
    //   prevalue: "",
    //   value: [
    //     false,
    //   ],
    //   disabled: "true",
    //   type: "checkbox",
    //   radio_options: [
    //     "A. Past 30 Days",
    //   ],
    //   act: "DS1-9-1",
    //   mandatory: "true",
    //   depending: "DS1",
    //   exception: "Amphetamines",
    //   link: "DS1-9-1-1",
    //   subtopic_container_act: "DS1-1-13-ACT",
    //   suptopic_code: "DS1-9-CODE",
    //   subtopic_name:
    //     "Amphetamines",
    // },
//========================================================================================
/*                                                                                      *
 *                                       Cannabis                                       *
 *                                                                                      */
//========================================================================================

    {
      id: "D10",
      shortname: "ds1-10-1",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "A. Past 30 Days",
      ],
      act: "DS1-10-1",
      // mandatory: "true",
      depending: "DS1",
      exception: "Cannabis",
      link: "DS1-10-1-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-10-CODE",
      subtopic_name:
        "Cannabis",
    },
    {
      id: "D10",
      extend: "true",
      shortname: "ds1-10-1-1",
      name: "",
      prevalue: "How many days in the past 30 you use Cannabis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-10-1-1",
      depending: "DS1-10-1",
      exception: "A. Past 30 Days",
      link: "DS1-10-2",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-10-CODE",
      subtopic_name:
        "Cannabis",
    },
    {
      id: "D10",
      extend: "true",
      shortname: "ds1-10-2",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "B. Lifetime (Years)",
      ],
      act: "DS1-10-2",
      // mandatory: "true",
      depending: "DS1",
      exception: "Cannabis",
      link: "DS1-10-2-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-10-CODE",
      subtopic_name:
        "Cannabis",
    },
    {
      id: "D10",
      extend: "true",
      shortname: "ds1-10-2-1",
      name: "",
      prevalue: "How many years in the Lifetime you use Cannabis?",
      value: "",
      disabled: "true",
      type: "number",
      // type: "select",
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-10-2-1",
      depending: "DS1-10-2",
      exception: "B. Lifetime (Years)",
      link: "DS1-10-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-10-CODE",
      subtopic_name:
        "Cannabis",
    },
    {
      id: "D10",
      extend: "true",
      shortname: "ds1-10-3",
      name: "",
      prevalue: "",
      value: [
        true,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "C. Route of Admin",
      ],
      act: "DS1-10-3",
      // mandatory: "true",
      depending: "DS1",
      exception: "Cannabis",
      link: "DS1-10-3-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-10-CODE",
      subtopic_name:
        "Cannabis",
    },
    {
      id: "D10",
      extend: "true",
      shortname: "ds1-10-3-1",
      name: "",
      prevalue: "What is the usual way you use Cannabis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - oral",
        "2 - nasal",
        "3 - smoking",
        "4 - non-IV injection",
        "5 - IV injection",
      ],
      act: "DS1-10-3-1",
      depending: "DS1-10-3",
      exception: "C. Route of Admin",
      // link: "DS1-1-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-10-CODE",
      subtopic_name:
        "Cannabis",
    },
    // {
    //   id: "D10",
    //   shortname: "ds1-9",
    //   name: "Cannabis",
    //   prevalue: "",
    //   value: [
    //     false,
    //     false,
    //     false,
    //   ],
    //   disabled: "true",
    //   type: "checkbox",
    //   radio_options: [
    //     "A. Past 30 Days",
    //     "B. Lifetime (Years)",
    //     "C. Route of Admin",
    //   ],
    //   act: "DS1-9",
    //   mandatory: "true",
    //   depending: "DS1",
    //   exception: "Cannabis",
    //   subtopic_container_act: "DS1-1-13-ACT",
    //   suptopic_code: "DS1-9-CODE",
    //   subtopic_name:
    //     "Cannabis",
    // },
    //========================================================================================
    /*                                                                                      *
    *                                      D11 Hallucinogens                                       *
    *                                                                                      */
    //========================================================================================

    {
      id: "D11",
      shortname: "ds1-11-1",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "A. Past 30 Days",
      ],
      act: "DS1-11-1",
      // mandatory: "true",
      depending: "DS1",
      exception: "Hallucinogens",
      link: "DS1-11-1-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-11-CODE",
      subtopic_name:
        "Hallucinogens",
    },
    {
      id: "D11",
      extend: "true",
      shortname: "ds1-11-1-1",
      name: "",
      prevalue: "How many days in the past 30 you use Hallucinogens?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-11-1-1",
      depending: "DS1-11-1",
      exception: "A. Past 30 Days",
      link: "DS1-11-2",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-11-CODE",
      subtopic_name:
        "Hallucinogens",
    },
    {
      id: "D11",
      extend: "true",
      shortname: "ds1-11-2",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "B. Lifetime (Years)",
      ],
      act: "DS1-11-2",
      // mandatory: "true",
      depending: "DS1",
      exception: "Hallucinogens",
      link: "DS1-11-2-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-11-CODE",
      subtopic_name:
        "Hallucinogens",
    },
    {
      id: "D11",
      extend: "true",
      shortname: "ds1-11-2-1",
      name: "",
      prevalue: "How many years in the Lifetime you use Hallucinogens?",
      value: "",
      disabled: "true",
      type: "number",
      // type: "select",
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-11-2-1",
      depending: "DS1-11-2",
      exception: "B. Lifetime (Years)",
      link: "DS1-11-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-11-CODE",
      subtopic_name:
        "Hallucinogens",
    },
    {
      id: "D11",
      extend: "true",
      shortname: "ds1-11-3",
      name: "",
      prevalue: "",
      value: [
        true,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "C. Route of Admin",
      ],
      act: "DS1-11-3",
      // mandatory: "true",
      depending: "DS1",
      exception: "Hallucinogens",
      link: "DS1-11-3-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-11-CODE",
      subtopic_name:
        "Hallucinogens",
    },
    {
      id: "D11",
      extend: "true",
      shortname: "ds1-11-3-1",
      name: "",
      prevalue: "What is the usual way you use Hallucinogens?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - oral",
        "2 - nasal",
        "3 - smoking",
        "4 - non-IV injection",
        "5 - IV injection",
      ],
      act: "DS1-11-3-1",
      depending: "DS1-11-3",
      exception: "C. Route of Admin",
      // link: "DS1-1-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-11-CODE",
      subtopic_name:
        "Hallucinogens",
    },
    // {
    //   id: "D11",
    //   shortname: "ds1-11",
    //   name: "Hallucinogens",
    //   prevalue: "",
    //   value: [
    //     false,
    //     false,
    //     false,
    //   ],
    //   disabled: "true",
    //   type: "checkbox",
    //   radio_options: [
    //     "A. Past 30 Days",
    //     "B. Lifetime (Years)",
    //     "C. Route of Admin",
    //   ],
    //   act: "DS1-11",
    //   mandatory: "true",
    //   depending: "DS1",
    //   exception: "Hallucinogens",
    //   subtopic_container_act: "DS1-1-13-ACT",
    //   suptopic_code: "DS1-11-CODE",
    //   subtopic_name:
    //     "Hallucinogens",
    // },
    //========================================================================================
    /*                                                                                      *
    *                                      D12 Inhalants                                       *
    *                                                                                      */
    //========================================================================================
    {
      id: "D12",
      shortname: "ds1-12-1",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "A. Past 30 Days",
      ],
      act: "DS1-12-1",
      // mandatory: "true",
      depending: "DS1",
      exception: "Inhalants",
      link: "DS1-12-1-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-12-CODE",
      subtopic_name:
        "Inhalants",
    },
    {
      id: "D12",
      extend: "true",
      shortname: "ds1-12-1-1",
      name: "",
      prevalue: "How many days in the past 30 you use Inhalants?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-12-1-1",
      depending: "DS1-12-1",
      exception: "A. Past 30 Days",
      link: "DS1-12-2",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-12-CODE",
      subtopic_name:
        "Inhalants",
    },
    {
      id: "D12",
      extend: "true",
      shortname: "ds1-12-2",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "B. Lifetime (Years)",
      ],
      act: "DS1-12-2",
      // mandatory: "true",
      depending: "DS1",
      exception: "Inhalants",
      link: "DS1-12-2-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-12-CODE",
      subtopic_name:
        "Inhalants",
    },
    {
      id: "D12",
      extend: "true",
      shortname: "ds1-12-2-1",
      name: "",
      prevalue: "How many years in the Lifetime you use Inhalants?",
      value: "",
      disabled: "true",
      type: "number",
      // type: "select",
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-12-2-1",
      depending: "DS1-12-2",
      exception: "B. Lifetime (Years)",
      link: "DS1-12-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-12-CODE",
      subtopic_name:
        "Inhalants",
    },
    {
      id: "D12",
      extend: "true",
      shortname: "ds1-12-3",
      name: "",
      prevalue: "",
      value: [
        true,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "C. Route of Admin",
      ],
      act: "DS1-12-3",
      // mandatory: "true",
      depending: "DS1",
      exception: "Inhalants",
      link: "DS1-12-3-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-12-CODE",
      subtopic_name:
        "Inhalants",
    },
    {
      id: "D12",
      extend: "true",
      shortname: "ds1-12-3-1",
      name: "",
      prevalue: "What is the usual way you use Inhalants?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - oral",
        "2 - nasal",
        "3 - smoking",
        "4 - non-IV injection",
        "5 - IV injection",
      ],
      act: "DS1-12-3-1",
      depending: "DS1-12-3",
      exception: "C. Route of Admin",
      // link: "DS1-1-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-12-CODE",
      subtopic_name:
        "Inhalants",
    },
    // {
    //   id: "D12",
    //   shortname: "ds1-12",
    //   name: "Inhalants",
    //   prevalue: "",
    //   value: [
    //     false,
    //     false,
    //     false,
    //   ],
    //   disabled: "true",
    //   type: "checkbox",
    //   radio_options: [
    //     "A. Past 30 Days",
    //     "B. Lifetime (Years)",
    //     "C. Route of Admin",
    //   ],
    //   act: "DS1-12",
    //   mandatory: "true",
    //   depending: "DS1",
    //   exception: "Inhalants",
    //   subtopic_container_act: "DS1-1-13-ACT",
    //   suptopic_code: "DS1-12-CODE",
    //   subtopic_name:
    //     "Inhalants",
    // },
    //========================================================================================
    /*                                                                                      *
    *                                      D13 More than one substance per day                                       *
    *                                                                                      */
    //========================================================================================
    {
      id: "D13",
      shortname: "ds1-13-1",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "A. Past 30 Days",
      ],
      act: "DS1-13-1",
      // mandatory: "true",
      depending: "DS1",
      exception: "More than one substance per day",
      link: "DS1-13-1-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-13-CODE",
      subtopic_name:
        "More than one substance per day",
    },
    {
      id: "D13",
      extend: "true",
      shortname: "ds1-13-1-1",
      name: "",
      prevalue: "How many days in the past 30 you use More than one substance per day?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-13-1-1",
      depending: "DS1-13-1",
      exception: "A. Past 30 Days",
      link: "DS1-13-2",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-13-CODE",
      subtopic_name:
        "More than one substance per day",
    },
    {
      id: "D13",
      extend: "true",
      shortname: "ds1-13-2",
      name: "",
      prevalue: "",
      value: [
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "B. Lifetime (Years)",
      ],
      act: "DS1-13-2",
      // mandatory: "true",
      depending: "DS1",
      exception: "More than one substance per day",
      link: "DS1-13-2-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-13-CODE",
      subtopic_name:
        "More than one substance per day",
    },
    {
      id: "D13",
      extend: "true",
      shortname: "ds1-13-2-1",
      name: "",
      prevalue: "How many years in the Lifetime you use More than one substance per day?",
      value: "",
      disabled: "true",
      type: "number",
      // type: "select",
      // ruletype: "string",
      // select_options: [
      //   "",
      //   "1 - oral",
      //   "2 - nasal",
      //   "3 - smoking",
      //   "4 - non-IV injection",
      //   "5 - IV injection",
      // ],
      act: "DS1-13-2-1",
      depending: "DS1-13-2",
      exception: "B. Lifetime (Years)",
      link: "DS1-13-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-13-CODE",
      subtopic_name:
        "More than one substance per day",
    },
    {
      id: "D13",
      extend: "true",
      shortname: "ds1-13-3",
      name: "",
      prevalue: "",
      value: [
        true,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "C. Route of Admin",
      ],
      act: "DS1-13-3",
      // mandatory: "true",
      depending: "DS1",
      exception: "More than one substance per day",
      link: "DS1-13-3-1",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-13-CODE",
      subtopic_name:
        "More than one substance per day",
    },
    {
      id: "D13",
      extend: "true",
      shortname: "ds1-13-3-1",
      name: "",
      prevalue: "What is the usual way you use More than one substance per day?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - oral",
        "2 - nasal",
        "3 - smoking",
        "4 - non-IV injection",
        "5 - IV injection",
      ],
      act: "DS1-13-3-1",
      depending: "DS1-13-3",
      exception: "C. Route of Admin",
      // link: "DS1-1-3",
      subtopic_container_act: "DS1-1-13-ACT",
      suptopic_code: "DS1-13-CODE",
      subtopic_name:
        "More than one substance per day",
    },
    // {
    //   id: "D13",
    //   shortname: "ds1-13",
    //   name: "More than one substance per day",
    //   prevalue: "",
    //   value: [
    //     false,
    //     false,
    //     false,
    //   ],
    //   disabled: "true",
    //   type: "checkbox",
    //   radio_options: [
    //     "A. Past 30 Days",
    //     "B. Lifetime (Years)",
    //     "C. Route of Admin",
    //   ],
    //   act: "DS1-13",
    //   mandatory: "true",
    //   depending: "DS1",
    //   exception: "More than one substance per day",
    //   subtopic_container_act: "DS1-1-13-ACT",
    //   suptopic_code: "DS1-13-CODE",
    //   subtopic_name:
    //     "More than one substance per day",
    // },
    {
      id: "D14",
      onlyasi: "",  //D14
      shortname: "ds14",
      name: "According to the interviewer, which substance is/are the major problem?",
      prevalue: "Interviewer should determine the major drug or drugs of abuse. Code the number next to the drug in questions D1-D12, or 00 = no problem 15 = alcohol & one or more drugs 16 = more than one drug but no alcohol. Ask patient when not clear.",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "1 - Alcohol (any use at all)",
        "2 - Alcohol (to intoxication)",
        "3 - Heroin",
        "4 - Methadone",
        "5 - Other Opiates/Analgesics",
        "6 - Barbiturates",
        "7 - Other Sedatives/Hypnotics/ Tranquilizers",
        "8 - Cocaine",
        "9 - Amphetamines",
        "10 - Cannabis",
        "11 - Hallucinogens",
        "12 - Inhalants",
        "00 - no problem",
        "15 - alcohol & one or more drug",
        "16 - more than one drug but no alcohol.",
      ],
      act: "DS14",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "D15",
      onlyasi: "",  //D15
      shortname: "ds15",
      name: "How long was your last period of voluntary abstinence from this major substance?",
      prevalue: "Last attempt of at least one month, not necessarily the longest. Periods of hospitalization/incarceration do not count. Periods of antabuse, methadone, or naltrexone use during abstinence do count. 00 = never abstinent",
      value: "",
      disabled: "false",
      type: "number",
      act: "DS15",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "D16",
      onlyasi: "",  //D16
      shortname: "ds16",
      name: "How many months ago did this abstinence end?",
      prevalue: "If D15 = 0, then D16 = NN, 00 = Still abstinent",
      value: "",
      disabled: "true",
      type: "number",
      act: "DS16",
      mandatory: "true",
      depending: "DS15",
      exception: "0",
    },
    {
      id: "D17",
      shortname: "ds17",
      name: "How many times have you had Alcohol D.T.’s?",
      prevalue: "Delirium Tremems (DTs): Occur 24-48 hours after last drink, or significant decrease in alcohol intake, shaking, severe disorientation, fever, hallucinations, they usually require medical attention",
      value: "",
      disabled: "false",
      type: "number",
      act: "DS17",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "D18",
      onlyasi: "",  //D18
      shortname: "ds18",
      name: "How many times have you overdosed on drugs?",
      prevalue: "Overdoses (OD): Require requires intervention by someone. to recover, not simply sleeping it off, include suicide attempts by OD.",
      value: "",
      disabled: "false",
      type: "number",
      act: "DS18",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "",
      shortname: "ds19-ds22-subtopic",
      name: "How many times in your life have you been treated for:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "DS19-DS22-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "D19",
      shortname: "ds19",
      name: "",
      prevalue: "Alcohol abuse? Include detoxification, halfway houses, in/outpatient counseling, and AA or NA (if 3+ meetings within one month period)",
      value: "",
      disabled: "false",
      type: "number",
      act: "DS19",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "DS19-DS22-ACT",
      suptopic_code: "DS19-CODE",
      subtopic_name:
        "Alcohol",
    },
    {
      id: "D21",
      shortname: "ds21",
      name: "",
      prevalue: "How many of these were detox only: Alcohol? ",
      value: "",
      disabled: "true",
      type: "number",
      act: "DS21",
      mandatory: "true",
      depending: "DS19",
      exception: "0",
      subtopic_container_act: "DS19-DS22-ACT",
      suptopic_code: "DS19-CODE",
      subtopic_name:
        "Alcohol",
    },
    {
      id: "D20",
      shortname: "ds20",
      name: "",
      prevalue: "Drug abuse? Include detoxification, halfway houses, in/outpatient counseling, and AA or NA (if 3+ meetings within one month period)",
      value: "",
      disabled: "false",
      type: "number",
      act: "DS20",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "DS19-DS22-ACT",
      suptopic_code: "DS20-CODE",
      subtopic_name:
        "Drug",
    },
    {
      id: "D22",
      shortname: "ds22",
      name: "",
      prevalue: "How many of these were detox only: Drugs?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DS22",
      mandatory: "true",
      depending: "DS20",
      exception: "0",
      subtopic_container_act: "DS19-DS22-ACT",
      suptopic_code: "DS20-CODE",
      subtopic_name:
        "Drug",
    },
    // {
    //   id: "",
    //   shortname: "ds23-ds24-subtopic",
    //   name: "How much money would you say you spent during the past 30 days on:...Only count actual money spent. What is the financial burden caused by drugs/alcohol?",
    //   prevalue: "",
    //   value: "",
    //   disabled: "false",
    //   type: "subtopic",
    //   act: "DS23-DS24-ACT",
    //   depending: "",
    //   exception: "",
    // },
    {
      id: "D23",
      shortname: "ds23",
      name: "How much money would you say you spent on Alcohol during the past 30 days:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "money",
      act: "DS23",
      mandatory: "true",
      depending: "",
      exception: "",
      // subtopic_container_act: "DS23-DS24-ACT",
      // suptopic_code: "DS23-CODE",
      // subtopic_name:
      //   "Alcohol?",
    },
    {
      id: "D24",
      shortname: "ds24",
      name: "How much money would you say you spent on Drugs during the past 30 days:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "money",
      act: "DS24",
      mandatory: "true",
      depending: "",
      exception: "",
      // subtopic_container_act: "DS23-DS24-ACT",
      // suptopic_code: "DS24-CODE",
      // subtopic_name:
      //   "Drugs?",
    },
    {
      id: "D25",
      shortname: "ds25",
      name: "How many days have you been treated in an outpatient setting for alcohol or drugs in the past 30 days?",
      prevalue: "Include AA/NA",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DS25",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "",
      shortname: "ds26-ds27-subtopic",
      name: "How many days in the past 30 have you experienced...Include craving, withdrawal symptoms, disturbing effects of use, or wanting to stop and being unable to.",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "DS26-DS27-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "D26",
      shortname: "ds26",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DS26",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "DS26-DS27-ACT",
      suptopic_code: "DS26-CODE",
      subtopic_name:
        "Alcohol problems?",
    },
    {
      id: "D27",
      shortname: "ds27",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DS27",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "DS26-DS27-ACT",
      suptopic_code: "DS27-CODE",
      subtopic_name:
        "Drug problems?",
    },
    {
      id: "",
      shortname: "ds28-ds31-subtopic",
      name: "For Questions D28 - D31, ask patient to use the Patient Rating Scale. How troubled or bothered have you been in the past 30 days by these:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "DS28-DS31-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "D28",
      shortname: "ds28",
      name: "",
      prevalue: "Alcohol problems",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0 - not at all",
        "1 - slightly",
        "2 - moderately",
        "3 - considerably",
        "4 - extremely",
      ],
      act: "DS28",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "DS28-DS31-ACT",
      suptopic_code: "DS28-DS29-CODE",
      subtopic_name:
        "How troubled or bothered have you been in the past 30 days by these:",
    },
    {
      id: "D29",
      shortname: "ds29",
      name: "",
      prevalue: "Drug problems",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0 - not at all",
        "1 - slightly",
        "2 - moderately",
        "3 - considerably",
        "4 - extremely",
      ],
      act: "DS29",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "DS28-DS31-ACT",
      suptopic_code: "DS28-DS29-CODE",
      subtopic_name:
        "How troubled or bothered have you been in the past 30 days by these:",
    },
    {
      id: "D30",
      shortname: "ds30",
      name: "",
      prevalue: "Alcohol problems",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0 - not at all",
        "1 - slightly",
        "2 - moderately",
        "3 - considerably",
        "4 - extremely",
      ],
      act: "DS30",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "DS28-DS31-ACT",
      suptopic_code: "DS30-DS31-CODE",
      subtopic_name:
        "How important to you now is treatment for these:",
    },
    {
      id: "D31",
      shortname: "ds31",
      name: "",
      prevalue: "Drug problems",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0 - not at all",
        "1 - slightly",
        "2 - moderately",
        "3 - considerably",
        "4 - extremely",
      ],
      act: "DS31",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "DS28-DS31-ACT",
      suptopic_code: "DS30-DS31-CODE",
      subtopic_name:
        "How important to you now is treatment for these:",
    },
    {
      id: "",
      onlyasi: "",  //subs D32-D33
      shortname: "ds32-ds33-subtopic",
      access: "private",
      name: "INTERVIEWER SEVERITY RATING.",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "DS32-DS33-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "D32",
      onlyasi: "", //D32
      shortname: "ds32",
      name: "",
      prevalue: "Alcohol problems",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
      ],
      act: "DS32",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "DS32-DS33-ACT",
      suptopic_code: "DS32-CODE",
      subtopic_name:
        "How would you rate the patient’s need for treatment for:",
    },
    {
      id: "D33",
      onlyasi: "",  //D33
      shortname: "ds33",
      name: "",
      prevalue: "Drug problems",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
      ],
      act: "DS33",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "DS32-DS33-ACT",
      suptopic_code: "DS33-CODE",
      subtopic_name:
        "How would you rate the patient’s need for treatment for: ",
    },
    {
      id: "",
      shortname: "ds34-ds35-subtopic",
      access: "private",
      name: "CONFIDENCE RATINGS. Is the above information significantly distorted by:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "DS34-DS35-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "D34",
      shortname: "ds34",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "DS34",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "DS34-DS35-ACT",
      suptopic_code: "DS34-CODE",
      subtopic_name:
        "Client’s misrepresentation?",
    },
    {
      id: "D35",
      shortname: "ds35",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "DS35",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "DS34-DS35-ACT",
      suptopic_code: "DS35-CODE",
      subtopic_name:
        "Client’s inability to understand?",
    },
    {
      id: "D-COMMENT",
      shortname: "ds-comment",
      access: "private",
      name: "ALCOHOL/DRUGS COMMENTS:",
      prevalue: "(Include the question number with your notes)",
      value: "",
      disabled: "false",
      type: "editor",
      act: "DS-COMMENT",
      depending: "",
      exception: "",
    },
  ],
}

export default alcoholHistoryJSON;
