const blockEcstasy = [
  //!========================================================================================
  /*                                                                                      *
   *                                   STARTBLOCKECSTASY                                  *
   *                                                                                      */
  //!========================================================================================
  {
    id: "DA.1.1.139",
    shortname: "ecstasy-block-subtopic",
    name: "Direction for Ecstasy: Please answer the items in the grid below. Have there EVER been significant periods during which you...",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "subtopic",
    act: "ECSTASY-BLOCK-SUBTOPIC",
    depending: "DA0",
    exception: "Ecstasy",
    condition: "in",
  },
  {
    id: "DA.1.1.140",
    shortname: "ecstasy-block-6",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "ECSTASY-BLOCK-6",
    depending: "DA0",
    exception: "Ecstasy",
    condition: "in",
    link: "ECSTASY-BLOCK-7",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.140.1",
    extend: "true",
    shortname: "ecstasy-block-7",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "ECSTASY-BLOCK-7",
    depending: "ECSTASY-BLOCK-6",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-8",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.140.2",
    extend: "true",
    shortname: "ecstasy-block-8",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-8",
    depending: "ECSTASY-BLOCK-6",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-9",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.140.3",
    extend: "true",
    shortname: "ecstasy-block-9",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-9",
    depending: "ECSTASY-BLOCK-6",
    exception: "This has happened",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.141",
    shortname: "ecstasy-block-10",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "ECSTASY-BLOCK-10",
    depending: "DA0",
    exception: "Ecstasy",
    condition: "in",
    link: "ECSTASY-BLOCK-11",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.141.1",
    extend: "true",
    shortname: "ecstasy-block-11",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "ECSTASY-BLOCK-11",
    depending: "ECSTASY-BLOCK-10",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-12",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.141.2",
    extend: "true",
    shortname: "ecstasy-block-12",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-12",
    depending: "ECSTASY-BLOCK-10",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-13",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.141.3",
    extend: "true",
    shortname: "ecstasy-block-13",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-13",
    depending: "ECSTASY-BLOCK-10",
    exception: "This has happened",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.142",
    shortname: "ecstasy-block-14",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "ECSTASY-BLOCK-14",
    depending: "DA0",
    exception: "Ecstasy",
    condition: "in",
    link: "ECSTASY-BLOCK-15",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.142.1",
    extend: "true",
    shortname: "ecstasy-block-15",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "ECSTASY-BLOCK-15",
    depending: "ECSTASY-BLOCK-14",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-16",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.142.2",
    extend: "true",
    shortname: "ecstasy-block-16",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-16",
    depending: "ECSTASY-BLOCK-14",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-17",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.142.3",
    extend: "true",
    shortname: "ecstasy-block-17",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-17",
    depending: "ECSTASY-BLOCK-14",
    exception: "This has happened",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.143",
    shortname: "ecstasy-block-18",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "ECSTASY-BLOCK-18",
    depending: "DA0",
    exception: "Ecstasy",
    condition: "in",
    link: "ECSTASY-BLOCK-19",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.143.1",
    extend: "true",
    shortname: "ecstasy-block-19",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "ECSTASY-BLOCK-19",
    depending: "ECSTASY-BLOCK-18",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-30",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.143.2",
    extend: "true",
    shortname: "ecstasy-block-20",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-20",
    depending: "ECSTASY-BLOCK-18",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-21",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.143.3",
    extend: "true",
    shortname: "ecstasy-block-21",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-21",
    depending: "ECSTASY-BLOCK-18",
    exception: "This has happened",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.144",
    shortname: "ecstasy-block-22",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "ECSTASY-BLOCK-22",
    depending: "DA0",
    exception: "Ecstasy",
    condition: "in",
    link: "ECSTASY-BLOCK-23",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.144.1",
    extend: "true",
    shortname: "ecstasy-block-23",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "ECSTASY-BLOCK-23",
    depending: "ECSTASY-BLOCK-22",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-24",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.144.2",
    extend: "true",
    shortname: "ecstasy-block-24",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-24",
    depending: "ECSTASY-BLOCK-22",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-25",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.144.3",
    extend: "true",
    shortname: "ecstasy-block-25",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-25",
    depending: "ECSTASY-BLOCK-22",
    exception: "This has happened",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.145",
    shortname: "ecstasy-block-26",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "ECSTASY-BLOCK-26",
    depending: "DA0",
    exception: "Ecstasy",
    condition: "in",
    link: "ECSTASY-BLOCK-27",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.145.1",
    extend: "true",
    shortname: "ecstasy-block-27",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "ECSTASY-BLOCK-27",
    depending: "ECSTASY-BLOCK-26",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-28",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.145.2",
    extend: "true",
    shortname: "ecstasy-block-28",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-28",
    depending: "ECSTASY-BLOCK-26",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-29",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.145.3",
    extend: "true",
    shortname: "ecstasy-block-29",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-29",
    depending: "ECSTASY-BLOCK-26",
    exception: "This has happened",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.146",
    shortname: "ecstasy-block-30",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "ECSTASY-BLOCK-30",
    depending: "DA0",
    exception: "Ecstasy",
    condition: "in",
    link: "ECSTASY-BLOCK-31",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.146.1",
    extend: "true",
    shortname: "ecstasy-block-31",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "ECSTASY-BLOCK-31",
    depending: "ECSTASY-BLOCK-30",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-32",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.146.2",
    extend: "true",
    shortname: "ecstasy-block-32",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-32",
    depending: "ECSTASY-BLOCK-30",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-33",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.146.3",
    extend: "true",
    shortname: "ecstasy-block-33",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-33",
    depending: "ECSTASY-BLOCK-30",
    exception: "This has happened",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.147",
    shortname: "ecstasy-block-34",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "ECSTASY-BLOCK-34",
    depending: "DA0",
    exception: "Ecstasy",
    condition: "in",
    link: "ECSTASY-BLOCK-35",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.147.1",
    extend: "true",
    shortname: "ecstasy-block-35",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "ECSTASY-BLOCK-35",
    depending: "ECSTASY-BLOCK-34",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-36",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.147.2",
    extend: "true",
    shortname: "ecstasy-block-36",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-36",
    depending: "ECSTASY-BLOCK-34",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-37",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.147.3",
    extend: "true",
    shortname: "ecstasy-block-37",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-37",
    depending: "ECSTASY-BLOCK-34",
    exception: "This has happened",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.148",
    shortname: "ecstasy-block-38",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "ECSTASY-BLOCK-38",
    depending: "DA0",
    exception: "Ecstasy",
    condition: "in",
    link: "ECSTASY-BLOCK-39",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.148.1",
    extend: "true",
    shortname: "ecstasy-block-39",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "ECSTASY-BLOCK-39",
    depending: "ECSTASY-BLOCK-38",
    exception: "This has happened",
    link: "ECSTASY-BLOCK0",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.148.2",
    extend: "true",
    shortname: "ecstasy-block-40",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-40",
    depending: "ECSTASY-BLOCK-38",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-41",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.148.3",
    extend: "true",
    shortname: "ecstasy-block-41",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-41",
    depending: "ECSTASY-BLOCK-38",
    exception: "This has happened",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.149",
    shortname: "ecstasy-block-42",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "ECSTASY-BLOCK-42",
    depending: "DA0",
    exception: "Ecstasy",
    condition: "in",
    link: "ECSTASY-BLOCK-43",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.149.1",
    extend: "true",
    shortname: "ecstasy-block-43",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "ECSTASY-BLOCK-43",
    depending: "ECSTASY-BLOCK-42",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-44",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.149.2",
    extend: "true",
    shortname: "ecstasy-block-44",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-44",
    depending: "ECSTASY-BLOCK-42",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-45",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.149.3",
    extend: "true",
    shortname: "ecstasy-block-45",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-45",
    depending: "ECSTASY-BLOCK-42",
    exception: "This has happened",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.150",
    shortname: "ecstasy-block-46",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "ECSTASY-BLOCK-46",
    depending: "DA0",
    exception: "Ecstasy",
    condition: "in",
    link: "ECSTASY-BLOCK-47",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.150.1",
    extend: "true",
    shortname: "ecstasy-block-47",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "ECSTASY-BLOCK-47",
    depending: "ECSTASY-BLOCK-46",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-48",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.150.2",
    extend: "true",
    shortname: "ecstasy-block-48",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-48",
    depending: "ECSTASY-BLOCK-46",
    exception: "This has happened",
    link: "ECSTASY-BLOCK-49",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.150.3",
    extend: "true",
    shortname: "ecstasy-block-49",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "ECSTASY-BLOCK-49",
    depending: "ECSTASY-BLOCK-46",
    exception: "This has happened",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.151",
    shortname: "ecstasy-block-50",
    name: "Number of criteria met for:",
    prevalue: "Number of criteria met for Ecstasy:",
    value: "0",
    disabled: "true",
    type: "content",
    comment: "Consider no diagnosis for Ecstasy",
    act: "ECSTASY-BLOCK-50",
    depending: "DA0",
    exception: "Ecstasy",
    condition: "in",
    meaningfulcount: "This has happened",
    subtopic_container_act: "ECSTASY-BLOCK-SUBTOPIC",
    suptopic_code: "ECSTASY-BLOCK-12-CONTENT",
    subtopic_name: "Content Criteria",
  },
  //!========================================================================================
  /*                                                                                      *
   *                                    ENDBLOCKECSTASY                                   *
   *                                                                                      */
  //!========================================================================================
];

export default blockEcstasy;
