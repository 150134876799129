const behavioralHistoryJSON = {
  section: "PS",
  topic: "Behavioral Health History",
  checked: "true",
  color: "secondary",
  description: "",
  body: [
    {
      id: "BH.1",
      shortname: "ps01-adol1-subtopic",
      name: "All of the following items have been defaulted to No. Please change to Yes, if present, and answer the drop-down questions. ",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "PS01-ADOL-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "BH.2",
      shortname: "ps01-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS01-ADOL1",
      depending: "",
      exception: "",
      link: "PS01-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO1-ADOL-CODE",
      subtopic_name:
        "Have you EVER been treated for psychological, emotional, or behavioral problems in an outpatient setting?",
    },
    {
      id: "BH.2.1",
      extend: "true",
      shortname: "ps01-1-adol1",
      name: "",
      prevalue: "Age of 1st treatment?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS01-1-ADOL1",
      depending: "PS01-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO1-ADOL-CODE",
      subtopic_name:
        "Have you EVER been treated for psychological, emotional, or behavioral problems in an outpatient setting?",
    },
    {
      id: "BH.2.2",
      extend: "true",
      shortname: "ps01-2-adol1",
      name: "",
      prevalue: "Number of episodes",
      value: "",
      disabled: "true",
      type: "number",
      act: "PS01-2-ADOL1",
      depending: "PS01-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO1-ADOL-CODE",
      subtopic_name:
        "Have you EVER been treated for psychological, emotional, or behavioral problems in an outpatient setting?",
    },
    {
      id: "BH.2.3",
      extend: "true",
      shortname: "ps01-3-adol1",
      name: "",
      prevalue: "Treated in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS01-3-ADOL1",
      condition: "in",
      depending: "PS01-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO1-ADOL-CODE",
      subtopic_name:
        "Have you EVER been treated for psychological, emotional, or behavioral problems in an outpatient setting?",
    },
    {
      id: "BH.2.4",
      extend: "true",
      shortname: "ps01-4-adol1",
      name: "",
      prevalue: "Treated in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS01-4-ADOL1",
      condition: "in",
      depending: "PS01-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO1-ADOL-CODE",
      subtopic_name:
        "Have you EVER been treated for psychological, emotional, or behavioral problems in an outpatient setting?",
    },
    {
      id: "BH.2.4.c",
      extend: "true",
      shortname: "ps01-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS01-C-ADOL1",
      depending: "PS01-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO1-ADOL-CODE",
      subtopic_name:
        "Have you EVER been treated for psychological, emotional, or behavioral problems in an outpatient setting?",
    },
    {
      id: "BH.3",
      shortname: "ps02-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS02-ADOL1",
      depending: "",
      exception: "",
      link: "PS02-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO2-ADOL-CODE",
      subtopic_name:
        "Have you EVER been treated for psychological, emotional, or behavioral problems in a partial or day program setting?",
    },
    {
      id: "BH.3.1",
      extend: "true",
      shortname: "ps02-1-adol1",
      name: "",
      prevalue: "Age of 1st treatment?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS02-1-ADOL1",
      depending: "PS02-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO2-ADOL-CODE",
      subtopic_name:
        "Have you EVER been treated for psychological, emotional, or behavioral problems in a partial or day program setting?",
    },
    {
      id: "BH.3.2",
      extend: "true",
      shortname: "ps02-2-adol1",
      name: "",
      prevalue: "Number of episodes",
      value: "",
      disabled: "true",
      type: "number",
      act: "PS02-2-ADOL1",
      depending: "PS02-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO2-ADOL-CODE",
      subtopic_name:
        "Have you EVER been treated for psychological, emotional, or behavioral problems in a partial or day program setting?",
    },
    {
      id: "BH.3.3",
      extend: "true",
      shortname: "ps02-3-adol1",
      name: "",
      prevalue: "Treated in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS02-3-ADOL1",
      condition: "in",
      depending: "PS02-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO2-ADOL-CODE",
      subtopic_name:
        "Have you EVER been treated for psychological, emotional, or behavioral problems in a partial or day program setting?",
    },
    {
      id: "BH.3.4",
      extend: "true",
      shortname: "ps02-4-adol1",
      name: "",
      prevalue: "Treated in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS02-4-ADOL1",
      condition: "in",
      depending: "PS02-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO2-ADOL-CODE",
      subtopic_name:
        "Have you EVER been treated for psychological, emotional, or behavioral problems in a partial or day program setting?",
    },
    {
      id: "BH.3.4.c",
      extend: "true",
      shortname: "ps02-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS02-C-ADOL1",
      depending: "PS02-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO2-ADOL-CODE",
      subtopic_name:
        "Have you EVER been treated for psychological, emotional, or behavioral problems in a partial or day program setting?",
    },
    {
      id: "BH.4",
      shortname: "ps03-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS03-ADOL1",
      depending: "",
      exception: "",
      link: "PS03-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO3-ADOL-CODE",
      subtopic_name:
        "Have you EVER been treated for psychological, emotional, or behavioral problems in an inpatient or residential setting?",
    },
    {
      id: "BH.4.1",
      extend: "true",
      shortname: "ps03-1-adol1",
      name: "",
      prevalue: "Age of 1st treatment?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS03-1-ADOL1",
      depending: "PS03-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO3-ADOL-CODE",
      subtopic_name:
        "Have you EVER been treated for psychological, emotional, or behavioral problems in an inpatient or residential setting?",
    },
    {
      id: "BH.4.2",
      extend: "true",
      shortname: "ps03-2-adol1",
      name: "",
      prevalue: "Number of episodes",
      value: "",
      disabled: "true",
      type: "number",
      act: "PS03-2-ADOL1",
      depending: "PS03-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO3-ADOL-CODE",
      subtopic_name:
        "Have you EVER been treated for psychological, emotional, or behavioral problems in an inpatient or residential setting?",
    },
    {
      id: "BH.4.3",
      extend: "true",
      shortname: "ps03-3-adol1",
      name: "",
      prevalue: "Treated in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS03-3-ADOL1",
      condition: "in",
      depending: "PS03-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO3-ADOL-CODE",
      subtopic_name:
        "Have you EVER been treated for psychological, emotional, or behavioral problems in an inpatient or residential setting?",
    },
    {
      id: "BH.4.4",
      extend: "true",
      shortname: "ps03-4-adol1",
      name: "",
      prevalue: "Treated in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS03-4-ADOL1",
      condition: "in",
      depending: "PS03-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO3-ADOL-CODE",
      subtopic_name:
        "Have you EVER been treated for psychological, emotional, or behavioral problems in an inpatient or residential setting?",
    },
    {
      id: "BH.4.4.c",
      extend: "true",
      shortname: "ps03-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS03-C-ADOL1",
      depending: "PS03-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO3-ADOL-CODE",
      subtopic_name:
        "Have you EVER been treated for psychological, emotional, or behavioral problems in an inpatient or residential setting?",
    },
    {
      id: "BH.5",
      shortname: "ps04-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS04-ADOL1",
      depending: "",
      exception: "",
      link: "PS04-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO4-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you  had consistent thoughts of failure, lacked self-confidence, were dissatisfied with yourself, felt you could not do anything right or felt inferior to others?",
    },
    {
      id: "BH.5.1",
      extend: "true",
      shortname: "ps04-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS04-1-ADOL1",
      depending: "PS04-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO4-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you  had consistent thoughts of failure, lacked self-confidence, were dissatisfied with yourself, felt you could not do anything right or felt inferior to others?",
    },
    // {
    //   id: "BH.5.2",
    //   extend: "true",
    //   shortname: "ps04-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS04-2-ADOL1",
    //   depending: "PS04-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PSO4-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you  had consistent thoughts of failure, lacked self-confidence, were dissatisfied with yourself, felt you could not do anything right or felt inferior to others?",
    // },
    {
      id: "BH.5.3",
      extend: "true",
      shortname: "ps04-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS04-3-ADOL1",
      condition: "in",
      depending: "PS04-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO4-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you  had consistent thoughts of failure, lacked self-confidence, were dissatisfied with yourself, felt you could not do anything right or felt inferior to others?",
    },
    {
      id: "BH.5.4",
      extend: "true",
      shortname: "ps04-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS04-4-ADOL1",
      condition: "in",
      depending: "PS04-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO4-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you  had consistent thoughts of failure, lacked self-confidence, were dissatisfied with yourself, felt you could not do anything right or felt inferior to others?",
    },
    {
      id: "BH.5.4.c",
      extend: "true",
      shortname: "ps04-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS04-C-ADOL1",
      depending: "PS04-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO4-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you  had consistent thoughts of failure, lacked self-confidence, were dissatisfied with yourself, felt you could not do anything right or felt inferior to others?",
    },
    {
      id: "BH.6",
      shortname: "ps05-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS05-ADOL1",
      depending: "",
      exception: "",
      link: "PS05-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO5-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were extremely intimidated, shy, felt withdrawn, self-conscious or were afraid of new situations?",
    },
    {
      id: "BH.6.1",
      extend: "true",
      shortname: "ps05-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS05-1-ADOL1",
      depending: "PS05-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO5-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were extremely intimidated, shy, felt withdrawn, self-conscious or were afraid of new situations?",
    },
    // {
    //   id: "BH.6.2",
    //   extend: "true",
    //   shortname: "ps05-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS05-2-ADOL1",
    //   depending: "PS05-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PSO5-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you were extremely intimidated, shy, felt withdrawn, self-conscious or were afraid of new situations?",
    // },
    {
      id: "BH.6.3",
      extend: "true",
      shortname: "ps05-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS05-3-ADOL1",
      condition: "in",
      depending: "PS05-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO5-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were extremely intimidated, shy, felt withdrawn, self-conscious or were afraid of new situations?",
    },
    {
      id: "BH.6.4",
      extend: "true",
      shortname: "ps05-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS05-4-ADOL1",
      condition: "in",
      depending: "PS05-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO5-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were extremely intimidated, shy, felt withdrawn, self-conscious or were afraid of new situations?",
    },
    {
      id: "BH.6.4.c",
      extend: "true",
      shortname: "ps05-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS05-C-ADOL1",
      depending: "PS05-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO5-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were extremely intimidated, shy, felt withdrawn, self-conscious or were afraid of new situations?",
    },
    {
      id: "BH.7",
      shortname: "ps06-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS06-ADOL1",
      depending: "",
      exception: "",
      link: "PS06-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO6-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were restless, fidgety, had excessive activity, could not sit still or were extremely distractible?",
    },
    {
      id: "BH.7.1",
      extend: "true",
      shortname: "ps06-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS06-1-ADOL1",
      depending: "PS06-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO6-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were restless, fidgety, had excessive activity, could not sit still or were extremely distractible?",
    },
    // {
    //   id: "BH.7.2",
    //   extend: "true",
    //   shortname: "ps06-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS06-2-ADOL1",
    //   depending: "PS06-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PSO6-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you were restless, fidgety, had excessive activity, could not sit still or were extremely distractible?",
    // },
    {
      id: "BH.7.3",
      extend: "true",
      shortname: "ps06-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS06-3-ADOL1",
      condition: "in",
      depending: "PS06-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO6-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were restless, fidgety, had excessive activity, could not sit still or were extremely distractible?",
    },
    {
      id: "BH.7.4",
      extend: "true",
      shortname: "ps06-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS06-4-ADOL1",
      condition: "in",
      depending: "PS06-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO6-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were restless, fidgety, had excessive activity, could not sit still or were extremely distractible?",
    },
    {
      id: "BH.7.4.c",
      extend: "true",
      shortname: "ps06-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS06-C-ADOL1",
      depending: "PS06-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO6-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were restless, fidgety, had excessive activity, could not sit still or were extremely distractible?",
    },
    {
      id: "BH.8",
      shortname: "ps07-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS07-ADOL1",
      depending: "",
      exception: "",
      link: "PS07-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO7-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were extremely anxious, keyed-up, tense, unable to relax, worried a lot, felt panicky, had sweaty/cold hands, your heart raced/pounded or you felt sick to your stomach when you were not physically sick?",
    },
    {
      id: "BH.8.1",
      extend: "true",
      shortname: "ps07-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS07-1-ADOL1",
      depending: "PS07-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO7-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were extremely anxious, keyed-up, tense, unable to relax, worried a lot, felt panicky, had sweaty/cold hands, your heart raced/pounded or you felt sick to your stomach when you were not physically sick?",
    },
    // {
    //   id: "BH.8.2",
    //   extend: "true",
    //   shortname: "ps07-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS07-2-ADOL1",
    //   depending: "PS07-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PSO7-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you were extremely anxious, keyed-up, tense, unable to relax, worried a lot, felt panicky, had sweaty/cold hands, your heart raced/pounded or you felt sick to your stomach when you were not physically sick?",
    // },
    {
      id: "BH.8.3",
      extend: "true",
      shortname: "ps07-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS07-3-ADOL1",
      condition: "in",
      depending: "PS07-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO7-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were extremely anxious, keyed-up, tense, unable to relax, worried a lot, felt panicky, had sweaty/cold hands, your heart raced/pounded or you felt sick to your stomach when you were not physically sick?",
    },
    {
      id: "BH.8.4",
      extend: "true",
      shortname: "ps07-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS07-4-ADOL1",
      condition: "in",
      depending: "PS07-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO7-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were extremely anxious, keyed-up, tense, unable to relax, worried a lot, felt panicky, had sweaty/cold hands, your heart raced/pounded or you felt sick to your stomach when you were not physically sick?",
    },
    {
      id: "BH.8.4.c",
      extend: "true",
      shortname: "ps07-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS07-C-ADOL1",
      depending: "PS07-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO7-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were extremely anxious, keyed-up, tense, unable to relax, worried a lot, felt panicky, had sweaty/cold hands, your heart raced/pounded or you felt sick to your stomach when you were not physically sick?",
    },
    {
      id: "BH.9",
      shortname: "ps08-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS08-ADOL1",
      depending: "",
      exception: "",
      link: "PS08-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO8-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you acted too quickly without thinking, were impulsive, did dangerous things for the thrill of it or were often said to be hot-headed?",
    },
    {
      id: "BH.9.1",
      extend: "true",
      shortname: "ps08-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS08-1-ADOL1",
      depending: "PS08-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO8-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you acted too quickly without thinking, were impulsive, did dangerous things for the thrill of it or were often said to be hot-headed?",
    },
    // {
    //   id: "BH.9.2",
    //   extend: "true",
    //   shortname: "ps08-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS08-2-ADOL1",
    //   depending: "PS08-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PSO8-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you acted too quickly without thinking, were impulsive, did dangerous things for the thrill of it or were often said to be hot-headed?",
    // },
    {
      id: "BH.9.3",
      extend: "true",
      shortname: "ps08-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS08-3-ADOL1",
      condition: "in",
      depending: "PS08-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO8-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you acted too quickly without thinking, were impulsive, did dangerous things for the thrill of it or were often said to be hot-headed?",
    },
    {
      id: "BH.9.4",
      extend: "true",
      shortname: "ps08-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS08-4-ADOL1",
      condition: "in",
      depending: "PS08-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO8-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you acted too quickly without thinking, were impulsive, did dangerous things for the thrill of it or were often said to be hot-headed?",
    },
    {
      id: "BH.9.4.c",
      extend: "true",
      shortname: "ps08-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS08-C-ADOL1",
      depending: "PS08-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO8-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you acted too quickly without thinking, were impulsive, did dangerous things for the thrill of it or were often said to be hot-headed?",
    },
    {
      id: "BH.10",
      shortname: "ps09-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS09-ADOL1",
      depending: "",
      exception: "",
      link: "PS09-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO9-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you intentionally violated rules, refused requests, were rebellious, did things purposely to annoy people or were spiteful?",
    },
    {
      id: "BH.10.1",
      extend: "true",
      shortname: "ps09-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS09-1-ADOL1",
      depending: "PS09-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO9-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you intentionally violated rules, refused requests, were rebellious, did things purposely to annoy people or were spiteful?",
    },
    // {
    //   id: "BH.10.2",
    //   extend: "true",
    //   shortname: "ps09-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS09-2-ADOL1",
    //   depending: "PS09-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PSO9-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you intentionally violated rules, refused requests, were rebellious, did things purposely to annoy people or were spiteful?",
    // },
    {
      id: "BH.10.3",
      extend: "true",
      shortname: "ps09-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS09-3-ADOL1",
      condition: "in",
      depending: "PS09-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO9-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you intentionally violated rules, refused requests, were rebellious, did things purposely to annoy people or were spiteful?",
    },
    {
      id: "BH.10.4",
      extend: "true",
      shortname: "ps09-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS09-4-ADOL1",
      condition: "in",
      depending: "PS09-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO9-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you intentionally violated rules, refused requests, were rebellious, did things purposely to annoy people or were spiteful?",
    },
    {
      id: "BH.10.4.c",
      extend: "true",
      shortname: "ps09-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS09-C-ADOL1",
      depending: "PS09-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PSO9-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you intentionally violated rules, refused requests, were rebellious, did things purposely to annoy people or were spiteful?",
    },
    {
      id: "BH.11",
      shortname: "ps10-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS10-ADOL1",
      depending: "",
      exception: "",
      link: "PS10-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS10-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you consistently lost your temper, argued with adults, were angry or resentful?",
    },
    {
      id: "BH.11.1",
      extend: "true",
      shortname: "ps10-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS10-1-ADOL1",
      depending: "PS10-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS10-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you consistently lost your temper, argued with adults, were angry or resentful?",
    },
    // {
    //   id: "BH.11.2",
    //   extend: "true",
    //   shortname: "ps10-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS10-2-ADOL1",
    //   depending: "PS10-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PS10-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you consistently lost your temper, argued with adults, were angry or resentful?",
    // },
    {
      id: "BH.11.3",
      extend: "true",
      shortname: "ps10-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS10-3-ADOL1",
      condition: "in",
      depending: "PS10-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS10-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you consistently lost your temper, argued with adults, were angry or resentful?",
    },
    {
      id: "BH.11.4",
      extend: "true",
      shortname: "ps10-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS10-4-ADOL1",
      condition: "in",
      depending: "PS10-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS10-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you consistently lost your temper, argued with adults, were angry or resentful?",
    },
    {
      id: "BH.11.4.c",
      extend: "true",
      shortname: "ps10-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS10-C-ADOL1",
      depending: "PS10-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS10-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you consistently lost your temper, argued with adults, were angry or resentful?",
    },
    {
      id: "BH.12",
      shortname: "ps11-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS11-ADOL1",
      depending: "",
      exception: "",
      link: "PS11-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS11-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were extremely hostile or physically violent which was difficult, if not impossible to control or had sudden and excessive outbursts?",
    },
    {
      id: "BH.12.1",
      extend: "true",
      shortname: "ps11-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS11-1-ADOL1",
      depending: "PS11-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS11-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were extremely hostile or physically violent which was difficult, if not impossible to control or had sudden and excessive outbursts?",
    },
    // {
    //   id: "BH.12.2",
    //   extend: "true",
    //   shortname: "ps11-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS11-2-ADOL1",
    //   depending: "PS11-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PS11-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you were extremely hostile or physically violent which was difficult, if not impossible to control or had sudden and excessive outbursts?",
    // },
    {
      id: "BH.12.3",
      extend: "true",
      shortname: "ps11-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS11-3-ADOL1",
      condition: "in",
      depending: "PS11-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS11-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were extremely hostile or physically violent which was difficult, if not impossible to control or had sudden and excessive outbursts?",
    },
    {
      id: "BH.12.4",
      extend: "true",
      shortname: "ps11-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS11-4-ADOL1",
      condition: "in",
      depending: "PS11-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS11-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were extremely hostile or physically violent which was difficult, if not impossible to control or had sudden and excessive outbursts?",
    },
    {
      id: "BH.12.4.c",
      extend: "true",
      shortname: "ps11-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS11-C-ADOL1",
      depending: "PS11-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS11-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were extremely hostile or physically violent which was difficult, if not impossible to control or had sudden and excessive outbursts?",
    },

    {
      id: "BH.13",
      shortname: "ps12-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS12-ADOL1",
      depending: "",
      exception: "",
      link: "PS12-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS12-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you deliberately set fires or destroyed property with the intention of causing damage?",
    },
    {
      id: "BH.13.1",
      extend: "true",
      shortname: "ps12-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS12-1-ADOL1",
      depending: "PS12-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS12-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you deliberately set fires or destroyed property with the intention of causing damage?",
    },
    // {
    //   id: "BH.13.2",
    //   extend: "true",
    //   shortname: "ps12-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS12-2-ADOL1",
    //   depending: "PS12-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PS12-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you deliberately set fires or destroyed property with the intention of causing damage?",
    // },
    {
      id: "BH.13.3",
      extend: "true",
      shortname: "ps12-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS12-3-ADOL1",
      condition: "in",
      depending: "PS12-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS12-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you deliberately set fires or destroyed property with the intention of causing damage?",
    },
    {
      id: "BH.13.4",
      extend: "true",
      shortname: "ps12-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS12-4-ADOL1",
      condition: "in",
      depending: "PS12-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS12-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you deliberately set fires or destroyed property with the intention of causing damage?",
    },
    {
      id: "BH.13.4.c",
      extend: "true",
      shortname: "ps12-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS12-C-ADOL1",
      depending: "PS12-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS12-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you deliberately set fires or destroyed property with the intention of causing damage?",
    },
    {
      id: "BH.14",
      shortname: "ps13-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS13-ADOL1",
      depending: "",
      exception: "",
      link: "PS13-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS13-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were physically cruel to animals or hurt animals?",
    },
    {
      id: "BH.14.1",
      extend: "true",
      shortname: "ps13-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS13-1-ADOL1",
      depending: "PS13-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS13-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were physically cruel to animals or hurt animals?",
    },
    // {
    //   id: "BH.14.2",
    //   extend: "true",
    //   shortname: "ps13-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS13-2-ADOL1",
    //   depending: "PS13-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PS13-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you were physically cruel to animals or hurt animals?",
    // },
    {
      id: "BH.14.3",
      extend: "true",
      shortname: "ps13-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS13-3-ADOL1",
      condition: "in",
      depending: "PS13-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS13-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were physically cruel to animals or hurt animals?",
    },
    {
      id: "BH.14.4",
      extend: "true",
      shortname: "ps13-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS13-4-ADOL1",
      condition: "in",
      depending: "PS13-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS13-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were physically cruel to animals or hurt animals?",
    },
    {
      id: "BH.14.4.c",
      extend: "true",
      shortname: "ps13-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS13-C-ADOL1",
      depending: "PS13-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS13-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were physically cruel to animals or hurt animals?",
    },
    {
      id: "BH.15",
      shortname: "ps14-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS14-ADOL1",
      depending: "",
      exception: "",
      link: "PS14-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS14-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were constantly preoccupied with food, your weight, how your body looks, purposely made yourself vomit or abused laxatives?",
    },
    {
      id: "BH.15.1",
      extend: "true",
      shortname: "ps14-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS14-1-ADOL1",
      depending: "PS14-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS14-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were constantly preoccupied with food, your weight, how your body looks, purposely made yourself vomit or abused laxatives?",
    },
    // {
    //   id: "BH.15.2",
    //   extend: "true",
    //   shortname: "ps14-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS14-2-ADOL1",
    //   depending: "PS14-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PS14-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you were constantly preoccupied with food, your weight, how your body looks, purposely made yourself vomit or abused laxatives?",
    // },
    {
      id: "BH.15.3",
      extend: "true",
      shortname: "ps14-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS14-3-ADOL1",
      condition: "in",
      depending: "PS14-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS14-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were constantly preoccupied with food, your weight, how your body looks, purposely made yourself vomit or abused laxatives?",
    },
    {
      id: "BH.15.4",
      extend: "true",
      shortname: "ps14-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS14-4-ADOL1",
      condition: "in",
      depending: "PS14-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS14-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were constantly preoccupied with food, your weight, how your body looks, purposely made yourself vomit or abused laxatives?",
    },
    {
      id: "BH.15.4.c",
      extend: "true",
      shortname: "ps14-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS14-C-ADOL1",
      depending: "PS14-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS14-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you were constantly preoccupied with food, your weight, how your body looks, purposely made yourself vomit or abused laxatives?",
    },
    {
      id: "BH.16",
      shortname: "ps15-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS15-ADOL1",
      depending: "",
      exception: "",
      link: "PS15-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS15-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you had thoughts you could not get rid of, had to do the same things over and over again?",
    },
    {
      id: "BH.16.1",
      extend: "true",
      shortname: "ps15-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS15-1-ADOL1",
      depending: "PS15-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS15-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you had thoughts you could not get rid of, had to do the same things over and over again?",
    },
    // {
    //   id: "BH.16.2",
    //   extend: "true",
    //   shortname: "ps15-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS15-2-ADOL1",
    //   depending: "PS15-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PS15-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you had thoughts you could not get rid of, had to do the same things over and over again?",
    // },
    {
      id: "BH.16.3",
      extend: "true",
      shortname: "ps15-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS15-3-ADOL1",
      condition: "in",
      depending: "PS15-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS15-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you had thoughts you could not get rid of, had to do the same things over and over again?",
    },
    {
      id: "BH.16.4",
      extend: "true",
      shortname: "ps15-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS15-4-ADOL1",
      condition: "in",
      depending: "PS15-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS15-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you had thoughts you could not get rid of, had to do the same things over and over again?",
    },
    {
      id: "BH.16.4.c",
      extend: "true",
      shortname: "ps15-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS15-C-ADOL1",
      depending: "PS15-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS15-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you had thoughts you could not get rid of, had to do the same things over and over again?",
    },
    {
      id: "BH.17",
      shortname: "ps16-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS16-ADOL1",
      depending: "",
      exception: "",
      link: "PS16-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS16-ADOL-CODE",
      subtopic_name:
        'Have you EVER had a significant period during which you felt sad, hopeless, "bummed out", or cried a lot?',
    },
    {
      id: "BH.17.1",
      extend: "true",
      shortname: "ps16-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS16-1-ADOL1",
      depending: "PS16-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS16-ADOL-CODE",
      subtopic_name:
        'Have you EVER had a significant period during which you felt sad, hopeless, "bummed out", or cried a lot?',
    },
    // {
    //   id: "BH.17.2",
    //   extend: "true",
    //   shortname: "ps16-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS16-2-ADOL1",
    //   depending: "PS16-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PS16-ADOL-CODE",
    //   subtopic_name:
    //     'Have you EVER had a significant period during which you felt sad, hopeless, "bummed out", or cried a lot?',
    // },
    {
      id: "BH.17.3",
      extend: "true",
      shortname: "ps16-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS16-3-ADOL1",
      condition: "in",
      depending: "PS16-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS16-ADOL-CODE",
      subtopic_name:
        'Have you EVER had a significant period during which you felt sad, hopeless, "bummed out", or cried a lot?',
    },
    {
      id: "BH.17.4",
      extend: "true",
      shortname: "ps16-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS16-4-ADOL1",
      condition: "in",
      depending: "PS16-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS16-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you had thoughts you could not get rid of, had to do the same things over and over again?",
    },
    {
      id: "BH.17.4.c",
      extend: "true",
      shortname: "ps16-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS16-C-ADOL1",
      depending: "PS16-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS16-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you had thoughts you could not get rid of, had to do the same things over and over again?",
    },
    {
      id: "BH.18",
      shortname: "ps17-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS17-ADOL1",
      depending: "",
      exception: "",
      link: "PS17-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS17-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you lost interest in things that were once important, were extremely tired or had little energy?",
    },
    {
      id: "BH.18.1",
      extend: "true",
      shortname: "ps17-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS17-1-ADOL1",
      depending: "PS17-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS17-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you lost interest in things that were once important, were extremely tired or had little energy?",
    },
    // {
    //   id: "BH.18.2",
    //   extend: "true",
    //   shortname: "ps17-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS17-2-ADOL1",
    //   depending: "PS17-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PS17-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you lost interest in things that were once important, were extremely tired or had little energy?",
    // },
    {
      id: "BH.18.3",
      extend: "true",
      shortname: "ps17-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS17-3-ADOL1",
      condition: "in",
      depending: "PS17-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS17-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you lost interest in things that were once important, were extremely tired or had little energy?",
    },
    {
      id: "BH.18.4",
      extend: "true",
      shortname: "ps17-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS17-4-ADOL1",
      condition: "in",
      depending: "PS17-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS17-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you lost interest in things that were once important, were extremely tired or had little energy?",
    },
    {
      id: "BH.18.4.c",
      extend: "true",
      shortname: "ps17-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS17-C-ADOL1",
      depending: "PS17-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS17-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you lost interest in things that were once important, were extremely tired or had little energy?",
    },
    {
      id: "BH.19",
      shortname: "ps18-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS18-ADOL1",
      depending: "",
      exception: "",
      link: "PS18-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS18-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you experienced serous thoughts of suicide?",
    },
    {
      id: "BH.19.1",
      extend: "true",
      shortname: "ps18-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS18-1-ADOL1",
      depending: "PS18-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS18-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you experienced serous thoughts of suicide?",
    },
    // {
    //   id: "BH.19.2",
    //   extend: "true",
    //   shortname: "ps18-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS18-2-ADOL1",
    //   depending: "PS18-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PS18-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you experienced serous thoughts of suicide?",
    // },
    {
      id: "BH.19.3",
      extend: "true",
      shortname: "ps18-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS18-3-ADOL1",
      condition: "in",
      depending: "PS18-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS18-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you experienced serous thoughts of suicide?",
    },
    {
      id: "BH.19.4",
      extend: "true",
      shortname: "ps18-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS18-4-ADOL1",
      condition: "in",
      depending: "PS18-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS18-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you experienced serous thoughts of suicide?",
    },
    {
      id: "BH.19.4.c",
      extend: "true",
      shortname: "ps18-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS18-C-ADOL1",
      depending: "PS18-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS18-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you experienced serous thoughts of suicide?",
    },
    {
      id: "BH.20",
      shortname: "ps19-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS19-ADOL1",
      depending: "",
      exception: "",
      link: "PS19-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS19-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you attempted suicide?",
    },
    {
      id: "BH.20.1",
      extend: "true",
      shortname: "ps19-1-adol1",
      name: "",
      prevalue: "Age of 1st attempt?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS19-1-ADOL1",
      depending: "PS19-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS19-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you attempted suicide?",
    },
    // {
    //   id: "BH.20.2",
    //   extend: "true",
    //   shortname: "ps19-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS19-2-ADOL1",
    //   depending: "PS19-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PS19-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you attempted suicide?",
    // },
    {
      id: "BH.20.3",
      extend: "true",
      shortname: "ps19-3-adol1",
      name: "",
      prevalue: "Attempted in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS19-3-ADOL1",
      condition: "in",
      depending: "PS19-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS19-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you attempted suicide?",
    },
    {
      id: "BH.20.4",
      extend: "true",
      shortname: "ps19-4-adol1",
      name: "",
      prevalue: "Attempted in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS19-4-ADOL1",
      condition: "in",
      depending: "PS19-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS19-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you attempted suicide?",
    },
    {
      id: "BH.20.4.c",
      extend: "true",
      shortname: "ps19-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS19-C-ADOL1",
      depending: "PS19-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS19-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you attempted suicide?",
    },
    {
      id: "BH.21",
      shortname: "ps20-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS20-ADOL1",
      depending: "",
      exception: "",
      link: "PS20-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS20-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you intentionally did things to physically hurt yourself (e.g., intentionally burn yourself with a cigarette or cut yourself)?",
    },
    {
      id: "BH.21.1",
      extend: "true",
      shortname: "ps20-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS20-1-ADOL1",
      depending: "PS20-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS20-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you intentionally did things to physically hurt yourself (e.g., intentionally burn yourself with a cigarette or cut yourself)?",
    },
    // {
    //   id: "BH.21.2",
    //   extend: "true",
    //   shortname: "ps20-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS20-2-ADOL1",
    //   depending: "PS20-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PS20-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you intentionally did things to physically hurt yourself (e.g., intentionally burn yourself with a cigarette or cut yourself)?",
    // },
    {
      id: "BH.21.3",
      extend: "true",
      shortname: "ps20-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS20-3-ADOL1",
      condition: "in",
      depending: "PS20-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS20-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you intentionally did things to physically hurt yourself (e.g., intentionally burn yourself with a cigarette or cut yourself)?",
    },
    {
      id: "BH.21.4",
      extend: "true",
      shortname: "ps20-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS20-4-ADOL1",
      condition: "in",
      depending: "PS20-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS20-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you intentionally did things to physically hurt yourself (e.g., intentionally burn yourself with a cigarette or cut yourself)?",
    },
    {
      id: "BH.21.4.c",
      extend: "true",
      shortname: "ps20-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS20-C-ADOL1",
      depending: "PS20-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS20-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you intentionally did things to physically hurt yourself (e.g., intentionally burn yourself with a cigarette or cut yourself)?",
    },
    {
      id: "BH.22",
      shortname: "ps21-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS21-ADOL1",
      depending: "",
      exception: "",
      link: "PS21-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS21-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you had a tremendous amount of energy or engaged in significantly more activity than is usual for you, felt euphoric (overly happy) or were extremely talkative?",
    },
    {
      id: "BH.22.1",
      extend: "true",
      shortname: "ps21-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS21-1-ADOL1",
      depending: "PS21-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS21-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you had a tremendous amount of energy or engaged in significantly more activity than is usual for you, felt euphoric (overly happy) or were extremely talkative?",
    },
    // {
    //   id: "BH.22.2",
    //   extend: "true",
    //   shortname: "ps21-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS21-2-ADOL1",
    //   depending: "PS21-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PS21-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you had a tremendous amount of energy or engaged in significantly more activity than is usual for you, felt euphoric (overly happy) or were extremely talkative?",
    // },
    {
      id: "BH.22.3",
      extend: "true",
      shortname: "ps21-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS21-3-ADOL1",
      condition: "in",
      depending: "PS21-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS21-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you had a tremendous amount of energy or engaged in significantly more activity than is usual for you, felt euphoric (overly happy) or were extremely talkative?",
    },
    {
      id: "BH.22.4",
      extend: "true",
      shortname: "ps21-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS21-4-ADOL1",
      condition: "in",
      depending: "PS21-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS21-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you had a tremendous amount of energy or engaged in significantly more activity than is usual for you, felt euphoric (overly happy) or were extremely talkative?",
    },
    {
      id: "BH.22.4.c",
      extend: "true",
      shortname: "ps21-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS21-C-ADOL1",
      depending: "PS21-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS21-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you had a tremendous amount of energy or engaged in significantly more activity than is usual for you, felt euphoric (overly happy) or were extremely talkative?",
    },
    {
      id: "BH.23",
      shortname: "ps22-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS22-ADOL1",
      depending: "",
      exception: "",
      link: "PS22-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS22-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you heard voices other people did not hear, saw or smelled things other people did not see or smell, had delusions or hallucinations, felt paranoid or extremely suspicious?",
    },
    {
      id: "BH.23.1",
      extend: "true",
      shortname: "ps22-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS22-1-ADOL1",
      depending: "PS22-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS22-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you heard voices other people did not hear, saw or smelled things other people did not see or smell, had delusions or hallucinations, felt paranoid or extremely suspicious?",
    },
    // {
    //   id: "BH.23.2",
    //   extend: "true",
    //   shortname: "ps22-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS22-2-ADOL1",
    //   depending: "PS22-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PS22-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you heard voices other people did not hear, saw or smelled things other people did not see or smell, had delusions or hallucinations, felt paranoid or extremely suspicious?",
    // },
    {
      id: "BH.23.3",
      extend: "true",
      shortname: "ps22-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS22-3-ADOL1",
      condition: "in",
      depending: "PS22-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS22-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you heard voices other people did not hear, saw or smelled things other people did not see or smell, had delusions or hallucinations, felt paranoid or extremely suspicious?",
    },
    {
      id: "BH.23.4",
      extend: "true",
      shortname: "ps22-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS22-4-ADOL1",
      condition: "in",
      depending: "PS22-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS22-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you heard voices other people did not hear, saw or smelled things other people did not see or smell, had delusions or hallucinations, felt paranoid or extremely suspicious?",
    },
    {
      id: "BH.23.4.c",
      extend: "true",
      shortname: "ps22-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS22-C-ADOL1",
      depending: "PS22-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS22-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you heard voices other people did not hear, saw or smelled things other people did not see or smell, had delusions or hallucinations, felt paranoid or extremely suspicious?",
    },
    {
      id: "BH.24",
      shortname: "ps23-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS23-ADOL1",
      depending: "",
      exception: "",
      link: "PS23-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS23-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you liked yourself, felt good about yourself?",
    },
    {
      id: "BH.24.1",
      extend: "true",
      shortname: "ps23-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS23-1-ADOL1",
      depending: "PS23-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS23-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you liked yourself, felt good about yourself?",
    },
    // {
    //   id: "BH.24.2",
    //   extend: "true",
    //   shortname: "ps23-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS23-2-ADOL1",
    //   depending: "PS23-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PS23-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you liked yourself, felt good about yourself?",
    // },
    {
      id: "BH.24.3",
      extend: "true",
      shortname: "ps23-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS23-3-ADOL1",
      condition: "in",
      depending: "PS23-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS23-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you liked yourself, felt good about yourself?",
    },
    {
      id: "BH.24.4",
      extend: "true",
      shortname: "ps23-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS23-4-ADOL1",
      condition: "in",
      depending: "PS23-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS23-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you liked yourself, felt good about yourself?",
    },
    {
      id: "BH.24.4.c",
      extend: "true",
      shortname: "ps23-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS23-C-ADOL1",
      depending: "PS23-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS23-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you liked yourself, felt good about yourself?",
    },
    {
      id: "BH.25",
      shortname: "ps24-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS24-ADOL1",
      depending: "",
      exception: "",
      link: "PS24-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS24-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you felt like you had a purpose in life?",
    },
    {
      id: "BH.25.1",
      extend: "true",
      shortname: "ps24-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS24-1-ADOL1",
      depending: "PS24-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS24-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you felt like you had a purpose in life?",
    },
    // {
    //   id: "BH.25.2",
    //   extend: "true",
    //   shortname: "ps24-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS24-2-ADOL1",
    //   depending: "PS24-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PS24-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you felt like you had a purpose in life?",
    // },
    {
      id: "BH.25.3",
      extend: "true",
      shortname: "ps24-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS24-3-ADOL1",
      condition: "in",
      depending: "PS24-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS24-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you felt like you had a purpose in life?",
    },
    {
      id: "BH.25.4",
      extend: "true",
      shortname: "ps24-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS24-4-ADOL1",
      condition: "in",
      depending: "PS24-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS24-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you felt like you had a purpose in life?",
    },
    {
      id: "BH.25.4.c",
      extend: "true",
      shortname: "ps24-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS24-C-ADOL1",
      depending: "PS24-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS24-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you felt like you had a purpose in life?",
    },
    {
      id: "BH.26",
      shortname: "ps25-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS25-ADOL1",
      depending: "",
      exception: "",
      link: "PS25-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS25-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you felt happy or content?",
    },
    {
      id: "BH.26.1",
      extend: "true",
      shortname: "ps25-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS25-1-ADOL1",
      depending: "PS25-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS25-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you felt happy or content?",
    },
    // {
    //   id: "BH.26.2",
    //   extend: "true",
    //   shortname: "ps25-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS25-2-ADOL1",
    //   depending: "PS25-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PS25-ADOL-CODE",
    //   subtopic_name:
    //     "Have you EVER had a significant period during which you felt happy or content?",
    // },
    {
      id: "BH.26.3",
      extend: "true",
      shortname: "ps25-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS25-3-ADOL1",
      condition: "in",
      depending: "PS25-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS25-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you felt happy or content?",
    },
    {
      id: "BH.26.4",
      extend: "true",
      shortname: "ps25-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS25-4-ADOL1",
      condition: "in",
      depending: "PS25-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS25-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you felt happy or content?",
    },
    {
      id: "BH.26.4.c",
      extend: "true",
      shortname: "ps25-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS25-C-ADOL1",
      depending: "PS25-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS25-ADOL-CODE",
      subtopic_name:
        "Have you EVER had a significant period during which you felt happy or content?",
    },
    {
      id: "BH.27",
      shortname: "ps26-adol1",
      name: "",
      prevalue: "",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS26-ADOL1",
      depending: "",
      exception: "",
      link: "PS26-1-ADOL1",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS26-ADOL-CODE",
      subtopic_name:
        'Have you EVER had a significant period during which you had more "good days" than "bad days"?',
    },
    {
      id: "BH.27.1",
      extend: "true",
      shortname: "ps26-1-adol1",
      name: "",
      prevalue: "How old were you when this first started?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "PS26-1-ADOL1",
      depending: "PS26-ADOL1",
      exception: "Yes",
      condition: "in",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS26-ADOL-CODE",
      subtopic_name:
        'Have you EVER had a significant period during which you had more "good days" than "bad days"?',
    },
    // {
    //   id: "BH.27.2",
    //   extend: "true",
    //   shortname: "ps26-2-adol1",
    //   name: "",
    //   prevalue: "Number of episodes",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "PS26-2-ADOL1",
    //   depending: "PS26-ADOL1",
    //   exception: "Yes",
    //   condition: "in",
    //   subtopic_container_act: "PS01-ADOL-ACT",
    //   suptopic_code: "PS26-ADOL-CODE",
    //   subtopic_name:
    //     'Have you EVER had a significant period during which you had more "good days" than "bad days"?',
    // },
    {
      id: "BH.27.3",
      extend: "true",
      shortname: "ps26-3-adol1",
      name: "",
      prevalue: "Experienced in the past 30 days?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS26-3-ADOL1",
      condition: "in",
      depending: "PS26-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS26-ADOL-CODE",
      subtopic_name:
        'Have you EVER had a significant period during which you had more "good days" than "bad days"?',
    },
    {
      id: "BH.27.4",
      extend: "true",
      shortname: "ps26-4-adol1",
      name: "",
      prevalue: "Experienced in the other 11 months of the past year?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "PS26-4-ADOL1",
      condition: "in",
      depending: "PS26-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS26-ADOL-CODE",
      subtopic_name:
        'Have you EVER had a significant period during which you had more "good days" than "bad days"?',
    },
    {
      id: "BH.27.4.c",
      extend: "true",
      shortname: "ps26-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "PS26-C-ADOL1",
      depending: "PS26-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "PS01-ADOL-ACT",
      suptopic_code: "PS26-ADOL-CODE",
      subtopic_name:
        'Have you EVER had a significant period during which you had more "good days" than "bad days"?',
    },
    {
      id: "BH.28",
      shortname: "ps3",
      name: "Do you receive any disability payments for a mental health problem?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS3",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "PS1-NEW6",
    },
    {
      id: "BH.28.1",
      shortname: "ps1-new6",
      name: "",
      prevalue:
        "If yes, what is the reason for the disability and what is the amount you receive?",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS1-NEW6",
      depending: "PS3",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "BH.29",
      shortname: "ps15",
      name: "Have you EVER been diagnosed with any mental health or psychological condition(s)? ",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS15",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "PS15-17",
    },
    {
      id: "BH.29.1",
      extend: "true",
      shortname: "ps15-17",
      name: "",
      prevalue:
        "If yes, what was the diagnosis? List current diagnoses and history of previous diagnoses.",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS15-17",
      depending: "PS15",
      exception: "Yes",
    },
    {
      id: "BH.30",
      shortname: "ps13",
      name: "Have you EVER been prescribed any medication for mental health or psychological condition(s)?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "PS13",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "PS13-19",
    },
    {
      id: "BH.30.1",
      extend: "true",
      shortname: "ps13-19",
      name: "",
      prevalue:
        "If yes, what medications have been prescribed? Indicate current prescription and history of other medications.",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS13-19",
      depending: "PS13",
      exception: "Yes",
    },
    {
      id: "BH.31",
      shortname: "ps28-adol1",
      name: "In the past month how often have you experienced psychological, behavioral or emotional issues which bothered you?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Never", "Sometimes", "Almost always", "Always"],
      act: "PS28-ADOL1",
      depending: "",
      exception: "",
    },
    {
      id: "BH.32",
      shortname: "ps27-adol1",
      name: "Do you think you have any psychological, behavioral or emotional problems?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Maybe", "Definitely"],
      act: "PS27-ADOL1",
      depending: "",
      exception: "",
    },
    {
      id: "BH.33",
      shortname: "ps16",
      name: "How worried or bothered have you been about any mental health or psychological condition in the past 30 days?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "a little bit",
        "a lot",
      ],
      act: "PS16",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "PS16-1",
    },
    {
      id: "BH.33.1",
      shortname: "ps16-1",
      name: "",
      prevalue: "Please explain the reason you are worried or bothered?",
      value: "",
      disabled: "true",
      type: "text",
      act: "PS16-1",
      depending: "PS16",
      exception: "not at all",
      extend: "true",
    },
    {
      id: "BH.34",
      shortname: "ps17",
      name: "How important is it for you to receive additional counseling or treatment for any mental health or psychological concerns?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "a little bit",
        "a lot",
      ],
      act: "PS17",
      depending: "",
      exception: "",
    },
    {
      id: "BH.35",
      shortname: "ps18c",
      access: "private",
      name: "Additional comments:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "editor",
      act: "PS18C",
      depending: "",
      exception: "",
    },
  ],
};

export default behavioralHistoryJSON;
