const stateList = [
    {id:0,state: ""},
    {id:1,state: "Alabama"},
    {id:2,state: "Alaska"},
    {id:3,state: "American Samoa"},
    {id:4,state: "Arizona"},
    {id:5,state: "Arkansas"},
    {id:6,state: "California"},
    {id:7,state: "Colorado"},
    {id:8,state: "Connecticut"},
    {id:9,state: "Delaware"},
    {id:10,state: "District Of Columbia"},
    {id:11,state: "Federated States Of Micronesia"},
    {id:12,state: "Florida"},
    {id:13,state: "Georgia"},
    {id:14,state: "Guam"},
    {id:15,state: "Hawaii"},
    {id:16,state: "Idaho"},
    {id:17,state: "Illinois"},
    {id:18,state: "Indiana"},
    {id:19,state: "Iowa"},
    {id:20,state: "Kansas"},
    {id:21,state: "Kentucky"},
    {id:22,state: "Louisiana"},
    {id:23,state: "Maine"},
    {id:24,state: "Marshall Islands"},
    {id:25,state: "Maryland"},
    {id:26,state: "Massachusetts"},
    {id:27,state: "Michigan"},
    {id:28,state: "Minnesota"},
    {id:29,state: "Mississippi"},
    {id:30,state: "Missouri"},
    {id:31,state: "Montana"},
    {id:32,state: "Nebraska"},
    {id:33,state: "Nevada"},
    {id:34,state: "New Hampshire"},
    {id:35,state: "New Jersey"},
    {id:36,state: "New Mexico"},
    {id:37,state: "New York"},
    {id:38,state: "North Carolina"},
    {id:39,state: "North Dakota"},
    {id:40,state: "Northern Mariana Islands"},
    {id:41,state: "Ohio"},
    {id:42,state: "Oklahoma"},
    {id:43,state: "Oregon"},
    {id:44,state: "Palau"},
    {id:45,state: "Pennsylvania"},
    {id:46,state: "Puerto Rico"},
    {id:47,state: "Rhode Island"},
    {id:48,state: "South Carolina"},
    {id:49,state: "South Dakota"},
    {id:50,state: "Tennessee"},
    {id:51,state: "Texas"},
    {id:52,state: "Utah"},
    {id:53,state: "Vermont"},
    {id:54,state: "Virgin Islands"},
    {id:55,state: "Virginia"},
    {id:56,state: "Washington"},
    {id:57,state: "West Virginia"},
    {id:58,state: "Wisconsin"},
    {id:59,state: "Wyoming"
}]

export default stateList;