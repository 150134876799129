const mseJSON = {
  section: "MSE",
  topic: "Mental Status Examination (MSE)",
  checked: "true",
  // access: "private",
  color: "secondary",
  description: "",
  body: [
    //?========================================================================================
    /*                                                                                      *
     *                                       LEFT SIDE MSE.1                                *
     *                                                                                      */
    //?========================================================================================
    {
      id: "MSE.1",
      shortname: "mse0-subtopic",
      name: "General Appearance:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "MSE1-SUBTOPIC",
      depending: "",
      exception: "",
    },
    /*
      {
        id: "MSE.1.1",
        shortname: "mse1-1",
        mandatory: "true",
        name:
          "",
        prevalue: "Height:",
        value: "",
        disabled: "true",
        type: "select",
        select_options: [
          "",
          "3 feet",
          "4 feet",
          "5 feet",
          "6 feet",
        ],
        act: "MSE1-1",
        depending: "",
        exception: "",
        subtopic_container_act: "MSE1-SUBTOPIC",
        suptopic_code: "MSE1-HEIGHT",
        subtopic_name: "",
      },
*/
    {
      id: "MSE.1.1",
      shortname: "mse1-1",
      mandatory: "true",
      name: "General Appearance:",
      prevalue: "Height:",
      value: "",
      disabled: "false",
      // type: "text",
      type: "select",
      ruletype: "number",
      select_options: ["", "3 feet", "4 feet", "5 feet", "6 feet"],
      act: "MSE1-1",
      // link: "MSE1-1-1",
      depending: "",
      exception: "",
      subtopic_container_act: "MSE1-SUBTOPIC",
      suptopic_code: "MSE1-HEIGHT",
    },
    {
      id: "MSE.1.1",
      // extend: "true",
      shortname: "mse1-1-1",
      mandatory: "true",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      // type: "text",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0 inches",
        "1 inches",
        "2 inches",
        "3 inches",
        "4 inches",
        "5 inches",
        "6 inches",
        "7 inches",
        "8 inches",
        "9 inches",
        "10 inches",
        "11 inches",
        "12 inches",
      ],
      act: "MSE1-1-1",
      depending: "",
      exception: "",
      subtopic_container_act: "MSE1-SUBTOPIC",
      suptopic_code: "MSE1-HEIGHT",
    },
    // {
    //   id: "MSE.1.1",
    //   shortname: "mse1-1",
    //   mandatory: "true",
    //   name: "General Appearance:",
    //   prevalue: "Height:",
    //   value: "",
    //   disabled: "false",
    //   // type: "text",
    //   type: "feetinches",
    //   act: "MSE1-1",
    //   depending: "",
    //   exception: "",
    //   subtopic_container_act: "MSE1-SUBTOPIC",
    //   suptopic_code: "MSE1-HEIGHT",
    // },
    /*
      {
        id: "MSE.1.2",
        // extend: "true",
        shortname: "mse1-2",
        mandatory: "true",
        name: "",
        prevalue: "Weight:",
        value: "",
        disabled: "false",
        type: "text",
        act: "MSE1-2",
        depending: "",
        exception: "",
        subtopic_container_act: "MSE1-SUBTOPIC",
        suptopic_code: "MSE1-WEIGHT",
      },
*/
    {
      id: "MSE.1.2",
      // extend: "true",
      shortname: "mse1-2",
      mandatory: "true",
      name: "",
      prevalue: "Weight (pounds):",
      value: "",
      disabled: "false",
      type: "number",
      // type: "weight",
      act: "MSE1-2",
      depending: "",
      exception: "",
      subtopic_container_act: "MSE1-SUBTOPIC",
      suptopic_code: "MSE1-WEIGHT",
    },
    // Check the appropriate boxes below
    // {
    //   id: "MSE.1",
    //   shortname: "mse1-subtopic",
    //   name: "MSE 1 Topic:",
    //   prevalue: "",
    //   value: "",
    //   disabled: "false",
    //   type: "subtopic",
    //   act: "MSE1-SUBTOPIC",
    //   depending: "",
    //   exception: "",
    // },

    {
      id: "MSE.1.3",
      // extend: "true",
      shortname: "mse1-3",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Appropriate Dress"],
      act: "MSE1-3",
      depending: "",
      exception: "",
      link: "MSE1-4",
      subtopic_container_act: "MSE1-SUBTOPIC",
      suptopic_code: "MSE1-FIRSTSIDE",
      subtopic_name: "",
    },
    {
      id: "MSE.1.4",
      extend: "true",
      shortname: "mse1-4",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Adequate Grooming and Hygiene"],
      act: "MSE1-4",
      depending: "",
      exception: "",
      link: "MSE1-5",
      subtopic_container_act: "MSE1-SUBTOPIC",
      suptopic_code: "MSE1-FIRSTSIDE",
      subtopic_name: "",
    },
    {
      id: "MSE.1.5",
      extend: "true",
      shortname: "mse1-5",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Disheveled"],
      act: "MSE1-5",
      depending: "",
      exception: "",
      link: "MSE1-6",
      subtopic_container_act: "MSE1-SUBTOPIC",
      suptopic_code: "MSE1-FIRSTSIDE",
      subtopic_name: "",
    },
    {
      id: "MSE.1.6",
      extend: "true",
      shortname: "mse1-6",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Malodorous"],
      act: "MSE1-6",
      depending: "",
      exception: "",
      link: "MSE1-7",
      subtopic_container_act: "MSE1-SUBTOPIC",
      suptopic_code: "MSE1-FIRSTSIDE",
      subtopic_name: "",
    },
    {
      id: "MSE.1.7",
      extend: "true",
      shortname: "mse1-7",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Unusual/Eccentric Dress"],
      act: "MSE1-7",
      depending: "",
      exception: "",
      link: "MSE1-8",
      subtopic_container_act: "MSE1-SUBTOPIC",
      suptopic_code: "MSE1-FIRSTSIDE",
      subtopic_name: "",
    },
    {
      id: "MSE.1.8",
      extend: "true",
      shortname: "mse1-8",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Obese"],
      act: "MSE1-8",
      depending: "",
      exception: "",
      link: "MSE1-9",
      subtopic_container_act: "MSE1-SUBTOPIC",
      suptopic_code: "MSE1-FIRSTSIDE",
      subtopic_name: "",
    },
    {
      id: "MSE.1.9",
      extend: "true",
      shortname: "mse1-9",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Cachectic"],
      act: "MSE1-9",
      depending: "",
      exception: "",
      subtopic_container_act: "MSE1-SUBTOPIC",
      suptopic_code: "MSE1-FIRSTSIDE",
      subtopic_name: "",
    },

    //========================================================================================
    /*                                                                                      *
     *                                      RIGHT SIDE                                      *
     *                                                                                      */
    //========================================================================================
    {
      id: "MSE.1.10",
      shortname: "mse1-10",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Deformities"],
      act: "MSE1-10",
      depending: "",
      exception: "",
      link: "MSE1-10-1",
      subtopic_container_act: "MSE1-SUBTOPIC",
      suptopic_code: "MSE1-SECONDSIDE",
      subtopic_name: "",
    },
    {
      id: "MSE.1.10.1",
      extend: "true",
      shortname: "mse1-10-1",
      name: "",
      prevalue: "Explain:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MSE1-10-1",
      depending: "MSE1-10",
      exception: "Deformities",
      link: "MSE1-11",
      subtopic_container_act: "MSE1-SUBTOPIC",
      suptopic_code: "MSE1-SECONDSIDE",
    },
    {
      id: "MSE.1.11",
      extend: "true",
      shortname: "mse1-11",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Facial Dimorphisms"],
      act: "MSE1-11",
      depending: "",
      exception: "",
      link: "MSE1-11-1",
      subtopic_container_act: "MSE1-SUBTOPIC",
      suptopic_code: "MSE1-SECONDSIDE",
      subtopic_name: "",
    },
    {
      id: "MSE.1.11.1",
      extend: "true",
      shortname: "mse1-11-1",
      name: "",
      prevalue: "Explain:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MSE1-11-1",
      depending: "MSE1-11",
      exception: "Facial Dimorphisms",
      link: "MSE1-12",
      subtopic_container_act: "MSE1-SUBTOPIC",
      suptopic_code: "MSE1-SECONDSIDE",
    },
    {
      id: "MSE.1.12",
      extend: "true",
      shortname: "mse1-12",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Assistive Devices"],
      act: "MSE1-12",
      depending: "",
      exception: "",
      link: "MSE1-12-1",
      subtopic_container_act: "MSE1-SUBTOPIC",
      suptopic_code: "MSE1-SECONDSIDE",
      subtopic_name: "",
    },
    {
      id: "MSE.1.12.1",
      extend: "true",
      shortname: "mse1-12-1",
      name: "",
      prevalue: "Explain:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MSE1-12-1",
      depending: "MSE1-12",
      exception: "Assistive Devices",
      link: "MSE1-13",
      subtopic_container_act: "MSE1-SUBTOPIC",
      suptopic_code: "MSE1-SECONDSIDE",
    },
    {
      id: "MSE.1.13",
      extend: "true",
      shortname: "mse1-13",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other"],
      act: "MSE1-13",
      depending: "",
      exception: "",
      link: "MSE1-13-1",
      subtopic_container_act: "MSE1-SUBTOPIC",
      suptopic_code: "MSE1-SECONDSIDE",
      subtopic_name: "",
    },
    {
      id: "MSE.1.13.1",
      extend: "true",
      shortname: "mse1-13-1",
      name: "",
      prevalue: "Explain:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MSE1-13-1",
      depending: "MSE1-13",
      exception: "Other",
      // link: "MSE1-13",
      subtopic_container_act: "MSE1-SUBTOPIC",
      suptopic_code: "MSE1-SECONDSIDE",
    },
    //!========================================================================================
    /*                                                                                      *
     *                                         MSE.2                                        *
     *                                                                                      */
    //!========================================================================================

    {
      id: "MSE.2",
      shortname: "mse2-subtopic",
      name: "Behavior/Attitude during the interview/Mood/Affect/Speech",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "MSE2-SUBTOPIC",
      depending: "",
      exception: "",
    },
    //?========================================================================================
    /*                                                                                      *
     *                                       LEFT SIDE MSE.2                                *
     *                                                                                      */
    //?========================================================================================
    {
      id: "MSE.2.1",
      shortname: "mse2-behatt-1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Cooperative"],
      act: "MSE2-BEHATT-1",
      depending: "",
      exception: "",
      link: "MSE2-BEHATT-4",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-BEHATT",
      subtopic_name: "Behavior/Attitude during the interview",
    },
    {
      id: "MSE.2.2",
      extend: "true",
      shortname: "mse2-behatt-4",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Uncooperative"],
      act: "MSE2-BEHATT-4",
      depending: "",
      exception: "",
      link: "MSE2-BEHATT-2",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-BEHATT",
      subtopic_name: "Behavior/Attitude during the interview",
    },
    {
      id: "MSE.2.3",
      extend: "true",
      shortname: "mse2-behatt-2",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Good Eye Contact"],
      act: "MSE2-BEHATT-2",
      depending: "",
      exception: "",
      link: "MSE2-BEHATT-6",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-BEHATT",
      subtopic_name: "Behavior/Attitude during the interview",
    },
    {
      id: "MSE.2.4",
      extend: "true",
      shortname: "mse2-behatt-6",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Poor Eye Contact"],
      act: "MSE2-BEHATT-6",
      depending: "",
      exception: "",
      link: "MSE2-BEHATT-7",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-BEHATT",
      subtopic_name: "Behavior/Attitude during the interview",
    },
    {
      id: "MSE.2.5",
      extend: "true",
      shortname: "mse2-behatt-7",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Intermittent Eye Contact"],
      act: "MSE2-BEHATT-7",
      depending: "",
      exception: "",
      link: "MSE2-BEHATT-8",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-BEHATT",
      subtopic_name: "Behavior/Attitude during the interview",
    },
    {
      id: "MSE.2.6",
      extend: "true",
      shortname: "mse2-behatt-8",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Eye Contact Improved over Appointment"],
      act: "MSE2-BEHATT-8",
      depending: "",
      exception: "",
      link: "MSE2-BEHATT-3",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-BEHATT",
      subtopic_name: "Behavior/Attitude during the interview",
    },
    {
      id: "MSE.2.7",
      extend: "true",
      shortname: "mse2-behatt-3",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Normal Psychomotor Activity"],
      act: "MSE2-BEHATT-3",
      depending: "",
      exception: "",
      link: "MSE2-BEHATT-9",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-BEHATT",
      subtopic_name: "Behavior/Attitude during the interview",
    },

    {
      id: "MSE.2.8",
      extend: "true",
      shortname: "mse2-behatt-9",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Psychomotor Retarded"],
      act: "MSE2-BEHATT-9",
      depending: "",
      exception: "",
      link: "MSE2-BEHATT-10",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-BEHATT",
      subtopic_name: "Behavior/Attitude during the interview",
    },
    {
      id: "MSE.2.9",
      extend: "true",
      shortname: "mse2-behatt-10",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Elevated Psychomotor Activity"],
      act: "MSE2-BEHATT-10",
      depending: "",
      exception: "",
      link: "MSE2-BEHATT-5",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-BEHATT",
      subtopic_name: "Behavior/Attitude during the interview",
    },
    {
      id: "MSE.2.10",
      extend: "true",
      shortname: "mse2-behatt-5",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Withdrawn"],
      act: "MSE2-BEHATT-5",
      depending: "",
      exception: "",
      link: "MSE2-BEHATT-11",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-BEHATT",
      subtopic_name: "Behavior/Attitude during the interview",
    },
    {
      id: "MSE.2.11",
      extend: "true",
      shortname: "mse2-behatt-11",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Involuntary Movements"],
      act: "MSE2-BEHATT-11",
      depending: "",
      exception: "",
      link: "MSE2-BEHATT-12",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-BEHATT",
      subtopic_name: "Behavior/Attitude during the interview",
    },
    {
      id: "MSE.2.12",
      extend: "true",
      shortname: "mse2-behatt-12",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Unusual Mannerisms"],
      act: "MSE2-BEHATT-12",
      depending: "",
      exception: "",
      link: "MSE2-BEHATT-13",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-BEHATT",
      subtopic_name: "Behavior/Attitude during the interview",
    },
    {
      id: "MSE.2.13",
      extend: "true",
      shortname: "mse2-behatt-13",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other"],
      act: "MSE2-BEHATT-13",
      depending: "",
      exception: "",
      link: "MSE2-BEHATT-13-1",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-BEHATT",
      subtopic_name: "Behavior/Attitude during the interview",
    },
    {
      id: "MSE.2.13.1",
      extend: "true",
      shortname: "mse2-behatt-13-1",
      name: "",
      prevalue: "Explain:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MSE2-BEHATT-13-1",
      depending: "MSE2-BEHATT-13",
      exception: "Other",
      // link: "MSE1-13",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-BEHATT",
      subtopic_name: "Behavior/Attitude during the interview",
    },
    //========================================================================================
    /*                                                                                      *
     *                                       RIGHT SIDE MSE.2                                *
     *                                                                                      */
    //========================================================================================

    {
      id: "MSE.2.14",
      shortname: "mse2-1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Euthymic"],
      act: "MSE2-1",
      depending: "",
      exception: "",
      link: "MSE2-2",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-FIRSTSIDE",
      subtopic_name: "Mood",
    },
    {
      id: "MSE.2.15",
      extend: "true",
      shortname: "mse2-2",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Sad/Depressed"],
      act: "MSE2-2",
      depending: "",
      exception: "",
      link: "MSE2-3",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-FIRSTSIDE",
      subtopic_name: "Mood",
    },
    {
      id: "MSE.2.16",
      extend: "true",
      shortname: "mse2-3",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Elated/Euphoric"],
      act: "MSE2-3",
      depending: "",
      exception: "",
      link: "MSE2-4",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-FIRSTSIDE",
      subtopic_name: "Mood",
    },
    {
      id: "MSE.2.17",
      extend: "true",
      shortname: "mse2-4",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Anxious"],
      act: "MSE2-4",
      depending: "",
      exception: "",
      link: "MSE2-5",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-FIRSTSIDE",
      subtopic_name: "Mood",
    },
    {
      id: "MSE.2.18",
      extend: "true",
      shortname: "mse2-5",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Irritable"],
      act: "MSE2-5",
      depending: "",
      exception: "",
      link: "MSE2-6",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-FIRSTSIDE",
      subtopic_name: "Mood",
    },
    {
      id: "MSE.2.19",
      extend: "true",
      shortname: "mse2-6",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Labile"],
      act: "MSE2-6",
      depending: "",
      exception: "",
      link: "MSE2-7",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-FIRSTSIDE",
      subtopic_name: "Mood",
    },
    {
      id: "MSE.2.20",
      extend: "true",
      shortname: "mse2-7",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Apathy"],
      act: "MSE2-7",
      depending: "",
      exception: "",
      link: "MSE2-8",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-FIRSTSIDE",
      subtopic_name: "Mood",
    },
    {
      id: "MSE.2.21",
      extend: "true",
      shortname: "mse2-8",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Angry"],
      act: "MSE2-8",
      depending: "",
      exception: "",
      link: "MSE2-9",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-FIRSTSIDE",
      subtopic_name: "Mood",
    },
    {
      id: "MSE.2.22",
      extend: "true",
      shortname: "mse2-9",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Hostile"],
      act: "MSE2-9",
      depending: "",
      exception: "",
      link: "MSE2-10",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-FIRSTSIDE",
      subtopic_name: "Mood",
    },
    {
      id: "MSE.2.23",
      extend: "true",
      shortname: "mse2-10",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other"],
      act: "MSE2-10",
      depending: "",
      exception: "",
      link: "MSE2-10-1",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-FIRSTSIDE",
      subtopic_name: "Mood",
    },
    {
      id: "MSE.2.23.1",
      extend: "true",
      shortname: "mse2-10-1",
      name: "",
      prevalue: "Explain:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MSE2-10-1",
      depending: "MSE2-10",
      exception: "Other",
      // link: "MSE1-13",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-FIRSTSIDE",
      subtopic_name: "Mood",
    },
    //?========================================================================================
    /*                                                                                      *
     *                                       Left SIDE MSE.2                                *
     *                                                                                      */
    //?========================================================================================
    {
      id: "MSE.2.24",
      shortname: "mse2-11",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Mood Congruent"],
      act: "MSE2-11",
      depending: "",
      exception: "",
      link: "MSE2-12",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-SECONDSIDE",
      subtopic_name: "Affect",
    },
    {
      id: "MSE.2.25",
      extend: "true",
      shortname: "mse2-12",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Normal Range and Modulation"],
      act: "MSE2-12",
      depending: "",
      exception: "",
      link: "MSE2-13",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-SECONDSIDE",
      subtopic_name: "Affect",
    },
    {
      id: "MSE.2.26",
      extend: "true",
      shortname: "mse2-13",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Flat/Blunted"],
      act: "MSE2-13",
      depending: "",
      exception: "",
      link: "MSE2-14",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-SECONDSIDE",
      subtopic_name: "Affect",
    },
    {
      id: "MSE.2.27",
      extend: "true",
      shortname: "mse2-14",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Restricted Range"],
      act: "MSE2-14",
      depending: "",
      exception: "",
      link: "MSE2-15",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-SECONDSIDE",
      subtopic_name: "Affect",
    },
    {
      id: "MSE.2.28",
      extend: "true",
      shortname: "mse2-15",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Fearful/Anxious"],
      act: "MSE2-15",
      depending: "",
      exception: "",
      link: "MSE2-16",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-SECONDSIDE",
      subtopic_name: "Affect",
    },
    {
      id: "MSE.2.29",
      extend: "true",
      shortname: "mse2-16",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Sad/Depressed"],
      act: "MSE2-16",
      depending: "",
      exception: "",
      link: "MSE2-17",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-SECONDSIDE",
      subtopic_name: "Affect",
    },
    {
      id: "MSE.2.30",
      extend: "true",
      shortname: "mse2-17",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Embarrassment/Shame"],
      act: "MSE2-17",
      depending: "",
      exception: "",
      link: "MSE2-18",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-SECONDSIDE",
      subtopic_name: "Affect",
    },
    {
      id: "MSE.2.31",
      extend: "true",
      shortname: "mse2-18",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Hostile/Aggressive"],
      act: "MSE2-18",
      depending: "",
      exception: "",
      link: "MSE2-19",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-SECONDSIDE",
      subtopic_name: "Affect",
    },
    {
      id: "MSE.2.32",
      extend: "true",
      shortname: "mse2-19",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Anger/Rage"],
      act: "MSE2-19",
      depending: "",
      exception: "",
      link: "MSE2-20",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-SECONDSIDE",
      subtopic_name: "Affect",
    },
    {
      id: "MSE.2.33",
      extend: "true",
      shortname: "mse2-20",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Guilty"],
      act: "MSE2-20",
      depending: "",
      exception: "",
      link: "MSE2-21",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-SECONDSIDE",
      subtopic_name: "Affect",
    },
    {
      id: "MSE.2.34",
      extend: "true",
      shortname: "mse2-21",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other"],
      act: "MSE2-21",
      depending: "",
      exception: "",
      link: "MSE2-21-1",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-SECONDSIDE",
      subtopic_name: "Affect",
    },
    {
      id: "MSE.2.35.1",
      extend: "true",
      shortname: "mse2-21-1",
      name: "",
      prevalue: "Explain:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MSE2-21-1",
      depending: "MSE2-21",
      exception: "Other",
      // link: "MSE1-13",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-SECONDSIDE",
      subtopic_name: "Affect",
    },
    //?========================================================================================
    /*                                                                                      *
     *                                       Third SIDE MSE.2                                *
     *                                                                                      */
    //?========================================================================================
    {
      id: "MSE.2.36",
      shortname: "mse2-22",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Normal Rate"],
      act: "MSE2-22",
      depending: "",
      exception: "",
      link: "MSE2-23",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-THIRDSIDE",
      subtopic_name: "Speech",
    },
    {
      id: "MSE.2.37",
      extend: "true",
      shortname: "mse2-23",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Normal Amplitude"],
      act: "MSE2-23",
      depending: "",
      exception: "",
      link: "MSE2-29-NORM",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-THIRDSIDE",
      subtopic_name: "Speech",
    },
    {
      id: "MSE.2.38",
      extend: "true",
      shortname: "mse2-29-norm",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Normal Prosody"],
      act: "MSE2-29-NORM",
      depending: "",
      exception: "",
      link: "MSE2-29",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-THIRDSIDE",
      subtopic_name: "Speech",
    },
    {
      id: "MSE.2.39",
      extend: "true",
      shortname: "mse2-29",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Abnormal Prosody"],
      act: "MSE2-29",
      depending: "",
      exception: "",
      link: "MSE2-24",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-THIRDSIDE",
      subtopic_name: "Speech",
    },
    {
      id: "MSE.2.40",
      extend: "true",
      shortname: "mse2-24",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Rapid/Pressured"],
      act: "MSE2-24",
      depending: "",
      exception: "",
      link: "MSE2-25",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-THIRDSIDE",
      subtopic_name: "Speech",
    },
    {
      id: "MSE.2.41",
      extend: "true",
      shortname: "mse2-25",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Slow"],
      act: "MSE2-25",
      depending: "",
      exception: "",
      link: "MSE2-26",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-THIRDSIDE",
      subtopic_name: "Speech",
    },
    {
      id: "MSE.2.42",
      extend: "true",
      shortname: "mse2-26",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Loud"],
      act: "MSE2-26",
      depending: "",
      exception: "",
      link: "MSE2-27",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-THIRDSIDE",
      subtopic_name: "Speech",
    },
    {
      id: "MSE.2.43",
      extend: "true",
      shortname: "mse2-27",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Soft"],
      act: "MSE2-27",
      depending: "",
      exception: "",
      link: "MSE2-28",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-THIRDSIDE",
      subtopic_name: "Speech",
    },
    {
      id: "MSE.2.44",
      extend: "true",
      shortname: "mse2-28",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Slurred"],
      act: "MSE2-28",
      depending: "",
      exception: "",
      link: "MSE2-30",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-THIRDSIDE",
      subtopic_name: "Speech",
    },
    {
      id: "MSE.2.45",
      extend: "true",
      shortname: "mse2-30",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Increased Latency"],
      act: "MSE2-30",
      depending: "",
      exception: "",
      link: "MSE2-31",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-THIRDSIDE",
      subtopic_name: "Speech",
    },
    {
      id: "MSE.2.46",
      extend: "true",
      shortname: "mse2-31",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Dysarthria"],
      act: "MSE2-31",
      depending: "",
      exception: "",
      link: "MSE2-32",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-THIRDSIDE",
      subtopic_name: "Speech",
    },
    {
      id: "MSE.2.47",
      extend: "true",
      shortname: "mse2-32",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Poverty of Speech"],
      act: "MSE2-32",
      depending: "",
      exception: "",
      link: "MSE2-33",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-THIRDSIDE",
      subtopic_name: "Speech",
    },
    {
      id: "MSE.2.48",
      extend: "true",
      shortname: "mse2-33",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other"],
      act: "MSE2-33",
      depending: "",
      exception: "",
      link: "MSE2-33-1",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-THIRDSIDE",
      subtopic_name: "Speech",
    },
    {
      id: "MSE.2.48.1",
      extend: "true",
      shortname: "mse2-33-1",
      name: "",
      prevalue: "Explain:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MSE2-33-1",
      depending: "MSE2-33",
      exception: "Other",
      // link: "MSE1-13",
      subtopic_container_act: "MSE2-SUBTOPIC",
      suptopic_code: "MSE2-THIRDSIDE",
      subtopic_name: "Speech",
    },
    //!========================================================================================
    /*                                                                                      *
     *                                         MSE.3                                        *
     *                                                                                      */
    //!========================================================================================
    {
      id: "MSE.3",
      shortname: "mse3-subtopic",
      name:
        "Thought content/Sensorium/Perceptual disorders/Hallucinations/Orientation",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "MSE3-SUBTOPIC",
      depending: "",
      exception: "",
    },
    //========================================================================================
    /*                                                                                      *
     *                                    Thought content                                   *
     *                                                                                      */
    //========================================================================================
    {
      id: "MSE.3.1",
      // extend: "true",
      shortname: "mse3-7",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["No Abnormal Content"],
      act: "MSE3-7",
      depending: "",
      exception: "",
      link: "MSE3-8",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    {
      id: "MSE.3.2",
      extend: "true",
      shortname: "mse3-8",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Future Oriented"],
      act: "MSE3-8",
      depending: "",
      exception: "",
      link: "MSE3-9",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    {
      id: "MSE.3.3",
      extend: "true",
      shortname: "mse3-9",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Hopeless"],
      act: "MSE3-9",
      depending: "",
      exception: "",
      link: "MSE3-10",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    {
      id: "MSE.3.4",
      extend: "true",
      shortname: "mse3-10",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Helpless"],
      act: "MSE3-10",
      depending: "",
      exception: "",
      link: "MSE3-11",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    {
      id: "MSE.3.5",
      extend: "true",
      shortname: "mse3-11",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Suicidal Ideation"],
      act: "MSE3-11",
      depending: "",
      exception: "",
      link: "MSE3-12",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    {
      id: "MSE.3.6",
      extend: "true",
      shortname: "mse3-12",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Homicidal Ideation"],
      act: "MSE3-12",
      depending: "",
      exception: "",
      link: "MSE3-13",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    {
      id: "MSE.3.7",
      extend: "true",
      shortname: "mse3-13",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["No Suicidal Ideation"],
      act: "MSE3-13",
      depending: "",
      exception: "",
      link: "MSE3-14",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    {
      id: "MSE.3.8",
      extend: "true",
      shortname: "mse3-14",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["No Homicidal Ideation"],
      act: "MSE3-14",
      depending: "",
      exception: "",
      link: "MSE3-15",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    {
      id: "MSE.3.9",
      extend: "true",
      shortname: "mse3-15",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Inflated Confidence/Grandiose"],
      act: "MSE3-15",
      depending: "",
      exception: "",
      link: "MSE3-16",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    {
      id: "MSE.3.10",
      extend: "true",
      shortname: "mse3-16",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Poor Confidence/Esteem"],
      act: "MSE3-16",
      depending: "",
      exception: "",
      link: "MSE3-17",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    {
      id: "MSE.3.11",
      extend: "true",
      shortname: "mse3-17",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Obsessions/Compulsions"],
      act: "MSE3-17",
      depending: "",
      exception: "",
      link: "MSE3-18",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    {
      id: "MSE.3.12",
      extend: "true",
      shortname: "mse3-18",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Phobias"],
      act: "MSE3-18",
      depending: "",
      exception: "",
      link: "MSE3-19",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    {
      id: "MSE.3.13",
      extend: "true",
      shortname: "mse3-19",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Poor Body Image"],
      act: "MSE3-19",
      depending: "",
      exception: "",
      link: "MSE3-20",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    {
      id: "MSE.3.14",
      extend: "true",
      shortname: "mse3-20",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Delusional"],
      act: "MSE3-20",
      depending: "",
      exception: "",
      link: "MSE3-21",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    // {
    //   id: "MSE.3.15",
    //   extend: "true",
    //   shortname: "mse3-21",
    //   name: "",
    //   prevalue: "",
    //   value: [false],
    //   disabled: "false",
    //   type: "checkbox",
    //   radio_options: ["Hallucinations"],
    //   act: "MSE3-21",
    //   depending: "",
    //   exception: "",
    //   link: "MSE3-22",
    //   subtopic_container_act: "MSE3-SUBTOPIC",
    //   suptopic_code: "MSE3-THOUGHT-CONTENT",
    //   subtopic_name: "Thought content",
    // },
    {
      id: "MSE.3.15",
      extend: "true",
      shortname: "mse3-22",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Paranoia/Suspicious"],
      act: "MSE3-22",
      depending: "",
      exception: "",
      link: "MSE3-23",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    {
      id: "MSE.3.16",
      extend: "true",
      shortname: "mse3-23",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Ruminations"],
      act: "MSE3-23",
      depending: "",
      exception: "",
      link: "MSE3-24",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    {
      id: "MSE.3.17",
      extend: "true",
      shortname: "mse3-24",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Perseverations"],
      act: "MSE3-24",
      depending: "",
      exception: "",
      link: "MSE3-25",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    {
      id: "MSE.3.18",
      extend: "true",
      shortname: "mse3-25",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Poverty of Content"],
      act: "MSE3-25",
      depending: "",
      exception: "",
      link: "MSE3-26",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    {
      id: "MSE.3.19",
      extend: "true",
      shortname: "mse3-26",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other"],
      act: "MSE3-26",
      depending: "",
      exception: "",
      link: "MSE3-26-1",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    {
      id: "MSE.3.19.1",
      extend: "true",
      shortname: "mse3-26-1",
      name: "",
      prevalue: "Explain:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MSE3-26-1",
      depending: "MSE3-26",
      exception: "Other",
      // link: "MSE1-13",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-THOUGHT-CONTENT",
      subtopic_name: "Thought content",
    },
    //========================================================================================
    /*                                                                                      *
     *                                    Sensorium                                   *
     *                                                                                      */
    //========================================================================================
    {
      id: "MSE.3.20",
      // extend: "true",
      shortname: "mse3-27",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Alert/Awake"],
      act: "MSE3-27",
      depending: "",
      exception: "",
      link: "MSE3-28",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-SENSORIUM-CONTENT",
      subtopic_name: "Sensorium",
    },
    {
      id: "MSE.3.21",
      extend: "true",
      shortname: "mse3-28",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Drowsy"],
      act: "MSE3-28",
      depending: "",
      exception: "",
      link: "MSE3-29",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-SENSORIUM-CONTENT",
      subtopic_name: "Sensorium",
    },
    {
      id: "MSE.3.22",
      extend: "true",
      shortname: "mse3-29",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Fluctuating"],
      act: "MSE3-29",
      depending: "",
      exception: "",
      link: "MSE3-30",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-SENSORIUM-CONTENT",
      subtopic_name: "Sensorium",
    },
    {
      id: "MSE.3.23",
      extend: "true",
      shortname: "mse3-30",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Confused"],
      act: "MSE3-30",
      depending: "",
      exception: "",
      link: "MSE3-31",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-SENSORIUM-CONTENT",
      subtopic_name: "Sensorium",
    },
    {
      id: "MSE.3.24",
      extend: "true",
      shortname: "mse3-31",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Clouded"],
      act: "MSE3-31",
      depending: "",
      exception: "",
      link: "MSE3-39",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-SENSORIUM-CONTENT",
      subtopic_name: "Sensorium",
    },

    {
      id: "MSE.3.25",
      extend: "true",
      shortname: "mse3-39",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other"],
      act: "MSE3-39",
      depending: "",
      exception: "",
      link: "MSE3-39-1",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-SENSORIUM-CONTENT",
      subtopic_name: "Sensorium",
    },
    {
      id: "MSE.3.25.1",
      extend: "true",
      shortname: "mse3-39-1",
      name: "",
      prevalue: "Explain:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MSE3-39-1",
      depending: "MSE3-39",
      exception: "Other",
      // link: "MSE3-39",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-SENSORIUM-CONTENT",
      subtopic_name: "Sensorium",
    },
    //========================================================================================
    /*                                                                                      *
     *                                 Perceptual disorders/Hallucinations                  *
     *                                                                                      */
    //========================================================================================
    /*      {
        id: "MSE.3.34",
        // extend: "true",
        shortname: "mse3-1",
        name: "",
        prevalue: "",
        value: [false],
        disabled: "false",
        type: "checkbox",
        radio_options: ["Indent"],   // Hallucinations
        act: "MSE3-1",
        depending: "",
        exception: "",
        link: "MSE3-2",
        subtopic_container_act: "MSE3-SUBTOPIC",
        suptopic_code: "MSE3-PERCEPTUAL-DISORDERS",
        subtopic_name: "Perceptual disorders/Hallucinations",
      },
*/
    {
      id: "MSE.3.26",
      // extend: "true",
      shortname: "mse3-2-none",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["None"],
      act: "MSE3-2-NONE",
      depending: "",
      exception: "",
      link: "MSE3-2",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-PERCEPTUAL-DISORDERS",
      subtopic_name: "Perceptual disorders/Hallucinations",
    },
    {
      id: "MSE.3.27",
      extend: "true",
      shortname: "mse3-2",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Auditory"],
      act: "MSE3-2",
      depending: "",
      exception: "",
      link: "MSE3-3",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-PERCEPTUAL-DISORDERS",
      subtopic_name: "Perceptual disorders/Hallucinations",
    },
    {
      id: "MSE.3.28",
      extend: "true",
      shortname: "mse3-3",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Visual"],
      act: "MSE3-3",
      depending: "",
      exception: "",
      link: "MSE3-4",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-PERCEPTUAL-DISORDERS",
      subtopic_name: "Perceptual disorders/Hallucinations",
    },
    {
      id: "MSE.3.29",
      extend: "true",
      shortname: "mse3-4",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Tactile (Haptic)"],
      act: "MSE3-4",
      depending: "",
      exception: "",
      link: "MSE3-5",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-PERCEPTUAL-DISORDERS",
      subtopic_name: "Perceptual disorders/Hallucinations",
    },
    {
      id: "MSE.3.30",
      extend: "true",
      shortname: "mse3-5",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Olfactory"],
      act: "MSE3-5",
      depending: "",
      exception: "",
      link: "MSE3-6",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-PERCEPTUAL-DISORDERS",
      subtopic_name: "Perceptual disorders/Hallucinations",
    },
    {
      id: "MSE.3.31",
      extend: "true",
      shortname: "mse3-6",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other"],
      act: "MSE3-6",
      depending: "",
      exception: "",
      link: "MSE3-6-1",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-PERCEPTUAL-DISORDERS",
      subtopic_name: "Perceptual disorders/Hallucinations",
    },
    {
      id: "MSE.3.31.1",
      extend: "true",
      shortname: "mse3-6-1",
      name: "",
      prevalue: "Explain:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MSE3-6-1",
      depending: "MSE3-6",
      exception: "Other",
      // link: "MSE1-13",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-PERCEPTUAL-DISORDERS",
      subtopic_name: "Perceptual disorders/Hallucinations",
    },
    //========================================================================================
    /*                                                                                      *
     *                                 Orientation                                          *
     *                                                                                      */
    //========================================================================================
    {
      id: "MSE.3.32",
      // extend: "true",
      shortname: "mse3-40",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Ox4 (Time, Place, Person, Situation)"],
      act: "MSE3-40",
      depending: "",
      exception: "",
      link: "MSE3-41",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-ORIENTATION",
      subtopic_name: "Orientation",
    },
    {
      id: "MSE.3.33",
      extend: "true",
      shortname: "mse3-41",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Ox3 (Time, Place, Person)"],
      act: "MSE3-41",
      depending: "",
      exception: "",
      link: "MSE3-42",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-ORIENTATION",
      subtopic_name: "Orientation",
    },
    {
      id: "MSE.3.34",
      extend: "true",
      shortname: "mse3-42",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Impaired"],
      act: "MSE3-42",
      depending: "",
      exception: "",
      // link: "MSE3-34",
      subtopic_container_act: "MSE3-SUBTOPIC",
      suptopic_code: "MSE3-ORIENTATION",
      subtopic_name: "Orientation",
    },
    /*
      {
        id: "MSE.3.26",
        extend: "true",
        shortname: "mse3-32",
        name: "",
        prevalue: "",
        value: [false],
        disabled: "false",
        type: "checkbox",
        radio_options: ["Ox4"],
        act: "MSE3-32",
        depending: "",
        exception: "",
        link: "MSE3-33",
        subtopic_container_act: "MSE3-SUBTOPIC",
        suptopic_code: "MSE3-ORIENTATION",
        subtopic_name: "Orientation",
      },
      {
        id: "MSE.3.27",
        extend: "true",
        shortname: "mse3-33",
        name: "",
        prevalue: "",
        value: [false],
        disabled: "false",
        type: "checkbox",
        radio_options: ["Ox3"],
        act: "MSE3-33",
        depending: "",
        exception: "",
        link: "MSE3-34",
        subtopic_container_act: "MSE3-SUBTOPIC",
        suptopic_code: "MSE3-ORIENTATION",
        subtopic_name: "Orientation",
      },
*/
    /*
      {
        id: "MSE.3.35",
        extend: "true",
        shortname: "mse3-34",
        name: "",
        prevalue: "",
        value: [false],
        disabled: "false",
        type: "checkbox",
        radio_options: ["Time"],
        act: "MSE3-34",
        depending: "",
        exception: "",
        link: "MSE3-35",
        subtopic_container_act: "MSE3-SUBTOPIC",
        suptopic_code: "MSE3-ORIENTATION",
        subtopic_name: "Orientation",
      },
      {
        id: "MSE.3.36",
        extend: "true",
        shortname: "mse3-35",
        name: "",
        prevalue: "",
        value: [false],
        disabled: "false",
        type: "checkbox",
        radio_options: ["Place"],
        act: "MSE3-35",
        depending: "",
        exception: "",
        link: "MSE3-36",
        subtopic_container_act: "MSE3-SUBTOPIC",
        suptopic_code: "MSE3-ORIENTATION",
        subtopic_name: "Orientation",
      },
      {
        id: "MSE.3.37",
        extend: "true",
        shortname: "mse3-36",
        name: "",
        prevalue: "",
        value: [false],
        disabled: "false",
        type: "checkbox",
        radio_options: ["Person"],
        act: "MSE3-36",
        depending: "",
        exception: "",
        link: "MSE3-37",
        subtopic_container_act: "MSE3-SUBTOPIC",
        suptopic_code: "MSE3-ORIENTATION",
        subtopic_name: "Orientation",
      },
      {
        id: "MSE.3.38",
        extend: "true",
        shortname: "mse3-37",
        name: "",
        prevalue: "",
        value: [false],
        disabled: "false",
        type: "checkbox",
        radio_options: ["Situation"],
        act: "MSE3-37",
        depending: "",
        exception: "",
        // link: "MSE3-38",
        subtopic_container_act: "MSE3-SUBTOPIC",
        suptopic_code: "MSE3-ORIENTATION",
        subtopic_name: "Orientation",
      },
*/
    /*
      {
        id: "MSE.3.32",
        extend: "true",
        shortname: "mse3-38",
        name: "",
        prevalue: "",
        value: [false],
        disabled: "false",
        type: "checkbox",
        radio_options: ["Impaired"],
        act: "MSE3-38",
        depending: "",
        exception: "",
        link: "MSE3-38-1",
        subtopic_container_act: "MSE3-SUBTOPIC",
        suptopic_code: "MSE3-ORIENTATION",
        subtopic_name: "Orientation",
      },
      {
        id: "MSE.3.32.1",
        extend: "true",
        shortname: "mse3-38-1",
        name: "",
        prevalue: "Explain:",
        value: "",
        disabled: "true",
        type: "text",
        act: "MSE3-38-1",
        depending: "MSE3-38",
        exception: "Impaired",
        // link: "MSE3-39",
        subtopic_container_act: "MSE3-SUBTOPIC",
        suptopic_code: "MSE3-ORIENTATION",
        subtopic_name: "Orientation",
      },
*/
    //!========================================================================================
    /*                                                                                      *
     *                                        Memory                                        *
     *                                                                                      */
    //!========================================================================================
    {
      id: "MSE.4",
      shortname: "mse4-subtopic",
      // name:"Remote Memory/Recent Memory/Immediate Memory/Concentration and calculation",
      name: "Memory",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "MSE4-SUBTOPIC",
      depending: "",
      exception: "",
    },
    //========================================================================================
    /*                                                                                      *
     *                                   Immediate Memory                                   *
     *                                                                                      */
    //========================================================================================
    {
      id: "MSE.4.1",
      // extend: "true",
      shortname: "mse4-9",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Repeat 6 Digits Forward"],
      act: "MSE4-9",
      depending: "",
      exception: "",
      link: "MSE4-10",
      subtopic_container_act: "MSE4-SUBTOPIC",
      suptopic_code: "MSE4-IMMEDIATE-MEMORY",
      subtopic_name: "Immediate Memory",
    },
    {
      id: "MSE.4.2",
      extend: "true",
      shortname: "mse4-10",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Repeat 6 Digits Backward"],
      act: "MSE4-10",
      depending: "",
      exception: "",
      link: "MSE4-11",
      subtopic_container_act: "MSE4-SUBTOPIC",
      suptopic_code: "MSE4-IMMEDIATE-MEMORY",
      subtopic_name: "Immediate Memory",
    },
    {
      id: "MSE.4.3",
      extend: "true",
      shortname: "mse4-11",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Recall 3 objects immediately"],
      act: "MSE4-11",
      depending: "",
      exception: "",
      link: "MSE4-12",
      subtopic_container_act: "MSE4-SUBTOPIC",
      suptopic_code: "MSE4-IMMEDIATE-MEMORY",
      subtopic_name: "Immediate Memory",
    },
    {
      id: "MSE.4.4",
      extend: "true",
      shortname: "mse4-12",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Recall 3 objects after 5 minutes"],
      act: "MSE4-12",
      depending: "",
      exception: "",
      // link: "MSE4-5",
      subtopic_container_act: "MSE4-SUBTOPIC",
      suptopic_code: "MSE4-IMMEDIATE-MEMORY",
      subtopic_name: "Immediate Memory",
    },
    //========================================================================================
    /*                                                                                      *
     *                           Recent Memory (Short-term Memory)                          *
     *                                                                                      */
    //========================================================================================
    {
      id: "MSE.4.5",
      // extend: "true",
      shortname: "mse4-5",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Intact"],
      act: "MSE4-5",
      depending: "",
      exception: "",
      link: "MSE4-6",
      subtopic_container_act: "MSE4-SUBTOPIC",
      suptopic_code: "MSE4-RECENT-MEMORY",
      subtopic_name: "Recent Memory",
    },
    {
      id: "MSE.4.6",
      extend: "true",
      shortname: "mse4-6",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Sporadic"],
      act: "MSE4-6",
      depending: "",
      exception: "",
      link: "MSE4-7",
      subtopic_container_act: "MSE4-SUBTOPIC",
      suptopic_code: "MSE4-RECENT-MEMORY",
      subtopic_name: "Recent Memory",
    },
    {
      id: "MSE.4.7",
      extend: "true",
      shortname: "mse4-7",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Poor"],
      act: "MSE4-7",
      depending: "",
      exception: "",
      link: "MSE4-8",
      subtopic_container_act: "MSE4-SUBTOPIC",
      suptopic_code: "MSE4-RECENT-MEMORY",
      subtopic_name: "Recent Memory",
    },
    {
      id: "MSE.4.8",
      extend: "true",
      shortname: "mse4-8",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Impaired"],
      act: "MSE4-8",
      depending: "",
      exception: "",
      // link: "MSE4-5",
      subtopic_container_act: "MSE4-SUBTOPIC",
      suptopic_code: "MSE4-RECENT-MEMORY",
      subtopic_name: "Recent Memory",
    },
    //========================================================================================
    /*                                                                                      *
     *                           Remote Memory (Long-term memory)                           *
     *                                                                                      */
    //========================================================================================
    {
      id: "MSE.4.9",
      // extend: "true",
      shortname: "mse4-1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Intact"],
      act: "MSE4-1",
      depending: "",
      exception: "",
      link: "MSE4-2",
      subtopic_container_act: "MSE4-SUBTOPIC",
      suptopic_code: "MSE4-REMOTE-MEMORY",
      subtopic_name: "Remote Memory",
    },
    {
      id: "MSE.4.10",
      extend: "true",
      shortname: "mse4-2",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Sporadic"],
      act: "MSE4-2",
      depending: "",
      exception: "",
      link: "MSE4-3",
      subtopic_container_act: "MSE4-SUBTOPIC",
      suptopic_code: "MSE4-REMOTE-MEMORY",
      subtopic_name: "Remote Memory",
    },
    {
      id: "MSE.4.11",
      extend: "true",
      shortname: "mse4-3",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Poor"],
      act: "MSE4-3",
      depending: "",
      exception: "",
      link: "MSE4-4",
      subtopic_container_act: "MSE4-SUBTOPIC",
      suptopic_code: "MSE4-REMOTE-MEMORY",
      subtopic_name: "Remote Memory",
    },
    {
      id: "MSE.4.12",
      extend: "true",
      shortname: "mse4-4",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Impaired"],
      act: "MSE4-4",
      depending: "",
      exception: "",
      // link: "MSE4-5",
      subtopic_container_act: "MSE4-SUBTOPIC",
      suptopic_code: "MSE4-REMOTE-MEMORY",
      subtopic_name: "Remote Memory",
    },
    //========================================================================================
    /*                                                                                      *
     *                             Concentration and calculation                            *
     *                                                                                      */
    //========================================================================================

    {
      id: "MSE.4.13",
      // extend: "true",
      shortname: "mse4-13",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Can count from 1-20 rapidly without error"],
      act: "MSE4-13",
      depending: "",
      exception: "",
      link: "MSE4-14",
      subtopic_container_act: "MSE4-SUBTOPIC",
      suptopic_code: "MSE4-CONCENTRATION-CALCULATION",
      subtopic_name: "Concentration and calculation",
    },
    {
      id: "MSE.4.14",
      extend: "true",
      shortname: "mse4-14",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Can do simple addition and subtraction"],
      act: "MSE4-14",
      depending: "",
      exception: "",
      link: "MSE4-15",
      subtopic_container_act: "MSE4-SUBTOPIC",
      suptopic_code: "MSE4-CONCENTRATION-CALCULATION",
      subtopic_name: "Concentration and calculation",
    },
    {
      id: "MSE.4.15",
      extend: "true",
      shortname: "mse4-15",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Serial 7s<br />(100-93-86-79-72-65)"],
      act: "MSE4-15",
      depending: "",
      exception: "",
      link: "MSE4-15-1",
      subtopic_container_act: "MSE4-SUBTOPIC",
      suptopic_code: "MSE4-CONCENTRATION-CALCULATION",
      subtopic_name: "Concentration and calculation",
    },
    {
      id: "MSE.4.15.1",
      extend: "true",
      shortname: "mse4-15-1",
      name: "",
      prevalue: "Number of correct answers:",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0 out of 5",
        "1 out of 5",
        "2 out of 5",
        "3 out of 5",
        "4 out of 5",
        "5 out of 5",
      ],
      act: "MSE4-15-1",
      depending: "MSE4-15",
      exception: "Serial 7s<br />(100-93-86-79-72-65)",
      subtopic_container_act: "MSE4-SUBTOPIC",
      suptopic_code: "MSE4-CONCENTRATION-CALCULATION",
      subtopic_name: "Concentration and calculation",
    },
    //!========================================================================================
    /*                                                                                      *
     *                                        Intelligence                                  *
     *                                                                                      */
    //!========================================================================================
    {
      id: "MSE.5",
      shortname: "mse5-subtopic",
      name: "Information/Intelligence/Abstract Thinking/Insight/Judgement",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "MSE5-SUBTOPIC",
      depending: "",
      exception: "",
    },
    //========================================================================================
    /*                                                                                      *
     *                            Information:                                              *
     *                                                                                      */
    //========================================================================================
    {
      id: "MSE.5.1",
      // extend: "true",
      shortname: "mse5-1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["General knowledge Intact"],
      act: "MSE5-1",
      depending: "",
      exception: "",
      link: "MSE5-2",
      subtopic_container_act: "MSE5-SUBTOPIC",
      suptopic_code: "MSE5-INFORMATION",
      subtopic_name: "Information",
    },
    {
      id: "MSE.5.2",
      extend: "true",
      shortname: "mse5-2",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["General knowledge Impaired"],
      act: "MSE5-2",
      depending: "",
      exception: "",
      // link: "MSE5-3",
      subtopic_container_act: "MSE5-SUBTOPIC",
      suptopic_code: "MSE5-INFORMATION",
      subtopic_name: "Information",
    },
    // {
    //   id: "MSE.5.3",
    //   extend: "true",
    //   shortname: "mse5-3",
    //   name: "",
    //   prevalue: "",
    //   value: [false],
    //   disabled: "false",
    //   type: "checkbox",
    //   radio_options: ["Estimated IQ"],
    //   act: "MSE5-3",
    //   depending: "",
    //   exception: "",
    //   link: "MSE5-4",
    //   subtopic_container_act: "MSE5-SUBTOPIC",
    //   suptopic_code: "MSE5-INFORMATION-INTELLIGENCE",
    //   subtopic_name: "Information and intelligence",
    // },
    //========================================================================================
    /*                                                                                      *
     *                            intelligence:                                             *
     *                                                                                      */
    //========================================================================================

    {
      id: "MSE.5.3",
      // extend: "true",
      shortname: "mse5-4",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Below Average"],
      act: "MSE5-4",
      depending: "",
      exception: "",
      link: "MSE5-5",
      subtopic_container_act: "MSE5-SUBTOPIC",
      suptopic_code: "MSE5-INTELLIGENCE",
      subtopic_name: "Intelligence",
    },
    {
      id: "MSE.5.4",
      extend: "true",
      shortname: "mse5-5",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Average"],
      act: "MSE5-5",
      depending: "",
      exception: "",
      link: "MSE5-6",
      subtopic_container_act: "MSE5-SUBTOPIC",
      suptopic_code: "MSE5-INTELLIGENCE",
      subtopic_name: "Intelligence",
    },
    {
      id: "MSE.5.5",
      extend: "true",
      shortname: "mse5-6",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Above Average"],
      act: "MSE5-6",
      depending: "",
      exception: "",
      link: "MSE5-7",
      subtopic_container_act: "MSE5-SUBTOPIC",
      suptopic_code: "MSE5-INTELLIGENCE",
      subtopic_name: "Intelligence",
    },
    {
      id: "MSE.5.6",
      extend: "true",
      shortname: "mse5-7",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Superior"],
      act: "MSE5-7",
      depending: "",
      exception: "",
      // link: "MSE5-8",
      subtopic_container_act: "MSE5-SUBTOPIC",
      suptopic_code: "MSE5-INTELLIGENCE",
      subtopic_name: "Intelligence",
    },
    //========================================================================================
    /*                                                                                      *
     *                                   Abstract Thinking                                  *
     *                                                                                      */
    //========================================================================================
    {
      id: "MSE.5.8",
      // extend: "true",
      shortname: "mse5-13",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Abstract"],
      act: "MSE5-13",
      depending: "",
      exception: "",
      link: "MSE5-14",
      subtopic_container_act: "MSE5-SUBTOPIC",
      suptopic_code: "MSE5-ABSTRACT-THINKING",
      subtopic_name: "Abstract Thinking",
    },
    {
      id: "MSE.5.9",
      extend: "true",
      shortname: "mse5-14",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Concrete"],
      act: "MSE5-14",
      depending: "",
      exception: "",
      // link: "MSE5-15",
      subtopic_container_act: "MSE5-SUBTOPIC",
      suptopic_code: "MSE5-ABSTRACT-THINKING",
      subtopic_name: "Abstract Thinking",
    },
    //========================================================================================
    /*                                                                                      *
     *                                   Insight/Judgment                                   *
     *                                                                                      */
    //========================================================================================

    {
      id: "MSE.5.10",
      // extend: "true",
      shortname: "mse5-8",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Intact"],
      act: "MSE5-8",
      depending: "",
      exception: "",
      link: "MSE5-9",
      subtopic_container_act: "MSE5-SUBTOPIC",
      suptopic_code: "MSE5-INSIGHT-JUDGMENT",
      subtopic_name: "Insight/Judgment",
    },
    {
      id: "MSE.5.11",
      extend: "true",
      shortname: "mse5-9",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Poor Insight"],
      act: "MSE5-9",
      depending: "",
      exception: "",
      link: "MSE5-10",
      subtopic_container_act: "MSE5-SUBTOPIC",
      suptopic_code: "MSE5-INSIGHT-JUDGMENT",
      subtopic_name: "Insight/Judgment",
    },
    {
      id: "MSE.5.12",
      extend: "true",
      shortname: "mse5-10",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Impaired Judgment"],
      act: "MSE5-10",
      depending: "",
      exception: "",
      link: "MSE5-11",
      subtopic_container_act: "MSE5-SUBTOPIC",
      suptopic_code: "MSE5-INSIGHT-JUDGMENT",
      subtopic_name: "Insight/Judgment",
    },

    {
      id: "MSE.5.13",
      extend: "true",
      shortname: "mse5-11",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Poor Impulse Control"],
      act: "MSE5-11",
      depending: "",
      exception: "",
      link: "MSE5-12",
      subtopic_container_act: "MSE5-SUBTOPIC",
      suptopic_code: "MSE5-INSIGHT-JUDGMENT",
      subtopic_name: "Insight/Judgment",
    },

    {
      id: "MSE.5.14",
      extend: "true",
      shortname: "mse5-12",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other"],
      act: "MSE5-12",
      depending: "",
      exception: "",
      link: "MSE5-12-1",
      subtopic_container_act: "MSE5-SUBTOPIC",
      suptopic_code: "MSE5-INSIGHT-JUDGMENT",
      subtopic_name: "Insight/Judgment",
    },
    {
      id: "MSE.5.14.1",
      extend: "true",
      shortname: "mse5-12-1",
      name: "",
      prevalue: "Explain:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MSE5-12-1",
      depending: "MSE5-12",
      exception: "Other",
      // link: "MSE5-13",
      subtopic_container_act: "MSE5-SUBTOPIC",
      suptopic_code: "MSE5-INSIGHT-JUDGMENT",
      subtopic_name: "Insight/Judgment",
    },
    //!========================================================================================
    /*                                                                                      *
     *                           physical condition of the patient                          *
     *                                                                                      */
    //!========================================================================================
    {
      id: "MSE.6",
      shortname: "mse6-subtopic",
      name: "Gait/Station/Muscle Strength/Muscle Tone/Other",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "MSE6-SUBTOPIC",
      depending: "",
      exception: "",
    },
    //========================================================================================
    /*                                                                                      *
     *                            Gait/Station                            *
     *                                                                                      */
    //========================================================================================
    {
      id: "MSE.6.1",
      // extend: "true",
      shortname: "mse6-1",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Within Normal Limits"],
      act: "MSE6-1",
      depending: "",
      exception: "",
      link: "MSE6-2",
      subtopic_container_act: "MSE6-SUBTOPIC",
      suptopic_code: "MSE6-GAIT-STATION",
      subtopic_name: "Gait/Station",
    },
    {
      id: "MSE.6.2",
      extend: "true",
      shortname: "mse6-2",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Impaired"],
      act: "MSE6-2",
      depending: "",
      exception: "",
      link: "MSE6-2-1",
      subtopic_container_act: "MSE6-SUBTOPIC",
      suptopic_code: "MSE6-GAIT-STATION",
      subtopic_name: "Gait/Station",
    },
    {
      id: "MSE.6.2.1",
      extend: "true",
      shortname: "mse6-2-1",
      name: "",
      prevalue: "Explain:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MSE6-2-1",
      depending: "MSE6-2",
      exception: "Impaired",
      // link: "MSE5-13",
      subtopic_container_act: "MSE6-SUBTOPIC",
      suptopic_code: "MSE6-GAIT-STATION",
      subtopic_name: "Gait/Station",
    },
    //========================================================================================
    /*                                                                                      *
     *                                    Muscle Strength                                   *
     *                                                                                      */
    //========================================================================================
    {
      id: "MSE.6.3",
      // extend: "true",
      shortname: "mse6-3",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Within Normal Limits"],
      act: "MSE6-3",
      depending: "",
      exception: "",
      link: "MSE6-4",
      subtopic_container_act: "MSE6-SUBTOPIC",
      suptopic_code: "MSE6-MUSCLE-STRENGTH",
      subtopic_name: "Muscle Strength",
    },
    {
      id: "MSE.6.4",
      extend: "true",
      shortname: "mse6-4",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Weak"],
      act: "MSE6-4",
      depending: "",
      exception: "",
      link: "MSE6-4-1",
      subtopic_container_act: "MSE6-SUBTOPIC",
      suptopic_code: "MSE6-MUSCLE-STRENGTH",
      subtopic_name: "Muscle Strength",
    },
    {
      id: "MSE.6.4.1",
      extend: "true",
      shortname: "mse6-4-1",
      name: "",
      prevalue: "Explain:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MSE6-4-1",
      depending: "MSE6-4",
      exception: "Weak",
      link: "MSE5-13",
      subtopic_container_act: "MSE6-SUBTOPIC",
      suptopic_code: "MSE6-MUSCLE-STRENGTH",
      subtopic_name: "Muscle Strength",
    },
    {
      id: "MSE.6.5",
      extend: "true",
      shortname: "mse6-5",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other"],
      act: "MSE6-5",
      depending: "",
      exception: "",
      link: "MSE6-5-1",
      subtopic_container_act: "MSE6-SUBTOPIC",
      suptopic_code: "MSE6-MUSCLE-STRENGTH",
      subtopic_name: "Muscle Strength",
    },
    {
      id: "MSE.6.5.1",
      extend: "true",
      shortname: "mse6-5-1",
      name: "",
      prevalue: "Explain:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MSE6-5-1",
      depending: "MSE6-5",
      exception: "Other",
      // link: "MSE5-13",
      subtopic_container_act: "MSE6-SUBTOPIC",
      suptopic_code: "MSE6-MUSCLE-STRENGTH",
      subtopic_name: "Muscle Strength",
    },
    //========================================================================================
    /*                                                                                      *
     *                                      Muscle Tone                                     *
     *                                                                                      */
    //========================================================================================
    {
      id: "MSE.6.6",
      // extend: "true",
      shortname: "mse6-6",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Within Normal Limits"],
      act: "MSE6-6",
      depending: "",
      exception: "",
      link: "MSE6-7",
      subtopic_container_act: "MSE6-SUBTOPIC",
      suptopic_code: "MSE6-MUSCLE-TOME",
      subtopic_name: "Muscle Tone",
    },
    {
      id: "MSE.6.7",
      extend: "true",
      shortname: "mse6-7",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Flaccid"],
      act: "MSE6-7",
      depending: "",
      exception: "",
      link: "MSE6-8",
      subtopic_container_act: "MSE6-SUBTOPIC",
      suptopic_code: "MSE6-MUSCLE-TOME",
      subtopic_name: "Muscle Tone",
    },
    {
      id: "MSE.6.8",
      extend: "true",
      shortname: "mse6-8",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Cod Wheel"],
      act: "MSE6-8",
      depending: "",
      exception: "",
      link: "MSE6-9",
      subtopic_container_act: "MSE6-SUBTOPIC",
      suptopic_code: "MSE6-MUSCLE-TOME",
      subtopic_name: "Muscle Tone",
    },
    {
      id: "MSE.6.9",
      extend: "true",
      shortname: "mse6-9",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Spastic"],
      act: "MSE6-9",
      depending: "",
      exception: "",
      link: "MSE6-10",
      subtopic_container_act: "MSE6-SUBTOPIC",
      suptopic_code: "MSE6-MUSCLE-TOME",
      subtopic_name: "Muscle Tone",
    },
    {
      id: "MSE.6.10",
      extend: "true",
      shortname: "mse6-10",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other"],
      act: "MSE6-10",
      depending: "",
      exception: "",
      link: "MSE6-10-1",
      subtopic_container_act: "MSE6-SUBTOPIC",
      suptopic_code: "MSE6-MUSCLE-TOME",
      subtopic_name: "Muscle Tone",
    },
    {
      id: "MSE.6.10.1",
      extend: "true",
      shortname: "mse6-10-1",
      name: "",
      prevalue: "Explain:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MSE6-10-1",
      depending: "MSE6-10",
      exception: "Other",
      // link: "MSE5-13",
      subtopic_container_act: "MSE6-SUBTOPIC",
      suptopic_code: "MSE6-MUSCLE-TOME",
      subtopic_name: "Muscle Tone",
    },
    //========================================================================================
    /*                                                                                      *
     *                                         Other                                        *
     *                                                                                      */
    //========================================================================================
    {
      id: "MSE.6.11",
      shortname: "mse6-11",
      name: "",
      prevalue: "",
      value: [false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Other"],
      act: "MSE6-11",
      depending: "",
      exception: "",
      link: "MSE6-11-1",
      subtopic_container_act: "MSE6-SUBTOPIC",
      suptopic_code: "MSE6-OTHER",
      subtopic_name: "Other",
    },
    {
      id: "MSE.6.11.1",
      extend: "true",
      shortname: "mse6-11-1",
      name: "",
      prevalue: "Explain:",
      value: "",
      disabled: "true",
      type: "text",
      act: "MSE6-11-1",
      depending: "MSE6-11",
      exception: "Other",
      // link: "MSE5-13",
      subtopic_container_act: "MSE6-SUBTOPIC",
      suptopic_code: "MSE6-OTHER",
      subtopic_name: "Other",
    },
    /*
      {
        id: "MSE.6.11",
        shortname: "mse6-11",
        name: "Other",
        prevalue: "",
        value: "",
        disabled: "false",
        type: "text",
        act: "MSE6-11",
        depending: "",
        exception: "",
        // link: "MSE1-2"
        subtopic_container_act: "MSE6-SUBTOPIC",
        suptopic_code: "MSE6-OTHER",
        subtopic_name: "Other",
      },
*/
    {
      id: "MSE.7.c",
      shortname: "mse7c",
      access: "private",
      name: "Additional comments:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "editor",
      act: "MSE7C",
      // mandatory: "true",
      depending: "",
      exception: "",
    },
  ],
};

export default mseJSON;
