const countryList = [
	{id:0,country:""},
	{id:1,country:"Afghanistan"},
	{id:2,country:"Albania"},
	{id:3,country:"Algeria"},
	{id:4,country:"American Samoa"},
	{id:5,country:"Andorra"},
	{id:6,country:"Angola"},
	{id:7,country:"Anguilla"},
	{id:8,country:"Antarctica"},
	{id:9,country:"Antigua and Barbuda"},
	{id:10,country:"Argentina"},
	{id:11,country:"Armenia"},
	{id:12,country:"Aruba"},
	{id:13,country:"Australia"},
	{id:14,country:"Austria"},
	{id:15,country:"Azerbaijan"},
	{id:16,country:"Bahamas"},
	{id:17,country:"Bahrain"},
	{id:18,country:"Bangladesh"},
	{id:19,country:"Barbados"},
	{id:20,country:"Belarus"},
	{id:21,country:"Belgium"},
	{id:22,country:"Belize"},
	{id:23,country:"Benin"},
	{id:24,country:"Bermuda"},
	{id:25,country:"Bhutan"},
	{id:26,country:"Bolivia"},
	{id:27,country:"Bosnia and Herzegovina"},
	{id:28,country:"Botswana"},
	{id:29,country:"Bouvet Island"},
	{id:30,country:"Brazil"},
	{id:31,country:"British Indian Ocean Territory"},
	{id:32,country:"Brunei Darussalam"},
	{id:33,country:"Bulgaria"},
	{id:34,country:"Burkina Faso"},
	{id:35,country:"Burundi"},
	{id:36,country:"Cambodia"},
	{id:37,country:"Cameroon"},
	{id:38,country:"Canada"},
	{id:39,country:"Cape Verde"},
	{id:40,country:"Cayman Islands"},
	{id:41,country:"Central African Republic"},
	{id:42,country:"Chad"},
	{id:43,country:"Chile"},
	{id:44,country:"China"},
	{id:45,country:"Christmas Island"},
	{id:46,country:"Cocos (Keeling) Islands"},
	{id:47,country:"Colombia"},
	{id:48,country:"Comoros"},
	{id:49,country:"Congo"},
	{id:50,country:"Cook Islands"},
	{id:51,country:"Costa Rica"},
	{id:52,country:"Croatia (Hrvatska)"},
	{id:53,country:"Cuba"},
	{id:54,country:"Cyprus"},
	{id:55,country:"Czech Republic"},
	{id:56,country:"Denmark"},
	{id:57,country:"Djibouti"},
	{id:58,country:"Dominica"},
	{id:59,country:"Dominican Republic"},
	{id:60,country:"East Timor"},
	{id:61,country:"Ecuador"},
	{id:62,country:"Egypt"},
	{id:63,country:"El Salvador"},
	{id:64,country:"Equatorial Guinea"},
	{id:65,country:"Eritrea"},
	{id:66,country:"Estonia"},
	{id:67,country:"Ethiopia"},
	{id:68,country:"Falkland Islands (Malvinas)"},
	{id:69,country:"Faroe Islands"},
	{id:70,country:"Fiji"},
	{id:71,country:"Finland"},
	{id:72,country:"France"},
	{id:73,country:"France, Metropolitan"},
	{id:74,country:"French Guiana"},
	{id:75,country:"French Polynesia"},
	{id:76,country:"French Southern Territories"},
	{id:77,country:"Gabon"},
	{id:78,country:"Gambia"},
	{id:79,country:"Georgia"},
	{id:80,country:"Germany"},
	{id:81,country:"Ghana"},
	{id:82,country:"Gibraltar"},
	{id:83,country:"Guernsey"},
	{id:84,country:"Greece"},
	{id:85,country:"Greenland"},
	{id:86,country:"Grenada"},
	{id:87,country:"Guadeloupe"},
	{id:88,country:"Guam"},
	{id:89,country:"Guatemala"},
	{id:90,country:"Guinea"},
	{id:91,country:"Guinea-Bissau"},
	{id:92,country:"Guyana"},
	{id:93,country:"Haiti"},
	{id:94,country:"Heard and Mc Donald Islands"},
	{id:95,country:"Honduras"},
	{id:96,country:"Hong Kong"},
	{id:97,country:"Hungary"},
	{id:98,country:"Iceland"},
	{id:99,country:"India"},
	{id:100,country:"Isle of Man"},
	{id:101,country:"Indonesia"},
	{id:102,country:"Iran (Islamic Republic of)"},
	{id:103,country:"Iraq"},
	{id:104,country:"Ireland"},
	{id:105,country:"Israel"},
	{id:106,country:"Italy"},
	{id:107,country:"Ivory Coast"},
	{id:108,country:"Jersey"},
	{id:109,country:"Jamaica"},
	{id:110,country:"Japan"},
	{id:111,country:"Jordan"},
	{id:112,country:"Kazakhstan"},
	{id:113,country:"Kenya"},
	{id:114,country:"Kiribati"},
	{id:115,country:"Korea, Democratic People"},
	{id:116,country:"Korea, Republic of"},
	{id:117,country:"Kosovo"},
	{id:118,country:"Kuwait"},
	{id:119,country:"Kyrgyzstan"},
	{id:120,country:"Lao People"},
	{id:121,country:"Latvia"},
	{id:122,country:"Lebanon"},
	{id:123,country:"Lesotho"},
	{id:124,country:"Liberia"},
	{id:125,country:"Libyan Arab Jamahiriya"},
	{id:126,country:"Liechtenstein"},
	{id:127,country:"Lithuania"},
	{id:128,country:"Luxembourg"},
	{id:129,country:"Macau"},
	{id:130,country:"Macedonia"},
	{id:131,country:"Madagascar"},
	{id:132,country:"Malawi"},
	{id:133,country:"Malaysia"},
	{id:134,country:"Maldives"},
	{id:135,country:"Mali"},
	{id:136,country:"Malta"},
	{id:137,country:"Marshall Islands"},
	{id:138,country:"Martinique"},
	{id:139,country:"Mauritania"},
	{id:140,country:"Mauritius"},
	{id:141,country:"Mayotte"},
	{id:142,country:"Mexico"},
	{id:143,country:"Micronesia, Federated States of"},
	{id:144,country:"Moldova, Republic of"},
	{id:145,country:"Monaco"},
	{id:146,country:"Mongolia"},
	{id:147,country:"Montenegro"},
	{id:148,country:"Montserrat"},
	{id:149,country:"Morocco"},
	{id:150,country:"Mozambique"},
	{id:151,country:"Myanmar"},
	{id:152,country:"Namibia"},
	{id:153,country:"Nauru"},
	{id:154,country:"Nepal"},
	{id:155,country:"Netherlands"},
	{id:156,country:"Netherlands Antilles"},
	{id:157,country:"New Caledonia"},
	{id:158,country:"New Zealand"},
	{id:159,country:"Nicaragua"},
	{id:160,country:"Niger"},
	{id:161,country:"Nigeria"},
	{id:162,country:"Niue"},
	{id:163,country:"Norfolk Island"},
	{id:164,country:"Northern Mariana Islands"},
	{id:165,country:"Norway"},
	{id:166,country:"Oman"},
	{id:167,country:"Pakistan"},
	{id:168,country:"Palau"},
	{id:169,country:"Palestine"},
	{id:170,country:"Panama"},
	{id:171,country:"Papua New Guinea"},
	{id:172,country:"Paraguay"},
	{id:173,country:"Peru"},
	{id:174,country:"Philippines"},
	{id:175,country:"Pitcairn"},
	{id:176,country:"Poland"},
	{id:177,country:"Portugal"},
	{id:178,country:"Puerto Rico"},
	{id:179,country:"Qatar"},
	{id:180,country:"Reunion"},
	{id:181,country:"Romania"},
	{id:182,country:"Russian Federation"},
	{id:183,country:"Rwanda"},
	{id:184,country:"Saint Kitts and Nevis"},
	{id:185,country:"Saint Lucia"},
	{id:186,country:"Saint Vincent and the Grenadines"},
	{id:187,country:"Samoa"},
	{id:188,country:"San Marino"},
	{id:189,country:"Sao Tome and Principe"},
	{id:190,country:"Saudi Arabia"},
	{id:191,country:"Senegal"},
	{id:192,country:"Serbia"},
	{id:193,country:"Seychelles"},
	{id:194,country:"Sierra Leone"},
	{id:195,country:"Singapore"},
	{id:196,country:"Slovakia"},
	{id:197,country:"Slovenia"},
	{id:198,country:"Solomon Islands"},
	{id:199,country:"Somalia"},
	{id:200,country:"South Africa"},
	{id:201,country:"South Georgia South Sandwich Islands"},
	{id:202,country:"South Sudan"},
	{id:203,country:"Spain"},
	{id:204,country:"Sri Lanka"},
	{id:205,country:"St. Helena"},
	{id:206,country:"St. Pierre and Miquelon"},
	{id:207,country:"Sudan"},
	{id:208,country:"Suriname"},
	{id:209,country:"Svalbard and Jan Mayen Islands"},
	{id:210,country:"Swaziland"},
	{id:211,country:"Sweden"},
	{id:212,country:"Switzerland"},
	{id:213,country:"Syrian Arab Republic"},
	{id:214,country:"Taiwan"},
	{id:215,country:"Tajikistan"},
	{id:216,country:"Tanzania, United Republic of"},
	{id:217,country:"Thailand"},
	{id:218,country:"Togo"},
	{id:219,country:"Tokelau"},
	{id:220,country:"Tonga"},
	{id:221,country:"Trinidad and Tobago"},
	{id:222,country:"Tunisia"},
	{id:223,country:"Turkey"},
	{id:224,country:"Turkmenistan"},
	{id:225,country:"Turks and Caicos Islands"},
	{id:226,country:"Tuvalu"},
	{id:227,country:"Uganda"},
	{id:228,country:"Ukraine"},
	{id:229,country:"United Arab Emirates"},
	{id:230,country:"United Kingdom"},
	{id:231,country:"United States"},
	{id:232,country:"United States minor outlying islands"},
	{id:233,country:"Uruguay"},
	{id:234,country:"Uzbekistan"},
	{id:235,country:"Vanuatu"},
	{id:236,country:"Vatican City State"},
	{id:237,country:"Venezuela"},
	{id:238,country:"Vietnam"},
	{id:239,country:"Virgin Islands (British)"},
	{id:240,country:"Virgin Islands (U.S.)"},
	{id:241,country:"Wallis and Futuna Islands"},
	{id:242,country:"Western Sahara"},
	{id:243,country:"Yemen"},
	{id:244,country:"Zaire"},
	{id:245,country:"Zambia"},
	{id:246,country:"Zimbabwe"}
]

export default countryList;