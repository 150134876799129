const asamCriteriaJSON = {
  section: "AC",
  topic: "ASAM Criteria",
  checked: "true",
  // access: "private",
  color: "secondary",
  description: "(optional)",
  body: [
    {
      id: "AC.1",
      shortname: "ac01-v4",
      name: "Intoxication, Withdrawal, and Addiction Medications",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0: Non-issue or very low-risk issue.",
        "1: Mildly difficult issue or present minor signs and symptoms.",
        "2: Moderate difficulty in functioning.",
        "3: Serious issue or difficulty coping within a given dimension.",
        "4: Indicates utmost severity; critical impairments in coping and functioning.",
      ],
      act: "AC01-V4",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "AC01-1-V4",
    },
    {
      id: "AC.1.1",
      shortname: "ac01-1-v4",
      name: "",
      prevalue: "Justification for the selected rating:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "AC01-1-V4",
      mandatory: "true",
      depending: "AC01-V4",
      exception: "",
      extend: "true",
    },
    {
      id: "AC.2",
      shortname: "ac02-v4",
      name: "Biomedical Conditions",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0: Non-issue or very low-risk issue.",
        "1: Mildly difficult issue or present minor signs and symptoms.",
        "2: Moderate difficulty in functioning.",
        "3: Serious issue or difficulty coping within a given dimension.",
        "4: Indicates utmost severity; critical impairments in coping and functioning.",
      ],
      act: "AC02-V4",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "AC02-1-V4",
    },
    {
      id: "AC.2.1",
      shortname: "ac02-1-v4",
      name: "",
      prevalue: "Justification for the selected rating:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "AC02-1-V4",
      mandatory: "true",
      depending: "AC02-V4",
      exception: "",
      extend: "true",
    },
    {
      id: "AC.3",
      shortname: "ac03-v4",
      name:
        "Psychiatric and Cognitive Conditions",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0: Non-issue or very low-risk issue.",
        "1: Mildly difficult issue or present minor signs and symptoms.",
        "2: Moderate difficulty in functioning.",
        "3: Serious issue or difficulty coping within a given dimension.",
        "4: Indicates utmost severity; critical impairments in coping and functioning.",
      ],
      act: "AC03-V4",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "AC03-1-V4",
    },
    {
      id: "AC.3.1",
      shortname: "ac03-1-v4",
      name: "",
      prevalue: "Justification for the selected rating:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "AC03-1-V4",
      mandatory: "true",
      depending: "AC03-V4",
      exception: "",
      extend: "true",
    },
    {
      id: "AC.4",
      shortname: "ac04-v4",
      name: "Substance Use-Related Risks",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0: Non-issue or very low-risk issue.",
        "1: Mildly difficult issue or present minor signs and symptoms.",
        "2: Moderate difficulty in functioning.",
        "3: Serious issue or difficulty coping within a given dimension.",
        "4: Indicates utmost severity; critical impairments in coping and functioning.",
      ],
      act: "AC04-V4",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "AC04-1-V4",
    },
    {
      id: "AC.4.1",
      shortname: "ac04-1-v4",
      name: "",
      prevalue: "Justification for the selected rating:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "AC04-1-V4",
      mandatory: "true",
      depending: "AC04-V4",
      exception: "",
      extend: "true",
    },
    {
      id: "AC.5",
      shortname: "ac05-v4",
      name: "Recovery Environment Interactions",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0: Non-issue or very low-risk issue.",
        "1: Mildly difficult issue or present minor signs and symptoms.",
        "2: Moderate difficulty in functioning.",
        "3: Serious issue or difficulty coping within a given dimension.",
        "4: Indicates utmost severity; critical impairments in coping and functioning.",
      ],
      act: "AC05-V4",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "AC05-1-V4",
    },
    {
      id: "AC.5.1",
      shortname: "ac05-1-v4",
      name: "",
      prevalue: "Justification for the selected rating:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "AC05-1-V4",
      mandatory: "true",
      depending: "AC05-V4",
      exception: "",
      extend: "true",
    },
    {
      id: "AC.6",
      shortname: "ac06-v4",
      name: "Person-Centered Considerations",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0: Non-issue or very low-risk issue.",
        "1: Mildly difficult issue or present minor signs and symptoms.",
        "2: Moderate difficulty in functioning.",
        "3: Serious issue or difficulty coping within a given dimension.",
        "4: Indicates utmost severity; critical impairments in coping and functioning.",
      ],
      act: "AC06-V4",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "AC06-1-V4",
    },
    {
      id: "AC.6.1",
      shortname: "ac06-1-v4",
      name: "",
      prevalue: "Justification for the selected rating:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "AC06-1-V4",
      mandatory: "true",
      depending: "AC06-V4",
      exception: "",
      extend: "true",
    },
    // {
    //   id: "AC.7",
    //   shortname: "ac07",
    //   access: "private",
    //   name: "Field for counselor: ",
    //   prevalue: "",
    //   value: "",
    //   disabled: "true",
    //   type: "editor",
    //   act: "AC07",
    //   depending: "",
    //   exception: "",
    // },
    // {
    //   id: "AC.7",
    //   shortname: "ac07",
    //   access: "private",
    //   name: "Field for counselor: ",
    //   prevalue: "",
    //   value: "",
    //   disabled: "false",
    //   type: "textarea",
    //   act: "AC07",
    //   depending: "",
    //   exception: "",
    // },
  ],
};

export default asamCriteriaJSON;
