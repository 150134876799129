const demographicInformationJSON =  {
  section: "DEMO",
  topic: "Demographic Information",
  checked: "true",
  // access: "public",
  color: "secondary",
  description: "",
  body: [
    {
      id: "DI.1",
      shortname: "demo5",
      name: "Client date of birth (defined in Patient Information section)",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "date",
      act: "DEMO5",
      depending: "",
      exception: "",
    },
    {
      id: "DI.2",
      shortname: "demo6",
      name:
        "What was your current age on the moment of the last information update?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
        "101",
        "102",
        "103",
        "104",
        "105",
        "106",
        "107",
        "108",
        "109",
        "110",
        "111",
        "112",
        "113",
        "114",
        "115",
      ],
      act: "DEMO6",
      depending: "",
      exception: "",
    },
    {
      id: "DI.3",
      shortname: "demo4",
      name: "Client gender :",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Male", "Female", "Other"],
      act: "DEMO4",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "DEMO4-NEW0",
    },
    {
      id: "DI.3.1",
      shortname: "demo4-new0",
      name: "",
      prevalue: "Sex assigned at birth",
      value: "",
      disabled: "true",
      type: "text",
      act: "DEMO4-NEW0",
      extend: "true",
      depending: "DEMO4",
      exception: "Other",
      link: "DEMO4-NEW1",
    },
    {
      id: "DI.4",
      shortname: "demo4-new1",
      name: "",
      prevalue: "If other, explain ?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DEMO4-NEW1",
      extend: "true",
      depending: "DEMO4",
      exception: "Other",
    },
    {
      id: "DI.5",
      shortname: "demo3",
      name: "Of what race do you consider yourself?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "White (not Hisp)",
        "Black (not Hisp)",
        "American Indian",
        "Alaskan Native",
        "Asian/Pacific",
        "Hispanic-Mexican",
        "Hispanic-Puerto Rican",
        "Hispanic-Cuban",
        "Unknown",
      ],
      act: "DEMO3",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    // {
    //   id: "DI.5",
    //   shortname: "demo3",
    //   name: "Of what race do you consider yourself?",
    //   prevalue: "",
    //   value: "",
    //   disabled: "false",
    //   type: "select",
    //   ruletype: "string",
    //   select_options: [
    //     "",
    //     "White",
    //     "African American",
    //     "Native American",
    //     "Pacific Islander",
    //     "Hispanic",
    //     "Asian",
    //     "Mixed",
    //   ],
    //   act: "DEMO3",
    //   mandatory: "true",
    //   depending: "",
    //   exception: "",
    //   link: "DEMO3-1",
    // },
    // {
    //   id: "DI.5.1",
    //   shortname: "demo3-1",
    //   name: "",
    //   prevalue: "If Mixed, explain ?",
    //   value: "",
    //   disabled: "true",
    //   type: "text",
    //   act: "DEMO3-1",
    //   depending: "DEMO3",
    //   exception: "Mixed",
    //   extend: "true",
    //   link: "DEMO3-2",
    // },
    // {
    //   id: "DI.5.2",
    //   shortname: "demo3-2",
    //   name: "",
    //   prevalue: "Hispanic:",
    //   value: "",
    //   disabled: "true",
    //   type: "select",
    //   ruletype: "string",
    //   select_options: [
    //     "",
    //     "Mexican",
    //     "Puerto Rican",
    //     "Cuban",
    //     "Other",
    //   ],
    //   act: "DEMO3-2",
    //   depending: "DEMO3",
    //   exception: "Hispanic",
    //   extend: "true",
    // },
    {
      id: "DI.6",
      shortname: "demo1",
      name: "What is your marital status?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "single",
        "married",
        "divorced",
        "widowed",
        "in a committed relationship",
        "separated",
      ],
      act: "DEMO1",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "DI.7",
      shortname: "demo2",
      name: "Are you satisfied with this status?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["satisfied", "not satisfied", "ambivalent"],
      act: "DEMO2",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "DEMO2-ADD1",
    },
    {
      id: "DI.7.1",
      extend: "true",
      shortname: "demo2-add1",
      name: "",
      prevalue: "if not satisfied/ambivalent, explain?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DEMO2-ADD1",
      depending: "DEMO2",
      exception: "satisfied",
    },
    {
      id: "DI.8",
      shortname: "demo7",
      name: "Where were you born? (City/State/Country)",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "text",
      act: "DEMO7",
      depending: "",
      exception: "",
      link: "DEMO7-ADD",
    },
    {
      id: "DI.8.1",
      extend: "true",
      shortname: "demo7-add",
      name: "",
      prevalue: "Is this the same location where you were raised?",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DEMO7-ADD",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "DEMO8",
    },
    {
      id: "DI.8.2",
      shortname: "demo8",
      name: "",
      prevalue: "Where were you mostly raised? (City/State/Country)",
      value: "",
      disabled: "true",
      type: "text",
      act: "DEMO8",
      depending: "DEMO7-ADD",
      exception: "No",
      extend: "true",
    },
    {
      id: "DI.9",
      shortname: "demo9",
      name: "What is your religious preference?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "none",
        "Catholic",
        "Protestant",
        "Judaism",
        "Buddhism",
        "Hinduism ",
        "Islam",
        "Other",
      ],
      act: "DEMO9",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "DEMO9-NEW1",
    },
    {
      id: "DI.9.1",
      shortname: "demo9-new1",
      name: "",
      prevalue: "If other, explain?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DEMO9-NEW1",
      depending: "DEMO9",
      exception: "Other",
      extend: "true",
      link: "DEMO9-NEW2",
    },
    {
      id: "DI.9.2",
      shortname: "demo9-new2",
      name: "",
      prevalue: "What specific Protestant denomination do you attend?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DEMO9-NEW2",
      depending: "DEMO9",
      exception: "Protestant",
      extend: "true",
    },
    {
      id: "DI.10",
      shortname: "demo10",
      name: "How many years have you lived at the address you provided us?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "less than one year",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
        "more than ten",
      ],
      act: "DEMO10",
      depending: "",
      exception: "",
    },
    {
      id: "DI.11",
      shortname: "demo11",
      name: "Is this residence owned by you or your family?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DEMO11",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "DEMO11-NEW1",
    },
    {
      id: "DI.11.1",
      shortname: "demo11-new1",
      name: "",
      prevalue: "If Yes, please specify Family or Self ?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Family", "Self"],
      act: "DEMO11-NEW1",
      depending: "DEMO11",
      exception: "Yes",
      link: "DEMO11-NEW2",
      extend: "true",
    },
    {
      id: "DI.11.2",
      shortname: "demo11-new2",
      name: "",
      prevalue:
        "If No, please specify: rent, dormitory, stay with others, prison/jail?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: [
        "rent",
        "dormitory",
        " stay with others",
        "prison/jail",
      ],
      act: "DEMO11-NEW2",
      depending: "DEMO11",
      exception: "No",
      extend: "true",
    },
  ],
}

export default demographicInformationJSON;
