const alcoholHistoryJSON = {
  section: "DAH",
  topic: "Drug and Alcohol History",
  checked: "true",
  color: "secondary",
  description: "",
  body: [
    {
      id: "DA.1",
      shortname: "da-new1",
      name: "Have you EVER tried alcohol, tobacco or illegal drugs, even once?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "Refused to answer"],
      act: "DA-NEW1",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "DA0",
    },
    {
      id: "DA.1.1",
      shortname: "da0",
      name: "",
      prevalue: "What substances have been used even once?",
      extend: "true",
      value: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "Alcohol",
        "Cannabis products, including K2",
        "Cocaine",
        "Ecstasy",
        "Hallucinogens /LSD/mushrooms, etc.",
        "Heroin",
        "Inhalants",
        "Meth or other Amphetamines",
        "Methadone",
        "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
        "Sedative/ Hypnotic/Anxiolytics",
        "Tobacco",
        "Other Substance",
      ],
      act: "DA0",
      mandatory: "true",
      depending: "DA-NEW1",
      exception: "Yes",
    },
    {
      id: "DA.1.1.1",
      shortname: "da1-rank",
      name: "Alcohol",
      prevalue: "Rank.",
      value: "",
      disabled: "true",
      type: "rank",
      act: "DA1-RANK",
      depending: "DA0",
      exception: "Alcohol",
      link: "DA1",
    },
    {
      id: "DA.1.1.2",
      extend: "true",
      shortname: "da1",
      name: "",
      prevalue: "At what age did you first use alcohol?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA1",
      depending: "DA0",
      exception: "Alcohol",
      // link: "DA1-ADOL1-0",
      link: "DA1-ADOL1",
      subtopic_container_act: "DA1-RANK",
      suptopic_code: "DA1_ALCOHOL_COLUMN1",
    },
/****************************************** */
    // {
    //   id: "DA.1.1.3",
    //   extend: "true",
    //   shortname: "da1-adol1-0",
    //   name: "",
    //   prevalue:
    //     "Have you used alcohol on regular bases?",
    //   value: "",
    //   disabled: "true",
    //   type: "radio",
    //   radio_options: ["Yes", "No"],
    //   act: "DA1-ADOL1-0",
    //   depending: "DA0",
    //   exception: "Alcohol",
    //   condition: "in",
    //   link: "DA1-ADOL1",
    //   subtopic_container_act: "DA1-RANK",
    //   suptopic_code: "DA1_ALCOHOL_COLUMN1",
    // },
    // {
    //   id: "DA.1.1.3.1",
    //   extend: "true",
    //   shortname: "da1-adol1",
    //   name: "",
    //   prevalue:
    //     "How old were you when you first started using alcohol on a regular basis?",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "DA1-ADOL1",
    //   depending: "DA1-ADOL1-0",
    //   exception: "Yes",
    //   link: "DA1-ADOL2",
    //   subtopic_container_act: "DA1-RANK",
    //   suptopic_code: "DA1_ALCOHOL_COLUMN1",
    // },
/****************************************** */
    {
      id: "DA.1.1.3.1",
      extend: "true",
      shortname: "da1-adol1",
      name: "",
      prevalue:
        "How old were you when you first started using alcohol on a regular basis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "never regular",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
      // type: "number",
      act: "DA1-ADOL1",
      depending: "DA0",
      exception: "Alcohol",
      condition: "in",
      link: "DA1-ADOL2",
      subtopic_container_act: "DA1-RANK",
      suptopic_code: "DA1_ALCOHOL_COLUMN1",
    },
    {
      id: "DA.1.1.4",
      extend: "true",
      shortname: "da1-adol2",
      name: "",
      prevalue:
        "In the past year what was your TYPICAL pattern of your use of alcohol?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA1-ADOL2",
      depending: "DA0",
      exception: "Alcohol",
      condition: "in",
      link: "DA1-ADOL3",
      subtopic_container_act: "DA1-RANK",
      suptopic_code: "DA1_ALCOHOL_COLUMN1",
    },
    {
      id: "DA.1.1.5",
      extend: "true",
      shortname: "da1-adol3",
      name: "",
      prevalue:
        "In the past year what was your PEAK pattern of your use of alcohol?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA1-ADOL3",
      depending: "DA0",
      exception: "Alcohol",
      condition: "in",
      link: "DA1-ADOL4",
      subtopic_container_act: "DA1-RANK",
      suptopic_code: "DA1_ALCOHOL_COLUMN1",
    },
    {
      id: "DA.1.1.6",
      extend: "true",
      shortname: "da1-adol4",
      name: "",
      prevalue: "When you use alcohol, with whom do you typically use?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Alone",
        "Friend(s)",
        "Sibling(s)",
        "Parent(s)",
        "Acquaintance(s)",
        "Stranger(s)",
        "Other Specify",
      ],
      act: "DA1-ADOL4",
      depending: "DA0",
      exception: "Alcohol",
      condition: "in",
      link: "DA1-ADOL4-1",
      subtopic_container_act: "DA1-RANK",
      suptopic_code: "DA1_ALCOHOL_COLUMN1",
    },
    {
      id: "DA.1.1.6.1",
      extend: "true",
      shortname: "da1-adol4-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA1-ADOL4-1",
      depending: "DA1-ADOL4",
      exception: "Other Specify",
      link: "DA1-1",
      subtopic_container_act: "DA1-RANK",
      suptopic_code: "DA1_ALCOHOL_COLUMN1",
    },
    {
      id: "DA.1.1.7",
      extend: "true",
      shortname: "da1-1",
      name: "",
      prevalue: "When did you last use alcohol?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA1-1",
      depending: "DA0",
      exception: "Alcohol",
      link: "DA1-2",
      subtopic_container_act: "DA1-RANK",
      suptopic_code: "DA1_ALCOHOL_COLUMN1",
    },
    {
      id: "DA.1.1.8",
      extend: "true",
      shortname: "da1-2",
      name: "",
      prevalue: "How many days in the past 30 days have you used alcohol?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA1-2",
      depending: "DA0",
      exception: "Alcohol",
      subtopic_container_act: "DA1-RANK",
      suptopic_code: "DA1_ALCOHOL_COLUMN1",
    },
    {
      id: "DA.1.1.9",
      shortname: "da1-3",
      name: "",
      prevalue:
        "How many years of regular use (more months than not) have you used Alcohol?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA1-3",
      depending: "DA0",
      exception: "Alcohol",
      link: "DA1-4",
      subtopic_container_act: "DA1-RANK",
      suptopic_code: "DA1_ALCOHOL_COLUMN2",
    },
    {
      id: "DA.1.1.10",
      extend: "true",
      shortname: "da1-4",
      name: "",
      prevalue: "What is the usual way you use alcohol?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "oral"],
      act: "DA1-4",
      depending: "DA0",
      exception: "Alcohol",
      link: "DA1-5",
      subtopic_container_act: "DA1-RANK",
      suptopic_code: "DA1_ALCOHOL_COLUMN2",
    },
    {
      id: "DA.1.1.11",
      extend: "true",
      shortname: "da1-5",
      name: "",
      prevalue:
        "Have you used alcohol before, during, or, after sexual activity?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA1-5",
      depending: "DA0",
      exception: "Alcohol",
      subtopic_container_act: "DA1-RANK",
      suptopic_code: "DA1_ALCOHOL_COLUMN2",
    },
    {
      id: "DA.1.1.12",
      shortname: "da2",
      name: "",
      prevalue: "If used, have you EVER drunk 3 or more drinks in a sitting?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA2",
      mandatory: "true",
      depending: "DA0",
      exception: "Alcohol",
      link: "DA2-1",
      subtopic_container_act: "DA1-RANK",
      suptopic_code: "DA1_ALCOHOL_COLUMN3",
    },
    {
      id: "DA.1.1.12.1",
      extend: "true",
      shortname: "da2-1",
      name: "",
      prevalue: "If Yes, then at what age did you first drink more than 3?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA2-1",
      depending: "DA2",
      exception: "Yes",
      link: "DA2-2",
      subtopic_container_act: "DA1-RANK",
      suptopic_code: "DA1_ALCOHOL_COLUMN3",
    },
    {
      id: "DA.1.1.12.2",
      extend: "true",
      shortname: "da2-2",
      name: "",
      prevalue: "When did you last drink more than 3 drinks?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA2-2",
      depending: "DA2",
      exception: "Yes",
      link: "DA2-3",
      subtopic_container_act: "DA1-RANK",
      suptopic_code: "DA1_ALCOHOL_COLUMN3",
    },
    {
      id: "DA.1.1.12.3",
      extend: "true",
      shortname: "da2-3",
      name: "",
      prevalue:
        "How many days in the past 30 days have you drunk 3 or more drinks in a day?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA2-3",
      depending: "DA2",
      exception: "Yes",
      subtopic_container_act: "DA1-RANK",
      suptopic_code: "DA1_ALCOHOL_COLUMN3",
    },
    {
      id: "DA.1.1.12.4",
      shortname: "da2-4",
      name: "",
      prevalue:
        "How many years in your lifetime have you drunk alcohol 3 or more times on a monthly or more often basis for most of a year?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA2-4",
      depending: "DA2",
      exception: "Yes",
      subtopic_container_act: "DA1-RANK",
      suptopic_code: "DA1_ALCOHOL_COLUMN3",
    },
    // {
    //   id: "DA.1.1.13",
    //   access: "private",
    //   shortname: "da2-comm",
    //   name: "",
    //   prevalue: "Comments (Alcohol)",
    //   value: "",
    //   disabled: "true",
    //   type: "textarea",
    //   act: "DA2-COMM",
    //   depending: "DA0",
    //   exception: "Alcohol",
    //   subtopic_container_act: "DA1-RANK",
    //   suptopic_code: "DA1_ALCOHOL_COLUMN4",
    // },
    {
      id: "DA.1.1.13",
      shortname: "da2-comm",
      access: "private",
      name: "",
      prevalue: "Comments (Alcohol)",
      value: "",
      disabled: "true",
      type: "editor",
      act: "DA2-COMM",
      depending: "DA0",
      exception: "Alcohol",
      subtopic_container_act: "DA1-RANK",
      suptopic_code: "DA1_ALCOHOL_COLUMN4",
      // subtopic_name: "Felt like you belonged in school?",
    },
    {
      id: "DA.2",
      shortname: "startblockalcohol",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "STARTBLOCKALCOHOL",
      depending: "",
      exception: "",
    },
    {
      id: "DA.3",
      shortname: "endblockalcohol",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "ENDBLOCKALCOHOL",
      depending: "",
      exception: "",
    },
    {
      id: "DA.1.1.27",
      shortname: "da5-rank",
      name: "Cannabis products, including K2",
      prevalue: "Rank.",
      value: "",
      disabled: "true",
      type: "rank",
      act: "DA5-RANK",
      depending: "DA0",
      exception: "Cannabis products, including K2",
      link: "DA5",
    },
    {
      id: "DA.1.1.28",
      extend: "true",
      shortname: "da5",
      name: "",
      prevalue: "At what age did you first use cannabis?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA5",
      depending: "DA0",
      exception: "Cannabis products, including K2",
      link: "DA1-ADOL1",
      subtopic_container_act: "DA5-RANK",
      suptopic_code: "DA5_MARIJUANA_COLUMN1",
    },
    // {
    //   id: "DA.1.1.29",
    //   extend: "true",
    //   shortname: "da5-adol1",
    //   name: "",
    //   prevalue:
    //     "How old were you when you first started using cannabis on a regular basis?",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "DA5-ADOL1",
    //   depending: "DA0",
    //   exception: "Cannabis products, including K2",
    //   condition: "in",
    //   link: "DA5-ADOL2",
    //   subtopic_container_act: "DA5-RANK",
    //   suptopic_code: "DA5_MARIJUANA_COLUMN1",
    // },
    {
      id: "DA.1.1.29",
      extend: "true",
      shortname: "da5-adol1",
      name: "",
      prevalue:
        "How old were you when you first started using cannabis on a regular basis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "never regular",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
      // type: "number",
      act: "DA5-ADOL1",
      depending: "DA0",
      exception: "Cannabis products, including K2",
      condition: "in",
      link: "DA5-ADOL2",
      subtopic_container_act: "DA5-RANK",
      suptopic_code: "DA5_MARIJUANA_COLUMN1",
    },
    {
      id: "DA.1.1.30",
      extend: "true",
      shortname: "da5-adol2",
      name: "",
      prevalue:
        "In the past year what was your TYPICAL pattern of your use of cannabis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA5-ADOL2",
      depending: "DA0",
      exception: "Cannabis products, including K2",
      condition: "in",
      link: "DA5-ADOL3",
      subtopic_container_act: "DA5-RANK",
      suptopic_code: "DA5_MARIJUANA_COLUMN1",
    },
    {
      id: "DA.1.1.31",
      extend: "true",
      shortname: "da5-adol3",
      name: "",
      prevalue:
        "In the past year what was your PEAK pattern of your use of cannabis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA5-ADOL3",
      depending: "DA0",
      exception: "Cannabis products, including K2",
      condition: "in",
      link: "DA5-ADOL4",
      subtopic_container_act: "DA5-RANK",
      suptopic_code: "DA5_MARIJUANA_COLUMN1",
    },
    {
      id: "DA.1.1.32",
      extend: "true",
      shortname: "da5-adol4",
      name: "",
      prevalue: "When you use cannabis, with whom do you typically use?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Alone",
        "Friend(s)",
        "Sibling(s)",
        "Parent(s)",
        "Acquaintance(s)",
        "Stranger(s)",
        "Other Specify",
      ],
      act: "DA5-ADOL4",
      depending: "DA0",
      exception: "Cannabis products, including K2",
      condition: "in",
      link: "DA5-ADOL4-1",
      subtopic_container_act: "DA5-RANK",
      suptopic_code: "DA5_MARIJUANA_COLUMN1",
    },
    {
      id: "DA.1.1.32.1",
      extend: "true",
      shortname: "da5-adol4-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA5-ADOL4-1",
      depending: "DA5-ADOL4",
      exception: "Other Specify",
      link: "DA5-1",
      subtopic_container_act: "DA5-RANK",
      suptopic_code: "DA5_MARIJUANA_COLUMN1",
    },
    {
      id: "DA.1.1.33",
      extend: "true",
      shortname: "da5-1",
      name: "",
      prevalue: "When did you last use cannabis?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA5-1",
      depending: "DA0",
      exception: "Cannabis products, including K2",
      link: "DA5-2",
      subtopic_container_act: "DA5-RANK",
      suptopic_code: "DA5_MARIJUANA_COLUMN1",
    },
    {
      id: "DA.1.1.34",
      extend: "true",
      shortname: "da5-2",
      name: "",
      prevalue: "How many days in the past 30 days have you used cannabis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA5-2",
      depending: "DA0",
      exception: "Cannabis products, including K2",
      subtopic_container_act: "DA5-RANK",
      suptopic_code: "DA5_MARIJUANA_COLUMN1",
    },
    {
      id: "DA.1.1.35",
      shortname: "da5-3",
      name: "",
      prevalue:
        "How many years of regular use (more months than not) have you used cannabis?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA5-3",
      depending: "DA0",
      exception: "Cannabis products, including K2",
      link: "DA5-4",
      subtopic_container_act: "DA5-RANK",
      suptopic_code: "DA5_MARIJUANA_COLUMN2",
    },
    {
      id: "DA.1.1.36",
      extend: "true",
      shortname: "da5-4",
      name: "",
      prevalue: "What is the usual way you use cannabis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "oral",
        "nasal",
        "smoking",
        "non-IV injection",
        "IV injection",
      ],
      act: "DA5-4",
      depending: "DA0",
      exception: "Cannabis products, including K2",
      link: "DA5-5",
      subtopic_container_act: "DA5-RANK",
      suptopic_code: "DA5_MARIJUANA_COLUMN2",
    },
    {
      id: "DA.1.1.37",
      extend: "true",
      shortname: "da5-5",
      name: "",
      prevalue:
        "Have you used cannabis before, during, or, after sexual activity?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA5-5",
      depending: "DA0",
      exception: "Cannabis products, including K2",
      subtopic_container_act: "DA5-RANK",
      suptopic_code: "DA5_MARIJUANA_COLUMN2",
    },
    // {
    //   id: "DA.1.1.38",
    //   access: "private",
    //   shortname: "da5-5-comm",
    //   name: "",
    //   prevalue: "Comments (Cannabis products, including K2)",
    //   value: "",
    //   disabled: "true",
    //   type: "textarea",
    //   act: "DA5-5-COMM",
    //   depending: "DA0",
    //   exception: "Cannabis products, including K2",
    //   subtopic_container_act: "DA5-RANK",
    //   suptopic_code: "DA5_MARIJUANA_COLUMN3",
    // },
    {
      id: "DA.1.1.38",
      access: "private",
      shortname: "da5-5-comm",
      name: "",
      prevalue: "Comments (Cannabis products, including K2)",
      value: "",
      disabled: "true",
      type: "editor",
      act: "DA5-5-COMM",
      depending: "DA0",
      exception: "Cannabis products, including K2",
      subtopic_container_act: "DA5-RANK",
      suptopic_code: "DA5_MARIJUANA_COLUMN3",
    },

    {
      id: "DA.4",
      shortname: "startblockcannabis",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "STARTBLOCKCANNABIS",
      depending: "",
      exception: "",
    },
    {
      id: "DA.5",
      shortname: "endbblockcannabis",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "ENDBLOCKCANNABIS",
      depending: "",
      exception: "",
    },
    {
      id: "DA.1.1.52",
      shortname: "da6-rank",
      name: "Cocaine",
      prevalue: "Rank.",
      value: "",
      disabled: "true",
      type: "rank",
      act: "DA6-RANK",
      depending: "DA0",
      exception: "Cocaine",
      link: "DA6",
    },
    {
      id: "DA.1.1.53",
      extend: "true",
      shortname: "da6",
      name: "",
      prevalue: "At what age did you first use cocaine?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA6",
      depending: "DA0",
      exception: "Cocaine",
      link: "DA6-ADOL1",
      subtopic_container_act: "DA6-RANK",
      suptopic_code: "DA6_COCAINE_COLUMN1",
    },
    // {
    //   id: "DA.1.1.54",
    //   extend: "true",
    //   shortname: "da6-adol1",
    //   name: "",
    //   prevalue:
    //     "How old were you when you first started using cocaine on a regular basis?",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "DA6-ADOL1",
    //   depending: "DA0",
    //   exception: "Cocaine",
    //   condition: "in",
    //   link: "DA6-ADOL2",
    //   subtopic_container_act: "DA6-RANK",
    //   suptopic_code: "DA6_COCAINE_COLUMN1",
    // },
    {
      id: "DA.1.1.54",
      extend: "true",
      shortname: "da6-adol1",
      name: "",
      prevalue:
        "How old were you when you first started using cocaine on a regular basis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "never regular",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
      // type: "number",
      act: "DA6-ADOL1",
      depending: "DA0",
      exception: "Cocaine",
      condition: "in",
      link: "DA6-ADOL2",
      subtopic_container_act: "DA6-RANK",
      suptopic_code: "DA6_COCAINE_COLUMN1",
    },
    {
      id: "DA.1.1.55",
      extend: "true",
      shortname: "da6-adol2",
      name: "",
      prevalue:
        "In the past year what was your TYPICAL pattern of your use of cocaine?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA6-ADOL2",
      depending: "DA0",
      exception: "Cocaine",
      condition: "in",
      link: "DA6-ADOL3",
      subtopic_container_act: "DA6-RANK",
      suptopic_code: "DA6_COCAINE_COLUMN1",
    },
    {
      id: "DA.1.1.56",
      extend: "true",
      shortname: "da6-adol3",
      name: "",
      prevalue:
        "In the past year what was your PEAK pattern of your use of cocaine?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA6-ADOL3",
      depending: "DA0",
      exception: "Cocaine",
      condition: "in",
      link: "DA6-ADOL4",
      subtopic_container_act: "DA6-RANK",
      suptopic_code: "DA6_COCAINE_COLUMN1",
    },
    {
      id: "DA.1.1.57",
      extend: "true",
      shortname: "da6-adol4",
      name: "",
      prevalue: "When you use cocaine, with whom do you typically use?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Alone",
        "Friend(s)",
        "Sibling(s)",
        "Parent(s)",
        "Acquaintance(s)",
        "Stranger(s)",
        "Other Specify",
      ],
      act: "DA6-ADOL4",
      depending: "DA0",
      exception: "Cocaine",
      condition: "in",
      link: "DA6-ADOL4-1",
      subtopic_container_act: "DA6-RANK",
      suptopic_code: "DA6_COCAINE_COLUMN1",
    },
    {
      id: "DA.1.1.57.1",
      extend: "true",
      shortname: "da6-adol4-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA6-ADOL4-1",
      depending: "DA6-ADOL4",
      exception: "Other Specify",
      link: "DA6-1",
      subtopic_container_act: "DA6-RANK",
      suptopic_code: "DA6_COCAINE_COLUMN1",
    },
    {
      id: "DA.1.1.58",
      extend: "true",
      shortname: "da6-1",
      name: "",
      prevalue: "When did you last use cocaine?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA6-1",
      depending: "DA0",
      exception: "Cocaine",
      link: "DA6-2",
      subtopic_container_act: "DA6-RANK",
      suptopic_code: "DA6_COCAINE_COLUMN1",
    },
    {
      id: "DA.1.1.59",
      extend: "true",
      shortname: "da6-2",
      name: "",
      prevalue: "How many days in the past 30 days have you used cocaine?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA6-2",
      depending: "DA0",
      exception: "Cocaine",
      subtopic_container_act: "DA6-RANK",
      suptopic_code: "DA6_COCAINE_COLUMN1",
    },
    {
      id: "DA.1.1.60",
      shortname: "da6-3",
      name: "",
      prevalue:
        "How many years of regular use (more months than not) have you used cocaine?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA6-3",
      depending: "DA0",
      exception: "Cocaine",
      link: "DA6-4",
      subtopic_container_act: "DA6-RANK",
      suptopic_code: "DA6_COCAINE_COLUMN2",
    },
    {
      id: "DA.1.1.61",
      extend: "true",
      shortname: "da6-4",
      name: "",
      prevalue: "What is the usual way you use cocaine?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "oral",
        "nasal",
        "smoking",
        "non-IV injection",
        "IV injection",
      ],
      act: "DA6-4",
      depending: "DA0",
      exception: "Cocaine",
      link: "DA6-5",
      subtopic_container_act: "DA6-RANK",
      suptopic_code: "DA6_COCAINE_COLUMN2",
    },
    {
      id: "DA.1.1.62",
      extend: "true",
      shortname: "da6-5",
      name: "",
      prevalue:
        "Have you used cocaine before, during, or, after sexual activity?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA6-5",
      depending: "DA0",
      exception: "Cocaine",
      subtopic_container_act: "DA6-RANK",
      suptopic_code: "DA6_COCAINE_COLUMN2",
    },
    // {
    //   id: "DA.1.1.63",
    //   access: "private",
    //   shortname: "da6-5-comm",
    //   name: "",
    //   prevalue: "Comments (Cocaine)",
    //   value: "",
    //   disabled: "true",
    //   type: "textarea",
    //   act: "DA6-5-COMM",
    //   depending: "DA0",
    //   exception: "Cocaine",
    //   subtopic_container_act: "DA6-RANK",
    //   suptopic_code: "DA6_COCAINE_COLUMN3",
    // },
    {
      id: "DA.1.1.63",
      access: "private",
      shortname: "da6-5-comm",
      name: "",
      prevalue: "Comments (Cocaine)",
      value: "",
      disabled: "true",
      type: "editor",
      act: "DA6-5-COMM",
      depending: "DA0",
      exception: "Cocaine",
      subtopic_container_act: "DA6-RANK",
      suptopic_code: "DA6_COCAINE_COLUMN3",
    },
    {
      id: "DA.6",
      shortname: "startblockcocaine",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "STARTBLOCKCOCAINE",
      depending: "",
      exception: "",
    },
    {
      id: "DA.7",
      shortname: "endbblockcocaine",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "ENDBLOCKCOCAINE",
      depending: "",
      exception: "",
    },
    {
      id: "DA.1.1.77",
      shortname: "da7-rank",
      name: "Crack",
      prevalue: "Rank.",
      value: "",
      disabled: "true",
      type: "rank",
      act: "DA7-RANK",
      depending: "DA0",
      exception: "Crack",
      link: "DA7-NEW",
    },
    {
      id: "DA.1.1.78",
      extend: "true",
      shortname: "da7-new",
      name: "",
      prevalue: "At what age did you first use crack?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA7-NEW",
      depending: "DA0",
      exception: "Crack",
      link: "DA7-NEW-ADOL1",
      subtopic_container_act: "DA7-RANK",
      suptopic_code: "DA7_CORACK_COLUMN1",
    },
    // {
    //   id: "DA.1.1.79",
    //   extend: "true",
    //   shortname: "da7-new-adol1",
    //   name: "",
    //   prevalue:
    //     "How old were you when you first started using crack on a regular basis?",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "DA7-NEW-ADOL1",
    //   depending: "DA0",
    //   exception: "Crack",
    //   condition: "in",
    //   link: "DA7-NEW-ADOL2",
    //   subtopic_container_act: "DA7-RANK",
    //   suptopic_code: "DA7_CORACK_COLUMN1",
    // },
    {
      id: "DA.1.1.79",
      extend: "true",
      shortname: "da7-new-adol1",
      name: "",
      prevalue:
        "How old were you when you first started using crack on a regular basis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "never regular",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
      // type: "number",
      act: "DA7-NEW-ADOL1",
      depending: "DA0",
      exception: "Crack",
      condition: "in",
      link: "DA7-NEW-ADOL2",
      subtopic_container_act: "DA7-RANK",
      suptopic_code: "DA7_CORACK_COLUMN1",
    },
    {
      id: "DA.1.1.80",
      extend: "true",
      shortname: "da7-new-adol2",
      name: "",
      prevalue:
        "In the past year what was your TYPICAL pattern of your use of crack?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA7-NEW-ADOL2",
      depending: "DA0",
      exception: "Crack",
      condition: "in",
      link: "DA7-NEW-ADOL3",
      subtopic_container_act: "DA7-RANK",
      suptopic_code: "DA7_CORACK_COLUMN1",
    },
    {
      id: "DA.1.1.81",
      extend: "true",
      shortname: "da7-new-adol3",
      name: "",
      prevalue:
        "In the past year what was your PEAK pattern of your use of crack?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA7-NEW-ADOL3",
      depending: "DA0",
      exception: "Crack",
      condition: "in",
      link: "DA7-NEW-ADOL4",
      subtopic_container_act: "DA7-RANK",
      suptopic_code: "DA7_CORACK_COLUMN1",
    },
    {
      id: "DA.1.1.82",
      extend: "true",
      shortname: "da7-new-adol4",
      name: "",
      prevalue: "When you use crack, with whom do you typically use?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Alone",
        "Friend(s)",
        "Sibling(s)",
        "Parent(s)",
        "Acquaintance(s)",
        "Stranger(s)",
        "Other Specify",
      ],
      act: "DA7-NEW-ADOL4",
      depending: "DA0",
      exception: "Crack",
      condition: "in",
      link: "DA7-NEW-ADOL4-1",
      subtopic_container_act: "DA7-RANK",
      suptopic_code: "DA7_CORACK_COLUMN1",
    },
    {
      id: "DA.1.1.82.1",
      extend: "true",
      shortname: "da7-new-adol4-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA7-NEW-ADOL4-1",
      depending: "DA7-NEW-ADOL4",
      exception: "Other Specify",
      link: "DA7-1-NEW",
      subtopic_container_act: "DA7-RANK",
      suptopic_code: "DA7_CORACK_COLUMN1",
    },
    {
      id: "DA.1.1.83",
      extend: "true",
      shortname: "da7-1-new",
      name: "",
      prevalue: "When did you last use crack?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA7-1-NEW",
      depending: "DA0",
      exception: "Crack",
      link: "DA7-2-NEW",
      subtopic_container_act: "DA7-RANK",
      suptopic_code: "DA7_CORACK_COLUMN1",
    },
    {
      id: "DA.1.1.84",
      extend: "true",
      shortname: "da7-2-new",
      name: "",
      prevalue: "How many days in the past 30 days have you used crack?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA7-2-NEW",
      depending: "DA0",
      exception: "Crack",
      subtopic_container_act: "DA7-RANK",
      suptopic_code: "DA7_CORACK_COLUMN1",
    },
    {
      id: "DA.1.1.85",
      shortname: "da7-3-new",
      name: "",
      prevalue:
        "How many years of regular use (more months than not) have you used crack?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA7-3-NEW",
      depending: "DA0",
      exception: "Crack",
      link: "DA7-4-NEW",
      subtopic_container_act: "DA7-RANK",
      suptopic_code: "DA7_CORACK_COLUMN2",
    },
    {
      id: "DA.1.1.86",
      extend: "true",
      shortname: "da7-4-new",
      name: "",
      prevalue: "What is the usual way you use crack?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "oral",
        "nasal",
        "smoking",
        "non-IV injection",
        "IV injection",
      ],
      act: "DA7-4-NEW",
      depending: "DA0",
      exception: "Crack",
      link: "DA7-5-NEW",
      subtopic_container_act: "DA7-RANK",
      suptopic_code: "DA7_CORACK_COLUMN2",
    },
    {
      id: "DA.1.1.87",
      extend: "true",
      shortname: "da7-5-new",
      name: "",
      prevalue:
        "Have you used crack before, during, or, after sexual activity?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA7-5-NEW",
      depending: "DA0",
      exception: "Crack",
      subtopic_container_act: "DA7-RANK",
      suptopic_code: "DA7_CORACK_COLUMN2",
    },
    // {
    //   id: "DA.1.1.88",
    //   access: "private",
    //   shortname: "da7-5-new-comm",
    //   name: "",
    //   prevalue: "Comments (Crack)",
    //   value: "",
    //   disabled: "true",
    //   type: "textarea",
    //   act: "DA7-5-NEW-COMM",
    //   depending: "DA0",
    //   exception: "Crack",
    //   subtopic_container_act: "DA7-RANK",
    //   suptopic_code: "DA7_CORACK_COLUMN3",
    // },
    {
      id: "DA.1.1.88",
      access: "private",
      shortname: "da7-5-new-comm",
      name: "",
      prevalue: "Comments (Crack)",
      value: "",
      disabled: "true",
      type: "editor",
      act: "DA7-5-NEW-COMM",
      depending: "DA0",
      exception: "Crack",
      subtopic_container_act: "DA7-RANK",
      suptopic_code: "DA7_CORACK_COLUMN3",
    },
    {
      id: "DA.8",
      shortname: "startblockcrack",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "STARTBLOCKCRACK",
      depending: "",
      exception: "",
    },
    {
      id: "DA.9",
      shortname: "endbblockcrack",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "ENDBLOCKCRACK",
      depending: "",
      exception: "",
    },
    {
      id: "DA.1.1.102",
      shortname: "da7-rank1",
      name: "Crank",
      prevalue: "Rank.",
      value: "",
      disabled: "true",
      type: "rank",
      act: "DA7-RANK1",
      depending: "DA0",
      exception: "Crank",
      link: "DA7-NEW1",
    },
    {
      id: "DA.1.1.103",
      extend: "true",
      shortname: "da7-new1",
      name: "",
      prevalue: "At what age did you first use crank?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA7-NEW1",
      depending: "DA0",
      exception: "Crank",
      link: "DA7-NEW1-ADOL1",
      subtopic_container_act: "DA7-RANK1",
      suptopic_code: "DA7_CRANK_COLUMN1",
    },
    // {
    //   id: "DA.1.1.104",
    //   extend: "true",
    //   shortname: "da7-new1-adol1",
    //   name: "",
    //   prevalue:
    //     "How old were you when you first started using crank on a regular basis?",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "DA7-NEW1-ADOL1",
    //   depending: "DA0",
    //   exception: "Crank",
    //   condition: "in",
    //   link: "DA7-NEW1-ADOL2",
    //   subtopic_container_act: "DA7-RANK1",
    //   suptopic_code: "DA7_CRANK_COLUMN1",
    // },
    {
      id: "DA.1.1.104",
      extend: "true",
      shortname: "da7-new1-adol1",
      name: "",
      prevalue:
        "How old were you when you first started using crank on a regular basis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "never regular",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
      // type: "number",
      act: "DA7-NEW1-ADOL1",
      depending: "DA0",
      exception: "Crank",
      condition: "in",
      link: "DA7-NEW1-ADOL2",
      subtopic_container_act: "DA7-RANK1",
      suptopic_code: "DA7_CRANK_COLUMN1",
    },
    {
      id: "DA.1.1.105",
      extend: "true",
      shortname: "da7-new1-adol2",
      name: "",
      prevalue:
        "In the past year what was your TYPICAL pattern of your use of crank?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA7-NEW1-ADOL2",
      depending: "DA0",
      exception: "Crank",
      condition: "in",
      link: "DA7-NEW1-ADOL3",
      subtopic_container_act: "DA7-RANK1",
      suptopic_code: "DA7_CRANK_COLUMN1",
    },
    {
      id: "DA.1.1.106",
      extend: "true",
      shortname: "da7-new1-adol3",
      name: "",
      prevalue:
        "In the past year what was your PEAK pattern of your use of crank?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA7-NEW1-ADOL3",
      depending: "DA0",
      exception: "Crank",
      condition: "in",
      link: "DA7-NEW1-ADOL4",
      subtopic_container_act: "DA7-RANK1",
      suptopic_code: "DA7_CRANK_COLUMN1",
    },
    {
      id: "DA.1.1.107",
      extend: "true",
      shortname: "da7-new1-adol4",
      name: "",
      prevalue: "When you use crank, with whom do you typically use?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Alone",
        "Friend(s)",
        "Sibling(s)",
        "Parent(s)",
        "Acquaintance(s)",
        "Stranger(s)",
        "Other Specify",
      ],
      act: "DA7-NEW1-ADOL4",
      depending: "DA0",
      exception: "Crank",
      condition: "in",
      link: "DA7-NEW1-ADOL4-1",
      subtopic_container_act: "DA7-RANK1",
      suptopic_code: "DA7_CRANK_COLUMN1",
    },
    {
      id: "DA.1.1.107.1",
      extend: "true",
      shortname: "da7-new1-adol4-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA7-NEW1-ADOL4-1",
      depending: "DA7-NEW1-ADOL4",
      exception: "Other Specify",
      link: "DA7-1-NEW1",
      subtopic_container_act: "DA7-RANK1",
      suptopic_code: "DA7_CRANK_COLUMN1",
    },
    {
      id: "DA.1.1.108",
      extend: "true",
      shortname: "da7-1-new1",
      name: "",
      prevalue: "When did you last use crank?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA7-1-NEW1",
      depending: "DA0",
      exception: "Crank",
      link: "DA7-2-NEW1",
      subtopic_container_act: "DA7-RANK1",
      suptopic_code: "DA7_CRANK_COLUMN1",
    },
    {
      id: "DA.1.1.109",
      extend: "true",
      shortname: "da7-2-new1",
      name: "",
      prevalue: "How many days in the past 30 days have you used crank?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA7-2-NEW1",
      depending: "DA0",
      exception: "Crank",
      subtopic_container_act: "DA7-RANK1",
      suptopic_code: "DA7_CRANK_COLUMN1",
    },
    {
      id: "DA.1.1.110",
      shortname: "da7-3-new1",
      name: "",
      prevalue:
        "How many years in your lifetime have you used crank on a monthly or more often basis for most of a year?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA7-3-NEW1",
      depending: "DA0",
      exception: "Crank",
      link: "DA7-4-NEW1",
      subtopic_container_act: "DA7-RANK1",
      suptopic_code: "DA7_CRANK_COLUMN2",
    },
    {
      id: "DA.1.1.111",
      extend: "true",
      shortname: "da7-4-new1",
      name: "",
      prevalue: "What is the usual way you use crank?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "oral",
        "nasal",
        "smoking",
        "non-IV injection",
        "IV injection",
      ],
      act: "DA7-4-NEW1",
      depending: "DA0",
      exception: "Crank",
      link: "DA7-5-NEW1",
      subtopic_container_act: "DA7-RANK1",
      suptopic_code: "DA7_CRANK_COLUMN2",
    },
    {
      id: "DA.1.1.112",
      extend: "true",
      shortname: "da7-5-new1",
      name: "",
      prevalue:
        "Have you used crank before, during, or, after sexual activity?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA7-5-NEW1",
      depending: "DA0",
      exception: "Crank",
      subtopic_container_act: "DA7-RANK1",
      suptopic_code: "DA7_CRANK_COLUMN2",
    },
    // {
    //   id: "DA.1.1.113",
    //   access: "private",
    //   shortname: "da7-5-new1-comm",
    //   name: "",
    //   prevalue: "Comments (Crank)",
    //   value: "",
    //   disabled: "true",
    //   type: "textarea",
    //   act: "DA7-5-NEW1-COMM",
    //   depending: "DA0",
    //   exception: "Crank",
    //   subtopic_container_act: "DA7-RANK1",
    //   suptopic_code: "DA7_CRANK_COLUMN3",
    // },
    {
      id: "DA.1.1.113",
      access: "private",
      shortname: "da7-5-new1-comm",
      name: "",
      prevalue: "Comments (Crank)",
      value: "",
      disabled: "true",
      type: "editor",
      act: "DA7-5-NEW1-COMM",
      depending: "DA0",
      exception: "Crank",
      subtopic_container_act: "DA7-RANK1",
      suptopic_code: "DA7_CRANK_COLUMN3",
    },

    {
      id: "DA.10",
      shortname: "startblockcrank",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "STARTBLOCKCRANK",
      depending: "",
      exception: "",
    },
    {
      id: "DA.11",
      shortname: "endbblockcrank",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "ENDBLOCKCRANK",
      depending: "",
      exception: "",
    },
    {
      id: "DA.1.1.127",
      shortname: "da8-rank",
      name: "Ecstasy",
      prevalue: "Rank.",
      value: "",
      disabled: "true",
      type: "rank",
      act: "DA8-RANK",
      depending: "DA0",
      exception: "Ecstasy",
      link: "DA8-NEW",
    },
    {
      id: "DA.1.1.128",
      extend: "true",
      shortname: "da8-new",
      name: "",
      prevalue: "At what age did you first use ecstasy?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA8-NEW",
      depending: "DA0",
      exception: "Ecstasy",
      link: "DA8-NEW-ADOL1",
      subtopic_container_act: "DA8-RANK",
      suptopic_code: "DA8_ECSTASY_COLUMN1",
    },
    // {
    //   id: "DA.1.1.129",
    //   extend: "true",
    //   shortname: "da8-new-adol1",
    //   name: "",
    //   prevalue:
    //     "How old were you when you first started using ecstasy on a regular basis?",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "DA8-NEW-ADOL1",
    //   depending: "DA0",
    //   exception: "Ecstasy",
    //   condition: "in",
    //   link: "DA8-NEW-ADOL2",
    //   subtopic_container_act: "DA8-RANK",
    //   suptopic_code: "DA8_ECSTASY_COLUMN1",
    // },
    {
      id: "DA.1.1.129",
      extend: "true",
      shortname: "da8-new-adol1",
      name: "",
      prevalue:
        "How old were you when you first started using ecstasy on a regular basis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "never regular",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
      // type: "number",
      act: "DA8-NEW-ADOL1",
      depending: "DA0",
      exception: "Ecstasy",
      condition: "in",
      link: "DA8-NEW-ADOL2",
      subtopic_container_act: "DA8-RANK",
      suptopic_code: "DA8_ECSTASY_COLUMN1",
    },
    {
      id: "DA.1.1.130",
      extend: "true",
      shortname: "da8-new-adol2",
      name: "",
      prevalue:
        "In the past year what was your TYPICAL pattern of your use of ecstasy?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA8-NEW-ADOL2",
      depending: "DA0",
      exception: "Ecstasy",
      condition: "in",
      link: "DA8-NEW-ADOL3",
      subtopic_container_act: "DA8-RANK",
      suptopic_code: "DA8_ECSTASY_COLUMN1",
    },
    {
      id: "DA.1.1.131",
      extend: "true",
      shortname: "da8-new-adol3",
      name: "",
      prevalue:
        "In the past year what was your PEAK pattern of your use of ecstasy?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA8-NEW-ADOL3",
      depending: "DA0",
      exception: "Ecstasy",
      condition: "in",
      link: "DA8-NEW-ADOL4",
      subtopic_container_act: "DA8-RANK",
      suptopic_code: "DA8_ECSTASY_COLUMN1",
    },
    {
      id: "DA.1.1.132",
      extend: "true",
      shortname: "da8-new-adol4",
      name: "",
      prevalue: "When you use ecstasy, with whom do you typically use?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Alone",
        "Friend(s)",
        "Sibling(s)",
        "Parent(s)",
        "Acquaintance(s)",
        "Stranger(s)",
        "Other Specify",
      ],
      act: "DA8-NEW-ADOL4",
      depending: "DA0",
      exception: "Ecstasy",
      condition: "in",
      link: "DA8-NEW-ADOL4-1",
      subtopic_container_act: "DA8-RANK",
      suptopic_code: "DA8_ECSTASY_COLUMN1",
    },
    {
      id: "DA.1.1.132.1",
      extend: "true",
      shortname: "da8-new-adol4-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA8-NEW-ADOL4-1",
      depending: "DA8-NEW-ADOL4",
      exception: "Other Specify",
      link: "DA8-1-NEW",
      subtopic_container_act: "DA8-RANK",
      suptopic_code: "DA8_ECSTASY_COLUMN1",
    },
    {
      id: "DA.1.1.133",
      extend: "true",
      shortname: "da8-1-new",
      name: "",
      prevalue: "When did you last use ecstasy?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA8-1-NEW",
      depending: "DA0",
      exception: "Ecstasy",
      link: "DA8-2-NEW",
      subtopic_container_act: "DA8-RANK",
      suptopic_code: "DA8_ECSTASY_COLUMN1",
    },
    {
      id: "DA.1.1.134",
      extend: "true",
      shortname: "da8-2-new",
      name: "",
      prevalue: "How many days in the past 30 days have you used ecstasy?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA8-2-NEW",
      depending: "DA0",
      exception: "Ecstasy",
      subtopic_container_act: "DA8-RANK",
      suptopic_code: "DA8_ECSTASY_COLUMN1",
    },
    {
      id: "DA.1.1.135",
      shortname: "da8-3-new",
      name: "",
      prevalue:
        "How many years of regular use (more months than not) have you used ecstasy?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA8-3-NEW",
      depending: "DA0",
      exception: "Ecstasy",
      link: "DA8-4-NEW",
      subtopic_container_act: "DA8-RANK",
      suptopic_code: "DA8_ECSTASY_COLUMN2",
    },
    {
      id: "DA.1.1.136",
      extend: "true",
      shortname: "da8-4-new",
      name: "",
      prevalue: "What is the usual way you use ecstasy?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "oral",
        "nasal",
        "smoking",
        "non-IV injection",
        "IV injection",
      ],
      act: "DA8-4-NEW",
      depending: "DA0",
      exception: "Ecstasy",
      link: "DA8-5-NEW",
      subtopic_container_act: "DA8-RANK",
      suptopic_code: "DA8_ECSTASY_COLUMN2",
    },
    {
      id: "DA.1.1.137",
      extend: "true",
      shortname: "da8-5-new",
      name: "",
      prevalue:
        "Have you used ecstasy before, during, or, after sexual activity?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA8-5-NEW",
      depending: "DA0",
      exception: "Ecstasy",
      subtopic_container_act: "DA8-RANK",
      suptopic_code: "DA8_ECSTASY_COLUMN2",
    },
    // {
    //   id: "DA.1.1.138",
    //   access: "private",
    //   shortname: "da8-5-new-comm",
    //   name: "",
    //   prevalue: "Comments (Ecstasy)",
    //   value: "",
    //   disabled: "true",
    //   type: "textarea",
    //   act: "DA8-5-NEW-COMM",
    //   depending: "DA0",
    //   exception: "Ecstasy",
    //   subtopic_container_act: "DA8-RANK",
    //   suptopic_code: "DA8_ECSTASY_COLUMN3",
    // },
    {
      id: "DA.1.1.138",
      access: "private",
      shortname: "da8-5-new-comm",
      name: "",
      prevalue: "Comments (Ecstasy)",
      value: "",
      disabled: "true",
      type: "editor",
      act: "DA8-5-NEW-COMM",
      depending: "DA0",
      exception: "Ecstasy",
      subtopic_container_act: "DA8-RANK",
      suptopic_code: "DA8_ECSTASY_COLUMN3",
    },
    {
      id: "DA.12",
      shortname: "startblockecstasy",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "STARTBLOCKECSTASY",
      depending: "",
      exception: "",
    },
    {
      id: "DA.13",
      shortname: "endbblockecstasy",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "ENDBLOCKECSTASY",
      depending: "",
      exception: "",
    },
    {
      id: "DA.1.1.152",
      shortname: "da8-rank0",
      name: "Hallucinogens /LSD/ mushrooms, etc.",
      prevalue: "Rank.",
      value: "",
      disabled: "true",
      type: "rank",
      act: "DA8-RANK0",
      depending: "DA0",
      exception: "Hallucinogens /LSD/mushrooms, etc.",
      link: "DA8",
    },
    {
      id: "DA.1.1.153",
      extend: "true",
      shortname: "da8",
      name: "",
      prevalue:
        "At what age did you first use hallucinogens/LSD/mushrooms, etc?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA8",
      depending: "DA0",
      exception: "Hallucinogens /LSD/mushrooms, etc.",
      link: "DA8-1",
      subtopic_container_act: "DA8-RANK0",
      suptopic_code: "DA8_HAL_COLUMN1",
    },
    // {
    //   id: "DA.1.1.154",
    //   extend: "true",
    //   shortname: "da8-adol1",
    //   name: "",
    //   prevalue:
    //     "How old were you when you first started using hallucinogens/LSD/mushrooms on a regular basis?",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "DA8-ADOL1",
    //   depending: "DA0",
    //   exception: "Hallucinogens /LSD/mushrooms, etc.",
    //   condition: "in",
    //   link: "DA8-ADOL2",
    //   subtopic_container_act: "DA8-RANK0",
    //   suptopic_code: "DA8_HAL_COLUMN1",
    // },
    {
      id: "DA.1.1.154",
      extend: "true",
      shortname: "da8-adol1",
      name: "",
      prevalue:
        "How old were you when you first started using hallucinogens/LSD/mushrooms on a regular basis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "never regular",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
      // type: "number",
      act: "DA8-ADOL1",
      depending: "DA0",
      exception: "Hallucinogens /LSD/mushrooms, etc.",
      condition: "in",
      link: "DA8-ADOL2",
      subtopic_container_act: "DA8-RANK0",
      suptopic_code: "DA8_HAL_COLUMN1",
    },
    {
      id: "DA.1.1.155",
      extend: "true",
      shortname: "da8-adol2",
      name: "",
      prevalue:
        "In the past year what was your TYPICAL pattern of your use of hallucinogens/LSD/mushrooms?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA8-ADOL2",
      depending: "DA0",
      exception: "Hallucinogens /LSD/mushrooms, etc.",
      condition: "in",
      link: "DA8-ADOL3",
      subtopic_container_act: "DA8-RANK0",
      suptopic_code: "DA8_HAL_COLUMN1",
    },
    {
      id: "DA.1.1.156",
      extend: "true",
      shortname: "da8-adol3",
      name: "",
      prevalue:
        "In the past year what was your PEAK pattern of your use of hallucinogens/LSD/mushrooms?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA8-ADOL3",
      depending: "DA0",
      exception: "Hallucinogens /LSD/mushrooms, etc.",
      condition: "in",
      link: "DA8-ADOL4",
      subtopic_container_act: "DA8-RANK0",
      suptopic_code: "DA8_HAL_COLUMN1",
    },
    {
      id: "DA.1.1.157",
      extend: "true",
      shortname: "da8-adol4",
      name: "",
      prevalue:
        "When you use hallucinogens/LSD/mushrooms, with whom do you typically use?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Alone",
        "Friend(s)",
        "Sibling(s)",
        "Parent(s)",
        "Acquaintance(s)",
        "Stranger(s)",
        "Other Specify",
      ],
      act: "DA8-ADOL4",
      depending: "DA0",
      exception: "Hallucinogens /LSD/mushrooms, etc.",
      condition: "in",
      link: "DA8-ADOL4-1",
      subtopic_container_act: "DA8-RANK0",
      suptopic_code: "DA8_HAL_COLUMN1",
    },
    {
      id: "DA.1.1.157.1",
      extend: "true",
      shortname: "da8-adol4-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA8-ADOL4-1",
      depending: "DA8-ADOL4",
      exception: "Other Specify",
      link: "DA8-1",
      subtopic_container_act: "DA8-RANK0",
      suptopic_code: "DA8_HAL_COLUMN1",
    },
    {
      id: "DA.1.1.158",
      extend: "true",
      shortname: "da8-1",
      name: "",
      prevalue: "When did you last use hallucinogens/LSD/mushrooms, etc?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA8-1",
      depending: "DA0",
      exception: "Hallucinogens /LSD/mushrooms, etc.",
      link: "DA8-2",
      subtopic_container_act: "DA8-RANK0",
      suptopic_code: "DA8_HAL_COLUMN1",
    },
    {
      id: "DA.1.1.159",
      extend: "true",
      shortname: "da8-2",
      name: "",
      prevalue:
        "How many days in the past 30 days have you used hallucinogens/LSD/mushrooms, etc?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA8-2",
      depending: "DA0",
      exception: "Hallucinogens /LSD/mushrooms, etc.",
      subtopic_container_act: "DA8-RANK0",
      suptopic_code: "DA8_HAL_COLUMN1",
    },
    {
      id: "DA.1.1.160",
      shortname: "da8-3",
      name: "",
      prevalue:
        "How many years in your lifetime have you used hallucinogens/LSD/mushrooms, etc on a monthly or more often basis for most of a year?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA8-3",
      depending: "DA0",
      exception: "Hallucinogens /LSD/mushrooms, etc.",
      link: "DA8-4",
      subtopic_container_act: "DA8-RANK0",
      suptopic_code: "DA8_HAL_COLUMN2",
    },
    {
      id: "DA.1.1.161",
      extend: "true",
      shortname: "da8-4",
      name: "",
      prevalue:
        "What is the usual way you use hallucinogens/LSD/mushrooms, etc?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "oral",
        "nasal",
        "smoking",
        "non-IV injection",
        "IV injection",
      ],
      act: "DA8-4",
      depending: "DA0",
      exception: "Hallucinogens /LSD/mushrooms, etc.",
      link: "DA8-5",
      subtopic_container_act: "DA8-RANK0",
      suptopic_code: "DA8_HAL_COLUMN2",
    },
    {
      id: "DA.1.1.162",
      extend: "true",
      shortname: "da8-5",
      name: "",
      prevalue:
        "Have you used hallucinogens/LSD/mushrooms, etc before, during, or, after sexual activity?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA8-5",
      depending: "DA0",
      exception: "Hallucinogens /LSD/mushrooms, etc.",
      subtopic_container_act: "DA8-RANK0",
      suptopic_code: "DA8_HAL_COLUMN2",
    },
    // {
    //   id: "DA.1.1.163",
    //   access: "private",
    //   shortname: "da8-5-comm",
    //   name: "",
    //   prevalue: "Comments (Hallucinogens /LSD/mushrooms, etc.)",
    //   value: "",
    //   disabled: "true",
    //   type: "textarea",
    //   act: "DA8-5-COMM",
    //   depending: "DA0",
    //   exception: "Hallucinogens /LSD/mushrooms, etc.",
    //   subtopic_container_act: "DA8-RANK0",
    //   suptopic_code: "DA8_HAL_COLUMN3",
    // },
    {
      id: "DA.1.1.163",
      access: "private",
      shortname: "da8-5-comm",
      name: "",
      prevalue: "Comments (Hallucinogens /LSD/mushrooms, etc.)",
      value: "",
      disabled: "true",
      type: "editor",
      act: "DA8-5-COMM",
      depending: "DA0",
      exception: "Hallucinogens /LSD/mushrooms, etc.",
      subtopic_container_act: "DA8-RANK0",
      suptopic_code: "DA8_HAL_COLUMN3",
    },
    {
      id: "DA.14",
      shortname: "startblockhallucinogens",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "STARTBLOCKHALLUCINOGENS",
      depending: "",
      exception: "",
    },
    {
      id: "DA.15",
      shortname: "endbblockhallucinogens",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "ENDBLOCKHALLUCINOGENS",
      depending: "",
      exception: "",
    },
    {
      id: "DA.1.1.177",
      shortname: "da9-rank",
      name: "Heroin",
      prevalue: "Rank.",
      value: "",
      disabled: "true",
      type: "rank",
      act: "DA9-RANK",
      depending: "DA0",
      exception: "Heroin",
      link: "DA9",
    },
    {
      id: "DA.1.1.178",
      extend: "true",
      shortname: "da9",
      name: "",
      prevalue: "At what age did you first use heroin?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA9",
      depending: "DA0",
      exception: "Heroin",
      link: "DA9-1",
      subtopic_container_act: "DA9-RANK",
      suptopic_code: "DA9_HEROIN_COLUMN1",
    },
    // {
    //   id: "DA.1.1.179",
    //   extend: "true",
    //   shortname: "da9-adol1",
    //   name: "",
    //   prevalue:
    //     "How old were you when you first started using heroin on a regular basis?",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "DA9-ADOL1",
    //   depending: "DA0",
    //   exception: "Heroin",
    //   condition: "in",
    //   link: "DA9-ADOL2",
    //   subtopic_container_act: "DA9-RANK",
    //   suptopic_code: "DA9_HEROIN_COLUMN1",
    // },
    {
      id: "DA.1.1.179",
      extend: "true",
      shortname: "da9-adol1",
      name: "",
      prevalue:
        "How old were you when you first started using heroin on a regular basis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "never regular",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
      // type: "number",
      act: "DA9-ADOL1",
      depending: "DA0",
      exception: "Heroin",
      condition: "in",
      link: "DA9-ADOL2",
      subtopic_container_act: "DA9-RANK",
      suptopic_code: "DA9_HEROIN_COLUMN1",
    },
    {
      id: "DA.1.1.180",
      extend: "true",
      shortname: "da9-adol2",
      name: "",
      prevalue:
        "In the past year what was your TYPICAL pattern of your use of heroin?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA9-ADOL2",
      depending: "DA0",
      exception: "Heroin",
      condition: "in",
      link: "DA9-ADOL3",
      subtopic_container_act: "DA9-RANK",
      suptopic_code: "DA9_HEROIN_COLUMN1",
    },
    {
      id: "DA.1.1.181",
      extend: "true",
      shortname: "da9-adol3",
      name: "",
      prevalue:
        "In the past year what was your PEAK pattern of your use of heroin?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA9-ADOL3",
      depending: "DA0",
      exception: "Heroin",
      condition: "in",
      link: "DA9-ADOL4",
      subtopic_container_act: "DA9-RANK",
      suptopic_code: "DA9_HEROIN_COLUMN1",
    },
    {
      id: "DA.1.1.182",
      extend: "true",
      shortname: "da9-adol4",
      name: "",
      prevalue: "When you use heroin, with whom do you typically use?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Alone",
        "Friend(s)",
        "Sibling(s)",
        "Parent(s)",
        "Acquaintance(s)",
        "Stranger(s)",
        "Other Specify",
      ],
      act: "DA9-ADOL4",
      depending: "DA0",
      exception: "Heroin",
      condition: "in",
      link: "DA9-ADOL4-1",
      subtopic_container_act: "DA9-RANK",
      suptopic_code: "DA9_HEROIN_COLUMN1",
    },
    {
      id: "DA.1.1.182.1",
      extend: "true",
      shortname: "da9-adol4-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA9-ADOL4-1",
      depending: "DA9-ADOL4",
      exception: "Other Specify",
      link: "DA9-1",
      subtopic_container_act: "DA9-RANK",
      suptopic_code: "DA9_HEROIN_COLUMN1",
    },
    {
      id: "DA.1.1.183",
      extend: "true",
      shortname: "da9-1",
      name: "",
      prevalue: "When did you last use heroin?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA9-1",
      depending: "DA0",
      exception: "Heroin",
      link: "DA9-2",
      subtopic_container_act: "DA9-RANK",
      suptopic_code: "DA9_HEROIN_COLUMN1",
    },
    {
      id: "DA.1.1.184",
      extend: "true",
      shortname: "da9-2",
      name: "",
      prevalue: "How many days in the past 30 days have you used heroin?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA9-2",
      depending: "DA0",
      exception: "Heroin",
      subtopic_container_act: "DA9-RANK",
      suptopic_code: "DA9_HEROIN_COLUMN1",
    },
    {
      id: "DA.1.1.185",
      shortname: "da9-3",
      name: "",
      prevalue:
        "How many years of regular use (more months than not) have you used heroin?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA9-3",
      depending: "DA0",
      exception: "Heroin",
      link: "DA9-4",
      subtopic_container_act: "DA9-RANK",
      suptopic_code: "DA9_HEROIN_COLUMN2",
    },
    {
      id: "DA.1.1.186",
      extend: "true",
      shortname: "da9-4",
      name: "",
      prevalue: "What is the usual way you use heroin?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "oral",
        "nasal",
        "smoking",
        "non-IV injection",
        "IV injection",
      ],
      act: "DA9-4",
      depending: "DA0",
      exception: "Heroin",
      link: "DA9-5",
      subtopic_container_act: "DA9-RANK",
      suptopic_code: "DA9_HEROIN_COLUMN2",
    },
    {
      id: "DA.1.1.187",
      extend: "true",
      shortname: "da9-5",
      name: "",
      prevalue:
        "Have you used heroin before, during, or, after sexual activity?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA9-5",
      depending: "DA0",
      exception: "Heroin",
      subtopic_container_act: "DA9-RANK",
      suptopic_code: "DA9_HEROIN_COLUMN2",
    },
    // {
    //   id: "DA.1.1.188",
    //   access: "private",
    //   shortname: "da9-5-comm",
    //   name: "",
    //   prevalue: "Comments (Heroin)",
    //   value: "",
    //   disabled: "true",
    //   type: "textarea",
    //   act: "DA9-5-COMM",
    //   depending: "DA0",
    //   exception: "Heroin",
    //   subtopic_container_act: "DA9-RANK",
    //   suptopic_code: "DA9_HEROIN_COLUMN3",
    // },
    {
      id: "DA.1.1.188",
      access: "private",
      shortname: "da9-5-comm",
      name: "",
      prevalue: "Comments (Heroin)",
      value: "",
      disabled: "true",
      type: "editor",
      act: "DA9-5-COMM",
      depending: "DA0",
      exception: "Heroin",
      subtopic_container_act: "DA9-RANK",
      suptopic_code: "DA9_HEROIN_COLUMN3",
    },

    {
      id: "DA.16",
      shortname: "startblockheroin",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "STARTBLOCKHEROIN",
      depending: "",
      exception: "",
    },
    {
      id: "DA.17",
      shortname: "endbblockheroin",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "ENDBLOCKHEROIN",
      depending: "",
      exception: "",
    },
    {
      id: "DA.1.1.202",
      shortname: "da10-rank",
      name: "Inhalants",
      prevalue: "Rank.",
      value: "",
      disabled: "true",
      type: "rank",
      act: "DA10-RANK",
      depending: "DA0",
      exception: "Inhalants",
      link: "DA10",
    },
    {
      id: "DA.1.1.203",
      extend: "true",
      shortname: "da10",
      name: "",
      prevalue: "At what age did you first use inhalants?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA10",
      depending: "DA0",
      exception: "Inhalants",
      link: "DA10-1",
      subtopic_container_act: "DA10-RANK",
      suptopic_code: "DA10_INHALANTS_COLUMN1",
    },
    // {
    //   id: "DA.1.1.204",
    //   extend: "true",
    //   shortname: "da10-adol1",
    //   name: "",
    //   prevalue:
    //     "How old were you when you first started using inhalants on a regular basis?",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "DA10-ADOL1",
    //   depending: "DA0",
    //   exception: "Inhalants",
    //   condition: "in",
    //   link: "DA10-ADOL2",
    //   subtopic_container_act: "DA10-RANK",
    //   suptopic_code: "DA10_INHALANTS_COLUMN1",
    // },
    {
      id: "DA.1.1.204",
      extend: "true",
      shortname: "da10-adol1",
      name: "",
      prevalue:
        "How old were you when you first started using inhalants on a regular basis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "never regular",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
      // type: "number",
      act: "DA10-ADOL1",
      depending: "DA0",
      exception: "Inhalants",
      condition: "in",
      link: "DA10-ADOL2",
      subtopic_container_act: "DA10-RANK",
      suptopic_code: "DA10_INHALANTS_COLUMN1",
    },
    {
      id: "DA.1.1.205",
      extend: "true",
      shortname: "da10-adol2",
      name: "",
      prevalue:
        "In the past year what was your TYPICAL pattern of your use of inhalants?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA10-ADOL2",
      depending: "DA0",
      exception: "Inhalants",
      condition: "in",
      link: "DA10-ADOL3",
      subtopic_container_act: "DA10-RANK",
      suptopic_code: "DA10_INHALANTS_COLUMN1",
    },
    {
      id: "DA.1.1.206",
      extend: "true",
      shortname: "da10-adol3",
      name: "",
      prevalue:
        "In the past year what was your PEAK pattern of your use of inhalants?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA10-ADOL3",
      depending: "DA0",
      exception: "Inhalants",
      condition: "in",
      link: "DA10-ADOL4",
      subtopic_container_act: "DA10-RANK",
      suptopic_code: "DA10_INHALANTS_COLUMN1",
    },
    {
      id: "DA.1.1.207",
      extend: "true",
      shortname: "da10-adol4",
      name: "",
      prevalue: "When you use inhalants, with whom do you typically use?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Alone",
        "Friend(s)",
        "Sibling(s)",
        "Parent(s)",
        "Acquaintance(s)",
        "Stranger(s)",
        "Other Specify",
      ],
      act: "DA10-ADOL4",
      depending: "DA0",
      exception: "Inhalants",
      condition: "in",
      link: "DA10-ADOL4-1",
      subtopic_container_act: "DA10-RANK",
      suptopic_code: "DA10_INHALANTS_COLUMN1",
    },
    {
      id: "DA.1.1.207.1",
      extend: "true",
      shortname: "da10-adol4-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA10-ADOL4-1",
      depending: "DA10-ADOL4",
      exception: "Other Specify",
      link: "DA10",
      subtopic_container_act: "DA10-RANK",
      suptopic_code: "DA10_INHALANTS_COLUMN1",
    },
    {
      id: "DA.1.1.208",
      extend: "true",
      shortname: "da10-1",
      name: "",
      prevalue: "When did you last use inhalants?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA10-1",
      depending: "DA0",
      exception: "Inhalants",
      link: "DA10-2",
      subtopic_container_act: "DA10-RANK",
      suptopic_code: "DA10_INHALANTS_COLUMN1",
    },
    {
      id: "DA.1.1.209",
      extend: "true",
      shortname: "da10-2",
      name: "",
      prevalue: "How many days in the past 30 days have you used inhalants?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA10-2",
      depending: "DA0",
      exception: "Inhalants",
      subtopic_container_act: "DA10-RANK",
      suptopic_code: "DA10_INHALANTS_COLUMN1",
    },
    {
      id: "DA.1.1.210",
      shortname: "da10-3",
      name: "",
      prevalue:
        "How many years of regular use (more months than not) have you used inhalants?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA10-3",
      depending: "DA0",
      exception: "Inhalants",
      link: "DA10-4",
      subtopic_container_act: "DA10-RANK",
      suptopic_code: "DA10_INHALANTS_COLUMN2",
    },
    {
      id: "DA.1.1.211",
      extend: "true",
      shortname: "da10-4",
      name: "",
      prevalue: "What is the usual way you use inhalants?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "oral",
        "nasal",
        "smoking",
        "non-IV injection",
        "IV injection",
      ],
      act: "DA10-4",
      depending: "DA0",
      exception: "Inhalants",
      link: "DA10-5",
      subtopic_container_act: "DA10-RANK",
      suptopic_code: "DA10_INHALANTS_COLUMN2",
    },
    {
      id: "DA.1.1.212",
      extend: "true",
      shortname: "da10-5",
      name: "",
      prevalue:
        "Have you used inhalants before, during, or, after sexual activity?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA10-5",
      depending: "DA0",
      exception: "Inhalants",
      subtopic_container_act: "DA10-RANK",
      suptopic_code: "DA10_INHALANTS_COLUMN2",
    },
    // {
    //   id: "DA.1.1.213",
    //   access: "private",
    //   shortname: "da10-5-comm",
    //   name: "",
    //   prevalue: "Comments (Inhalants)",
    //   value: "",
    //   disabled: "true",
    //   type: "textarea",
    //   act: "DA10-5-COMM",
    //   depending: "DA0",
    //   exception: "Inhalants",
    //   subtopic_container_act: "DA10-RANK",
    //   suptopic_code: "DA10_INHALANTS_COLUMN3",
    // },
    {
      id: "DA.1.1.213",
      access: "private",
      shortname: "da10-5-comm",
      name: "",
      prevalue: "Comments (Inhalants)",
      value: "",
      disabled: "true",
      type: "editor",
      act: "DA10-5-COMM",
      depending: "DA0",
      exception: "Inhalants",
      subtopic_container_act: "DA10-RANK",
      suptopic_code: "DA10_INHALANTS_COLUMN3",
    },
    {
      id: "DA.18",
      shortname: "startblockinhalants",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "STARTBLOCKINHALANTS",
      depending: "",
      exception: "",
    },
    {
      id: "DA.19",
      shortname: "endbblockinhalants",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "ENDBLOCKINHALANTS",
      depending: "",
      exception: "",
    },
    {
      id: "DA.1.1.227",
      shortname: "da11-rank",
      name: "Meth or other Amphetamines",
      prevalue: "Rank.",
      value: "",
      disabled: "true",
      type: "rank",
      act: "DA11-RANK",
      depending: "DA0",
      exception: "Meth or other Amphetamines",
      link: "DA11",
    },
    {
      id: "DA.1.1.228",
      extend: "true",
      shortname: "da11",
      name: "",
      prevalue: "At what age did you first use meth or other amphetamines?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA11",
      depending: "DA0",
      exception: "Meth or other Amphetamines",
      link: "DA11-1",
      subtopic_container_act: "DA11-RANK",
      suptopic_code: "DAaa_METH_COLUMN1",
    },
    // {
    //   id: "DA.1.1.229",
    //   extend: "true",
    //   shortname: "da11-adol1",
    //   name: "",
    //   prevalue:
    //     "How old were you when you first started using meth or other amphetamines on a regular basis?",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "DA11-ADOL1",
    //   depending: "DA0",
    //   exception: "Meth or other Amphetamines",
    //   condition: "in",
    //   link: "DA11-ADOL2",
    //   subtopic_container_act: "DA11-RANK",
    //   suptopic_code: "DAaa_METH_COLUMN1",
    // },
    {
      id: "DA.1.1.229",
      extend: "true",
      shortname: "da11-adol1",
      name: "",
      prevalue:
        "How old were you when you first started using meth or other amphetamines on a regular basis?",
      value: "",
      disabled: "true",
      // type: "number",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "never regular",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
      act: "DA11-ADOL1",
      depending: "DA0",
      exception: "Meth or other Amphetamines",
      condition: "in",
      link: "DA11-ADOL2",
      subtopic_container_act: "DA11-RANK",
      suptopic_code: "DAaa_METH_COLUMN1",
    },
    {
      id: "DA.1.1.230",
      extend: "true",
      shortname: "da11-adol2",
      name: "",
      prevalue:
        "In the past year what was your TYPICAL pattern of your use of meth or other amphetamines?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA11-ADOL2",
      depending: "DA0",
      exception: "Meth or other Amphetamines",
      condition: "in",
      link: "DA11-ADOL3",
      subtopic_container_act: "DA11-RANK",
      suptopic_code: "DAaa_METH_COLUMN1",
    },
    {
      id: "DA.1.1.231",
      extend: "true",
      shortname: "da11-adol3",
      name: "",
      prevalue:
        "In the past year what was your PEAK pattern of your use of meth or other amphetamines?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA11-ADOL3",
      depending: "DA0",
      exception: "Meth or other Amphetamines",
      condition: "in",
      link: "DA11-ADOL4",
      subtopic_container_act: "DA11-RANK",
      suptopic_code: "DAaa_METH_COLUMN1",
    },
    {
      id: "DA.1.1.232",
      extend: "true",
      shortname: "da11-adol4",
      name: "",
      prevalue:
        "When you use meth or other amphetamines, with whom do you typically use?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Alone",
        "Friend(s)",
        "Sibling(s)",
        "Parent(s)",
        "Acquaintance(s)",
        "Stranger(s)",
        "Other Specify",
      ],
      act: "DA11-ADOL4",
      depending: "DA0",
      exception: "Meth or other Amphetamines",
      condition: "in",
      link: "DA11-ADOL4-1",
      subtopic_container_act: "DA11-RANK",
      suptopic_code: "DAaa_METH_COLUMN1",
    },
    {
      id: "DA.1.1.232.1",
      extend: "true",
      shortname: "da11-adol4-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA11-ADOL4-1",
      depending: "DA11-ADOL4",
      exception: "Other Specify",
      link: "DA11-1",
      subtopic_container_act: "DA11-RANK",
      suptopic_code: "DAaa_METH_COLUMN1",
    },
    {
      id: "DA.1.1.233",
      extend: "true",
      shortname: "da11-1",
      name: "",
      prevalue: "When did you last use meth or other amphetamines?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA11-1",
      depending: "DA0",
      exception: "Meth or other Amphetamines",
      link: "DA11-2",
      subtopic_container_act: "DA11-RANK",
      suptopic_code: "DAaa_METH_COLUMN1",
    },
    {
      id: "DA.1.1.234",
      extend: "true",
      shortname: "da11-2",
      name: "",
      prevalue:
        "How many days in the past 30 days have you used meth or other amphetamines?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA11-2",
      depending: "DA0",
      exception: "Meth or other Amphetamines",
      subtopic_container_act: "DA11-RANK",
      suptopic_code: "DAaa_METH_COLUMN1",
    },
    {
      id: "DA.1.1.235",
      shortname: "da11-3",
      name: "",
      prevalue:
        "How many years of regular use (more months than not) have you used meth or other amphetamines?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA11-3",
      depending: "DA0",
      exception: "Meth or other Amphetamines",
      link: "DA11-4",
      subtopic_container_act: "DA11-RANK",
      suptopic_code: "DAaa_METH_COLUMN2",
    },
    {
      id: "DA.1.1.236",
      extend: "true",
      shortname: "da11-4",
      name: "",
      prevalue: "What is the usual way you use meth or other amphetamines?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "oral",
        "nasal",
        "smoking",
        "non-IV injection",
        "IV injection",
      ],
      act: "DA11-4",
      depending: "DA0",
      exception: "Meth or other Amphetamines",
      link: "DA11-5",
      subtopic_container_act: "DA11-RANK",
      suptopic_code: "DAaa_METH_COLUMN2",
    },
    {
      id: "DA.1.1.237",
      extend: "true",
      shortname: "da11-5",
      name: "",
      prevalue:
        "Have you used meth or other amphetamines before, during, or, after sexual activity?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA11-5",
      depending: "DA0",
      exception: "Meth or other Amphetamines",
      subtopic_container_act: "DA11-RANK",
      suptopic_code: "DAaa_METH_COLUMN2",
    },
    // {
    //   id: "DA.1.1.238",
    //   access: "private",
    //   shortname: "da11-5-comm",
    //   name: "",
    //   prevalue: "Comments (Meth or other Amphetamines)",
    //   value: "",
    //   disabled: "true",
    //   type: "textarea",
    //   act: "DA11-5-COMM",
    //   depending: "DA0",
    //   exception: "Meth or other Amphetamines",
    //   subtopic_container_act: "DA11-RANK",
    //   suptopic_code: "DAaa_METH_COLUMN3",
    // },
    {
      id: "DA.1.1.238",
      access: "private",
      shortname: "da11-5-comm",
      name: "",
      prevalue: "Comments (Meth or other Amphetamines)",
      value: "",
      disabled: "true",
      type: "editor",
      act: "DA11-5-COMM",
      depending: "DA0",
      exception: "Meth or other Amphetamines",
      subtopic_container_act: "DA11-RANK",
      suptopic_code: "DAaa_METH_COLUMN3",
    },

    {
      id: "DA.20",
      shortname: "startblockmeth",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "STARTBLOCKMETH",
      depending: "",
      exception: "",
    },
    {
      id: "DA.21",
      shortname: "endbblockmeth",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "ENDBLOCKMETH",
      depending: "",
      exception: "",
    },
    {
      id: "DA.1.1.252",
      shortname: "da12-rank",
      name: "Methadone",
      prevalue: "Rank.",
      value: "",
      disabled: "true",
      type: "rank",
      act: "DA12-RANK",
      depending: "DA0",
      exception: "Methadone",
      link: "DA12",
    },
    {
      id: "DA.1.1.253",
      extend: "true",
      shortname: "da12",
      name: "",
      prevalue: "At what age did you first use methadone?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA12",
      depending: "DA0",
      exception: "Methadone",
      link: "DA12-ADOL1",
      subtopic_container_act: "DA12-RANK",
      suptopic_code: "DA12_METHADONE_COLUMN1",
    },
    // {
    //   id: "DA.1.1.254",
    //   extend: "true",
    //   shortname: "da12-adol1",
    //   name: "",
    //   prevalue:
    //     "How old were you when you first started using methadone on a regular basis?",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "DA12-ADOL1",
    //   depending: "DA0",
    //   exception: "Methadone",
    //   condition: "in",
    //   link: "DA12-ADOL2",
    //   subtopic_container_act: "DA12-RANK",
    //   suptopic_code: "DA12_METHADONE_COLUMN1",
    // },
    {
      id: "DA.1.1.254",
      extend: "true",
      shortname: "da12-adol1",
      name: "",
      prevalue:
        "How old were you when you first started using methadone on a regular basis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "never regular",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
      // type: "number",
      act: "DA12-ADOL1",
      depending: "DA0",
      exception: "Methadone",
      condition: "in",
      link: "DA12-ADOL2",
      subtopic_container_act: "DA12-RANK",
      suptopic_code: "DA12_METHADONE_COLUMN1",
    },
    {
      id: "DA.1.1.255",
      extend: "true",
      shortname: "da12-adol2",
      name: "",
      prevalue:
        "In the past year what was your TYPICAL pattern of your use of methadone?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA12-ADOL2",
      depending: "DA0",
      exception: "Methadone",
      condition: "in",
      link: "DA12-ADOL3",
      subtopic_container_act: "DA12-RANK",
      suptopic_code: "DA12_METHADONE_COLUMN1",
    },
    {
      id: "DA.1.1.256",
      extend: "true",
      shortname: "da12-adol3",
      name: "",
      prevalue:
        "In the past year what was your PEAK pattern of your use of methadone?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA12-ADOL3",
      depending: "DA0",
      exception: "Methadone",
      condition: "in",
      link: "DA12-ADOL4",
      subtopic_container_act: "DA12-RANK",
      suptopic_code: "DA12_METHADONE_COLUMN1",
    },
    {
      id: "DA.1.1.257",
      extend: "true",
      shortname: "da12-adol4",
      name: "",
      prevalue: "When you use methadone, with whom do you typically use?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Alone",
        "Friend(s)",
        "Sibling(s)",
        "Parent(s)",
        "Acquaintance(s)",
        "Stranger(s)",
        "Other Specify",
      ],
      act: "DA12-ADOL4",
      depending: "DA0",
      exception: "Methadone",
      condition: "in",
      link: "DA12-ADOL4-1",
      subtopic_container_act: "DA12-RANK",
      suptopic_code: "DA12_METHADONE_COLUMN1",
    },
    {
      id: "DA.1.1.257.1",
      extend: "true",
      shortname: "da12-adol4-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA12-ADOL4-1",
      depending: "DA12-ADOL4",
      exception: "Other Specify",
      link: "DA12-1",
      subtopic_container_act: "DA12-RANK",
      suptopic_code: "DA12_METHADONE_COLUMN1",
    },
    {
      id: "DA.1.1.258",
      extend: "true",
      shortname: "da12-1",
      name: "",
      prevalue: "When did you last use methadone?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA12-1",
      depending: "DA0",
      exception: "Methadone",
      link: "DA12-2",
      subtopic_container_act: "DA12-RANK",
      suptopic_code: "DA12_METHADONE_COLUMN1",
    },
    {
      id: "DA.1.1.259",
      extend: "true",
      shortname: "da12-2",
      name: "",
      prevalue: "How many days in the past 30 days have you used methadone?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA12-2",
      depending: "DA0",
      exception: "Methadone",
      subtopic_container_act: "DA12-RANK",
      suptopic_code: "DA12_METHADONE_COLUMN1",
    },
    {
      id: "DA.1.1.260",
      shortname: "da12-3",
      name: "",
      prevalue:
        "How many years of regular use (more months than not) have you used methadone?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA12-3",
      depending: "DA0",
      exception: "Methadone",
      link: "DA12-4",
      subtopic_container_act: "DA12-RANK",
      suptopic_code: "DA12_METHADONE_COLUMN2",
    },
    {
      id: "DA.1.1.261",
      extend: "true",
      shortname: "da12-4",
      name: "",
      prevalue: "What is the usual way you use methadone?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "oral",
        "nasal",
        "smoking",
        "non-IV injection",
        "IV injection",
      ],
      act: "DA12-4",
      depending: "DA0",
      exception: "Methadone",
      link: "DA12-5",
      subtopic_container_act: "DA12-RANK",
      suptopic_code: "DA12_METHADONE_COLUMN2",
    },
    {
      id: "DA.1.1.262",
      extend: "true",
      shortname: "da12-5",
      name: "",
      prevalue:
        "Have you used methadone before, during, or, after sexual activity?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA12-5",
      depending: "DA0",
      exception: "Methadone",
      subtopic_container_act: "DA12-RANK",
      suptopic_code: "DA12_METHADONE_COLUMN2",
    },
    // {
    //   id: "DA.1.1.263",
    //   access: "private",
    //   shortname: "da12-5-comm",
    //   name: "",
    //   prevalue: "Comments (Methadone)",
    //   value: "",
    //   disabled: "true",
    //   type: "textarea",
    //   act: "DA12-5-COMM",
    //   depending: "DA0",
    //   exception: "Methadone",
    //   subtopic_container_act: "DA12-RANK",
    //   suptopic_code: "DA12_METHADONE_COLUMN3",
    // },
    {
      id: "DA.1.1.263",
      access: "private",
      shortname: "da12-5-comm",
      name: "",
      prevalue: "Comments (Methadone)",
      value: "",
      disabled: "true",
      type: "editor",
      act: "DA12-5-COMM",
      depending: "DA0",
      exception: "Methadone",
      subtopic_container_act: "DA12-RANK",
      suptopic_code: "DA12_METHADONE_COLUMN3",
    },
    {
      id: "DA.22",
      shortname: "startblockmethadone",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "STARTBLOCKMETHADONE",
      depending: "",
      exception: "",
    },
    {
      id: "DA.23",
      shortname: "endbblockmethadone",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "ENDBLOCKMETHADONE",
      depending: "",
      exception: "",
    },
    {
      id: "DA.1.1.277",
      shortname: "da13-rank",
      name: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
      prevalue: "Rank.",
      value: "",
      disabled: "true",
      type: "rank",
      act: "DA13-RANK",
      depending: "DA0",
      exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
      link: "DA13",
    },
    {
      id: "DA.1.1.278",
      extend: "true",
      shortname: "da13",
      name: "",
      prevalue: "At what age did you first use opiates?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA13",
      depending: "DA0",
      exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
      link: "DA13-ADOL1",
      subtopic_container_act: "DA13-RANK",
      suptopic_code: "DA13_OPIATES_COLUMN1",
    },
    // {
    //   id: "DA.1.1.279",
    //   extend: "true",
    //   shortname: "da13-adol1",
    //   name: "",
    //   prevalue:
    //     "How old were you when you first started using opiates on a regular basis?",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "DA13-ADOL1",
    //   depending: "DA0",
    //   exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
    //   condition: "in",
    //   link: "DA13-ADOL2",
    //   subtopic_container_act: "DA13-RANK",
    //   suptopic_code: "DA13_OPIATES_COLUMN1",
    // },
    {
      id: "DA.1.1.279",
      extend: "true",
      shortname: "da13-adol1",
      name: "",
      prevalue:
        "How old were you when you first started using opiates on a regular basis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "never regular",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
      // type: "number",
      act: "DA13-ADOL1",
      depending: "DA0",
      exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
      condition: "in",
      link: "DA13-ADOL2",
      subtopic_container_act: "DA13-RANK",
      suptopic_code: "DA13_OPIATES_COLUMN1",
    },
    {
      id: "DA.1.1.280",
      extend: "true",
      shortname: "da13-adol2",
      name: "",
      prevalue:
        "In the past year what was your TYPICAL pattern of your use of opiates?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA13-ADOL2",
      depending: "DA0",
      exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
      condition: "in",
      link: "DA13-ADOL3",
      subtopic_container_act: "DA13-RANK",
      suptopic_code: "DA13_OPIATES_COLUMN1",
    },
    {
      id: "DA.1.1.281",
      extend: "true",
      shortname: "da13-adol3",
      name: "",
      prevalue:
        "In the past year what was your PEAK pattern of your use of opiates?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA13-ADOL3",
      depending: "DA0",
      exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
      condition: "in",
      link: "DA13-ADOL4",
      subtopic_container_act: "DA13-RANK",
      suptopic_code: "DA13_OPIATES_COLUMN1",
    },
    {
      id: "DA.1.1.282",
      extend: "true",
      shortname: "da13-adol4",
      name: "",
      prevalue: "When you use opiates, with whom do you typically use?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Alone",
        "Friend(s)",
        "Sibling(s)",
        "Parent(s)",
        "Acquaintance(s)",
        "Stranger(s)",
        "Other Specify",
      ],
      act: "DA13-ADOL4",
      depending: "DA0",
      exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
      condition: "in",
      link: "DA13-ADOL4-1",
      subtopic_container_act: "DA13-RANK",
      suptopic_code: "DA13_OPIATES_COLUMN1",
    },
    {
      id: "DA.1.1.282.1",
      extend: "true",
      shortname: "da13-adol4-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA13-ADOL4-1",
      depending: "DA13-ADOL4",
      exception: "Other Specify",
      link: "DA13-1",
      subtopic_container_act: "DA13-RANK",
      suptopic_code: "DA13_OPIATES_COLUMN1",
    },
    {
      id: "DA.1.1.283",
      extend: "true",
      shortname: "da13-1",
      name: "",
      prevalue: "When did you last use opiates?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA13-1",
      depending: "DA0",
      exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
      link: "DA13-2",
      subtopic_container_act: "DA13-RANK",
      suptopic_code: "DA13_OPIATES_COLUMN1",
    },
    {
      id: "DA.1.1.284",
      extend: "true",
      shortname: "da13-2",
      name: "",
      prevalue: "How many days in the past 30 days have you used opiates?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA13-2",
      depending: "DA0",
      exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
      subtopic_container_act: "DA13-RANK",
      suptopic_code: "DA13_OPIATES_COLUMN1",
    },
    {
      id: "DA.1.1.285",
      shortname: "da13-3",
      name: "",
      prevalue:
        "How many years of regular use (more months than not) have you used opiates?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA13-3",
      depending: "DA0",
      exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
      link: "DA13-4",
      subtopic_container_act: "DA13-RANK",
      suptopic_code: "DA13_OPIATES_COLUMN2",
    },
    {
      id: "DA.1.1.286",
      extend: "true",
      shortname: "da13-4",
      name: "",
      prevalue: "What is the usual way you use opiates?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "oral",
        "nasal",
        "smoking",
        "non-IV injection",
        "IV injection",
      ],
      act: "DA13-4",
      depending: "DA0",
      exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
      link: "DA13-5",
      subtopic_container_act: "DA13-RANK",
      suptopic_code: "DA13_OPIATES_COLUMN2",
    },
    {
      id: "DA.1.1.287",
      extend: "true",
      shortname: "da13-5",
      name: "",
      prevalue:
        "Have you used opiates before, during, or, after sexual activity?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA13-5",
      depending: "DA0",
      exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
      subtopic_container_act: "DA13-RANK",
      suptopic_code: "DA13_OPIATES_COLUMN2",
    },
    // {
    //   id: "DA.1.1.288",
    //   access: "private",
    //   shortname: "da13-5-comm",
    //   name: "",
    //   prevalue: "Comments (Opiates: Hydrocodone/ Oxycontin/ Morphine/other)",
    //   value: "",
    //   disabled: "true",
    //   type: "textarea",
    //   act: "DA13-5-COMM",
    //   depending: "DA0",
    //   exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
    //   subtopic_container_act: "DA13-RANK",
    //   suptopic_code: "DA13_OPIATES_COLUMN3",
    // },
    {
      id: "DA.1.1.288",
      access: "private",
      shortname: "da13-5-comm",
      name: "",
      prevalue: "Comments (Opiates: Hydrocodone/ Oxycontin/ Morphine/other)",
      value: "",
      disabled: "true",
      type: "editor",
      act: "DA13-5-COMM",
      depending: "DA0",
      exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
      subtopic_container_act: "DA13-RANK",
      suptopic_code: "DA13_OPIATES_COLUMN3",
    },
    {
      id: "DA.24",
      shortname: "startblockopiates",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "STARTBLOCKOPIATES",
      depending: "",
      exception: "",
    },
    {
      id: "DA.25",
      shortname: "endbblockopiates",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "ENDBLOCKOPIATES",
      depending: "",
      exception: "",
    },
    {
      id: "DA.1.1.302",
      shortname: "da14-rank",
      name: "Sedative/Hypnotic/Anxiolytics",
      prevalue: "Rank.",
      value: "",
      disabled: "true",
      type: "rank",
      act: "DA14-RANK",
      depending: "DA0",
      exception: "Sedative/ Hypnotic/Anxiolytics",
      link: "DA14",
    },
    {
      id: "DA.1.1.303",
      extend: "true",
      shortname: "da14",
      name: "",
      prevalue: "At what age did you first use sedative/hypnotic/anxiolytics?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA14",
      depending: "DA0",
      exception: "Sedative/ Hypnotic/Anxiolytics",
      link: "DA14-ADOL1",
      subtopic_container_act: "DA14-RANK",
      suptopic_code: "DA14_SEDATIVES_COLUMN1",
    },
    // {
    //   id: "DA.1.1.304",
    //   extend: "true",
    //   shortname: "da14-adol1",
    //   name: "",
    //   prevalue:
    //     "How old were you when you first started using sedative/hypnotic/anxiolytics on a regular basis?",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "DA14-ADOL1",
    //   depending: "DA0",
    //   exception: "Sedative/ Hypnotic/Anxiolytics",
    //   condition: "in",
    //   link: "DA14-ADOL2",
    //   subtopic_container_act: "DA14-RANK",
    //   suptopic_code: "DA14_SEDATIVES_COLUMN1",
    // },
    {
      id: "DA.1.1.304",
      extend: "true",
      shortname: "da14-adol1",
      name: "",
      prevalue:
        "How old were you when you first started using sedative/hypnotic/anxiolytics on a regular basis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "never regular",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
      // type: "number",
      act: "DA14-ADOL1",
      depending: "DA0",
      exception: "Sedative/ Hypnotic/Anxiolytics",
      condition: "in",
      link: "DA14-ADOL2",
      subtopic_container_act: "DA14-RANK",
      suptopic_code: "DA14_SEDATIVES_COLUMN1",
    },
    {
      id: "DA.1.1.305",
      extend: "true",
      shortname: "da14-adol2",
      name: "",
      prevalue:
        "In the past year what was your TYPICAL pattern of your use of sedative/hypnotic/anxiolytics?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA14-ADOL2",
      depending: "DA0",
      exception: "Sedative/ Hypnotic/Anxiolytics",
      condition: "in",
      link: "DA14-ADOL3",
      subtopic_container_act: "DA14-RANK",
      suptopic_code: "DA14_SEDATIVES_COLUMN1",
    },
    {
      id: "DA.1.1.306",
      extend: "true",
      shortname: "da14-adol3",
      name: "",
      prevalue:
        "In the past year what was your PEAK pattern of your use of sedative/hypnotic/anxiolytics?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA14-ADOL3",
      depending: "DA0",
      exception: "Sedative/ Hypnotic/Anxiolytics",
      condition: "in",
      link: "DA14-ADOL4",
      subtopic_container_act: "DA14-RANK",
      suptopic_code: "DA14_SEDATIVES_COLUMN1",
    },
    {
      id: "DA.1.1.307",
      extend: "true",
      shortname: "da14-adol4",
      name: "",
      prevalue:
        "When you use sedative/hypnotic/anxiolytics, with whom do you typically use?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Alone",
        "Friend(s)",
        "Sibling(s)",
        "Parent(s)",
        "Acquaintance(s)",
        "Stranger(s)",
        "Other Specify",
      ],
      act: "DA14-ADOL4",
      depending: "DA0",
      exception: "Sedative/ Hypnotic/Anxiolytics",
      condition: "in",
      link: "DA14-ADOL4-1",
      subtopic_container_act: "DA14-RANK",
      suptopic_code: "DA14_SEDATIVES_COLUMN1",
    },
    {
      id: "DA.1.1.307.1",
      extend: "true",
      shortname: "da14-adol4-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA14-ADOL4-1",
      depending: "DA14-ADOL4",
      exception: "Other Specify",
      link: "DA14-1",
      subtopic_container_act: "DA14-RANK",
      suptopic_code: "DA14_SEDATIVES_COLUMN1",
    },
    {
      id: "DA.1.1.308",
      extend: "true",
      shortname: "da14-1",
      name: "",
      prevalue: "When did you last use sedative/hypnotic/anxiolytics?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA14-1",
      depending: "DA0",
      exception: "Sedative/ Hypnotic/Anxiolytics",
      link: "DA14-2",
      subtopic_container_act: "DA14-RANK",
      suptopic_code: "DA14_SEDATIVES_COLUMN1",
    },
    {
      id: "DA.1.1.309",
      extend: "true",
      shortname: "da14-2",
      name: "",
      prevalue:
        "How many days in the past 30 days have you used sedative/hypnotic/anxiolytics?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA14-2",
      depending: "DA0",
      exception: "Sedative/ Hypnotic/Anxiolytics",
      subtopic_container_act: "DA14-RANK",
      suptopic_code: "DA14_SEDATIVES_COLUMN1",
    },
    {
      id: "DA.1.1.310",
      shortname: "da14-3",
      name: "",
      prevalue:
        "How many years of regular use (more months than not) have you used sedative/hypnotic/anxiolytics?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA14-3",
      depending: "DA0",
      exception: "Sedative/ Hypnotic/Anxiolytics",
      link: "DA14-4",
      subtopic_container_act: "DA14-RANK",
      suptopic_code: "DA14_SEDATIVES_COLUMN2",
    },
    {
      id: "DA.1.1.311",
      extend: "true",
      shortname: "da14-4",
      name: "",
      prevalue: "What is the usual way you use sedative/hypnotic/anxiolytics?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "oral",
        "nasal",
        "smoking",
        "non-IV injection",
        "IV injection",
      ],
      act: "DA14-4",
      depending: "DA0",
      exception: "Sedative/ Hypnotic/Anxiolytics",
      link: "DA14-5",
      subtopic_container_act: "DA14-RANK",
      suptopic_code: "DA14_SEDATIVES_COLUMN2",
    },
    {
      id: "DA.1.1.312",
      extend: "true",
      shortname: "da14-5",
      name: "",
      prevalue:
        "Have you used sedative/hypnotic/anxiolytics before, during, or, after sexual activity?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA14-5",
      depending: "DA0",
      exception: "Sedative/ Hypnotic/Anxiolytics",
      subtopic_container_act: "DA14-RANK",
      suptopic_code: "DA14_SEDATIVES_COLUMN2",
    },
    // {
    //   id: "DA.1.1.313",
    //   access: "private",
    //   shortname: "da14-5-comm",
    //   name: "",
    //   prevalue: "Comments (Sedative/ Hypnotic/Anxiolytics)",
    //   value: "",
    //   disabled: "true",
    //   type: "textarea",
    //   act: "DA14-5-COMM",
    //   depending: "DA0",
    //   exception: "Sedative/ Hypnotic/Anxiolytics",
    //   subtopic_container_act: "DA14-RANK",
    //   suptopic_code: "DA14_SEDATIVES_COLUMN3",
    // },
    {
      id: "DA.1.1.313",
      access: "private",
      shortname: "da14-5-comm",
      name: "",
      prevalue: "Comments (Sedative/ Hypnotic/Anxiolytics)",
      value: "",
      disabled: "true",
      type: "editor",
      act: "DA14-5-COMM",
      depending: "DA0",
      exception: "Sedative/ Hypnotic/Anxiolytics",
      subtopic_container_act: "DA14-RANK",
      suptopic_code: "DA14_SEDATIVES_COLUMN3",
    },
    {
      id: "DA.26",
      shortname: "startblocksedative",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "STARTBLOCKSEDATIVE",
      depending: "",
      exception: "",
    },
    {
      id: "DA.27",
      shortname: "endbblocksedative",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "ENDBLOCKSEDATIVE",
      depending: "",
      exception: "",
    },
    {
      id: "DA.1.1.327",
      shortname: "da15-rank",
      name: "Tobacco",
      prevalue: "Rank.",
      value: "",
      disabled: "true",
      type: "rank",
      act: "DA15-RANK",
      depending: "DA0",
      exception: "Tobacco",
      link: "DA15",
    },
    {
      id: "DA.1.1.328",
      extend: "true",
      shortname: "da15",
      name: "",
      prevalue: "At what age did you first use tobacco?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA15",
      depending: "DA0",
      exception: "Tobacco",
      link: "DA15-ADOL1",
      subtopic_container_act: "DA15-RANK",
      suptopic_code: "DA15_TOBACCO_COLUMN1",
    },
    // {
    //   id: "DA.1.1.329",
    //   extend: "true",
    //   shortname: "da15-adol1",
    //   name: "",
    //   prevalue:
    //     "How old were you when you first started using tobacco on a regular basis?",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "DA15-ADOL1",
    //   depending: "DA0",
    //   exception: "Tobacco",
    //   condition: "in",
    //   link: "DA15-ADOL2",
    //   subtopic_container_act: "DA15-RANK",
    //   suptopic_code: "DA15_TOBACCO_COLUMN1",
    // },
    {
      id: "DA.1.1.329",
      extend: "true",
      shortname: "da15-adol1",
      name: "",
      prevalue:
        "How old were you when you first started using tobacco on a regular basis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "never regular",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
      // type: "number",
      act: "DA15-ADOL1",
      depending: "DA0",
      exception: "Tobacco",
      condition: "in",
      link: "DA15-ADOL2",
      subtopic_container_act: "DA15-RANK",
      suptopic_code: "DA15_TOBACCO_COLUMN1",
    },
    {
      id: "DA.1.1.330",
      extend: "true",
      shortname: "da15-adol2",
      name: "",
      prevalue:
        "In the past year what was your TYPICAL pattern of your use of tobacco?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA15-ADOL2",
      depending: "DA0",
      exception: "Tobacco",
      condition: "in",
      link: "DA15-ADOL3",
      subtopic_container_act: "DA15-RANK",
      suptopic_code: "DA15_TOBACCO_COLUMN1",
    },
    {
      id: "DA.1.1.331",
      extend: "true",
      shortname: "da15-adol3",
      name: "",
      prevalue:
        "In the past year what was your PEAK pattern of your use of tobacco?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA15-ADOL3",
      depending: "DA0",
      exception: "Tobacco",
      condition: "in",
      link: "DA15-ADOL4",
      subtopic_container_act: "DA15-RANK",
      suptopic_code: "DA15_TOBACCO_COLUMN1",
    },
    {
      id: "DA.1.1.332",
      extend: "true",
      shortname: "da15-adol4",
      name: "",
      prevalue: "When you use tobacco, with whom do you typically use?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Alone",
        "Friend(s)",
        "Sibling(s)",
        "Parent(s)",
        "Acquaintance(s)",
        "Stranger(s)",
        "Other Specify",
      ],
      act: "DA15-ADOL4",
      depending: "DA0",
      exception: "Tobacco",
      condition: "in",
      link: "DA15-ADOL4-1",
      subtopic_container_act: "DA15-RANK",
      suptopic_code: "DA15_TOBACCO_COLUMN1",
    },
    {
      id: "DA.1.1.332.1",
      extend: "true",
      shortname: "da15-adol4-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA15-ADOL4-1",
      depending: "DA15-ADOL4",
      exception: "Other Specify",
      link: "DA15-1",
      subtopic_container_act: "DA15-RANK",
      suptopic_code: "DA15_TOBACCO_COLUMN1",
    },
    {
      id: "DA.1.1.333",
      extend: "true",
      shortname: "da15-1",
      name: "",
      prevalue: "When did you last use tobacco?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA15-1",
      depending: "DA0",
      exception: "Tobacco",
      link: "DA15-2",
      subtopic_container_act: "DA15-RANK",
      suptopic_code: "DA15_TOBACCO_COLUMN1",
    },
    {
      id: "DA.1.1.334",
      extend: "true",
      shortname: "da15-2",
      name: "",
      prevalue: "How many days in the past 30 days have you used tobacco?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA15-2",
      depending: "DA0",
      exception: "Tobacco",
      subtopic_container_act: "DA15-RANK",
      suptopic_code: "DA15_TOBACCO_COLUMN1",
    },
    {
      id: "DA.1.1.335",
      shortname: "da15-3",
      name: "",
      prevalue:
        "How many years of regular use (more months than not) have you used tobacco?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA15-3",
      depending: "DA0",
      exception: "Tobacco",
      link: "DA15-4",
      subtopic_container_act: "DA15-RANK",
      suptopic_code: "DA15_TOBACCO_COLUMN2",
    },
    {
      id: "DA.1.1.336",
      extend: "true",
      shortname: "da15-4",
      name: "",
      prevalue: "What is the usual way you use tobacco?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "oral", "nasal", "smoking"],
      act: "DA15-4",
      depending: "DA0",
      exception: "Tobacco",
      link: "DA15-5",
      subtopic_container_act: "DA15-RANK",
      suptopic_code: "DA15_TOBACCO_COLUMN2",
    },
    {
      id: "DA.1.1.337",
      extend: "true",
      shortname: "da15-5",
      name: "",
      prevalue:
        "Have you used tobacco before, during, or, after sexual activity?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA15-5",
      depending: "DA0",
      exception: "Tobacco",
      subtopic_container_act: "DA15-RANK",
      suptopic_code: "DA15_TOBACCO_COLUMN2",
    },
    // {
    //   id: "DA.1.1.338",
    //   access: "private",
    //   shortname: "da15-5-comm",
    //   name: "",
    //   prevalue: "Comments (Tobacco)",
    //   value: "",
    //   disabled: "true",
    //   type: "textarea",
    //   act: "DA15-5-COMM",
    //   depending: "DA0",
    //   exception: "Tobacco",
    //   subtopic_container_act: "DA15-RANK",
    //   suptopic_code: "DA15_TOBACCO_COLUMN3",
    // },
    {
      id: "DA.1.1.338",
      access: "private",
      shortname: "da15-5-comm",
      name: "",
      prevalue: "Comments (Tobacco)",
      value: "",
      disabled: "true",
      type: "editor",
      act: "DA15-5-COMM",
      depending: "DA0",
      exception: "Tobacco",
      subtopic_container_act: "DA15-RANK",
      suptopic_code: "DA15_TOBACCO_COLUMN3",
    },
    {
      id: "DA.28",
      shortname: "startblocktobacco",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "STARTBLOCKTOBACCO",
      depending: "",
      exception: "",
    },
    {
      id: "DA.29",
      shortname: "endbblocktobacco",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "ENDBLOCKTOBACCO",
      depending: "",
      exception: "",
    },
    {
      id: "DA.1.1.352",
      shortname: "da16-rank",
      name: "Other Substance",
      prevalue: "Rank.",
      value: "",
      disabled: "true",
      type: "rank",
      act: "DA16-RANK",
      depending: "DA0",
      exception: "Other Substance",
      link: "DA16",
    },
    {
      id: "DA.1.1.353",
      extend: "true",
      shortname: "da16",
      name: "",
      prevalue: "Please name the Other Substance",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA16",
      depending: "DA0",
      exception: "Other Substance",
      subtopic_container_act: "DA16-RANK",
      suptopic_code: "DA16_OTHER_COLUMN1",
    },
    {
      id: "DA.1.1.354",
      shortname: "da16-0",
      name: "",
      prevalue: "At what age did you first use this substance?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA16-0",
      depending: "DA0",
      exception: "Other Substance",
      link: "DA16-ADOL1",
      subtopic_container_act: "DA16-RANK",
      suptopic_code: "DA16_OTHER_COLUMN1",
    },
    // {
    //   id: "DA.1.1.355",
    //   extend: "true",
    //   shortname: "da16-adol1",
    //   name: "",
    //   prevalue:
    //     "How old were you when you first started using this substance on a regular basis?",
    //   value: "",
    //   disabled: "true",
    //   type: "number",
    //   act: "DA16-ADOL1",
    //   depending: "DA0",
    //   exception: "Other Substance",
    //   condition: "in",
    //   link: "DA16-ADOL2",
    //   subtopic_container_act: "DA16-RANK",
    //   suptopic_code: "DA16_OTHER_COLUMN1",
    // },
    {
      id: "DA.1.1.355",
      extend: "true",
      shortname: "da16-adol1",
      name: "",
      prevalue:
        "How old were you when you first started using this substance on a regular basis?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "never regular",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
      // type: "number",
      act: "DA16-ADOL1",
      depending: "DA0",
      exception: "Other Substance",
      condition: "in",
      link: "DA16-ADOL2",
      subtopic_container_act: "DA16-RANK",
      suptopic_code: "DA16_OTHER_COLUMN1",
    },
    {
      id: "DA.1.1.356",
      extend: "true",
      shortname: "da16-adol2",
      name: "",
      prevalue:
        "In the past year what was your TYPICAL pattern of your use of this substance?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA16-ADOL2",
      depending: "DA0",
      exception: "Other Substance",
      condition: "in",
      link: "DA16-ADOL3",
      subtopic_container_act: "DA16-RANK",
      suptopic_code: "DA16_OTHER_COLUMN1",
    },
    {
      id: "DA.1.1.357",
      extend: "true",
      shortname: "da16-adol3",
      name: "",
      prevalue:
        "In the past year what was your PEAK pattern of your use of this substance?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "No past year use",
        "One to ten times",
        "Episodic use",
        "Binge use only",
        "Once per month",
        "Two or more times per month",
        "Once per week",
        "Two or more times per week",
        "Once per day",
        "More than once per day",
      ],
      act: "DA16-ADOL3",
      depending: "DA0",
      exception: "Other Substance",
      condition: "in",
      link: "DA16-ADOL4",
      subtopic_container_act: "DA16-RANK",
      suptopic_code: "DA16_OTHER_COLUMN1",
    },
    {
      id: "DA.1.1.358",
      extend: "true",
      shortname: "da16-adol4",
      name: "",
      prevalue: "When you use this substance, with whom do you typically use?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Alone",
        "Friend(s)",
        "Sibling(s)",
        "Parent(s)",
        "Acquaintance(s)",
        "Stranger(s)",
        "Other Specify",
      ],
      act: "DA16-ADOL4",
      depending: "DA0",
      exception: "Other Substance",
      condition: "in",
      link: "DA16-ADOL4-1",
      subtopic_container_act: "DA16-RANK",
      suptopic_code: "DA16_OTHER_COLUMN1",
    },
    {
      id: "DA.1.1.358.1",
      extend: "true",
      shortname: "da16-adol4-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA16-ADOL4-1",
      depending: "DA16-ADOL4",
      exception: "Other Specify",
      link: "DA16-1",
      subtopic_container_act: "DA16-RANK",
      suptopic_code: "DA16_OTHER_COLUMN1",
    },
    {
      id: "DA.1.1.359",
      extend: "true",
      shortname: "da16-1",
      name: "",
      prevalue: "When did you last use this substance?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA16-1",
      depending: "DA0",
      exception: "Other Substance",
      link: "DA16-2",
      subtopic_container_act: "DA16-RANK",
      suptopic_code: "DA16_OTHER_COLUMN1",
    },
    {
      id: "DA.1.1.360",
      extend: "true",
      shortname: "da16-2",
      name: "",
      prevalue: "How many days in the past 30 days have you used this drug?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA16-2",
      depending: "DA0",
      exception: "Other Substance",
      subtopic_container_act: "DA16-RANK",
      suptopic_code: "DA16_OTHER_COLUMN1",
    },
    {
      id: "DA.1.1.361",
      shortname: "da16-3",
      name: "",
      prevalue:
        "How many years of regular use (more months than not) have you used other substance?",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA16-3",
      depending: "DA0",
      exception: "Other Substance",
      link: "DA16-4",
      subtopic_container_act: "DA16-RANK",
      suptopic_code: "DA16_OTHER_COLUMN2",
    },
    {
      id: "DA.1.1.362",
      extend: "true",
      shortname: "da16-4",
      name: "",
      prevalue: "What is the usual way you use this drug?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "oral",
        "nasal",
        "smoking",
        "non-IV injection",
        "IV injection",
      ],
      act: "DA16-4",
      depending: "DA0",
      exception: "Other Substance",
      link: "DA16-5",
      subtopic_container_act: "DA16-RANK",
      suptopic_code: "DA16_OTHER_COLUMN2",
    },
    {
      id: "DA.1.1.363",
      extend: "true",
      shortname: "da16-5",
      name: "",
      prevalue:
        "Have you used this drug before, during, or, after sexual activity?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA16-5",
      depending: "DA0",
      exception: "Other Substance",
      subtopic_container_act: "DA16-RANK",
      suptopic_code: "DA16_OTHER_COLUMN2",
    },
    // {
    //   id: "DA.1.1.364",
    //   access: "private",
    //   shortname: "da16-5-comm",
    //   name: "",
    //   prevalue: "Comments (Other Substance)",
    //   value: "",
    //   disabled: "true",
    //   type: "textarea",
    //   act: "DA16-5-COMM",
    //   depending: "DA0",
    //   exception: "Other Substance",
    //   subtopic_container_act: "DA16-RANK",
    //   suptopic_code: "DA16_OTHER_COLUMN3",
    // },
    {
      id: "DA.1.1.364",
      access: "private",
      shortname: "da16-5-comm",
      name: "",
      prevalue: "Comments (Other Substance)",
      value: "",
      disabled: "true",
      type: "editor",
      act: "DA16-5-COMM",
      depending: "DA0",
      exception: "Other Substance",
      subtopic_container_act: "DA16-RANK",
      suptopic_code: "DA16_OTHER_COLUMN3",
    },
    {
      id: "DA.30",
      shortname: "startblockother",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "STARTBLOCKOTHER",
      depending: "",
      exception: "",
    },
    {
      id: "DA.31",
      shortname: "endbblockother",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "ENDBLOCKOTHER",
      depending: "",
      exception: "",
    },
    {
      id: "DA.1.2",
      shortname: "da1-1-adol1",
      name: "What was the first substances you EVER tried?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Alcohol",
        "Cannabis products, including K2",
        "Cocaine",
        "Crack",
        "Crank",
        "Ecstasy",
        "Hallucinogens /LSD/mushrooms, etc.",
        "Heroin",
        "Inhalants",
        "Meth or other Amphetamines",
        "Methadone",
        "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
        "Sedative/ Hypnotic/Anxiolytics",
        "Tobacco",
        "Other Substance",
      ],
      act: "DA1-1-ADOL1",
      depending: "DA-NEW1",
      exception: "Yes",
      condition: "in",
      link: "DA1-1-ADOL1-1",
    },
    {
      id: "DA.1.2.1",
      extend: "true",
      shortname: "da1-1-adol1-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA1-1-ADOL1-1",
      depending: "DA1-1-ADOL1",
      exception: "Other Substance",
      link: "DA1-1-ADOL2",
    },
    {
      id: "DA.1.3",
      extend: "true",
      shortname: "da1-1-adol2",
      name: "",
      prevalue: "With whom did you use the first substance?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Alone",
        "Friend(s)",
        "Sibling(s)",
        "Parent(s)",
        "Acquaintance(s)",
        "Stranger(s)",
        "Other Specify",
      ],
      act: "DA1-1-ADOL2",
      depending: "DA-NEW1",
      exception: "Yes",
      condition: "in",
      link: "DA1-1-ADOL2-1",
    },
    {
      id: "DA.1.3.1",
      extend: "true",
      shortname: "da1-1-adol2-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA1-1-ADOL2-1",
      depending: "DA1-1-ADOL2",
      exception: "Other Specify",
      link: "DA1-1-ADOL3",
    },
    {
      id: "DA.1.2.2",
      extend: "true",
      shortname: "da1-1-adol3",
      name: "",
      prevalue:
        "If tobacco was the first substance you ever tried, what was the second?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Alcohol",
        "Cannabis products, including K2",
        "Cocaine",
        "Crack",
        "Crank",
        "Ecstasy",
        "Hallucinogens /LSD/mushrooms, etc.",
        "Heroin",
        "Inhalants",
        "Meth or other Amphetamines",
        "Methadone",
        "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
        "Sedative/ Hypnotic/Anxiolytics",
        "Other Substance",
      ],
      act: "DA1-1-ADOL3",
      depending: "DA1-1-ADOL1",
      exception: "Tobacco",
      condition: "in",
      link: "DA1-1-ADOL3-1",
    },
    {
      id: "DA.1.2.2.1",
      extend: "true",
      shortname: "da1-1-adol3-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA1-1-ADOL3-1",
      depending: "DA1-1-ADOL3",
      exception: "Other Substance",
      link: "DA1-1-ADOL4",
    },
    {
      id: "DA.1.2.3",
      extend: "true",
      shortname: "da1-1-adol4",
      name: "",
      prevalue: "With whom did you use the second substance?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "Alone",
        "Friend(s)",
        "Sibling(s)",
        "Parent(s)",
        "Acquaintance(s)",
        "Stranger(s)",
        "Other Specify",
      ],
      act: "DA1-1-ADOL4",
      depending: "DA1-1-ADOL1",
      exception: "Tobacco",
      condition: "in",
      link: "DA1-1-ADOL4-1",
    },
    {
      id: "DA.1.2.3.1",
      extend: "true",
      shortname: "da1-1-adol4-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA1-1-ADOL4-1",
      depending: "DA1-1-ADOL4",
      exception: "Other Specify",
    },
    {
      id: "DA.1.4",
      shortname: "da16-new113-add",
      name: "Have you EVER passed out or blacked out after using any substances, especially alcohol?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA16-NEW113-ADD",
      mandatory: "true",
      depending: "DA-NEW1",
      exception: "Yes",
      link: "DA16-NEW114-ADD",
    },
    {
      id: "DA.1.4.1",
      shortname: "da16-new114-add",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA16-NEW114-ADD",
      depending: "DA16-NEW113-ADD",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "DA.1.5",
      shortname: "da16-new113",
      name: "Have you EVER experienced withdrawal from any of the above substances?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA16-NEW113",
      mandatory: "true",
      depending: "DA-NEW1",
      exception: "Yes",
      link: "DA16-NEW114",
    },
    {
      id: "DA.1.5.1",
      shortname: "da16-new114",
      name: "",
      prevalue:
        "If yes, please list to what substance(s) and what were your symptoms of withdrawal?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA16-NEW114",
      depending: "DA16-NEW113",
      exception: "Yes",
      extend: "true",
      link: "DA16-NEW115",
    },
    {
      id: "DA.1.6",
      shortname: "da16-new115",
      name: "",
      prevalue: "Are you currently abstinent from your #1 drug of choice?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA16-NEW115",
      depending: "DA-NEW1",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "DA.1.7",
      shortname: "da16-new117add",
      name: "Have you EVER been voluntarily abstinent from your #1 drug of choice?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No", "No need"],
      act: "DA16-NEW117ADD",
      mandatory: "true",
      depending: "DA-NEW1",
      exception: "Yes",
      link: "DA16-NEW117",
    },
    {
      id: "DA.1.7.1",
      extend: "true",
      shortname: "da16-new117",
      name: "",
      prevalue:
        "How long (years/months) was your last period of voluntary abstinent from your #1 primary drug of choice?<br /> <br /> Year",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      act: "DA16-NEW117",
      depending: "DA16-NEW117ADD",
      exception: "Yes",
      link: "DA16-NEW117A",
    },
    {
      id: "DA.1.7.2",
      extend: "true",
      shortname: "da16-new117a",
      name: "",
      prevalue: "Month",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "DA16-NEW117A",
      depending: "DA16-NEW117ADD",
      exception: "Yes",
      link: "DA16-NEW116",
    },
    {
      id: "DA.1.7.3",
      extend: "true",
      shortname: "da16-new116",
      name: "",
      prevalue: "How long ago did your last period of abstinent end?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA16-NEW116",
      depending: "DA16-NEW117ADD",
      exception: "Yes",
    },
    {
      id: "DA.1.1.378",
      shortname: "da16-new118",
      name: "How much money would you say you have spent on alcohol DURING THE PAST 30 DAYS?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "money",
      act: "DA16-NEW118",
      depending: "DA0",
      exception: "Alcohol",
    },
    {
      id: "DA.1.1.379",
      shortname: "da16-new119",
      name: "How much money would you say you have spent on drugs DURING THE PAST 30 DAYS?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "money",
      act: "DA16-NEW119",
      depending: "DA0",
      exception:
        "Cannabis products, including K2;Cocaine;Crack;Crank;Ecstasy;Hallucinogens /LSD/mushrooms, etc.;Heroin;Inhalants;Meth or other Amphetamines;Methadone;Opiates: Hydrocodone/ Oxycontin/ Morphine/other;Sedative/ Hypnotic/Anxiolytics;Other Substance",
    },
    {
      id: "DA.1.1.380",
      shortname: "da16-new120",
      name: "How many times have you overdosed on drugs?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA16-NEW120",
      depending: "DA0",
      exception:
        "Cannabis products, including K2;Cocaine;Crack;Crank;Ecstasy;Hallucinogens /LSD/mushrooms, etc.;Heroin;Inhalants;Meth or other Amphetamines;Methadone;Opiates: Hydrocodone/ Oxycontin/ Morphine/other;Sedative/ Hypnotic/Anxiolytics;Other Substance",
    },
    {
      id: "DA.1.1.381",
      shortname: "da16-new121",
      name: "Have you EVER used a needle to administer drugs?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "DA16-NEW121",
      mandatory: "true",
      link: "DA16-NEW122",
      depending: "DA0",
      exception:
        "Cannabis products, including K2;Cocaine;Crack;Crank;Ecstasy;Hallucinogens /LSD/mushrooms, etc.;Heroin;Inhalants;Meth or other Amphetamines;Methadone;Opiates: Hydrocodone/ Oxycontin/ Morphine/other;Sedative/ Hypnotic/Anxiolytics;Other Substance",
    },
    {
      id: "DA.1.1.381.1",
      shortname: "da16-new122",
      name: "",
      prevalue:
        "If yes please list what drug(s) were used by IV injection and the last time this drug was used IV. (Example Meth 2016 and Heroin last week?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA16-NEW122",
      depending: "DA16-NEW121",
      exception: "Yes",
      extend: "true",
      link: "DA16-NEW117ADD-ADOL1",
    },
    {
      id: "DA.1.1.381.2",
      extend: "true",
      shortname: "da16-new117add-adol1",
      name: "",
      prevalue: "What was the first drug used by IV, i.e., shot-up?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "None",
        "Cocaine",
        "Meth or other Amphetamines",
        "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
        "Other Substance",
      ],
      act: "DA16-NEW117ADD-ADOL1",
      depending: "DA16-NEW121",
      exception: "Yes",
      condition: "in",
      link: "DA16-NEW117ADD-ADOL1-1",
    },
    {
      id: "DA.1.1.381.2.1",
      extend: "true",
      shortname: "da16-new117add-adol1-1",
      name: "",
      prevalue: "Please specify",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA16-NEW117ADD-ADOL1-1",
      depending: "DA16-NEW117ADD-ADOL1",
      exception: "Other Substance",
      link: "DA16-NEW117ADD-1-ADOL1",
    },
    {
      id: "DA.1.1.381.2.2",
      extend: "true",
      shortname: "da16-new117add-1-adol1",
      name: "",
      prevalue: "How old were you when you first used IV, i.e., shot-up?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "<12", "12", "13", "14", "15", "16", "17", "18"],
      act: "DA16-NEW117ADD-1-ADOL1",
      depending: "DA16-NEW117ADD-ADOL1",
      exception:
        "Cocaine;Meth or other Amphetamines;Opiates: Hydrocodone/ Oxycontin/ Morphine/other;Other Substance",
    },
    {
      id: "DA.1.8",
      shortname: "da16-new117add-adol2",
      name: "How many days in the past 30 days have you used anything?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA16-NEW117ADD-ADOL2",
      depending: "DA-NEW1",
      exception: "Yes",
      condition: "in",
      link: "DA16-NEW117ADD-ADOL3",
    },
    {
      id: "DA.1.9",
      extend: "true",
      shortname: "da16-new117add-adol3",
      name: "",
      prevalue:
        "Overall, how long have you used drugs or drank alcohol? <br /> <br /> Years",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "DA16-NEW117ADD-ADOL3",
      depending: "DA-NEW1",
      exception: "Yes",
      condition: "in",
      link: "DA16-NEW117ADD-ADOL4",
    },
    {
      id: "DA.1.10",
      extend: "true",
      shortname: "da16-new117add-adol4",
      name: "",
      prevalue: "Months",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "DA16-NEW117ADD-ADOL4",
      depending: "DA-NEW1",
      exception: "Yes",
      condition: "in",
    },
    {
      id: "DA.32",
      shortname: "startalcoholtable",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "STARTALCOHOLTABLE",
      depending: "",
      exception: "",
    },
    {
      id: "DA.33",
      shortname: "endalcoholtable",
      name: "",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "label",
      act: "ENDALCOHOLTABLE",
      depending: "",
      exception: "",
    },
    {
      id: "DA.1.11",
      shortname: "da17-adol1-subtopic",
      name: "Direction: Please answer the items in the grid below. Have you EVER had significant periods during which you...",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "subtopic",
      act: "DA17-ADOL-ACT",
      depending: "DA-NEW1",
      exception: "Yes",
      condition: "in",
    },
    {
      id: "DA.34",
      shortname: "da17-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "DA17-ADOL1",
      depending: "",
      exception: "",
      link: "DA17-1-ADOL1",
      subtopic_container_act: "DA17-ADOL-ACT",
      suptopic_code: "DA17-ADOL-CODE",
      subtopic_name:
        "...stole substances, stole money to buy substances, or used money from stolen goods to buy substances?",
    },
    {
      id: "DA.34.1",
      extend: "true",
      shortname: "da17-1-adol1",
      name: "",
      prevalue: "Age you first did this",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "DA17-1-ADOL1",
      condition: "in",
      depending: "DA17-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "DA17-ADOL-ACT",
      suptopic_code: "DA17-ADOL-CODE",
      subtopic_name:
        "...stole substances, stole money to buy substances, or used money from stolen goods to buy substances?",
    },
    {
      id: "DA.34.2",
      extend: "true",
      shortname: "da17-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "DA17-2-ADOL1",
      condition: "in",
      depending: "DA17-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "DA17-ADOL-ACT",
      suptopic_code: "DA17-ADOL-CODE",
      subtopic_name:
        "...stole substances, stole money to buy substances, or used money from stolen goods to buy substances?",
    },
    {
      id: "DA.34.3",
      extend: "true",
      shortname: "da17-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "DA17-3-ADOL1",
      condition: "in",
      depending: "DA17-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "DA17-ADOL-ACT",
      suptopic_code: "DA17-ADOL-CODE",
      subtopic_name:
        "...stole substances, stole money to buy substances, or used money from stolen goods to buy substances?",
    },
    {
      id: "DA.34.3.c",
      shortname: "da17-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "DA17-C-ADOL1",
      depending: "DA17-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "DA17-ADOL-ACT",
      suptopic_code: "DA17-ADOL-CODE",
      subtopic_name:
        "...stole substances, stole money to buy substances, or used money from stolen goods to buy substances?",
    },
    {
      id: "DA.35",
      shortname: "da18-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "DA18-ADOL1",
      depending: "",
      exception: "",
      link: "DA18-1-ADOL1",
      subtopic_container_act: "DA17-ADOL-ACT",
      suptopic_code: "DA18-ADOL-CODE",
      subtopic_name:
        "...had sex with someone because you knew they would share their drugs/alcohol with you, obtained substances in exchange for sex or used money from paid sexual activity to buy drugs/alcohol?",
    },
    {
      id: "DA.35.1",
      extend: "true",
      shortname: "da18-1-adol1",
      name: "",
      prevalue: "Age you first did this",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "DA18-1-ADOL1",
      condition: "in",
      depending: "DA18-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "DA17-ADOL-ACT",
      suptopic_code: "DA18-ADOL-CODE",
      subtopic_name:
        "...had sex with someone because you knew they would share their drugs/alcohol with you, obtained substances in exchange for sex or used money from paid sexual activity to buy drugs/alcohol?",
    },
    {
      id: "DA.35.2",
      extend: "true",
      shortname: "da18-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "DA18-2-ADOL1",
      condition: "in",
      depending: "DA18-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "DA17-ADOL-ACT",
      suptopic_code: "DA18-ADOL-CODE",
      subtopic_name:
        "...had sex with someone because you knew they would share their drugs/alcohol with you, obtained substances in exchange for sex or used money from paid sexual activity to buy drugs/alcohol?",
    },
    {
      id: "DA.35.3",
      extend: "true",
      shortname: "da18-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "DA18-3-ADOL1",
      condition: "in",
      depending: "DA18-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "DA17-ADOL-ACT",
      suptopic_code: "DA18-ADOL-CODE",
      subtopic_name:
        "...had sex with someone because you knew they would share their drugs/alcohol with you, obtained substances in exchange for sex or used money from paid sexual activity to buy drugs/alcohol?",
    },
    {
      id: "DA.35.3.c",
      shortname: "da18-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "DA18-C-ADOL1",
      depending: "DA18-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "DA17-ADOL-ACT",
      suptopic_code: "DA18-ADOL-CODE",
      subtopic_name:
        "...had sex with someone because you knew they would share their drugs/alcohol with you, obtained substances in exchange for sex or used money from paid sexual activity to buy drugs/alcohol?",
    },
    {
      id: "DA.36",
      shortname: "da19-adol1",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "DA19-ADOL1",
      depending: "",
      exception: "",
      link: "DA19-1-ADOL1",
      subtopic_container_act: "DA17-ADOL-ACT",
      suptopic_code: "DA19-ADOL-CODE",
      subtopic_name:
        "...dealt drugs, skimmed off dealt drugs for own use or used money from dealing to buy substances?",
    },
    {
      id: "DA.36.1",
      extend: "true",
      shortname: "da19-1-adol1",
      name: "",
      prevalue: "Age you first did this",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      act: "DA19-1-ADOL1",
      condition: "in",
      depending: "DA19-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "DA17-ADOL-ACT",
      suptopic_code: "DA19-ADOL-CODE",
      subtopic_name:
        "...dealt drugs, skimmed off dealt drugs for own use or used money from dealing to buy substances?",
    },
    {
      id: "DA.36.2",
      extend: "true",
      shortname: "da19-2-adol1",
      name: "",
      prevalue: "Occur in the past month?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "DA19-2-ADOL1",
      condition: "in",
      depending: "DA19-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "DA17-ADOL-ACT",
      suptopic_code: "DA19-ADOL-CODE",
      subtopic_name:
        "...dealt drugs, skimmed off dealt drugs for own use or used money from dealing to buy substances?",
    },
    {
      id: "DA.36.3",
      extend: "true",
      shortname: "da19-3-adol1",
      name: "",
      prevalue: "Occur the other 11 months?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "DA19-3-ADOL1",
      condition: "in",
      depending: "DA19-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "DA17-ADOL-ACT",
      suptopic_code: "DA19-ADOL-CODE",
      subtopic_name:
        "...dealt drugs, skimmed off dealt drugs for own use or used money from dealing to buy substances?",
    },
    {
      id: "DA.36.3.c",
      shortname: "da19-c-adol1",
      access: "private",
      name: "",
      prevalue: "Counselor comments:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "DA19-C-ADOL1",
      depending: "DA19-ADOL1",
      exception: "Ever;Yes",
      subtopic_container_act: "DA17-ADOL-ACT",
      suptopic_code: "DA19-ADOL-CODE",
      subtopic_name:
        "...dealt drugs, skimmed off dealt drugs for own use or used money from dealing to buy substances?",
    },
    {
      id: "DA.1.12",
      shortname: "da16-new123",
      name: "How many times have you had treatment for substance use?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "number",
      act: "DA16-NEW123",
      mandatory: "true",
      depending: "DA-NEW1",
      exception: "Yes",
    },
    {
      id: "DA.1.12.1",
      shortname: "da16-new124",
      name: "If you have received treatment for substance use in the past, where and when were these treatments and what type of treatment was it? e.g., Sunset Center, 2015, Residential?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA16-NEW124",
      depending: "DA16-NEW123",
      exception: "0",
    },
    {
      id: "DA.1.13",
      shortname: "da16-new125",
      name: "How many days in the past 30 days have you attended AA/NA or SMART support groups?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA16-NEW125",
      depending: "DA-NEW1",
      exception: "Yes",
    },
    {
      id: "DA.1.14",
      shortname: "da16-new125-adol1",
      name: "Do you think you have any problems with drugs or alcohol? ",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: ["", "No", "Maybe", "Definitely"],
      act: "DA16-NEW125-ADOL1",
      depending: "DA-NEW1",
      exception: "Yes",
      condition: "in",
    },
    {
      id: "DA.1.1.382",
      shortname: "da16-new126",
      name: "How many days in the past 30 days have you experienced any alcohol-related problems?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA16-NEW126",
      depending: "DA0",
      exception: "Alcohol",
    },
    {
      id: "DA.1.1.383",
      shortname: "da16-new127",
      name: "How worried or bothered have you been about any alcohol-related problems in the past 30 days?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "a little bit",
        "a lot",
      ],
      act: "DA16-NEW127",
      mandatory: "true",
      depending: "DA0",
      exception: "Alcohol",
      link: "DA16-NEW127-1",
    },
    {
      id: "DA.1.1.383.1",
      shortname: "da16-new127-1",
      name: "",
      prevalue: "Please explain the reason you are worried or bothered?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA16-NEW127-1",
      depending: "DA16-NEW127",
      exception: "not at all",
      extend: "true",
    },
    {
      id: "DA.1.1.384",
      shortname: "da16-new128",
      name: "How important is it for you to receive counseling or treatment for any alcohol problems?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "a little bit",
        "a lot",
      ],
      act: "DA16-NEW128",
      depending: "DA0",
      exception: "Alcohol",
    },
    {
      id: "DA.1.1.385",
      shortname: "da16-new129",
      name: "How many days in the past 30 days have you experienced any drug-related problems?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "DA16-NEW129",
      depending: "DA0",
      exception:
        "Cannabis products, including K2;Cocaine;Crack;Crank;Ecstasy;Hallucinogens /LSD/mushrooms, etc.;Heroin;Inhalants;Meth or other Amphetamines;Methadone;Opiates: Hydrocodone/ Oxycontin/ Morphine/other;Sedative/ Hypnotic/Anxiolytics;Other Substance",
    },
    {
      id: "DA.1.1.386",
      shortname: "da16-new130",
      name: "How worried or bothered have you been about any drug-related problems in the past 30 days? ",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "a little bit",
        "a lot",
      ],
      act: "DA16-NEW130",
      mandatory: "true",
      depending: "DA0",
      link: "DA16-NEW130-1",
      exception:
        "Cannabis products, including K2;Cocaine;Crack;Crank;Ecstasy;Hallucinogens /LSD/mushrooms, etc.;Heroin;Inhalants;Meth or other Amphetamines;Methadone;Opiates: Hydrocodone/ Oxycontin/ Morphine/other;Sedative/ Hypnotic/Anxiolytics;Other Substance",
    },
    {
      id: "DA.1.1.386.1",
      shortname: "da16-new130-1",
      name: "",
      prevalue: "Please explain the reason you are worried or bothered?",
      value: "",
      disabled: "true",
      type: "text",
      act: "DA16-NEW130-1",
      depending: "DA16-NEW130",
      exception: "not at all",
      extend: "true",
    },
    {
      id: "DA.1.1.387",
      shortname: "da16-new131",
      name: "How important is it for you to receive counseling or treatment for any drug problems?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "a little bit",
        "a lot",
      ],
      act: "DA16-NEW131",
      depending: "DA0",
      exception:
        "Cannabis products, including K2;Cocaine;Crack;Crank;Ecstasy;Hallucinogens /LSD/mushrooms, etc.;Heroin;Inhalants;Meth or other Amphetamines;Methadone;Opiates: Hydrocodone/ Oxycontin/ Morphine/other;Sedative/ Hypnotic/Anxiolytics;Other Substance",
    },
    {
      id: "DA.37",
      shortname: "da16-new132",
      access: "private",
      name: "Additional Counselor Comments:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "editor",
      act: "DA16-NEW132",
      depending: "",
      exception: "",
    },
  ],
};

export default alcoholHistoryJSON;
