const blockCrank = [
  //!========================================================================================
  /*                                                                                      *
   *                                    STARTBLOCKCRANK                                   *
   *                                                                                      */
  //!========================================================================================
  {
    id: "DA.1.1.114",
    shortname: "crank-block-subtopic",
    name: "Direction for Crank: Please answer the items in the grid below. Have there EVER been significant periods during which you...",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "subtopic",
    act: "CRANK-BLOCK-SUBTOPIC",
    depending: "DA0",
    exception: "Crank",
    condition: "in",
  },
  {
    id: "DA.1.1.115",
    shortname: "crank-block-6",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRANK-BLOCK-6",
    depending: "DA0",
    exception: "Crank",
    condition: "in",
    link: "CRANK-BLOCK-7",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.115.1",
    extend: "true",
    shortname: "crank-block-7",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRANK-BLOCK-7",
    depending: "CRANK-BLOCK-6",
    exception: "This has happened",
    link: "CRANK-BLOCK-8",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.115.2",
    extend: "true",
    shortname: "crank-block-8",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-8",
    depending: "CRANK-BLOCK-6",
    exception: "This has happened",
    link: "CRANK-BLOCK-9",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.115.3",
    extend: "true",
    shortname: "crank-block-9",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-9",
    depending: "CRANK-BLOCK-6",
    exception: "This has happened",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.116",
    shortname: "crank-block-10",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRANK-BLOCK-10",
    depending: "DA0",
    exception: "Crank",
    condition: "in",
    link: "CRANK-BLOCK-11",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.116.1",
    extend: "true",
    shortname: "crank-block-11",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRANK-BLOCK-11",
    depending: "CRANK-BLOCK-10",
    exception: "This has happened",
    link: "CRANK-BLOCK-12",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.116.2",
    extend: "true",
    shortname: "crank-block-12",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-12",
    depending: "CRANK-BLOCK-10",
    exception: "This has happened",
    link: "CRANK-BLOCK-13",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.116.3",
    extend: "true",
    shortname: "crank-block-13",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-13",
    depending: "CRANK-BLOCK-10",
    exception: "This has happened",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.117",
    shortname: "crank-block-14",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRANK-BLOCK-14",
    depending: "DA0",
    exception: "Crank",
    condition: "in",
    link: "CRANK-BLOCK-15",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.117.1",
    extend: "true",
    shortname: "crank-block-15",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRANK-BLOCK-15",
    depending: "CRANK-BLOCK-14",
    exception: "This has happened",
    link: "CRANK-BLOCK-16",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.117.2",
    extend: "true",
    shortname: "crank-block-16",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-16",
    depending: "CRANK-BLOCK-14",
    exception: "This has happened",
    link: "CRANK-BLOCK-17",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.117.3",
    extend: "true",
    shortname: "crank-block-17",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-17",
    depending: "CRANK-BLOCK-14",
    exception: "This has happened",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.118",
    shortname: "crank-block-18",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRANK-BLOCK-18",
    depending: "DA0",
    exception: "Crank",
    condition: "in",
    link: "CRANK-BLOCK-19",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.118.1",
    extend: "true",
    shortname: "crank-block-19",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRANK-BLOCK-19",
    depending: "CRANK-BLOCK-18",
    exception: "This has happened",
    link: "CRANK-BLOCK-30",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.118.2",
    extend: "true",
    shortname: "crank-block-20",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-20",
    depending: "CRANK-BLOCK-18",
    exception: "This has happened",
    link: "CRANK-BLOCK-21",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.118.3",
    extend: "true",
    shortname: "crank-block-21",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-21",
    depending: "CRANK-BLOCK-18",
    exception: "This has happened",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.119",
    shortname: "crank-block-22",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRANK-BLOCK-22",
    depending: "DA0",
    exception: "Crank",
    condition: "in",
    link: "CRANK-BLOCK-23",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.119.1",
    extend: "true",
    shortname: "crank-block-23",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRANK-BLOCK-23",
    depending: "CRANK-BLOCK-22",
    exception: "This has happened",
    link: "CRANK-BLOCK-24",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.119.2",
    extend: "true",
    shortname: "crank-block-24",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-24",
    depending: "CRANK-BLOCK-22",
    exception: "This has happened",
    link: "CRANK-BLOCK-25",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.119.3",
    extend: "true",
    shortname: "crank-block-25",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-25",
    depending: "CRANK-BLOCK-22",
    exception: "This has happened",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.120",
    shortname: "crank-block-26",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRANK-BLOCK-26",
    depending: "DA0",
    exception: "Crank",
    condition: "in",
    link: "CRANK-BLOCK-27",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.120.1",
    extend: "true",
    shortname: "crank-block-27",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRANK-BLOCK-27",
    depending: "CRANK-BLOCK-26",
    exception: "This has happened",
    link: "CRANK-BLOCK-28",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.120.2",
    extend: "true",
    shortname: "crank-block-28",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-28",
    depending: "CRANK-BLOCK-26",
    exception: "This has happened",
    link: "CRANK-BLOCK-29",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.120.3",
    extend: "true",
    shortname: "crank-block-29",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-29",
    depending: "CRANK-BLOCK-26",
    exception: "This has happened",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.121",
    shortname: "crank-block-30",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRANK-BLOCK-30",
    depending: "DA0",
    exception: "Crank",
    condition: "in",
    link: "CRANK-BLOCK-31",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.121.1",
    extend: "true",
    shortname: "crank-block-31",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRANK-BLOCK-31",
    depending: "CRANK-BLOCK-30",
    exception: "This has happened",
    link: "CRANK-BLOCK-32",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.121.2",
    extend: "true",
    shortname: "crank-block-32",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-32",
    depending: "CRANK-BLOCK-30",
    exception: "This has happened",
    link: "CRANK-BLOCK-33",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.121.3",
    extend: "true",
    shortname: "crank-block-33",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-33",
    depending: "CRANK-BLOCK-30",
    exception: "This has happened",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.122",
    shortname: "crank-block-34",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRANK-BLOCK-34",
    depending: "DA0",
    exception: "Crank",
    condition: "in",
    link: "CRANK-BLOCK-35",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.122.1",
    extend: "true",
    shortname: "crank-block-35",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRANK-BLOCK-35",
    depending: "CRANK-BLOCK-34",
    exception: "This has happened",
    link: "CRANK-BLOCK-36",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.122.2",
    extend: "true",
    shortname: "crank-block-36",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-36",
    depending: "CRANK-BLOCK-34",
    exception: "This has happened",
    link: "CRANK-BLOCK-37",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.122.3",
    extend: "true",
    shortname: "crank-block-37",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-37",
    depending: "CRANK-BLOCK-34",
    exception: "This has happened",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.123",
    shortname: "crank-block-38",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRANK-BLOCK-38",
    depending: "DA0",
    exception: "Crank",
    condition: "in",
    link: "CRANK-BLOCK-39",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.123.1",
    extend: "true",
    shortname: "crank-block-39",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRANK-BLOCK-39",
    depending: "CRANK-BLOCK-38",
    exception: "This has happened",
    link: "CRANK-BLOCK0",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.123.2",
    extend: "true",
    shortname: "crank-block-40",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-40",
    depending: "CRANK-BLOCK-38",
    exception: "This has happened",
    link: "CRANK-BLOCK-41",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.123.3",
    extend: "true",
    shortname: "crank-block-41",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-41",
    depending: "CRANK-BLOCK-38",
    exception: "This has happened",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.124",
    shortname: "crank-block-42",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRANK-BLOCK-42",
    depending: "DA0",
    exception: "Crank",
    condition: "in",
    link: "CRANK-BLOCK-43",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.124.1",
    extend: "true",
    shortname: "crank-block-43",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRANK-BLOCK-43",
    depending: "CRANK-BLOCK-42",
    exception: "This has happened",
    link: "CRANK-BLOCK-44",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.124.2",
    extend: "true",
    shortname: "crank-block-44",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-44",
    depending: "CRANK-BLOCK-42",
    exception: "This has happened",
    link: "CRANK-BLOCK-45",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.124.3",
    extend: "true",
    shortname: "crank-block-45",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-45",
    depending: "CRANK-BLOCK-42",
    exception: "This has happened",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.125",
    shortname: "crank-block-46",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRANK-BLOCK-46",
    depending: "DA0",
    exception: "Crank",
    condition: "in",
    link: "CRANK-BLOCK-47",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.125.1",
    extend: "true",
    shortname: "crank-block-47",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRANK-BLOCK-47",
    depending: "CRANK-BLOCK-46",
    exception: "This has happened",
    link: "CRANK-BLOCK-48",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.125.2",
    extend: "true",
    shortname: "crank-block-48",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-48",
    depending: "CRANK-BLOCK-46",
    exception: "This has happened",
    link: "CRANK-BLOCK-49",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.125.3",
    extend: "true",
    shortname: "crank-block-49",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRANK-BLOCK-49",
    depending: "CRANK-BLOCK-46",
    exception: "This has happened",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.126",
    shortname: "crank-block-50",
    name: "Number of criteria met for:",
    prevalue: "Number of criteria met for Crank:",
    value: "0",
    disabled: "true",
    type: "content",
    comment: "Consider no diagnosis for Crank",
    act: "CRANK-BLOCK-50",
    depending: "DA0",
    exception: "Crank",
    condition: "in",
    meaningfulcount: "This has happened",
    subtopic_container_act: "CRANK-BLOCK-SUBTOPIC",
    suptopic_code: "CRANK-BLOCK-12-CONTENT",
    subtopic_name: "Content Criteria",
  },
  //!========================================================================================
  /*                                                                                      *
   *                                     ENDBLOCKCRANK                                    *
   *                                                                                      */
  //!========================================================================================
];

export default blockCrank;
