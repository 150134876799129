const blockCrack = [
  //!========================================================================================
  /*                                                                                      *
   *                                    STARTBLOCKCRACK                                   *
   *                                                                                      */
  //!========================================================================================
  {
    id: "DA.1.1.89",
    shortname: "crack-block-subtopic",
    name: "Direction for Crack: Please answer the items in the grid below. Have there EVER been significant periods during which you...",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "subtopic",
    act: "CRACK-BLOCK-SUBTOPIC",
    depending: "DA0",
    exception: "Crack",
    condition: "in",
  },
  {
    id: "DA.1.1.90",
    shortname: "crack-block-6",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRACK-BLOCK-6",
    depending: "DA0",
    exception: "Crack",
    condition: "in",
    link: "CRACK-BLOCK-7",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.90.1",
    extend: "true",
    shortname: "crack-block-7",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRACK-BLOCK-7",
    depending: "CRACK-BLOCK-6",
    exception: "This has happened",
    link: "CRACK-BLOCK-8",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.90.2",
    extend: "true",
    shortname: "crack-block-8",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-8",
    depending: "CRACK-BLOCK-6",
    exception: "This has happened",
    link: "CRACK-BLOCK-9",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.90.3",
    extend: "true",
    shortname: "crack-block-9",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-9",
    depending: "CRACK-BLOCK-6",
    exception: "This has happened",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.91",
    shortname: "crack-block-10",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRACK-BLOCK-10",
    depending: "DA0",
    exception: "Crack",
    condition: "in",
    link: "CRACK-BLOCK-11",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.91.1",
    extend: "true",
    shortname: "crack-block-11",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRACK-BLOCK-11",
    depending: "CRACK-BLOCK-10",
    exception: "This has happened",
    link: "CRACK-BLOCK-12",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.91.2",
    extend: "true",
    shortname: "crack-block-12",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-12",
    depending: "CRACK-BLOCK-10",
    exception: "This has happened",
    link: "CRACK-BLOCK-13",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.91.3",
    extend: "true",
    shortname: "crack-block-13",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-13",
    depending: "CRACK-BLOCK-10",
    exception: "This has happened",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.92",
    shortname: "crack-block-14",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRACK-BLOCK-14",
    depending: "DA0",
    exception: "Crack",
    condition: "in",
    link: "CRACK-BLOCK-15",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.92.1",
    extend: "true",
    shortname: "crack-block-15",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRACK-BLOCK-15",
    depending: "CRACK-BLOCK-14",
    exception: "This has happened",
    link: "CRACK-BLOCK-16",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.92.2",
    extend: "true",
    shortname: "crack-block-16",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-16",
    depending: "CRACK-BLOCK-14",
    exception: "This has happened",
    link: "CRACK-BLOCK-17",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.92.3",
    extend: "true",
    shortname: "crack-block-17",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-17",
    depending: "CRACK-BLOCK-14",
    exception: "This has happened",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.93",
    shortname: "crack-block-18",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRACK-BLOCK-18",
    depending: "DA0",
    exception: "Crack",
    condition: "in",
    link: "CRACK-BLOCK-19",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.93.1",
    extend: "true",
    shortname: "crack-block-19",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRACK-BLOCK-19",
    depending: "CRACK-BLOCK-18",
    exception: "This has happened",
    link: "CRACK-BLOCK-30",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.93.2",
    extend: "true",
    shortname: "crack-block-20",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-20",
    depending: "CRACK-BLOCK-18",
    exception: "This has happened",
    link: "CRACK-BLOCK-21",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.93.3",
    extend: "true",
    shortname: "crack-block-21",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-21",
    depending: "CRACK-BLOCK-18",
    exception: "This has happened",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.94",
    shortname: "crack-block-22",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRACK-BLOCK-22",
    depending: "DA0",
    exception: "Crack",
    condition: "in",
    link: "CRACK-BLOCK-23",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.94.1",
    extend: "true",
    shortname: "crack-block-23",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRACK-BLOCK-23",
    depending: "CRACK-BLOCK-22",
    exception: "This has happened",
    link: "CRACK-BLOCK-24",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.94.2",
    extend: "true",
    shortname: "crack-block-24",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-24",
    depending: "CRACK-BLOCK-22",
    exception: "This has happened",
    link: "CRACK-BLOCK-25",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.94.3",
    extend: "true",
    shortname: "crack-block-25",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-25",
    depending: "CRACK-BLOCK-22",
    exception: "This has happened",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.95",
    shortname: "crack-block-26",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRACK-BLOCK-26",
    depending: "DA0",
    exception: "Crack",
    condition: "in",
    link: "CRACK-BLOCK-27",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.95.1",
    extend: "true",
    shortname: "crack-block-27",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRACK-BLOCK-27",
    depending: "CRACK-BLOCK-26",
    exception: "This has happened",
    link: "CRACK-BLOCK-28",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.95.2",
    extend: "true",
    shortname: "crack-block-28",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-28",
    depending: "CRACK-BLOCK-26",
    exception: "This has happened",
    link: "CRACK-BLOCK-29",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.95.3",
    extend: "true",
    shortname: "crack-block-29",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-29",
    depending: "CRACK-BLOCK-26",
    exception: "This has happened",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.96",
    shortname: "crack-block-30",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRACK-BLOCK-30",
    depending: "DA0",
    exception: "Crack",
    condition: "in",
    link: "CRACK-BLOCK-31",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.96.1",
    extend: "true",
    shortname: "crack-block-31",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRACK-BLOCK-31",
    depending: "CRACK-BLOCK-30",
    exception: "This has happened",
    link: "CRACK-BLOCK-32",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.96.2",
    extend: "true",
    shortname: "crack-block-32",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-32",
    depending: "CRACK-BLOCK-30",
    exception: "This has happened",
    link: "CRACK-BLOCK-33",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.96.3",
    extend: "true",
    shortname: "crack-block-33",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-33",
    depending: "CRACK-BLOCK-30",
    exception: "This has happened",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.97",
    shortname: "crack-block-34",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRACK-BLOCK-34",
    depending: "DA0",
    exception: "Crack",
    condition: "in",
    link: "CRACK-BLOCK-35",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.97.1",
    extend: "true",
    shortname: "crack-block-35",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRACK-BLOCK-35",
    depending: "CRACK-BLOCK-34",
    exception: "This has happened",
    link: "CRACK-BLOCK-36",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.97.2",
    extend: "true",
    shortname: "crack-block-36",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-36",
    depending: "CRACK-BLOCK-34",
    exception: "This has happened",
    link: "CRACK-BLOCK-37",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.97.3",
    extend: "true",
    shortname: "crack-block-37",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-37",
    depending: "CRACK-BLOCK-34",
    exception: "This has happened",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.98",
    shortname: "crack-block-38",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRACK-BLOCK-38",
    depending: "DA0",
    exception: "Crack",
    condition: "in",
    link: "CRACK-BLOCK-39",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.98.1",
    extend: "true",
    shortname: "crack-block-39",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRACK-BLOCK-39",
    depending: "CRACK-BLOCK-38",
    exception: "This has happened",
    link: "CRACK-BLOCK0",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.98.2",
    extend: "true",
    shortname: "crack-block-40",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-40",
    depending: "CRACK-BLOCK-38",
    exception: "This has happened",
    link: "CRACK-BLOCK-41",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.98.3",
    extend: "true",
    shortname: "crack-block-41",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-41",
    depending: "CRACK-BLOCK-38",
    exception: "This has happened",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.99",
    shortname: "crack-block-42",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRACK-BLOCK-42",
    depending: "DA0",
    exception: "Crack",
    condition: "in",
    link: "CRACK-BLOCK-43",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.99.1",
    extend: "true",
    shortname: "crack-block-43",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRACK-BLOCK-43",
    depending: "CRACK-BLOCK-42",
    exception: "This has happened",
    link: "CRACK-BLOCK-44",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.99.2",
    extend: "true",
    shortname: "crack-block-44",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-44",
    depending: "CRACK-BLOCK-42",
    exception: "This has happened",
    link: "CRACK-BLOCK-45",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.99.3",
    extend: "true",
    shortname: "crack-block-45",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-45",
    depending: "CRACK-BLOCK-42",
    exception: "This has happened",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.100",
    shortname: "crack-block-46",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "CRACK-BLOCK-46",
    depending: "DA0",
    exception: "Crack",
    condition: "in",
    link: "CRACK-BLOCK-47",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.100.1",
    extend: "true",
    shortname: "crack-block-47",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "CRACK-BLOCK-47",
    depending: "CRACK-BLOCK-46",
    exception: "This has happened",
    link: "CRACK-BLOCK-48",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.100.2",
    extend: "true",
    shortname: "crack-block-48",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-48",
    depending: "CRACK-BLOCK-46",
    exception: "This has happened",
    link: "CRACK-BLOCK-49",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.100.3",
    extend: "true",
    shortname: "crack-block-49",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "CRACK-BLOCK-49",
    depending: "CRACK-BLOCK-46",
    exception: "This has happened",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.101",
    shortname: "crack-block-50",
    name: "Number of criteria met for:",
    prevalue: "Number of criteria met for Crack:",
    value: "0",
    disabled: "true",
    type: "content",
    comment: "Consider no diagnosis for Crack",
    act: "CRACK-BLOCK-50",
    depending: "DA0",
    exception: "Crack",
    condition: "in",
    meaningfulcount: "This has happened",
    subtopic_container_act: "CRACK-BLOCK-SUBTOPIC",
    suptopic_code: "CRACK-BLOCK-12-CONTENT",
    subtopic_name: "Content Criteria",
  },
  //!========================================================================================
  /*                                                                                      *
   *                                     ENDBLOCKCRACK                                    *
   *                                                                                      */
  //!========================================================================================
];

export default blockCrack;
