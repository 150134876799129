const blockTobacco = [
  //!========================================================================================
  /*                                                                                      *
   *                                   STARTBLOCKTOBACCO                                  *
   *                                                                                      */
  //!========================================================================================
  {
    id: "DA.1.1.339",
    shortname: "tobacco-block-subtopic",
    name: "Direction for Tobacco: Please answer the items in the grid below. Have there EVER been significant periods during which you...",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "subtopic",
    act: "TOBACCO-BLOCK-SUBTOPIC",
    depending: "DA0",
    exception: "Tobacco",
    condition: "in",
  },
  {
    id: "DA.1.1.340",
    shortname: "tobacco-block-6",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "TOBACCO-BLOCK-6",
    depending: "DA0",
    exception: "Tobacco",
    condition: "in",
    link: "TOBACCO-BLOCK-7",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.340.1",
    extend: "true",
    shortname: "tobacco-block-7",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "TOBACCO-BLOCK-7",
    depending: "TOBACCO-BLOCK-6",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-8",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.340.2",
    extend: "true",
    shortname: "tobacco-block-8",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-8",
    depending: "TOBACCO-BLOCK-6",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-9",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.340.3",
    extend: "true",
    shortname: "tobacco-block-9",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-9",
    depending: "TOBACCO-BLOCK-6",
    exception: "This has happened",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.341",
    shortname: "tobacco-block-10",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "TOBACCO-BLOCK-10",
    depending: "DA0",
    exception: "Tobacco",
    condition: "in",
    link: "TOBACCO-BLOCK-11",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.341.1",
    extend: "true",
    shortname: "tobacco-block-11",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "TOBACCO-BLOCK-11",
    depending: "TOBACCO-BLOCK-10",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-12",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.341.2",
    extend: "true",
    shortname: "tobacco-block-12",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-12",
    depending: "TOBACCO-BLOCK-10",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-13",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.341.3",
    extend: "true",
    shortname: "tobacco-block-13",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-13",
    depending: "TOBACCO-BLOCK-10",
    exception: "This has happened",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.342",
    shortname: "tobacco-block-14",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "TOBACCO-BLOCK-14",
    depending: "DA0",
    exception: "Tobacco",
    condition: "in",
    link: "TOBACCO-BLOCK-15",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.342.1",
    extend: "true",
    shortname: "tobacco-block-15",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "TOBACCO-BLOCK-15",
    depending: "TOBACCO-BLOCK-14",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-16",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.342.2",
    extend: "true",
    shortname: "tobacco-block-16",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-16",
    depending: "TOBACCO-BLOCK-14",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-17",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.342.3",
    extend: "true",
    shortname: "tobacco-block-17",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-17",
    depending: "TOBACCO-BLOCK-14",
    exception: "This has happened",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.343",
    shortname: "tobacco-block-18",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "TOBACCO-BLOCK-18",
    depending: "DA0",
    exception: "Tobacco",
    condition: "in",
    link: "TOBACCO-BLOCK-19",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.343.1",
    extend: "true",
    shortname: "tobacco-block-19",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "TOBACCO-BLOCK-19",
    depending: "TOBACCO-BLOCK-18",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-30",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.343.2",
    extend: "true",
    shortname: "tobacco-block-20",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-20",
    depending: "TOBACCO-BLOCK-18",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-21",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.343.3",
    extend: "true",
    shortname: "tobacco-block-21",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-21",
    depending: "TOBACCO-BLOCK-18",
    exception: "This has happened",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.344",
    shortname: "tobacco-block-22",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "TOBACCO-BLOCK-22",
    depending: "DA0",
    exception: "Tobacco",
    condition: "in",
    link: "TOBACCO-BLOCK-23",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.344.1",
    extend: "true",
    shortname: "tobacco-block-23",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "TOBACCO-BLOCK-23",
    depending: "TOBACCO-BLOCK-22",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-24",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.344.2",
    extend: "true",
    shortname: "tobacco-block-24",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-24",
    depending: "TOBACCO-BLOCK-22",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-25",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.344.3",
    extend: "true",
    shortname: "tobacco-block-25",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-25",
    depending: "TOBACCO-BLOCK-22",
    exception: "This has happened",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.345",
    shortname: "tobacco-block-26",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "TOBACCO-BLOCK-26",
    depending: "DA0",
    exception: "Tobacco",
    condition: "in",
    link: "TOBACCO-BLOCK-27",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.345.1",
    extend: "true",
    shortname: "tobacco-block-27",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "TOBACCO-BLOCK-27",
    depending: "TOBACCO-BLOCK-26",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-28",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.345.2",
    extend: "true",
    shortname: "tobacco-block-28",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-28",
    depending: "TOBACCO-BLOCK-26",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-29",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.345.3",
    extend: "true",
    shortname: "tobacco-block-29",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-29",
    depending: "TOBACCO-BLOCK-26",
    exception: "This has happened",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.346",
    shortname: "tobacco-block-30",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "TOBACCO-BLOCK-30",
    depending: "DA0",
    exception: "Tobacco",
    condition: "in",
    link: "TOBACCO-BLOCK-31",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.346.1",
    extend: "true",
    shortname: "tobacco-block-31",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "TOBACCO-BLOCK-31",
    depending: "TOBACCO-BLOCK-30",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-32",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.346.2",
    extend: "true",
    shortname: "tobacco-block-32",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-32",
    depending: "TOBACCO-BLOCK-30",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-33",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.346.3",
    extend: "true",
    shortname: "tobacco-block-33",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-33",
    depending: "TOBACCO-BLOCK-30",
    exception: "This has happened",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.347",
    shortname: "tobacco-block-34",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "TOBACCO-BLOCK-34",
    depending: "DA0",
    exception: "Tobacco",
    condition: "in",
    link: "TOBACCO-BLOCK-35",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.347.1",
    extend: "true",
    shortname: "tobacco-block-35",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "TOBACCO-BLOCK-35",
    depending: "TOBACCO-BLOCK-34",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-36",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.347.2",
    extend: "true",
    shortname: "tobacco-block-36",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-36",
    depending: "TOBACCO-BLOCK-34",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-37",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.347.3",
    extend: "true",
    shortname: "tobacco-block-37",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-37",
    depending: "TOBACCO-BLOCK-34",
    exception: "This has happened",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.348",
    shortname: "tobacco-block-38",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "TOBACCO-BLOCK-38",
    depending: "DA0",
    exception: "Tobacco",
    condition: "in",
    link: "TOBACCO-BLOCK-39",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.348.1",
    extend: "true",
    shortname: "tobacco-block-39",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "TOBACCO-BLOCK-39",
    depending: "TOBACCO-BLOCK-38",
    exception: "This has happened",
    link: "TOBACCO-BLOCK0",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.348.2",
    extend: "true",
    shortname: "tobacco-block-40",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-40",
    depending: "TOBACCO-BLOCK-38",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-41",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.348.3",
    extend: "true",
    shortname: "tobacco-block-41",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-41",
    depending: "TOBACCO-BLOCK-38",
    exception: "This has happened",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.349",
    shortname: "tobacco-block-42",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "TOBACCO-BLOCK-42",
    depending: "DA0",
    exception: "Tobacco",
    condition: "in",
    link: "TOBACCO-BLOCK-43",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.349.1",
    extend: "true",
    shortname: "tobacco-block-43",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "TOBACCO-BLOCK-43",
    depending: "TOBACCO-BLOCK-42",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-44",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.349.2",
    extend: "true",
    shortname: "tobacco-block-44",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-44",
    depending: "TOBACCO-BLOCK-42",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-45",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.349.3",
    extend: "true",
    shortname: "tobacco-block-45",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-45",
    depending: "TOBACCO-BLOCK-42",
    exception: "This has happened",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.350",
    shortname: "tobacco-block-46",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "TOBACCO-BLOCK-46",
    depending: "DA0",
    exception: "Tobacco",
    condition: "in",
    link: "TOBACCO-BLOCK-47",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.350.1",
    extend: "true",
    shortname: "tobacco-block-47",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "TOBACCO-BLOCK-47",
    depending: "TOBACCO-BLOCK-46",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-48",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.350.2",
    extend: "true",
    shortname: "tobacco-block-48",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-48",
    depending: "TOBACCO-BLOCK-46",
    exception: "This has happened",
    link: "TOBACCO-BLOCK-49",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.350.3",
    extend: "true",
    shortname: "tobacco-block-49",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "TOBACCO-BLOCK-49",
    depending: "TOBACCO-BLOCK-46",
    exception: "This has happened",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.351",
    shortname: "tobacco-block-50",
    name: "Number of criteria met for:",
    prevalue: "Number of criteria met for Tobacco:",
    value: "0",
    disabled: "true",
    type: "content",
    comment: "Consider no diagnosis for Tobacco",
    act: "TOBACCO-BLOCK-50",
    depending: "DA0",
    exception: "Tobacco",
    condition: "in",
    meaningfulcount: "This has happened",
    subtopic_container_act: "TOBACCO-BLOCK-SUBTOPIC",
    suptopic_code: "TOBACCO-BLOCK-12-CONTENT",
    subtopic_name: "Content Criteria",
  },
  //!========================================================================================
  /*                                                                                      *
   *                                    ENDBLOCKTOBACCO                                   *
   *                                                                                      */
  //!========================================================================================
];

export default blockTobacco;
