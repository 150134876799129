const familyHistoryJSON = {
  section: "FR",
  topic: "Family History",
  checked: "true",
  // access: "public",
  color: "secondary",
  description: "",
  body: [
    {
      id: "FH.1",
      shortname: "fr01-add",
      name: "Do you know the name of your biological father?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR01-ADD",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR01",
    },
    {
      id: "FH.1.1",
      shortname: "fr01",
      name: "",
      prevalue: "What is the first and last name of your biological father?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR01",
      depending: "FR01-ADD",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "FH.2",
      shortname: "fr02-add",
      name: "Do you know the name of your biological mother?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR02-ADD",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR02",
    },
    {
      id: "FH.2.1",
      shortname: "fr02",
      name: "",
      prevalue: "What is the first and last name of your biological mother?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR02",
      depending: "FR02-ADD",
      exception: "Yes",
      extend: "true",
    },
    {
      id: "FH.3",
      shortname: "fr03",
      name: "Do they remain together as a couple?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "Not known"],
      act: "FR03",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR04",
    },
    {
      id: "FH.3.1",
      shortname: "fr04",
      name: "",
      prevalue: "If not together, please indicate?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "never married",
        "separated",
        "divorced",
        "father deceased",
        "mother deceased",
        "both deceased",
      ],
      act: "FR04",
      mandatory: "true",
      depending: "FR03",
      exception: "No",
      extend: "true",
      link: "FR05",
    },
    {
      id: "FH.3.1.1",
      shortname: "fr05",
      name: "",
      prevalue:
        "If not together, what age were you when they separated, divorced, or died? Explain.",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR05",
      depending: "FR04",
      exception: "never married",
      extend: "true",
    },
//========================================================================================
/*                                                                                      *
 *                              STEP_FATHER jr STEP_MOTHER                              *
 *                                                                                      */
//========================================================================================
    {
      id: "FH.4",
      shortname: "fr03-add0",
      name: "Have you EVER had a step-father or step-mother?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR03-ADD0",
      mandatory: "true",
      depending: "FR03",
      exception: "No;Not known",
      link: "FR03-ADD2",
    },
    {
      id: "FH.4.1",
      shortname: "fr03-add2",
      name: "",
      prevalue: "What is the name or names of your step-father(s)?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR03-ADD2",
      depending: "FR03-ADD0",
      exception: "Yes",
      extend: "true",
      link: "FR04-ADD2",
    },
    {
      id: "FH.4.2",
      shortname: "fr04-add2",
      name: "",
      prevalue: "What is the name or names of your step-mother(s)?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR04-ADD2",
      depending: "FR03-ADD0",
      exception: "Yes",
      extend: "true",
      link: "FR05-ADD1",
    },
    //---
    {
      id: "FH.4.3",
      shortname: "fr05-add1",
      name: "",
      prevalue: "Do they remain together as a couple?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No", "Not known"],
      act: "FR05-ADD1",
      mandatory: "true",
      depending: "FR03-ADD0",
      exception: "Yes",
      extend: "true",
      link: "FR05-ADD2",
    },
    {
      id: "FH.4.3.1",
      shortname: "fr05-add2",
      name: "",
      prevalue: "If not together, please indicate?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "never married",
        "separated",
        "divorced",
        "father deceased",
        "mother deceased",
        "both deceased",
      ],
      act: "FR05-ADD2",
      depending: "FR05-ADD1",
      exception: "No",
      extend: "true",
    },

    //---------
    {
      id: "FH.5",
      shortname: "fr05-new1-add",
      name: "Have you EVER been adopted?",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR05-NEW1-ADD",
      mandatory: "true",
      depending: "FR03",
      exception: "No;Not known",
      link: "FR05-NEW1",
    },

    {
      id: "FH.5.1",
      shortname: "fr05-new1",
      name: "",
      prevalue: "If adopted, father’s name?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR05-NEW1",
      depending: "FR05-NEW1-ADD",
      exception: "Yes",
      extend: "true",
      link: "FR05-NEW2",
    },
    {
      id: "FH.5.2",
      shortname: "fr05-new2",
      name: "",
      prevalue: "If adopted, mother’s name?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR05-NEW2",
      depending: "FR05-NEW1-ADD",
      exception: "Yes",
      extend: "true",
      link: "FR05-NEW2-COUPLE",
    },
    {
      id: "FH.5.3",
      shortname: "fr05-new2-couple",
      name: "",
      prevalue: "Do they remain together as a couple?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No", "Not known"],
      act: "FR05-NEW2-COUPLE",
      mandatory: "true",
      depending: "FR05-NEW1-ADD",
      exception: "Yes",
      link: "FR05-NEW2-COUPLE-ADD",
      extend: "true",
    },
    {
      id: "FH.5.3.1",
      shortname: "fr05-new2-couple-add",
      name: "",
      prevalue: "If not together, please indicate?",
      value: "",
      disabled: "true",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "never married",
        "separated",
        "divorced",
        "father deceased",
        "mother deceased",
        "both deceased",
      ],
      act: "FR05-NEW2-COUPLE-ADD",
      depending: "FR05-NEW2-COUPLE",
      exception: "No",
      extend: "true",
    },

    //-----------------------------
    {
      id: "FH.6",
      shortname: "fr06-table",
      name: "Do you have any siblings?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR06-TABLE",
      mandatory: "true",
      depending: "",
      exception: "",
      // subtopic_container_act: "FR6-SUBTOPIC",
    },
    {
      id: "",
      shortname: "fr6-subtopic",
      name: "How many brothers and sisters do you have? Enter the correct number below.",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "subtopic",
      act: "FR6-SUBTOPIC",
      depending: "FR06-TABLE",
      exception: "Yes",
    },

    /********** */
    {
      id: "FH.6.1",
      shortname: "fr06",
      name: "",
      prevalue: "Biological Siblings?",
      value: "0",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "FR06",
      depending: "FR06-TABLE",
      exception: "Yes",
      // link: "FR07",
      subtopic_container_act: "FR6-SUBTOPIC",
      suptopic_code: "FR6_BROTHERS",
      subtopic_name: "Brothers",
    },
    {
      id: "FH.6.2",
      shortname: "fr06-half",
      name: "",
      prevalue: "Half Siblings?",
      value: "0",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "FR06-HALF",
      depending: "FR06-TABLE",
      exception: "Yes",
      // link: "FR07",
      subtopic_container_act: "FR6-SUBTOPIC",
      suptopic_code: "FR6_BROTHERS",
      subtopic_name: "Brothers",
    },
    {
      id: "FH.6.3",
      shortname: "fr06-adopted",
      name: "",
      prevalue: "Adopted Siblings?",
      value: "0",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "FR06-ADOPTED",
      depending: "FR06-TABLE",
      exception: "Yes",
      // link: "FR07",
      subtopic_container_act: "FR6-SUBTOPIC",
      suptopic_code: "FR6_BROTHERS",
      subtopic_name: "Brothers",
    },
    {
      id: "FH.6.4",
      shortname: "fr06-step",
      name: "",
      prevalue: "Step Siblings?",
      value: "0",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "FR06-STEP",
      depending: "FR06-TABLE",
      exception: "Yes",
      // link: "FR07",
      subtopic_container_act: "FR6-SUBTOPIC",
      suptopic_code: "FR6_BROTHERS",
      subtopic_name: "Brothers",
    },

    {
      id: "FH.6.5",
      shortname: "fr07",
      name: "",
      prevalue: "Biological Siblings?",
      value: "0",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "FR07",
      depending: "FR06-TABLE",
      exception: "Yes",
      subtopic_container_act: "FR6-SUBTOPIC",
      suptopic_code: "FR6_SISTERS",
      subtopic_name: "Sisters",
    },
    {
      id: "FH.6.6",
      shortname: "fr07-half",
      name: "",
      prevalue: "Half Siblings?",
      value: "0",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "FR07-HALF",
      depending: "FR06-TABLE",
      exception: "Yes",
      subtopic_container_act: "FR6-SUBTOPIC",
      suptopic_code: "FR6_SISTERS",
      subtopic_name: "Sisters",
    },
    {
      id: "FH.6.7",
      shortname: "fr07-adopted",
      name: "",
      prevalue: "Adopted Siblings?",
      value: "0",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "FR07-ADOPTED",
      depending: "FR06-TABLE",
      exception: "Yes",
      subtopic_container_act: "FR6-SUBTOPIC",
      suptopic_code: "FR6_SISTERS",
      subtopic_name: "Sisters",
    },
    {
      id: "FH.6.8",
      shortname: "fr07-step",
      name: "",
      prevalue: "Step Siblings?",
      value: "0",
      disabled: "true",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      act: "FR07-STEP",
      depending: "FR06-TABLE",
      exception: "Yes",
      subtopic_container_act: "FR6-SUBTOPIC",
      suptopic_code: "FR6_SISTERS",
      subtopic_name: "Sisters",
    },
    /****************************************************** */
    {
      id: "FH.7",
      shortname: "fr8-free",
      access: "private",
      name: "In your own words, please describe what it was like growing up as a child. Describe your relationship with your parental figure(s), siblings, and other significant relationships.:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "editor",
      act: "FR8-FREE",
      depending: "",
      exception: "",
    },
    /****************************************************** */

    {
      id: "FH.8",
      shortname: "fr08-00",
      name: "Marital Status:",
      prevalue: "",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR08-00",
      depending: "",
      exception: "",
    },
    {
      id: "FH.9",
      shortname: "fr08-01",
      name: "Currently, are you living with a significant other?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR08-01",
      mandatory: "true",
      depending: "DEMO1",
      exception: "married",
      link: "FR08",
    },
    {
      id: "FH.9.1",
      shortname: "fr08",
      name: "",
      prevalue:
        "If currently married or living with a significant other, what is his/her/their name?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR08",
      depending: "FR08-01",
      exception: "Yes",
      extend: "true",
      link: "FR09",
    },
    {
      id: "FH.9.2",
      shortname: "fr09",
      name: "",
      prevalue: "How many years have you been in this current relationship?",
      value: "",
      disabled: "true",
      type: "number",
      act: "FR09",
      depending: "FR08-01",
      exception: "Yes",
      extend: "true",
      link: "FR10",
    },
    {
      id: "FH.9.3",
      shortname: "fr10",
      name: "",
      prevalue: "Are you satisfied with living arrangements?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR10",
      mandatory: "true",
      depending: "FR08-01",
      exception: "Yes",
      link: "FR10-1",
      extend: "true",
    },
    {
      id: "FH.9.3.1",
      extend: "true",
      shortname: "fr10-1",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR10-1",
      depending: "FR10",
      exception: "No",
    },
    {
      id: "FH.10",
      shortname: "fr-new11",
      name: "How many times have you been married, before?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: ["", "never", "1", "2", "3", "4", "5"],
      act: "FR-NEW11",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR-NEW11-1",
    },
    {
      id: "FH.10.1",
      shortname: "fr-new11-1",
      name: "",
      prevalue: "1. spouse's name:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR-NEW11-1",
      depending: "FR-NEW11",
      exception: "never",
      extend: "true",
      link: "FR-NEW11-2",
    },
    {
      id: "FH.10.2",
      shortname: "fr-new11-2",
      name: "",
      prevalue: "2. spouse's name:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR-NEW11-2",
      depending: "FR-NEW11",
      exception: "1",
      extend: "true",
      link: "FR-NEW11-3",
    },
    {
      id: "FH.10.3",
      shortname: "fr-new11-3",
      name: "",
      prevalue: "3. spouse's name:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR-NEW11-3",
      depending: "FR-NEW11",
      exception: "2",
      extend: "true",
      link: "FR-NEW11-4",
    },
    {
      id: "FH.10.4",
      shortname: "fr-new11-4",
      name: "",
      prevalue: "4. spouse's name:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR-NEW11-4",
      depending: "FR-NEW11",
      exception: "3",
      extend: "true",
      link: "FR-NEW11-5",
    },
    {
      id: "FH.10.5",
      shortname: "fr-new11-5",
      name: "",
      prevalue: "5. spouse's name:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR-NEW11-5",
      depending: "FR-NEW11",
      exception: "4",
      extend: "true",
    },
    {
      id: "FH.11",
      shortname: "fr11",
      name: "How many children do you have?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "number",
      act: "FR11",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR12",
    },
    {
      id: "FH.11.1",
      shortname: "fr12",
      name: "",
      prevalue: "What are their name(s) and current ages?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR12",
      depending: "FR11",
      exception: "0",
      extend: "true",
    },
    {
      id: "FH.12",
      shortname: "fr13",
      name: "What has been your usual living arrangement for most of the past 3 years? Explain.",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "With sexual partner & children",
        "With sexual partner alone",
        "With children alone",
        "With parents",
        "With family",
        "With friends",
        "Alone",
        "Controlled environment",
        "No stable arrangement",
      ],
      act: "FR13",
      depending: "",
      exception: "",
    },
    {
      id: "",
      shortname: "fr14-fr14a-subtopic",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "FR14-FR14A-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "FH.13",
      shortname: "fr14",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR14",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR-NEW18",
      subtopic_container_act: "FR14-FR14A-ACT",
      suptopic_code: "FR14-CODE",
      subtopic_name:
        "Do you live with someone who has a current alcohol problem?",
    },
    {
      id: "FH.13.1",
      shortname: "fr-new18",
      name: "",
      prevalue: "If yes, explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR-NEW18",
      depending: "FR14",
      exception: "Yes",
      extend: "true",
      subtopic_container_act: "FR14-FR14A-ACT",
      suptopic_code: "FR14-CODE",
      subtopic_name:
        "Do you live with someone who has a current alcohol problem?",
    },
    {
      id: "FH.13.2",
      shortname: "fr14a",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR14A",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR-NEW18A",
      subtopic_container_act: "FR14-FR14A-ACT",
      suptopic_code: "FR14A-CODE",
      subtopic_name:
        "Do you live with someone uses non-prescribed drugs?",

    },
    {
      id: "FH.13.3",
      shortname: "fr-new18a",
      name: "",
      prevalue: "If yes, explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR-NEW18A",
      depending: "FR14A",
      exception: "Yes",
      extend: "true",
      subtopic_container_act: "FR14-FR14A-ACT",
      suptopic_code: "FR14A-CODE",
      subtopic_name:
        "Do you live with someone uses non-prescribed drugs?",
    },
    {
      id: "FH.14",
      shortname: "fr15",
      name: "How many close/best friends do you have?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
      ],
      act: "FR15",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR-NEW20",
    },
    {
      id: "FH.14.1",
      shortname: "fr-new20",
      name: "",
      prevalue: "Do any of these friends have an alcohol problem or use drugs?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR-NEW20",
      depending: "FR15",
      exception: "0",
      extend: "true",
    },
    {
      id: "FH.15",
      shortname: "fr16",
      name: "With whom do you spends most of your time?",
      prevalue: "",
      value: "with ",
      disabled: "false",
      type: "text",
      act: "FR16",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "FH.16",
      shortname: "fr17a",
      name: "How many days in the past 30 days have you had serious conflicts with anyone in your family?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "FR17A",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "FH.17",
      shortname: "fr17b",
      name: "How many days in the past 30 days have you had serious conflicts with anyone other than a family member?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "number",
      select_options: [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      act: "FR17B",
      mandatory: "true",
      depending: "",
      exception: "",
    },

    //========================================================================================
    /*                                                                                      *
     *                                         FH 18                                        *
     *                                                                                      */
    //========================================================================================

    {
      id: "FH.18",
      shortname: "fh18-subtopic",
      name: 'Have you had a close reciprocal relationship with the identified people in your life? All of the items below have been defaulted to YES. If your answer is NO or "no contact", please change Yes to No or "no contact". ',
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "FH18-SUBTOPIC",
      depending: "",
      exception: "",
    },
    {
      id: "FH.18.1",
      shortname: "fr18a",
      name: "Past 30 day",
      prevalue: "Father",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR18A",
      depending: "",
      exception: "",
      link: "FR18B-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.18.1.1",
      extend: "true",
      shortname: "fr18a-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18A-NO",
      depending: "FR18A",
      exception: "No",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
      link: "FR18B",
    },

    {
      id: "FH.18.2",
      extend: "true",
      shortname: "fr18b",
      name: "",
      prevalue: "Mother",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR18B",
      depending: "",
      exception: "",
      link: "FR18B-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.18.2.1",
      extend: "true",
      shortname: "fr18b-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18B-NO",
      depending: "FR18B",
      exception: "No",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
      link: "FR18C",
    },

    {
      id: "FH.18.3",
      extend: "true",
      shortname: "fr18c",
      name: "",
      prevalue: "Siblings",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR18C",
      depending: "",
      exception: "",
      link: "FR18C-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.18.3.1",
      extend: "true",
      shortname: "fr18c-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18C-NO",
      depending: "FR18C",
      exception: "No",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
      link: "FR18D",
    },
    {
      id: "FH.18.4",
      extend: "true",
      shortname: "fr18d",
      name: "",
      prevalue: "Significant other",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR18D",
      depending: "",
      exception: "",
      link: "FR18D-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.18.4.1",
      extend: "true",
      shortname: "fr18d-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18D-NO",
      depending: "FR18D",
      exception: "No",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
      link: "FR18E",
    },
    {
      id: "FH.18.5",
      extend: "true",
      shortname: "fr18e",
      name: "",
      prevalue: "Child/Children",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR18E",
      depending: "",
      exception: "",
      link: "FR18E-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.18.5.1",
      extend: "true",
      shortname: "fr18e-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18E-NO",
      depending: "FR18E",
      exception: "No",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
      link: "FR18F",
    },
    {
      id: "FH.18.6",
      extend: "true",
      shortname: "fr18f",
      name: "",
      prevalue: "Friends",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR18F",
      depending: "",
      exception: "",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
      link: "FR18F-NO",
    },
    {
      id: "FH.18.6.1",
      extend: "true",
      shortname: "fr18f-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18F-NO",
      depending: "FR18F",
      exception: "No",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.18.7",
      shortname: "fr18a-1",
      name: "In lifetime",
      prevalue: "Father",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR18A-1",
      depending: "",
      exception: "",
      link: "FR18A-1-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.18.7.1",
      extend: "true",
      shortname: "fr18a-1-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18A-1-NO",
      depending: "FR18A-1",
      exception: "No",
      link: "FR18B-1",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.18.8",
      extend: "true",
      shortname: "fr18b-1",
      name: "",
      prevalue: "Mother",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR18B-1",
      depending: "",
      exception: "",
      link: "FR18B-1-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.18.8.1",
      extend: "true",
      shortname: "fr18b-1-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18B-1-NO",
      depending: "FR18B-1",
      exception: "No",
      link: "FR18C-1",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.18.9",
      extend: "true",
      shortname: "fr18c-1",
      name: "",
      prevalue: "Siblings",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR18C-1",
      depending: "",
      exception: "",
      link: "FR18C-1-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.18.9.1",
      extend: "true",
      shortname: "fr18c-1-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18C-1-NO",
      depending: "FR18C-1",
      exception: "No",
      link: "FR18D-1",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.18.10",
      extend: "true",
      shortname: "fr18d-1",
      name: "",
      prevalue: "Significant other",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR18D-1",
      depending: "",
      exception: "",
      link: "FR18D-1-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.18.10.1",
      extend: "true",
      shortname: "fr18d-1-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18D-1-NO",
      depending: "FR18D-1",
      exception: "No",
      link: "FR18E-1",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.18.11",
      extend: "true",
      shortname: "fr18e-1",
      name: "",
      prevalue: "Child/Children",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR18E-1",
      depending: "",
      exception: "",
      link: "FR18E-1-NO",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.18.11.1",
      extend: "true",
      shortname: "fr18e-1-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18E-1-NO",
      depending: "FR18E-1",
      exception: "No",
      link: "FR18F-1",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.18.12",
      extend: "true",
      shortname: "fr18f-1",
      name: "",
      prevalue: "Friends",
      value: "Yes",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR18F-1",
      depending: "",
      exception: "",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.18.12.1",
      extend: "true",
      shortname: "fr18f-1-no",
      name: "",
      prevalue: "If no, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR18F-1-NO",
      depending: "FR18F-1",
      exception: "No",
      subtopic_container_act: "FH18-SUBTOPIC",
      suptopic_code: "FR18_LIFETIME",
      subtopic_name: "In lifetime",
    },
    //========================================================================================
    /*                                                                                      *
     *                                         FH 19                                        *
     *                                                                                      */
    //========================================================================================

    {
      id: "FH.19",
      shortname: "fh19-subtopic",
      name: 'Have you EVER had a significant period in your life when there were serious problems getting along with the identified people?  All of the items below have been defaulted to NO. If your answer is YES or "no contact", please change NO to YES or "no contact".',
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "FH19-SUBTOPIC",
      depending: "",
      exception: "",
    },
    // A
    {
      id: "FH.19.1",
      shortname: "fr19a",
      name: "Past 30 day",
      prevalue: "Father",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR19A",
      depending: "",
      exception: "",
      link: "FR19A-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.19.1.1",
      extend: "true",
      shortname: "fr19a-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19A-YES",
      depending: "FR19A",
      exception: "Yes",
      link: "FR19B",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    // B
    {
      id: "FH.19.2",
      extend: "true",
      shortname: "fr19b",
      name: "",
      prevalue: "Mother",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR19B",
      depending: "",
      exception: "",
      link: "FR19B-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.19.2.1",
      extend: "true",
      shortname: "fr19b-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19B-YES",
      depending: "FR19B",
      exception: "Yes",
      link: "FR19C",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    // C
    {
      id: "FH.19.3",
      extend: "true",
      shortname: "fr19c",
      name: "",
      prevalue: "Siblings",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR19C",
      depending: "",
      exception: "",
      link: "FR19C-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.19.3.1",
      extend: "true",
      shortname: "fr19c-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19C-YES",
      depending: "FR19C",
      exception: "Yes",
      link: "FR19D",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    // D
    {
      id: "FH.19.4",
      extend: "true",
      shortname: "fr19d",
      name: "",
      prevalue: "Significant other",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR19D",
      depending: "",
      exception: "",
      link: "FR19D-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.19.4.1",
      extend: "true",
      shortname: "fr19d-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19D-YES",
      depending: "FR19D",
      exception: "Yes",
      link: "FR19E",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    // E
    {
      id: "FH.19.5",
      shortname: "fr19e",
      name: "",
      prevalue: "Other Family",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR19E",
      depending: "",
      exception: "",
      link: "FR19E-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.19.5.1",
      extend: "true",
      shortname: "fr19e-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19E-YES",
      depending: "FR19E",
      exception: "Yes",
      link: "FR19F",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    //F
    {
      id: "FH.19.6",
      extend: "true",
      shortname: "fr19f",
      name: "",
      prevalue: "Child/Children",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR19F",
      depending: "",
      exception: "",
      link: "FR19F-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.19.6.1",
      extend: "true",
      shortname: "fr19f-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19F-YES",
      depending: "FR19F",
      exception: "Yes",
      link: "FR19G",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    // G
    {
      id: "FH.19.7",
      extend: "true",
      shortname: "fr19g",
      name: "",
      prevalue: "Friends",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR19G",
      depending: "",
      exception: "",
      link: "FR19G-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.19.7.1",
      extend: "true",
      shortname: "fr19g-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19G-YES",
      depending: "FR19G",
      exception: "Yes",
      link: "FR19H",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    // H
    {
      id: "FH.19.8",
      extend: "true",
      shortname: "fr19h",
      name: "",
      prevalue: "Neighbors",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR19H",
      depending: "",
      exception: "",
      link: "FR19H-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.19.8.1",
      extend: "true",
      shortname: "fr19h-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19H-YES",
      depending: "FR19H",
      exception: "Yes",
      link: "FR19J",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    // J
    {
      id: "FH.19.9",
      extend: "true",
      shortname: "fr19j",
      name: "",
      prevalue: "Co-Workers",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR19J",
      depending: "",
      exception: "",
      link: "FR19J-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    {
      id: "FH.19.9.1",
      extend: "true",
      shortname: "fr19j-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19J-YES",
      depending: "FR19J",
      exception: "Yes",
      // link: "FR19J",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_30DAYS",
      subtopic_name: "Past 30 day",
    },
    //========================================================================================
    /*                                                                                      *
     *                                      In lifetime                                     *
     *                                                                                      */
    //========================================================================================
    //A
    {
      id: "FH.19.10",
      shortname: "fr19a-1",
      name: "In lifetime",
      prevalue: "Father",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR19A-1",
      depending: "",
      exception: "",
      link: "FR19A-1-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.19.10.1",
      extend: "true",
      shortname: "fr19a-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19A-1-YES",
      depending: "FR19A-1",
      exception: "Yes",
      link: "FR19B-1",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    // B
    {
      id: "FH.19.11",
      extend: "true",
      shortname: "fr19b-1",
      name: "",
      prevalue: "Mother",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR19B-1",
      depending: "",
      exception: "",
      link: "FR19B-1-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.19.11.1",
      extend: "true",
      shortname: "fr19b-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19B-1-YES",
      depending: "FR19B-1",
      exception: "Yes",
      link: "FR19C-1",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    // C
    {
      id: "FH.19.12",
      extend: "true",
      shortname: "fr19c-1",
      name: "",
      prevalue: "Siblings",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR19C-1",
      depending: "",
      exception: "",
      link: "FR19C-1-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.19.12.1",
      extend: "true",
      shortname: "fr19c-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19C-1-YES",
      depending: "FR19C-1",
      exception: "Yes",
      link: "FR19D-1",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    // D
    {
      id: "FH.19.13",
      extend: "true",
      shortname: "fr19d-1",
      name: "",
      prevalue: "Significant other",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR19D-1",
      depending: "",
      exception: "",
      link: "FR19D-1-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.19.13.1",
      extend: "true",
      shortname: "fr19d-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19D-1-YES",
      depending: "FR19D-1",
      exception: "Yes",
      link: "FR19E-1",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    // E
    {
      id: "FH.19.14",
      shortname: "fr19e-1",
      name: "",
      prevalue: "Other Family",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR19E-1",
      depending: "",
      exception: "",
      link: "FR19E-1-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.19.14.1",
      extend: "true",
      shortname: "fr19e-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19E-1-YES",
      depending: "FR19E-1",
      exception: "Yes",
      link: "FR19F-1",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    // F
    {
      id: "FH.19.15",
      extend: "true",
      shortname: "fr19f-1",
      name: "",
      prevalue: "Child/Children",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR19F-1",
      depending: "",
      exception: "",
      link: "FR19F-1-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.19.15.1",
      extend: "true",
      shortname: "fr19f-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19F-1-YES",
      depending: "FR19F-1",
      exception: "Yes",
      link: "FR19G-1",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    // G
    {
      id: "FH.19.16",
      extend: "true",
      shortname: "fr19g-1",
      name: "",
      prevalue: "Friends",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR19G-1",
      depending: "",
      exception: "",
      link: "FR19G-1-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.19.16.1",
      extend: "true",
      shortname: "fr19g-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19G-1-YES",
      depending: "FR19G-1",
      exception: "Yes",
      link: "FR19H-1",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    // H
    {
      id: "FH.19.17",
      extend: "true",
      shortname: "fr19h-1",
      name: "",
      prevalue: "Neighbors",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR19H-1",
      depending: "",
      exception: "",
      link: "FR19H-1-YES",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.19.17.1",
      extend: "true",
      shortname: "fr19h-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19H-1-YES",
      depending: "FR19H-1",
      exception: "Yes",
      link: "FR19J-1",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },

    // J
    {
      id: "FH.19.18",
      extend: "true",
      shortname: "fr19j-1",
      name: "",
      prevalue: "Co-Workers",
      value: "No",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No", "no contact"],
      act: "FR19J-1",
      depending: "",
      exception: "",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    {
      id: "FH.19.18.1",
      extend: "true",
      shortname: "fr19j-1-yes",
      name: "",
      prevalue: "If yes, please explain",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR19J-1-YES",
      depending: "FR19J-1",
      exception: "Yes",
      // link: "FR19J",
      subtopic_container_act: "FH19-SUBTOPIC",
      suptopic_code: "FR19_LIFETIME",
      subtopic_name: "In lifetime",
    },
    /************************************************************************** */
    // {
    //   id: "FH.20",
    //   shortname: "fr20",
    //   name:
    //     "Have you EVER been emotionally abused? For the past 30 days or lifetime.",
    //   prevalue: "",
    //   value: "",
    //   disabled: "false",
    //   type: "radio",
    //   radio_options: ["Yes", "No"],
    //   act: "FR20",
    //   mandatory: "true",
    //   depending: "",
    //   exception: "",
    //   link: "FR20A",
    // },
    // {
    //   id: "FH.20.1",
    //   shortname: "fr20a",
    //   name: "",
    //   prevalue: "If yes for the past 30 days who abused you and briefly describe?",
    //   value: "",
    //   disabled: "true",
    //   type: "text",
    //   act: "FR20A",
    //   depending: "FR20",
    //   exception: "Yes",
    //   extend: "true",
    //   link: "FR20B",
    // },
    // {
    //   id: "FH.20.2",
    //   shortname: "fr20b",
    //   name: "",
    //   prevalue: "If yes for your lifetime who abused you and briefly describe?",
    //   value: "",
    //   disabled: "true",
    //   type: "text",
    //   act: "FR20B",
    //   depending: "FR20",
    //   exception: "Yes",
    //   extend: "true",
    // },
    // {
    //   id: "",
    //   shortname: "fr20-subtopic",
    //   name:
    //     "Have you EVER been emotionally abused?",
    //   prevalue: "",
    //   value: "",
    //   disabled: "false",
    //   type: "subtopic",
    //   act: "FR20-SUBTOPIC",
    //   depending: "",
    //   exception: "",
    // },
    {
      id: "FH.20.1",
      shortname: "fr20",
      name: "Have you EVER been emotionally abused?",
      prevalue: "For the past 30 days.",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR20",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR20A",
    },
    {
      id: "FH.20.1.1",
      extend: "true",
      shortname: "fr20a",
      name: "",
      prevalue:
        "If yes for the past 30 days who abused you and briefly describe?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR20A",
      depending: "FR20",
      exception: "Yes",
      link: "FR20-2",
    },
    {
      id: "FH.20.2",
      extend: "true",
      shortname: "fr20-2",
      name: "",
      prevalue: "For the lifetime.",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR20-2",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR20B",
    },
    {
      id: "FH.20.2.1",
      shortname: "fr20b",
      name: "",
      prevalue: "If yes for your lifetime who abused you and briefly describe?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR20B",
      depending: "FR20-2",
      exception: "Yes",
      extend: "true",
    },
    /************************************************************************************* */
    // {
    //   id: "FH.21",
    //   shortname: "fr21",
    //   name: "Have you EVER been physically abused?",
    //   prevalue: "",
    //   value: "",
    //   disabled: "false",
    //   type: "radio",
    //   radio_options: ["Yes", "No"],
    //   act: "FR21",
    //   mandatory: "true",
    //   depending: "",
    //   exception: "",
    //   link: "FR21A",
    // },
    // {
    //   id: "FH.21.1",
    //   shortname: "fr21a",
    //   name: "",
    //   prevalue: "If yes for the past 30 days who abused you and briefly describe?",
    //   value: "",
    //   disabled: "true",
    //   type: "text",
    //   act: "FR21A",
    //   depending: "FR21",
    //   exception: "Yes",
    //   extend: "true",
    //   link: "FR21B",
    // },
    // {
    //   id: "FH.21.2",
    //   shortname: "fr21b",
    //   name: "",
    //   prevalue: "If yes for your lifetime who abused you and briefly describe?",
    //   value: "",
    //   disabled: "true",
    //   type: "text",
    //   act: "FR21B",
    //   depending: "FR21",
    //   exception: "Yes",
    //   extend: "true",
    // },
    {
      id: "FH.21.1",
      shortname: "fr21",
      name: "Have you EVER been physically abused?",
      prevalue: "For the past 30 days.",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR21",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR21A",
    },
    {
      id: "FH.21.1.1",
      extend: "true",
      shortname: "fr21a",
      name: "",
      prevalue:
        "If yes for the past 30 days who abused you and briefly describe?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR21A",
      depending: "FR21",
      exception: "Yes",
      link: "FR21-2",
    },
    {
      id: "FH.21.2",
      extend: "true",
      shortname: "fr21-2",
      name: "",
      prevalue: "For the lifetime.",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR21-2",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR21B",
    },
    {
      id: "FH.21.2.1",
      shortname: "fr21b",
      name: "",
      prevalue: "If yes for your lifetime who abused you and briefly describe?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR21B",
      depending: "FR21-2",
      exception: "Yes",
      extend: "true",
    },
    /************************************************** */
    // {
    //   id: "FH.22",
    //   shortname: "fr22",
    //   name: "Have you EVER been sexually abused?",
    //   prevalue: "",
    //   value: "",
    //   disabled: "false",
    //   type: "radio",
    //   radio_options: ["Yes", "No"],
    //   act: "FR22",
    //   mandatory: "true",
    //   depending: "",
    //   exception: "",
    //   link: "FR22A",
    // },
    // {
    //   id: "FH.22.1",
    //   shortname: "fr22a",
    //   name: "",
    //   prevalue: "If yes for the past 30 days who abused you and briefly describe?",
    //   value: "",
    //   disabled: "true",
    //   type: "text",
    //   act: "FR22A",
    //   depending: "FR22",
    //   exception: "Yes",
    //   extend: "true",
    //   link: "FR22B",
    // },
    // {
    //   id: "FH.22.2",
    //   shortname: "fr22b",
    //   name: "",
    //   prevalue: "If yes for your lifetime who abused you and briefly describe?",
    //   value: "",
    //   disabled: "true",
    //   type: "text",
    //   act: "FR22B",
    //   depending: "FR22",
    //   exception: "Yes",
    //   extend: "true",
    // },
    {
      id: "FH.22.1",
      shortname: "fr22",
      name: "Have you EVER been sexually abused?",
      prevalue: "For the past 30 days.",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR22",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR22A",
    },
    {
      id: "FH.22.1.1",
      extend: "true",
      shortname: "fr22a",
      name: "",
      prevalue:
        "If yes for the past 30 days who abused you and briefly describe?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR22A",
      depending: "FR22",
      exception: "Yes",
      link: "FR22-2",
    },
    {
      id: "FH.21.2",
      extend: "true",
      shortname: "fr22-2",
      name: "",
      prevalue: "For the lifetime.",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR22-2",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR22B",
    },
    {
      id: "FH.22.2.1",
      shortname: "fr22b",
      name: "",
      prevalue: "If yes for your lifetime who abused you and briefly describe?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR22B",
      depending: "FR22-2",
      exception: "Yes",
      extend: "true",
    },
    /******************************************************* */
    {
      id: "FH.23",
      shortname: "fr23",
      name: "Would you like to talk to a counselor about any abuse history?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR23",
      depending: "",
      exception: "",
    },

    {
      id: "FH.24",
      shortname: "fr23-new",
      name: "Have any of the following biological family members had an alcohol, drug or mental health problem? ",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "FR23-NEW",
      depending: "",
      exception: "",
    },
    // Father's side

    // Grandfather
    {
      id: "FH.24.1",
      shortname: "fr25",
      name: "On your Father’s side",
      prevalue: "Grandfather?",
      value: [false, false, false, false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Alcohol", "Drug", "Mental Health", "I don't know"],
      act: "FR25",
      depending: "",
      exception: "",
      link: "FR25-AD",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_FATHERSSIDE",
      subtopic_name: "Father's side",
    },
    {
      id: "FH.24.1.1",
      extend: "true",
      shortname: "fr25-ad",
      name: "",
      prevalue: "Is this person currently using?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR25-AD",
      depending: "FR25",
      exception: "Alcohol;Drug",
      link: "FR25-AD1",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_FATHERSSIDE",
      subtopic_name: "Father's side",
    },
    {
      id: "FH.24.1.2",
      extend: "true",
      shortname: "fr25-ad1",
      name: "",
      prevalue: "Did this person EVER have treatment or counselling?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR25-AD1",
      depending: "FR25",
      exception: "Alcohol;Drug",
      link: "FR25-AD2",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_FATHERSSIDE",
      subtopic_name: "Father's side",
    },
    {
      id: "FH.24.1.3",
      extend: "true",
      shortname: "fr25-ad2",
      name: "",
      prevalue: "What was the diagnosis or possible condition?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR25-AD2",
      depending: "FR25",
      exception: "Mental Health",
      link: "FR25-AD3",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_FATHERSSIDE",
      subtopic_name: "Father's side",
    },
    {
      id: "FH.24.1.4",
      extend: "true",
      shortname: "fr25-ad3",
      name: "",
      prevalue: "Describe the nature of the problem:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR25-AD3",
      depending: "FR25",
      exception: "Mental Health",
      link: "FR26",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_FATHERSSIDE",
      subtopic_name: "Father's side",
    },

    //========================================================================================
    /*                                                                                      *
     *                                      Grandmother                                     *
     *                                                                                      */
    //========================================================================================

    {
      id: "FH.24.2",
      extend: "true",
      shortname: "fr26",
      name: "",
      prevalue: "Grandmother?",
      value: [false, false, false, false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Alcohol", "Drug", "Mental Health", "I don't know"],
      act: "FR26",
      depending: "",
      exception: "",
      link: "FR26-AD",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_FATHERSSIDE",
      subtopic_name: "Father's side",
    },
    {
      id: "FH.24.2.1",
      extend: "true",
      shortname: "fr26-ad",
      name: "",
      prevalue: "Is this person currently using?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR26-AD",
      depending: "FR26",
      exception: "Alcohol;Drug",
      link: "FR26-AD1",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_FATHERSSIDE",
      subtopic_name: "Father's side",
    },
    {
      id: "FH.24.2.2",
      extend: "true",
      shortname: "fr26-ad1",
      name: "",
      prevalue: "Did this person EVER have treatment or counselling?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR26-AD1",
      depending: "FR26",
      exception: "Alcohol;Drug",
      link: "FR26-AD2",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_FATHERSSIDE",
      subtopic_name: "Father's side",
    },
    {
      id: "FH.24.2.3",
      extend: "true",
      shortname: "fr26-ad2",
      name: "",
      prevalue: "What was the diagnosis or possible condition?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR26-AD2",
      depending: "FR26",
      exception: "Mental Health",
      link: "FR26-AD3",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_FATHERSSIDE",
      subtopic_name: "Father's side",
    },
    {
      id: "FH.24.2.4",
      extend: "true",
      shortname: "fr26-ad3",
      name: "",
      prevalue: "Describe the nature of the problem:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR26-AD3",
      depending: "FR26",
      exception: "Mental Health",
      link: "FR27",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_FATHERSSIDE",
      subtopic_name: "Father's side",
    },
    //========================================================================================
    /*                                                                                      *
     *                                        Father                                        *
     *                                                                                      */
    //========================================================================================

    {
      id: "FH.24.3",
      extend: "true",
      shortname: "fr27",
      name: "",
      prevalue: "Father?",
      value: [false, false, false, false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Alcohol", "Drug", "Mental Health", "I don't know"],
      act: "FR27",
      depending: "",
      exception: "",
      link: "FR27-AD",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_FATHERSSIDE",
      subtopic_name: "Father's side",
    },
    {
      id: "FH.24.3.1",
      extend: "true",
      shortname: "fr27-ad",
      name: "",
      prevalue: "Is this person currently using?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR27-AD",
      depending: "FR27",
      exception: "Alcohol;Drug",
      link: "FR27-AD1",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_FATHERSSIDE",
      subtopic_name: "Father's side",
    },
    {
      id: "FH.24.3.2",
      extend: "true",
      shortname: "fr27-ad1",
      name: "",
      prevalue: "Did this person EVER have treatment or counselling?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR27-AD1",
      depending: "FR27",
      exception: "Alcohol;Drug",
      link: "FR27-AD2",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_FATHERSSIDE",
      subtopic_name: "Father's side",
    },
    {
      id: "FH.24.3.3",
      extend: "true",
      shortname: "fr27-ad2",
      name: "",
      prevalue: "What was the diagnosis or possible condition?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR27-AD2",
      depending: "FR27",
      exception: "Mental Health",
      link: "FR27-AD3",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_FATHERSSIDE",
      subtopic_name: "Father's side",
    },
    {
      id: "FH.24.3.4",
      extend: "true",
      shortname: "fr27-ad3",
      name: "",
      prevalue: "Describe the nature of the problem:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR27-AD3",
      depending: "FR27",
      exception: "Mental Health",
      link: "FR28",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_FATHERSSIDE",
      subtopic_name: "Father's side",
    },

    //========================================================================================
    /*                                                                                      *
     *                                        Uncles                                        *
     *                                                                                      */
    //========================================================================================

    {
      id: "FH.24.4",
      extend: "true",
      shortname: "fr28",
      name: "",
      prevalue: "Any Uncles?",
      value: [false, false, false, false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Alcohol", "Drug", "Mental Health", "I don't know"],
      act: "FR28",
      depending: "",
      exception: "",
      link: "FR28-AD",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_FATHERSSIDE",
      subtopic_name: "Father's side",
    },
    // {
    //   id: "FH.24.4.1",
    //   extend: "true",
    //   shortname: "fr28-ad",
    //   name: "",
    //   prevalue: "Is this person currently using?",
    //   value: "",
    //   disabled: "true",
    //   type: "radio",
    //   radio_options: ["Yes", "No"],
    //   act: "FR28-AD",
    //   depending: "FR28",
    //   exception: "Alcohol;Drug",
    //   link: "FR28-AD1",
    //   subtopic_container_act: "FR23-NEW",
    //   suptopic_code: "FR23_FATHERSSIDE",
    //   subtopic_name: "Father's side",
    // },
    // {
    //   id: "FH.24.4.2",
    //   extend: "true",
    //   shortname: "fr28-ad1",
    //   name: "",
    //   prevalue: "Did this person EVER have treatment or counselling?",
    //   value: "",
    //   disabled: "true",
    //   type: "radio",
    //   radio_options: ["Yes", "No"],
    //   act: "FR28-AD1",
    //   depending: "FR28",
    //   exception: "Alcohol;Drug",
    //   link: "FR28-AD2",
    //   subtopic_container_act: "FR23-NEW",
    //   suptopic_code: "FR23_FATHERSSIDE",
    //   subtopic_name: "Father's side",
    // },
    // {
    //   id: "FH.24.4.3",
    //   extend: "true",
    //   shortname: "fr28-ad2",
    //   name: "",
    //   prevalue: "What was the diagnosis or possible condition?",
    //   value: "",
    //   disabled: "true",
    //   type: "text",
    //   act: "FR28-AD2",
    //   depending: "FR28",
    //   exception: "Mental Health",
    //   link: "FR28-AD3",
    //   subtopic_container_act: "FR23-NEW",
    //   suptopic_code: "FR23_FATHERSSIDE",
    //   subtopic_name: "Father's side",
    // },
    // {
    //   id: "FH.24.4.4",
    //   extend: "true",
    //   shortname: "fr28-ad3",
    //   name: "",
    //   prevalue: "Describe the nature of the problem:",
    //   value: "",
    //   disabled: "true",
    //   type: "text",
    //   act: "FR28-AD3",
    //   depending: "FR28",
    //   exception: "Mental Health",
    //   link: "FR29",
    //   subtopic_container_act: "FR23-NEW",
    //   suptopic_code: "FR23_FATHERSSIDE",
    //   subtopic_name: "Father's side",
    // },

    //========================================================================================
    /*                                                                                      *
     *                                         Aunts                                        *
     *                                                                                      */
    //========================================================================================

    {
      id: "FH.24.5",
      extend: "true",
      shortname: "fr29",
      name: "",
      prevalue: "Any Aunts?",
      value: [false, false, false, false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Alcohol", "Drug", "Mental Health", "I don't know"],
      act: "FR29",
      depending: "",
      exception: "",
      link: "FR29-AD",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_FATHERSSIDE",
      subtopic_name: "Father's side",
    },
    // {
    //   id: "FH.24.5.1",
    //   extend: "true",
    //   shortname: "fr29-ad",
    //   name: "",
    //   prevalue: "Is this person currently using?",
    //   value: "",
    //   disabled: "true",
    //   type: "radio",
    //   radio_options: ["Yes", "No"],
    //   act: "FR29-AD",
    //   depending: "FR29",
    //   exception: "Alcohol;Drug",
    //   link: "FR29-AD1",
    //   subtopic_container_act: "FR23-NEW",
    //   suptopic_code: "FR23_FATHERSSIDE",
    //   subtopic_name: "Father's side",
    // },
    // {
    //   id: "FH.24.5.2",
    //   extend: "true",
    //   shortname: "fr29-ad1",
    //   name: "",
    //   prevalue: "Did this person EVER have treatment or counselling?",
    //   value: "",
    //   disabled: "true",
    //   type: "radio",
    //   radio_options: ["Yes", "No"],
    //   act: "FR29-AD1",
    //   depending: "FR29",
    //   exception: "Alcohol;Drug",
    //   link: "FR29-AD2",

    //   subtopic_container_act: "FR23-NEW",
    //   suptopic_code: "FR23_FATHERSSIDE",
    //   subtopic_name: "Father's side",
    // },
    // {
    //   id: "FH.24.5.3",
    //   extend: "true",
    //   shortname: "fr29-ad2",
    //   name: "",
    //   prevalue: "What was the diagnosis or possible condition?",
    //   value: "",
    //   disabled: "true",
    //   type: "text",
    //   act: "FR29-AD2",
    //   depending: "FR29",
    //   exception: "Mental Health",
    //   link: "FR29-AD3",
    //   subtopic_container_act: "FR23-NEW",
    //   suptopic_code: "FR23_FATHERSSIDE",
    //   subtopic_name: "Father's side",
    // },
    // {
    //   id: "FH.24.5.4",
    //   extend: "true",
    //   shortname: "fr29-ad3",
    //   name: "",
    //   prevalue: "Describe the nature of the problem:",
    //   value: "",
    //   disabled: "true",
    //   type: "text",
    //   act: "FR29-AD3",
    //   depending: "FR29",
    //   exception: "Mental Health",
    //   // link: "FR29",
    //   subtopic_container_act: "FR23-NEW",
    //   suptopic_code: "FR23_FATHERSSIDE",
    //   subtopic_name: "Father's side",
    // },
    //========================================================================================
    /*                                                                                      *
     *                                      Grandfather                                     *
     *                                                                                      */
    //========================================================================================

    {
      id: "FH.24.6",
      shortname: "fr30",
      name: "On your Mother’s side",
      prevalue: "Grandfather?",
      value: [false, false, false, false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Alcohol", "Drug", "Mental Health", "I don't know"],
      act: "FR30",
      depending: "",
      exception: "",
      link: "FR30-AD",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_MOTHERSSIDE",
      subtopic_name: "Mother's side",
    },
    {
      id: "FH.24.6.1",
      extend: "true",
      shortname: "fr30-ad",
      name: "",
      prevalue: "Is this person currently using?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR30-AD",
      depending: "FR30",
      exception: "Alcohol;Drug",
      link: "FR30-AD1",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_MOTHERSSIDE",
      subtopic_name: "Mother's side",
    },
    {
      id: "FH.24.6.2",
      extend: "true",
      shortname: "fr30-ad1",
      name: "",
      prevalue: "Did this person EVER have treatment or counselling?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR30-AD1",
      depending: "FR30",
      exception: "Alcohol;Drug",
      link: "FR30-AD2",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_MOTHERSSIDE",
      subtopic_name: "Mother's side",
    },
    {
      id: "FH.24.6.3",
      extend: "true",
      shortname: "fr30-ad2",
      name: "",
      prevalue: "What was the diagnosis or possible condition?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR30-AD2",
      depending: "FR30",
      exception: "Mental Health",
      link: "FR30-AD3",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_MOTHERSSIDE",
      subtopic_name: "Mother's side",
    },
    {
      id: "FH.24.6.4",
      extend: "true",
      shortname: "fr30-ad3",
      name: "",
      prevalue: "Describe the nature of the problem:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR30-AD3",
      depending: "FR30",
      exception: "Mental Health",
      link: "FR31",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_MOTHERSSIDE",
      subtopic_name: "Mother's side",
    },

    //========================================================================================
    /*                                                                                      *
     *                                      Grandmother                                     *
     *                                                                                      */
    //========================================================================================

    {
      id: "FH.24.7",
      extend: "true",
      shortname: "fr31",
      name: "",
      prevalue: "Grandmother?",
      value: [false, false, false, false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Alcohol", "Drug", "Mental Health", "I don't know"],
      act: "FR31",
      depending: "",
      exception: "",
      link: "FR31-AD",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_MOTHERSSIDE",
      subtopic_name: "Mother's side",
    },
    {
      id: "FH.24.7.1",
      extend: "true",
      shortname: "fr31-ad",
      name: "",
      prevalue: "Is this person currently using?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR31-AD",
      depending: "FR31",
      exception: "Alcohol;Drug",
      link: "FR31-AD1",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_MOTHERSSIDE",
      subtopic_name: "Mother's side",
    },
    {
      id: "FH.24.7.2",
      extend: "true",
      shortname: "fr31-ad1",
      name: "",
      prevalue: "Did this person EVER have treatment or counselling?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR31-AD1",
      depending: "FR31",
      exception: "Alcohol;Drug",
      link: "FR31-AD2",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_MOTHERSSIDE",
      subtopic_name: "Mother's side",
    },
    {
      id: "FH.24.7.3",
      extend: "true",
      shortname: "fr31-ad2",
      name: "",
      prevalue: "What was the diagnosis or possible condition?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR31-AD2",
      depending: "FR31",
      exception: "Mental Health",
      link: "FR31-AD3",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_MOTHERSSIDE",
      subtopic_name: "Mother's side",
    },
    {
      id: "FH.24.7.4",
      extend: "true",
      shortname: "fr31-ad3",
      name: "",
      prevalue: "Describe the nature of the problem:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR31-AD3",
      depending: "FR31",
      exception: "Mental Health",
      link: "FR32",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_MOTHERSSIDE",
      subtopic_name: "Mother's side",
    },

    //========================================================================================
    /*                                                                                      *
     *                                        Mother                                        *
     *                                                                                      */
    //========================================================================================

    {
      id: "FH.24.8",
      extend: "true",
      shortname: "fr32",
      name: "",
      prevalue: "Mother?",
      value: [false, false, false, false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Alcohol", "Drug", "Mental Health", "I don't know"],
      act: "FR32",
      depending: "",
      exception: "",
      link: "FR32-AD",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_MOTHERSSIDE",
      subtopic_name: "Mother's side",
    },
    {
      id: "FH.24.8.1",
      extend: "true",
      shortname: "fr32-ad",
      name: "",
      prevalue: "Is this person currently using?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR32-AD",
      depending: "FR32",
      exception: "Alcohol;Drug",
      link: "FR32-AD1",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_MOTHERSSIDE",
      subtopic_name: "Mother's side",
    },
    {
      id: "FH.24.8.2",
      extend: "true",
      shortname: "fr32-ad1",
      name: "",
      prevalue: "Did this person EVER have treatment or counselling?",
      value: "",
      disabled: "true",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "FR32-AD1",
      depending: "FR32",
      exception: "Alcohol;Drug",
      link: "FR32-AD2",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_MOTHERSSIDE",
      subtopic_name: "Mother's side",
    },
    {
      id: "FH.24.8.3",
      extend: "true",
      shortname: "fr32-ad2",
      name: "",
      prevalue: "What was the diagnosis or possible condition?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR32-AD2",
      depending: "FR32",
      exception: "Mental Health",
      link: "FR32-AD3",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_MOTHERSSIDE",
      subtopic_name: "Mother's side",
    },
    {
      id: "FH.24.8.4",
      extend: "true",
      shortname: "fr32-ad3",
      name: "",
      prevalue: "Describe the nature of the problem:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR32-AD3",
      depending: "FR32",
      exception: "Mental Health",
      // link: "FR33",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_MOTHERSSIDE",
      subtopic_name: "Mother's side",
    },
    //========================================================================================
    /*                                                                                      *
     *                                        Uncles                                        *
     *                                                                                      */
    //========================================================================================

    {
      id: "FH.24.9",
      extend: "true",
      shortname: "fr33",
      name: "",
      prevalue: "Any Uncles?",
      value: [false, false, false, false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Alcohol", "Drug", "Mental Health", "I don't know"],
      act: "FR33",
      depending: "",
      exception: "",
      link: "FR33-AD",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_MOTHERSSIDE",
      subtopic_name: "Mother's side",
    },
    // {
    //   id: "FH.24.9.1",
    //   extend: "true",
    //   shortname: "fr33-ad",
    //   name: "",
    //   prevalue: "Is this person currently using?",
    //   value: "",
    //   disabled: "true",
    //   type: "radio",
    //   radio_options: ["Yes", "No"],
    //   act: "FR33-AD",
    //   depending: "FR33",
    //   exception: "Alcohol;Drug",
    //   link: "FR33-AD1",
    //   subtopic_container_act: "FR23-NEW",
    //   suptopic_code: "FR23_MOTHERSSIDE",
    //   subtopic_name: "Mother's side",
    // },
    // {
    //   id: "FH.24.9.2",
    //   extend: "true",
    //   shortname: "fr33-ad1",
    //   name: "",
    //   prevalue: "Did this person EVER have treatment or counselling?",
    //   value: "",
    //   disabled: "true",
    //   type: "radio",
    //   radio_options: ["Yes", "No"],
    //   act: "FR33-AD1",
    //   depending: "FR33",
    //   exception: "Alcohol;Drug",
    //   link: "FR33-AD2",
    //   subtopic_container_act: "FR23-NEW",
    //   suptopic_code: "FR23_MOTHERSSIDE",
    //   subtopic_name: "Mother's side",
    // },
    // {
    //   id: "FH.24.9.3",
    //   extend: "true",
    //   shortname: "fr33-ad2",
    //   name: "",
    //   prevalue: "What was the diagnosis or possible condition?",
    //   value: "",
    //   disabled: "true",
    //   type: "text",
    //   act: "FR33-AD2",
    //   depending: "FR33",
    //   exception: "Mental Health",
    //   link: "FR33-AD3",
    //   subtopic_container_act: "FR23-NEW",
    //   suptopic_code: "FR23_MOTHERSSIDE",
    //   subtopic_name: "Mother's side",
    // },
    // {
    //   id: "FH.24.9.4",
    //   extend: "true",
    //   shortname: "fr33-ad3",
    //   name: "",
    //   prevalue: "Describe the nature of the problem:",
    //   value: "",
    //   disabled: "true",
    //   type: "text",
    //   act: "FR33-AD3",
    //   depending: "FR33",
    //   exception: "Mental Health",
    //   link: "FR34",
    //   subtopic_container_act: "FR23-NEW",
    //   suptopic_code: "FR23_MOTHERSSIDE",
    //   subtopic_name: "Mother's side",
    // },
    //========================================================================================
    /*                                                                                      *
     *                                         Aunts                                        *
     *                                                                                      */
    //========================================================================================

    {
      id: "FH.24.10",
      extend: "true",
      shortname: "fr34",
      name: "",
      prevalue: "Any Aunts?",
      value: [false, false, false, false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Alcohol", "Drug", "Mental Health", "I don't know"],
      act: "FR34",
      depending: "",
      exception: "",
      link: "FR34-AD",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_MOTHERSSIDE",
      subtopic_name: "Mother's side",
    },
    // {
    //   id: "FH.24.10.1",
    //   extend: "true",
    //   shortname: "fr34-ad",
    //   name: "",
    //   prevalue: "Is this person currently using?",
    //   value: "",
    //   disabled: "true",
    //   type: "radio",
    //   radio_options: ["Yes", "No"],
    //   act: "FR34-AD",
    //   depending: "FR34",
    //   exception: "Alcohol;Drug",
    //   link: "FR34-AD1",
    //   subtopic_container_act: "FR23-NEW",
    //   suptopic_code: "FR23_MOTHERSSIDE",
    //   subtopic_name: "Mother's side",
    // },
    // {
    //   id: "FH.24.10.2",
    //   extend: "true",
    //   shortname: "fr34-ad1",
    //   name: "",
    //   prevalue: "Did this person EVER have treatment or counselling?",
    //   value: "",
    //   disabled: "true",
    //   type: "radio",
    //   radio_options: ["Yes", "No"],
    //   act: "FR34-AD1",
    //   depending: "FR34",
    //   exception: "Alcohol;Drug",
    //   // link: "FR34-AD2",
    //   subtopic_container_act: "FR23-NEW",
    //   suptopic_code: "FR23_MOTHERSSIDE",
    //   subtopic_name: "Mother's side",
    // },
    // {
    //   id: "FH.24.10.3",
    //   extend: "true",
    //   shortname: "fr34-ad2",
    //   name: "",
    //   prevalue: "What was the diagnosis or possible condition?",
    //   value: "",
    //   disabled: "true",
    //   type: "text",
    //   act: "FR34-AD2",
    //   depending: "FR34",
    //   exception: "Mental Health",
    //   // link: "FR34-AD3",
    //   subtopic_container_act: "FR23-NEW",
    //   suptopic_code: "FR23_MOTHERSSIDE",
    //   subtopic_name: "Mother's side",
    // },
    // {
    //   id: "FH.24.10.4",
    //   extend: "true",
    //   shortname: "fr34-ad3",
    //   name: "",
    //   prevalue: "Describe the nature of the problem:",
    //   value: "",
    //   disabled: "true",
    //   type: "text",
    //   act: "FR34-AD3",
    //   depending: "FR34",
    //   exception: "Mental Health",
    //   // link: "FR34",
    //   subtopic_container_act: "FR23-NEW",
    //   suptopic_code: "FR23_MOTHERSSIDE",
    //   subtopic_name: "Mother's side",
    // },
    //========================================================================================
    /*                                                                                      *
     *                                      Any Sibling                                     *
     *                                                                                      */
    //========================================================================================

    {
      id: "FH.24.11",
      shortname: "fr35",
      name: "Any Sibling",
      prevalue: "Any Siblings?",
      value: [false, false, false, false],
      disabled: "false",
      type: "checkbox",
      radio_options: ["Alcohol", "Drug", "Mental Health", "I don't know"],
      act: "FR35",
      link: "FR35-AD",
      depending: "",
      exception: "",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_SIBLINGS",
      subtopic_name: "Any Siblings",
    },
    // {
    //   id: "FH.24.11.1",
    //   extend: "true",
    //   shortname: "fr35-ad",
    //   name: "",
    //   prevalue: "Is this person currently using?",
    //   value: "",
    //   disabled: "true",
    //   type: "radio",
    //   radio_options: ["Yes", "No"],
    //   act: "FR35-AD",
    //   depending: "FR35",
    //   exception: "Alcohol;Drug",
    //   link: "FR35-AD1",
    //   subtopic_container_act: "FR23-NEW",
    //   suptopic_code: "FR23_SIBLINGS",
    //   subtopic_name: "Any Siblings",
    // },
    // {
    //   id: "FH.24.11.2",
    //   extend: "true",
    //   shortname: "fr35-ad1",
    //   name: "",
    //   prevalue: "Did this person EVER have treatment or counselling?",
    //   value: "",
    //   disabled: "true",
    //   type: "radio",
    //   radio_options: ["Yes", "No"],
    //   act: "FR35-AD1",
    //   depending: "FR35",
    //   exception: "Alcohol;Drug",
    //   link: "FR35-AD2",
    //   subtopic_container_act: "FR23-NEW",
    //   suptopic_code: "FR23_SIBLINGS",
    //   subtopic_name: "Any Siblings",
    // },
    {
      id: "FH.24.11.3",
      extend: "true",
      shortname: "fr35-ad2",
      name: "",
      prevalue: "What was the diagnosis or possible condition?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR35-AD2",
      depending: "FR35",
      exception: "Mental Health",
      link: "FR35-AD3",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_SIBLINGS",
      subtopic_name: "Any Siblings",
    },
    {
      id: "FH.24.11.4",
      extend: "true",
      shortname: "fr35-ad3",
      name: "",
      prevalue: "Describe the nature of the problem:",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR35-AD3",
      depending: "FR35",
      exception: "Mental Health",
      // link: "FR34",
      subtopic_container_act: "FR23-NEW",
      suptopic_code: "FR23_SIBLINGS",
      subtopic_name: "Any Siblings",
    },


/************************************************************************* */
    {
      id: "FH.25",
      shortname: "fr39",
      name: "How worried or bothered have you been about family or social-related problems in the past 30 days?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "slightly",
        "moderately",
        "considerably",
        "extremely",
      ],
      act: "FR39",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "FR39-1",
    },
    {
      id: "FH.25.1",
      shortname: "fr39-1",
      name: "",
      prevalue: "Please explain the reason you are worried or bothered?",
      value: "",
      disabled: "true",
      type: "text",
      act: "FR39-1",
      depending: "FR39",
      exception: "not at all",
      extend: "true",
    },
    {
      id: "FH.26",
      shortname: "fr40",
      name: "How important is it for you to receive counseling or treatment for family or social-related problems?",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "slightly",
        "moderately",
        "considerably",
        "extremely",
      ],
      act: "FR40",
      depending: "",
      exception: "",
    },
    {
      id: "FH.27.c",
      shortname: "fr41c",
      access: "private",
      name: "Additional comments:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "editor",
      act: "FR41C",
      depending: "",
      exception: "",
    },
    {
      id: "FH.27.d",
      shortname: "fr41d",
      access: "private",
      name: "INTERVIEWER SEVERITY RATING",
      prevalue: "How would you rate the patient’s need for family and/or or social counseling?",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "not at all",
        "slightly",
        "moderately",
        "considerably",
        "extremely",
      ],
      act: "FR41D",
      mandatory: "true",
      depending: "",
      exception: "",
    },
    {
      id: "",
      shortname: "fs37-fs38-subtopic",
      access: "private",
      name: "CONFIDENCE RATINGS. Is the above information significantly distorted by:",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "subtopic",
      act: "FS37-FS38-ACT",
      depending: "",
      exception: "",
    },
    {
      id: "FH.28",
      shortname: "fs37",
      access: "private",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FS37",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "FS37-FS38-ACT",
      suptopic_code: "FS37-CODE",
      subtopic_name:
        "Client's misrepresentation?",
    },
    {
      id: "FH.29",
      shortname: "fs38",
      access: "private",
      name: "",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["No", "Yes"],
      act: "FS38",
      mandatory: "true",
      depending: "",
      exception: "",
      subtopic_container_act: "FS37-FS38-ACT",
      suptopic_code: "FS38-CODE",
      subtopic_name:
        "Client’s inability to understand?",
    },
  ],
};

export default familyHistoryJSON;
