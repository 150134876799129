const blockHallucinogens = [
  //!========================================================================================
  /*                                                                                      *
   *                                STARTBLOCKHALLUCINOGENS                               *
   *                                                                                      */
  //!========================================================================================
  {
    id: "DA.1.1.164",
    shortname: "hallucinogens-block-subtopic",
    name: "Direction for Hallucinogens: Please answer the items in the grid below. Have there EVER been significant periods during which you...",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "subtopic",
    act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    depending: "DA0",
    exception: "Hallucinogens /LSD/mushrooms, etc.",
    condition: "in",
  },
  {
    id: "DA.1.1.165",
    shortname: "hallucinogens-block-6",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HALLUCINOGENS-BLOCK-6",
    depending: "DA0",
    exception: "Hallucinogens /LSD/mushrooms, etc.",
    condition: "in",
    link: "HALLUCINOGENS-BLOCK-7",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.165.1",
    extend: "true",
    shortname: "hallucinogens-block-7",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HALLUCINOGENS-BLOCK-7",
    depending: "HALLUCINOGENS-BLOCK-6",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-8",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.165.2",
    extend: "true",
    shortname: "hallucinogens-block-8",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-8",
    depending: "HALLUCINOGENS-BLOCK-6",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-9",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.165.3",
    extend: "true",
    shortname: "hallucinogens-block-9",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-9",
    depending: "HALLUCINOGENS-BLOCK-6",
    exception: "This has happened",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.166",
    shortname: "hallucinogens-block-10",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HALLUCINOGENS-BLOCK-10",
    depending: "DA0",
    exception: "Hallucinogens /LSD/mushrooms, etc.",
    condition: "in",
    link: "HALLUCINOGENS-BLOCK-11",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.166.1",
    extend: "true",
    shortname: "hallucinogens-block-11",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HALLUCINOGENS-BLOCK-11",
    depending: "HALLUCINOGENS-BLOCK-10",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-12",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.166.2",
    extend: "true",
    shortname: "hallucinogens-block-12",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-12",
    depending: "HALLUCINOGENS-BLOCK-10",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-13",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.166.3",
    extend: "true",
    shortname: "hallucinogens-block-13",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-13",
    depending: "HALLUCINOGENS-BLOCK-10",
    exception: "This has happened",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.167",
    shortname: "hallucinogens-block-14",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HALLUCINOGENS-BLOCK-14",
    depending: "DA0",
    exception: "Hallucinogens /LSD/mushrooms, etc.",
    condition: "in",
    link: "HALLUCINOGENS-BLOCK-15",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.167.1",
    extend: "true",
    shortname: "hallucinogens-block-15",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HALLUCINOGENS-BLOCK-15",
    depending: "HALLUCINOGENS-BLOCK-14",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-16",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.167.2",
    extend: "true",
    shortname: "hallucinogens-block-16",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-16",
    depending: "HALLUCINOGENS-BLOCK-14",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-17",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.167.3",
    extend: "true",
    shortname: "hallucinogens-block-17",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-17",
    depending: "HALLUCINOGENS-BLOCK-14",
    exception: "This has happened",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.168",
    shortname: "hallucinogens-block-18",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HALLUCINOGENS-BLOCK-18",
    depending: "DA0",
    exception: "Hallucinogens /LSD/mushrooms, etc.",
    condition: "in",
    link: "HALLUCINOGENS-BLOCK-19",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.168.1",
    extend: "true",
    shortname: "hallucinogens-block-19",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HALLUCINOGENS-BLOCK-19",
    depending: "HALLUCINOGENS-BLOCK-18",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-30",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.168.2",
    extend: "true",
    shortname: "hallucinogens-block-20",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-20",
    depending: "HALLUCINOGENS-BLOCK-18",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-21",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.168.3",
    extend: "true",
    shortname: "hallucinogens-block-21",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-21",
    depending: "HALLUCINOGENS-BLOCK-18",
    exception: "This has happened",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.169",
    shortname: "hallucinogens-block-22",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HALLUCINOGENS-BLOCK-22",
    depending: "DA0",
    exception: "Hallucinogens /LSD/mushrooms, etc.",
    condition: "in",
    link: "HALLUCINOGENS-BLOCK-23",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.169.1",
    extend: "true",
    shortname: "hallucinogens-block-23",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HALLUCINOGENS-BLOCK-23",
    depending: "HALLUCINOGENS-BLOCK-22",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-24",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.169.2",
    extend: "true",
    shortname: "hallucinogens-block-24",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-24",
    depending: "HALLUCINOGENS-BLOCK-22",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-25",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.169.3",
    extend: "true",
    shortname: "hallucinogens-block-25",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-25",
    depending: "HALLUCINOGENS-BLOCK-22",
    exception: "This has happened",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.170",
    shortname: "hallucinogens-block-26",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HALLUCINOGENS-BLOCK-26",
    depending: "DA0",
    exception: "Hallucinogens /LSD/mushrooms, etc.",
    condition: "in",
    link: "HALLUCINOGENS-BLOCK-27",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.170.1",
    extend: "true",
    shortname: "hallucinogens-block-27",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HALLUCINOGENS-BLOCK-27",
    depending: "HALLUCINOGENS-BLOCK-26",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-28",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.170.2",
    extend: "true",
    shortname: "hallucinogens-block-28",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-28",
    depending: "HALLUCINOGENS-BLOCK-26",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-29",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.170.3",
    extend: "true",
    shortname: "hallucinogens-block-29",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-29",
    depending: "HALLUCINOGENS-BLOCK-26",
    exception: "This has happened",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.171",
    shortname: "hallucinogens-block-30",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HALLUCINOGENS-BLOCK-30",
    depending: "DA0",
    exception: "Hallucinogens /LSD/mushrooms, etc.",
    condition: "in",
    link: "HALLUCINOGENS-BLOCK-31",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.171.1",
    extend: "true",
    shortname: "hallucinogens-block-31",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HALLUCINOGENS-BLOCK-31",
    depending: "HALLUCINOGENS-BLOCK-30",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-32",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.171.2",
    extend: "true",
    shortname: "hallucinogens-block-32",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-32",
    depending: "HALLUCINOGENS-BLOCK-30",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-33",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.171.3",
    extend: "true",
    shortname: "hallucinogens-block-33",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-33",
    depending: "HALLUCINOGENS-BLOCK-30",
    exception: "This has happened",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.172",
    shortname: "hallucinogens-block-34",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HALLUCINOGENS-BLOCK-34",
    depending: "DA0",
    exception: "Hallucinogens /LSD/mushrooms, etc.",
    condition: "in",
    link: "HALLUCINOGENS-BLOCK-35",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.172.1",
    extend: "true",
    shortname: "hallucinogens-block-35",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HALLUCINOGENS-BLOCK-35",
    depending: "HALLUCINOGENS-BLOCK-34",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-36",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.172.2",
    extend: "true",
    shortname: "hallucinogens-block-36",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-36",
    depending: "HALLUCINOGENS-BLOCK-34",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-37",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.172.3",
    extend: "true",
    shortname: "hallucinogens-block-37",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-37",
    depending: "HALLUCINOGENS-BLOCK-34",
    exception: "This has happened",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.173",
    shortname: "hallucinogens-block-38",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HALLUCINOGENS-BLOCK-38",
    depending: "DA0",
    exception: "Hallucinogens /LSD/mushrooms, etc.",
    condition: "in",
    link: "HALLUCINOGENS-BLOCK-39",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.173.1",
    extend: "true",
    shortname: "hallucinogens-block-39",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HALLUCINOGENS-BLOCK-39",
    depending: "HALLUCINOGENS-BLOCK-38",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK0",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.173.2",
    extend: "true",
    shortname: "hallucinogens-block-40",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-40",
    depending: "HALLUCINOGENS-BLOCK-38",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-41",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.173.3",
    extend: "true",
    shortname: "hallucinogens-block-41",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-41",
    depending: "HALLUCINOGENS-BLOCK-38",
    exception: "This has happened",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.174",
    shortname: "hallucinogens-block-42",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HALLUCINOGENS-BLOCK-42",
    depending: "DA0",
    exception: "Hallucinogens /LSD/mushrooms, etc.",
    condition: "in",
    link: "HALLUCINOGENS-BLOCK-43",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.174.1",
    extend: "true",
    shortname: "hallucinogens-block-43",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HALLUCINOGENS-BLOCK-43",
    depending: "HALLUCINOGENS-BLOCK-42",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-44",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.174.2",
    extend: "true",
    shortname: "hallucinogens-block-44",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-44",
    depending: "HALLUCINOGENS-BLOCK-42",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-45",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.174.3",
    extend: "true",
    shortname: "hallucinogens-block-45",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-45",
    depending: "HALLUCINOGENS-BLOCK-42",
    exception: "This has happened",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.175",
    shortname: "hallucinogens-block-46",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "HALLUCINOGENS-BLOCK-46",
    depending: "DA0",
    exception: "Hallucinogens /LSD/mushrooms, etc.",
    condition: "in",
    link: "HALLUCINOGENS-BLOCK-47",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.175.1",
    extend: "true",
    shortname: "hallucinogens-block-47",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "HALLUCINOGENS-BLOCK-47",
    depending: "HALLUCINOGENS-BLOCK-46",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-48",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.175.2",
    extend: "true",
    shortname: "hallucinogens-block-48",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-48",
    depending: "HALLUCINOGENS-BLOCK-46",
    exception: "This has happened",
    link: "HALLUCINOGENS-BLOCK-49",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.175.3",
    extend: "true",
    shortname: "hallucinogens-block-49",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "HALLUCINOGENS-BLOCK-49",
    depending: "HALLUCINOGENS-BLOCK-46",
    exception: "This has happened",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.176",
    shortname: "hallucinogens-block-50",
    name: "Number of criteria met for:",
    prevalue: "Number of criteria met for Hallucinogens /LSD/mushrooms, etc.:",
    value: "0",
    disabled: "true",
    type: "content",
    comment: "Consider no diagnosis for Hallucinogens /LSD/mushrooms, etc.",
    act: "HALLUCINOGENS-BLOCK-50",
    depending: "DA0",
    exception: "Hallucinogens /LSD/mushrooms, etc.",
    condition: "in",
    meaningfulcount: "This has happened",
    subtopic_container_act: "HALLUCINOGENS-BLOCK-SUBTOPIC",
    suptopic_code: "HALLUCINOGENS-BLOCK-12-CONTENT",
    subtopic_name: "Content Criteria",
  },
  //!========================================================================================
  /*                                                                                      *
   *                                 ENDBLOCKHALLUCINOGENS                                *
   *                                                                                      */
  //!========================================================================================
];

export default blockHallucinogens;
