const blockOpiates = [
  //!========================================================================================
  /*                                                                                      *
   *                                   STARTBLOCKOPIATES                                  *
   *                                                                                      */
  //!========================================================================================
  {
    id: "DA.1.1.289",
    shortname: "opiates-block-subtopic",
    name: "Direction for Opiates: Please answer the items in the grid below. Have there EVER been significant periods during which you...",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "subtopic",
    act: "OPIATES-BLOCK-SUBTOPIC",
    depending: "DA0",
    exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
    condition: "in",
  },
  {
    id: "DA.1.1.290",
    shortname: "opiates-block-6",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OPIATES-BLOCK-6",
    depending: "DA0",
    exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
    condition: "in",
    link: "OPIATES-BLOCK-7",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.290.1",
    extend: "true",
    shortname: "opiates-block-7",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OPIATES-BLOCK-7",
    depending: "OPIATES-BLOCK-6",
    exception: "This has happened",
    link: "OPIATES-BLOCK-8",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.290.2",
    extend: "true",
    shortname: "opiates-block-8",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-8",
    depending: "OPIATES-BLOCK-6",
    exception: "This has happened",
    link: "OPIATES-BLOCK-9",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.290.3",
    extend: "true",
    shortname: "opiates-block-9",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-9",
    depending: "OPIATES-BLOCK-6",
    exception: "This has happened",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.291",
    shortname: "opiates-block-10",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OPIATES-BLOCK-10",
    depending: "DA0",
    exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
    condition: "in",
    link: "OPIATES-BLOCK-11",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.291.1",
    extend: "true",
    shortname: "opiates-block-11",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OPIATES-BLOCK-11",
    depending: "OPIATES-BLOCK-10",
    exception: "This has happened",
    link: "OPIATES-BLOCK-12",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.291.2",
    extend: "true",
    shortname: "opiates-block-12",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-12",
    depending: "OPIATES-BLOCK-10",
    exception: "This has happened",
    link: "OPIATES-BLOCK-13",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.291.3",
    extend: "true",
    shortname: "opiates-block-13",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-13",
    depending: "OPIATES-BLOCK-10",
    exception: "This has happened",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.292",
    shortname: "opiates-block-14",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OPIATES-BLOCK-14",
    depending: "DA0",
    exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
    condition: "in",
    link: "OPIATES-BLOCK-15",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.292.1",
    extend: "true",
    shortname: "opiates-block-15",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OPIATES-BLOCK-15",
    depending: "OPIATES-BLOCK-14",
    exception: "This has happened",
    link: "OPIATES-BLOCK-16",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.292.2",
    extend: "true",
    shortname: "opiates-block-16",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-16",
    depending: "OPIATES-BLOCK-14",
    exception: "This has happened",
    link: "OPIATES-BLOCK-17",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.292.3",
    extend: "true",
    shortname: "opiates-block-17",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-17",
    depending: "OPIATES-BLOCK-14",
    exception: "This has happened",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.293",
    shortname: "opiates-block-18",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OPIATES-BLOCK-18",
    depending: "DA0",
    exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
    condition: "in",
    link: "OPIATES-BLOCK-19",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.293.1",
    extend: "true",
    shortname: "opiates-block-19",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OPIATES-BLOCK-19",
    depending: "OPIATES-BLOCK-18",
    exception: "This has happened",
    link: "OPIATES-BLOCK-30",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.293.2",
    extend: "true",
    shortname: "opiates-block-20",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-20",
    depending: "OPIATES-BLOCK-18",
    exception: "This has happened",
    link: "OPIATES-BLOCK-21",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.293.3",
    extend: "true",
    shortname: "opiates-block-21",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-21",
    depending: "OPIATES-BLOCK-18",
    exception: "This has happened",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.294",
    shortname: "opiates-block-22",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OPIATES-BLOCK-22",
    depending: "DA0",
    exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
    condition: "in",
    link: "OPIATES-BLOCK-23",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.294.1",
    extend: "true",
    shortname: "opiates-block-23",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OPIATES-BLOCK-23",
    depending: "OPIATES-BLOCK-22",
    exception: "This has happened",
    link: "OPIATES-BLOCK-24",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.294.2",
    extend: "true",
    shortname: "opiates-block-24",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-24",
    depending: "OPIATES-BLOCK-22",
    exception: "This has happened",
    link: "OPIATES-BLOCK-25",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.294.3",
    extend: "true",
    shortname: "opiates-block-25",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-25",
    depending: "OPIATES-BLOCK-22",
    exception: "This has happened",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.295",
    shortname: "opiates-block-26",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OPIATES-BLOCK-26",
    depending: "DA0",
    exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
    condition: "in",
    link: "OPIATES-BLOCK-27",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.295.1",
    extend: "true",
    shortname: "opiates-block-27",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OPIATES-BLOCK-27",
    depending: "OPIATES-BLOCK-26",
    exception: "This has happened",
    link: "OPIATES-BLOCK-28",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.295.2",
    extend: "true",
    shortname: "opiates-block-28",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-28",
    depending: "OPIATES-BLOCK-26",
    exception: "This has happened",
    link: "OPIATES-BLOCK-29",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.295.3",
    extend: "true",
    shortname: "opiates-block-29",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-29",
    depending: "OPIATES-BLOCK-26",
    exception: "This has happened",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.296",
    shortname: "opiates-block-30",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OPIATES-BLOCK-30",
    depending: "DA0",
    exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
    condition: "in",
    link: "OPIATES-BLOCK-31",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.296.1",
    extend: "true",
    shortname: "opiates-block-31",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OPIATES-BLOCK-31",
    depending: "OPIATES-BLOCK-30",
    exception: "This has happened",
    link: "OPIATES-BLOCK-32",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.296.2",
    extend: "true",
    shortname: "opiates-block-32",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-32",
    depending: "OPIATES-BLOCK-30",
    exception: "This has happened",
    link: "OPIATES-BLOCK-33",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.296.3",
    extend: "true",
    shortname: "opiates-block-33",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-33",
    depending: "OPIATES-BLOCK-30",
    exception: "This has happened",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.297",
    shortname: "opiates-block-34",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OPIATES-BLOCK-34",
    depending: "DA0",
    exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
    condition: "in",
    link: "OPIATES-BLOCK-35",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.297.1",
    extend: "true",
    shortname: "opiates-block-35",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OPIATES-BLOCK-35",
    depending: "OPIATES-BLOCK-34",
    exception: "This has happened",
    link: "OPIATES-BLOCK-36",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.297.2",
    extend: "true",
    shortname: "opiates-block-36",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-36",
    depending: "OPIATES-BLOCK-34",
    exception: "This has happened",
    link: "OPIATES-BLOCK-37",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.297.3",
    extend: "true",
    shortname: "opiates-block-37",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-37",
    depending: "OPIATES-BLOCK-34",
    exception: "This has happened",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.298",
    shortname: "opiates-block-38",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OPIATES-BLOCK-38",
    depending: "DA0",
    exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
    condition: "in",
    link: "OPIATES-BLOCK-39",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.298.1",
    extend: "true",
    shortname: "opiates-block-39",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OPIATES-BLOCK-39",
    depending: "OPIATES-BLOCK-38",
    exception: "This has happened",
    link: "OPIATES-BLOCK0",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.298.2",
    extend: "true",
    shortname: "opiates-block-40",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-40",
    depending: "OPIATES-BLOCK-38",
    exception: "This has happened",
    link: "OPIATES-BLOCK-41",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.298.3",
    extend: "true",
    shortname: "opiates-block-41",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-41",
    depending: "OPIATES-BLOCK-38",
    exception: "This has happened",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.299",
    shortname: "opiates-block-42",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OPIATES-BLOCK-42",
    depending: "DA0",
    exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
    condition: "in",
    link: "OPIATES-BLOCK-43",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.299.1",
    extend: "true",
    shortname: "opiates-block-43",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OPIATES-BLOCK-43",
    depending: "OPIATES-BLOCK-42",
    exception: "This has happened",
    link: "OPIATES-BLOCK-44",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.299.2",
    extend: "true",
    shortname: "opiates-block-44",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-44",
    depending: "OPIATES-BLOCK-42",
    exception: "This has happened",
    link: "OPIATES-BLOCK-45",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.299.3",
    extend: "true",
    shortname: "opiates-block-45",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-45",
    depending: "OPIATES-BLOCK-42",
    exception: "This has happened",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.300",
    shortname: "opiates-block-46",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OPIATES-BLOCK-46",
    depending: "DA0",
    exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
    condition: "in",
    link: "OPIATES-BLOCK-47",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.300.1",
    extend: "true",
    shortname: "opiates-block-47",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OPIATES-BLOCK-47",
    depending: "OPIATES-BLOCK-46",
    exception: "This has happened",
    link: "OPIATES-BLOCK-48",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.300.2",
    extend: "true",
    shortname: "opiates-block-48",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-48",
    depending: "OPIATES-BLOCK-46",
    exception: "This has happened",
    link: "OPIATES-BLOCK-49",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.300.3",
    extend: "true",
    shortname: "opiates-block-49",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OPIATES-BLOCK-49",
    depending: "OPIATES-BLOCK-46",
    exception: "This has happened",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.301",
    shortname: "opiates-block-50",
    name: "Number of criteria met for:",
    prevalue:
      "Number of criteria met for Opiates: Hydrocodone/ Oxycontin/ Morphine/other:",
    value: "0",
    disabled: "true",
    type: "content",
    comment: "Consider no diagnosis for Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
    act: "OPIATES-BLOCK-50",
    depending: "DA0",
    exception: "Opiates: Hydrocodone/ Oxycontin/ Morphine/other",
    condition: "in",
    meaningfulcount: "This has happened",
    subtopic_container_act: "OPIATES-BLOCK-SUBTOPIC",
    suptopic_code: "OPIATES-BLOCK-12-CONTENT",
    subtopic_name: "Content Criteria",
  },
  //!========================================================================================
  /*                                                                                      *
   *                                    ENDBLOCKOPIATES                                   *
   *                                                                                      */
  //!========================================================================================
];

export default blockOpiates;
