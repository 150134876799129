const caseManagementGoals =  {
  section: "CMG",
  topic: "Case Management Goals",
  checked: "true",
  // access: "public",
  color: "secondary",
  description: "(optional)",
  body: [
    {
      id: "CMG.1",
      shortname: "cmg0",
      name: "Case Management Goals should be reviewed",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "radio",
      radio_options: ["Yes", "No"],
      act: "CMG0",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "CMG1",
      // subtopic_container_act: "CI1",
      // suptopic_code: "CI1_INFO3",
      // subtopic_name: "",
    },
    {
      id: "CMG.1.1",
      extend: "true",
      shortname: "cmg1",
      name: "",
      prevalue: "Case Management Goals",
      value: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      disabled: "true",
      type: "checkbox",
      radio_options: [
        "Vocational/Educational-Training & Opportunities",
        "Employment Resume /Coaching Services",
        "Budget -Credit Report - Finances",
        "Housing /Transitional Housing",
        "Homeless Program Referrals",
        "Housing Assessment Template",
        "Health-Medical wellness",
        "Social Network -12 step-Faith Base Support",
        "Public Benefits-Transportation",
        "Clothing/Food",
        "Legal Protections for People with Criminal Records",
      ],
      act: "CMG1",
      depending: "CMG0",
      exception: "Yes",
    },
  ]
}

export default caseManagementGoals;
