const blockInhalants = [
  //!========================================================================================
  /*                                                                                      *
   *                                  STARTBLOCKINHALANTS                                 *
   *                                                                                      */
  //!========================================================================================
  {
    id: "DA.1.1.214",
    shortname: "inhalants-block-subtopic",
    name: "Direction for Inhalants: Please answer the items in the grid below. Have there EVER been significant periods during which you...",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "subtopic",
    act: "INHALANTS-BLOCK-SUBTOPIC",
    depending: "DA0",
    exception: "Inhalants",
    condition: "in",
  },
  {
    id: "DA.1.1.215",
    shortname: "inhalants-block-6",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "INHALANTS-BLOCK-6",
    depending: "DA0",
    exception: "Inhalants",
    condition: "in",
    link: "INHALANTS-BLOCK-7",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.215.1",
    extend: "true",
    shortname: "inhalants-block-7",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "INHALANTS-BLOCK-7",
    depending: "INHALANTS-BLOCK-6",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-8",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.215.2",
    extend: "true",
    shortname: "inhalants-block-8",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-8",
    depending: "INHALANTS-BLOCK-6",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-9",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.215.3",
    extend: "true",
    shortname: "inhalants-block-9",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-9",
    depending: "INHALANTS-BLOCK-6",
    exception: "This has happened",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.216",
    shortname: "inhalants-block-10",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "INHALANTS-BLOCK-10",
    depending: "DA0",
    exception: "Inhalants",
    condition: "in",
    link: "INHALANTS-BLOCK-11",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.216.1",
    extend: "true",
    shortname: "inhalants-block-11",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "INHALANTS-BLOCK-11",
    depending: "INHALANTS-BLOCK-10",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-12",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.216.2",
    extend: "true",
    shortname: "inhalants-block-12",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-12",
    depending: "INHALANTS-BLOCK-10",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-13",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.216.3",
    extend: "true",
    shortname: "inhalants-block-13",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-13",
    depending: "INHALANTS-BLOCK-10",
    exception: "This has happened",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.217",
    shortname: "inhalants-block-14",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "INHALANTS-BLOCK-14",
    depending: "DA0",
    exception: "Inhalants",
    condition: "in",
    link: "INHALANTS-BLOCK-15",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.217.1",
    extend: "true",
    shortname: "inhalants-block-15",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "INHALANTS-BLOCK-15",
    depending: "INHALANTS-BLOCK-14",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-16",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.217.2",
    extend: "true",
    shortname: "inhalants-block-16",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-16",
    depending: "INHALANTS-BLOCK-14",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-17",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.217.3",
    extend: "true",
    shortname: "inhalants-block-17",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-17",
    depending: "INHALANTS-BLOCK-14",
    exception: "This has happened",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.218",
    shortname: "inhalants-block-18",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "INHALANTS-BLOCK-18",
    depending: "DA0",
    exception: "Inhalants",
    condition: "in",
    link: "INHALANTS-BLOCK-19",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.218.1",
    extend: "true",
    shortname: "inhalants-block-19",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "INHALANTS-BLOCK-19",
    depending: "INHALANTS-BLOCK-18",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-30",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.218.2",
    extend: "true",
    shortname: "inhalants-block-20",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-20",
    depending: "INHALANTS-BLOCK-18",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-21",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.218.3",
    extend: "true",
    shortname: "inhalants-block-21",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-21",
    depending: "INHALANTS-BLOCK-18",
    exception: "This has happened",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.219",
    shortname: "inhalants-block-22",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "INHALANTS-BLOCK-22",
    depending: "DA0",
    exception: "Inhalants",
    condition: "in",
    link: "INHALANTS-BLOCK-23",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.219.1",
    extend: "true",
    shortname: "inhalants-block-23",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "INHALANTS-BLOCK-23",
    depending: "INHALANTS-BLOCK-22",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-24",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.219.2",
    extend: "true",
    shortname: "inhalants-block-24",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-24",
    depending: "INHALANTS-BLOCK-22",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-25",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.219.3",
    extend: "true",
    shortname: "inhalants-block-25",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-25",
    depending: "INHALANTS-BLOCK-22",
    exception: "This has happened",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.220",
    shortname: "inhalants-block-26",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "INHALANTS-BLOCK-26",
    depending: "DA0",
    exception: "Inhalants",
    condition: "in",
    link: "INHALANTS-BLOCK-27",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.220.1",
    extend: "true",
    shortname: "inhalants-block-27",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "INHALANTS-BLOCK-27",
    depending: "INHALANTS-BLOCK-26",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-28",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.220.2",
    extend: "true",
    shortname: "inhalants-block-28",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-28",
    depending: "INHALANTS-BLOCK-26",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-29",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.220.3",
    extend: "true",
    shortname: "inhalants-block-29",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-29",
    depending: "INHALANTS-BLOCK-26",
    exception: "This has happened",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.221",
    shortname: "inhalants-block-30",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "INHALANTS-BLOCK-30",
    depending: "DA0",
    exception: "Inhalants",
    condition: "in",
    link: "INHALANTS-BLOCK-31",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.221.1",
    extend: "true",
    shortname: "inhalants-block-31",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "INHALANTS-BLOCK-31",
    depending: "INHALANTS-BLOCK-30",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-32",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.221.2",
    extend: "true",
    shortname: "inhalants-block-32",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-32",
    depending: "INHALANTS-BLOCK-30",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-33",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.221.3",
    extend: "true",
    shortname: "inhalants-block-33",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-33",
    depending: "INHALANTS-BLOCK-30",
    exception: "This has happened",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.222",
    shortname: "inhalants-block-34",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "INHALANTS-BLOCK-34",
    depending: "DA0",
    exception: "Inhalants",
    condition: "in",
    link: "INHALANTS-BLOCK-35",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.222.1",
    extend: "true",
    shortname: "inhalants-block-35",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "INHALANTS-BLOCK-35",
    depending: "INHALANTS-BLOCK-34",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-36",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.222.2",
    extend: "true",
    shortname: "inhalants-block-36",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-36",
    depending: "INHALANTS-BLOCK-34",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-37",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.222.3",
    extend: "true",
    shortname: "inhalants-block-37",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-37",
    depending: "INHALANTS-BLOCK-34",
    exception: "This has happened",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.223",
    shortname: "inhalants-block-38",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "INHALANTS-BLOCK-38",
    depending: "DA0",
    exception: "Inhalants",
    condition: "in",
    link: "INHALANTS-BLOCK-39",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.223.1",
    extend: "true",
    shortname: "inhalants-block-39",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "INHALANTS-BLOCK-39",
    depending: "INHALANTS-BLOCK-38",
    exception: "This has happened",
    link: "INHALANTS-BLOCK0",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.223.2",
    extend: "true",
    shortname: "inhalants-block-40",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-40",
    depending: "INHALANTS-BLOCK-38",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-41",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.223.3",
    extend: "true",
    shortname: "inhalants-block-41",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-41",
    depending: "INHALANTS-BLOCK-38",
    exception: "This has happened",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.224",
    shortname: "inhalants-block-42",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "INHALANTS-BLOCK-42",
    depending: "DA0",
    exception: "Inhalants",
    condition: "in",
    link: "INHALANTS-BLOCK-43",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.224.1",
    extend: "true",
    shortname: "inhalants-block-43",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "INHALANTS-BLOCK-43",
    depending: "INHALANTS-BLOCK-42",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-44",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.224.2",
    extend: "true",
    shortname: "inhalants-block-44",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-44",
    depending: "INHALANTS-BLOCK-42",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-45",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.224.3",
    extend: "true",
    shortname: "inhalants-block-45",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-45",
    depending: "INHALANTS-BLOCK-42",
    exception: "This has happened",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.225",
    shortname: "inhalants-block-46",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "INHALANTS-BLOCK-46",
    depending: "DA0",
    exception: "Inhalants",
    condition: "in",
    link: "INHALANTS-BLOCK-47",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.225.1",
    extend: "true",
    shortname: "inhalants-block-47",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "INHALANTS-BLOCK-47",
    depending: "INHALANTS-BLOCK-46",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-48",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.225.2",
    extend: "true",
    shortname: "inhalants-block-48",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-48",
    depending: "INHALANTS-BLOCK-46",
    exception: "This has happened",
    link: "INHALANTS-BLOCK-49",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.225.3",
    extend: "true",
    shortname: "inhalants-block-49",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "INHALANTS-BLOCK-49",
    depending: "INHALANTS-BLOCK-46",
    exception: "This has happened",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.226",
    shortname: "inhalants-block-50",
    name: "Number of criteria met for:",
    prevalue: "Number of criteria met for Inhalants:",
    value: "0",
    disabled: "true",
    type: "content",
    comment: "Consider no diagnosis for Inhalants",
    act: "INHALANTS-BLOCK-50",
    depending: "DA0",
    exception: "Inhalants",
    condition: "in",
    meaningfulcount: "This has happened",
    subtopic_container_act: "INHALANTS-BLOCK-SUBTOPIC",
    suptopic_code: "INHALANTS-BLOCK-12-CONTENT",
    subtopic_name: "Content Criteria",
  },
  //!========================================================================================
  /*                                                                                      *
   *                                   ENDBLOCKINHALANTS                                  *
   *                                                                                      */
  //!========================================================================================
];

export default blockInhalants;
