const blockOther = [
  //!========================================================================================
  /*                                                                                      *
   *                                    STARTBLOCKOTHER                                   *
   *                                                                                      */
  //!========================================================================================
  {
    id: "DA.1.1.365",
    shortname: "other-block-subtopic",
    name: "Direction for Other: Please answer the items in the grid below. Have there EVER been significant periods during which you...",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "subtopic",
    act: "OTHER-BLOCK-SUBTOPIC",
    depending: "DA0",
    exception: "Other Substance",
    condition: "in",
  },
  {
    id: "DA.1.1.366",
    shortname: "other-block-6",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OTHER-BLOCK-6",
    depending: "DA0",
    exception: "Other Substance",
    condition: "in",
    link: "OTHER-BLOCK-7",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.366.1",
    extend: "true",
    shortname: "other-block-7",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OTHER-BLOCK-7",
    depending: "OTHER-BLOCK-6",
    exception: "This has happened",
    link: "OTHER-BLOCK-8",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.366.2",
    extend: "true",
    shortname: "other-block-8",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-8",
    depending: "OTHER-BLOCK-6",
    exception: "This has happened",
    link: "OTHER-BLOCK-9",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.366.3",
    extend: "true",
    shortname: "other-block-9",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-9",
    depending: "OTHER-BLOCK-6",
    exception: "This has happened",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-1-CONTENT",
    subtopic_name:
      "This substance is often taken in larger amounts or over a longer period than was intended",
  },
  {
    id: "DA.1.1.367",
    shortname: "other-block-10",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OTHER-BLOCK-10",
    depending: "DA0",
    exception: "Other Substance",
    condition: "in",
    link: "OTHER-BLOCK-11",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.367.1",
    extend: "true",
    shortname: "other-block-11",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OTHER-BLOCK-11",
    depending: "OTHER-BLOCK-10",
    exception: "This has happened",
    link: "OTHER-BLOCK-12",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.367.2",
    extend: "true",
    shortname: "other-block-12",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-12",
    depending: "OTHER-BLOCK-10",
    exception: "This has happened",
    link: "OTHER-BLOCK-13",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.367.3",
    extend: "true",
    shortname: "other-block-13",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-13",
    depending: "OTHER-BLOCK-10",
    exception: "This has happened",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-2-CONTENT",
    subtopic_name:
      "There is a persistent desire or unsuccessful efforts to cut down or control this substance use",
  },
  {
    id: "DA.1.1.368",
    shortname: "other-block-14",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OTHER-BLOCK-14",
    depending: "DA0",
    exception: "Other Substance",
    condition: "in",
    link: "OTHER-BLOCK-15",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.368.1",
    extend: "true",
    shortname: "other-block-15",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OTHER-BLOCK-15",
    depending: "OTHER-BLOCK-14",
    exception: "This has happened",
    link: "OTHER-BLOCK-16",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.368.2",
    extend: "true",
    shortname: "other-block-16",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-16",
    depending: "OTHER-BLOCK-14",
    exception: "This has happened",
    link: "OTHER-BLOCK-17",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.368.3",
    extend: "true",
    shortname: "other-block-17",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-17",
    depending: "OTHER-BLOCK-14",
    exception: "This has happened",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-3-CONTENT",
    subtopic_name:
      "A great deal of time is spent in activities necessary to obtain this substance, use this substance, or recover from its effects",
  },
  {
    id: "DA.1.1.369",
    shortname: "other-block-18",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OTHER-BLOCK-18",
    depending: "DA0",
    exception: "Other Substance",
    condition: "in",
    link: "OTHER-BLOCK-19",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.369.1",
    extend: "true",
    shortname: "other-block-19",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OTHER-BLOCK-19",
    depending: "OTHER-BLOCK-18",
    exception: "This has happened",
    link: "OTHER-BLOCK-30",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.369.2",
    extend: "true",
    shortname: "other-block-20",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-20",
    depending: "OTHER-BLOCK-18",
    exception: "This has happened",
    link: "OTHER-BLOCK-21",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.369.3",
    extend: "true",
    shortname: "other-block-21",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-21",
    depending: "OTHER-BLOCK-18",
    exception: "This has happened",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-4-CONTENT",
    subtopic_name: "Craving, or a strong desire or urge to use this substance",
  },
  {
    id: "DA.1.1.370",
    shortname: "other-block-22",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OTHER-BLOCK-22",
    depending: "DA0",
    exception: "Other Substance",
    condition: "in",
    link: "OTHER-BLOCK-23",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.370.1",
    extend: "true",
    shortname: "other-block-23",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OTHER-BLOCK-23",
    depending: "OTHER-BLOCK-22",
    exception: "This has happened",
    link: "OTHER-BLOCK-24",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.370.2",
    extend: "true",
    shortname: "other-block-24",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-24",
    depending: "OTHER-BLOCK-22",
    exception: "This has happened",
    link: "OTHER-BLOCK-25",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.370.3",
    extend: "true",
    shortname: "other-block-25",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-25",
    depending: "OTHER-BLOCK-22",
    exception: "This has happened",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-CONTENT",
    subtopic_name:
      "Recurrent use of this substance resulting in a failure to fulfill major role obligations at work, school, or home",
  },
  {
    id: "DA.1.1.371",
    shortname: "other-block-26",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OTHER-BLOCK-26",
    depending: "DA0",
    exception: "Other Substance",
    condition: "in",
    link: "OTHER-BLOCK-27",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.371.1",
    extend: "true",
    shortname: "other-block-27",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OTHER-BLOCK-27",
    depending: "OTHER-BLOCK-26",
    exception: "This has happened",
    link: "OTHER-BLOCK-28",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.371.2",
    extend: "true",
    shortname: "other-block-28",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-28",
    depending: "OTHER-BLOCK-26",
    exception: "This has happened",
    link: "OTHER-BLOCK-29",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.371.3",
    extend: "true",
    shortname: "other-block-29",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-29",
    depending: "OTHER-BLOCK-26",
    exception: "This has happened",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-6-CONTENT",
    subtopic_name:
      "Continues use of this substance despite having persistent or recurrent social or interpersonal problems caused or exacerbated by the effects of this substance",
  },
  {
    id: "DA.1.1.372",
    shortname: "other-block-30",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OTHER-BLOCK-30",
    depending: "DA0",
    exception: "Other Substance",
    condition: "in",
    link: "OTHER-BLOCK-31",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.372.1",
    extend: "true",
    shortname: "other-block-31",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OTHER-BLOCK-31",
    depending: "OTHER-BLOCK-30",
    exception: "This has happened",
    link: "OTHER-BLOCK-32",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.372.2",
    extend: "true",
    shortname: "other-block-32",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-32",
    depending: "OTHER-BLOCK-30",
    exception: "This has happened",
    link: "OTHER-BLOCK-33",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.372.3",
    extend: "true",
    shortname: "other-block-33",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-33",
    depending: "OTHER-BLOCK-30",
    exception: "This has happened",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-7-CONTENT",
    subtopic_name:
      "Important social, occupational, or recreational activities are given up or reduced because of this substance use",
  },
  {
    id: "DA.1.1.373",
    shortname: "other-block-34",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OTHER-BLOCK-34",
    depending: "DA0",
    exception: "Other Substance",
    condition: "in",
    link: "OTHER-BLOCK-35",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.373.1",
    extend: "true",
    shortname: "other-block-35",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OTHER-BLOCK-35",
    depending: "OTHER-BLOCK-34",
    exception: "This has happened",
    link: "OTHER-BLOCK-36",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.373.2",
    extend: "true",
    shortname: "other-block-36",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-36",
    depending: "OTHER-BLOCK-34",
    exception: "This has happened",
    link: "OTHER-BLOCK-37",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.373.3",
    extend: "true",
    shortname: "other-block-37",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-37",
    depending: "OTHER-BLOCK-34",
    exception: "This has happened",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-8-CONTENT",
    subtopic_name:
      "Recurrent use of this substance in situations in which it is physically hazardous",
  },
  {
    id: "DA.1.1.374",
    shortname: "other-block-38",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OTHER-BLOCK-38",
    depending: "DA0",
    exception: "Other Substance",
    condition: "in",
    link: "OTHER-BLOCK-39",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.374.1",
    extend: "true",
    shortname: "other-block-39",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OTHER-BLOCK-39",
    depending: "OTHER-BLOCK-38",
    exception: "This has happened",
    link: "OTHER-BLOCK0",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.374.2",
    extend: "true",
    shortname: "other-block-40",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-40",
    depending: "OTHER-BLOCK-38",
    exception: "This has happened",
    link: "OTHER-BLOCK-41",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.374.3",
    extend: "true",
    shortname: "other-block-41",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-41",
    depending: "OTHER-BLOCK-38",
    exception: "This has happened",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-9-CONTENT",
    subtopic_name:
      "Use of this substance is continued despite knowledge of having a persistent or recurrent physical or psychological problem that is likely to have been caused or exacerbated by this substance",
  },
  {
    id: "DA.1.1.375",
    shortname: "other-block-42",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OTHER-BLOCK-42",
    depending: "DA0",
    exception: "Other Substance",
    condition: "in",
    link: "OTHER-BLOCK-43",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.375.1",
    extend: "true",
    shortname: "other-block-43",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OTHER-BLOCK-43",
    depending: "OTHER-BLOCK-42",
    exception: "This has happened",
    link: "OTHER-BLOCK-44",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.375.2",
    extend: "true",
    shortname: "other-block-44",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-44",
    depending: "OTHER-BLOCK-42",
    exception: "This has happened",
    link: "OTHER-BLOCK-45",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.375.3",
    extend: "true",
    shortname: "other-block-45",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-45",
    depending: "OTHER-BLOCK-42",
    exception: "This has happened",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-10-CONTENT",
    subtopic_name:
      "Tolerance, as defined by either of the following:-A need for markedly increased amounts of this substance to achieve intoxication or desired effect; - A markedly diminished effect with continued use of the same amount of this substance",
  },
  {
    id: "DA.1.1.376",
    shortname: "other-block-46",
    name: "",
    prevalue: "",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["This has happened", "Never"],
    act: "OTHER-BLOCK-46",
    depending: "DA0",
    exception: "Other Substance",
    condition: "in",
    link: "OTHER-BLOCK-47",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.376.1",
    extend: "true",
    shortname: "other-block-47",
    name: "",
    prevalue: "Age this began",
    value: "",
    disabled: "true",
    type: "text",
    act: "OTHER-BLOCK-47",
    depending: "OTHER-BLOCK-46",
    exception: "This has happened",
    link: "OTHER-BLOCK-48",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.376.2",
    extend: "true",
    shortname: "other-block-48",
    name: "",
    prevalue: "During the past 3 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-48",
    depending: "OTHER-BLOCK-46",
    exception: "This has happened",
    link: "OTHER-BLOCK-49",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.376.3",
    extend: "true",
    shortname: "other-block-49",
    name: "",
    prevalue: "During the past 12 months",
    value: "",
    disabled: "true",
    type: "radio",
    radio_options: ["Yes", "No"],
    act: "OTHER-BLOCK-49",
    depending: "OTHER-BLOCK-46",
    exception: "This has happened",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-11-CONTENT",
    subtopic_name:
      "Withdrawal, as manifested by either of the following: - The characteristic withdrawal syndrome for this substance; - Other substance(s)is/are taken to relieve or avoid withdrawal symptoms",
  },
  {
    id: "DA.1.1.377",
    shortname: "other-block-50",
    name: "Number of criteria met for:",
    prevalue: "Number of criteria met for Other Substance:",
    value: "0",
    disabled: "true",
    type: "content",
    comment: "Consider no diagnosis for Other Substance",
    act: "OTHER-BLOCK-50",
    depending: "DA0",
    exception: "Other Substance",
    condition: "in",
    meaningfulcount: "This has happened",
    subtopic_container_act: "OTHER-BLOCK-SUBTOPIC",
    suptopic_code: "OTHER-BLOCK-12-CONTENT",
    subtopic_name: "Content Criteria",
  },
  //!========================================================================================
  /*                                                                                      *
   *                                     ENDBLOCKOTHER                                    *
   *                                                                                      */
  //!========================================================================================
];

export default blockOther;
